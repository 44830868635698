import React, { useEffect, useState } from 'react';

import { Button, FormFeedback, FormGroup, Input, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IoClose } from 'react-icons/io5';
import Logo from '../../../assets/logo/homeLogo.png';

import { appActions, cashActions, memberActions } from '../../../redux/actions';
import ToastMessage from '../../Web/ToastMessage';
import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
  themeColor: state.appReducers.themeColor,
  captchaData: state.memberReducers.captchaData,
  initData: state.homeReducers.initData,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  getCaptcha: () => dispatch(memberActions.getCaptcha()),
  visitorLogin: (callback = () => {}) => dispatch(memberActions.visitorLogin(callback)),
  login: (username, password, callback = () => {}) => dispatch(memberActions.login(username, password, callback)),
  fetchIsExistLogin: (account, setSuccessMessage, setErrorMessage, setSubmitting) => dispatch(cashActions.fetchIsExistLogin(account, setSuccessMessage, setErrorMessage, setSubmitting)),
  getRegisterPhoneSMSWeb: (phone, setSMSSuccess, setSMSError, setIsLoading, setIsOpenToast) => dispatch(memberActions.getRegisterPhoneSMSWeb(phone, setSMSSuccess, setSMSError, setIsLoading, setIsOpenToast)),
  registerWeb: (account, password, affCode, code, sms, phone, setRegisterSuccess, setRegisterError, setIsRegistering, setRegisterErrorToast, setRegisterSuccessToast) => dispatch(memberActions.registerWeb(account, password, affCode, code, sms, phone, setRegisterSuccess, setRegisterError, setIsRegistering, setRegisterErrorToast, setRegisterSuccessToast)),
});

const LoginAndRehisterModal = ({
  themeColor,
  updateModalAlert,
  captchaData,
  getCaptcha,
  login,
  visitorLogin,
  initData,
  fetchIsExistLogin,
  getRegisterPhoneSMSWeb,
  registerWeb
}) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    _username: '',
    _password: '',
    _captcha: '',
    account: '',
    password: '',
    confirmPW: '',
    code: '',
    affCode: '',
    phone: '',
    sms: '',
  });

  const [errors, setErrors] = useState({});

  const [inputValid, setInputValid] = useState({
    _username: false,
    _password: false,
    _captcha: false,
    password: false,
    confirmPW: false,
    code: false,
    phone: false,
    sms: false,
  });

  const [captchaImage, setCaptchaImage] = useState('');
  const [pageType, setPageType] = useState('login');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [smsError, setSMSError] = useState(null);
  const [smsSuccess, setSMSSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState(null);
  const [registerError, setRegisterError] = useState(null);
  const [isOpenToast, setIsOpenToast] = useState(true);
  const [registerSuccessToast, setRegisterSuccessToast] = useState(true);
  const [registerErrorToast, setRegisterErrorToast] = useState(true);
  const phoneValidatation = new RegExp(/^(?:\+?86)?1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[0-35-9]\d{2}|4(?:0\d|1[0-2]|9\d))|9[0-35-9]\d{2}|6[2567]\d{2}|4(?:(?:10|4[01])\d{3}|[68]\d{4}|[579]\d{2}))\d{6}$/);

  const history = useHistory();


  useEffect(() => {
    if (captchaData && captchaData.base64) {
      setCaptchaImage(`data:image/jpg;base64,${captchaData.base64}`);
    } else {
      getCaptcha();
    }
  }, [captchaData]);

  const onCaptchaClick = () => {
    getCaptcha();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData, [name]: value
    }));
  };

  const handleError = (field, error) => {
    setErrors(prevErrors => ({
      ...prevErrors, [field]: error
    }));
  };

  const handleInputValid = (field, valid) => {
    setInputValid(prevInputValid => ({
      ...prevInputValid, [field]: valid
    }));
  };

  const onLogin = () => {
    const isAccountValid = validateAccount('login');
    const isPasswordValid = validatePassword('login');
    const isCodeValid = validateCode('login');

    if (isAccountValid && isPasswordValid && isCodeValid) {
      updateModalAlert({
        visible: true,
        type: 'loading',
      });
      login(formData._username, formData._password, () => { history.push('/agreement'); });
    }
  };

  const onVisitorLogin = () => {
    updateModalAlert({
      visible: true,
      type: 'loading',
    });
    visitorLogin(() => { history.push('/agreement'); });
  };

  const validateAccount = type => {
    if (type === 'login') {
      if (!formData._username) {
        handleError('username', t('REGISTER_ERROR_REQUIRED_USERNAME'));
        return false;
      }
    } else if (type === 'register') {
      if (!formData.account || (formData.account && (formData.account.length < 4 || formData.account.length > 12))) {
        handleError('account', t('LOGIN_ERROR_USERNAME_LENGTH'));
        return false;
      }
    }
    return true;
  };

  const validatePassword = type => {
    if (type === 'login') {
      if (!formData._password) {
        handleError('_password', t('LOGIN_ERROR_EMPTY_PASSWORD'));
        return false;
      }
    } else if (type === 'register') {
      if (!formData.password) {
        handleError('password', t('LOGIN_ERROR_EMPTY_PASSWORD'));
        return false;
      }
    }
    return true;
  };

  const validateConfirmPW = () => {
    if (!formData.confirmPW) {
      handleError('confirmPW', t('REGISTER_ERROR_CONFIRM_PASSWORD'));
      return false;
    }
    if (formData.password !== formData.confirmPW) {
      handleError('confirmPW', t('REGISTER_ERROR_PASSWORD_MISMATCHED'));
      return false;
    }
    return true;
  };

  const validatePhone = () => {
    if (initData && initData.company.safePhoneInRegEnable === 0 && !phoneValidatation.test(formData.phone)) {
      handleError('phone', t('REGISTER_ERROR_PHONE_INVALID'));
      return false;
    }
    return true;
  };

  const validateSms = () => {
    if (initData && initData.company.safePhoneInRegEnable === 0 && !formData.sms) {
      setSMSError(t('REGISTER_ERROR_EMPTY_SMS'));
      return false;
    }
    return true;
  };

  const validateCode = type => {
    if (type === 'login') {
      if (!formData._captcha) {
        handleError('_captcha', t('REGISTER_ERROR_EMPTY_CAPTCHA'));
        return false;
      }
      if (captchaData && formData._captcha !== captchaData.code) {
        handleError('_captcha', t('CAPTCHA_NOT_MATCH'));
        return false;
      }
    } else if (type === 'register') {
      if (!formData.code) {
        handleError('code', t('REGISTER_ERROR_EMPTY_CAPTCHA'));
        return false;
      }
      if (captchaData && formData.code !== captchaData.code) {
        handleError('code', t('CAPTCHA_NOT_MATCH'));
        return false;
      }
    }
    return true;
  };


  const onRegister = () => {
    const isAccountValid = validateAccount('register');
    const isPasswordValid = validatePassword('register');
    const isConfirmPWValid = validateConfirmPW();
    const isPhoneValid = validatePhone();
    const isSmsValid = validateSms();
    const isCodeValid = validateCode('register');

    if (isAccountValid && isPasswordValid && isConfirmPWValid && isPhoneValid && isSmsValid && isCodeValid && submitting) {
      setIsRegistering(true);
      registerWeb(formData.account, formData.password, formData.affCode, formData.code, formData.sms, formData.phone, setRegisterSuccess, setRegisterError, setIsRegistering, setRegisterErrorToast, setRegisterSuccessToast);
    }
  };

  function handleKeyDown(event) {
    if (event.key === 'Escape') {
      updateModalAlert({
        visible: false
      });
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  const onGetRegCode = () => {
    setIsLoading(true);
    getRegisterPhoneSMSWeb(formData.phone, setSMSSuccess, setSMSError, setIsLoading, setIsOpenToast);
  };

  const toggleToast = () => {
    setIsOpenToast(false);
    setSMSSuccess(null);
  };
  const toggleRegisterSuccesssToast = () => {
    setRegisterSuccessToast(false);
    setRegisterSuccess(null);
  };
  const toggleRegisterErrorToast = () => {
    setRegisterErrorToast(false);
    setRegisterError(null);
  };

  useEffect(() => {
    if (smsSuccess && isOpenToast) {
      const timer = setTimeout(() => {
        toggleToast();
      }, 5000);

      return () => clearTimeout(timer);
    }

    if (registerSuccess && registerSuccessToast) {
      const timer = setTimeout(() => {
        toggleRegisterSuccesssToast();
        login(formData.account, formData.password);
      }, 5000);

      return () => clearTimeout(timer);
    }

    if (registerError && registerErrorToast) {
      const timer = setTimeout(() => {
        toggleRegisterErrorToast();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [smsSuccess, registerSuccess, registerError]);

  return (
    <div className={`loginRegisterWarpper theme-${themeColor}`}>
      <div className="loginAndRegister">
        {pageType === 'login'
        && (
        <form className="loginBox" onSubmit={e => { e.preventDefault(); onLogin(); }}>
          <div className="d-flex justify-content-center align-items-center">
            <div className="loginTitle d-flex flex-column justify-content-center align-items-center">
              <img src={Logo} alt="" srcSet="" />
              <div>会员登录</div>
            </div>
            <div className="closeIcon">
              <IoClose
                size={29}
                onClick={() => {
                  updateModalAlert({
                    visible: false
                  });
                }}
              />
            </div>
          </div>
          <div className="mb-2">
            <FormGroup className="position-relative">
              <div className={`loginBoxItem ${errors.username ? 'borderRed' : ''}`}>
                <div>{t('ACCOUNT')}</div>
                <Input
                  value={formData._username}
                  name="_username"
                  placeholder="请输入您的账号"
                  invalid={errors.username}
                  valid={inputValid._username}
                  onChange={e => {
                    handleChange(e);
                    handleError('username', null);
                  }}
                  onBlur={() => {
                    handleInputValid('_username', validateAccount('login'));
                  }}
                />
                {errors.username && <FormFeedback className="formFeedback">{`*${errors.username}`}</FormFeedback>}
              </div>
            </FormGroup>
            <FormGroup className="position-relative">
              <div className={`loginBoxItem ${errors._password ? 'borderRed' : ''}`}>
                <div>{`${t('PASSWORD')}`}</div>
                <Input
                  type="password"
                  name="_password"
                  placeholder="请输入您的密码"
                  value={formData._password}
                  invalid={errors._password}
                  valid={inputValid._password}
                  onChange={e => {
                    handleChange(e);
                    handleError('_password', null);
                  }}
                  onBlur={() => {
                    handleInputValid('_password', validatePassword('login'));
                  }}
                />
                {errors._password && <FormFeedback className="formFeedback">{`*${errors._password}`}</FormFeedback>}
              </div>
            </FormGroup>
            <FormGroup className="position-relative">
              <div className={`loginBoxItem ${errors._captcha ? 'borderRed' : ''}`}>
                <div>{`${t('VERIFY_CODE')}`}</div>
                <Input
                  placeholder="请输入验证码"
                  name="_captcha"
                  value={formData._captcha}
                  invalid={errors._captcha}
                  valid={inputValid._captcha}
                  onChange={e => {
                    handleChange(e);
                    handleError('_captcha', null);
                  }}
                  onBlur={() => {
                    handleInputValid('_captcha', validateCode('login'));
                  }}
                />
                <div className="border-0 w-25" onClick={() => onCaptchaClick()}>
                  <img id="captchaImg" src={captchaImage} alt="" className="w-100 px-2 py-1" />
                </div>
                {errors._captcha && <FormFeedback className="formFeedback">{`*${errors._captcha}`}</FormFeedback>}
              </div>
            </FormGroup>
          </div>
          <div className="loginBoxItem">
            <Button className="loginButton" type="submit">{t('LOGIN')}</Button>
          </div>
          <div className="loginNoAccount">
            {t('NO_ACCOUNT')}
            ？
            <span onClick={() => setPageType('register')}>{t('PLEASE_REGISTER')}</span>
            <div onClick={() => onVisitorLogin()} className="trialButton d-flex justify-content-center align-items-center">
              <span>试玩登录</span>
            </div>
          </div>
        </form>
        )}
        {pageType === 'register'
        && (
        <form className="loginBox" onSubmit={e => { e.preventDefault(); onRegister(); }}>
          <div className="d-flex justify-content-center align-items-center">
            <div className="loginTitle d-flex flex-column justify-content-center align-items-center">
              <img src={Logo} alt="" srcSet="" />
              <div>会员注册</div>
            </div>
            <div className="closeIcon">
              <IoClose
                size={29}
                onClick={() => {
                  updateModalAlert({
                    visible: false
                  });
                }}
              />
            </div>
          </div>
          <div className="formInputs my-2">
            <div className="loginBoxItem">
              <div>{t('AFFILIATE_CODE')}</div>
              <Input name="affCode" value={formData.affCode} placeholder="请输入您的邀请码" onChange={e => handleChange(e)} />
            </div>
            <FormGroup className="position-relative">
              <div className={`loginBoxItem ${errors.account || errorMessage ? 'borderRed' : ''}`}>
                <div>{t('ACCOUNT')}</div>
                <Input
                  type="text"
                  name="account"
                  value={formData.account}
                  maxLength="12"
                  placeholder="请输入您的账号"
                  invalid={errors.account || errorMessage}
                  valid={!!successMessage}
                  onChange={e => {
                    handleChange(e);
                    handleError('account', null);
                  }}
                  onBlur={() => fetchIsExistLogin(formData.account, setSuccessMessage, setErrorMessage, setSubmitting)}
                />
                {errors.account && <FormFeedback className="formFeedback">{`*${errors.account}`}</FormFeedback>}
                {successMessage && <FormFeedback className="formFeedback" valid>{successMessage}</FormFeedback>}
                {errorMessage && <FormFeedback className="formFeedback">{`*${errorMessage}`}</FormFeedback>}
              </div>
            </FormGroup>
            <FormGroup className="position-relative">
              <div className={`loginBoxItem ${errors.password ? 'borderRed' : ''}`}>
                <div>{`${t('PASSWORD')}`}</div>
                <Input
                  type="password"
                  name="password"
                  placeholder="请输入您的密码"
                  value={formData.password}
                  invalid={errors.password}
                  valid={inputValid.password}
                  onChange={e => {
                    handleChange(e);
                    handleError('password', null);
                  }}
                  onBlur={() => {
                    handleInputValid('password', validatePassword('register'));
                  }}
                />
                {errors.password && <FormFeedback className="formFeedback">{`*${errors.password}`}</FormFeedback>}
              </div>
            </FormGroup>
            <FormGroup className="position-relative">
              <div className={`loginBoxItem ${errors.confirmPW ? 'borderRed' : ''}`}>
                <div>{`${t('CONFIRM_PASSWORD')}`}</div>
                <Input
                  type="password"
                  name="confirmPW"
                  placeholder="请输入确认密码"
                  value={formData.confirmPW}
                  invalid={errors.confirmPW}
                  valid={inputValid.confirmPW}
                  onChange={e => {
                    handleChange(e);
                    handleError('confirmPW', null);
                  }}
                  onBlur={({ target: { value: text } }) => {
                    handleInputValid('confirmPW', validateConfirmPW(text));
                  }}
                />
                {errors.confirmPW && <FormFeedback className="formFeedback">{`*${errors.confirmPW}`}</FormFeedback>}
              </div>
            </FormGroup>
            <FormGroup className="position-relative">
              <div className={`loginBoxItem ${errors.code ? 'borderRed' : ''}`}>
                <div>{`${t('VERIFY_CODE')}`}</div>
                <Input
                  name="code"
                  placeholder="请输入验证码"
                  value={formData.code}
                  onChange={e => {
                    handleChange(e);
                    handleError('code', null);
                  }}
                  onBlur={() => {
                    handleInputValid('code', validateCode('register'));
                  }}
                  invalid={errors.code}
                  valid={inputValid.code}
                />
                <div className="border-0 w-25" onClick={() => onCaptchaClick()}>
                  <img id="captchaImg" src={captchaImage} alt="" className="w-100 px-2 py-1" />
                </div>
                {errors.code && <FormFeedback className="formFeedback">{`*${errors.code}`}</FormFeedback>}
              </div>
            </FormGroup>
            {initData && initData.company && initData.company.safePhoneInRegEnable === 0 && (
            <>
              <FormGroup className="position-relative">
                <div className={`loginBoxItem ${errors.phone ? 'borderRed' : ''}`}>
                  <div>{`${t('PHONE')}`}</div>
                  <Input
                    placeholder="请输入国内11位数手机号码"
                    name="phone"
                    value={formData.phone}
                    onChange={e => {
                      handleChange(e);
                      handleError('phone', null);
                    }}
                    onBlur={({ target: { value: text } }) => {
                      handleInputValid('phone', validatePhone(text));
                    }}
                    invalid={errors.phone}
                    valid={inputValid.phone}
                  />
                  {errors.phone && <FormFeedback className="formFeedback">{`*${errors.phone}`}</FormFeedback>}
                </div>
              </FormGroup>
              {smsSuccess && (<ToastMessage className="bg-success" isOpen={isOpenToast} onClick={toggleToast} toastHeader="发送成功" toastBody={smsSuccess} />)}
              <FormGroup className="position-relative">
                <div className={`loginBoxItem ${smsError ? 'borderRed' : ''}`}>
                  <div>{`${t('SMS_VERIFY_CODE')}`}</div>
                  <Input
                    placeholder="请输入短信验证码"
                    name="sms"
                    value={formData.sms}
                    onChange={e => {
                      handleChange(e);
                      setSMSError(null);
                    }}
                    onBlur={({ target: { value: text } }) => {
                      handleInputValid('sms', validateSms(text));
                    }}
                    invalid={smsError}
                    valid={inputValid.sms}
                  />
                  <button type="button" className="border-0 text-white d-flex justify-content-center align-items-center registerSMSBtn" onClick={e => onGetRegCode(e)} disabled={!inputValid.phone}>
                    {!isLoading && <span>{t('GET_VERIFY_CODE')}</span>}
                    {isLoading && <Spinner size="sm">loading...</Spinner>}
                  </button>
                  {smsError && <FormFeedback className="formFeedback">{`*${smsError}`}</FormFeedback>}
                </div>
              </FormGroup>
            </>
            )}
          </div>
          {registerSuccess && (<ToastMessage className="bg-success" isOpen={registerSuccessToast} onClick={toggleRegisterSuccesssToast} toastHeader="注册成功!" toastBody="将登入与导回到首页" isButton account={formData.account} password={formData.password} />)}
          {registerError && (<ToastMessage className="bg-danger" isOpen={registerErrorToast} onClick={toggleRegisterErrorToast} toastHeader="注册失败" toastBody={registerError} />)}
          <div className="loginNoAccount">
            {t('HAVE_ACCOUNT')}
            ？
            <span onClick={() => setPageType('login')}>{t('PLEASE_LOGIN')}</span>
            <Button
              className="registerButton d-flex justify-content-center align-items-center"
              type="submit"
              disabled={isRegistering}
            >
              {!isRegistering && <span>{t('REGISTER')}</span>}
              {isRegistering && <Spinner size="sm">loading...</Spinner>}
            </Button>
          </div>
        </form>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginAndRehisterModal);