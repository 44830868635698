import { OddsTypeTable, ProbabilitySelectionPanel } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const ZSL = [
  {
    componentType: ProbabilitySelectionPanel,
    data: {
      showProbability: false,
      midCodeList: [{
        label: '前三',
        value: 'ZX6Q3',
        minimumSelection: 4,
        maximumSelection: 8,
        iteration: 3,
      }, {
        label: '中三',
        value: 'ZX6Z3',
        minimumSelection: 4,
        maximumSelection: 8,
        iteration: 3,
      }, {
        label: '后三',
        value: 'ZX6H3',
        minimumSelection: 4,
        maximumSelection: 8,
        iteration: 3,
      }]
    }
  },
  {
    componentType: QuickBet,
  },
  {
    componentType: OddsTypeTable,
    data: {
      renderLabel: sectionSelection => {
        if (!sectionSelection) return;
        if (sectionSelection.value === 'ZX6Q3') {
          return '前三组选六';
        } if (sectionSelection.value === 'ZX6Z3') {
          return '中三组选六';
        }
        return '后三组选六';
      }
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      mainColumnLength: 2,
      subColumnLength: 2,
      betItemStyle: ['w-25', 'w-75'],
      betItemList: ['DisplayText', 'CheckBox'],
      betItemColumn: 2,
      list: [
        {
          code: 'A0',
          displayName: '0',
          className: 'circle',
        }, {
          code: 'A5',
          displayName: '5',
          className: 'circle',
        }, {
          code: 'A1',
          displayName: '1',
          className: 'circle',
        }, {
          code: 'A6',
          displayName: '6',
          className: 'circle',
        }, {
          code: 'A2',
          displayName: '2',
          className: 'circle',
        }, {
          code: 'A7',
          displayName: '7',
          className: 'circle',
        }, {
          code: 'A3',
          displayName: '3',
          className: 'circle',
        }, {
          code: 'A8',
          displayName: '8',
          className: 'circle',
        }, {
          code: 'A4',
          displayName: '4',
          className: 'circle',
        }, {
          code: 'A9',
          displayName: '9',
          className: 'circle',
        }
      ]
    }
  }, {
    componentType: QuickBet,
  }
];

export default ZSL;