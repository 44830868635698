import React from 'react';

import { Col } from 'reactstrap';

import ChooseBet from '../../Web/Content/ChooseBet';
import './styles.scss';

const Template2 = ({ _currentLengReResult, tableTitle, tableHeadertitle, cycleHeaderTitle, zodiacTotalBall, mainColumnLength, subColumnLength, emptyItemStyle, subColumnStyle, betItemStyle, betItemList, list, showProbability }) => {
  const renderTableBody = () => {
    const tableBodyTitle = [];
    const tableBody = [];
    let count = 0;
    let tableBodyRow = [];

    const fillEmptyRow = () => {
      while (tableBodyRow.length < mainColumnLength) {
        tableBodyRow.push(
          <td key={`choose_bet_empty_item_${tableBodyRow.length.toString()}`} className="table-cell emptyContainer">
            <div className="d-flex justify-content-center">
              {
                emptyItemStyle && emptyItemStyle.map(item => (<div className={item} />))
              }
            </div>
          </td>
        );
      }
      tableBody.push(<tr key={`table_body_${count.toString()}`}>{tableBodyRow}</tr>);
    };

    const fillItemRow = () => {
      tableBodyRow.push(
        <>
          {(list[count] && list[count].rowTitle) && <td className={`${list[count].rowTitleClassName}`}>{list[count].rowTitle}</td>}
          <td key={`choose_bet_item_${count.toString()}`} className="table-cell">
            {
            list[count] && <ChooseBet _currentLengReResult={_currentLengReResult} zodiacTotalBall={zodiacTotalBall} obj={list[count]} betItemStyle={betItemStyle} betItemList={betItemList} showProbability={showProbability} />
          }
          </td>
        </>
      );
      count += 1;
    };

    if (tableHeadertitle) {
      for (let i = 0; i < mainColumnLength; i++) {
        const tableHeadertitleRow = [];
        for (let j = 0; j < subColumnLength; j++) {
          tableHeadertitleRow.push(<div key={`table_title_item_${i.toString()}_${j}`} className={`py-2 ${subColumnStyle[j]}`}>{tableHeadertitle[cycleHeaderTitle ? j : i]}</div>);
        }
        tableBodyTitle.push(<td key={`table_title_td_${i.toString()}`}><div className="table-title h-100 d-flex justify-content-center align-items-center">{tableHeadertitleRow}</div></td>);
      }
    }
    while (count < list.length) {
      if (list[count] && list[count].newLine) {
        fillEmptyRow();
        tableBodyRow = [];
        fillItemRow();
      } else if (tableBodyRow.length < mainColumnLength) {
        fillItemRow();
      } else {
        tableBody.push(<tr key={`table_body_${count.toString()}`}>{tableBodyRow}</tr>);
        tableBodyRow = [];
      }
    }
    fillEmptyRow();

    return (
      <tbody>
        {tableHeadertitle && <tr className="header-bg">{tableBodyTitle}</tr> }
        {tableBody}
      </tbody>
    );
  };
  return (
    <Col md="12" className="template2 my-2">
      <table className="w-100  text-center templateStyle">
        { tableTitle && (
        <thead>
          <tr className="header-bg">
            {Array.isArray(tableTitle) ? (tableTitle.map((item, index) => <td key={`table_titel_${index.toString()}`} className="py-2">{item}</td>))
              : <td colSpan={mainColumnLength} className="py-2">{tableTitle}</td>}
          </tr>
        </thead>
        )}
        {
          renderTableBody()
        }
      </table>
    </Col>
  );
};

export default Template2;