import HM from './HM';
import LIANMA from './LIANMA';
import LiangMian from './LiangMian';
import ZhiXuan from './ZhiXuan';

const renderQiu = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  return result.map(item => (
    {
      value: item, className: 'SSC_ball'
    }
  ));
};

const renderZH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  let championSum = 0;
  result.forEach(item => (championSum += parseInt(item, 10)));

  tempResult.push({
    value: championSum,
    className: 'redText'
  }, {
    value: championSum > 30 ? '大' : championSum < 30 ? '小' : '和',
    className: championSum > 30 ? 'resultItem resultItemBlue' : championSum < 30 ? 'resultItem resultItemOrange' : 'resultItem resultItemGreen',
  }, {
    value: championSum % 2 ? '单' : '双',
    className: championSum % 2 ? 'resultItem resultItemBlue' : 'resultItem resultItemOrange',
  }, {
    value: (parseInt(result[0], 10)) > parseInt(result[result.length - 1], 10) ? '龙' : '虎',
    className: (parseInt(result[0], 10)) > parseInt(result[result.length - 1], 10) ? 'resultItem resultItemBlue' : 'resultItem resultItemOrange',
  });

  return tempResult;
};

const renderDX = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  return result.map(item => (
    {
      value: (item > 5 && item < 11) ? '大' : (item <= 5) ? '小' : '和',
      className: (item > 5 && item < 11) ? 'resultItem resultItemBlue' : (item <= 5) ? 'resultItem resultItemOrange' : 'resultItem resultItemGreen',
    }
  ));
};

const renderDS = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  return result.map(item => (
    {
      value: (item === 11) ? '和' : (item % 2) ? '单' : '双',
      className: (item === 11) ? 'resultItem resultItemGreen' : (item % 2) ? 'resultItem resultItemBlue' : 'resultItem resultItemOrange',
    }
  ));
};

const CJ_11X5 = {
  children: [
    {
      tabId: 'LiangMian',
      label: '两面',
      children: LiangMian.LiangMian,
      settingCode: 'LM,ZHWDX,ZHDS'
    },
    {
      tabId: 'DanHao',
      label: '单号',
      children: HM.HM,
      midCode: 'YZY,B1QH,B2QH,B3QH,B4QH,B5QH'
    },
    {
      tabId: 'LianMa',
      label: '连码',
      children: LIANMA.LIANMA,
      settingCode: 'LIANMA'
    },
    {
      tabId: 'ZhiXuan',
      label: '直选',
      children: ZhiXuan.ZhiXuan,
      settingCode: 'ZX'
    },
  ],
  results: {
    TabList: [{
      code: 'NUMBER',
      renderFunc: data => renderQiu(data)
    },
    {
      code: 'SUM',
      renderFunc: data => renderZH(data)
    },
    {
      code: 'BIG_SMALL',
      renderFunc: data => renderDX(data)
    },
    {
      code: 'ODD_EVEN',
      renderFunc: data => renderDS(data)
    }]
  }
};

export default CJ_11X5;