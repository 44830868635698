// import Template1 from './Template1';
import FanTanTemplate from './FanTanTemplate';
import ProbabilitySelectionPanel from '../Web/ProbabilitySelectionPanel';
import QuickBet from '../Web/Content/QuickBet';
import Template2 from './Template2';

export default {
  FanTanTemplate,
  ProbabilitySelectionPanel,
  QuickBet,
  Template2,
};