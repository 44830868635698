import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const GYJZH = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '冠亚军和值',
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '赔率', '金额'],
      mainColumnLength: 4,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      emptyItemStyle: ['column-3items-empty-label', 'column-3items-empty-odds', 'column-3items-empty-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'GYJH1',
          displayName: '3',
        }, {
          code: 'GYJH2',
          displayName: '4',
        }, {
          code: 'GYJH3',
          displayName: '5',
        }, {
          code: 'GYJH4',
          displayName: '6',
        }, {
          code: 'GYJH5',
          displayName: '7',
          newLine: true
        },
        {
          code: 'GYJH6',
          displayName: '8',
        }, {
          code: 'GYJH7',
          displayName: '9',
        }, {
          code: 'GYJH8',
          displayName: '10',
        }, {
          code: 'GYJH9',
          displayName: '11',
        }, {
          code: 'GYJH10',
          displayName: '12',
        }, {
          code: 'GYJH11',
          displayName: '13',
        }, {
          code: 'GYJH12',
          displayName: '14',
        }, {
          code: 'GYJH13',
          displayName: '15',
        }, {
          code: 'GYJH14',
          displayName: '16',
        }, {
          code: 'GYJH15',
          displayName: '17',
        }, {
          code: 'GYJH16',
          displayName: '18',
        }, {
          code: 'GYJH17',
          displayName: '19',
        }, {
          code: 'GYJH18',
          displayName: '冠亚大',
          newLine: true
        }, {
          code: 'GYJH19',
          displayName: '冠亚小',
        }, {
          code: 'GYJH20',
          displayName: '冠亚单',
        }, {
          code: 'GYJH21',
          displayName: '冠亚双',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default GYJZH;