import { DELETE_ITEM_IN_CART, INSERT_ITEM_TO_CART, RESET_ITEM_IN_CART, UPDATE_ENTIRE_CART } from '../actionTypes';

const insertItemToCart = companyInfo => ({
  type: INSERT_ITEM_TO_CART,
  value: companyInfo,
});

const deleteItemInCart = companyInfo => ({
  type: DELETE_ITEM_IN_CART,
  value: companyInfo,
});

const resetCart = () => ({
  type: RESET_ITEM_IN_CART,
});

const updateEntireCart = cart => ({
  type: UPDATE_ENTIRE_CART,
  value: cart
});

const updateCart = cart => dispatch => (
  dispatch(updateEntireCart(cart))
);

const addItemToCart = (item, reset) => dispatch => {
  if (reset) {
    dispatch(resetCart());
  }
  dispatch(insertItemToCart(item));
};

const removeItemInCart = item => dispatch => (
  dispatch(deleteItemInCart(item))
);

const resetItemInCart = () => dispatch => (
  dispatch(resetCart())
);

export default {
  addItemToCart,
  removeItemInCart,
  resetItemInCart,
  updateCart,
};