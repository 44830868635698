import { StatusCodes } from 'http-status-codes';

import { SET_MODAL_ALERT, SET_TOP_UP_OPTIONS } from '../actionTypes';
import { apiService } from '../../../library';

const setTopUpOptions = topUpOptions => ({
  type: SET_TOP_UP_OPTIONS,
  value: topUpOptions,
});

const setModalAlert = modal => ({
  type: SET_MODAL_ALERT,
  value: modal
});

const getInfo = () => dispatch => {
  apiService.get('/topup/getInfo').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(setTopUpOptions(data.result));
    } else {
      dispatch(setModalAlert({
        visible: true,
        type: 'error',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const save = (topUpObject, successCallBack) => dispatch => {
  apiService.post('/topup/save', topUpObject).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (successCallBack) {
        successCallBack();
      }
      dispatch(setModalAlert({
        visible: true,
        type: 'topUp',
        data: {
          message: data.msg,
          result: data.result,
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const checkStatus = transferId => dispatch => {
  apiService.get('/topup/checkStatus', `transferId=${transferId}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(setTopUpOptions());
        dispatch(setModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
      } else if (data.state === 5102 || data.state === 5103) {
        /**
         * 1. 5102, 无效订单, 该订单为非充值订单
         * 2.  5103, 该订单已被处理, 处理结果为: + actionStatus
         */
        dispatch(setModalAlert({
          visible: true,
          type: 'error',
          data: {
            message: data.msg,
          }
        }));
      }
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const verify = transferId => dispatch => {
  apiService.get('/topup/verify', `transferId=${transferId}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(setModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
      } else {
        /**
         * 1. 5102, 无效订单, 该订单为非充值订单
         * 2. 5103, 该订单已被处理, 处理结果为: + actionStatus
         * 3. 5104, 无效订单, 该订单为非线下支付订单
         */
        dispatch(setModalAlert({
          visible: true,
          type: 'error',
          data: {
            message: data.msg,
          }
        }));
      }
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const cancel = transferId => dispatch => {
  apiService.get('/topup/cancel', `transferId=${transferId}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(setModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
      } else {
        /**
         * 1. 5102, 无效订单, 该订单为非充值订单
         * 2. 5103, 该订单已被处理, 处理结果为: + actionStatus
         */
        dispatch(setModalAlert({
          visible: true,
          type: 'error',
          data: {
            message: data.msg,
          }
        }));
      }
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

export default {
  getInfo,
  save,
  checkStatus,
  verify,
  cancel,
};