import gameItemIconMap from '../../../GameItemIcon';

import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const SJDX = [
  {
    componentType: CollapseType,
    header: '三军、大小',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper PK10ItemWrapperGY',
      list: [
        {
          code: 'SJ11',
          image: gameItemIconMap.CJ_DICE_1,
          displayName: '1',
        }, {
          code: 'SJ13',
          image: gameItemIconMap.CJ_DICE_3,
          displayName: '3',
        }, {
          code: 'SJ15',
          image: gameItemIconMap.CJ_DICE_5,
          displayName: '5',
        }, {
          code: 'DX48',
          displayName: '大',
        }, {
          code: 'SJ12',
          image: gameItemIconMap.CJ_DICE_2,
          displayName: '2',
        }, {
          code: 'SJ14',
          image: gameItemIconMap.CJ_DICE_4,
          displayName: '4',
        }, {
          code: 'SJ16',
          image: gameItemIconMap.CJ_DICE_6,
          displayName: '6',
        }, {
          code: 'DX49',
          displayName: '小',
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '围骰、全骰',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper PK10ItemWrapperGY',
      list: [
        {
          code: 'WS7',
          image: [gameItemIconMap.CJ_DICE_1, gameItemIconMap.CJ_DICE_1, gameItemIconMap.CJ_DICE_1],
          displayName: '111',
        }, {
          code: 'WS9',
          image: [gameItemIconMap.CJ_DICE_3, gameItemIconMap.CJ_DICE_3, gameItemIconMap.CJ_DICE_3],
          displayName: '333',
        }, {
          code: 'WS11',
          image: [gameItemIconMap.CJ_DICE_5, gameItemIconMap.CJ_DICE_5, gameItemIconMap.CJ_DICE_5],
          displayName: '555',
        }, {
          code: 'WS8',
          image: [gameItemIconMap.CJ_DICE_2, gameItemIconMap.CJ_DICE_2, gameItemIconMap.CJ_DICE_2],
          displayName: '222',
        }, {
          code: 'WS10',
          image: [gameItemIconMap.CJ_DICE_4, gameItemIconMap.CJ_DICE_4, gameItemIconMap.CJ_DICE_4],
          displayName: '444',
        }, {
          code: 'WS12',
          image: [gameItemIconMap.CJ_DICE_6, gameItemIconMap.CJ_DICE_6, gameItemIconMap.CJ_DICE_6],
          displayName: '666',
        }, {
          code: 'QS50',
          displayName: '全骰',
        },
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '点数',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper PK10ItemWrapperGY',
      list: [
        {
          code: 'DS34',
          displayName: '4点',
        }, {
          code: 'DS38',
          displayName: '8点',
        }, {
          code: 'DS42',
          displayName: '12点',
        }, {
          code: 'DS46',
          displayName: '16点',
        }, {
          code: 'DS35',
          displayName: '5点',
        }, {
          code: 'DS39',
          displayName: '9点',
        }, {
          code: 'DS43',
          displayName: '13点',
        }, {
          code: 'DS47',
          displayName: '17点',
        }, {
          code: 'DS36',
          displayName: '6点',
        }, {
          code: 'DS40',
          displayName: '10点',
        }, {
          code: 'DS44',
          displayName: '14点',
        }, {
          code: 'DS37',
          displayName: '7点',
        }, {
          code: 'DS41',
          displayName: '11点',
        }, {
          code: 'DS45',
          displayName: '15点',
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '长牌',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper PK10ItemWrapperGY',
      list: [
        {
          code: 'CP13',
          image: [gameItemIconMap.CJ_DICE_1, gameItemIconMap.CJ_DICE_2],
          displayName: '12',
        }, {
          code: 'CP18',
          image: [gameItemIconMap.CJ_DICE_2, gameItemIconMap.CJ_DICE_3],
          displayName: '23',
        }, {
          code: 'CP23',
          image: [gameItemIconMap.CJ_DICE_3, gameItemIconMap.CJ_DICE_5],
          displayName: '35',
        }, {
          code: 'CP14',
          image: [gameItemIconMap.CJ_DICE_1, gameItemIconMap.CJ_DICE_3],
          displayName: '13',
        }, {
          code: 'CP19',
          image: [gameItemIconMap.CJ_DICE_2, gameItemIconMap.CJ_DICE_4],
          displayName: '24',
        }, {
          code: 'CP24',
          image: [gameItemIconMap.CJ_DICE_3, gameItemIconMap.CJ_DICE_6],
          displayName: '36',
        }, {
          code: 'CP15',
          image: [gameItemIconMap.CJ_DICE_1, gameItemIconMap.CJ_DICE_4],
          displayName: '14',
        }, {
          code: 'CP20',
          image: [gameItemIconMap.CJ_DICE_2, gameItemIconMap.CJ_DICE_5],
          displayName: '25',
        }, {
          code: 'CP25',
          image: [gameItemIconMap.CJ_DICE_4, gameItemIconMap.CJ_DICE_5],
          displayName: '45',
        }, {
          code: 'CP16',
          image: [gameItemIconMap.CJ_DICE_1, gameItemIconMap.CJ_DICE_5],
          displayName: '15',
        }, {
          code: 'CP21',
          image: [gameItemIconMap.CJ_DICE_2, gameItemIconMap.CJ_DICE_6],
          displayName: '26',
        }, {
          code: 'CP26',
          image: [gameItemIconMap.CJ_DICE_4, gameItemIconMap.CJ_DICE_6],
          displayName: '46',
        }, {
          code: 'CP16',
          image: [gameItemIconMap.CJ_DICE_1, gameItemIconMap.CJ_DICE_6],
          displayName: '16',
          newLine: true
        }, {
          code: 'CP22',
          image: [gameItemIconMap.CJ_DICE_3, gameItemIconMap.CJ_DICE_4],
          displayName: '34',
        }, {
          code: 'CP27',
          image: [gameItemIconMap.CJ_DICE_5, gameItemIconMap.CJ_DICE_6],
          displayName: '56',
        },
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '短牌',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper PK10ItemWrapperGY',
      list: [
        {
          code: 'DP28',
          image: [gameItemIconMap.CJ_DICE_1, gameItemIconMap.CJ_DICE_1],
          displayName: '11',
        }, {
          code: 'DP30',
          image: [gameItemIconMap.CJ_DICE_3, gameItemIconMap.CJ_DICE_3],
          displayName: '33',
        }, {
          code: 'DP32',
          image: [gameItemIconMap.CJ_DICE_5, gameItemIconMap.CJ_DICE_5],
          displayName: '55',
        }, {
          code: 'DP29',
          image: [gameItemIconMap.CJ_DICE_2, gameItemIconMap.CJ_DICE_2],
          displayName: '22',
        }, {
          code: 'DP31',
          image: [gameItemIconMap.CJ_DICE_4, gameItemIconMap.CJ_DICE_4],
          displayName: '44',
        }, {
          code: 'DP33',
          image: [gameItemIconMap.CJ_DICE_6, gameItemIconMap.CJ_DICE_6],
          displayName: '66',
        }
      ]
    }
  }
];

export default SJDX;