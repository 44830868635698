import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';
import PengAndDan from '../../../../components/Mobile/ComponentType/PengAndDan';
import TeMaAndLianMa from '../../../../components/Mobile/ComponentType/TeMaAndLianMa';

const EZDW = [
  {
    componentType: TeMaAndLianMa,
    data: {
      list: [
        {
          displayName: '万千',
          numberOfSelectionBlock: 2,
          code: 'DW2WQ',
          minimumSelection: 2,
          maximumSelection: 20,
          children: [{
            code: 'DW2WQ'
          }]
        },
        {
          displayName: '万佰',
          numberOfSelectionBlock: 2,
          code: 'DW2WB',
          minimumSelection: 3,
          maximumSelection: 20,
          children: [{
            code: 'DW2WB'
          }]
        },
        {
          displayName: '万拾',
          numberOfSelectionBlock: 2,
          code: 'DW2WS',
          minimumSelection: 2,
          maximumSelection: 20,
          children: [{
            code: 'DW2WS'
          }]
        },
        {
          displayName: '万个',
          numberOfSelectionBlock: 2,
          code: 'DW2WG',
          minimumSelection: 3,
          maximumSelection: 20,
          children: [{
            code: 'DW2WG'
          }]
        },
        {
          displayName: '千佰',
          numberOfSelectionBlock: 2,
          code: 'DW2QB',
          minimumSelection: 2,
          maximumSelection: 20,
          children: [{
            code: 'DW2QB'
          }]
        },
        {
          displayName: '千拾',
          numberOfSelectionBlock: 2,
          code: 'DW2QS',
          minimumSelection: 3,
          maximumSelection: 20,
          children: [{
            code: 'DW2QS'
          }]
        }, {
          displayName: '千个',
          numberOfSelectionBlock: 2,
          code: 'DW2QG',
          minimumSelection: 2,
          maximumSelection: 20,
          children: [{
            code: 'DW2QG'
          }]
        },
        {
          displayName: '佰拾',
          numberOfSelectionBlock: 2,
          code: 'DW2BS',
          minimumSelection: 3,
          maximumSelection: 20,
          children: [{
            code: 'DW2BS'
          }]
        }, {
          displayName: '佰个',
          numberOfSelectionBlock: 2,
          code: 'DW2BG',
          minimumSelection: 2,
          maximumSelection: 20,
          children: [{
            code: 'DW2BG'
          }]
        },
        {
          displayName: '拾个',
          numberOfSelectionBlock: 2,
          code: 'DW2SG',
          minimumSelection: 3,
          maximumSelection: 20,
          children: [{
            code: 'DW2SG'
          }]
        },
      ]
    }
  },
  {
    componentType: PengAndDan,
    data: {}
  },
  {
    componentType: CollapseType,
    header: '万位',
    headerStyle: '',
    selectionBlockIndex: 0,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      kuaiJieSelectedGroupIndex: 0,
      list: [
        {
          drawImgClassName: 'circle',
          displayName: '0'
        },
        {
          drawImgClassName: 'circle',
          displayName: '1'
        },
        {
          drawImgClassName: 'circle',
          displayName: '2'
        },
        {
          drawImgClassName: 'circle',
          displayName: '3'
        },
        {
          drawImgClassName: 'circle',
          displayName: '4'
        },
        {
          drawImgClassName: 'circle',
          displayName: '5'
        },
        {
          drawImgClassName: 'circle',
          displayName: '6'
        },
        {
          drawImgClassName: 'circle',
          displayName: '7'
        },
        {
          drawImgClassName: 'circle',
          displayName: '8'
        },
        {
          drawImgClassName: 'circle',
          displayName: '9'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '千位',
    headerStyle: '',
    selectionBlockIndex: 1,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      kuaiJieSelectedGroupIndex: 1,
      list: [
        {
          drawImgClassName: 'circle',
          displayName: '0'
        },
        {
          drawImgClassName: 'circle',
          displayName: '1'
        },
        {
          drawImgClassName: 'circle',
          displayName: '2'
        },
        {
          drawImgClassName: 'circle',
          displayName: '3'
        },
        {
          drawImgClassName: 'circle',
          displayName: '4'
        },
        {
          drawImgClassName: 'circle',
          displayName: '5'
        },
        {
          drawImgClassName: 'circle',
          displayName: '6'
        },
        {
          drawImgClassName: 'circle',
          displayName: '7'
        },
        {
          drawImgClassName: 'circle',
          displayName: '8'
        },
        {
          drawImgClassName: 'circle',
          displayName: '9'
        }
      ]
    }
  }
];

export default EZDW;