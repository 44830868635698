const PK10 = {
  lotterySpecific: gameData => {
    const { interval, lotteryBeginTimeBase, lotteryEndTimeBase, lotteryName, code, lotteryTermCycle } = gameData || {};
    const endTimeString = lotteryEndTimeBase ? `开到凌晨${lotteryEndTimeBase}` : '';
    const intervalString = interval % 60 === 0 ? `${interval / 60}分` : `${interval}秒`;
    switch (code) {
      case 'JSSCPK10':
      case 'JSXYFTPK10':
        return `“${lotteryName}”是引进欧洲博彩公司开奖设备，由菲律宾博彩执照公司审核监控，联合开发的彩票游戏，保证公平公正；您可以放心投注、开奖时间，北京时间（GMT+8）每天白天从早上${lotteryBeginTimeBase}${endTimeString}，每${intervalString}开一次奖,每天开奖${lotteryTermCycle}期。`;
      case 'AUXYSCPK10':
        return `该游戏的投注时间、开奖时间和开奖号码与澳洲Lucky 10 Lotto完全同步。您可以放心投注，开奖时间：北京时间（GMT+8）每天白天从早上${lotteryBeginTimeBase}开到凌晨04:00:00，每${intervalString}开一次奖,每天开奖${lotteryTermCycle}期。`;
      default:
        return `以下所有投注皆含本金\n该游戏保证公平公正；您可以放心投注、开奖时间，北京时间（GMT+8）每天从${lotteryBeginTimeBase}开始，每${intervalString}开一次奖,每天开奖${lotteryTermCycle}期。`;
    }
  },
  templateSpecific: () => (
    [
      {
        header: '1～10 两面：指 单、双；大、小。',
        body: [
          '单、双：号码为双数叫双，如4、8；号码为单数叫单，如5、9。',
          '大、小：开出之号码大于或等于6为大，小于或等于5为小。',
          '第一名～第十名 车号指定：每一个车号为一投注组合，开奖结果“投注车号”对应所投名次视为中奖，其余情形视为不中奖。'
        ]
      },
      {
        header: '1～5龙虎',
        body: [
          '冠  军 龙/虎：“第一名”车号大于“第十名”车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。',
          '亚  军 龙/虎：“第二名”车号大于“第九名”车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。',
          '第三名 龙/虎：“第三名”车号大于“第八名”车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。',
          '第四名 龙/虎：“第四名”车号大于“第七名”车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。',
          '第五名 龙/虎：“第五名”车号大于“第六名”车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。',
        ]
      },
      {
        header: '冠军车号＋亚军车号＝冠亚和值（为3~19)',
        body: [
          '冠亚和单双：“冠亚和值”为单视为投注“单”的注单视为中奖，为双视为投注“双”的注单视为中奖，其余视为不中奖。',
          '冠亚和大小：“冠亚和值”大于11时投注“大”的注单视为中奖，小于或等于11时投注“小”的注单视为中奖，其余视为不中奖。',
          '冠亚和指定：“冠亚和值”可能出现的结果为3～19， 投中对应“冠亚和值”数字的视为中奖，其余视为不中奖。',
        ]
      }
    ]),
};

export default PK10;