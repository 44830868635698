import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Label from '../../Label';
import { cartActions } from '../../../redux/actions';
import { validateNumber } from '../../../constants/utils';
import './styles.scss';

const mapStateToProps = state => ({
  gameInfo: state.placeBetReducers.gameInfo,
  sectionSelection: state.gameReducers.sectionSelection,
});

const mapDispatchToProps = dispatch => ({
  addItemToCart: item => dispatch(cartActions.addItemToCart(item)),
  resetItemInCart: () => dispatch(cartActions.resetItemInCart()),
});

const ShortCutInput = ({ list, padLeadingZero, gameInfo, addItemToCart, resetItemInCart, sectionSelection }) => {
  const [_shortCutValue, setShortCutValue] = useState();
  const [_sectionSelection, setSectionSelection] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (JSON.stringify(sectionSelection) !== JSON.stringify(_sectionSelection)) {
      setSectionSelection(sectionSelection);
    }
  }, [sectionSelection]);

  const onShortCutInputChange = ({ target: { value: text } }) => {
    if (text === ' ') return setShortCutValue('');
    resetItemInCart();
    const selectionArr = text.split(' ');
    selectionArr.forEach(item => {
      const selectionItemArr = item.split('=');
      const listItem = list.find(x => x.displayName === (padLeadingZero ? padLeadingZeros(selectionItemArr[0], padLeadingZero) : selectionItemArr[0]));
      if (listItem && selectionItemArr.filter(x => x !== '').length === 2) {
        const keyCode = `${_sectionSelection.value}_${listItem.code}`;
        const { midType, odds, enabled } = gameInfo.oddsList[keyCode] || {};
        if (enabled === 0) return;

        addItemToCart({
          [`${sectionSelection.value}_${listItem.code}`]: {
            displayName: listItem.displayName,
            amount: validateNumber(selectionItemArr[1]),
            keyCode: `${sectionSelection.value}_${listItem.code}`,
            midType,
            odds,
          }
        });
      }
    });

    setShortCutValue(validateNumber(text, ['=', ''], true));
  };

  const padLeadingZeros = (num, size) => {
    let s = `${num}`;
    while (s.length < size) s = `0${s}`;
    return s;
  };

  return (
    <Row className="shortCutInputWrapper mx-0">
      <Col md="3" className="justify-content-end d-flex align-items-center">
        <Label className="title" value={t('SHORTCUT_SELECT_BET')} />
      </Col>
      <Col md="7" className="justify-content-center d-flex align-items-center">
        <Input value={_shortCutValue} placeholder={t('SHORTCUT_INPUT_PLACEHOLDER')} onChange={onShortCutInputChange} />
      </Col>
      <Col md="2" className="justify-content-start d-flex align-items-center">
        <Button
          type="button"
          className="resetBtn p-0"
          onClick={() => {
            resetItemInCart();
            setShortCutValue('');
          }}
        >
          {t('RESET')}
        </Button>
      </Col>
    </Row>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShortCutInput);