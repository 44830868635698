import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Label from '../../Label';
import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const LatestAnnouncementModal = ({ platform, updateModalAlert, data: { latestNews } }) => {
  const [_latestNews, setLatestNews] = useState();
  const [_doNotRemind, setDoNotRemind] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (JSON.stringify(_latestNews) !== JSON.stringify(latestNews)) {
      setLatestNews(latestNews);
    }
  }, [latestNews]);

  return (
    <div className={`latestAnnouncementWrapper${platform}`}>
      <Row className="modalHeader">
        {t('IMPORTANT_NOTICE')}
      </Row>
      <Row className="modalContent p-3">
        <Col className="contentWrapper">
          <Row>
            <Label className="mb-2 title" value={`● ${(_latestNews && _latestNews.title) || ''}`} />
          </Row>
          <Row>
            <Label value={(_latestNews && _latestNews.content) || ''} />
          </Row>
        </Col>
      </Row>
      <Row className="modalFooter p-3">
        <Col xs="6" className="justify-content-start align-items-center pl-2 d-flex">
          <div className="doNotRemindTitle" onClick={() => setDoNotRemind(!_doNotRemind)}>
            <Input type="checkbox" className="m-0 position-relative mr-2 doNotRemindTitle" checked={_doNotRemind} />
            <Label value={t('DO_NOT_REMIND_TODAY')} />
          </div>
        </Col>
        <Col xs="6" className="justify-content-center d-flex">
          <Button
            type="button"
            className="acknowledgeButton p-0 mr-2"
            onClick={() => {
              if (_doNotRemind) {
                const balanceSecond = moment().endOf('day').unix() - moment().unix();
                document.cookie = `doNotRemind=${_doNotRemind}; max-age=${balanceSecond}`;
              }
              updateModalAlert({
                visible: false
              });
            }}
          >
            {t('MODAL_ACKNOWLEDGE')}
          </Button>
          <Button
            type="button"
            className="detailButton p-0"
            onClick={() => {
              if (_doNotRemind) {
                const balanceSecond = moment().endOf('day').unix() - moment().unix();
                document.cookie = `doNotRemind=${_doNotRemind}; max-age=${balanceSecond}`;
              }
              updateModalAlert({
                visible: false
              });
              history.push({
                pathname: 'announcement',
                state: {
                  type: 'news'
                }
              });
            }}
          >
            {t('CHECK_OUT_DETAIL')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LatestAnnouncementModal);