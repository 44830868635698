import { renderResultDateCell } from '../../utils';

import HM1 from './HM1';
import HM18 from './HM18';
import HM2 from './HM2';
import HM3 from './HM3';
import HM4 from './HM4';
import HM5 from './HM5';
import HM6 from './HM6';
import HM7 from './HM7';
import HM8 from './HM8';
import LIANMA from './LIANMA';
import ZhengHe from './ZhengHe';
import ZhengMa from './ZhengMa';

const renderQiu = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  return result.map(item => (
    {
      value: item, className: `KL10_ball  ${item >= 19 ? 'ball_red' : ''}`
    }
  ));
};

const renderZH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  let championSum = 0;
  result.forEach(item => (championSum += parseInt(item, 10)));

  tempResult.push({
    value: championSum,
    className: 'redText',
  });

  tempResult.push({
    value: championSum > 80 ? '大' : '小',
    className: championSum > 80 ? 'resultItem resultItemBlue' : 'resultItem resultItemOrange',
  });

  tempResult.push({
    value: championSum % 2 ? '单' : '双',
    className: championSum % 2 ? 'resultItem resultItemOrange' : 'resultItem resultItemBlue',
  });

  tempResult.push({
    value: championSum % 10 >= 5 ? '尾大' : '尾小',
    className: championSum % 10 >= 5 ? 'resultItem resultItemBlue' : 'resultItem resultItemOrange',
  });
  return tempResult;
};

const renderLH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  for (let i = 0; i < result.length / 2; i++) {
    if ((parseInt(result[i], 10)) > parseInt(result[result.length - 1 - i], 10)) {
      tempResult.push({
        value: '龙',
        className: 'resultItem resultItemBlue',
      });
    } else {
      tempResult.push({
        value: '虎',
        className: 'resultItem resultItemOrange',
      });
    }
  }
  return tempResult;
};

const KL10 = {
  children: [
    {
      tabId: 'ZhengHe',
      label: '整合',
      children: ZhengHe.ZhengHe,
      settingCode: 'ZHDX,ZHDS,ZHDWDX,LM'
    },
    {
      tabId: 'HM18',
      label: '单球1~8',
      children: HM18.HM18,
      settingCode: 'QH18'
    },
    {
      tabId: 'HM1',
      label: '第一球',
      children: HM1.HM1,
      midCode: 'B1M,B1LM,B1ZFB,B1FW'
    },
    {
      tabId: 'HM2',
      label: '第二球',
      children: HM2.HM2,
      midCode: 'B2M,B2LM,B2ZFB,B2FW'
    }, {
      tabId: 'HM3',
      label: '第三球',
      children: HM3.HM3,
      midCode: 'B3HM,B3LM,B3ZFB,B3FW'
    }, {
      tabId: 'HM4',
      label: '第四球',
      children: HM4.HM4,
      midCode: 'B4HM,B4LM,B4ZFB,B4FW'
    }, {
      tabId: 'HM5',
      label: '第五球',
      children: HM5.HM5,
      midCode: 'B5HM,B5LM,B5ZFB,B5FW'
    }, {
      tabId: 'HM6',
      label: '第六球',
      children: HM6.HM6,
      midCode: 'B6HM,B6LM,B6ZFB,B6FW'
    }, {
      tabId: 'HM7',
      label: '第七球',
      children: HM7.HM7,
      midCode: 'B7HM,B7LM,B7ZFB,B7FW'
    }, {
      tabId: 'HM8',
      label: '第八球',
      children: HM8.HM8,
      midCode: 'B8HM,B8LM,B8ZFB,B8FW'
    },
    {
      tabId: 'ZhengMa',
      label: '正码',
      children: ZhengMa.ZhengMa,
      settingCode: 'ZM,ZHDX,ZHDWDX,ZHDS'
    },
    {
      tabId: 'LianMa',
      label: '连码',
      children: LIANMA.LIANMA,
      settingCode: 'LIANMA'
    }
  ],
  resultTab: {
    headerCell: [{
      attr: '期数',
      wrapperStyle: 'kl10Header'
    }, {
      attr: '日期',
      wrapperStyle: 'kl10Header'
    }, {
      attr: '彩球号码',
      wrapperStyle: 'kl10Header'
    }, {
      attr: '总和',
      wrapperStyle: 'kl10Header'
    }, {
      attr: '1-4球龙虎',
      wrapperStyle: 'kl10Header'
    }],
    bodyCell: [{
      attr: 'installments',
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderResultDateCell,
      wrapperStyle: 'dateCol'
    }, {
      attr: renderQiu,
      wrapperStyle: 'd-flex justify-content-center align-items-center kl10Num'
    }, {
      attr: renderZH,
      wrapperStyle: 'd-flex justify-content-center align-items-center pk10GYH'
    }, {
      attr: renderLH,
      wrapperStyle: 'd-flex justify-content-center align-items-center pk10LH'
    }],
  },
  results: {
    TabList: [{
      code: 'NUMBER',
      renderFunc: data => renderQiu(data)
    },
    {
      code: 'SUM',
      renderFunc: data => renderZH(data)
    },
    {
      code: '_14LH',
      renderFunc: data => renderLH(data)
    }]
  }
};

export default KL10;