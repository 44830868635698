import HM from './HM';
import LIANMA from './LIANMA';
import LM from './LM';
import Q2ZX from './Q2ZX';
import Q3ZX from './Q3ZX';

const CJ_11X5 = {
  children: [
    {
      tabId: 'LIANGMIAN',
      label: '两面',
      children: LM.LM,
      settingCode: 'LM,ZHWDX,ZHDS'
    },
    {
      tabId: 'HM',
      label: '单号',
      children: HM.HM,
      midCode: 'YZY,B1LM,B1QH,B2QH,B2LM,B3QH,B3LM,B4QH,B4LM,B5QH,B5LM'
    },
    {
      tabId: 'LIANMA11X5',
      label: '连码',
      children: LIANMA.LIANMA,
      settingCode: 'LIANMA'
    },
    {
      tabId: 'Q2ZX',
      label: '前二直选',
      children: Q2ZX.Q2ZX,
    },
    {
      tabId: 'Q3ZX',
      label: '前三直选',
      children: Q3ZX.Q3ZX
    },
  ],
  results: {
    header: [{
      value: '开奖结果'
    }],
    TabList: [{
      code: 'NUMBER',
      renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
        value: item,
      }))
    },
    {
      code: 'BIG_SMALL',
      renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
        value: item <= 5 ? '小' : '大',
        className: item[item.length - 1] <= 5 ? 'smallValue' : '',
      }))
    },
    {
      code: 'ODD_EVEN',
      renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
        value: item % 2 ? '单' : '双',
        className: item % 2 ? '' : 'even'
      }))
    }]
  }
};

export default CJ_11X5;