import { SET_LATEST_ANNOUNCEMENT, SET_NEWS_LIST } from '../../actions/actionTypes';

const initialState = {
  newsList: undefined,
  latestAnnouncement: undefined,
};

const noticeReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEWS_LIST:
      return {
        ...state, newsList: action.value
      };
    case SET_LATEST_ANNOUNCEMENT:
      return {
        ...state, latestAnnouncement: action.value
      };
    default:
      return state;
  }
};

export default noticeReducers;