import React from 'react';

import { connect } from 'react-redux';

import Footer1 from '../../../../assets/image/home/footer-1.png';
import Footer2 from '../../../../assets/image/home/footer-2.png';
import Footer3 from '../../../../assets/image/home/footer-3.png';
import Footer4 from '../../../../assets/image/home/footer-4.png';

import './styles.scss';

const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
});

const footerData = [
  {
    title: '合作平台商', image: Footer1,
  },
  {
    title: '合作支付商', image: Footer2
  },
  {
    title: '游戏责任', image: Footer3
  },
  {
    title: '技术支持', image: Footer4
  },
];

const Footer = () => (
  <div className="homeFooterWrapper mt-5">
    <div className="homeFooter">
      <div className="row justify-content-between align-items-center px-2 py-5">
        {footerData.map((item, index) => (
          <div key={`${index.toString()}`} className="col-3 text-start text-white font-weight-bold">
            <div className="d-flex flex-column">
              <div className="mb-4">{item.title}</div>
              <div><img src={item.image} alt="companyLogo" srcSet="" /></div>
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex align-items-center p-4 aboutUs">
        <div>
          {`Copyright @ ${new Date().getFullYear()} 星辉科技`}
        </div>
        <div className="flex-grow-1" />
        {['关于我们', '博彩声明', '博彩责任', '用户协议', '隐私条款', '帮助中心'].map(item => <div key={item} className="px-3">{item}</div>)}
      </div>
    </div>
  </div>
);

export default connect(mapStateToProps, null)(Footer);