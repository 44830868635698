const FANT = {
  lotterySpecific: (lotteryCode, lotteryName) => {
    switch (lotteryCode) {
      case 'JSSSC':
        return `"${lotteryName}”是引进欧洲博彩公司开奖设备，由菲律宾博彩执照公司审核监控，联合开发的彩票游戏，保证公平公正；您可以放心投注、开奖时间，北京时间（GMT+8）每天白天从早上07:29:10开到凌晨03:59:10，每75秒开一次奖,每天开奖985期。`;
      default:
        return '';
    }
  },
  templateSpecific: () => ([{
    header: '1.正',
    body: [
      '于自1~4任选1号进行投注，当开奖结果与所选的号码相同时，即为中奖。',
      '开出号码加总除四馀数为1，中奖为1，不中奖为3，其他视为和局；',
      '开出号码加总除四馀数为2，中奖为2，不中奖为4，其他视为和局；',
      '开出号码加总除四馀数为3，中奖为3，不中奖为1，其他视为和局；',
      '开出号码加总除四馀数为0，中奖为4，不中奖为2，其他视为和局。',
    ]
  }, {
    header: '2.番',
    body: [
      '于自1~4任选1号进行投注，当开奖结果与所选的号码相同时，即为中奖。',
      '开出号码加总除四馀数为1，中奖为1，其他视为不中奖；',
      '开出号码加总除四馀数为2，中奖为2，其他视为不中奖；',
      '开出号码加总除四馀数为3，中奖为3，其他视为不中奖；',
      '开出号码加总除四馀数为0，中奖为4，其他视为不中奖。',
    ]
  }, {
    header: '3.角',
    body: [
      '于自1-2，2-3，3-4，1-4任选1个进行投注，当开奖结果与所选的号码相同时，即为中奖。',
      '开出号码加总除四馀数为1，中奖为1-2角、1-4角，其他视为不中奖；',
      '开出号码加总除四馀数为2，中奖为2-3角、1-2角，其他视为不中奖；',
      '开出号码加总除四馀数为3，中奖为2-3角、3-4角，其他视为不中奖；',
      '开出号码加总除四馀数为0，中奖为1-4角、3-4角，其他视为不中奖。',
    ]
  }, {
    header: '4.念',
    body: [
      '于自A念B，任选1个进行投注，当开奖结果与A号码相同时，即为中奖，当开奖结果与B号码相同时，则为和局，其他视为不中奖。',
      '开出号码加总除四馀数为1，中奖为1念2、1念3、1念4，和局为2念1、3念1、4念1，其他视为不中奖；',
      '开出号码加总除四馀数为2，中奖为2念1、2念3、2念4，和局为1念2、3念2、4念2，其他视为不中奖；',
      '开出号码加总除四馀数为3，中奖为3念1、3念2、3念4，和局为1念3、2念3、4念3，其他视为不中奖；',
      '开出号码加总除四馀数为0，中奖为4念1、4念2、4念3，和局为1念4、2念4、3念4，其他视为不中奖。',
    ]
  }, {
    header: '5.单，双',
    body: [
      '于自单或双任选1个进行投注，当开奖结果为单数则单数中奖，其他视为不中奖。当开奖结果为双数则双数中奖，其他视为不中奖。',
    ]
  }, {
    header: '6.三門',
    body: [
      '投注三个号码，如果开出的结果是三个号码之一，则中奖，其余为输，如：',
      '开出的号码加总除四余数为1,中奖号码为134中,124中,123中,不中奖为234中；',
      '开出的号码加总除四余数为2,中奖号码为234中,124中,123中,不中奖为134中；',
      '开出的号码加总除四余数为3,中奖号码为234中,134中,123中,不中奖为124中；',
      '开出的号码加总除四余数为0,中奖号码为234中,134中,124中,不中奖为123中。',
    ]
  }, {
    header: '7.大，小',
    body: [
      '于自大或小任选1个进行投注。当开奖结果为1、2则为小中奖，其他视为不中奖。当开奖结果为3、4则为大中奖，其他视为不中奖。',
    ]
  }])
};

export default FANT;