import Lottery from '../../assets/image/subMenu/lottery-1.png';
import Lottery2 from '../../assets/image/subMenu/lottery-2.png';
import Lottery3 from '../../assets/image/subMenu/lottery-3.png';
import LotteryBackground from '../../assets/image/subMenu/lottery-background.png';

import SportsBackground from '../../assets/image/subMenu/sports-background.png';
import SportsCharacter from '../../assets/image/subMenu/sports-character.png';
import SportsCharacter2 from '../../assets/image/subMenu/sports-character-2.png';
import SportsCharacter3 from '../../assets/image/subMenu/sports-character-3.png';
import SportsCharacter4 from '../../assets/image/subMenu/sports-character-4.png';

import GamesBackground from '../../assets/image/subMenu/games-background.png';
import GamesCharacter from '../../assets/image/subMenu/games-character.png';
import GamesCharacter2 from '../../assets/image/subMenu/games-character-2.png';
import GamesCharacter3 from '../../assets/image/subMenu/games-character-3.png';


import CasinoBackground from '../../assets/image/subMenu/casino-background.png';
import CasinoCharacter from '../../assets/image/subMenu/casino-character.png';
import CasinoCharacter2 from '../../assets/image/subMenu/casino-character-2.png';
import CasinoCharacter3 from '../../assets/image/subMenu/casino-character-3.png';
import CasinoCharacter4 from '../../assets/image/subMenu/casino-character-4.png';

const SubMenuConstant = {
  lotteryList: () => ([
    {
      title: '六合彩', img: LotteryBackground, character: Lottery, left: '-34px', bottom: '2px'
    },
    {
      title: '极速彩', img: LotteryBackground, character: Lottery3, left: '-10px', bottom: '2px'
    },
    {
      title: '幸运彩', img: LotteryBackground, character: Lottery2, left: '-14px', bottom: '2px'
    }
  ]),

  sportsList: () => ([
    {
      title: 'OG 体育', img: SportsBackground, character: SportsCharacter, left: '4px', bottom: '2px'
    },
    {
      title: '沙巴体育', img: SportsBackground, character: SportsCharacter2, left: '-26px', bottom: '2px'
    },
    {
      title: 'MG 体育', img: SportsBackground, character: SportsCharacter3, left: '-18px', bottom: '2px'
    },
    {
      title: 'PM 体育', img: SportsBackground, character: SportsCharacter4, left: '6px', bottom: '0'
    },
  ]),

  gamesList: () => ([
    {
      title: 'OG 棋牌', img: GamesBackground, character: GamesCharacter, left: '-30px', bottom: '2px'
    },
    {
      title: '双赢棋牌', img: GamesBackground, character: GamesCharacter2, left: '-4px', bottom: '2px'
    },
    {
      title: '王者棋牌', img: GamesBackground, character: GamesCharacter3, left: '4px', bottom: '2px'
    }
  ]),
  casinoList: () => ([
    {
      title: 'PM 真人', img: CasinoBackground, character: CasinoCharacter, left: '-20px', bottom: '2px'
    },
    {
      title: 'AG 真人', img: CasinoBackground, character: CasinoCharacter2, left: '-26px', bottom: '2px'
    },
    {
      title: 'BG 真人', img: CasinoBackground, character: CasinoCharacter3, left: '-26px', bottom: '2px'
    },
    {
      title: 'eBET 真人', img: CasinoBackground, character: CasinoCharacter4, left: '-26px', bottom: '2px'
    }
  ])
};

export default SubMenuConstant;