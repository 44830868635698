import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const YXWS = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['生肖', '号码', '赔率', '金额'],
      mainColumnLength: 2,
      subColumnLength: 4,
      subColumnStyle: ['column-4items-label', 'column-4items-balls', 'column-4items-odds', 'column-4items-input'],
      betItemStyle: ['column-4items-label', 'column-4items-array-balls column-4items-balls', 'column-4items-odds', 'column-4items-input'],
      betItemList: ['DisplayText', 'HK6_Zodiac', 'Odds', 'Input'],
      betItemColumn: 4,
      zodiacTotalBall: 49,
      list: [
        {
          code: 'PTYX1', // keyCode
          displayName: '鼠',
        }, {
          code: 'PTYX7',
          displayName: '马',
        }, {
          code: 'PTYX2',
          displayName: '牛',
        }, {
          code: 'PTYX8',
          displayName: '羊',
        }, {
          code: 'PTYX3',
          displayName: '虎',
        }, {
          code: 'PTYX9',
          displayName: '猴',
        }, {
          code: 'PTYX4',
          displayName: '兔',
        }, {
          code: 'PTYX10',
          displayName: '鸡',
        }, {
          code: 'PTYX5',
          displayName: '龙',
        }, {
          code: 'PTYX11',
          displayName: '狗',
        }, {
          code: 'PTYX6',
          displayName: '蛇',
        }, {
          code: 'PTYX12',
          displayName: '猪',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['生肖', '号码', '赔率', '金额'],
      mainColumnLength: 2,
      subColumnLength: 4,
      subColumnStyle: ['column-4items-label', 'column-4items-balls', 'column-4items-odds', 'column-4items-input'],
      betItemStyle: ['column-4items-label', 'column-4items-array-balls column-4items-balls', 'column-4items-odds', 'column-4items-input'],
      betItemList: ['DisplayText', 'HK6_WS', 'Odds', 'Input'],
      betItemColumn: 4,
      list: [
        {
          code: 'PTWS13', // keyCode
          displayName: '0尾',
        }, {
          code: 'PTWS18',
          displayName: '5尾',
        }, {
          code: 'PTWS14',
          displayName: '1尾',
        }, {
          code: 'PTWS19',
          displayName: '6尾',
        }, {
          code: 'PTWS15',
          displayName: '2尾',
        }, {
          code: 'PTWS20',
          displayName: '7尾',
        }, {
          code: 'PTWS16',
          displayName: '3尾',
        }, {
          code: 'PTWS21',
          displayName: '8尾',
        }, {
          code: 'PTWS17',
          displayName: '4尾',
        }, {
          code: 'PTWS22',
          displayName: '9尾',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default YXWS;