import React, { useState } from 'react';

import { Button, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { IoCheckmarkSharp } from 'react-icons/io5';
import tiblue from '../../../assets/Mobile/image/themeImage/blue.jpg';
import tigreen from '../../../assets/Mobile/image/themeImage/green.jpg';
import tired from '../../../assets/Mobile/image/themeImage/red.jpg';
import tiyellow from '../../../assets/Mobile/image/themeImage/orange.jpg';

import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
  themeColor: state.appReducers.themeColor,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  updateThemeColor: themeColor => dispatch(appActions.updateThemeColor(themeColor)),
});

const MobileThemeColorModal = ({ updateModalAlert, updateThemeColor }) => {
  const [activeColor, setActiveColor] = useState(localStorage.getItem('themeColor') || 'blue');
  const { t } = useTranslation();

  const colors = [
    {
      key: 'red',
      image: tired,
    }, {
      key: 'yellow',
      image: tiyellow,
    }, {
      key: 'green',
      image: tigreen,
    }, {
      key: 'blue',
      image: tiblue,
    }
  ];

  const toogleColor = color => {
    if (activeColor !== color) {
      setActiveColor(color);
    }
  };

  const colorList = color => (
    <div key={`theme_color_${color.toString()}`} className={color} onClick={() => toogleColor(color)}>
      {
        color === activeColor && (
          <IoCheckmarkSharp className="h4 mb-0 text-white" />
        )
      }
    </div>
  );

  return (
    <div className="themeColorModalWrapperMobile">
      <Row className="modalContent d-flex justify-content-center">
        <Row className="mb-1 mt-3 p-2 d-flex align-items-center justify-content-center">
          <img
            alt="my ime"
            src={colors.find(color => color.key === activeColor)?.image}
            className="d-flex align-items-center justify-content-center bannerImage"
          />
        </Row>
        <Row className="themeSelectorWrapper mb-2 mt-2 d-flex align-items-center justify-content-center">
          {
            colors.map(item => colorList(item.key))
          }
        </Row>
      </Row>
      <Row className="modalFooter m-0 d-flex align-items-center justify-content-center">
        <Button
          className="confirmButton my-3"
          onClick={() => {
            localStorage.setItem('themeColor', activeColor);
            updateThemeColor(activeColor);
            updateModalAlert({
              visible: false
            });
          }}
        >
          {t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileThemeColorModal);