import React, { useEffect, useState } from 'react';

import { StatusCodes } from 'http-status-codes';

import { motion } from 'framer-motion';

import { useHistory } from 'react-router-dom';

import { FaChevronDown, FaChevronUp, FaEnvelope, FaUserCircle, FaYenSign } from 'react-icons/fa';

import { RiCouponFill } from 'react-icons/ri';

import { MdCardMembership, MdGetApp, MdQuestionAnswer } from 'react-icons/md';

import { BiSupport } from 'react-icons/bi';

import { connect } from 'react-redux';

import { TbRefresh } from 'react-icons/tb';

import { apiService } from '../../../../library';

import { appActions } from '../../../../redux/actions';

import Logo from '../../../../assets/logo/homeLogo.png';

import SubMenu from '../../../../components/Web/SubMenu';
import UserProfile from '../../../../components/Web/UserProfile';

import 'element-theme-default';
import './styles.scss';


const serviceItem = [
  {
    name: '优惠', icon: <RiCouponFill color="#FB9C00" />
  },
  {
    name: '加盟', icon: <MdCardMembership color="#0000FF" />
  },
  {
    name: '客服', icon: <BiSupport color="#008000" />
  },
  {
    name: '常见问题', icon: <MdQuestionAnswer color="#FF829D" />
  },
  {
    name: 'APP', icon: <MdGetApp color="#FF0000" />
  }
];

const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
  userInfo: state.memberReducers.userInfo,
  initData: state.homeReducers.initData,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const date = new Date();
const offsetInMinutes = date.getTimezoneOffset();
const offsetInHours = offsetInMinutes / 60;

const Header = props => {
  const { updateModalAlert, GameIndex, setGameIndex, toggleSubMenu, setToggleSubMenu, userData, userInfo, toggleUserProfile, setToggleUserProfile, initData } = props || {};
  const [unreadMsg, setUnreadMsg] = useState(0);

  useEffect(() => {
    if (initData?.company?.resourseDomain) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = initData.company.resourseDomain;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [initData]);

  useEffect(() => {
    if (userData) {
      const fetchUnreadMsg = () => {
        apiService.get('/cash/UnreadMsg').then(res => {
          const { data, status } = res || {};

          if (status === StatusCodes.OK) {
            setUnreadMsg(data.result);
          }
        }).catch(err => {
          Promise.reject(err);
        });
      };

      fetchUnreadMsg();
      // re-fetch after every 5 minutes
      const intervalId = setInterval(fetchUnreadMsg, 300000);

      return () => clearInterval(intervalId);
    }
  }, [userData]);

  const history = useHistory();

  const onclickToPages = type => {
    switch (type) {
      case 'topup':
        history.push('/user/bank');
        break;
      case 'inbox':
        history.push('/user/announcement');
        break;
      default: history.push('/agreement');
    }
  };

  return (
    <div className="headerGradient">
      <div className="homeHeader" onMouseEnter={() => setToggleUserProfile(false)}>
        <div className="container-fluid">
          <div className="row  align-items-center">
            { (userData && userInfo)
            && (
            <div className="col-12 d-flex justify-content-end">
              <div className="d-flex align-items-center userIcon" onMouseEnter={() => setToggleUserProfile(true)}>
                <FaUserCircle size={25} />
                <div className="ml-1">{userData?.loginId}</div>
                <UserProfile toggleUserProfile={toggleUserProfile} />
              </div>
              <motion.div
                whileHover={{
                  scale: 1.1
                }}
                whileTap={{
                  scale: 0.9
                }}
              >
                <div className="d-flex align-items-center ml-4 py-1 px-2 reloadIcon" onClick={() => onclickToPages('topup')}>
                  <FaYenSign size={10} />
                  <div className="mx-1">{ userInfo?.accountLimit ?? '' }</div>
                  <TbRefresh size={20} color="#cad3e7" />
                </div>
              </motion.div>
              <div className="d-flex align-items-center ml-4 text-secondary font-weight-bold">
                GMT
                {offsetInHours}
              </div>
              <motion.div
                whileHover={{
                  scale: 1.1
                }}
                whileTap={{
                  scale: 0.9
                }}
              >
                <div
                  className="d-flex align-items-center ml-4 text-secondary position-relative cursorPointer"
                  onClick={() => onclickToPages('inbox')}
                >
                  <FaEnvelope size={20} />
                  {unreadMsg > 0 && (
                    <span className="badge badge-pill badge-danger position-absolute notificationBadge">{unreadMsg}</span>
                  )}
                </div>
              </motion.div>
            </div>
            )}
          </div>
          <div className="row mt-2 justify-content-center align-items-center">
            <div className="col-6">
              <div className="row">
                <img className="logoWrapper" src={Logo} alt="logo" srcSet="" />
                <div className="gameWrapper ml-2 d-flex justify-content-center align-items-center">
                  {['首页', '彩票', '体育', '棋牌', '真人'].map((item, index) => (
                    <div
                      key={item}
                      onClick={() => setGameIndex(index)}
                      onMouseEnter={() => {
                        setGameIndex(index);
                        setToggleSubMenu(true);
                      }}
                      className={`d-flex justify-content-center align-items-center mx-3
                      gameItem  ${GameIndex === index && 'selected'}`}
                    >
                      <div>{item}</div>
                      {GameIndex === index && index !== 0 ? <FaChevronUp className="ml-1" size={12} />
                        : index !== 0 ? <FaChevronDown className="ml-1" size={12} /> : ''}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row justify-content-end">
                <div className="serviceWrapper d-flex justify-content-center align-items-center">
                  {serviceItem.map((item, index) => (
                    <motion.div
                      whileHover={{
                        scale: 1.2
                      }}
                      whileTap={{
                        scale: 0.8
                      }}
                      key={`${index.toString()}`}
                    >
                      <div
                        onClick={() => {
                          setGameIndex(index + 5);
                          if (index + 5 === 7) {
                            window.OnlineService();
                          }
                        }}
                        className={`d-flex justify-content-center align-items-center mx-2
                        serviceItem ${GameIndex === (index + 5) && 'selected'}`}
                      >
                        {item.icon}
                        <div className="ml-1">{item.name}</div>
                      </div>
                    </motion.div>
                  ))}
                </div>
                <div className="ml-1 mr-2 mt-2 divider">|</div>
                { userData
                  ? (
                    <div
                      className="homeloginAndRegister d-flex justify-content-center align-items-center"
                      onClick={() => onclickToPages('topup')}
                    >
                      <motion.div
                        whileHover={{
                          scale: 1.1
                        }}
                        whileTap={{
                          scale: 0.9
                        }}
                      >
                        <button type="button" className="btn btn-sm btn-primary">充提</button>
                      </motion.div>
                    </div>
                  )
                  : (
                    <div
                      className="homeloginAndRegister d-flex justify-content-center align-items-center"
                      onClick={() => {
                        updateModalAlert({
                          visible: true,
                          type: 'loginAndRegister',
                        });
                      }}
                    >
                      <motion.div
                        whileHover={{
                          scale: 1.1
                        }}
                        whileTap={{
                          scale: 0.9
                        }}
                      >
                        <button type="button" className="btn btn-sm btn-primary">登入/注册</button>
                      </motion.div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SubMenu GameIndex={GameIndex} toggleSubMenu={toggleSubMenu} />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);