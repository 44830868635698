import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';
import { renderHeXiaoBalls } from '../../../utils';

const ZHENGXIAO = [
  {
    componentType: CollapseType,
    header: '正肖',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper HK6TeXiao',
      list: [
        {
          code: 'ZENGX13', // keyCode
          displayName: '鼠',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'ZENGX19',
          displayName: '马',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'ZENGX14',
          displayName: '牛',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'ZENGX20',
          displayName: '羊',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'ZENGX15',
          displayName: '虎',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'ZENGX21',
          displayName: '猴',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'ZENGX16',
          displayName: '兔',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'ZENGX22',
          displayName: '鸡',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'ZENGX17',
          displayName: '龙',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'ZENGX23',
          displayName: '狗',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'ZENGX18',
          displayName: '蛇',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'ZENGX24',
          displayName: '猪',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }
      ]
    }
  }
];

export default ZHENGXIAO;