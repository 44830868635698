import React, { useEffect, useState } from 'react';

const Odds = ({ displayName, code, gameInfo, className }) => {
  const [_gameInfo, setGameInfo] = useState();

  useEffect(() => {
    if (JSON.stringify(_gameInfo) !== JSON.stringify(gameInfo)) {
      setGameInfo(gameInfo);
    }
  }, [gameInfo]);

  return (
    <div className={className}>
      {displayName && <span className="displayText mr-1">{displayName}</span>}
      <span>{(_gameInfo && _gameInfo.oddsList && _gameInfo.oddsList[code] && _gameInfo.oddsList[code].enabled && _gameInfo.oddsList[code].odds) || '--'}</span>
    </div>
  );
};

export default Odds;