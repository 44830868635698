import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';
import TeMaAndLianMa from '../../../../components/Mobile/ComponentType/TeMaAndLianMa';

const SZHS = [
  {
    componentType: TeMaAndLianMa,
    data: {
      list: [
        {
          displayName: '前三定位',
          numberOfSelectionBlock: 2,
          code: 'HS3Q3',
          children: [{
            code: 'HS3Q3'
          }]
        },
        {
          displayName: '中三定位',
          numberOfSelectionBlock: 2,
          code: 'HS3Z3',
          children: [{
            code: 'HS3Z3'
          }]
        },
        {
          displayName: '后三',
          numberOfSelectionBlock: 2,
          code: 'HS3H3',
          children: [{
            code: 'HS3H3'
          }]
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '前三和数',
    headerStyle: '',
    selectionBlockIndex: 0,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      kuaiJieSelectedGroupIndex: 0,
      list: [
        {
          code: '_6',
          displayName: '0~6',
        }, {
          code: '_7',
          displayName: '7',
        }, {
          code: '_8',
          displayName: '8',
        }, {
          code: '_9',
          displayName: '9',
        }, {
          code: '_10',
          displayName: '10',
        }, {
          code: '_11',
          displayName: '11',
        }, {
          code: '_12',
          displayName: '12',
        }, {
          code: '_13',
          displayName: '13',
        }, {
          code: '_14',
          displayName: '14',
        }, {
          code: '_15',
          displayName: '15',
        }, {
          code: '_16',
          displayName: '16',
        }, {
          code: '_17',
          displayName: '17',
        }, {
          code: '_18',
          displayName: '18',
        }, {
          code: '_19',
          displayName: '19',
        }, {
          code: '_20',
          displayName: '20',
        }, {
          code: '_21',
          displayName: '21~27',
        }
      ]
    }
  }, {
    componentType: CollapseType,
    header: '前三和数尾数',
    headerStyle: '',
    selectionBlockIndex: 1,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      kuaiJieSelectedGroupIndex: 1,
      list: [
        {
          code: 'W_0', // keyCode
          displayName: '0',
        }, {
          code: 'W_1',
          displayName: '1',
        }, {
          code: 'W_2',
          displayName: '2',
        }, {
          code: 'W_3',
          displayName: '3',
        }, {
          code: 'W_4',
          displayName: '4',
        }, {
          code: 'W_5',
          displayName: '5',
        }, {
          code: 'W_6',
          displayName: '6',
        }, {
          code: 'W_7',
          displayName: '7',
        }, {
          code: 'W_8',
          displayName: '8',
        }, {
          code: 'W_9',
          displayName: '9',
        }
      ]
    }
  }
];

export default SZHS;