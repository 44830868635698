import React from 'react';

import { Col, Row } from 'reactstrap';
import { BsFillCircleFill } from 'react-icons/bs';

import './styles.scss';

const CheckBox = ({ displayName, gameInfo, code, active, onChange }) => (
  <div className="d-flex justify-content-center checkboxWrapper" onClick={gameInfo && gameInfo.oddsList && gameInfo.oddsList[code] && gameInfo.oddsList[code].enabled === 1 && onChange}>
    {
      gameInfo && gameInfo.oddsList && gameInfo.oddsList[code] && gameInfo.oddsList[code].enabled === 1 && (
      <span className={`optionWrapper ${active && 'active'}`}>
        {
          active && (
            <BsFillCircleFill className={`checkboxCircle ${active && 'active'}`} />
          )
        }
      </span>
      )
    }
    {
      displayName.length >= 5 ? (
        <Col>
          <Row>
            <span className={!(gameInfo && gameInfo.oddsList && gameInfo.oddsList[code] && gameInfo.oddsList[code].enabled === 1) && 'disabledLabel'}>{displayName.slice(0, 2)}</span>
          </Row>
          <Row>
            <span className={!(gameInfo && gameInfo.oddsList && gameInfo.oddsList[code] && gameInfo.oddsList[code].enabled === 1) && 'disabledLabel'}>{displayName.slice(2, 5)}</span>
          </Row>
        </Col>
      ) : (
        <span className={!(gameInfo && gameInfo.oddsList && gameInfo.oddsList[code] && gameInfo.oddsList[code].enabled === 1) && 'disabledLabel'}>{displayName}</span>
      )
    }
  </div>
);

export default CheckBox;