import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import PengAndDan from '../../../../components/Mobile/ComponentType/PengAndDan';
import TeMaAndLianMa from '../../../../components/Mobile/ComponentType/TeMaAndLianMa';

import OptionItem from '../../../../components/Mobile/OptionItem';

const LM = [
  {
    componentType: TeMaAndLianMa,
    data: {
      list: [
        {
          code: 'L3QZ',
          displayName: '三全中',
          minimumSelection: 3,
          maximumSelection: 11,
          className: 'cellWrapper',
          danList: ['1', '2'],
        }, {
          code: 'L3Z2Z2',
          displayName: '三中二',
          minimumSelection: 3,
          maximumSelection: 8,
          className: 'cellWrapper',
          danList: ['1', '2'],
        }, {
          code: 'L2QZ',
          displayName: '二全中',
          minimumSelection: 2,
          maximumSelection: 8,
          danList: ['1'],
          className: 'cellWrapper',
        }, {
          code: 'L2ZT',
          displayName: '二中特',
          minimumSelection: 2,
          maximumSelection: 8,
          className: 'cellWrapper',
          danList: ['1'],
        }, {
          code: 'LTC',
          displayName: '特串',
          minimumSelection: 2,
          maximumSelection: 17,
          danList: ['1'],
          className: 'cellWrapper',
        }
      ]
    }
  },
  {
    componentType: PengAndDan,
    data: {}
  },
  {
    componentType: CollapseType,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper threeColumnStyle',
      list: [
        {
          code: '1',
          displayName: '01',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '02',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '03',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '04',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '05',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '06',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '07',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '08',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '09',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '10',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '11',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '12',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '15',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '16',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '17',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '18',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '22',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '23',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '24',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '25',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '26',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '27',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '28',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '29',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '30',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '31',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '32',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '33',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '34',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '35',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '36',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '37',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '38',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '39',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '40',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '41',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '42',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '43',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '44',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '45',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '46',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '47',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '48',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '49',
          drawImgClassName: 'circle circleGreen'
        }
      ]
    }
  }
];

export default LM;

// {
//   rowTitle: '赔率',
//   rowTitleClassName: 'text-center',
//   rowType: Odds,
//   wrapperStyle: 'oddsRow',
//   className: 'chooseBetOdds',
//   rowData: [{
//     code: 'L3QZ1',
//     displayName: '三全中',
//     className: 'chooseBetOdds',
//   }, {
//     code: 'L3Z2Z21',
//     displayName: [{
//       code: 'L3Z2Z21', displayName: '中二', className: 'd-flex justify-content-center pb-2 align-items-center border-bottom h-100 w-100'
//     }, {
//       code: 'L3Z2Z31', displayName: '中三', className: 'd-flex justify-content-center pt-2 align-items-center h-100 w-100'
//     }],
//     className: 'chooseBetOdds',
//   }, {
//     code: 'L2QZ1',
//     displayName: '二全中',
//     className: 'chooseBetOdds',
//   }, {
//     code: 'L2ZT1',
//     displayName: [{
//       code: 'L2ZT1', displayName: '中特', className: 'd-flex justify-content-center pb-2 align-items-center border-bottom h-100 w-100'
//     }, {
//       code: 'L2ZTZ21', displayName: '中二', className: 'd-flex justify-content-center pt-2 align-items-center h-100 w-100'
//     }],
//     className: 'chooseBetOdds',
//   }, {
//     code: 'LTC1',
//     displayName: '特串',
//     className: 'chooseBetOdds',
//   }]
// }