import { BetTypeContainer, MultipleTypeSelectionPanel, Tab } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const LW = [
  {
    componentType: QuickBet,
  },
  {
    componentType: MultipleTypeSelectionPanel,
    data: {
      list: [
        {
          rowType: Tab,
          wrapperStyle: 'tabRow',
          onCheckBoxChange: (updateSectionSelection, subItem) => {
            updateSectionSelection(subItem);
          },
          rowData: [{
            code: 'L2LW',
            displayName: '二尾',
            minimumSelection: 2,
            maximumSelection: 6,
            danList: ['1'],
            className: 'cellWrapper',
            midCode: 'L2LW'
          }, {
            code: 'L3LW',
            displayName: '三尾',
            minimumSelection: 3,
            maximumSelection: 6,
            danList: ['1', '2'],
            className: 'cellWrapper',
            midCode: 'L3LW'
          }, {
            code: 'L4LW',
            displayName: '四尾',
            minimumSelection: 4,
            maximumSelection: 7,
            danList: ['1', '2', '3'],
            className: 'cellWrapper',
            midCode: 'L4LW'
          }, {
            code: 'L2LWBZ',
            displayName: '二尾不中',
            minimumSelection: 2,
            maximumSelection: 6,
            danList: ['1'],
            className: 'cellWrapper',
            midCode: 'L2LWBZ'
          }, {
            code: 'L3LWBZ',
            displayName: '三尾不中',
            minimumSelection: 3,
            maximumSelection: 6,
            danList: ['1', '2'],
            className: 'cellWrapper',
            midCode: 'L3LWBZ'
          }, {
            code: 'L4LWBZ',
            displayName: '四尾不中',
            minimumSelection: 4,
            maximumSelection: 7,
            danList: ['1', '2', '3'],
            className: 'cellWrapper',
            midCode: 'L4LWBZ'
          }]
        },
        {
          rowTitleClassName: 'p-2 text-center',
          rowType: BetTypeContainer,
          wrapperStyle: 'p-2',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['尾数', '赔率', '选择'],
      mainColumnLength: 5,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-checkbox'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-checkbox'],
      betItemList: ['DisplayText', 'Odds', 'CheckBox'],
      betItemColumn: 3,
      list: [{
        code: '1',
        displayName: '0尾',
      }, {
        code: '2',
        displayName: '1尾',
      }, {
        code: '3',
        displayName: '2尾',
      }, {
        code: '4',
        displayName: '3尾',
      }, {
        code: '5',
        displayName: '4尾',
      }, {
        code: '6',
        displayName: '5尾',
      }, {
        code: '7',
        displayName: '6尾',
      }, {
        code: '8',
        displayName: '7尾',
      }, {
        code: '9',
        displayName: '8尾',
      }, {
        code: '10',
        displayName: '9尾',
      }]
    }
  },
  {
    componentType: QuickBet,
  }
];

export default LW;