import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import { motion } from 'framer-motion';

import Marquee from 'react-fast-marquee';

import QRCodeSVG from 'qrcode.react';

import { FiDownload } from 'react-icons/fi';
import { GiBurningRoundShot } from 'react-icons/gi';
import { MdQuestionAnswer } from 'react-icons/md';
import { RiVipDiamondFill } from 'react-icons/ri';

import Accordion from '../../../../../components/Web/Accordion';
import CarouselBanner from '../../../../../components/Web/CarouselBanner';
import Divider from '../../../../../components/Web/Divider';
import ServiceCardOne from '../../../../../components/Web/Service/ServiceCardOne';
import ServiceCardTwo from '../../../../../components/Web/Service/ServiceCardTwo';

import AppMockup from '../../../../../assets/image/home/app_mockup.png';
import SmallBanner1 from '../../../../../assets/image/home/Sbanner_1.png';
import SmallBanner2 from '../../../../../assets/image/home/Sbanner_2.png';
import SmallBanner3 from '../../../../../assets/image/home/Sbanner_3.png';

import Games1 from '../../../../../assets/image/home/games_1.png';
import Games2 from '../../../../../assets/image/home/games_2.png';
import Games3 from '../../../../../assets/image/home/games_3.png';
import Games4 from '../../../../../assets/image/home/games_4.png';
import Games5 from '../../../../../assets/image/home/games_5.png';
import Games6 from '../../../../../assets/image/home/games_6.png';
import Games7 from '../../../../../assets/image/home/games_7.png';
import Games8 from '../../../../../assets/image/home/games_8.png';
import Games9 from '../../../../../assets/image/home/games_9.png';

import QrAndriod from '../../../../../assets/image/home/qr_android.png';
import QrAndriodBackground from '../../../../../assets/image/home/qr_android_bg.png';
import QrIos from '../../../../../assets/image/home/qr_ios.png';
import QrIosBackground from '../../../../../assets/image/home/qr_ios_bg.png';


import Service1 from '../../../../../assets/icon/ServiceIcons/service-1.png';
import Service2 from '../../../../../assets/icon/ServiceIcons/service-2.png';
import Service3 from '../../../../../assets/icon/ServiceIcons/service-3.png';
import Service4 from '../../../../../assets/icon/ServiceIcons/service-4.png';
import Service5 from '../../../../../assets/icon/ServiceIcons/service-5.png';
import Service6 from '../../../../../assets/icon/ServiceIcons/service-6.png';
import Service7 from '../../../../../assets/icon/ServiceIcons/service-7.png';
import Service8 from '../../../../../assets/icon/ServiceIcons/service-8.png';


import 'element-theme-default';
import './styles.scss';


const mapStateToProps = state => ({
  latestAnnouncement: state.noticeReducers.latestAnnouncement,
  initData: state.homeReducers.initData,
});


const HomeContent = ({
  latestAnnouncement,
  initData
}) => {
  const [_latestAnnouncement, setLatestAnnouncement] = useState() || [];
  const [apkUrlTrimmed, setApkUrlTrimmed] = useState('');
  const [ipaUrlTrimmed, setIpaUrlTrimmed] = useState('');

  useEffect(() => {
    if (initData && initData.company.apkDown && initData.company.ipaDown) {
      setApkUrlTrimmed(initData.company.apkDown.trim());
      setIpaUrlTrimmed(initData.company.ipaDown.trim());
    }
  }, [initData]);

  useEffect(() => {
    if (latestAnnouncement) {
      setLatestAnnouncement(latestAnnouncement.news);
    }
  }, [latestAnnouncement]);

  const topGames = [{
    img: Games1, name: '极速飞车'
  }, {
    img: Games2, name: '极速分分彩'
  }, {
    img: Games3, name: '幸运赛车'
  }, {
    img: Games4, name: '幸运时时彩'
  }, {
    img: Games5, name: '香港六合彩'
  }, {
    img: Games6, name: '澳门六合彩'
  }, {
    img: Games7, name: '台湾大乐透'
  }, {
    img: Games8, name: '赛车番摊'
  }, {
    img: Games9, name: '时时彩番摊'
  }];

  const serviceListOne = [{
    src: Service1, title: '30', subTitle: '秒', content: '平均存款时间'
  }, {
    src: Service2, title: '70', subTitle: '家', content: '合作支付平台'
  }, {
    src: Service3, title: '50', subTitle: '秒', content: '平均取款时间'
  }, {
    src: Service4, title: '15', subTitle: '家', content: '合作游戏平台'
  }];

  const serviceListTwo = [{
    src: Service5, title: '极速存取转款', content: '为用户提供多种的存取款方式，最新自主研发的财务处理系统，保障用户存取过程中顺利和及时，独家的网络优化技术为用户带来一流的游戏体验。'
  }, {
    src: Service6, title: '加密安全管理', content: '采用最先进128位SSL加密管理系统，确保用户的资金和隐私得到最完善的保障，让用户尽情享受投注乐趣，无后顾之忧。'
  }, {
    src: Service7, title: '海量赛事种类', content: '向用户提供最齐全的投注游戏种类，每天超过500场不同类别的精彩体育赛事以及最丰富的电竞赛事、真人娱乐场、电子游戏、彩票等；给用户带来全方位的优良体验和酣畅淋漓的游戏享受。'
  }, {
    src: Service8, title: '三端任你选', content: '基于原生态APP，完美支持各终端设备，兼容APP、H5、PC三端数据互通，用户随时随地投注，最专业的技术团队，保障用户全天24小时安全省心游戏。'
  }];

  const fadeVariant = {
    offscreen: {
      opacity: 0
    },
    onscreen: {
      opacity: 1,
      transition: {
        duration: 1.0
      }
    }
  };

  return (
    <>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{
          once: true
        }}
        variants={fadeVariant}
      >
        <div className="bannerWrapper my-4">
          <div className="row">
            <div className="col-9 py-2">
              <CarouselBanner />
            </div>
            <div className="col-3">
              <div className="d-flex flex-column">
                {
                    [SmallBanner3, SmallBanner2, SmallBanner1].map((item, index) => (
                      <div key={`${index.toString()}`} className="py-2">
                        <img className="bannerImage" src={item} alt="banner" width="100%" height="100%" />
                      </div>
                    ))
                  }
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      <div className="homeContentWrapper">
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{
            once: true
          }}
          variants={fadeVariant}
        >
          <div className="noticeWrapper">
            <div className="row justify-content-center align-items-center px-3">
              <div className="col-1 noticeTitle py-2 px-3">系统消息</div>
              <div className="col-11">
                <Marquee pauseOnHover pauseOnClick>
                  {_latestAnnouncement && _latestAnnouncement.map((item, index) => (
                    <div key={`${index.toString()}`} className="px-5">
                      {item.content}
                    </div>
                  ))}
                </Marquee>
              </div>
            </div>
          </div>
        </motion.div>
        <Divider title="客户端下载" subTitle="自主研发精品客户端 极致享受投注体验" icon={<FiDownload color="white" size={20} />} />
        <div className="card appCardWrapper p-4">
          <div className="row no-gutters justify-content-center align-items-center">
            <div className="col-md-4">
              <img className="w-100 pr-2" src={AppMockup} alt="app" />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title font-weight-bold">全站APP下载</h5>
                <div className="position-relative">
                  <h1 className="card-title">线上娱乐 随时畅玩</h1>
                  <p className="appDownload position-absolute font-weight-bolder">APP DOWNLOAD</p>
                </div>
                <p className="card-text font-weight-light">全球首家一体化娱乐原生APP，尽显流畅，完美操作。海量体育， 电竞顶尖赛车，真人娱乐，彩票投注及电子游戏等，最新最全娱乐项目尽在掌中，体验扫码下载，即刻拥有</p>
                <div className="d-flex justify-content-start">
                  {initData && (initData.company.apkDown !== '' || initData.company.apkDown !== null) && (
                    <div className="position-relative">
                      <img src={QrAndriodBackground} className="qrImg" alt="androidBackground" />
                      <QRCodeSVG value={apkUrlTrimmed} size={76} className="position-absolute qrPosition" />
                    </div>
                  )}
                  {initData && (initData.company.apkDown === '' || initData.company.apkDown === null) && (
                    <img src={QrAndriod} className="qrImg" alt="android" />
                  )}
                  {initData && (initData.company.ipaDown !== '' || initData.company.ipaDown !== null) && (
                    <div className="position-relative">
                      <img src={QrIosBackground} className="qrImg" alt="iosBackground" />
                      <QRCodeSVG value={ipaUrlTrimmed} size={76} className="position-absolute qrPosition" />
                    </div>
                  )}
                  {initData && (initData.company.ipaDown === '' || initData.company.ipaDown === null) && (
                    <img src={QrIos} className="qrImg" alt="ios" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider title="专业服务" subTitle="专业客服团队 优质内核系统" icon={<RiVipDiamondFill color="white" size={20} />} />
        <div className="row row-cols-4">
          {serviceListOne.map(item => (
            <div className="col mb-4" key={item.title}>
              <ServiceCardOne src={item.src} title={item.title} subTitle={item.subTitle} content={item.content} />
            </div>
          ))}
        </div>
        <div className="row row-cols-4">
          {serviceListTwo.map(item => (
            <div className="col mb-4" key={item.title}>
              <ServiceCardTwo src={item.src} title={item.title} content={item.content} />
            </div>
          ))}
        </div>
        <Divider title="热门彩种" subTitle="提供迅速的投注体验和多样化的热门彩种 稳定创造收益" icon={<GiBurningRoundShot color="white" size={20} />} />
        <div className="topGameWrapper">
          <div className="d-flex flex-row flex-wrap justify-content-around align-items-center">
            {
              topGames.map(item => (
                <div key={item.name} className="d-flex flex-column align-items-center m-2">
                  <img src={item.img} alt="img" srcSet="" />
                  <h4 className="fontColor">{item.name}</h4>
                </div>
              ))
            }
          </div>
        </div>
        <Divider title="常见问题" subTitle="这里提供了简明扼要的回答 可帮助您更迅速地解决疑虑" icon={<MdQuestionAnswer color="white" size={20} />} />
        {[{
          question: '如何加入星辉科技呢？', answer: '您可以直接点击注册，填写资料后，即可登记成为星辉科技会员。'
        }, {
          question: '我在哪里可以找到活动规则？', answer: '在登陆后，您可以在游戏右上看到‘活动规则’选项，清楚的告诉您游戏的玩法、规则及派彩方式。'
        }, {
          question: '如何提款？', answer: '提款时请先确保金额不是处于冻结状态，最低提款金额为100元。任何一笔存款或返水最少需要一倍任意游戏才能进行提款，优惠红利则需按优惠规则限定的流水倍数打完流水，否则提款将无法通过。提款失败者请到交易记录页面查询原因。'
        }].map((item, index) => (
          <div key={`question_${index.toString()}`}>
            <Accordion question={item.question} answer={item.answer} />
          </div>
        ))}
      </div>
    </>
  );
};

export default connect(mapStateToProps, null)(HomeContent);