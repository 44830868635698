import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';
import PengAndDan from '../../../../components/Mobile/ComponentType/PengAndDan';
import TeMaAndLianMa from '../../../../components/Mobile/ComponentType/TeMaAndLianMa';

const ZSS = [
  {
    componentType: TeMaAndLianMa,
    data: {
      list: [
        {
          displayName: '前三',
          code: 'ZX3Q3_',
          minimumSelection: 5,
          maximumSelection: 10,
          dynamicChildren: {
            displayName: '前三组选三',
            code: 'ZX3Q3_'
          }
        },
        {
          displayName: '中三',
          code: 'ZX3Z3_',
          minimumSelection: 5,
          maximumSelection: 10,
          dynamicChildren: {
            displayName: '中三组选三',
            code: 'ZX3Z3_'
          }
        },
        {
          displayName: '后三',
          code: 'ZX3H3_',
          minimumSelection: 5,
          maximumSelection: 10,
          dynamicChildren: {
            displayName: '后三组选三',
            code: 'ZX3H3_'
          }
        }
      ]
    }
  },
  {
    componentType: PengAndDan,
    data: {}
  },
  {
    componentType: CollapseType,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          displayName: '0',
          drawImgClassName: 'circle',
          showOdds: false
        }, {
          displayName: '1',
          drawImgClassName: 'circle',
          showOdds: false
        }, {
          displayName: '2',
          drawImgClassName: 'circle',
          showOdds: false
        }, {
          displayName: '3',
          drawImgClassName: 'circle',
          showOdds: false
        }, {
          displayName: '4',
          drawImgClassName: 'circle',
          showOdds: false
        }, {
          displayName: '5',
          drawImgClassName: 'circle',
          showOdds: false
        }, {
          displayName: '6',
          drawImgClassName: 'circle',
          showOdds: false
        }, {
          displayName: '7',
          drawImgClassName: 'circle',
          showOdds: false
        }, {
          displayName: '8',
          drawImgClassName: 'circle',
          showOdds: false
        }, {
          displayName: '9',
          drawImgClassName: 'circle',
          showOdds: false
        }
      ]
    }
  },
];

export default ZSS;