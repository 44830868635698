import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import PengAndDan from '../../../../components/Mobile/ComponentType/PengAndDan';
import TeMaAndLianMa from '../../../../components/Mobile/ComponentType/TeMaAndLianMa';

import OptionItem from '../../../../components/Mobile/OptionItem';
import gameItemIconMap from '../../../GameItemIcon';

const LIANMA = [
  {
    componentType: TeMaAndLianMa,
    data: {
      list: [{
        code: 'LMA1',
        displayName: '任选二',
        minimumSelection: 2,
        maximumSelection: 7,
        children: [{
          code: 'LMA1'
        }]
      }, {
        code: 'LMA2',
        displayName: '任选三',
        minimumSelection: 3,
        maximumSelection: 7,
        children: [{
          code: 'LMA2'
        }]
      }, {
        code: 'LMA3',
        displayName: '任选四',
        minimumSelection: 4,
        maximumSelection: 7,
        children: [{
          code: 'LMA3'
        }]
      }, {
        code: 'LMA4',
        displayName: '任选五',
        minimumSelection: 5,
        maximumSelection: 7,
        children: [{
          code: 'LMA4'
        }]
      }, {
        code: 'LMA5',
        displayName: '选二连组',
        minimumSelection: 2,
        maximumSelection: 7,
        children: [{
          code: 'LMA5'
        }]
      }, {
        code: 'LMA6',
        displayName: '选三前组',
        minimumSelection: 3,
        maximumSelection: 7,
        children: [{
          code: 'LMA6'
        }]
      }]
    }
  },
  {
    componentType: PengAndDan,
    data: {}
  },
  {
    componentType: CollapseType,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper HK6TeXiao',
      list: [
        {
          code: 'B1M1',
          displayName: '1',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'B1M6',
          displayName: '6',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'B1M11',
          displayName: '11',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'B1M16',
          displayName: '16',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'B1M2',
          displayName: '2',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'B1M7',
          displayName: '7',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'B1M12',
          displayName: '12',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'B1M17',
          displayName: '17',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'B1M3',
          displayName: '3',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'B1M8',
          displayName: '8',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'B1M13',
          displayName: '13',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'B1M18',
          displayName: '18',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'B1M4',
          displayName: '4',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'B1M9',
          displayName: '9',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'B1M14',
          displayName: '14',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'B1M19',
          displayName: '19',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'B1M5',
          displayName: '5',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'B1M10',
          displayName: '10',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'B1M15',
          displayName: '15',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'B1M20',
          displayName: '20',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  }
];

export default LIANMA;