import React, { useEffect, useState } from 'react';

import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, cartActions, gameActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  gameInfo: state.placeBetReducers.gameInfo,
  presetAmount: state.gameReducers.presetAmount,
  cart: state.cartReducers.cart,
  sectionSelection: state.gameReducers.sectionSelection,
  companyInfo: state.companyReducers.companyInfo,
  betTypeSelection: state.gameReducers.betTypeSelection,
  selectedDan: state.gameReducers.selectedDan,
  currentSelected: state.gameReducers.currentSelected,
  lotteryType: state.gameReducers.lotteryType,
  template: state.gameReducers.template,
  lengReResult: state.resultHistoryReducers.lengReResult,
  yiLouCount: state.appReducers.yiLouCount,
  showLengRe: state.appReducers.showLengRe,
  showYiLou: state.appReducers.showYiLou,
});

const mapDispatchToProps = dispatch => ({
  addItemToCart: (item, reset) => dispatch(cartActions.addItemToCart(item, reset)),
  removeItemInCart: keyCode => dispatch(cartActions.removeItemInCart(keyCode)),
  updateSelectedDan: dan => dispatch(gameActions.updateSelectedDan(dan)),
  updateCurrentSelected: val => dispatch(gameActions.updateCurrentSelected(val)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  updateCart: cart => dispatch(cartActions.updateCart(cart)),
});

function MultipleItemsSelectionPanel({ list, numberOfColumn, maximumSelection, danNum, title, updateCart, updateModalAlert, gameInfo, cart, selectedDan, currentSelected, presetAmount, sectionSelection, updateSelectedDan, updateCurrentSelected }) {
  const { t } = useTranslation();
  const [_currentSelection, setCurrentSelection] = useState([]);
  const [_sectionSelection, setSectionSelection] = useState();
  const [_selectedDan, setSelectedDan] = useState([]);
  const [_gameInfo, setGameInfo] = useState();

  useEffect(() => {
    if (JSON.stringify(sectionSelection) !== JSON.stringify(_sectionSelection)) {
      setSectionSelection(sectionSelection);
    }
  }, [sectionSelection]);

  useEffect(() => {
    if (JSON.stringify(selectedDan) !== JSON.stringify(_selectedDan)) {
      setSelectedDan(selectedDan || []);
    }
  }, [selectedDan]);

  useEffect(() => {
    if (JSON.stringify(currentSelected) !== JSON.stringify(_currentSelection)) {
      setCurrentSelection(currentSelected || []);
    }
  }, [currentSelected]);

  useEffect(() => {
    if (JSON.stringify(gameInfo) !== JSON.stringify(_gameInfo)) {
      setGameInfo(gameInfo);
    }
  }, [gameInfo]);

  useEffect(() => {
    if (JSON.stringify(gameInfo) !== JSON.stringify(_gameInfo)) {
      if (gameInfo && _gameInfo) {
        const { oddsList: newOddsList } = gameInfo || {};
        const { oddsList } = _gameInfo || {};
        if (newOddsList && oddsList) {
          const diff = difference(oddsList, newOddsList);
          if (Object.keys(diff).length > 0) {
            const changes = checkChangesInCart(diff);
            if (changes > 0) {
              updateModalAlert({
                visible: true,
                type: 'confirmation',
                data: {
                  message: t('X_AMOUNT_ODDS_CHANGE_ARE_YOU_SURE_TO_UPDATE').replace('X', changes),
                  confirmationText: t('UPDATE_IMMEDIATELY'),
                  onConfirmationClick: () => {
                    const tempCart = JSON.parse(JSON.stringify(cart));
                    Object.entries(tempCart).forEach(([key, item]) => {
                      if (diff[key]) {
                        tempCart[key] = {
                          ...item,
                          ...diff[key]
                        };
                      }
                    });
                    updateCart(tempCart);
                  },
                  cancelText: t('CANCEL_IMMEDIATELY'),
                  onCancelClick: () => {
                    const tempCart = JSON.parse(JSON.stringify(cart));
                    Object.entries(tempCart).forEach(([key]) => {
                      if (diff[key]) {
                        delete tempCart[key];
                      }
                    });
                    updateCart(tempCart);
                    updateModalAlert({
                      visible: false,
                    });
                  }
                }
              });
            }
          }
        }
      }
      setGameInfo(gameInfo);
    }
  }, [gameInfo]);

  const checkChangesInCart = diff => {
    if (!cart) return;
    let count = 0;
    Object.keys(cart).forEach(key => {
      if (diff[key] !== undefined) count += 1;
    });
    return count;
  };

  const difference = (obj1, obj2) => {
    let keyFound = {};
    Object.keys(obj1).forEach(key => {
      if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
        keyFound = {
          ...keyFound,
          [key]: obj2[key]
        };
      }
    });
    return keyFound;
  };

  const generateClassName = obj => list.find(x => x.displayName === obj.displayName).className;

  const onItemClick = (obj, amount) => {
    if (!_gameInfo.oddsList) return;
    const keyCode = obj.code;
    const currentOddItem = _gameInfo && _gameInfo.oddsList && (_gameInfo.oddsList[`${_sectionSelection && _sectionSelection.value}${obj.code}`] || _gameInfo.oddsList[`${_sectionSelection && _sectionSelection.value}_${obj.code}`] || _gameInfo.oddsList[obj.code]);

    const { midType, odds, enabled } = currentOddItem || {};

    if (enabled === 0) return;

    let tempSelectedBet;
    let tempDan;

    const isItemADan = _selectedDan.find(dan => dan.displayName === obj.displayName);
    const isItemABet = _currentSelection.find(bet => bet.displayName === obj.displayName);

    const item = {
      displayName: obj.displayName,
      amount: amount || presetAmount || 0,
      keyCode,
      midType,
      odds,
    };

    if (danNum) {
      if (_selectedDan.length < danNum && !isItemABet) {
        tempDan = [..._selectedDan, item];
        tempSelectedBet = _currentSelection;
      } else if (isItemADan) {
        tempDan = _selectedDan.filter(x => x.displayName !== item.displayName);
        tempSelectedBet = _currentSelection;
      } else if (isItemABet) {
        tempDan = _selectedDan;
        tempSelectedBet = _currentSelection.filter(x => x.displayName !== item.displayName);
      } else {
        tempDan = _selectedDan;
        tempSelectedBet = [..._currentSelection, item].sort((a, b) => a.displayName - b.displayName);
      }
      updateSelectedDan(tempDan);
      return updateCurrentSelected(tempSelectedBet);
    }
    if (_currentSelection.find(x => x.displayName === item.displayName)) {
      tempSelectedBet = _currentSelection.filter(x => x.displayName !== item.displayName).sort((a, b) => a.displayName - b.displayName);
    } else {
      tempSelectedBet = [..._currentSelection, item].sort((a, b) => a.displayName - b.displayName);
    }
    return updateCurrentSelected(tempSelectedBet);
  };

  const renderTable = () => {
    const tableBody = [];
    let count = 0;
    let tableBodyRow = [];
    const fillEmptyRow = () => {
      while (tableBodyRow.length < numberOfColumn) {
        tableBodyRow.push(
          <td key={`choose_bet_empty_item_${tableBodyRow.length.toString()}`} />
        );
      }
      tableBody.push(<tr key={`table_body_${count.toString()}`}>{tableBodyRow}</tr>);
    };
    const fillItemRow = obj => {
      const _className = (_selectedDan.find(x => x.displayName === obj.displayName) || _currentSelection.find(x => x.displayName === obj.displayName)) ? 'selectedCell' : _currentSelection.length + _selectedDan.length === maximumSelection ? 'disabledCell' : '';

      tableBodyRow.push(
        <td
          key={`choose_bet_item_${count.toString()}`}
          className={`${_className}`}
          onClick={() => {
            if (_className === 'disabledCell') return;
            onItemClick(obj);
          }}
        >
          <span className={`${obj.className}`}>
            {obj.displayName}
          </span>
        </td>
      );
      count += 1;
    };
    while (count < list.length) {
      if (list[count] && list[count].newLine) {
        fillEmptyRow();
        tableBodyRow = [];
        fillItemRow(list[count]);
      } else if (tableBodyRow.length < numberOfColumn) {
        fillItemRow(list[count]);
      } else {
        tableBody.push(<tr key={`table_body_${count.toString()}`}>{tableBodyRow}</tr>);
        tableBodyRow = [];
      }
    }
    fillEmptyRow();
    return (
      <tbody>
        {tableBody}
      </tbody>
    );
  };
  return (
    <div className="multipleItemsSelectionPanelWrapper">
      {title && (<Row className="mx-0 py-2 headerTitle justify-content-center align-items-center">{title}</Row>)}
      <Row className="mx-0">
        <Col className="px-0 leftSelectedWrapper d-flex flex-column justify-content-center align-items-center">
          <div>{_selectedDan.map(item => <span className={`${generateClassName(item)}`}>{item.displayName}</span>)}</div>
          <div>{_currentSelection.map(item => <span className={`${generateClassName(item)}`}>{item.displayName}</span>)}</div>
        </Col>
        <Col className="px-0">
          <table className="w-100 text-center templateStyle">
            {renderTable()}
          </table>
        </Col>
      </Row>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MultipleItemsSelectionPanel);