import { OddsTypeTable, ProbabilitySelectionPanel } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const SZDW = [
  {
    componentType: ProbabilitySelectionPanel,
    data: {
      showProbability: false,
      midCodeList: [{
        label: '前三定位',
        value: 'DW3Q3',
        minimumSelection: 1,
        maximumSelection: 30,
        iteration: 3,
      }, {
        label: '中三定位',
        value: 'DW3Z3',
        minimumSelection: 1,
        maximumSelection: 30,
        iteration: 3,
      }, {
        label: '后三定位',
        value: 'DW3H3',
        minimumSelection: 1,
        maximumSelection: 30,
        iteration: 3,
      }],
    }
  },
  {
    componentType: QuickBet,
  },
  {
    componentType: OddsTypeTable,
    data: {
      renderLabel: sectionSelection => {
        if (!sectionSelection) return;
        return sectionSelection.label;
      }
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '万位',
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '选择'],
      mainColumnLength: 5,
      subColumnLength: 2,
      subColumnStyle: ['w-50', 'w-50'],
      betItemStyle: ['w-50', 'w-50'],
      betItemList: ['DisplayText', 'CheckBox'],
      betItemColumn: 2,
      list: [
        {
          code: 'A0',
          displayName: '0',
          className: 'circle',
        }, {
          code: 'A2',
          displayName: '2',
          className: 'circle',
        }, {
          code: 'A4',
          displayName: '4',
          className: 'circle',
        }, {
          code: 'A6',
          displayName: '6',
          className: 'circle',
        }, {
          code: 'A8',
          displayName: '8',
          className: 'circle',
        }, {
          code: 'A1',
          displayName: '1',
          className: 'circle',
        }, {
          code: 'A3',
          displayName: '3',
          className: 'circle',
        }, {
          code: 'A5',
          displayName: '5',
          className: 'circle',
        }, {
          code: 'A7',
          displayName: '7',
          className: 'circle',
        }, {
          code: 'A9',
          displayName: '9',
          className: 'circle',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '千位',
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '选择'],
      mainColumnLength: 5,
      subColumnLength: 2,
      subColumnStyle: ['w-50', 'w-50'],
      betItemStyle: ['w-50', 'w-50'],
      betItemList: ['DisplayText', 'CheckBox'],
      betItemColumn: 2,
      list: [
        {
          code: 'B0',
          displayName: '0',
          className: 'circle',
        }, {
          code: 'B2',
          displayName: '2',
          className: 'circle',
        }, {
          code: 'B4',
          displayName: '4',
          className: 'circle',
        }, {
          code: 'B6',
          displayName: '6',
          className: 'circle',
        }, {
          code: 'B8',
          displayName: '8',
          className: 'circle',
        }, {
          code: 'B1',
          displayName: '1',
          className: 'circle',
        }, {
          code: 'B3',
          displayName: '3',
          className: 'circle',
        }, {
          code: 'B5',
          displayName: '5',
          className: 'circle',
        }, {
          code: 'B7',
          displayName: '7',
          className: 'circle',
        }, {
          code: 'B9',
          displayName: '9',
          className: 'circle',
        }
      ]
    }
  }, {
    componentType: Template2,
    data: {
      tableTitle: '百位',
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '选择'],
      mainColumnLength: 5,
      subColumnLength: 2,
      subColumnStyle: ['w-50', 'w-50'],
      betItemStyle: ['w-50', 'w-50'],
      betItemList: ['DisplayText', 'CheckBox'],
      betItemColumn: 2,
      list: [
        {
          code: 'C0',
          displayName: '0',
          className: 'circle',
        }, {
          code: 'C2',
          displayName: '2',
          className: 'circle',
        }, {
          code: 'C4',
          displayName: '4',
          className: 'circle',
        }, {
          code: 'C6',
          displayName: '6',
          className: 'circle',
        }, {
          code: 'C8',
          displayName: '8',
          className: 'circle',
        }, {
          code: 'C1',
          displayName: '1',
          className: 'circle',
        }, {
          code: 'C3',
          displayName: '3',
          className: 'circle',
        }, {
          code: 'C5',
          displayName: '5',
          className: 'circle',
        }, {
          code: 'C7',
          displayName: '7',
          className: 'circle',
        }, {
          code: 'C9',
          displayName: '9',
          className: 'circle',
        }
      ]
    }
  }, {
    componentType: QuickBet,
  }
];

export default SZDW;