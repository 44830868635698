import React, { useEffect } from 'react';

import { Button } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Cross } from '../../../assets/icon/TopUpIcons/icon_cross.svg';
import Label from '../../Label';
import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const ErrorModal = ({ platform, updateModalAlert, data }) => {
  function handleKeyDown(event) {
    if (event.key === 'Enter' || event.key === 'Escape') {
      updateModalAlert({
        visible: false
      });
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const { t } = useTranslation();
  return (
    <div className={`errorModalWrapper${platform}`}>
      <div className="modalHeader d-flex">
        <div
          className="d-flex align-items-center col"
        >
          {t('REMINDER')}
        </div>
        <div className="d-flex justify-content-end align-items-center col">
          <IoClose
            className="h4 mb-0 float-right closeIcon"
            onClick={() => {
              updateModalAlert({
                visible: false
              });
            }}
          />
        </div>
      </div>
      <div className="modalContent d-flex justify-content-center flex-column align-items-center">
        <div className="d-flex justify-content-center align-items-center my-2">
          <Cross className="errorCross" />
        </div>
        <div className="d-flex justify-content-center my-2">
          <Label className="errorText" value={data && data.message} />
        </div>
      </div>
      <div className="modalFooter d-flex align-items-center justify-content-center">
        <Button
          className="confirmButton my-2"
          onClick={() => {
            if (data?.onConfirmClick) {
              data.onConfirmClick();
            }
            updateModalAlert({
              visible: false
            });
          }}
        >
          {t('CONFIRM')}
        </Button>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);