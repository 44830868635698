import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const ZH = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '总和、总和过关',
      mainColumnLength: 4,
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      emptyItemStyle: ['column-3items-empty-label', 'column-3items-empty-odds', 'column-3items-empty-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'LM1', // keyCode
          displayName: '总和大',
        }, {
          code: 'LM2',
          displayName: '总和小',
        }, {
          code: 'LM3',
          displayName: '总和单',
        }, {
          code: 'LM4',
          displayName: '总和双',
        }, {
          code: 'ZHH5',
          displayName: '总和810',
        }, {
          code: 'ZHGG6',
          displayName: '总和大单',
          newLine: true,
        }, {
          code: 'ZHGG7',
          displayName: '总和大双',
        }, {
          code: 'ZHGG8',
          displayName: '总和小单',
        }, {
          code: 'ZHGG9',
          displayName: '总和小双',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '前后和',
      mainColumnLength: 3,
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'QHH10', // keyCode
          displayName: '前(多)',
        }, {
          code: 'QHH11',
          displayName: '后(多)',
        }, {
          code: 'QHH12',
          displayName: '前后(和)',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '单双和',
      mainColumnLength: 3,
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'DSH13', // keyCode
          displayName: '单(多)',
        }, {
          code: 'DSH14',
          displayName: '双(多)',
        }, {
          code: 'DSH15',
          displayName: '单双(和)',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '五行',
      mainColumnLength: 5,
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'WX16', // keyCode
          displayName: '金',
        }, {
          code: 'WX17',
          displayName: '木',
        }, {
          code: 'WX18',
          displayName: '水',
        }, {
          code: 'WX19',
          displayName: '火',
        }, {
          code: 'WX20',
          displayName: '土',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  }
];

export default ZH;