import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const WX = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['色波', '号码', '赔率', '金额'],
      mainColumnLength: 1,
      subColumnLength: 4,
      subColumnStyle: ['column-4items-label', 'column-4items-balls', 'column-4items-odds', 'column-4items-input'],
      betItemStyle: ['column-4items-label', 'column-4items-array-balls column-4items-balls', 'column-4items-odds', 'column-4items-input'],
      betItemList: ['DisplayText', 'HK6_WX', 'Odds', 'Input'],
      betItemColumn: 4,
      list: [
        {
          code: 'TMWX16', // keyCode
          displayName: '金',
        }, {
          code: 'TMWX17',
          displayName: '木',
        }, {
          code: 'TMWX18',
          displayName: '水',
        }, {
          code: 'TMWX19',
          displayName: '火',
        }, {
          code: 'TMWX20',
          displayName: '土',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['种类', '赔率', '金额'],
      mainColumnLength: 3,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: '7SB28',
          displayName: '红波',
          className: 'fillRed',
        }, {
          code: '7SB29',
          displayName: '蓝波',
          className: 'fillBlue',
        }, {
          code: '7SB30',
          displayName: '绿波',
          className: 'fillGreen',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default WX;