import { CheckBox, MultipleTypeSelectionPanel, Odds } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const LIANMA = [
  {
    componentType: QuickBet,
  },
  {
    componentType: MultipleTypeSelectionPanel,
    data: {
      list: [
        {
          rowTitle: '类别',
          rowTitleClassName: 'p-2 text-center',
          rowType: CheckBox,
          wrapperStyle: 'checkBoxRow',
          onCheckBoxChange: (updateSectionSelection, subItem) => {
            updateSectionSelection(subItem);
          },
          rowData: [{
            code: 'LIANMA1',
            displayName: '任选二中二',
            minimumSelection: 2,
            maximumSelection: 8,
            className: 'cellWrapper',
          }, {
            code: 'LIANMA2',
            displayName: '任选三中三',
            minimumSelection: 3,
            maximumSelection: 8,
            className: 'cellWrapper',
          }, {
            code: 'LIANMA3',
            displayName: '任选四中四',
            minimumSelection: 4,
            maximumSelection: 8,
            className: 'cellWrapper',
          }, {
            code: 'LIANMA4',
            displayName: '任选五中五',
            minimumSelection: 5,
            maximumSelection: 8,
            className: 'cellWrapper',
          }, {
            code: 'LIANMA5',
            displayName: '任选六中五',
            minimumSelection: 6,
            maximumSelection: 8,
            className: 'cellWrapper',
          }, {
            code: 'LIANMA6',
            displayName: '任选七中五',
            minimumSelection: 7,
            maximumSelection: 8,
            className: 'cellWrapper',
          }, {
            code: 'LIANMA7',
            displayName: '任选八中五',
            minimumSelection: 8,
            maximumSelection: 8,
            className: 'cellWrapper',
          }, {
            code: 'LIANMA8',
            displayName: '前二组选',
            minimumSelection: 2,
            maximumSelection: 8,
            className: 'cellWrapper',
          }, {
            code: 'LIANMA9',
            displayName: '前三组选',
            minimumSelection: 3,
            maximumSelection: 8,
            className: 'cellWrapper',
          }]
        },
        {
          rowTitle: '赔率',
          rowTitleClassName: 'p-2 text-center',
          rowType: Odds,
          wrapperStyle: 'oddsRow',
          className: 'chooseBetOdds',
          rowData: [{
            code: 'LIANMA1',
            className: 'chooseBetOdds',
          }, {
            code: 'LIANMA2',
            className: 'chooseBetOdds',
          }, {
            code: 'LIANMA3',
            className: 'chooseBetOdds',
          }, {
            code: 'LIANMA4',
            className: 'chooseBetOdds',
          }, {
            code: 'LIANMA5',
            className: 'chooseBetOdds',
          }, {
            code: 'LIANMA6',
            className: 'chooseBetOdds',
          }, {
            code: 'LIANMA7',
            className: 'chooseBetOdds',
          }, {
            code: 'LIANMA8',
            className: 'chooseBetOdds',
          }, {
            code: 'LIANMA9',
            className: 'chooseBetOdds',
          }]
        },
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '勾选'],
      mainColumnLength: 2,
      subColumnLength: 2,
      subColumnStyle: ['column-2items-label CJ-11x5-lianma-label', 'column-2items-label CJ-11x5-lianma-input'],
      betItemStyle: ['column-2items-label CJ-11x5-lianma-label', 'column-2items-label CJ-11x5-lianma-input'],
      emptyItemStyle: ['column-empty-label', 'column-empty-input'],
      betItemList: ['DisplayText', 'CheckBox'],
      betItemColumn: 3,
      list: [
        {
          displayName: '1',
          className: 'circle',
        }, {
          displayName: '7',
          className: 'circle',
        }, {
          displayName: '2',
          className: 'circle',
        }, {
          displayName: '8',
          className: 'circle',
        }, {
          displayName: '3',
          className: 'circle',
        }, {
          displayName: '9',
          className: 'circle',
        }, {
          displayName: '4',
          className: 'circle',
        }, {
          displayName: '10',
          className: 'circle',
        }, {
          displayName: '5',
          className: 'circle',
        }, {
          displayName: '11',
          className: 'circle',
        }, {
          displayName: '6',
          className: 'circle',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default LIANMA;