import { ProbabilitySelectionPanel } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const EZZH = [
  {
    componentType: ProbabilitySelectionPanel,
    data: {
      showProbability: false,
      midCodeList: [{
        label: '前三',
        value: 'ZH2Q3',
      }, {
        label: '中三',
        value: 'ZH2Z3',
      }, {
        label: '后三',
        value: 'ZH2H3',
      }],
    }
  },
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '赔率', '金额'],
      mainColumnLength: 5,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: '_00',
          displayName: '00',
        }, {
          code: '_12',
          displayName: '12',
        }, {
          code: '_25',
          displayName: '25',
        }, {
          code: '_39',
          displayName: '39',
        }, {
          code: '_59',
          displayName: '59',
        }, {
          code: '_01',
          displayName: '01',
        }, {
          code: '_13',
          displayName: '13',
        }, {
          code: '_26',
          displayName: '26',
        }, {
          code: '_44',
          displayName: '44',
        }, {
          code: '_66',
          displayName: '66',
        }, {
          code: '_02',
          displayName: '02',
        }, {
          code: '_14',
          displayName: '14',
        }, {
          code: '_27',
          displayName: '27',
        }, {
          code: '_45',
          displayName: '45',
        }, {
          code: '_67',
          displayName: '67',
        }, {
          code: '_03',
          displayName: '03',
        }, {
          code: '_15',
          displayName: '15',
        }, {
          code: '_28',
          displayName: '28',
        }, {
          code: '_46',
          displayName: '46',
        }, {
          code: '_68',
          displayName: '68',
        }, {
          code: '_04',
          displayName: '04',
        }, {
          code: '_16',
          displayName: '16',
        }, {
          code: '_29',
          displayName: '29',
        }, {
          code: '_47',
          displayName: '47',
        }, {
          code: '_69',
          displayName: '69',
        }, {
          code: '_05',
          displayName: '05',
        }, {
          code: '_17',
          displayName: '17',
        }, {
          code: '_33',
          displayName: '33',
        }, {
          code: '_48',
          displayName: '48',
        }, {
          code: '_77',
          displayName: '77',
        }, {
          code: '_06',
          displayName: '06',
        }, {
          code: '_18',
          displayName: '18',
        }, {
          code: '_34',
          displayName: '34',
        }, {
          code: '_49',
          displayName: '49',
        }, {
          code: '_78',
          displayName: '78',
        }, {
          code: '_07',
          displayName: '07',
        }, {
          code: '_19',
          displayName: '19',
        }, {
          code: '_35',
          displayName: '35',
        }, {
          code: '_55',
          displayName: '55',
        }, {
          code: '_79',
          displayName: '79',
        }, {
          code: '_08',
          displayName: '08',
        }, {
          code: '_22',
          displayName: '22',
        }, {
          code: '_36',
          displayName: '36',
        }, {
          code: '_56',
          displayName: '56',
        }, {
          code: '_88',
          displayName: '88',
        }, {
          code: '_09',
          displayName: '09',
        }, {
          code: '_23',
          displayName: '23',
        }, {
          code: '_37',
          displayName: '37',
        }, {
          code: '_57',
          displayName: '57',
        }, {
          code: '_89',
          displayName: '89',
        }, {
          code: '_11',
          displayName: '11',
        }, {
          code: '_24',
          displayName: '24',
        }, {
          code: '_38',
          displayName: '38',
        }, {
          code: '_58',
          displayName: '58',
        }, {
          code: '_99',
          displayName: '99',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  }
];

export default EZZH;