const CJ_11X5 = {
  lotterySpecific: gameData => {
    const { interval, lotteryBeginTimeBase, code, lotteryTermCycle } = gameData || {};
    const intervalString = interval % 60 === 0 ? `${interval / 60}分` : `${interval}秒`;
    switch (code) {
      case 'GX11X5':
        return `该游戏保证公平公正；您可以放心投注、开奖时间，北京时间（GMT+8）每天从${lotteryBeginTimeBase}开始，每${intervalString}开一次奖,每天开奖${lotteryTermCycle}期。`;
      default:
        return `以下所有投注皆含本金\n该游戏保证公平公正；您可以放心投注、开奖时间，北京时间（GMT+8）每天从${lotteryBeginTimeBase}开始，每${intervalString}开一次奖,每天开奖${lotteryTermCycle}期。`;
    }
  },
  templateSpecific: () => ([{
    header: '1.【单码】- 指第一球、第二球、第三球、第四球、第五球出现的顺序与号码为派彩依据。',
    body: [
      '单号：如现场滚球第一个开奖号码为10号，投注第一球为10号则视为中奖，其它号码视为不中奖 大小：开出的号码大于或等于6为大，小于或等于5为小，开出11为和 (不计算输赢)',
    ]
  }, {
    header: '2.【总和】-以全部开出的5个号码，加起来的总和来判定。',
    body: [
      '总和大小: 所有开奖号码数字加总值大于30为和大；总和值小于30为和小；若总和值等于30为和 (不计算输赢)。',
      '总和单双: 所有开奖号码数字加总值为单数叫和单，如11、31；加总值为双数叫和双，如42、30。',
      '总和尾数大小: 所有开奖号码数字加总值的尾数，大于或等于5为尾大，小于或等于4为尾小。',
    ]
  }, {
    header: '3.【龙虎】',
    body: [
      '龙：第一球开奖号码大于第五球开奖号码，如第一球开出10，第五球开出7。',
      '虎：第一球开奖号码小于第五球开奖号码，如第一球开出3，第五球开出7。',
    ]
  }, {
    header: '4.选号- 选号玩法是由1~11号中，选出1~5个号码为一投注组合来进行投注。',
    body: [
      '一中一: 投注1个号码与当期开奖的5个号码中任1个号码相同，视为中奖。',
      '任选二: 投注2个号码与当期开奖的5个号码中任2个号码相同(顺序不限)，视为中奖。',
      '任选三: 投注3个号码与当期开奖的5个号码中任3个号码相同(顺序不限)，视为中奖。',
      '任选四: 投注4个号码与当期开奖的5个号码中任4个号码相同(顺序不限)，视为中奖。',
      '任选五: 投注5个号码与当期开奖的5个号码中5个号码相同(顺序不限)，视为中奖。',
      '任选六: 投注6个号码中任5个号码与当期开奖的5个号码相同(顺序不限)，视为中奖。',
      '任选七: 投注7个号码中任5个号码与当期开奖的5个号码相同(顺序不限)，视为中奖。',
      '任选八: 投注8个号码中任5个号码与当期开奖的5个号码相同(顺序不限)，视为中奖。',
      '组选前二: 投注的2个号码与当期顺序开出的5个号码中的前2个号码相同，视为中奖。',
      '组选前三: 投注的3个号码与当期顺序开出的5个号码中的前3个号码相同，视为中奖。',
      '直选前二: 投注的2个号码与当期顺序开出的5个号码中的前2个号码相同且顺序一致，视为中奖。',
      '直选前三: 投注的3个号码与当期顺序开出的5个号码中的前3个号码相同且顺序一致，视为中奖。',
    ]
  }])
};

export default CJ_11X5;