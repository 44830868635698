import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';

import './styles.scss';

import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';

import Banner1 from '../../../assets/image/home/banner_1.png';
import Banner2 from '../../../assets/image/home/banner_2.png';
import Banner3 from '../../../assets/image/home/banner_3.png';
import Banner4 from '../../../assets/image/home/banner_4.png';

SwiperCore.use([Pagination, Autoplay, Navigation]);

const items = [
  {
    src: Banner1,
    altText: 'Slide 1',
    key: 1,
  },
  {
    src: Banner2,
    altText: 'Slide 2',
    key: 2,
  },
  {
    src: Banner3,
    altText: 'Slide 3',
    key: 3,
  },
  {
    src: Banner4,
    altText: 'Slide 4',
    key: 4,
  },
];

function CarouselBanner() {
  return (
    <Swiper
      spaceBetween={30}
      slidesPerView={1}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false
      }}
      pagination={{
        clickable: true
      }}
      style={{
        height: 'fit-content'
      }}
      loop
      navigation
    >
      {items.map(item => (
        <SwiperSlide key={item.key}>
          <img alt={item.altText} src={item.src} width="100%" height="327px" className="rounded w-100" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default CarouselBanner;