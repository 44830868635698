import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';
import gameItemIconMap from '../../../GameItemIcon';

const LM = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '正码',
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '赔率', '金额'],
      mainColumnLength: 4,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayOrText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'ZM1',
          displayName: '1',
          className: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'ZM6',
          displayName: '6',
          className: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'ZM11',
          displayName: '11',
          className: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'ZM16',
          displayName: '16',
          className: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'ZM2',
          displayName: '2',
          className: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'ZM7',
          displayName: '7',
          className: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'ZM12',
          displayName: '12',
          className: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'ZM17',
          displayName: '17',
          className: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'ZM3',
          displayName: '3',
          className: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'ZM8',
          displayName: '8',
          className: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'ZM13',
          displayName: '13',
          className: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'ZM18',
          displayName: '18',
          className: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'ZM4',
          displayName: '4',
          className: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'ZM9',
          displayName: '9',
          className: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'ZM14',
          displayName: '14',
          className: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'ZM19',
          displayName: '19',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'ZM5',
          displayName: '5',
          className: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'ZM10',
          displayName: '10',
          className: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'ZM15',
          displayName: '15',
          className: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'ZM20',
          displayName: '20',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '总和',
      cycleHeaderTitle: true,
      mainColumnLength: 3,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'ZM21',
          displayName: '总和大',
        }, {
          code: 'ZM23',
          displayName: '总和单',
        }, {
          code: 'ZM25',
          displayName: '总和尾大',
        }, {
          code: 'ZM22',
          displayName: '总和小',
        }, {
          code: 'ZM24',
          displayName: '总和双',
        }, {
          code: 'ZM26',
          displayName: '总和尾小',
        },
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default LM;