const GXK10 = {
  lotterySpecific: gameData => {
    const { interval, lotteryBeginTimeBase, lotteryEndTimeBase, code, lotteryTermCycle } = gameData || {};
    const endTimeString = lotteryEndTimeBase ? `开到晚上${lotteryEndTimeBase}` : '';
    const intervalString = interval % 60 === 0 ? `${interval / 60}分` : `${interval}秒`;
    switch (code) {
      default:
        return `广西福利彩票快乐十分经国家财政部批准，由中国福利彩票发行管理中心在广西区内发行，由广西福利彩票发行中心承销。\n该游戏的投注时间、开奖时间和开奖号码与广西福利彩票快乐十分完全同步，北京时间（GMT+8）每天上午${lotteryBeginTimeBase}${endTimeString}，每${intervalString}钟开一次奖，每天开奖${lotteryTermCycle}期。\n假设快乐十分开奖结果为1、2、3、4、5，那么正码是1、2、3、4，特码是5。（每个位数在开奖时，从1 ~ 21中摇出一个结果）选号玩法。`;
    }
  },
  templateSpecific: () => (
    [{
      header: '1.单双大小：',
      body: [
        '根据正码一~正码四及特码开出的球号判断胜负。',
        '大小：开出号码01~10为小，11~20为大，21为和。',
        '单双：开出号码21为和。',
      ]
    }, {
      header: '2.尾大、小：',
      body: [
        '开出之特码尾数大于或等于5为尾大，小于或等于4为尾小，出21为和。'
      ]
    }, {
      header: '3.合单双：',
      body: [
        '以号特码个位和十位数字之合来判断胜负，如01、12、16为合单；02、11、20为合双；出21为和。'
      ]
    }, {
      header: '4.特码：',
      body: [
        '开出号码21为赢，其他玩法为全和。'
      ]
    }, {
      header: '5.福禄寿喜：',
      body: [
        '依号码顺序将21除外的20个号码分为四种类（如下），再以四种类下注。',
        '福：01、02、03、04、05',
        '禄：06、07、08、09、10',
        '寿：11、12、13、14、15',
        '寿：11、12、13、14、15',
        '若当期号码在相对应的球位落在下注三种类范围内，视为中奖。',
      ]
    }, {
      header: '6.色波：',
      body: [
        '依特码、正码1~4将21个号码分为三种类，分别为红、蓝、绿（如下），再依三种类下注。',
        '红：1、4、7、10、13、16、19',
        '蓝：2、5、8、11、14、17、20',
        '绿：3、6、9、12、15、18、21',
        '若当期号码在相对应的球位落在下注三种类范围内，视为中奖。',
      ]
    }, {
      header: '7.合数单双：',
      body: [
        '合单：指开出球号的个位数加上十位数之和为“单数”，如1、14、18。',
        '合双：指开出球号的个位数加上十位数之和为“双数”，如11、15、19。',
      ]
    }, {
      header: '8.总和：',
      body: [
        '总大小：数字总和值56~95为总大，数字总和值15~54为总小，所有5个开奖号码的总和值为55打和。',
        '总单双：数字总和值是双数为总和双，数字总和值是单数为总和单。',
        '总尾大小：所有5个开奖号码的数字总和数值的个位数大于或等于5为总尾大，小于或等于4为总尾小假如投注组合符合中奖结果，视为中奖，其余情形视为不中奖。',
      ]
    }, {
      header: '9.龙虎：',
      body: [
        '龙：第一球开奖号码大于第五球开奖号码，如第一球开出10，第五球开出7。',
        '虎：第一球开奖号码小于第五球开奖号码，如第一球开出3，第五球开出7。',
      ]
    }])
};

export default GXK10;