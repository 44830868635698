import FantLiangMian from './FantLiangMian';
import NI from './NI';
import ZESM from './ZESM';

const renderBall = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [[{
    value: result[0],
    className: 'circle circleBlue'
  }, {
    className: 'fantUnderLine'
  }], [{
    value: result[1],
    className: 'circle circleBlue'
  }, {
    className: 'fantUnderLine'
  }], [{
    value: result[2],
    className: 'circle circleBlue'
  }, {
    className: 'fantUnderLine'
  }], [{
    value: result[3],
    className: 'circle circleBlue'
  }, {
    className: 'fantUnderLine'
  }], {
    value: result[4],
    className: 'circle circleBlue'
  }, {
    value: '+',
  }, [{
    value: result.slice(0, 4).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0),
    className: 'circle circleYellow'
  }, {
    value: data.sx,
  }]];
  return tempResult;
};

const renderZHFDSDX = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  const sum = result.slice(0, 4).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0);
  const tempResult = [];
  tempResult.push({
    value: sum,
    className: 'redText',
  }, {
    value: (sum % 4) === 0 ? 4 : sum % 4,
    className: 'redText',
  }, {
    value: (sum % 4) % 2 ? '单' : '双',
    className: (sum % 4) % 2 ? 'resultItem resultItemBlue' : 'resultItem resultItemOrange',
  }, {
    value: (sum % 4) > 2 ? '大' : '小',
    className: (sum % 4) > 2 ? 'resultItem resultItemBlue' : 'resultItem resultItemOrange',
  });

  return tempResult;
};

const FANT = {
  children: [
    {
      tabId: 'FantLiangMian',
      label: '正-两面-角',
      children: FantLiangMian.FantLiangMian,
      settingCode: 'LM,ZE,JI'
    },
    {
      tabId: 'ZESM',
      label: '番-三门',
      children: ZESM.ZESM,
      settingCode: 'SM,FA'
    },
    {
      tabId: 'NI',
      label: '念',
      children: NI.NI,
      settingCode: 'NI'
    },
  ],
  results: {
    TabList: [{
      code: 'NUMBER',
      renderFunc: data => renderBall(data)
    },
    {
      code: 'ZH_FH_DX_DS',
      renderFunc: data => renderZHFDSDX(data)
    }]
  }
};

export default FANT;