import React, { useEffect, useState } from 'react';

import { Select } from 'antd';

import { connect } from 'react-redux';

import { cartActions, gameActions } from '../../../../redux/actions';

import './styles.scss';

const { Option } = Select;


const mapStateToProps = state => ({
  betTypeSelection: state.gameReducers.betTypeSelection,
  sectionSelection: state.gameReducers.sectionSelection,
  gameInfo: state.placeBetReducers.gameInfo,
  currentSelected: state.gameReducers.currentSelected,
  selectedSubType: state.gameReducers.selectedSubType,
});

const mapDispatchToProps = dispatch => ({
  updateBetTypeSelection: item => dispatch(gameActions.updateBetTypeSelection(item)),
  updateSelectedDan: dan => dispatch(gameActions.updateSelectedDan(dan)),
  updateCurrentSelected: val => dispatch(gameActions.updateCurrentSelected(val)),
  resetItemInCart: () => dispatch(cartActions.resetItemInCart()),
});

const PengAndDan = ({ currentSelected, gameInfo, updateBetTypeSelection, betTypeSelection, sectionSelection, updateSelectedDan, updateCurrentSelected, resetItemInCart }) => {
  const [_gameInfo, setGameInfo] = useState();
  const [_oddStatus, setOddStatus] = useState('--');

  const [_betTypeSection, setBetTypeSection] = useState({
    value: 'LP'
  });

  useEffect(() => {
    if (JSON.stringify(gameInfo) !== JSON.stringify(_gameInfo)) {
      setGameInfo(gameInfo);
    }
  }, [gameInfo]);

  useEffect(() => {
    if (_gameInfo) {
      let _odds = '--';
      if (sectionSelection && sectionSelection?.dynamicChildren) {
        const keyCode = `${sectionSelection.dynamicChildren.code}${[].concat(currentSelected).length}`;
        _odds = _gameInfo && _gameInfo.oddsList?.[keyCode] ? _gameInfo.oddsList[keyCode].enabled === 1 ? _gameInfo.oddsList[keyCode].odds : '停押' : '--';
        setOddStatus(_odds);
      }
    }
  }, [_gameInfo, currentSelected]);

  useEffect(() => {
    if (sectionSelection) {
      updateBetTypeSelection({
        value: 'LP'
      });
    } else {
      updateBetTypeSelection();
    }
  }, [sectionSelection]);

  useEffect(() => {
    if (betTypeSelection && JSON.stringify(betTypeSelection) !== JSON.stringify(_betTypeSection)) {
      setBetTypeSection(betTypeSelection);
    }
  }, [betTypeSelection]);

  return (
    <div className="lianPengDanTuoWrapper">
      <div className="oddsPart">
        {sectionSelection && sectionSelection?.children && [].concat(sectionSelection.children).map(item => (
          <div className="d-flex align-items-center">
            {item.displayName && <span>{item.displayName }</span> }
            <span className="oddsStyle">
              {
                _gameInfo && _gameInfo?.oddsList?.[item.code] ? _gameInfo?.oddsList[item.code]?.enabled === 1 ? _gameInfo?.oddsList[item.code]?.odds : '停押' : '--'
              }
            </span>
          </div>
        ))}
        {sectionSelection && sectionSelection?.dynamicChildren
          && (
          <div className="d-flex align-items-center">
            {sectionSelection?.dynamicChildren.displayName && <span>{sectionSelection?.dynamicChildren.displayName }</span> }
            <span className="oddsStyle">
              {
                _oddStatus
              }
            </span>
          </div>
          )}
      </div>
      {sectionSelection && sectionSelection?.danList && (
      <div className="LianPengDanTuoPart">
        <span
          className={`${_betTypeSection.value === 'LP' && 'selected'}`}
          onClick={() => {
            updateCurrentSelected([]);
            resetItemInCart();
            updateSelectedDan([]);
            updateBetTypeSelection({
              value: 'LP'
            });
          }}
        >
          连碰
        </span>
        <span
          className={`${_betTypeSection.value === 'DT' && 'selected'}`}
          onClick={() => {
            updateCurrentSelected([]);
            resetItemInCart();
            updateSelectedDan([]);
            updateBetTypeSelection({
              value: 'DT',
              selectedDan: sectionSelection && sectionSelection.danList[0]
            });
          }}
        >
          胆拖
        </span>
        <Select
          className="danTuoSelect"
          value={_betTypeSection?.selectedDan || '1'}
          disabled={_betTypeSection.value === 'LP'}
          onChange={item => {
            updateBetTypeSelection({
              value: 'DT', selectedDan: item
            });
          }}
        >
          {
            sectionSelection && sectionSelection?.danList && sectionSelection.danList.map((item, index) => (
              <Option
                value={`${item}`}
                key={`dan_${index.toString()}`}
              >
                {item}
              </Option>
            ))
          }
        </Select>
      </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PengAndDan);