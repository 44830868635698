import React from 'react';
import { connect } from 'react-redux';

import { Button, Toast, ToastBody, ToastHeader } from 'reactstrap';
import { memberActions } from '../../../redux/actions';

const mapDispatchToProps = dispatch => ({
  login: (username, password, callback = () => {}) => dispatch(memberActions.login(username, password, callback)),
});

const ToastMessage = ({ className, isOpen, onClick, toastHeader, toastBody, isButton, account, password, login }) => (
  <Toast className={className} isOpen={isOpen}>
    <ToastHeader close={<Button close onClick={onClick} />}>
      {toastHeader}
    </ToastHeader>
    <ToastBody className="text-white">
      <div>{toastBody}</div>
      {isButton && (
        <Button
          outline
          color="light"
          className="text-black mt-1"
          onClick={() => login(account, password)}
        >
          确认
        </Button>
      )}
    </ToastBody>
  </Toast>
);

export default connect(null, mapDispatchToProps)(ToastMessage);