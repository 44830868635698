import EZDW from './EZDW';
import EZHS from './EZHS';
import EZZH from './EZZH';
import FSZH from './FSZH';
import HM15 from './HM15';
import KD from './KD';
import NNSH from './NNSH';
import QZHS from './QZHS';
import SZDW from './SZDW';
import SZHS from './SZHS';
import SZZH from './SZZH';
import YZZH from './YZZH';
import ZH from './ZH';
import ZLH from './ZLH';
import ZSL from './ZSL';
import ZSS from './ZSS';

const renderQiu = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  return result.map(item => (
    {
      value: item, className: 'SSC_ball'
    }
  ));
};
const renderZH = data => {
  if (data && !data.openResult) return;
  const tempResult = [];
  let championSum = 0;
  const result = data.openResult.split(',');
  result.forEach(item => (championSum += parseInt(item, 10)));
  tempResult.push({
    value: championSum,
    className: 'redText'
  });
  tempResult.push({
    value: championSum > 22 ? '大' : '小',
    className: championSum > 22 ? 'resultItem resultItemBlue' : 'resultItem resultItemOrange',
  });
  tempResult.push({
    value: championSum % 2 ? '单' : '双',
    className: championSum % 2 ? 'resultItem resultItemOrange' : 'resultItem resultItemBlue',
  });
  tempResult.push({
    value: (parseInt(result[0], 10)) > parseInt(result[result.length - 1], 10) ? '龙' : ((parseInt(result[0], 10)) < parseInt(result[result.length - 1], 10)) ? '虎' : '和',
    className: (parseInt(result[0], 10)) > parseInt(result[result.length - 1], 10) ? 'resultItem resultItemBlue' : ((parseInt(result[0], 10)) < parseInt(result[result.length - 1], 10)) ? 'resultItem resultItemOrange' : 'resultItem resultItemGreen'
  });
  return tempResult;
};

const generateSSCResultBySelectedArr = arr => {
  const zero = parseInt(arr[0], 10);
  const one = parseInt(arr[1], 10);
  const two = parseInt(arr[2], 10);
  if (arr[0] === arr[1] && arr[0] === arr[2] && arr[1] === arr[2]) {
    return '豹子';
  } if (arr[0] === arr[1] || arr[0] === arr[2] || arr[1] === arr[2]) {
    return '对子';
  } if ((zero === 0 && two === 9 && (one === 8 || one === 1)) || (zero === (one - 1) && one === (two - 1))) {
    return '顺子';
  } if ((zero === (one - 1) || one === (two - 1)) || (zero === 0 && (one === 9 || two === 9)) || (one === 0 && (zero === 9 || two === 9)) || (two === 0 && (zero === 9 || one === 9))) {
    return '半顺';
  }
  return '杂六';
};

const renderQ = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  const selection = [result[0], result[1], result[2]];
  tempResult.push({
    value: generateSSCResultBySelectedArr(selection.sort()),
  });
  return tempResult;
};
const renderZ = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  const selection = [result[1], result[2], result[3]];
  tempResult.push({
    value: generateSSCResultBySelectedArr(selection.sort()),
  });
  return tempResult;
};
const renderH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  const selection = [result[2], result[3], result[4]];
  tempResult.push({
    value: generateSSCResultBySelectedArr(selection.sort()),
  });
  return tempResult;
};

const SSC = {
  children: [
    {
      tabId: 'ZhengHe',
      label: '整合',
      children: ZH.ZH,
      settingCode: 'LM,LHH,QH15,QZH3'
    },
    {
      tabId: 'Qiu15',
      label: '1-5球',
      children: HM15.HM15,
      settingCode: 'LM,QH15'
    },
    {
      tabId: 'QZH3',
      label: '前中后三',
      children: QZHS.QZHS,
      settingCode: 'QZH3'
    },
    {
      tabId: 'YZZH',
      label: '一字组合',
      children: YZZH.YZZH,
      settingCode: 'ZH1'
    },
    {
      tabId: 'EZZH',
      label: '二字组合',
      children: EZZH.EZZH,
      settingCode: 'ZH2'
    },
    {
      tabId: 'SZZH',
      label: '三字组合',
      children: SZZH.SZZH,
      settingCode: 'ZH3'
    },
    {
      tabId: 'EZDW',
      label: '二字定位',
      children: EZDW.EZDW,
      settingCode: 'DW2'
    },
    {
      tabId: 'SZDW',
      label: '三字定位',
      children: SZDW.SZDW,
      settingCode: 'DW3'
    },
    {
      tabId: 'EZHS',
      label: '二字和数',
      children: EZHS.EZHS,
      settingCode: 'HS2,HS2W'
    },
    {
      tabId: 'SZHS',
      label: '三字和数',
      children: SZHS.SZHS,
      settingCode: 'HS3,HS3W'
    },
    {
      tabId: 'ZX3',
      label: '组选三',
      children: ZSS.ZSS,
      settingCode: 'ZX3'
    },
    {
      tabId: 'ZX6',
      label: '组选六',
      children: ZSL.ZSL,
      settingCode: 'ZX6'
    },
    {
      tabId: 'FSZH',
      label: '复式组合',
      children: FSZH.FSZH,
      settingCode: 'FSZH'
    },
    {
      tabId: 'KD',
      label: '跨度',
      children: KD.KD,
      settingCode: 'KD'
    },
    {
      tabId: 'ZLH',
      label: '组合龙虎',
      children: ZLH.ZLH,
      settingCode: 'ZLH'
    },
    {
      tabId: 'NNSH',
      label: '牛牛/梭哈',
      children: NNSH.NNSH,
      midCode: 'NN,NNLMDS,NNLMDX,SH'
    }
  ],
  results: {
    TabList: [{
      code: 'NUMBER',
      renderFunc: data => renderQiu(data)
    },
    {
      code: 'ZH_LH',
      renderFunc: data => renderZH(data)
    },
    {
      code: 'QZHS',
      renderFunc: data => { if (data && !data.openResult) return; return renderQ(data).concat(renderZ(data), renderH(data)); }
    }]
  }
};

export default SSC;