import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const QZHS = [
  {
    componentType: CollapseType,
    header: '前三',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'Q31', // keyCode
          displayName: '豹子',
        }, {
          code: 'Q32',
          displayName: '顺子',
        }, {
          code: 'Q33',
          displayName: '对子',
        }, {
          code: 'Q34',
          displayName: '半顺',
        }, {
          code: 'Q35',
          displayName: '杂六',
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '中三',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'Z31', // keyCode
          displayName: '豹子',
        }, {
          code: 'Z32',
          displayName: '顺子',
        }, {
          code: 'Z33',
          displayName: '对子',
        }, {
          code: 'Z34',
          displayName: '半顺',
        }, {
          code: 'Z35',
          displayName: '杂六',
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '后三',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'H31', // keyCode
          displayName: '豹子',
        }, {
          code: 'H32',
          displayName: '顺子',
        }, {
          code: 'H33',
          displayName: '对子',
        }, {
          code: 'H34',
          displayName: '半顺',
        }, {
          code: 'H35',
          displayName: '杂六',
        }
      ]
    }
  }
];

export default QZHS;