import { ProbabilitySelectionPanel } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const SZHS = [
  {
    componentType: ProbabilitySelectionPanel,
    data: {
      showProbability: false,
      midCodeList: [{
        label: '前三',
        value: 'HS3Q3',
      }, {
        label: '中三',
        value: 'HS3Z3',
      }, {
        label: '后三',
        value: 'HS3H3',
      }],
    }
  },
  {
    componentType: QuickBet,
  }, {
    componentType: Template2,
    data: {
      tableTitle: '前三和数',
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '赔率', '金额'],
      mainColumnLength: 5,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      emptyItemStyle: ['column-3items-empty-label', 'column-3items-empty-odds', 'column-3items-empty-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: '_6',
          displayName: '0~6',
        }, {
          code: '_7',
          displayName: '7',
        }, {
          code: '_8',
          displayName: '8',
        }, {
          code: '_9',
          displayName: '9',
        }, {
          code: '_10',
          displayName: '10',
        }, {
          code: '_11',
          displayName: '11',
        }, {
          code: '_12',
          displayName: '12',
        }, {
          code: '_13',
          displayName: '13',
        }, {
          code: '_14',
          displayName: '14',
        }, {
          code: '_15',
          displayName: '15',
        }, {
          code: '_16',
          displayName: '16',
        }, {
          code: '_17',
          displayName: '17',
        }, {
          code: '_18',
          displayName: '18',
        }, {
          code: '_19',
          displayName: '19',
        }, {
          code: '_20',
          displayName: '20',
        }, {
          code: '_21',
          displayName: '21~27',
        }
      ]
    }
  }, {
    componentType: Template2,
    data: {
      tableTitle: '前三和数尾数',
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '赔率', '金额'],
      mainColumnLength: 5,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'W_0', // keyCode
          displayName: '0',
        }, {
          code: 'W_1',
          displayName: '1',
        }, {
          code: 'W_2',
          displayName: '2',
        }, {
          code: 'W_3',
          displayName: '3',
        }, {
          code: 'W_4',
          displayName: '4',
        }, {
          code: 'W_5',
          displayName: '5',
        }, {
          code: 'W_6',
          displayName: '6',
        }, {
          code: 'W_7',
          displayName: '7',
        }, {
          code: 'W_8',
          displayName: '8',
        }, {
          code: 'W_9',
          displayName: '9',
        }
      ]
    }
  }, {
    componentType: QuickBet,
  }
];

export default SZHS;