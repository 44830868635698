import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const ZLH = [
  {
    componentType: CollapseType,
    header: '万千',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'ZLHWQ_L',
          displayName: '龙'
        },
        {
          code: 'ZLHWQ_H',
          displayName: '虎'
        },
        {
          code: 'ZLHWQ_HE',
          displayName: '和'
        },
        {
          code: 'ZLHWQ_HD',
          displayName: '大'
        },
        {
          code: 'ZLHWQ_HX',
          displayName: '小'
        },
        {
          code: 'ZLHWQ_HDAN',
          displayName: '单'
        },
        {
          code: 'ZLHWQ_HS',
          displayName: '双'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '万佰',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'ZLHWB_L',
          displayName: '龙'
        },
        {
          code: 'ZLHWB_H',
          displayName: '虎'
        },
        {
          code: 'ZLHWB_HE',
          displayName: '和'
        },
        {
          code: 'ZLHWB_HD',
          displayName: '大'
        },
        {
          code: 'ZLHWB_HX',
          displayName: '小'
        },
        {
          code: 'ZLHWB_HDAN',
          displayName: '单'
        },
        {
          code: 'ZLHWB_HS',
          displayName: '双'
        }
      ]
    }
  }, {
    componentType: CollapseType,
    header: '万拾',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'ZLHWS_L',
          displayName: '龙'
        },
        {
          code: 'ZLHWS_H',
          displayName: '虎'
        },
        {
          code: 'ZLHWS_HE',
          displayName: '和'
        },
        {
          code: 'ZLHWS_HD',
          displayName: '大'
        },
        {
          code: 'ZLHWS_HX',
          displayName: '小'
        },
        {
          code: 'ZLHWS_HDAN',
          displayName: '单'
        },
        {
          code: 'ZLHWS_HS',
          displayName: '双'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '万个',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'ZLHWG_L',
          displayName: '龙'
        },
        {
          code: 'ZLHWG_H',
          displayName: '虎'
        },
        {
          code: 'ZLHWG_HE',
          displayName: '和'
        },
        {
          code: 'ZLHWG_HD',
          displayName: '大'
        },
        {
          code: 'ZLHWG_HX',
          displayName: '小'
        },
        {
          code: 'ZLHWG_HDAN',
          displayName: '单'
        },
        {
          code: 'ZLHWG_HS',
          displayName: '双'
        }
      ]
    }
  }, {
    componentType: CollapseType,
    header: '千佰',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'ZLHQB_L',
          displayName: '龙'
        },
        {
          code: 'ZLHQB_H',
          displayName: '虎'
        },
        {
          code: 'ZLHQB_HE',
          displayName: '和'
        },
        {
          code: 'ZLHQB_HD',
          displayName: '大'
        },
        {
          code: 'ZLHQB_HX',
          displayName: '小'
        },
        {
          code: 'ZLHQB_HDAN',
          displayName: '单'
        },
        {
          code: 'ZLHQB_HS',
          displayName: '双'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '千拾',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'ZLHQS_L',
          displayName: '龙'
        },
        {
          code: 'ZLHQS_H',
          displayName: '虎'
        },
        {
          code: 'ZLHQS_HE',
          displayName: '和'
        },
        {
          code: 'ZLHQS_HD',
          displayName: '大'
        },
        {
          code: 'ZLHQS_HX',
          displayName: '小'
        },
        {
          code: 'ZLHQS_HDAN',
          displayName: '单'
        },
        {
          code: 'ZLHQS_HS',
          displayName: '双'
        }
      ]
    }
  }, {
    componentType: CollapseType,
    header: '千个',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'ZLHQG_L',
          displayName: '龙'
        },
        {
          code: 'ZLHQG_H',
          displayName: '虎'
        },
        {
          code: 'ZLHQG_HE',
          displayName: '和'
        },
        {
          code: 'ZLHQG_HD',
          displayName: '大'
        },
        {
          code: 'ZLHQG_HX',
          displayName: '小'
        },
        {
          code: 'ZLHQG_HDAN',
          displayName: '单'
        },
        {
          code: 'ZLHQG_HS',
          displayName: '双'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '佰拾',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'ZLHBS_L',
          displayName: '龙'
        },
        {
          code: 'ZLHBS_H',
          displayName: '虎'
        },
        {
          code: 'ZLHBS_HE',
          displayName: '和'
        },
        {
          code: 'ZLHBS_HD',
          displayName: '大'
        },
        {
          code: 'ZLHBS_HX',
          displayName: '小'
        },
        {
          code: 'ZLHBS_HDAN',
          displayName: '单'
        },
        {
          code: 'ZLHBS_HS',
          displayName: '双'
        }
      ]
    }
  }, {
    componentType: CollapseType,
    header: '佰个',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'ZLHBG_L',
          displayName: '龙'
        },
        {
          code: 'ZLHBG_H',
          displayName: '虎'
        },
        {
          code: 'ZLHBG_HE',
          displayName: '和'
        },
        {
          code: 'ZLHBG_HD',
          displayName: '大'
        },
        {
          code: 'ZLHBG_HX',
          displayName: '小'
        },
        {
          code: 'ZLHBG_HDAN',
          displayName: '单'
        },
        {
          code: 'ZLHBG_HS',
          displayName: '双'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '拾个',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'ZLHSG_L',
          displayName: '龙'
        },
        {
          code: 'ZLHSG_H',
          displayName: '虎'
        },
        {
          code: 'ZLHSG_HE',
          displayName: '和'
        },
        {
          code: 'ZLHSG_HD',
          displayName: '大'
        },
        {
          code: 'ZLHSG_HX',
          displayName: '小'
        },
        {
          code: 'ZLHSG_HDAN',
          displayName: '单'
        },
        {
          code: 'ZLHSG_HS',
          displayName: '双'
        }
      ]
    }
  }
];

export default ZLH;