import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import { cartActions, gameActions } from '../../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  gameInfo: state.placeBetReducers.gameInfo,
  cart: state.cartReducers.cart,
  presetAmount: state.gameReducers.presetAmount,
});

const mapDispatchToProps = dispatch => ({
  updateSectionSelection: selection => dispatch(gameActions.updateSectionSelection(selection)),
  addItemToCart: (item, reset) => dispatch(cartActions.addItemToCart(item, reset)),
  removeItemInCart: keyCode => dispatch(cartActions.removeItemInCart(keyCode)),
});

const FANT = ({ gameInfo, cart, presetAmount, addItemToCart, removeItemInCart }) => {
  const [_gameInfo, setGameInfo] = useState();

  useEffect(() => {
    if (JSON.stringify(gameInfo) !== JSON.stringify(_gameInfo)) {
      setGameInfo(gameInfo);
    }
  }, [gameInfo]);

  const onItemClick = (displayName, code) => {
    if (!_gameInfo.oddsList) return;
    const keyCode = code;
    const currentOddItem = _gameInfo && _gameInfo.oddsList && (_gameInfo.oddsList[keyCode]);
    const { midType, odds, enabled } = currentOddItem || {};
    if (enabled === 0) return;
    if (cart && cart[keyCode]) {
      removeItemInCart(keyCode);
    } else {
      return addItemToCart({
        [keyCode]: {
          displayName,
          amount: presetAmount || 0,
          midType,
          keyCode,
          odds,
        }
      });
    }
  };

  return (
    <div className="FantLiangMianWrapper">
      <div className="FantLiangMianTitle">正-两面-角</div>
      <div className="FantTanContent">
        <div>
          <div onClick={() => onItemClick('3-4角', 'JI3_4')} className={`betItem ${cart && cart?.JI3_4 && 'selected'}`}>
            <div>3-4角</div>
            <div className="itemOdds">{_gameInfo && _gameInfo.oddsList ? _gameInfo.oddsList?.JI3_4?.enabled === 1 ? _gameInfo.oddsList?.JI3_4?.odds : '停押' : '--'}</div>
          </div>
          <div onClick={() => onItemClick('3正', 'ZE3')} className={`betItem ${cart && cart?.ZE3 && 'selected'}`}>
            <div>3正</div>
            <div className="itemOdds">{_gameInfo && _gameInfo.oddsList ? _gameInfo.oddsList?.ZE3?.enabled === 1 ? _gameInfo.oddsList?.ZE3?.odds : '停押' : '--'}</div>
          </div>
          <div onClick={() => onItemClick('1-2角', 'JI1_2')} className={`betItem ${cart && cart?.JI1_2 && 'selected'}`}>
            <div>1-2角</div>
            <div className="itemOdds">{_gameInfo && _gameInfo.oddsList ? _gameInfo.oddsList?.JI1_2?.enabled === 1 ? _gameInfo.oddsList?.JI1_2?.odds : '停押' : '--'}</div>
          </div>
        </div>
        <div>
          <div onClick={() => onItemClick('4正', 'ZE4')} className={`betItem ${cart && cart?.ZE4 && 'selected'}`}>
            <div>4正</div>
            <div className="itemOdds">{_gameInfo && _gameInfo.oddsList ? _gameInfo.oddsList?.ZE4?.enabled === 1 ? _gameInfo.oddsList?.ZE4?.odds : '停押' : '--'}</div>
          </div>
          <div className="centerContent">
            <div onClick={() => onItemClick('大', 'DX_D')} className={`betItem ${cart && cart?.DX_D && 'selected'}`}>
              <div>大</div>
              <div className="itemOdds">{_gameInfo && _gameInfo.oddsList ? _gameInfo.oddsList?.DX_D?.enabled === 1 ? _gameInfo.oddsList?.DX_D?.odds : '停押' : '--'}</div>
            </div>
            <div onClick={() => onItemClick('小', 'DX_X')} className={`betItem ${cart && cart?.DX_X && 'selected'}`}>
              <div>小</div>
              <div className="itemOdds">{_gameInfo && _gameInfo.oddsList ? _gameInfo.oddsList?.DX_X?.enabled === 1 ? _gameInfo.oddsList?.DX_X?.odds : '停押' : '--'}</div>
            </div>
            <div onClick={() => onItemClick('单', 'DS_D')} className={`betItem ${cart && cart?.DS_D && 'selected'}`}>
              <div>单</div>
              <div className="itemOdds">{_gameInfo && _gameInfo.oddsList ? _gameInfo.oddsList?.DS_D?.enabled === 1 ? _gameInfo.oddsList?.DS_D?.odds : '停押' : '--'}</div>
            </div>
            <div onClick={() => onItemClick('双', 'DS_S')} className={`betItem ${cart && cart?.DS_S && 'selected'}`}>
              <div>双</div>
              <div className="itemOdds">{_gameInfo && _gameInfo.oddsList ? _gameInfo.oddsList?.DS_S?.enabled === 1 ? _gameInfo.oddsList?.DS_S?.odds : '停押' : '--'}</div>
            </div>
          </div>
          <div onClick={() => onItemClick('2正', 'ZE2')} className={`betItem ${cart && cart?.ZE2 && 'selected'}`}>
            <div>2正</div>
            <div className="itemOdds">{_gameInfo && _gameInfo.oddsList ? _gameInfo.oddsList?.ZE2?.enabled === 1 ? _gameInfo.oddsList?.ZE2?.odds : '停押' : '--'}</div>
          </div>
        </div>
        <div>
          <div onClick={() => onItemClick('1-4角', 'JI1_4')} className={`betItem ${cart && cart?.JI1_4 && 'selected'}`}>
            <div>1-4角</div>
            <div className="itemOdds">{_gameInfo && _gameInfo.oddsList ? _gameInfo.oddsList?.JI1_4?.enabled === 1 ? _gameInfo.oddsList?.JI1_4?.odds : '停押' : '--'}</div>
          </div>
          <div onClick={() => onItemClick('1正', 'ZE1')} className={`betItem ${cart && cart?.ZE1 && 'selected'}`}>
            <div>1正</div>
            <div className="itemOdds">{_gameInfo && _gameInfo.oddsList ? _gameInfo.oddsList?.ZE1?.enabled === 1 ? _gameInfo.oddsList?.ZE1?.odds : '停押' : '--'}</div>
          </div>
          <div onClick={() => onItemClick('2-3角', 'JI2_3')} className={`betItem ${cart && cart?.JI2_3 && 'selected'}`}>
            <div>2-3角</div>
            <div className="itemOdds">{_gameInfo && _gameInfo.oddsList ? _gameInfo.oddsList?.JI2_3?.enabled === 1 ? _gameInfo.oddsList?.JI2_3?.odds : '停押' : '--'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FANT);