import { StatusCodes } from 'http-status-codes';

import { SET_CHECK_LATENCY, SET_PING_RESULT } from '../actionTypes';
import { apiService } from '../../../library';

const setCheckLatency = checkLatencyData => ({
  type: SET_CHECK_LATENCY,
  value: checkLatencyData,
});

const setPingResult = pingResultData => ({
  type: SET_PING_RESULT,
  value: pingResultData,
});

const getCheckLatency = () => dispatch => {
  const start = new Date();
  apiService.get('/ping/CheckLatency').then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      data.result.time = (new Date()) - start;
      return dispatch(setCheckLatency(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};


const latencyTest = route => dispatch => {
  const start = new Date();
  fetch(route).then(response => {
    if (response.ok) {
      dispatch(setPingResult({
        route,
        latency: (new Date()) - start
      }));
    } else {
      dispatch(setPingResult({
        route,
        latency: '99999'
      }));
    }
  }).catch(() => {
    dispatch(setPingResult({
      route,
      latency: '99999'
    }));
  });
};

export default {
  getCheckLatency,
  latencyTest
};