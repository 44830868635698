import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const YZZH = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '一字组合（前三）',
      mainColumnLength: 5,
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'ZH1Q3_0', // keyCode
          displayName: ['0'],
        }, {
          code: 'ZH1Q3_1',
          displayName: ['1'],
        }, {
          code: 'ZH1Q3_2',
          displayName: ['2'],
        }, {
          code: 'ZH1Q3_3',
          displayName: ['3'],
        }, {
          code: 'ZH1Q3_4',
          displayName: ['4'],
        }, {
          code: 'ZH1Q3_5',
          displayName: ['5'],
        }, {
          code: 'ZH1Q3_6',
          displayName: ['6'],
        }, {
          code: 'ZH1Q3_7',
          displayName: ['7'],
        }, {
          code: 'ZH1Q3_8',
          displayName: ['8'],
        }, {
          code: 'ZH1Q3_9',
          displayName: ['9'],
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '一字组合（中三）',
      mainColumnLength: 5,
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'ZH1Z3_0', // keyCode
          displayName: ['0'],
        }, {
          code: 'ZH1Z3_1',
          displayName: ['1'],
        }, {
          code: 'ZH1Z3_2',
          displayName: ['2'],
        }, {
          code: 'ZH1Z3_3',
          displayName: ['3'],
        }, {
          code: 'ZH1Z3_4',
          displayName: ['4'],
        }, {
          code: 'ZH1Z3_5',
          displayName: ['5'],
        }, {
          code: 'ZH1Z3_6',
          displayName: ['6'],
        }, {
          code: 'ZH1Z3_7',
          displayName: ['7'],
        }, {
          code: 'ZH1Z3_8',
          displayName: ['8'],
        }, {
          code: 'ZH1Z3_9',
          displayName: ['9'],
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '一字组合（后三）',
      mainColumnLength: 5,
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'ZH1H3_0', // keyCode
          displayName: ['0'],
        }, {
          code: 'ZH1H3_1',
          displayName: ['1'],
        }, {
          code: 'ZH1H3_2',
          displayName: ['2'],
        }, {
          code: 'ZH1H3_3',
          displayName: ['3'],
        }, {
          code: 'ZH1H3_4',
          displayName: ['4'],
        }, {
          code: 'ZH1H3_5',
          displayName: ['5'],
        }, {
          code: 'ZH1H3_6',
          displayName: ['6'],
        }, {
          code: 'ZH1H3_7',
          displayName: ['7'],
        }, {
          code: 'ZH1H3_8',
          displayName: ['8'],
        }, {
          code: 'ZH1H3_9',
          displayName: ['9'],
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '一字组合（全五）',
      mainColumnLength: 5,
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'ZH1Q5_0', // keyCode
          displayName: ['0'],
        }, {
          code: 'ZH1Q5_1',
          displayName: ['1'],
        }, {
          code: 'ZH1Q5_2',
          displayName: ['2'],
        }, {
          code: 'ZH1Q5_3',
          displayName: ['3'],
        }, {
          code: 'ZH1Q5_4',
          displayName: ['4'],
        }, {
          code: 'ZH1Q5_5',
          displayName: ['5'],
        }, {
          code: 'ZH1Q5_6',
          displayName: ['6'],
        }, {
          code: 'ZH1Q5_7',
          displayName: ['7'],
        }, {
          code: 'ZH1Q5_8',
          displayName: ['8'],
        }, {
          code: 'ZH1Q5_9',
          displayName: ['9'],
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default YZZH;