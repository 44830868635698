import { ProbabilitySelectionPanel } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const ZM16 = [
  {
    componentType: ProbabilitySelectionPanel,
  },
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      tableTitle: ['正码一', '正码二', '正码三', '正码四', '正码五', '正码六'],
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '赔率', '金额'],
      mainColumnLength: 6,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label paddingLabel', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'ZM1LM1',
          displayName: '单',
        }, {
          code: 'ZM2LM1',
          displayName: '单',
        }, {
          code: 'ZM3LM1',
          displayName: '单',
        }, {
          code: 'ZM4LM1',
          displayName: '单',
        }, {
          code: 'ZM5LM1',
          displayName: '单',
        }, {
          code: 'ZM6LM1',
          displayName: '单',
        }, {
          code: 'ZM1LM2',
          displayName: '双',
        }, {
          code: 'ZM2LM2',
          displayName: '双',
        }, {
          code: 'ZM3LM2',
          displayName: '双',
        }, {
          code: 'ZM4LM2',
          displayName: '双',
        }, {
          code: 'ZM5LM2',
          displayName: '双',
        }, {
          code: 'ZM6LM2',
          displayName: '双',
        }, {
          code: 'ZM1LM3',
          displayName: '大',
        }, {
          code: 'ZM2LM3',
          displayName: '大',
        }, {
          code: 'ZM3LM3',
          displayName: '大',
        }, {
          code: 'ZM4LM3',
          displayName: '大',
        }, {
          code: 'ZM5LM3',
          displayName: '大',
        }, {
          code: 'ZM6LM3',
          displayName: '大',
        }, {
          code: 'ZM1LM4',
          displayName: '小',
        }, {
          code: 'ZM2LM4',
          displayName: '小',
        }, {
          code: 'ZM3LM4',
          displayName: '小',
        }, {
          code: 'ZM4LM4',
          displayName: '小',
        }, {
          code: 'ZM5LM4',
          displayName: '小',
        }, {
          code: 'ZM6LM4',
          displayName: '小',
        }, {
          code: 'ZM1LM5',
          displayName: '合单',
        }, {
          code: 'ZM2LM5',
          displayName: '合单',
        }, {
          code: 'ZM3LM5',
          displayName: '合单',
        }, {
          code: 'ZM4LM5',
          displayName: '合单',
        }, {
          code: 'ZM5LM5',
          displayName: '合单',
        }, {
          code: 'ZM6LM5',
          displayName: '合单',
        }, {
          code: 'ZM1LM6',
          displayName: '合双',
        }, {
          code: 'ZM2LM6',
          displayName: '合双',
        }, {
          code: 'ZM3LM6',
          displayName: '合双',
        }, {
          code: 'ZM4LM6',
          displayName: '合双',
        }, {
          code: 'ZM5LM6',
          displayName: '合双',
        }, {
          code: 'ZM6LM6',
          displayName: '合双',
        }, {
          code: 'ZM1LM7',
          displayName: '合大',
        }, {
          code: 'ZM2LM7',
          displayName: '合大',
        }, {
          code: 'ZM3LM7',
          displayName: '合大',
        }, {
          code: 'ZM4LM7',
          displayName: '合大',
        }, {
          code: 'ZM5LM7',
          displayName: '合大',
        }, {
          code: 'ZM6LM7',
          displayName: '合大',
        }, {
          code: 'ZM1LM8',
          displayName: '合小',
        }, {
          code: 'ZM2LM8',
          displayName: '合小',
        }, {
          code: 'ZM3LM8',
          displayName: '合小',
        }, {
          code: 'ZM4LM8',
          displayName: '合小',
        }, {
          code: 'ZM5LM8',
          displayName: '合小',
        }, {
          code: 'ZM6LM8',
          displayName: '合小',
        }, {
          code: 'ZM1LM9',
          displayName: '尾大',
        }, {
          code: 'ZM2LM9',
          displayName: '尾大',
        }, {
          code: 'ZM3LM9',
          displayName: '尾大',
        }, {
          code: 'ZM4LM9',
          displayName: '尾大',
        }, {
          code: 'ZM5LM9',
          displayName: '尾大',
        }, {
          code: 'ZM6LM9',
          displayName: '尾大',
        }, {
          code: 'ZM1LM10',
          displayName: '尾小',
        }, {
          code: 'ZM2LM10',
          displayName: '尾小',
        }, {
          code: 'ZM3LM10',
          displayName: '尾小',
        }, {
          code: 'ZM4LM10',
          displayName: '尾小',
        }, {
          code: 'ZM5LM10',
          displayName: '尾小',
        }, {
          code: 'ZM6LM10',
          displayName: '尾小',
        }, {
          code: 'ZM1SB11',
          displayName: '红波',
          className: 'hk6-sb-red-bg'
        }, {
          code: 'ZM2SB11',
          displayName: '红波',
          className: 'hk6-sb-red-bg'
        }, {
          code: 'ZM3SB11',
          displayName: '红波',
          className: 'hk6-sb-red-bg'
        }, {
          code: 'ZM4SB11',
          displayName: '红波',
          className: 'hk6-sb-red-bg'
        }, {
          code: 'ZM5SB11',
          displayName: '红波',
          className: 'hk6-sb-red-bg'
        }, {
          code: 'ZM6SB11',
          displayName: '红波',
          className: 'hk6-sb-red-bg'
        }, {
          code: 'ZM1SB12',
          displayName: '蓝波',
          className: 'hk6-sb-blue-bg'
        }, {
          code: 'ZM2SB12',
          displayName: '蓝波',
          className: 'hk6-sb-blue-bg'
        }, {
          code: 'ZM3SB12',
          displayName: '蓝波',
          className: 'hk6-sb-blue-bg'
        }, {
          code: 'ZM4SB12',
          displayName: '蓝波',
          className: 'hk6-sb-blue-bg'
        }, {
          code: 'ZM5SB12',
          displayName: '蓝波',
          className: 'hk6-sb-blue-bg'
        }, {
          code: 'ZM6SB12',
          displayName: '蓝波',
          className: 'hk6-sb-blue-bg'
        }, {
          code: 'ZM1SB13',
          displayName: '绿波',
          className: 'hk6-sb-green-bg'
        }, {
          code: 'ZM2SB13',
          displayName: '绿波',
          className: 'hk6-sb-green-bg'
        }, {
          code: 'ZM3SB13',
          displayName: '绿波',
          className: 'hk6-sb-green-bg'
        }, {
          code: 'ZM4SB13',
          displayName: '绿波',
          className: 'hk6-sb-green-bg'
        }, {
          code: 'ZM5SB13',
          displayName: '绿波',
          className: 'hk6-sb-green-bg'
        }, {
          code: 'ZM6SB13',
          displayName: '绿波',
          className: 'hk6-sb-green-bg'
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default ZM16;