import React from 'react';

import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const GeneralModal = ({ header, message, toggle, platform }) => {
  const { t } = useTranslation();
  return (
    <div className={`generalWrapper ${platform.toLowerCase()}`}>
      <ModalHeader>
        {header}
      </ModalHeader>
      <ModalBody className="d-flex justify-content-center align-items-center">
        {message}
      </ModalBody>
      <ModalFooter className="border-top-0">
        <Button className="w-100" color="primary" onClick={toggle}>{t('MODAL_ACKNOWLEDGE')}</Button>
      </ModalFooter>
    </div>
  );
};

export default GeneralModal;