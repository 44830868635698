import EZDW from './EZDW';
import EZHS from './EZHS';
import EZZH from './EZZH';
import FSZH from './FSZH';
import HM15 from './HM15';
import KD from './KD';
import NNSH from './NNSH';
import SZDW from './SZDW';
import SZHS from './SZHS';
import SZZH from './SZZH';
import YZZH from './YZZH';
import ZH from './ZH';
import ZLH from './ZLH';
import ZSL from './ZSL';
import ZSS from './ZSS';
import { renderResultDateCell } from '../../utils';

const renderQiu = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  return result.map(item => (
    {
      value: item, className: 'circle circleBlue'
    }
  ));
};
const renderZH = data => {
  if (data && !data.openResult) return;
  const tempResult = [];
  let championSum = 0;
  const result = data.openResult.split(',');
  result.forEach(item => (championSum += parseInt(item, 10)));
  tempResult.push({
    value: championSum, className: ''
  });
  tempResult.push({
    value: championSum > 22 ? '大' : '小',
    className: championSum > 22 ? 'redText' : ''
  });
  tempResult.push({
    value: championSum % 2 ? '单' : '双', className: championSum % 2 ? 'redText' : ''
  });
  return tempResult;
};

const renderLH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  tempResult.push({
    value: (parseInt(result[0], 10)) > parseInt(result[result.length - 1], 10) ? '龙' : ((parseInt(result[0], 10)) < parseInt(result[result.length - 1], 10)) ? '虎' : '和',
    className: (parseInt(result[0], 10)) > parseInt(result[result.length - 1], 10) && 'redText'
  });
  return tempResult;
};

const generateSSCResultBySelectedArr = arr => {
  const zero = parseInt(arr[0], 10);
  const one = parseInt(arr[1], 10);
  const two = parseInt(arr[2], 10);
  if (arr[0] === arr[1] && arr[0] === arr[2] && arr[1] === arr[2]) {
    return '豹子';
  } if (arr[0] === arr[1] || arr[0] === arr[2] || arr[1] === arr[2]) {
    return '对子';
  } if ((zero === 0 && two === 9 && (one === 8 || one === 1)) || (zero === (one - 1) && one === (two - 1))) {
    return '顺子';
  } if ((zero === (one - 1) || one === (two - 1)) || (zero === 0 && (one === 9 || two === 9)) || (one === 0 && (zero === 9 || two === 9)) || (two === 0 && (zero === 9 || one === 9))) {
    return '半顺';
  }
  return '杂六';
};

const renderQ = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  const selection = [result[0], result[1], result[2]];
  tempResult.push({
    value: generateSSCResultBySelectedArr(selection.sort()),
  });
  return tempResult;
};
const renderZ = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  const selection = [result[1], result[2], result[3]];
  tempResult.push({
    value: generateSSCResultBySelectedArr(selection.sort()),
  });
  return tempResult;
};
const renderH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  const selection = [result[2], result[3], result[4]];
  tempResult.push({
    value: generateSSCResultBySelectedArr(selection.sort()),
  });
  return tempResult;
};

const SSC = {
  children: [
    {
      tabId: 'ZH',
      label: '整合',
      children: ZH.ZH,
      settingCode: 'LM,LHH,QH15,QZH3'
    },
    {
      tabId: 'HM15',
      label: '1-5球',
      children: HM15.HM15,
      midCode: 'B1QH,B1LM,B2QH,B2LM,B3QH,B3LM,B4QH,B4LM,B5QH,B5LM'
    },
    {
      tabId: 'YZZH',
      label: '一字组合',
      children: YZZH.YZZH,
      settingCode: 'ZH1'
    },
    {
      tabId: 'EZZH',
      label: '二字组合',
      children: EZZH.EZZH,
      settingCode: 'ZH2'
    },
    {
      tabId: 'SZZH',
      label: '三字组合',
      children: SZZH.SZZH,
      settingCode: 'ZH3'
    },
    {
      tabId: 'EZDW',
      label: '二字定位',
      children: EZDW.EZDW
    },
    {
      tabId: 'SZDW',
      label: '三字定位',
      children: SZDW.SZDW
    },
    {
      tabId: 'EZHS',
      label: '二字和数',
      children: EZHS.EZHS
    },
    {
      tabId: 'SZHS',
      label: '三字和数',
      children: SZHS.SZHS
    },
    {
      tabId: 'ZSS',
      label: '组选三',
      children: ZSS.ZSS
    },
    {
      tabId: 'ZSL',
      label: '组选六',
      children: ZSL.ZSL
    },
    {
      tabId: 'FSZH',
      label: '复式组合',
      children: FSZH.FSZH
    },
    {
      tabId: 'KD',
      label: '跨度',
      children: KD.KD
    },
    {
      tabId: 'ZLH',
      label: '组合龙虎',
      children: ZLH.ZLH,
      settingCode: 'ZLH'
    },
    {
      tabId: 'NNSH',
      label: '牛牛/梭哈',
      children: NNSH.NNSH,
      settingCode: 'NN,NNLMDS,NNLMDX,SH'
    }
  ],
  resultTab: {
    headerCell: [{
      attr: '期数',
      wrapperStyle: 'sscHeader'
    }, {
      attr: '日期',
      wrapperStyle: 'sscHeader'
    }, {
      attr: '彩球号码',
      wrapperStyle: 'sscHeader'
    }, {
      attr: '总和',
      wrapperStyle: 'sscHeader'
    }, {
      attr: '龙虎',
      wrapperStyle: 'sscHeader'
    }, {
      attr: '前三',
      wrapperStyle: 'sscHeader'
    }, {
      attr: '中三',
      wrapperStyle: 'sscHeader'
    }, {
      attr: '后三',
      wrapperStyle: 'sscHeader'
    }],
    bodyCell: [{
      attr: 'installments',
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderResultDateCell,
      wrapperStyle: 'dateCol'
    }, {
      attr: renderQiu,
      wrapperStyle: 'd-flex sscBallAndZh'
    }, {
      attr: renderZH,
      wrapperStyle: 'd-flex sscBallAndZh'
    }, {
      attr: renderLH,
      wrapperStyle: 'd-flex sscBallAndZh'
    }, {
      attr: renderQ,
      wrapperStyle: 'd-flex sscBallAndZh'
    }, {
      attr: renderZ,
      wrapperStyle: 'd-flex sscBallAndZh'
    }, {
      attr: renderH,
      wrapperStyle: 'd-flex sscBallAndZh'
    }],
  },
  probabilityResult: {
    dropdownList: [{
      label: '第一球',
      value: 'D1Q',
      resultPosition: 1,
    }, {
      label: '第二球',
      value: 'D2Q',
      resultPosition: 2,
    }, {
      label: '第三球',
      value: 'D3Q',
      resultPosition: 3,
    }, {
      label: '第四球',
      value: 'D4Q',
      resultPosition: 4,
    }, {
      label: '第五球',
      value: 'D5Q',
      resultPosition: 5,
    }],
    sideBetNavList: [{
      label: '大小',
      value: 'DX'
    }, {
      label: '单双',
      value: 'DS'
    }, {
      label: '总和大小',
      value: 'ZHDX'
    }, {
      label: '总和单双',
      value: 'ZHDS'
    }, {
      label: '龙虎和',
      value: 'LHH'
    }],
    result: {
      D1Q: {
        render: (data, resultPosition) => {
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',')[resultPosition - 1];
            if (currentItem) {
              if (_result.length >= 25) return;
              if (_result.length > 0 && _result[_result.length - 1].includes(currentItem)) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, currentItem]);
              } else {
                _result.push(currentItem);
              }
            }
          });
          return _result;
        }
      },
      D2Q: {
        render: (data, resultPosition) => {
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',')[resultPosition - 1];
            if (currentItem) {
              if (_result.length >= 25) return;
              if (_result.length > 0 && _result[_result.length - 1].includes(currentItem)) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, currentItem]);
              } else {
                _result.push(currentItem);
              }
            }
          });
          return _result;
        }
      },
      D3Q: {
        render: (data, resultPosition) => {
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',')[resultPosition - 1];
            if (currentItem) {
              if (_result.length >= 25) return;
              if (_result.length > 0 && _result[_result.length - 1].includes(currentItem)) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, currentItem]);
              } else {
                _result.push(currentItem);
              }
            }
          });
          return _result;
        }
      },
      D4Q: {
        render: (data, resultPosition) => {
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',')[resultPosition - 1];
            if (currentItem) {
              if (_result.length >= 25) return;
              if (_result.length > 0 && _result[_result.length - 1].includes(currentItem)) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, currentItem]);
              } else {
                _result.push(currentItem);
              }
            }
          });
          return _result;
        }
      },
      D5Q: {
        render: (data, resultPosition) => {
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',')[resultPosition - 1];
            if (currentItem) {
              if (_result.length >= 25) return;
              if (_result.length > 0 && _result[_result.length - 1].includes(currentItem)) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, currentItem]);
              } else {
                _result.push(currentItem);
              }
            }
          });
          return _result;
        }
      },
      DX: {
        render: (data, resultPosition) => {
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',')[resultPosition - 1];
            if (currentItem) {
              if (_result.length >= 25) return;
              if (_result.length > 0 && _result[_result.length - 1].includes(currentItem <= 4 ? '小' : '大')) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, currentItem <= 4 ? '小' : '大']);
              } else {
                _result.push(currentItem <= 4 ? '小' : '大');
              }
            }
          });
          return _result;
        }
      },
      DS: {
        render: (data, resultPosition) => {
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',')[resultPosition - 1];
            if (currentItem) {
              if (_result.length >= 25) return;
              if (_result.length > 0 && _result[_result.length - 1].includes(currentItem % 2 ? '单' : '双')) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, currentItem % 2 ? '单' : '双']);
              } else {
                _result.push(currentItem % 2 ? '单' : '双');
              }
            }
          });
          return _result;
        }
      },
      ZHDX: {
        render: data => {
          let championSum = 0;
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',');
            if (currentItem) {
              if (_result.length >= 25) return;
              championSum = 0;
              currentItem.forEach(item => (championSum += parseInt(item, 10)));
              if (_result.length > 0 && _result[_result.length - 1].includes(championSum > 22 ? '大' : '小')) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, championSum > 22 ? '大' : '小']);
              } else {
                _result.push(championSum > 22 ? '大' : '小');
              }
            }
          });
          return _result;
        }
      },
      ZHDS: {
        render: data => {
          let championSum = 0;
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',');
            if (currentItem) {
              if (_result.length >= 25) return;
              championSum = 0;
              currentItem.forEach(item => (championSum += parseInt(item, 10)));
              if (_result.length > 0 && _result[_result.length - 1].includes(championSum % 2 ? '单' : '双')) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, championSum % 2 ? '单' : '双']);
              } else {
                _result.push(championSum % 2 ? '单' : '双');
              }
            }
          });
          return _result;
        }
      },
      LHH: {
        render: data => {
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const openResultArr = item.openResult && item.openResult.split(',');
            if (openResultArr) {
              if (_result.length >= 25) return;
              const valueItem = openResultArr[0] > openResultArr[openResultArr.length - 1] ? '龙' : openResultArr[0] < openResultArr[openResultArr.length - 1] ? '虎' : '和';
              if (_result.length > 0 && _result[_result.length - 1].includes(valueItem)) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, valueItem]);
              } else {
                _result.push(valueItem);
              }
            }
          });
          return _result;
        }
      }
    }
  },
  results: {
    header: [{
      value: '开奖结果'
    }],
    TabList: [{
      code: 'NUMBER',
      renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
        value: item,
      }))
    },
    {
      code: 'BIG_SMALL',
      renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
        value: item <= 5 ? '小' : '大',
        className: item[item.length - 1] <= 5 ? 'smallValue' : '',
      }))
    },
    {
      code: 'ODD_EVEN',
      renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
        value: item % 2 ? '单' : '双',
        className: item % 2 ? '' : 'even'
      }))
    }]
  }
};

export default SSC;