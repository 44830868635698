import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';
import { renderWuXingBalls } from '../../../utils';

const WX = [
  {
    componentType: CollapseType,
    header: '色波',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper HK6SeBo',
      list: [
        {
          code: 'TMWX16', // keyCode
          displayName: '金',
          generateNumber: obj => renderWuXingBalls(obj.displayName),
          generateNumberWrapperStyle: 'HK6SeBoWrapper'
        }, {
          code: 'TMWX17',
          displayName: '木',
          generateNumber: obj => renderWuXingBalls(obj.displayName),
          generateNumberWrapperStyle: 'HK6SeBoWrapper'
        }, {
          code: 'TMWX18',
          displayName: '水',
          generateNumber: obj => renderWuXingBalls(obj.displayName),
          generateNumberWrapperStyle: 'HK6SeBoWrapper'
        }, {
          code: 'TMWX19',
          displayName: '火',
          generateNumber: obj => renderWuXingBalls(obj.displayName),
          generateNumberWrapperStyle: 'HK6SeBoWrapper'
        }, {
          code: 'TMWX20',
          displayName: '土',
          generateNumber: obj => renderWuXingBalls(obj.displayName),
          generateNumberWrapperStyle: 'HK6SeBoWrapper'
        }
      ]
    }
  }
];

export default WX;