import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const YZZH = [
  {
    componentType: CollapseType,
    header: '一字组合(前三)',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'ZH1Q3_0', // keyCode
          displayName: '0',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Q3_1',
          displayName: '1',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Q3_2',
          displayName: '2',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Q3_3',
          displayName: '3',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Q3_4',
          displayName: '4',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Q3_5',
          displayName: '5',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Q3_6',
          displayName: '6',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Q3_7',
          displayName: '7',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Q3_8',
          displayName: '8',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Q3_9',
          displayName: '9',
          drawImgClassName: 'circle'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '一字组合(中三)',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'ZH1Z3_0', // keyCode
          displayName: '0',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Z3_1',
          displayName: '1',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Z3_2',
          displayName: '2',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Z3_3',
          displayName: '3',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Z3_4',
          displayName: '4',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Z3_5',
          displayName: '5',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Z3_6',
          displayName: '6',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Z3_7',
          displayName: '7',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Z3_8',
          displayName: '8',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Z3_9',
          displayName: '9',
          drawImgClassName: 'circle'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '一字组合(后三)',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'ZH1H3_0', // keyCode
          displayName: '0',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1H3_1',
          displayName: '1',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1H3_2',
          displayName: '2',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1H3_3',
          displayName: '3',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1H3_4',
          displayName: '4',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1H3_5',
          displayName: '5',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1H3_6',
          displayName: '6',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1H3_7',
          displayName: '7',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1H3_8',
          displayName: '8',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1H3_9',
          displayName: '9',
          drawImgClassName: 'circle'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '一字组合(全五)',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'ZH1Q5_0', // keyCode
          displayName: '0',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Q5_1',
          displayName: '1',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Q5_2',
          displayName: '2',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Q5_3',
          displayName: '3',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Q5_4',
          displayName: '4',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Q5_5',
          displayName: '5',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Q5_6',
          displayName: '6',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Q5_7',
          displayName: '7',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Q5_8',
          displayName: '8',
          drawImgClassName: 'circle'
        }, {
          code: 'ZH1Q5_9',
          displayName: '9',
          drawImgClassName: 'circle'
        }
      ]
    }
  }
];

export default YZZH;