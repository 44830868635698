import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const TMBS = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      mainColumnLength: 1,
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 2,
      list: [
        {
          code: 'TMBS5', // keyCode
          displayName: '类别',
          betItemList: ['DisplayText', 'Type'],
          betItemStyle: ['w-25', 'w-75']
        }, {
          displayName: '赔率',
          betItemList: ['DisplayText', 'Odds'],
          betItemStyle: ['w-25', 'w-75']
        }]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '勾选'],
      mainColumnLength: 4,
      subColumnLength: 2,
      subColumnStyle: ['w-50', 'w-50'],
      betItemStyle: ['w-50', 'w-50'],
      betItemList: ['DisplayText', 'CheckBox'],
      betItemColumn: 2,
      list: [
        {
          code: 'HZ1',
          displayName: '00',
          className: 'circle circleYellow',
        }, {
          code: 'HZ8',
          displayName: '07',
          className: 'circle circleGreen',
        }, {
          code: 'HZ15',
          displayName: '14',
          className: 'circle circleYellow',
        }, {
          code: 'HZ22',
          displayName: '21',
          className: 'circle circleRed',
        }, {
          code: 'HZ2',
          displayName: '01',
          className: 'circle circleGreen',
        }, {
          code: 'HZ9',
          displayName: '08',
          className: 'circle circleBlue',
        }, {
          code: 'HZ16',
          displayName: '15',
          className: 'circle circleRed',
        }, {
          code: 'HZ23',
          displayName: '22',
          className: 'circle circleGreen',
        }, {
          code: 'HZ3',
          displayName: '02',
          className: 'circle circleBlue',
        }, {
          code: 'HZ10',
          displayName: '09',
          className: 'circle circleRed',
        }, {
          code: 'HZ17',
          displayName: '16',
          className: 'circle circleGreen',
        }, {
          code: 'HZ24',
          displayName: '23',
          className: 'circle circleBlue',
        }, {
          code: 'HZ4',
          displayName: '03',
          className: 'circle circleRed',
        }, {
          code: 'HZ11',
          displayName: '10',
          className: 'circle circleGreen',
        }, {
          code: 'HZ18',
          displayName: '17',
          className: 'circle circleBlue',
        }, {
          code: 'HZ25',
          displayName: '24',
          className: 'circle circleRed',
        }, {
          code: 'HZ5',
          displayName: '04',
          className: 'circle circleGreen',
        }, {
          code: 'HZ12',
          displayName: '11',
          className: 'circle circleBlue',
        }, {
          code: 'HZ19',
          displayName: '18',
          className: 'circle circleRed',
        }, {
          code: 'HZ26',
          displayName: '25',
          className: 'circle circleGreen',
        }, {
          code: 'HZ6',
          displayName: '05',
          className: 'circle circleBlue',
        }, {
          code: 'HZ13',
          displayName: '12',
          className: 'circle circleRed',
        }, {
          code: 'HZ20',
          displayName: '19',
          className: 'circle circleGreen',
        }, {
          code: 'HZ27',
          displayName: '26',
          className: 'circle circleBlue',
        }, {
          code: 'HZ7',
          displayName: '06',
          className: 'circle circleRed',
        }, {
          code: 'HZ14',
          displayName: '13',
          className: 'circle circleYellow',
        }, {
          code: 'HZ21',
          displayName: '20',
          className: 'circle circleBlue',
        }, {
          code: 'HZ28',
          displayName: '27',
          className: 'circle circleYellow',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default TMBS;