import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const HM5 = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '第五球',
      cycleHeaderTitle: true,
      mainColumnLength: 4,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-style-1th', 'column-3items-style-2th', 'column-3items-style-3th'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'B5QH1',
          displayName: '1',
          className: 'circle circleRed',
        }, {
          code: 'B5QH2',
          displayName: '2',
          className: 'circle circleBlue',
        }, {
          code: 'B5QH3',
          displayName: '3',
          className: 'circle circleGreen',
        }, {
          code: 'B5QH4',
          displayName: '4',
          className: 'circle circleRed',
        }, {
          code: 'B5QH5',
          displayName: '5',
          className: 'circle circleBlue',
        }, {
          code: 'B5QH6',
          displayName: '6',
          className: 'circle circleGreen',
        }, {
          code: 'B5QH7',
          displayName: '7',
          className: 'circle circleRed',
        }, {
          code: 'B5QH8',
          displayName: '8',
          className: 'circle circleBlue',
        }, {
          code: 'B5QH9',
          displayName: '9',
          className: 'circle circleGreen',
        }, {
          code: 'B5QH10',
          displayName: '10',
          className: 'circle circleRed',
        }, {
          code: 'B5QH11',
          displayName: '11',
          className: 'circle circleBlue',
        }, {
          code: 'B5QH12',
          displayName: '12',
          className: 'circle circleGreen',
        }, {
          code: 'B5QH13',
          displayName: '13',
          className: 'circle circleRed',
        }, {
          code: 'B5QH14',
          displayName: '14',
          className: 'circle circleBlue',
        }, {
          code: 'B5QH15',
          displayName: '15',
          className: 'circle circleGreen',
        }, {
          code: 'B5QH16',
          displayName: '16',
          className: 'circle circleRed',
        }, {
          code: 'B5QH17',
          displayName: '17',
          className: 'circle circleBlue',
        }, {
          code: 'B5QH18',
          displayName: '18',
          className: 'circle circleGreen',
        }, {
          code: 'B5QH19',
          displayName: '19',
          className: 'circle circleRed',
        }, {
          code: 'B5QH20',
          displayName: '20',
          className: 'circle circleBlue',
        }, {
          code: 'B5QH21',
          displayName: '21',
          className: 'circle circleGreen',
        }, {
          code: 'B5SB22',
          displayName: '红',
          className: 'fillRed',
        }, {
          code: 'B5SB23',
          displayName: '绿',
          className: 'fillGreen',
        }, {
          code: 'B5SB24',
          displayName: '蓝',
          className: 'fillBlue',
        }, {
          code: 'B5FLSX25',
          displayName: '福',
          className: 'label',
        }, {
          code: 'B5FLSX26',
          displayName: '禄',
          className: 'label',
        }, {
          code: 'B5FLSX27',
          displayName: '寿',
          className: 'label',
        }, {
          code: 'B5FLSX28',
          displayName: '喜',
          className: 'label',
        }, {
          code: 'B5LM33',
          displayName: '大',
          className: 'label',
        }, {
          code: 'B5LM34',
          displayName: '小',
          className: 'label',
        }, {
          code: 'B5LM35',
          displayName: '单',
          className: 'label',
        }, {
          code: 'B5LM36',
          displayName: '双',
          className: 'label',
        }, {
          code: 'B5LM37',
          displayName: '尾大',
          className: 'label',
        }, {
          code: 'B5LM38',
          displayName: '尾小',
          className: 'label',
        }, {
          code: 'B5LM39',
          displayName: '合单',
          className: 'label',
        }, {
          code: 'B5LM40',
          displayName: '合双',
          className: 'label',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default HM5;