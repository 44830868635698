

import IM_AG from '../../assets/image/thirdparty/AG.png';
import IM_SP from '../../assets/image/thirdparty/IM.png';
import KY_CC from '../../assets/image/thirdparty/KAIYUAN.png';
import LC_CC from '../../assets/image/thirdparty/LJQP.png';
import SBO_SP from '../../assets/image/thirdparty/SBOBET.png';
import XJ_SP from '../../assets/image/thirdparty/SPORTS188.png';

export default {
  IM_AG,
  IM_SP,
  KY_CC,
  LC_CC,
  SBO_SP,
  XJ_SP,
};