import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';
import { renderSeBoBalls } from '../../../utils';

const SB = [
  {
    componentType: CollapseType,
    header: '色波',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper HK6SeBo',
      list: [
        {
          code: 'TMSB60', // keyCode
          displayName: '红波',
          generateNumber: obj => renderSeBoBalls(obj.displayName),
          generateNumberWrapperStyle: 'HK6SeBoWrapper'
        }, {
          code: 'TMSB61',
          displayName: '蓝波',
          generateNumber: obj => renderSeBoBalls(obj.displayName),
          generateNumberWrapperStyle: 'HK6SeBoWrapper'
        }, {
          code: 'TMSB62',
          displayName: '绿波',
          generateNumber: obj => renderSeBoBalls(obj.displayName),
          generateNumberWrapperStyle: 'HK6SeBoWrapper'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '半波',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper PK10ItemWrapperGY',
      list: [
        {
          code: 'TBBDS2', // keyCode
          displayName: '红单',
          className: 'hk6-sb-red-text'
        }, {
          code: 'TBBDS11', // keyCode
          displayName: '蓝单',
          className: 'hk6-sb-blue-text'
        }, {
          code: 'TBBDS20', // keyCode
          displayName: '绿单',
          className: 'hk6-sb-green-text'
        }, {
          code: 'TBBDS3',
          displayName: '红双',
          className: 'hk6-sb-red-text'
        }, {
          code: 'TBBDS12',
          displayName: '蓝双',
          className: 'hk6-sb-blue-text'
        }, {
          code: 'TBBDS21',
          displayName: '绿双',
          className: 'hk6-sb-green-text'
        }, {
          code: 'TBBDX4',
          displayName: '红大',
          className: 'hk6-sb-red-text'
        }, {
          code: 'TBBDX13',
          displayName: '蓝大',
          className: 'hk6-sb-blue-text'
        }, {
          code: 'TBBDX22',
          displayName: '绿大',
          className: 'hk6-sb-green-text'
        }, {
          code: 'TBBDX5',
          displayName: '红小',
          className: 'hk6-sb-red-text'
        }, {
          code: 'TBBDX14',
          displayName: '蓝小',
          className: 'hk6-sb-blue-text'
        }, {
          code: 'TBBDX23',
          displayName: '绿小',
          className: 'hk6-sb-green-text'
        },
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '半半波',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper PK10ItemWrapperGY',
      list: [
        {
          code: 'TMBBB6', // keyCode
          displayName: '红大单',
          className: 'hk6-sb-red-text'
        }, {
          code: 'TMBBB15', // keyCode
          displayName: '蓝大单',
          className: 'hk6-sb-blue-text'
        }, {
          code: 'TMBBB24', // keyCode
          displayName: '绿大单',
          className: 'hk6-sb-green-text'
        }, {
          code: 'TMBBB7',
          displayName: '红大双',
          className: 'hk6-sb-red-text'
        }, {
          code: 'TMBBB16',
          displayName: '蓝大双',
          className: 'hk6-sb-blue-text'
        }, {
          code: 'TMBBB25',
          displayName: '绿大双',
          className: 'hk6-sb-green-text'
        }, {
          code: 'TMBBB8',
          displayName: '红小单',
          className: 'hk6-sb-red-text'
        }, {
          code: 'TMBBB17',
          displayName: '蓝小单',
          className: 'hk6-sb-blue-text'
        }, {
          code: 'TMBBB26',
          displayName: '绿小单',
          className: 'hk6-sb-green-text'
        }, {
          code: 'TMBBB9',
          displayName: '红小双',
          className: 'hk6-sb-red-text'
        }, {
          code: 'TMBBB18',
          displayName: '蓝小双',
          className: 'hk6-sb-blue-text'
        }, {
          code: 'TMBBB27',
          displayName: '绿小双',
          className: 'hk6-sb-green-text'
        },
      ]
    }
  }
];

export default SB;