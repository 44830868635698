import gameItemIconMap from '../../../GameItemIcon';

import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const YXXTB = [
  {
    componentType: CollapseType,
    header: '鱼虾蟹骰宝',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper PK10ItemWrapperGY',
      list: [
        {
          code: 'SJ11',
          image: gameItemIconMap.CJ_FISH,
          displayName: '1/鱼'
        }, {
          code: 'SJ13',
          image: gameItemIconMap.CJ_GOURD,
          displayName: '3/葫芦'
        }, {
          code: 'SJ15',
          image: gameItemIconMap.CJ_CRAB,
          displayName: '5/螃蟹'
        }, {
          code: 'SJ12',
          image: gameItemIconMap.CJ_PRAWN,
          displayName: '2/虾'
        }, {
          code: 'SJ14',
          image: gameItemIconMap.CJ_COIN,
          displayName: '4/金钱'
        }, {
          code: 'SJ16',
          image: gameItemIconMap.CJ_CHICKEN,
          displayName: '6/鸡'
        }
      ]
    }
  }
];

export default YXXTB;