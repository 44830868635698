import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Row } from 'reactstrap';
import { IoCloseOutline, IoRadioButtonOff, IoRadioButtonOn } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, gameActions } from '../../../redux/actions';
import Label from '../../Label';
import crossIcon from '../../../assets/icon/GameBetIcon/ic_off_.png';
import { validateNumber } from '../../../constants/utils';
import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
  presetAmountList: state.gameReducers.presetAmountList
});

const mapDispatchToProps = dispatch => ({
  resetPresetAmountList: () => dispatch(gameActions.resetPresetAmountList()),
  updatePresetAmountList: presetAmountList => dispatch(gameActions.updatePresetAmountList(presetAmountList)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const PresetAmountModal = ({ platform, presetAmountList, updateModalAlert, updatePresetAmountList, resetPresetAmountList }) => {
  const [resetDefaultButton, setResetDefaultButton] = useState(false);
  const [_presetAmountList, setPresetAmountList] = useState(presetAmountList);
  const { t } = useTranslation();

  const onSaveClick = () => {
    if (resetDefaultButton) resetPresetAmountList();
    else updatePresetAmountList(_presetAmountList);
    updateModalAlert({
      visible: false
    });
  };

  const onPresetAmountChange = (text, index) => {
    const newPresetAmountList = [..._presetAmountList];
    newPresetAmountList[index] = validateNumber(text) || 0;
    setPresetAmountList(newPresetAmountList);
  };

  function handleKeyDown(event) {
    if (event.key === 'Escape') {
      updateModalAlert({
        visible: false
      });
    } else if (event.key === 'Enter') {
      onSaveClick();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className={`presetAmountWrapper${platform}`}>
      <Row className="modalHeader">
        <Col
          md={{
            offset: 1,
            size: 10,
          }}
          className="justify-content-center d-flex align-items-center"
        >
          <Label value={t('QUICK_SELECTION_AMOUNT')} />
        </Col>
        <Col
          md="1"
          className="d-flex p-0 align-items-center justify-content-center"
          onClick={() => updateModalAlert({
            visible: false
          })}
        >
          <IoCloseOutline className="h4 mb-0 closeIcon" />
        </Col>
      </Row>
      <Row className="modalContent">
        {
          _presetAmountList.map((item, index) => (
            <Col md="2" className="pr-2 pl-2" key={`presetAmount_item_${index.toString()}`}>
              <Input
                value={item}
                onChange={({ target: { value: text } }) => {
                  onPresetAmountChange(text, index);
                }}
              />
              <div
                aria-hidden
                className="crossIconWrapper"
                onMouseDown={e => {
                  e.preventDefault();
                  onPresetAmountChange(0, index);
                }}
              >
                <img src={crossIcon} alt="Reset Amount" className="crossIcon" />
              </div>
            </Col>
          ))
        }
      </Row>
      <Row className="modalFooter">
        <Col md="6" className="justify-content-center align-items-center d-flex resetDefaultWrapper" onClick={() => setResetDefaultButton(!resetDefaultButton)}>
          {
            resetDefaultButton ? <IoRadioButtonOn className="mr-2 h5 mb-0 radiobutton" /> : <IoRadioButtonOff className="mr-2 h5 mb-0 radiobutton" />
          }
          <Label className="resetDefaultText" value={t('RESET_DEFAULT')} />
        </Col>
        <Col
          md="6"
          className="d-flex justify-content-center align-items-center d-flex"
        >
          <Button type="button" onClick={onSaveClick} className="saveBtn p-0">{t('SAVE')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PresetAmountModal);