import { StatusCodes } from 'http-status-codes';

import { LATEST_RESULT, SET_CHANGLONG_DATA, SET_CUSTOM_CHANGLONG, SET_MODAL_ALERT, SET_TREND_PREFERENCE } from '../actionTypes';
import { apiService } from '../../../library';

const setChangLong = changLongData => ({
  type: SET_CHANGLONG_DATA,
  value: changLongData
});

const setLatestResult = value => ({
  type: LATEST_RESULT,
  value
});

const setTrendPreference = trendPreferenceData => ({
  type: SET_TREND_PREFERENCE,
  value: trendPreferenceData,
});

const setCustomChanglong = customChangLongData => ({
  type: SET_CUSTOM_CHANGLONG,
  value: customChangLongData,
});

const updateModalAlert = modal => ({
  type: SET_MODAL_ALERT,
  value: modal
});

const getInfo = currentGameCode => dispatch => {
  apiService.get(`/trend/GetInfo/${currentGameCode}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      const { result } = data || {};
      dispatch(setChangLong(result.data));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const GetDayResult = lotteryType => dispatch => {
  apiService.get(`/trend/GetDayResult/${lotteryType}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      return dispatch(setLatestResult(data?.result?.data ? data?.result?.data : []));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const getMultiInfo = (lotteryList = '', minCount, maxCount) => dispatch => {
  apiService.get('/trend/getMultiInfo',
    new URLSearchParams({
      lotteryType: lotteryList,
      minCount,
      maxCount
    })).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      const { result } = data || {};
      const differentLottery = lotteryList.split(',');
      const splitLotteryArray = [];
      const customChanglongArr = [];
      differentLottery.forEach(lotteryCode => {
        const singleLotteryArr = [];
        singleLotteryArr.push(result.filter(item => item.lotteryType === lotteryCode));
        splitLotteryArray.push(singleLotteryArr[0]);
      });
      splitLotteryArray.forEach(lotteryTypeArr => {
        lotteryTypeArr.forEach(item => customChanglongArr.push(item));
      });
      dispatch(setCustomChanglong(customChanglongArr));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const saveTrendPreference = (lotteryType = '', minCount, isDelete = false, maxCount) => dispatch => {
  apiService.post('/Trend/SaveTrendPreference', {
    lotteryType,
    minCount,
    isDelete,
    maxCount,
  }).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(setTrendPreference(data.result));
      dispatch(updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const updateChangLong = changLongData => dispatch => (
  dispatch(setChangLong(changLongData))
);

export default {
  getInfo,
  getMultiInfo,
  updateChangLong,
  saveTrendPreference,
  GetDayResult
};