import { SET_WITHDRAWAL_INFO } from '../../actions/actionTypes';

const initialState = {
  withdrawalInfo: undefined,
};

const withdrawReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_WITHDRAWAL_INFO:
      return {
        ...state, withdrawalInfo: action.value
      };

    default:
      return state;
  }
};

export default withdrawReducers;