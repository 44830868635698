import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const ZSP = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['和值', '赔率', '金额'],
      mainColumnLength: 4,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'HZ1',
          displayName: '0',
          className: 'circle circleYellow',
        }, {
          code: 'HZ8',
          displayName: '7',
          className: 'circle circleGreen',
        }, {
          code: 'HZ15',
          displayName: '14',
          className: 'circle circleYellow',
        }, {
          code: 'HZ22',
          displayName: '21',
          className: 'circle circleRed',
        }, {
          code: 'HZ2',
          displayName: '1',
          className: 'circle circleGreen',
        }, {
          code: 'HZ9',
          displayName: '8',
          className: 'circle circleBlue',
        }, {
          code: 'HZ16',
          displayName: '15',
          className: 'circle circleRed',
        }, {
          code: 'HZ23',
          displayName: '22',
          className: 'circle circleGreen',
        }, {
          code: 'HZ3',
          displayName: '2',
          className: 'circle circleBlue',
        }, {
          code: 'HZ10',
          displayName: '9',
          className: 'circle circleRed',
        }, {
          code: 'HZ17',
          displayName: '16',
          className: 'circle circleGreen',
        }, {
          code: 'HZ24',
          displayName: '23',
          className: 'circle circleBlue',
        }, {
          code: 'HZ4',
          displayName: '3',
          className: 'circle circleRed',
        }, {
          code: 'HZ11',
          displayName: '10',
          className: 'circle circleGreen',
        }, {
          code: 'HZ18',
          displayName: '17',
          className: 'circle circleBlue',
        }, {
          code: 'HZ25',
          displayName: '24',
          className: 'circle circleRed',
        }, {
          code: 'HZ5',
          displayName: '4',
          className: 'circle circleGreen',
        }, {
          code: 'HZ12',
          displayName: '11',
          className: 'circle circleBlue',
        }, {
          code: 'HZ19',
          displayName: '18',
          className: 'circle circleRed',
        }, {
          code: 'HZ26',
          displayName: '25',
          className: 'circle circleGreen',
        }, {
          code: 'HZ6',
          displayName: '5',
          className: 'circle circleBlue',
        }, {
          code: 'HZ13',
          displayName: '12',
          className: 'circle circleRed',
        }, {
          code: 'HZ20',
          displayName: '19',
          className: 'circle circleGreen',
        }, {
          code: 'HZ27',
          displayName: '26',
          className: 'circle circleBlue',
        }, {
          code: 'HZ7',
          displayName: '6',
          className: 'circle circleRed',
        }, {
          code: 'HZ14',
          displayName: '13',
          className: 'circle circleYellow',
        }, {
          code: 'HZ21',
          displayName: '20',
          className: 'circle circleBlue',
        }, {
          code: 'HZ28',
          displayName: '27',
          className: 'circle circleYellow',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '两面',
      cycleHeaderTitle: true,
      mainColumnLength: 5,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'DX1',
          displayName: '大',
        }, {
          code: 'DS3',
          displayName: '单',
        }, {
          code: 'JDX5',
          displayName: '极大',
        }, {
          code: 'ZH7',
          displayName: '大单',
        }, {
          code: 'ZH8',
          displayName: '大双',
        }, {
          code: 'DX2',
          displayName: '小',
        }, {
          code: 'DS4',
          displayName: '双',
        }, {
          code: 'JDX6',
          displayName: '极小',
        }, {
          code: 'ZH10',
          displayName: '小单',
        }, {
          code: 'ZH9',
          displayName: '小双',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '色波/豹子',
      cycleHeaderTitle: true,
      mainColumnLength: 4,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-style-1th', 'column-3items-style-2th', 'column-3items-style-3th'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [{
        code: 'SB1',
        displayName: '红波',
        className: 'fillRed',
      }, {
        code: 'SB3',
        displayName: '绿波',
        className: 'fillGreen',
      }, {
        code: 'SB2',
        displayName: '蓝波',
        className: 'fillBlue',
      }, {
        code: 'BZ4',
        displayName: '豹子',
        className: 'label',
      }]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default ZSP;