import { BetTypeContainer, CheckBox, MultipleTypeSelectionPanel, Odds } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const LM = [
  {
    componentType: QuickBet,
  },
  {
    componentType: MultipleTypeSelectionPanel,
    data: {
      list: [
        {
          rowTitle: '类别',
          rowTitleClassName: 'text-center',
          rowType: CheckBox,
          wrapperStyle: 'checkBoxRow',
          onCheckBoxChange: (updateSectionSelection, subItem) => {
            updateSectionSelection(subItem);
          },
          rowData: [{
            code: 'L3QZ1',
            displayName: '三全中',
            minimumSelection: 3,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1', '2'],
          }, {
            code: 'L3Z2Z21',
            displayName: '三中二',
            minimumSelection: 3,
            maximumSelection: 8,
            className: 'cellWrapper',
            danList: ['1', '2'],
          }, {
            code: 'L2QZ1',
            displayName: '二全中',
            minimumSelection: 2,
            maximumSelection: 8,
            danList: ['1'],
            className: 'cellWrapper',
          }, {
            code: 'L2ZT1',
            displayName: '二中特',
            minimumSelection: 2,
            maximumSelection: 8,
            className: 'cellWrapper',
            danList: ['1'],
          }, {
            code: 'LTC1',
            displayName: '特串',
            minimumSelection: 2,
            maximumSelection: 17,
            danList: ['1'],
            className: 'cellWrapper',
          }]
        },
        {
          rowTitle: '赔率',
          rowTitleClassName: 'text-center',
          rowType: Odds,
          wrapperStyle: 'oddsRow',
          className: 'chooseBetOdds',
          rowData: [{
            code: 'L3QZ1',
            displayName: '三全中',
            className: 'chooseBetOdds',
          }, {
            code: 'L3Z2Z21',
            displayName: [{
              code: 'L3Z2Z21', displayName: '中二', className: 'd-flex justify-content-center pb-2 align-items-center border-bottom h-100 w-100'
            }, {
              code: 'L3Z2Z31', displayName: '中三', className: 'd-flex justify-content-center pt-2 align-items-center h-100 w-100'
            }],
            className: 'chooseBetOdds',
          }, {
            code: 'L2QZ1',
            displayName: '二全中',
            className: 'chooseBetOdds',
          }, {
            code: 'L2ZT1',
            displayName: [{
              code: 'L2ZT1', displayName: '中特', className: 'd-flex justify-content-center pb-2 align-items-center border-bottom h-100 w-100'
            }, {
              code: 'L2ZTZ21', displayName: '中二', className: 'd-flex justify-content-center pt-2 align-items-center h-100 w-100'
            }],
            className: 'chooseBetOdds',
          }, {
            code: 'LTC1',
            displayName: '特串',
            className: 'chooseBetOdds',
          }]
        },
        {
          rowTitleClassName: 'p-2 text-center',
          rowType: BetTypeContainer,
          wrapperStyle: 'p-2',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '赔率', '选择'],
      mainColumnLength: 5,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-checkbox'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-checkbox'],
      emptyItemStyle: ['column-3items-empty-label', 'column-3items-empty-odds', 'column-3items-empty-checkbox'],
      betItemList: ['DisplayText', 'Odds', 'CheckBox'],
      betItemColumn: 3,
      list: [
        {
          code: '1',
          displayName: '01',
          className: 'circle circleRed',
        }, {
          code: '11',
          displayName: '11',
          className: 'circle circleGreen',
        }, {
          code: '21',
          displayName: '21',
          className: 'circle circleGreen',
        }, {
          code: '31',
          displayName: '31',
          className: 'circle circleBlue',
        }, {
          code: '41',
          displayName: '41',
          className: 'circle circleBlue',
        }, {
          code: '2',
          displayName: '02',
          className: 'circle circleRed',
        }, {
          code: '12',
          displayName: '12',
          className: 'circle circleRed',
        }, {
          code: '22',
          displayName: '22',
          className: 'circle circleGreen',
        }, {
          code: '32',
          displayName: '32',
          className: 'circle circleGreen',
        }, {
          code: '42',
          displayName: '42',
          className: 'circle circleBlue',
        }, {
          code: '3',
          displayName: '03',
          className: 'circle circleBlue',
        }, {
          code: '13',
          displayName: '13',
          className: 'circle circleRed',
        }, {
          code: '23',
          displayName: '23',
          className: 'circle circleRed',
        }, {
          code: '33',
          displayName: '33',
          className: 'circle circleGreen',
        }, {
          code: '43',
          displayName: '43',
          className: 'circle circleGreen',
        }, {
          code: '4',
          displayName: '04',
          className: 'circle',
        }, {
          code: '14',
          displayName: '14',
          className: 'circle',
        }, {
          code: '24',
          displayName: '24',
          className: 'circle circleRed',
        }, {
          code: '34',
          displayName: '34',
          className: 'circle circleRed',
        }, {
          code: '44',
          displayName: '44',
          className: 'circle circleGreen',
        }, {
          code: '5',
          displayName: '05',
          className: 'circle circleGreen',
        }, {
          code: '15',
          displayName: '15',
          className: 'circle',
        }, {
          code: '25',
          displayName: '25',
          className: 'circle',
        }, {
          code: '35',
          displayName: '35',
          className: 'circle circleRed',
        }, {
          code: '45',
          displayName: '45',
          className: 'circle circleRed',
        }, {
          code: '6',
          displayName: '06',
          className: 'circle circleGreen',
        }, {
          code: '16',
          displayName: '16',
          className: 'circle circleGreen',
        }, {
          code: '26',
          displayName: '26',
          className: 'circle',
        }, {
          code: '36',
          displayName: '36',
          className: 'circle',
        }, {
          code: '46',
          displayName: '46',
          className: 'circle circleRed',
        }, {
          code: '7',
          displayName: '07',
          className: 'circle circleRed',
        }, {
          code: '17',
          displayName: '17',
          className: 'circle circleGreen',
        }, {
          code: '27',
          displayName: '27',
          className: 'circle circleGreen',
        }, {
          code: '37',
          displayName: '37',
          className: 'circle',
        }, {
          code: '47',
          displayName: '47',
          className: 'circle',
        }, {
          code: '8',
          displayName: '08',
          className: 'circle circleRed',
        }, {
          code: '18',
          displayName: '18',
          className: 'circle circleRed',
        }, {
          code: '28',
          displayName: '28',
          className: 'circle circleGreen',
        }, {
          code: '38',
          displayName: '38',
          className: 'circle circleGreen',
        }, {
          code: '48',
          displayName: '48',
          className: 'circle',
        }, {
          code: '9',
          displayName: '09',
          className: 'circle',
        }, {
          code: '19',
          displayName: '19',
          className: 'circle circleRed',
        }, {
          code: '29',
          displayName: '29',
          className: 'circle circleRed',
        }, {
          code: '39',
          displayName: '39',
          className: 'circle circleGreen',
        }, {
          code: '49',
          displayName: '49',
          className: 'circle circleGreen',
        }, {
          code: '10',
          displayName: '10',
          className: 'circle',
        }, {
          code: '20',
          displayName: '20',
          className: 'circle',
        }, {
          code: '30',
          displayName: '30',
          className: 'circle circleRed',
        }, {
          code: '40',
          displayName: '40',
          className: 'circle circleRed',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default LM;