import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const LiangMian = [
  {
    componentType: CollapseType,
    header: '总和',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'ZH41',
          displayName: '总和大',
        }, {
          code: 'ZH43',
          displayName: '总和单',
        }, {
          code: 'ZH45',
          displayName: '总尾大',
        }, {
          code: 'ZH47',
          displayName: '龙',
        }, {
          code: 'ZH42',
          displayName: '总和小',
        }, {
          code: 'ZH44',
          displayName: '总和双',
        }, {
          code: 'ZH46',
          displayName: '总尾小',
        }, {
          code: 'ZH48',
          displayName: '虎',
        },
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第一球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B1LM1',
          displayName: '大'
        },
        {
          code: 'B1LM2',
          displayName: '小'
        },
        {
          code: 'B1LM3',
          displayName: '单'
        },
        {
          code: 'B1LM4',
          displayName: '双'
        },
        {
          code: 'B1LM5',
          displayName: '尾大'
        },
        {
          code: 'B1LM6',
          displayName: '尾小'
        },
        {
          code: 'B1LM7',
          displayName: '合单'
        },
        {
          code: 'B1LM8',
          displayName: '合双'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第二球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B2LM1',
          displayName: '大'
        },
        {
          code: 'B2LM2',
          displayName: '小'
        },
        {
          code: 'B2LM3',
          displayName: '单'
        },
        {
          code: 'B2LM4',
          displayName: '双'
        },
        {
          code: 'B2LM5',
          displayName: '尾大'
        },
        {
          code: 'B2LM6',
          displayName: '尾小'
        },
        {
          code: 'B2LM7',
          displayName: '合单'
        },
        {
          code: 'B2LM8',
          displayName: '合双'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第三球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B3LM1',
          displayName: '大'
        },
        {
          code: 'B3LM2',
          displayName: '小'
        },
        {
          code: 'B3LM3',
          displayName: '单'
        },
        {
          code: 'B3LM4',
          displayName: '双'
        },
        {
          code: 'B3LM5',
          displayName: '尾大'
        },
        {
          code: 'B3LM6',
          displayName: '尾小'
        },
        {
          code: 'B3LM7',
          displayName: '合单'
        },
        {
          code: 'B3LM8',
          displayName: '合双'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第四球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B4LM1',
          displayName: '大'
        },
        {
          code: 'B4LM2',
          displayName: '小'
        },
        {
          code: 'B4LM3',
          displayName: '单'
        },
        {
          code: 'B4LM4',
          displayName: '双'
        },
        {
          code: 'B4LM5',
          displayName: '尾大'
        },
        {
          code: 'B4LM6',
          displayName: '尾小'
        },
        {
          code: 'B4LM7',
          displayName: '合单'
        },
        {
          code: 'B4LM8',
          displayName: '合双'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第五球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B5LM1',
          displayName: '大'
        },
        {
          code: 'B5LM2',
          displayName: '小'
        },
        {
          code: 'B5LM3',
          displayName: '单'
        },
        {
          code: 'B5LM4',
          displayName: '双'
        },
        {
          code: 'B5LM5',
          displayName: '尾大'
        },
        {
          code: 'B5LM6',
          displayName: '尾小'
        },
        {
          code: 'B5LM7',
          displayName: '合单'
        },
        {
          code: 'B5LM8',
          displayName: '合双'
        }
      ]
    }
  }
];

export default LiangMian;