import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';
import { renderHeXiaoBalls } from '../../../utils';

const YXWS = [
  {
    componentType: CollapseType,
    header: '一肖',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper HK6TeXiao',
      list: [
        {
          code: 'PTYX1', // keyCode
          displayName: '鼠',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'PTYX7',
          displayName: '马',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'PTYX2',
          displayName: '牛',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'PTYX8',
          displayName: '羊',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'PTYX3',
          displayName: '虎',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'PTYX9',
          displayName: '猴',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'PTYX4',
          displayName: '兔',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'PTYX10',
          displayName: '鸡',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'PTYX5',
          displayName: '龙',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'PTYX11',
          displayName: '狗',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'PTYX6',
          displayName: '蛇',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'PTYX12',
          displayName: '猪',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '尾数',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper PK10ItemWrapperGY',
      list: [
        {
          code: 'PTWS13', // keyCode
          displayName: '0尾',
        }, {
          code: 'PTWS18',
          displayName: '5尾',
        }, {
          code: 'PTWS14',
          displayName: '1尾',
        }, {
          code: 'PTWS19',
          displayName: '6尾',
        }, {
          code: 'PTWS15',
          displayName: '2尾',
        }, {
          code: 'PTWS20',
          displayName: '7尾',
        }, {
          code: 'PTWS16',
          displayName: '3尾',
        }, {
          code: 'PTWS21',
          displayName: '8尾',
        }, {
          code: 'PTWS17',
          displayName: '4尾',
        }, {
          code: 'PTWS22',
          displayName: '9尾',
        }
      ]
    }
  }
];

export default YXWS;