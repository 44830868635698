import CJ_11X5 from './11X5';
import CJ_BJK8 from './BJK8';
import CJ_F31X7 from './F31X7';
import CJ_F36X7 from './F36X7';
import CJ_FANT from './FANT';
import CJ_GXK10 from './GXK10';
import CJ_HK6 from './HK6';
import CJ_K3 from './K3';
import CJ_KL10 from './KL10';
import CJ_PCDD from './PCDD';
import CJ_PK10 from './PK10';
import CJ_SSC from './SSC';

const standardRules = [
  '如果客户怀疑自己的资料被盗用，应立即通知本公司，并更改详细资料，以前的使用者名称及密码将全部无效。',
  '客户有责任确保自己的帐户及登入资料的保密性。以使用者名称及密码进行的任何网上投注将被视为有效。',
  '公布赔率时出现的任何打字错误或非故意人为失误，本公司保留改正错误和按正确赔率结算投注的权力。您居住所在地的法律有可能规定网路博奕不合法；若此情况属实，本公司将不会批准您使用付帐卡进行交易。',
  '每次登入时客户都应该核对自己的帐户结余额。如对余额有任何疑问，请在第一时间内通知本公司。',
  ' 一旦投注被接受，则不得取消或修改。',
  '所有号码赔率将不时浮动，派彩时的赔率将以确认投注时之赔率为准。',
  '每注最高投注金额按不同[场次]及[投注项目]及[会员账号]设定浮动。如投注金额超过上述设定，本公司有权取消超过之投注金额。',
  '所有投注都必须在开奖前时间内进行否则投注无效。',
  '所有投注派彩彩金皆含本金。',
];

export default {
  CJ_11X5,
  CJ_BJK8,
  CJ_F31X7,
  CJ_FANT,
  CJ_GXK10,
  CJ_HK6,
  CJ_K3,
  CJ_KL10,
  CJ_PK10,
  CJ_SSC,
  CJ_F36X7,
  CJ_PCDD,
  standardRules,
};