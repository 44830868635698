import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import PengAndDan from '../../../../components/Mobile/ComponentType/PengAndDan';
import TeMaAndLianMa from '../../../../components/Mobile/ComponentType/TeMaAndLianMa';

import OptionItem from '../../../../components/Mobile/OptionItem';

const ZXBZ = [
  {
    componentType: TeMaAndLianMa,
    data: {
      list: [
        {
          code: 'ZX5BZ',
          displayName: '5不中',
          minimumSelection: 5,
          maximumSelection: 9,
          className: 'cellWrapper',
          danList: ['1', '2', '3', '4'],
        }, {
          code: 'ZX6BZ',
          displayName: '6不中',
          minimumSelection: 6,
          maximumSelection: 10,
          className: 'cellWrapper',
          danList: ['1', '2', '3', '4', '5'],
        }, {
          code: 'ZX7BZ',
          displayName: '7不中',
          minimumSelection: 7,
          maximumSelection: 11,
          className: 'cellWrapper',
          danList: ['1', '2', '3', '4', '5', '6'],
        }, {
          code: 'ZX8BZ',
          displayName: '8不中',
          minimumSelection: 8,
          maximumSelection: 11,
          className: 'cellWrapper',
          danList: ['1', '2', '3', '4', '5', '6', '7'],
        }, {
          code: 'ZX9BZ',
          displayName: '9不中',
          minimumSelection: 9,
          maximumSelection: 12,
          className: 'cellWrapper',
          danList: ['1', '2', '3', '4', '5', '6', '7', '8'],
        }, {
          code: 'ZX10BZ',
          displayName: '10不中',
          minimumSelection: 10,
          maximumSelection: 13,
          className: 'cellWrapper',
          danList: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
        }, {
          code: 'ZX11BZ',
          displayName: '11不中',
          minimumSelection: 11,
          maximumSelection: 14,
          className: 'cellWrapper',
          danList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        }
      ]
    }
  },
  {
    componentType: PengAndDan,
    data: {}
  },
  {
    componentType: CollapseType,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper threeColumnStyle',
      list: [
        {
          displayName: '01',
          drawImgClassName: 'circle circleRed',
          code: 1
        },
        {
          displayName: '02',
          drawImgClassName: 'circle circleRed',
          code: 2
        },
        {
          displayName: '03',
          drawImgClassName: 'circle circleBlue',
          code: 3
        },
        {
          displayName: '04',
          drawImgClassName: 'circle circleBlue',
          code: 4
        },
        {
          displayName: '05',
          drawImgClassName: 'circle circleGreen',
          code: 5
        },
        {
          displayName: '06',
          drawImgClassName: 'circle circleGreen',
          code: 6
        },
        {
          displayName: '07',
          drawImgClassName: 'circle circleRed',
          code: 7
        },
        {
          displayName: '08',
          drawImgClassName: 'circle circleRed',
          code: 8
        },
        {
          displayName: '09',
          drawImgClassName: 'circle circleBlue',
          code: 9
        },
        {
          displayName: '10',
          drawImgClassName: 'circle circleBlue',
          code: 10
        },
        {
          displayName: '11',
          drawImgClassName: 'circle circleGreen',
          code: 11
        },
        {
          displayName: '12',
          drawImgClassName: 'circle circleRed',
          code: 12
        },
        {
          displayName: '13',
          drawImgClassName: 'circle circleRed',
          code: 13
        },
        {
          displayName: '14',
          drawImgClassName: 'circle circleBlue',
          code: 14
        },
        {
          displayName: '15',
          drawImgClassName: 'circle circleBlue',
          code: 15
        },
        {
          displayName: '16',
          drawImgClassName: 'circle circleGreen',
          code: 16
        },
        {
          displayName: '17',
          drawImgClassName: 'circle circleGreen',
          code: 17
        },
        {
          displayName: '18',
          drawImgClassName: 'circle circleRed',
          code: 18
        },
        {
          displayName: '19',
          drawImgClassName: 'circle circleRed',
          code: 19
        },
        {
          displayName: '20',
          drawImgClassName: 'circle circleBlue',
          code: 20
        },
        {
          displayName: '21',
          drawImgClassName: 'circle circleGreen',
          code: 21
        },
        {
          displayName: '22',
          drawImgClassName: 'circle circleGreen',
          code: 22
        },
        {
          displayName: '23',
          drawImgClassName: 'circle circleRed',
          code: 23
        },
        {
          displayName: '24',
          drawImgClassName: 'circle circleRed',
          code: 24
        },
        {
          displayName: '25',
          drawImgClassName: 'circle circleBlue',
          code: 25
        },
        {
          displayName: '26',
          drawImgClassName: 'circle circleBlue',
          code: 26
        },
        {
          displayName: '27',
          drawImgClassName: 'circle circleGreen',
          code: 27
        },
        {
          displayName: '28',
          drawImgClassName: 'circle circleGreen',
          code: 28
        },
        {
          displayName: '29',
          drawImgClassName: 'circle circleRed',
          code: 29
        },
        {
          displayName: '30',
          drawImgClassName: 'circle circleRed',
          code: 30
        },
        {
          displayName: '31',
          drawImgClassName: 'circle circleBlue',
          code: 31
        },
        {
          displayName: '32',
          drawImgClassName: 'circle circleGreen',
          code: 32
        },
        {
          displayName: '33',
          drawImgClassName: 'circle circleGreen',
          code: 33
        },
        {
          displayName: '34',
          drawImgClassName: 'circle circleRed',
          code: 34
        },
        {
          displayName: '35',
          drawImgClassName: 'circle circleRed',
          code: 35
        },
        {
          displayName: '36',
          drawImgClassName: 'circle circleBlue',
          code: 36
        },
        {
          displayName: '37',
          drawImgClassName: 'circle circleBlue',
          code: 37
        },
        {
          displayName: '38',
          drawImgClassName: 'circle circleGreen',
          code: 38
        },
        {
          displayName: '39',
          drawImgClassName: 'circle circleGreen',
          code: 39
        },
        {
          displayName: '40',
          drawImgClassName: 'circle circleRed',
          code: 40
        },
        {
          displayName: '41',
          drawImgClassName: 'circle circleBlue',
          code: 41
        },
        {
          displayName: '42',
          drawImgClassName: 'circle circleBlue',
          code: 42
        },
        {
          displayName: '43',
          drawImgClassName: 'circle circleGreen',
          code: 43
        },
        {
          displayName: '44',
          drawImgClassName: 'circle circleGreen',
          code: 44
        },
        {
          displayName: '45',
          drawImgClassName: 'circle circleRed',
          code: 45
        },
        {
          displayName: '46',
          drawImgClassName: 'circle circleRed',
          code: 46
        },
        {
          displayName: '47',
          drawImgClassName: 'circle circleBlue',
          code: 47
        },
        {
          displayName: '48',
          drawImgClassName: 'circle circleBlue',
          code: 48
        },
        {
          displayName: '49',
          drawImgClassName: 'circle circleGreen',
          code: 49
        }
      ]
    }
  }
];

export default ZXBZ;