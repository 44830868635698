import { DELETE_ITEM_IN_CART, INSERT_ITEM_TO_CART, RESET_ITEM_IN_CART, UPDATE_ENTIRE_CART, UPDATE_PRESET_AMOUNT } from '../../actions/actionTypes';

const initialState = {
  cart: undefined
};

const appReducers = (state = initialState, action) => {
  const tempCart = {
    ...state.cart
  };

  switch (action.type) {
    case INSERT_ITEM_TO_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          ...action.value
        }
      };

    case DELETE_ITEM_IN_CART:
      delete tempCart[action.value];
      return {
        ...state, cart: tempCart
      };

    case RESET_ITEM_IN_CART:
      return {
        ...state,
        cart: initialState.cart
      };

    case UPDATE_PRESET_AMOUNT:
      Object.keys(tempCart).forEach(item => {
        tempCart[item].amount = action.value;
      });
      return {
        ...state,
        cart: tempCart
      };

    case UPDATE_ENTIRE_CART:
      return {
        ...state, cart: action.value
      };

    default:
      return state;
  }
};

export default appReducers;