import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';
import gameItemIconMap from '../../../GameItemIcon';

const ZhengMa = [
  {
    componentType: CollapseType,
    header: '正码',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'ZM1',
          displayName: '1',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'ZM6',
          displayName: '6',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'ZM11',
          displayName: '11',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'ZM16',
          displayName: '16',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'ZM2',
          displayName: '2',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'ZM7',
          displayName: '7',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'ZM12',
          displayName: '12',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'ZM17',
          displayName: '17',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'ZM3',
          displayName: '3',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'ZM8',
          displayName: '8',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'ZM13',
          displayName: '13',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'ZM18',
          displayName: '18',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'ZM4',
          displayName: '4',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'ZM9',
          displayName: '9',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'ZM14',
          displayName: '14',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'ZM19',
          displayName: '19',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'ZM5',
          displayName: '5',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'ZM10',
          displayName: '10',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'ZM15',
          displayName: '15',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'ZM20',
          displayName: '20',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '总和',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'ZM21',
          displayName: '总和大',
        }, {
          code: 'ZM23',
          displayName: '总和单',
        }, {
          code: 'ZM25',
          displayName: '总和尾大',
        }, {
          code: 'ZM22',
          displayName: '总和小',
        }, {
          code: 'ZM24',
          displayName: '总和双',
        }, {
          code: 'ZM26',
          displayName: '总和尾小',
        },
      ]
    }
  }
];

export default ZhengMa;