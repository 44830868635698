import { ReactComponent as ArrowConvert } from '../../assets/icon/TransferIcons/arrow_convert.svg';
import { ReactComponent as ButtonAdd } from '../../assets/icon/TransferIcons/button_add.svg';
import { ReactComponent as ButtonRemove } from '../../assets/icon/TransferIcons/button_remove.svg';
import IconCheck from '../../assets/icon/TransferIcons/icon_check.png';
import { ReactComponent as IconWallet } from '../../assets/icon/TransferIcons/icon_wallet.svg';
import { ReactComponent as IconWalletLock } from '../../assets/icon/TransferIcons/icon_walletlock.svg';

export default {
  ButtonRemove,
  ButtonAdd,
  IconCheck,
  ArrowConvert,
  IconWallet,
  IconWalletLock
};