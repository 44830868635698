import { OddsTypeTable, ProbabilitySelectionPanel } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const EZDW = [
  {
    componentType: ProbabilitySelectionPanel,
    data: {
      showProbability: false,
      midCodeList: [{
        label: '万千',
        value: 'DW2WQ',
        minimumSelection: 1,
        maximumSelection: 20,
        iteration: 2,
      }, {
        label: '万佰',
        value: 'DW2WB',
        minimumSelection: 1,
        maximumSelection: 20,
        iteration: 2,
      }, {
        label: '万拾',
        value: 'DW2WS',
        minimumSelection: 1,
        maximumSelection: 20,
        iteration: 2,
      }, {
        label: '万个',
        value: 'DW2WG',
        minimumSelection: 1,
        maximumSelection: 20,
        iteration: 2,
      }, {
        label: '千佰',
        value: 'DW2QB',
        minimumSelection: 1,
        maximumSelection: 20,
        iteration: 2,
      }, {
        label: '千拾',
        value: 'DW2QS',
        minimumSelection: 1,
        maximumSelection: 20,
        iteration: 2,
      }, {
        label: '千个',
        value: 'DW2QG',
        minimumSelection: 1,
        maximumSelection: 20,
        iteration: 2,
      }, {
        label: '佰拾',
        value: 'DW2BS',
        minimumSelection: 1,
        maximumSelection: 20,
        iteration: 2,
      }, {
        label: '佰个',
        value: 'DW2BG',
        minimumSelection: 1,
        maximumSelection: 20,
        iteration: 2,
      }, {
        label: '拾个',
        value: 'DW2SG',
        minimumSelection: 1,
        maximumSelection: 20,
        iteration: 2,
      }],
    }
  },
  {
    componentType: QuickBet,
  },
  {
    componentType: OddsTypeTable,
    data: {
      renderLabel: sectionSelection => {
        if (!sectionSelection) return;
        return sectionSelection.label;
      }
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '万位',
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '选择'],
      mainColumnLength: 5,
      subColumnLength: 2,
      subColumnStyle: ['w-50', 'w-50'],
      betItemStyle: ['w-50', 'w-50'],
      betItemList: ['DisplayText', 'CheckBox'],
      betItemColumn: 2,
      list: [
        {
          code: 'A0',
          displayName: '0',
          className: 'circle',
        }, {
          code: 'A2',
          displayName: '2',
          className: 'circle',
        }, {
          code: 'A4',
          displayName: '4',
          className: 'circle',
        }, {
          code: 'A6',
          displayName: '6',
          className: 'circle',
        }, {
          code: 'A8',
          displayName: '8',
          className: 'circle',
        }, {
          code: 'A1',
          displayName: '1',
          className: 'circle',
        }, {
          code: 'A3',
          displayName: '3',
          className: 'circle',
        }, {
          code: 'A5',
          displayName: '5',
          className: 'circle',
        }, {
          code: 'A7',
          displayName: '7',
          className: 'circle',
        }, {
          code: 'A9',
          displayName: '9',
          className: 'circle',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '千位',
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '选择'],
      mainColumnLength: 5,
      subColumnLength: 2,
      subColumnStyle: ['w-50', 'w-50'],
      betItemStyle: ['w-50', 'w-50'],
      betItemList: ['DisplayText', 'CheckBox'],
      betItemColumn: 2,
      list: [
        {
          code: 'B0',
          displayName: '0',
          className: 'circle',
        }, {
          code: 'B2',
          displayName: '2',
          className: 'circle',
        }, {
          code: 'B4',
          displayName: '4',
          className: 'circle',
        }, {
          code: 'B6',
          displayName: '6',
          className: 'circle',
        }, {
          code: 'B8',
          displayName: '8',
          className: 'circle',
        }, {
          code: 'B1',
          displayName: '1',
          className: 'circle',
        }, {
          code: 'B3',
          displayName: '3',
          className: 'circle',
        }, {
          code: 'B5',
          displayName: '5',
          className: 'circle',
        }, {
          code: 'B7',
          displayName: '7',
          className: 'circle',
        }, {
          code: 'B9',
          displayName: '9',
          className: 'circle',
        }
      ]
    }
  }, {
    componentType: QuickBet,
  }
];

export default EZDW;