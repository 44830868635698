import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const ZHENGMA = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '赔率', '金额'],
      mainColumnLength: 4,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      emptyItemStyle: ['column-3items-empty-label', 'column-3items-empty-odds', 'column-3items-empty-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'ZM1',
          displayName: '01',
          className: 'circle circleRed',
        }, {
          code: 'ZM11',
          displayName: '11',
          className: 'circle circleGreen',
        }, {
          code: 'ZM21',
          displayName: '21',
          className: 'circle circleGreen',
        }, {
          code: 'ZM31',
          displayName: '31',
          className: 'circle circleBlue',
        }, {
          code: 'ZM2',
          displayName: '02',
          className: 'circle circleRed',
        }, {
          code: 'ZM12',
          displayName: '12',
          className: 'circle circleRed',
        }, {
          code: 'ZM22',
          displayName: '22',
          className: 'circle circleGreen',
        }, {
          code: 'ZFDX_D',
          displayName: '总分大',
        }, {
          code: 'ZM3',
          displayName: '03',
          className: 'circle circleBlue',
        }, {
          code: 'ZM13',
          displayName: '13',
          className: 'circle circleRed',
        }, {
          code: 'ZM23',
          displayName: '23',
          className: 'circle circleRed',
        }, {
          code: 'ZFDX_X',
          displayName: '总分小',
        }, {
          code: 'ZM4',
          displayName: '04',
          className: 'circle',
        }, {
          code: 'ZM14',
          displayName: '14',
          className: 'circle',
        }, {
          code: 'ZM24',
          displayName: '24',
          className: 'circle circleRed',
        }, {
          code: 'ZFDS_D',
          displayName: '总分单',
        }, {
          code: 'ZM5',
          displayName: '05',
          className: 'circle circleGreen',
        }, {
          code: 'ZM15',
          displayName: '15',
          className: 'circle',
        }, {
          code: 'ZM25',
          displayName: '25',
          className: 'circle',
        }, {
          code: 'ZFDS_S',
          displayName: '总分双',
        }, {
          code: 'ZM6',
          displayName: '06',
          className: 'circle circleGreen',
        }, {
          code: 'ZM16',
          displayName: '16',
          className: 'circle circleGreen',
        }, {
          code: 'ZM26',
          displayName: '26',
          className: 'circle',
        }, {
          code: 'ZM7',
          displayName: '07',
          className: 'circle circleRed',
          newLine: true,
        }, {
          code: 'ZM17',
          displayName: '17',
          className: 'circle circleGreen',
        }, {
          code: 'ZM27',
          displayName: '27',
          className: 'circle circleGreen',
        }, {
          code: 'ZM8',
          displayName: '08',
          className: 'circle circleRed',
          newLine: true,
        }, {
          code: 'ZM18',
          displayName: '18',
          className: 'circle circleRed',
        }, {
          code: 'ZM28',
          displayName: '28',
          className: 'circle circleGreen',
        }, {
          code: 'ZM9',
          displayName: '09',
          className: 'circle',
          newLine: true,
        }, {
          code: 'ZM19',
          displayName: '19',
          className: 'circle circleRed',
        }, {
          code: 'ZM29',
          displayName: '29',
          className: 'circle circleRed',
        }, {
          code: 'ZM10',
          displayName: '10',
          className: 'circle',
          newLine: true,
        }, {
          code: 'ZM20',
          displayName: '20',
          className: 'circle',
        }, {
          code: 'ZM30',
          displayName: '30',
          className: 'circle circleRed',
        },
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default ZHENGMA;