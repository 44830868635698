import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const ZESM = [
  {
    componentType: CollapseType,
    header: '番',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'FA1', // keyCode
          displayName: '一番',
        }, {
          code: 'FA2',
          displayName: '二番',
        }, {
          code: 'FA3',
          displayName: '三番',
        }, {
          code: 'FA4',
          displayName: '四番',
        }
      ],
    }
  },
  {
    componentType: CollapseType,
    header: '三门',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'SM123', // keyCode
        displayName: '123中',
      }, {
        code: 'SM124',
        displayName: '124中',
      }, {
        code: 'SM134',
        displayName: '134中',
      }, {
        code: 'SM234',
        displayName: '234中',
      }],
    }
  }
];

export default ZESM;