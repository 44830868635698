import FANTAN from './FANTAN';
import { renderResultDateCell } from '../../utils';

const renderQiu = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  return result.map(item => (
    {
      value: item, className: 'circle circleBlue'
    }
  ));
};

const renderSum = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  return result.slice(0, 4).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0);
};

const renderRemainder = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const remainder = result.slice(0, 4).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0) % 4;
  return remainder === 0 ? 0 : remainder;
};

const renderFan = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const remainder = result.slice(0, 4).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0) % 4;
  return remainder === 0 ? 4 : remainder;
};

const renderZhong = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const remainder = result.slice(0, 4).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0) % 4;
  const zhongList = [];
  if ('123'.includes(remainder === 0 ? 4 : remainder)) {
    zhongList.push('123中');
  }
  if ('124'.includes(remainder === 0 ? 4 : remainder)) {
    zhongList.push('124中');
  }
  if ('134'.includes(remainder === 0 ? 4 : remainder)) {
    zhongList.push('134中');
  }
  if ('234'.includes(remainder === 0 ? 4 : remainder)) {
    zhongList.push('234中');
  }
  return zhongList.join(',');
};

const renderDS = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const remainder = result.slice(0, 4).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0) % 4;
  return ((remainder === 0 ? 4 : remainder) % 2 === 0);
};

const renderDX = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const remainder = result.slice(0, 4).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0) % 4;
  return ((remainder === 0 ? 4 : remainder) > 2);
};

const FANT = {
  children: [
    {
      tabId: 'ZLMJ',
      label: '总盘',
      children: FANTAN.ZLMJ,
      midCode: 'Q3'
    },
  ],
  resultTab: {
    headerCell: [{
      attr: '期数',
      wrapperStyle: 'fantanHeader'
    }, {
      attr: '日期',
      wrapperStyle: 'fantanHeader'
    }, {
      attr: '彩球号码',
      wrapperStyle: 'fantanHeader'
    }, {
      attr: '结果',
      wrapperStyle: 'fantanHeader'
    }, {
      attr: '除4余数',
      wrapperStyle: 'fantanHeader'
    }, {
      attr: '番',
      wrapperStyle: 'fantanHeader'
    }, {
      attr: '中',
      wrapperStyle: 'fantanHeader'
    }, {
      attr: '单双',
      wrapperStyle: 'fantanHeader'
    }, {
      attr: '大小',
      wrapperStyle: 'fantanHeader'
    }],
    bodyCell: [{
      attr: 'installments',
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderResultDateCell,
      wrapperStyle: 'dateCol'
    }, {
      attr: renderQiu,
      wrapperStyle: 'd-flex fantanBall justify-content-center align-items-center'
    }, {
      attr: renderSum,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderRemainder,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderFan,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderZhong,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderDS,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderDX,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }],
  },
  results: {
    header: [{
      value: '番'
    }, {
      value: '单双'
    }, {
      value: '大小'
    }],
    defaultFunc: data => {
      if (!data.openResult) return;
      const tempResult = [];
      const fan = renderFan(data);
      tempResult.push({
        value: fan,
        className: fan === 1 ? 'FANT_ball ball_red' : fan === 2 ? 'FANT_ball ball_blue' : fan === 2 ? 'FANT_ball ball_green' : 'FANT_ball ball_red'
      });
      tempResult.push({
        value: renderDS(data) ? '双' : '单',
        className: renderDS(data) ? 'even' : ''
      });
      tempResult.push({
        value: renderDX(data) ? '大' : '小',
        className: renderDX(data) ? '' : 'smallValue',
      });
      return tempResult;
    },
  }
};

export default FANT;