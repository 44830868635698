import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const GYZH = [
  {
    componentType: CollapseType,
    header: '冠、亚军和',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper PK10ItemWrapperGY',
      list: [{
        code: 'GYJH1', // keyCode
        displayName: '3',
      }, {
        code: 'GYJH2',
        displayName: '4',
      }, {
        code: 'GYJH3',
        displayName: '5',
      }, {
        code: 'GYJH4',
        displayName: '6',
      }, {
        code: 'GYJH5',
        displayName: '7',
      }, {
        code: 'GYJH6',
        displayName: '8',
      }, {
        code: 'GYJH7',
        displayName: '9',
      }, {
        code: 'GYJH8',
        displayName: '10',
      }, {
        code: 'GYJH9',
        displayName: '11',
      }, {
        code: 'GYJH10',
        displayName: '12',
      }, {
        code: 'GYJH11',
        displayName: '13',
      }, {
        code: 'GYJH12',
        displayName: '14',
      }, {
        code: 'GYJH13',
        displayName: '15',
      }, {
        code: 'GYJH14',
        displayName: '16',
      }, {
        code: 'GYJH15',
        displayName: '17',
      }, {
        code: 'GYJH16',
        displayName: '18',
      }, {
        code: 'GYJH17',
        displayName: '19',
      }, {
        code: 'GYJH18',
        displayName: '冠亚大',
      }, {
        code: 'GYJH19',
        displayName: '冠亚小',
      }, {
        code: 'GYJH20',
        displayName: '冠亚单',
      }, {
        code: 'GYJH21',
        displayName: '冠亚双',
      }],
    }
  },
];

export default GYZH;