import FANT from '../../../../components/Mobile/ComponentType/FANT';

const FantLiangMian = [

  {
    componentType: FANT,
    data: {}
  }
];

export default FantLiangMian;