import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { Loading } from 'element-react';
import { connect } from 'react-redux';

import { appActions, memberActions } from '../../../redux/actions';
import { ModalAlert } from '../../../components';
import Templates from './templates';


import './styles.scss';

const mapStateToProps = state => ({
  initData: state.homeReducers.initData,
  token: state.appReducers.token
});

const mapDispatchToProps = dispatch => ({
  updateAccessToken: data => dispatch(appActions.updateAccessToken(data)),
  GetLoginUserInfo: callback => dispatch(memberActions.GetLoginUserInfo(callback))
});

const Home = props => {
  const { initData, token, updateAccessToken, GetLoginUserInfo } = props;

  const Component = initData ? Templates[initData.template] || Templates.Default : Templates.Default;

  const data = useLocation();
  const params = data?.search === '' ? '' : new URLSearchParams(data.search);
  const [pageType, setPageType] = useState(data?.search === '' ? 'home' : 'loading');

  useEffect(() => {
    const _token = params ? params.get('token') : '';
    if (_token) {
      updateAccessToken(_token);
      setPageType('loading');
    } else {
      setPageType('home');
    }
  }, []);

  useEffect(() => {
    if (token) {
      const callback = success => {
        if (!success) {
          updateAccessToken('');
          setPageType('home');
        }
      };
      GetLoginUserInfo(callback);
    }
  }, [token]);

  return (
    <div>
      {pageType === 'loading'
        ? <Loading fullscreen text="请稍等，正在跳转中..." /> : pageType === 'home'
          ? <Component {...props} /> : ''}
      <ModalAlert />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);