import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const HM = [
  {
    componentType: CollapseType,
    header: '一中一',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'YZY1',
          displayName: '1',
          drawImgClassName: 'circle',
        }, {
          code: 'YZY2',
          displayName: '2',
          drawImgClassName: 'circle',
        }, {
          code: 'YZY3',
          displayName: '3',
          drawImgClassName: 'circle',
        }, {
          code: 'YZY4',
          displayName: '4',
          drawImgClassName: 'circle',
        }, {
          code: 'YZY5',
          displayName: '5',
          drawImgClassName: 'circle',
        }, {
          code: 'YZY6',
          displayName: '6',
          drawImgClassName: 'circle',
        }, {
          code: 'YZY7',
          displayName: '7',
          drawImgClassName: 'circle',
        }, {
          code: 'YZY8',
          displayName: '8',
          drawImgClassName: 'circle',
        }, {
          code: 'YZY9',
          displayName: '9',
          drawImgClassName: 'circle',
        }, {
          code: 'YZY10',
          displayName: '10',
          drawImgClassName: 'circle',
        }, {
          code: 'YZY11',
          displayName: '11',
          drawImgClassName: 'circle',
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第一球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'B1QH1',
          displayName: '1',
          drawImgClassName: 'circle'
        },
        {
          code: 'B1QH2',
          displayName: '2',
          drawImgClassName: 'circle'
        },
        {
          code: 'B1QH3',
          displayName: '3',
          drawImgClassName: 'circle'
        },
        {
          code: 'B1QH4',
          displayName: '4',
          drawImgClassName: 'circle'
        },
        {
          code: 'B1QH5',
          displayName: '5',
          drawImgClassName: 'circle'
        },
        {
          code: 'B1QH6',
          displayName: '6',
          drawImgClassName: 'circle'
        },
        {
          code: 'B1QH7',
          displayName: '7',
          drawImgClassName: 'circle'
        },
        {
          code: 'B1QH8',
          displayName: '8',
          drawImgClassName: 'circle'
        },
        {
          code: 'B1QH9',
          displayName: '9',
          drawImgClassName: 'circle'
        },
        {
          code: 'B1QH10',
          displayName: '10',
          drawImgClassName: 'circle'
        },
        {
          code: 'B1QH11',
          displayName: '11',
          drawImgClassName: 'circle'
        }
      ]
    }
  }, {
    componentType: CollapseType,
    header: '第二球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'B2QH1',
          displayName: '1',
          drawImgClassName: 'circle'
        },
        {
          code: 'B2QH2',
          displayName: '2',
          drawImgClassName: 'circle'
        },
        {
          code: 'B2QH3',
          displayName: '3',
          drawImgClassName: 'circle'
        },
        {
          code: 'B2QH4',
          displayName: '4',
          drawImgClassName: 'circle'
        },
        {
          code: 'B2QH5',
          displayName: '5',
          drawImgClassName: 'circle'
        },
        {
          code: 'B2QH6',
          displayName: '6',
          drawImgClassName: 'circle'
        },
        {
          code: 'B2QH7',
          displayName: '7',
          drawImgClassName: 'circle'
        },
        {
          code: 'B2QH8',
          displayName: '8',
          drawImgClassName: 'circle'
        },
        {
          code: 'B2QH9',
          displayName: '9',
          drawImgClassName: 'circle'
        },
        {
          code: 'B2QH10',
          displayName: '10',
          drawImgClassName: 'circle'
        },
        {
          code: 'B2QH11',
          displayName: '11',
          drawImgClassName: 'circle'
        }
      ]
    }
  }, {
    componentType: CollapseType,
    header: '第三球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'B3QH1',
          displayName: '1',
          drawImgClassName: 'circle'
        },
        {
          code: 'B3QH2',
          displayName: '2',
          drawImgClassName: 'circle'
        },
        {
          code: 'B3QH3',
          displayName: '3',
          drawImgClassName: 'circle'
        },
        {
          code: 'B3QH4',
          displayName: '4',
          drawImgClassName: 'circle'
        },
        {
          code: 'B3QH5',
          displayName: '5',
          drawImgClassName: 'circle'
        },
        {
          code: 'B3QH6',
          displayName: '6',
          drawImgClassName: 'circle'
        },
        {
          code: 'B3QH7',
          displayName: '7',
          drawImgClassName: 'circle'
        },
        {
          code: 'B3QH8',
          displayName: '8',
          drawImgClassName: 'circle'
        },
        {
          code: 'B3QH9',
          displayName: '9',
          drawImgClassName: 'circle'
        },
        {
          code: 'B3QH10',
          displayName: '10',
          drawImgClassName: 'circle'
        },
        {
          code: 'B3QH11',
          displayName: '11',
          drawImgClassName: 'circle'
        }
      ]
    }
  }, {
    componentType: CollapseType,
    header: '第四球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'B4QH1',
          displayName: '1',
          drawImgClassName: 'circle'
        },
        {
          code: 'B4QH2',
          displayName: '2',
          drawImgClassName: 'circle'
        },
        {
          code: 'B4QH3',
          displayName: '3',
          drawImgClassName: 'circle'
        },
        {
          code: 'B4QH4',
          displayName: '4',
          drawImgClassName: 'circle'
        },
        {
          code: 'B4QH5',
          displayName: '5',
          drawImgClassName: 'circle'
        },
        {
          code: 'B4QH6',
          displayName: '6',
          drawImgClassName: 'circle'
        },
        {
          code: 'B4QH7',
          displayName: '7',
          drawImgClassName: 'circle'
        },
        {
          code: 'B4QH8',
          displayName: '8',
          drawImgClassName: 'circle'
        },
        {
          code: 'B4QH9',
          displayName: '9',
          drawImgClassName: 'circle'
        },
        {
          code: 'B4QH10',
          displayName: '10',
          drawImgClassName: 'circle'
        },
        {
          code: 'B4QH11',
          displayName: '11',
          drawImgClassName: 'circle'
        }
      ]
    }
  }, {
    componentType: CollapseType,
    header: '第五球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'B5QH1',
          displayName: '1',
          drawImgClassName: 'circle'
        },
        {
          code: 'B5QH2',
          displayName: '2',
          drawImgClassName: 'circle'
        },
        {
          code: 'B5QH3',
          displayName: '3',
          drawImgClassName: 'circle'
        },
        {
          code: 'B5QH4',
          displayName: '4',
          drawImgClassName: 'circle'
        },
        {
          code: 'B5QH5',
          displayName: '5',
          drawImgClassName: 'circle'
        },
        {
          code: 'B5QH6',
          displayName: '6',
          drawImgClassName: 'circle'
        },
        {
          code: 'B5QH7',
          displayName: '7',
          drawImgClassName: 'circle'
        },
        {
          code: 'B5QH8',
          displayName: '8',
          drawImgClassName: 'circle'
        },
        {
          code: 'B5QH9',
          displayName: '9',
          drawImgClassName: 'circle'
        },
        {
          code: 'B5QH10',
          displayName: '10',
          drawImgClassName: 'circle'
        },
        {
          code: 'B5QH11',
          displayName: '11',
          drawImgClassName: 'circle'
        }
      ]
    }
  }
];

export default HM;