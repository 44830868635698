import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const NI = [
  {
    componentType: CollapseType,
    header: '三门',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'NI12', // keyCode
        displayName: '1念2',
      }, {
        code: 'NI13',
        displayName: '1念3',
      }, {
        code: 'NI14',
        displayName: '1念4',
      }, {
        code: 'NI21',
        displayName: '2念1',
      }, {
        code: 'NI23', // keyCode
        displayName: '2念3',
      }, {
        code: 'NI24',
        displayName: '2念4',
      }, {
        code: 'NI31',
        displayName: '3念1',
      }, {
        code: 'NI32',
        displayName: '3念2',
      }, {
        code: 'NI34', // keyCode
        displayName: '3念4',
      }, {
        code: 'NI41',
        displayName: '4念1',
      }, {
        code: 'NI42',
        displayName: '4念2',
      }, {
        code: 'NI43',
        displayName: '4念3',
      }],
    }
  }
];

export default NI;