import React from 'react';

import { connect } from 'react-redux';

import './styles.scss';

const Tab = ({ displayName, active, onChange }) => (
  <span className={`tabListContainer ${active && 'tabAcitve'}`} onClick={() => onChange()}>{displayName}</span>
);

export default connect(null, null)(Tab);