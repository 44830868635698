import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';
import gameItemIconMap from '../../../GameItemIcon';

const HM18 = [
  {
    componentType: CollapseType,
    header: '第一球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B1M1',
          displayName: '1',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'B1M6',
          displayName: '6',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'B1M11',
          displayName: '11',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'B1M16',
          displayName: '16',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'B1M2',
          displayName: '2',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'B1M7',
          displayName: '7',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'B1M12',
          displayName: '12',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'B1M17',
          displayName: '17',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'B1M3',
          displayName: '3',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'B1M8',
          displayName: '8',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'B1M13',
          displayName: '13',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'B1M18',
          displayName: '18',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'B1M4',
          displayName: '4',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'B1M9',
          displayName: '9',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'B1M14',
          displayName: '14',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'B1M19',
          displayName: '19',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'B1M5',
          displayName: '5',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'B1M10',
          displayName: '10',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'B1M15',
          displayName: '15',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'B1M20',
          displayName: '20',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第二球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B2M1',
          displayName: '1',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'B2M6',
          displayName: '6',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'B2M11',
          displayName: '11',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'B2M16',
          displayName: '16',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'B2M2',
          displayName: '2',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'B2M7',
          displayName: '7',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'B2M12',
          displayName: '12',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'B2M17',
          displayName: '17',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'B2M3',
          displayName: '3',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'B2M8',
          displayName: '8',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'B2M13',
          displayName: '13',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'B2M18',
          displayName: '18',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'B2M4',
          displayName: '4',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'B2M9',
          displayName: '9',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'B2M14',
          displayName: '14',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'B2M19',
          displayName: '19',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'B2M5',
          displayName: '5',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'B2M10',
          displayName: '10',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'B2M15',
          displayName: '15',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'B2M20',
          displayName: '20',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第三球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B3HM1',
          displayName: '1',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'B3HM6',
          displayName: '6',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'B3HM11',
          displayName: '11',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'B3HM16',
          displayName: '16',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'B3HM2',
          displayName: '2',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'B3HM7',
          displayName: '7',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'B3HM12',
          displayName: '12',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'B3HM17',
          displayName: '17',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'B3HM3',
          displayName: '3',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'B3HM8',
          displayName: '8',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'B3HM13',
          displayName: '13',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'B3HM18',
          displayName: '18',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'B3HM4',
          displayName: '4',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'B3HM9',
          displayName: '9',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'B3HM14',
          displayName: '14',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'B3HM19',
          displayName: '19',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'B3HM5',
          displayName: '5',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'B3HM10',
          displayName: '10',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'B3HM15',
          displayName: '15',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'B3HM20',
          displayName: '20',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第四球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B4HM1',
          displayName: '1',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'B4HM6',
          displayName: '6',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'B4HM11',
          displayName: '11',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'B4HM16',
          displayName: '16',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'B4HM2',
          displayName: '2',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'B4HM7',
          displayName: '7',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'B4HM12',
          displayName: '12',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'B4HM17',
          displayName: '17',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'B4HM3',
          displayName: '3',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'B4HM8',
          displayName: '8',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'B4HM13',
          displayName: '13',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'B4HM18',
          displayName: '18',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'B4HM4',
          displayName: '4',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'B4HM9',
          displayName: '9',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'B4HM14',
          displayName: '14',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'B4HM19',
          displayName: '19',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'B4HM5',
          displayName: '5',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'B4HM10',
          displayName: '10',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'B4HM15',
          displayName: '15',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'B4HM20',
          displayName: '20',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第五球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B5HM1',
          displayName: '1',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'B5HM6',
          displayName: '6',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'B5HM11',
          displayName: '11',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'B5HM16',
          displayName: '16',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'B5HM2',
          displayName: '2',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'B5HM7',
          displayName: '7',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'B5HM12',
          displayName: '12',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'B5HM17',
          displayName: '17',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'B5HM3',
          displayName: '3',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'B5HM8',
          displayName: '8',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'B5HM13',
          displayName: '13',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'B5HM18',
          displayName: '18',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'B5HM4',
          displayName: '4',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'B5HM9',
          displayName: '9',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'B5HM14',
          displayName: '14',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'B5HM19',
          displayName: '19',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'B5HM5',
          displayName: '5',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'B5HM10',
          displayName: '10',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'B5HM15',
          displayName: '15',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'B5HM20',
          displayName: '20',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第六球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B6HM1',
          displayName: '1',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'B6HM6',
          displayName: '6',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'B6HM11',
          displayName: '11',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'B6HM16',
          displayName: '16',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'B6HM2',
          displayName: '2',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'B6HM7',
          displayName: '7',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'B6HM12',
          displayName: '12',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'B6HM17',
          displayName: '17',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'B6HM3',
          displayName: '3',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'B6HM8',
          displayName: '8',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'B6HM13',
          displayName: '13',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'B6HM18',
          displayName: '18',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'B6HM4',
          displayName: '4',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'B6HM9',
          displayName: '9',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'B6HM14',
          displayName: '14',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'B6HM19',
          displayName: '19',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'B6HM5',
          displayName: '5',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'B6HM10',
          displayName: '10',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'B6HM15',
          displayName: '15',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'B6HM20',
          displayName: '20',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第七球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B7HM1',
          displayName: '1',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'B7HM6',
          displayName: '6',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'B7HM11',
          displayName: '11',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'B7HM16',
          displayName: '16',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'B7HM2',
          displayName: '2',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'B7HM7',
          displayName: '7',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'B7HM12',
          displayName: '12',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'B7HM17',
          displayName: '17',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'B7HM3',
          displayName: '3',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'B7HM8',
          displayName: '8',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'B7HM13',
          displayName: '13',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'B7HM18',
          displayName: '18',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'B7HM4',
          displayName: '4',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'B7HM9',
          displayName: '9',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'B7HM14',
          displayName: '14',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'B7HM19',
          displayName: '19',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'B7HM5',
          displayName: '5',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'B7HM10',
          displayName: '10',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'B7HM15',
          displayName: '15',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'B7HM20',
          displayName: '20',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第八球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B8HM1',
          displayName: '1',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'B8HM6',
          displayName: '6',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'B8HM11',
          displayName: '11',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'B8HM16',
          displayName: '16',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'B8HM2',
          displayName: '2',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'B8HM7',
          displayName: '7',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'B8HM12',
          displayName: '12',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'B8HM17',
          displayName: '17',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'B8HM3',
          displayName: '3',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'B8HM8',
          displayName: '8',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'B8HM13',
          displayName: '13',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'B8HM18',
          displayName: '18',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'B8HM4',
          displayName: '4',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'B8HM9',
          displayName: '9',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'B8HM14',
          displayName: '14',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'B8HM19',
          displayName: '19',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'B8HM5',
          displayName: '5',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'B8HM10',
          displayName: '10',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'B8HM15',
          displayName: '15',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'B8HM20',
          displayName: '20',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  }
];

export default HM18;