import FanTanTemplate from '../../../../components/GameTemplates/FanTanTemplate';
import QuickBet from '../../../../components/Web/Content/QuickBet';

const ZLMJ = [
  {
    componentType: QuickBet,
  },
  {
    componentType: FanTanTemplate,
  },
  {
    componentType: QuickBet,
  },
];

export default ZLMJ;