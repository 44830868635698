import React from 'react';

import { Col, ModalBody, Row } from 'reactstrap';
import { RiLoader2Fill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const LoadingModal = ({ platform = 'Mobile' }) => {
  const { t } = useTranslation();
  return (
    <ModalBody className={`d-flex justify-content-center align-items-center col loadingWrapper_${platform.toLowerCase()}`}>
      <Row>
        <Col>
          <Row className="d-flex justify-content-center mt-4 mb-3">
            <RiLoader2Fill className="refreshrotate loading-icon-style" size="2.75em" />
          </Row>
          <Row className="mb-4">
            <span className="loading-text-style">
              {t('LOADING')}
              . . .
            </span>
          </Row>
        </Col>
      </Row>
    </ModalBody>
  );
};

export default LoadingModal;