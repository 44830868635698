import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';
import PengAndDan from '../../../../components/Mobile/ComponentType/PengAndDan';
import TeMaAndLianMa from '../../../../components/Mobile/ComponentType/TeMaAndLianMa';

const FSZH = [
  {
    componentType: TeMaAndLianMa,
    data: {
      list: [
        {
          displayName: '前三',
          numberOfSelectionBlock: 3,
          code: 'FSZHQ3',
          minimumSelection: 9,
          maximumSelection: 21,
          children: [{
            displayName: '前三复式',
            code: 'FSZHQ3'
          }]
        },
        {
          displayName: '中三',
          numberOfSelectionBlock: 3,
          code: 'FSZHZ3',
          minimumSelection: 9,
          maximumSelection: 21,
          children: [{
            displayName: '中三复式',
            code: 'FSZHZ3'
          }]
        },
        {
          displayName: '后三',
          numberOfSelectionBlock: 3,
          code: 'FSZHH3',
          minimumSelection: 9,
          maximumSelection: 21,
          children: [{
            displayName: '后三复式',
            code: 'FSZHH3'
          }]
        }
      ]
    }
  },
  {
    componentType: PengAndDan,
    data: {}
  },
  {
    componentType: CollapseType,
    header: '选择',
    headerStyle: '',
    selectionBlockIndex: 0,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      kuaiJieSelectedGroupIndex: 0,
      list: [
        {
          drawImgClassName: 'circle',
          displayName: '0'
        },
        {
          drawImgClassName: 'circle',
          displayName: '1'
        },
        {
          drawImgClassName: 'circle',
          displayName: '2'
        },
        {
          drawImgClassName: 'circle',
          displayName: '3'
        },
        {
          drawImgClassName: 'circle',
          displayName: '4'
        },
        {
          drawImgClassName: 'circle',
          displayName: '5'
        },
        {
          drawImgClassName: 'circle',
          displayName: '6'
        },
        {
          drawImgClassName: 'circle',
          displayName: '7'
        },
        {
          drawImgClassName: 'circle',
          displayName: '8'
        },
        {
          drawImgClassName: 'circle',
          displayName: '9'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '选择',
    headerStyle: '',
    selectionBlockIndex: 1,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      kuaiJieSelectedGroupIndex: 1,
      list: [
        {
          drawImgClassName: 'circle',
          displayName: '0'
        },
        {
          drawImgClassName: 'circle',
          displayName: '1'
        },
        {
          drawImgClassName: 'circle',
          displayName: '2'
        },
        {
          drawImgClassName: 'circle',
          displayName: '3'
        },
        {
          drawImgClassName: 'circle',
          displayName: '4'
        },
        {
          drawImgClassName: 'circle',
          displayName: '5'
        },
        {
          drawImgClassName: 'circle',
          displayName: '6'
        },
        {
          drawImgClassName: 'circle',
          displayName: '7'
        },
        {
          drawImgClassName: 'circle',
          displayName: '8'
        },
        {
          drawImgClassName: 'circle',
          displayName: '9'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '选择',
    headerStyle: '',
    selectionBlockIndex: 2,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      kuaiJieSelectedGroupIndex: 2,
      list: [
        {
          drawImgClassName: 'circle',
          displayName: '0'
        },
        {
          drawImgClassName: 'circle',
          displayName: '1'
        },
        {
          drawImgClassName: 'circle',
          displayName: '2'
        },
        {
          drawImgClassName: 'circle',
          displayName: '3'
        },
        {
          drawImgClassName: 'circle',
          displayName: '4'
        },
        {
          drawImgClassName: 'circle',
          displayName: '5'
        },
        {
          drawImgClassName: 'circle',
          displayName: '6'
        },
        {
          drawImgClassName: 'circle',
          displayName: '7'
        },
        {
          drawImgClassName: 'circle',
          displayName: '8'
        },
        {
          drawImgClassName: 'circle',
          displayName: '9'
        }
      ]
    }
  }
];

export default FSZH;