import { AnimatePresence, motion } from 'framer-motion';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import React, { useState } from 'react';

import './styles.scss';

const Accordion = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="my-3 accordionWrapper">
      <motion.div
        onClick={() => setIsOpen(prev => !prev)}
        className="accordionQuestion"
      >
        <motion.div
          whileHover={{
            backgroundColor: '#c4c8d9',
            borderRadius: '10px',
          }}
          whileTap={{
            scale: 0.8,
            backgroundColor: '#a9b1c4',
          }}
        >
          <div className="p-2 d-flex align-items-center">
            <div>
              <span className="font-weight-bold">问: </span>
              {' '}
              {question}
              {' '}
            </div>
            <div className="flex-grow-1" />
            {isOpen ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        </motion.div>
      </motion.div>
      <AnimatePresence>
        {isOpen ? (
          <motion.div
            initial={{
              opacity: 0, y: '-10%'
            }}
            animate={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.5,
              },
            }}
            exit={{
              opacity: 0,
              y: '-10%',
              transition: {
                duration: 0.5,
              },
            }}
            className="accordionAnswer p-2"
          >
            <span className="font-weight-bold">答: </span>
            <span>
              {answer}
            </span>
          </motion.div>
        ) : (
          ''
        )}
      </AnimatePresence>
    </div>
  );
};

export default Accordion;