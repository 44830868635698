import React from 'react';

import { Col, Row } from 'reactstrap';

import './styles.scss';

const Images = ({ horizontal, source }) => (horizontal ? (
  <Row className="imageWrapper">
    {
      Array.isArray(source) ? (
        source.map(item => (
          <Col>
            <img src={item} alt="img" />
          </Col>
        ))
      ) : (
        <Col>
          <img src={source} alt="img" />
        </Col>
      )
    }
  </Row>
) : (
  <Col className="imageWrapper">
    {
       Array.isArray(source) ? (
         source.map(item => (
           <Row>
             <img src={item} alt="img" />
           </Row>
         ))
       ) : (
         <Row>
           <img src={source} alt="img" />
         </Row>
       )
    }
  </Col>
));

export default Images;