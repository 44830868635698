import React from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, cashActions } from '../../../redux/actions';
import TransferIcon from '../../../constants/TransferIcon';
import './styles.scss';

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  deleteBankCard: bankId => dispatch(cashActions.deleteBankCard(bankId)),
});

const BankCard = ({ deleteBankCard, updateModalAlert, item, onClick, _activeSelectedCard }) => {
  const { ButtonRemove } = TransferIcon;
  const { t } = useTranslation();
  return (
    <div onClick={() => { if (!onClick) return; onClick(item); }} className={`bankCard pr-3 pb-3 ${_activeSelectedCard && _activeSelectedCard.id === item.id ? 'selected' : ''}`}>
      <div
        className="removeCard recyclePostion"
        onClick={e => {
          if (!e) e = window.event;
          e.cancelBubble = true;
          if (e.stopPropagation) e.stopPropagation();
          updateModalAlert({
            visible: true,
            type: 'confirmation',
            data: {
              message: t('ARE_YOU_SURE_TO_DELETE_BANK_CARD'),
              onConfirmationClick: () => {
                deleteBankCard(item.id);
              },
              onCancelClick: () => {
                updateModalAlert({
                  visible: false,
                });
              }
            }
          });
        }}
      >
        <ButtonRemove />
      </div>
      <div className="cardNo">
        {item.bankNumberCut.substr(item.bankNumberCut.length - 5, item.bankNumberCut.length)}
      </div>
      <div className="cardName">
        {item.bankName}
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(BankCard);