import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';
import gameItemIconMap from '../../../GameItemIcon';

const HM15 = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '第一球',
      cycleHeaderTitle: false,
      mainColumnLength: 4,
      subColumnLength: 1,
      subColumnStyle: ['column-1item-style'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayOrText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'B1M1',
          displayName: '1',
          className: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'B1M6',
          displayName: '6',
          className: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'B1M11',
          displayName: '11',
          className: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'B1M16',
          displayName: '16',
          className: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'B1M2',
          displayName: '2',
          className: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'B1M7',
          displayName: '7',
          className: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'B1M12',
          displayName: '12',
          className: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'B1M17',
          displayName: '17',
          className: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'B1M3',
          displayName: '3',
          className: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'B1M8',
          displayName: '8',
          className: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'B1M13',
          displayName: '13',
          className: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'B1M18',
          displayName: '18',
          className: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'B1M4',
          displayName: '4',
          className: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'B1M9',
          displayName: '9',
          className: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'B1M14',
          displayName: '14',
          className: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'B1M19',
          displayName: '19',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'B1M5',
          displayName: '5',
          className: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'B1M10',
          displayName: '10',
          className: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'B1M15',
          displayName: '15',
          className: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'B1M20',
          displayName: '20',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '第二球',
      cycleHeaderTitle: false,
      mainColumnLength: 4,
      subColumnLength: 1,
      subColumnStyle: ['column-1item-style'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayOrText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'B2M1',
          displayName: '1',
          className: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'B2M6',
          displayName: '6',
          className: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'B2M11',
          displayName: '11',
          className: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'B2M16',
          displayName: '16',
          className: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'B2M2',
          displayName: '2',
          className: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'B2M7',
          displayName: '7',
          className: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'B2M12',
          displayName: '12',
          className: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'B2M17',
          displayName: '17',
          className: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'B2M3',
          displayName: '3',
          className: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'B2M8',
          displayName: '8',
          className: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'B2M13',
          displayName: '13',
          className: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'B2M18',
          displayName: '18',
          className: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'B2M4',
          displayName: '4',
          className: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'B2M9',
          displayName: '9',
          className: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'B2M14',
          displayName: '14',
          className: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'B2M19',
          displayName: '19',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'B2M5',
          displayName: '5',
          className: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'B2M10',
          displayName: '10',
          className: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'B2M15',
          displayName: '15',
          className: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'B2M20',
          displayName: '20',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '第三球',
      cycleHeaderTitle: false,
      mainColumnLength: 4,
      subColumnLength: 1,
      subColumnStyle: ['column-1item-style'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayOrText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'B3M1',
          displayName: '1',
          className: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'B3M6',
          displayName: '6',
          className: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'B3M11',
          displayName: '11',
          className: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'B3M16',
          displayName: '16',
          className: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'B3M2',
          displayName: '2',
          className: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'B3M7',
          displayName: '7',
          className: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'B3M12',
          displayName: '12',
          className: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'B3M17',
          displayName: '17',
          className: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'B3M3',
          displayName: '3',
          className: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'B3M8',
          displayName: '8',
          className: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'B3M13',
          displayName: '13',
          className: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'B3M18',
          displayName: '18',
          className: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'B3M4',
          displayName: '4',
          className: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'B3M9',
          displayName: '9',
          className: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'B3M14',
          displayName: '14',
          className: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'B3M19',
          displayName: '19',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'B3M5',
          displayName: '5',
          className: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'B3M10',
          displayName: '10',
          className: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'B3M15',
          displayName: '15',
          className: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'B3M20',
          displayName: '20',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '第四球',
      cycleHeaderTitle: false,
      mainColumnLength: 4,
      subColumnLength: 1,
      subColumnStyle: ['column-1item-style'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayOrText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'B4M1',
          displayName: '1',
          className: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'B4M6',
          displayName: '6',
          className: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'B4M11',
          displayName: '11',
          className: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'B4M16',
          displayName: '16',
          className: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'B4M2',
          displayName: '2',
          className: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'B4M7',
          displayName: '7',
          className: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'B4M12',
          displayName: '12',
          className: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'B4M17',
          displayName: '17',
          className: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'B4M3',
          displayName: '3',
          className: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'B4M8',
          displayName: '8',
          className: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'B4M13',
          displayName: '13',
          className: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'B4M18',
          displayName: '18',
          className: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'B4M4',
          displayName: '4',
          className: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'B4M9',
          displayName: '9',
          className: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'B4M14',
          displayName: '14',
          className: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'B4M19',
          displayName: '19',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'B4M5',
          displayName: '5',
          className: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'B4M10',
          displayName: '10',
          className: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'B4M15',
          displayName: '15',
          className: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'B4M20',
          displayName: '20',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '第五球',
      cycleHeaderTitle: false,
      mainColumnLength: 4,
      subColumnLength: 1,
      subColumnStyle: ['column-1item-style'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayOrText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'B5M1',
          displayName: '1',
          className: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'B5M6',
          displayName: '6',
          className: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'B5M11',
          displayName: '11',
          className: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'B5M16',
          displayName: '16',
          className: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'B5M2',
          displayName: '2',
          className: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'B5M7',
          displayName: '7',
          className: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'B5M12',
          displayName: '12',
          className: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'B5M17',
          displayName: '17',
          className: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'B5M3',
          displayName: '3',
          className: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'B5M8',
          displayName: '8',
          className: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'B5M13',
          displayName: '13',
          className: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'B5M18',
          displayName: '18',
          className: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'B5M4',
          displayName: '4',
          className: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'B5M9',
          displayName: '9',
          className: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'B5M14',
          displayName: '14',
          className: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'B5M19',
          displayName: '19',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'B5M5',
          displayName: '5',
          className: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'B5M10',
          displayName: '10',
          className: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'B5M15',
          displayName: '15',
          className: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'B5M20',
          displayName: '20',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '第六球',
      cycleHeaderTitle: false,
      mainColumnLength: 4,
      subColumnLength: 1,
      subColumnStyle: ['column-1item-style'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayOrText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'B6M1',
          displayName: '1',
          className: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'B6M6',
          displayName: '6',
          className: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'B6M11',
          displayName: '11',
          className: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'B6M16',
          displayName: '16',
          className: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'B6M2',
          displayName: '2',
          className: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'B6M7',
          displayName: '7',
          className: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'B6M12',
          displayName: '12',
          className: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'B6M17',
          displayName: '17',
          className: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'B6M3',
          displayName: '3',
          className: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'B6M8',
          displayName: '8',
          className: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'B6M13',
          displayName: '13',
          className: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'B6M18',
          displayName: '18',
          className: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'B6M4',
          displayName: '4',
          className: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'B6M9',
          displayName: '9',
          className: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'B6M14',
          displayName: '14',
          className: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'B6M19',
          displayName: '19',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'B6M5',
          displayName: '5',
          className: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'B6M10',
          displayName: '10',
          className: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'B6M15',
          displayName: '15',
          className: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'B6M20',
          displayName: '20',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '第七球',
      cycleHeaderTitle: false,
      mainColumnLength: 4,
      subColumnLength: 1,
      subColumnStyle: ['column-1item-style'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayOrText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'B7M1',
          displayName: '1',
          className: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'B7M6',
          displayName: '6',
          className: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'B7M11',
          displayName: '11',
          className: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'B7M16',
          displayName: '16',
          className: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'B7M2',
          displayName: '2',
          className: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'B7M7',
          displayName: '7',
          className: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'B7M12',
          displayName: '12',
          className: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'B7M17',
          displayName: '17',
          className: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'B7M3',
          displayName: '3',
          className: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'B7M8',
          displayName: '8',
          className: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'B7M13',
          displayName: '13',
          className: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'B7M18',
          displayName: '18',
          className: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'B7M4',
          displayName: '4',
          className: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'B7M9',
          displayName: '9',
          className: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'B7M14',
          displayName: '14',
          className: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'B7M19',
          displayName: '19',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'B7M5',
          displayName: '5',
          className: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'B7M10',
          displayName: '10',
          className: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'B7M15',
          displayName: '15',
          className: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'B7M20',
          displayName: '20',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '第八球',
      cycleHeaderTitle: false,
      mainColumnLength: 4,
      subColumnLength: 1,
      subColumnStyle: ['column-1item-style'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayOrText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'B8M1',
          displayName: '1',
          className: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'B8M6',
          displayName: '6',
          className: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'B8M11',
          displayName: '11',
          className: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'B8M16',
          displayName: '16',
          className: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'B8M2',
          displayName: '2',
          className: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'B8M7',
          displayName: '7',
          className: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'B8M12',
          displayName: '12',
          className: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'B8M17',
          displayName: '17',
          className: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'B8M3',
          displayName: '3',
          className: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'B8M8',
          displayName: '8',
          className: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'B8M13',
          displayName: '13',
          className: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'B8M18',
          displayName: '18',
          className: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'B8M4',
          displayName: '4',
          className: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'B8M9',
          displayName: '9',
          className: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'B8M14',
          displayName: '14',
          className: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'B8M19',
          displayName: '19',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'B8M5',
          displayName: '5',
          className: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'B8M10',
          displayName: '10',
          className: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'B8M15',
          displayName: '15',
          className: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'B8M20',
          displayName: '20',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default HM15;