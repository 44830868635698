import HM1 from './HM1';
import HM15 from './HM15';
import HM2 from './HM2';
import HM3 from './HM3';
import HM4 from './HM4';
import HM5 from './HM5';
import LM from './LM';
import { renderResultDateCell } from '../../utils';

const balls = {
  1: {
    value: 1,
    color: '红',
    fls: '福',
    className: 'circle circleRed'
  },
  2: {
    value: 2,
    color: '蓝',
    fls: '福',
    className: 'circle circleBlue'
  },
  3: {
    value: 3,
    color: '绿',
    fls: '福',
    className: 'circle circleGreen'
  },
  4: {
    value: 4,
    color: '红',
    fls: '福',
    className: 'circle circleRed'
  },
  5: {
    value: 5,
    color: '蓝',
    fls: '福',
    className: 'circle circleBlue'
  },
  6: {
    value: 6,
    color: '绿',
    fls: '禄',
    className: 'circle circleGreen'
  },
  7: {
    color: '红',
    value: 7,
    fls: '禄',
    className: 'circle circleRed'
  },
  8: {
    value: 8,
    color: '蓝',
    fls: '禄',
    className: 'circle circleBlue'
  },
  9: {
    value: 9,
    color: '绿',
    fls: '禄',
    className: 'circle circleGreen'
  },
  10: {
    color: '红',
    value: 10,
    fls: '禄',
    className: 'circle circleRed'
  },
  11: {
    value: 11,
    color: '蓝',
    fls: '寿',
    className: 'circle circleBlue'
  },
  12: {
    value: 12,
    color: '绿',
    fls: '寿',
    className: 'circle circleGreen'
  },
  13: {
    color: '红',
    value: 13,
    fls: '寿',
    className: 'circle circleRed'
  },
  14: {
    value: 14,
    color: '蓝',
    fls: '寿',
    className: 'circle circleBlue'
  },
  15: {
    value: 15,
    color: '绿',
    fls: '寿',
    className: 'circle circleGreen'
  },
  16: {
    color: '红',
    value: 16,
    fls: '喜',
    className: 'circle circleRed'
  },
  17: {
    value: 17,
    color: '蓝',
    fls: '喜',
    className: 'circle circleBlue'
  },
  18: {
    value: 18,
    color: '绿',
    fls: '喜',
    className: 'circle circleGreen'
  },
  19: {
    color: '红',
    value: 19,
    fls: '喜',
    className: 'circle circleRed'
  },
  20: {
    value: 20,
    color: '蓝',
    fls: '喜',
    className: 'circle circleBlue'
  },
  21: {
    value: 21,
    color: '绿',
    className: 'circle circleGreen'
  }
};

const renderQiu = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  return result.map(item => (
    {
      value: item, className: balls[item] ? balls[item].className : ''
    }
  ));
};

const renderZH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  let championSum = 0;
  result.forEach(item => (championSum += parseInt(item, 10)));
  tempResult.push({
    value: championSum
  });

  tempResult.push({
    value: championSum > 55 ? '大' : championSum < 55 ? '小' : '和',
    className: championSum > 55 ? 'redText' : '',
  });

  tempResult.push({
    value: championSum % 2 ? '单' : '双',
    className: championSum % 2 ? 'redText' : '',
  });

  tempResult.push({
    value: (championSum % 10) >= 5 ? '尾大' : '尾小',
    className: (championSum % 10) >= 5 ? 'redText' : '',
  });
  return tempResult;
};

const renderLH = data => {
  if (data && !data.openResult) return;
  const tempResult = [];
  const result = data.openResult.split(',');
  tempResult.push({
    value: ((parseInt(result[0], 10)) > parseInt(result[result.length - 1], 10)) ? '龙' : '虎',
    className: ((parseInt(result[0], 10)) > parseInt(result[result.length - 1], 10)) ? 'redText' : '',
  });
  return tempResult;
};

const GXK10 = {
  children: [
    {
      tabId: 'LIANGMIAN',
      label: '两面',
      children: LM.LM,
      settingCode: 'LM,LH,ZHDX,ZHDS,ZHWDX'
    },
    {
      tabId: 'HM15',
      label: '单号1-5',
      children: HM15.HM15,
      settingCode: 'QH15'
    },
    {
      tabId: 'HM1',
      label: '第一球',
      children: HM1.HM1,
      midCode: 'B1QH,B1LM'
    },
    {
      tabId: 'HM2',
      label: '第二球',
      children: HM2.HM2,
      midCode: 'B2QH,B2LM,B2FLSX,B2SB'
    },
    {
      tabId: 'HM3',
      label: '第三球',
      children: HM3.HM3,
      midCode: 'B3QH,B3LM,B3FLSX,B3SB'
    },
    {
      tabId: 'HM4',
      label: '第四球',
      children: HM4.HM4,
      midCode: 'B4QH,B4LM,B4FLSX,B4SB'
    },
    {
      tabId: 'HM5',
      label: '第五球',
      children: HM5.HM5,
      midCode: 'B5QH,B5LM,B5FLSX,B5SB'
    }
  ],
  resultTab: {
    headerCell: [{
      attr: '期数',
      wrapperStyle: 'gxk10Header'
    }, {
      attr: '日期',
      wrapperStyle: 'gxk10Header'
    }, {
      attr: '彩球号码',
      wrapperStyle: 'gxk10Header'
    }, {
      attr: '总和',
      wrapperStyle: 'gxk10Header'
    }, {
      attr: '龙虎',
      wrapperStyle: 'gxk10Header'
    }],
    bodyCell: [{
      attr: 'installments',
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderResultDateCell,
      wrapperStyle: 'dateCol'
    }, {
      attr: renderQiu,
      wrapperStyle: 'd-flex justify-content-center align-items-center gxk10Num'
    }, {
      attr: renderZH,
      wrapperStyle: 'd-flex justify-content-center align-items-center gxk10ZH'
    }, {
      attr: renderLH,
      wrapperStyle: 'd-flex justify-content-center align-items-center gxk10LH'
    }],
  },
  results: {
    header: [{
      value: '开奖结果'
    }],
    TabList: [{
      code: 'NUMBER',
      renderFunc: rowItem => rowItem && rowItem.openResult && rowItem.openResult.split(',').map(item => ({
        value: item,
      }))
    },
    {
      code: 'BIG_SMALL',
      renderFunc: rowItem => rowItem && rowItem.openResult && rowItem.openResult.split(',').map(item => ({
        value: parseInt(item, 10) === 21 ? '和' : (item <= 10 ? '小' : '大'),
        className: parseInt(item, 10) === 21 ? '' : (item <= 10 ? 'smallValue' : ''),
      }))
    },
    {
      code: 'ODD_EVEN',
      renderFunc: rowItem => rowItem && rowItem.openResult && rowItem.openResult.split(',').map(item => ({
        value: parseInt(item, 10) === 21 ? '和' : (item % 2 ? '单' : '双'),
        className: parseInt(item, 10) === 21 ? '和' : (item % 2 ? '' : 'even')
      }))
    },
    {
      code: 'HE_DAN_SHUANG',
      renderFunc: rowItem => rowItem && rowItem.openResult && rowItem.openResult.split(',').map(item => ({
        value: item.length > 1 ? (((parseInt(item[0], 10) + parseInt(item[1], 10)) % 2) ? '单' : '双') : (item % 2 ? '单' : '双'),
        className: item.length > 1 ? (((parseInt(item[0], 10) + parseInt(item[1], 10)) % 2) ? '' : 'even') : (item % 2 ? '' : 'even'),
      }))
    },
    {
      code: 'DA_XIAO_WEI',
      renderFunc: rowItem => rowItem && rowItem.openResult && rowItem.openResult.split(',').map(item => ({
        value: (item % 10) >= 5 ? '大' : '小',
        className: (item % 10) >= 5 ? '' : 'smallValue'
      }))
    },
    {
      code: 'SE_BO',
      renderFunc: rowItem => rowItem && rowItem.openResult && rowItem.openResult.split(',').map(item => ({
        value: balls[item].color,
      }))
    },
    {
      code: 'FU_LU_SHOU_XI',
      renderFunc: rowItem => rowItem && rowItem.openResult && rowItem.openResult.split(',').map(item => ({
        value: balls[item].fls || '和',
      }))
    }]
  }
};

export default GXK10;