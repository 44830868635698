import BZ from './BZ';

import LIANGMIAN from './LIANGMIAN';
import LM from './LM';
import TEMA from './TEMA';
import ZHENGMA from './ZHENGMA';
import ZTM from './ZTM';

const F31X7 = {
  children: [
    {
      tabId: 'LIANGMIAN',
      label: '两面',
      children: LIANGMIAN.LIANGMIAN,
      settingCode: 'TMLM,ZMLM'
    },
    {
      tabId: 'TEMA',
      label: '特码',
      children: TEMA.TEMA,
      settingCode: 'TM,TMLM'
    },
    {
      tabId: 'ZHENGMA',
      label: '正码',
      children: ZHENGMA.ZHENGMA,
      settingCode: 'ZM,ZFLM'
    }, {
      tabId: 'ZTM',
      label: '正特码',
      children: ZTM.ZTM,
      settingCode: 'ZTM'
    },
    {
      tabId: 'BZ',
      label: '不中',
      children: BZ.BZ,
      settingCode: 'BZ'
    },
    {
      tabId: 'LM',
      label: '连码',
      children: LM.LM,
      settingCode: '2QZ,1ZB,3QZ,2ZB,4QZ,3ZB,3ZT,4ZT'
    },
  ],
  results: {
    header: [{
      value: '开奖结果'
    }],
    // TabList: [{
    //   code: 'NUMBER',
    //   renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
    //     value: item, className: ''
    //   }))
    // },
    // {
    //   code: 'BIG_SMALL',
    //   renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
    //     value: item <= 5 ? '小' : '大', className: ''
    //   }))
    // },
    // {
    //   code: 'ODD_EVEN',
    //   renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
    //     value: item % 2 ? '单' : '双', className: ''
    //   }))
    // }],
    defaultFunc: () => {}
  }
};

export default F31X7;