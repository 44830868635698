import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const SZZH = [
  {
    componentType: CollapseType,
    header: '三字前三',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [{
        code: 'ZH3Q3_000', // keyCode
        displayName: '000',
      }, {
        code: 'ZH3Q3_001', // keyCode
        displayName: '001',
      }, {
        code: 'ZH3Q3_002',
        displayName: '002',
      }, {
        code: 'ZH3Q3_003',
        displayName: '003',
      }, {
        code: 'ZH3Q3_004',
        displayName: '004',
      }, {
        code: 'ZH3Q3_005',
        displayName: '005',
      }, {
        code: 'ZH3Q3_006',
        displayName: '006',
      }, {
        code: 'ZH3Q3_007',
        displayName: '007',
      }, {
        code: 'ZH3Q3_008',
        displayName: '008',
      }, {
        code: 'ZH3Q3_009',
        displayName: '009',
      }, {
        code: 'ZH3Q3_011', // keyCode
        displayName: '011',
      }, {
        code: 'ZH3Q3_012',
        displayName: '012',
      }, {
        code: 'ZH3Q3_013',
        displayName: '013',
      }, {
        code: 'ZH3Q3_014',
        displayName: '014',
      }, {
        code: 'ZH3Q3_015',
        displayName: '015',
      }, {
        code: 'ZH3Q3_016',
        displayName: '016',
      }, {
        code: 'ZH3Q3_017',
        displayName: '017',
      }, {
        code: 'ZH3Q3_018',
        displayName: '018',
      }, {
        code: 'ZH3Q3_019',
        displayName: '019',
      }, {
        code: 'ZH3Q3_022',
        displayName: '022',
      }, {
        code: 'ZH3Q3_023',
        displayName: '023',
      }, {
        code: 'ZH3Q3_024',
        displayName: '024',
      }, {
        code: 'ZH3Q3_025',
        displayName: '025',
      }, {
        code: 'ZH3Q3_026',
        displayName: '026',
      }, {
        code: 'ZH3Q3_027',
        displayName: '027',
      }, {
        code: 'ZH3Q3_028',
        displayName: '028',
      }, {
        code: 'ZH3Q3_029',
        displayName: '029',
      }, {
        code: 'ZH3Q3_033',
        displayName: '033',
      }, {
        code: 'ZH3Q3_034',
        displayName: '034',
      }, {
        code: 'ZH3Q3_035',
        displayName: '035',
      }, {
        code: 'ZH3Q3_036',
        displayName: '036',
      }, {
        code: 'ZH3Q3_037',
        displayName: '037',
      }, {
        code: 'ZH3Q3_038',
        displayName: '038',
      }, {
        code: 'ZH3Q3_039',
        displayName: '039',
      }, {
        code: 'ZH3Q3_044',
        displayName: '044',
      }, {
        code: 'ZH3Q3_045',
        displayName: '045',
      }, {
        code: 'ZH3Q3_046',
        displayName: '046',
      }, {
        code: 'ZH3Q3_047',
        displayName: '047',
      }, {
        code: 'ZH3Q3_048',
        displayName: '048',
      }, {
        code: 'ZH3Q3_049',
        displayName: '049',
      }, {
        code: 'ZH3Q3_055',
        displayName: '055',
      }, {
        code: 'ZH3Q3_056',
        displayName: '056',
      }, {
        code: 'ZH3Q3_057',
        displayName: '057',
      }, {
        code: 'ZH3Q3_058',
        displayName: '058',
      }, {
        code: 'ZH3Q3_059',
        displayName: '059',
      }, {
        code: 'ZH3Q3_066',
        displayName: '066',
      }, {
        code: 'ZH3Q3_067',
        displayName: '067',
      }, {
        code: 'ZH3Q3_068',
        displayName: '068',
      }, {
        code: 'ZH3Q3_069',
        displayName: '069',
      }, {
        code: 'ZH3Q3_077',
        displayName: '077',
      }, {
        code: 'ZH3Q3_078',
        displayName: '078',
      }, {
        code: 'ZH3Q3_079',
        displayName: '079',
      }, {
        code: 'ZH3Q3_088',
        displayName: '088',
      }, {
        code: 'ZH3Q3_089',
        displayName: '089',
      }, {
        code: 'ZH3Q3_099',
        displayName: '099',
      }, {
        code: 'ZH3Q3_111',
        displayName: '111',
      }, {
        code: 'ZH3Q3_112',
        displayName: '112',
      }, {
        code: 'ZH3Q3_113',
        displayName: '113',
      }, {
        code: 'ZH3Q3_114',
        displayName: '114',
      }, {
        code: 'ZH3Q3_115',
        displayName: '115',
      }, {
        code: 'ZH3Q3_116',
        displayName: '116',
      }, {
        code: 'ZH3Q3_117',
        displayName: '117',
      }, {
        code: 'ZH3Q3_118',
        displayName: '118',
      }, {
        code: 'ZH3Q3_119',
        displayName: '129',
      }, {
        code: 'ZH3Q3_122',
        displayName: '122',
      }, {
        code: 'ZH3Q3_123',
        displayName: '123',
      }, {
        code: 'ZH3Q3_124',
        displayName: '124',
      }, {
        code: 'ZH3Q3_125',
        displayName: '125',
      }, {
        code: 'ZH3Q3_126',
        displayName: '126',
      }, {
        code: 'ZH3Q3_127',
        displayName: '127',
      }, {
        code: 'ZH3Q3_128',
        displayName: '128',
      }, {
        code: 'ZH3Q3_129',
        displayName: '129',
      }, {
        code: 'ZH3Q3_133',
        displayName: '133',
      }, {
        code: 'ZH3Q3_134',
        displayName: '134',
      }, {
        code: 'ZH3Q3_135',
        displayName: '135',
      }, {
        code: 'ZH3Q3_136',
        displayName: '136',
      }, {
        code: 'ZH3Q3_137',
        displayName: '137',
      }, {
        code: 'ZH3Q3_138',
        displayName: '138',
      }, {
        code: 'ZH3Q3_139',
        displayName: '139',
      }, {
        code: 'ZH3Q3_144',
        displayName: '144',
      }, {
        code: 'ZH3Q3_145',
        displayName: '145',
      }, {
        code: 'ZH3Q3_146',
        displayName: '146',
      }, {
        code: 'ZH3Q3_147',
        displayName: '147',
      }, {
        code: 'ZH3Q3_148',
        displayName: '148',
      }, {
        code: 'ZH3Q3_149',
        displayName: '149',
      }, {
        code: 'ZH3Q3_155',
        displayName: '155',
      }, {
        code: 'ZH3Q3_156',
        displayName: '156',
      }, {
        code: 'ZH3Q3_157',
        displayName: '157',
      }, {
        code: 'ZH3Q3_158',
        displayName: '158',
      }, {
        code: 'ZH3Q3_159',
        displayName: '159',
      }, {
        code: 'ZH3Q3_166',
        displayName: '166',
      }, {
        code: 'ZH3Q3_167',
        displayName: '167',
      }, {
        code: 'ZH3Q3_168',
        displayName: '168',
      }, {
        code: 'ZH3Q3_169',
        displayName: '169',
      }, {
        code: 'ZH3Q3_177',
        displayName: '177',
      }, {
        code: 'ZH3Q3_178',
        displayName: '178',
      }, {
        code: 'ZH3Q3_179',
        displayName: '179',
      }, {
        code: 'ZH3Q3_188',
        displayName: '188',
      }, {
        code: 'ZH3Q3_189',
        displayName: '189',
      }, {
        code: 'ZH3Q3_199',
        displayName: '199',
      }, {
        code: 'ZH3Q3_222',
        displayName: '222',
      }, {
        code: 'ZH3Q3_223',
        displayName: '223',
      }, {
        code: 'ZH3Q3_224',
        displayName: '224',
      }, {
        code: 'ZH3Q3_225',
        displayName: '225',
      }, {
        code: 'ZH3Q3_226',
        displayName: '226',
      }, {
        code: 'ZH3Q3_227',
        displayName: '227',
      }, {
        code: 'ZH3Q3_228',
        displayName: '228',
      }, {
        code: 'ZH3Q3_229',
        displayName: '229',
      }, {
        code: 'ZH3Q3_233',
        displayName: '233',
      }, {
        code: 'ZH3Q3_234',
        displayName: '234',
      }, {
        code: 'ZH3Q3_235',
        displayName: '235',
      }, {
        code: 'ZH3Q3_236',
        displayName: '236',
      }, {
        code: 'ZH3Q3_237',
        displayName: '237',
      }, {
        code: 'ZH3Q3_238',
        displayName: '238',
      }, {
        code: 'ZH3Q3_239',
        displayName: '239',
      }, {
        code: 'ZH3Q3_244',
        displayName: '244',
      }, {
        code: 'ZH3Q3_245',
        displayName: '245',
      }, {
        code: 'ZH3Q3_246',
        displayName: '246',
      }, {
        code: 'ZH3Q3_247',
        displayName: '247',
      }, {
        code: 'ZH3Q3_248',
        displayName: '248',
      }, {
        code: 'ZH3Q3_249',
        displayName: '249',
      }, {
        code: 'ZH3Q3_255',
        displayName: '255',
      }, {
        code: 'ZH3Q3_256',
        displayName: '256',
      }, {
        code: 'ZH3Q3_257',
        displayName: '257',
      }, {
        code: 'ZH3Q3_258',
        displayName: '258',
      }, {
        code: 'ZH3Q3_259',
        displayName: '259',
      }, {
        code: 'ZH3Q3_266',
        displayName: '266',
      }, {
        code: 'ZH3Q3_267',
        displayName: '267',
      }, {
        code: 'ZH3Q3_268',
        displayName: '268',
      }, {
        code: 'ZH3Q3_269',
        displayName: '269',
      }, {
        code: 'ZH3Q3_277',
        displayName: '277',
      }, {
        code: 'ZH3Q3_278',
        displayName: '278',
      }, {
        code: 'ZH3Q3_279',
        displayName: '279',
      }, {
        code: 'ZH3Q3_288',
        displayName: '288',
      }, {
        code: 'ZH3Q3_289',
        displayName: '289',
      }, {
        code: 'ZH3Q3_299',
        displayName: '299',
      }, {
        code: 'ZH3Q3_333',
        displayName: '333',
      }, {
        code: 'ZH3Q3_334',
        displayName: '334',
      }, {
        code: 'ZH3Q3_335',
        displayName: '335',
      }, {
        code: 'ZH3Q3_336',
        displayName: '336',
      }, {
        code: 'ZH3Q3_337',
        displayName: '337',
      }, {
        code: 'ZH3Q3_338',
        displayName: '338',
      }, {
        code: 'ZH3Q3_339',
        displayName: '339',
      }, {
        code: 'ZH3Q3_344',
        displayName: '344',
      }, {
        code: 'ZH3Q3_345',
        displayName: '345',
      }, {
        code: 'ZH3Q3_346',
        displayName: '346',
      }, {
        code: 'ZH3Q3_347',
        displayName: '347',
      }, {
        code: 'ZH3Q3_348',
        displayName: '348',
      }, {
        code: 'ZH3Q3_349',
        displayName: '349',
      }, {
        code: 'ZH3Q3_355',
        displayName: '355',
      }, {
        code: 'ZH3Q3_356',
        displayName: '356',
      }, {
        code: 'ZH3Q3_357',
        displayName: '357',
      }, {
        code: 'ZH3Q3_358',
        displayName: '358',
      }, {
        code: 'ZH3Q3_359',
        displayName: '359',
      }, {
        code: 'ZH3Q3_366',
        displayName: '366',
      }, {
        code: 'ZH3Q3_367',
        displayName: '367',
      }, {
        code: 'ZH3Q3_368',
        displayName: '368',
      }, {
        code: 'ZH3Q3_369',
        displayName: '369',
      }, {
        code: 'ZH3Q3_377',
        displayName: '377',
      }, {
        code: 'ZH3Q3_378',
        displayName: '378',
      }, {
        code: 'ZH3Q3_379',
        displayName: '379',
      }, {
        code: 'ZH3Q3_388',
        displayName: '388',
      }, {
        code: 'ZH3Q3_389',
        displayName: '389',
      }, {
        code: 'ZH3Q3_399',
        displayName: '399',
      }, {
        code: 'ZH3Q3_444',
        displayName: '444',
      }, {
        code: 'ZH3Q3_445',
        displayName: '445',
      }, {
        code: 'ZH3Q3_446',
        displayName: '446',
      }, {
        code: 'ZH3Q3_447',
        displayName: '447',
      }, {
        code: 'ZH3Q3_448',
        displayName: '448',
      }, {
        code: 'ZH3Q3_449',
        displayName: '449',
      }, {
        code: 'ZH3Q3_455',
        displayName: '455',
      }, {
        code: 'ZH3Q3_456',
        displayName: '456',
      }, {
        code: 'ZH3Q3_457',
        displayName: '457',
      }, {
        code: 'ZH3Q3_458',
        displayName: '458',
      }, {
        code: 'ZH3Q3_459',
        displayName: '459',
      }, {
        code: 'ZH3Q3_466',
        displayName: '466',
      }, {
        code: 'ZH3Q3_467',
        displayName: '467',
      }, {
        code: 'ZH3Q3_468',
        displayName: '468',
      }, {
        code: 'ZH3Q3_469',
        displayName: '469',
      }, {
        code: 'ZH3Q3_477',
        displayName: '477',
      }, {
        code: 'ZH3Q3_478',
        displayName: '478',
      }, {
        code: 'ZH3Q3_479',
        displayName: '479',
      }, {
        code: 'ZH3Q3_488',
        displayName: '488',
      }, {
        code: 'ZH3Q3_489',
        displayName: '489',
      }, {
        code: 'ZH3Q3_499',
        displayName: '499',
      }, {
        code: 'ZH3Q3_555',
        displayName: '555',
      }, {
        code: 'ZH3Q3_556',
        displayName: '556',
      }, {
        code: 'ZH3Q3_557',
        displayName: '557',
      }, {
        code: 'ZH3Q3_558',
        displayName: '558',
      }, {
        code: 'ZH3Q3_559',
        displayName: '559',
      }, {
        code: 'ZH3Q3_566',
        displayName: '566',
      }, {
        code: 'ZH3Q3_567',
        displayName: '567',
      }, {
        code: 'ZH3Q3_568',
        displayName: '568',
      }, {
        code: 'ZH3Q3_569',
        displayName: '569',
      }, {
        code: 'ZH3Q3_577',
        displayName: '577',
      }, {
        code: 'ZH3Q3_578',
        displayName: '578',
      }, {
        code: 'ZH3Q3_579',
        displayName: '579',
      }, {
        code: 'ZH3Q3_588',
        displayName: '588',
      }, {
        code: 'ZH3Q3_589',
        displayName: '589',
      }, {
        code: 'ZH3Q3_599',
        displayName: '599',
      }, {
        code: 'ZH3Q3_666',
        displayName: '666',
      }, {
        code: 'ZH3Q3_667',
        displayName: '667',
      }, {
        code: 'ZH3Q3_668',
        displayName: '668',
      }, {
        code: 'ZH3Q3_669',
        displayName: '669',
      }, {
        code: 'ZH3Q3_677',
        displayName: '677',
      }, {
        code: 'ZH3Q3_678',
        displayName: '678',
      }, {
        code: 'ZH3Q3_679',
        displayName: '679',
      }, {
        code: 'ZH3Q3_688',
        displayName: '688',
      }, {
        code: 'ZH3Q3_689',
        displayName: '689',
      }, {
        code: 'ZH3Q3_699',
        displayName: '699',
      }, {
        code: 'ZH3Q3_777',
        displayName: '777',
      }, {
        code: 'ZH3Q3_778',
        displayName: '778',
      }, {
        code: 'ZH3Q3_779',
        displayName: '779',
      }, {
        code: 'ZH3Q3_788',
        displayName: '788',
      }, {
        code: 'ZH3Q3_789',
        displayName: '789',
      }, {
        code: 'ZH3Q3_799',
        displayName: '799',
      }, {
        code: 'ZH3Q3_888',
        displayName: '888',
      }, {
        code: 'ZH3Q3_889',
        displayName: '889',
      }, {
        code: 'ZH3Q3_899',
        displayName: '899',
      }, {
        code: 'ZH3Q3_999',
        displayName: '999',
      }]
    }
  },
  {
    componentType: CollapseType,
    header: '三字中三',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [{
        code: 'ZH3Z3_000', // keyCode
        displayName: '000',
      }, {
        code: 'ZH3Z3_001', // keyCode
        displayName: '001',
      }, {
        code: 'ZH3Z3_002',
        displayName: '002',
      }, {
        code: 'ZH3Z3_003',
        displayName: '003',
      }, {
        code: 'ZH3Z3_004',
        displayName: '004',
      }, {
        code: 'ZH3Z3_005',
        displayName: '005',
      }, {
        code: 'ZH3Z3_006',
        displayName: '006',
      }, {
        code: 'ZH3Z3_007',
        displayName: '007',
      }, {
        code: 'ZH3Z3_008',
        displayName: '008',
      }, {
        code: 'ZH3Z3_009',
        displayName: '009',
      }, {
        code: 'ZH3Z3_011', // keyCode
        displayName: '011',
      }, {
        code: 'ZH3Z3_012',
        displayName: '012',
      }, {
        code: 'ZH3Z3_013',
        displayName: '013',
      }, {
        code: 'ZH3Z3_014',
        displayName: '014',
      }, {
        code: 'ZH3Z3_015',
        displayName: '015',
      }, {
        code: 'ZH3Z3_016',
        displayName: '016',
      }, {
        code: 'ZH3Z3_017',
        displayName: '017',
      }, {
        code: 'ZH3Z3_018',
        displayName: '018',
      }, {
        code: 'ZH3Z3_019',
        displayName: '019',
      }, {
        code: 'ZH3Z3_022',
        displayName: '022',
      }, {
        code: 'ZH3Z3_023',
        displayName: '023',
      }, {
        code: 'ZH3Z3_024',
        displayName: '024',
      }, {
        code: 'ZH3Z3_025',
        displayName: '025',
      }, {
        code: 'ZH3Z3_026',
        displayName: '026',
      }, {
        code: 'ZH3Z3_027',
        displayName: '027',
      }, {
        code: 'ZH3Z3_028',
        displayName: '028',
      }, {
        code: 'ZH3Z3_029',
        displayName: '029',
      }, {
        code: 'ZH3Z3_033',
        displayName: '033',
      }, {
        code: 'ZH3Z3_034',
        displayName: '034',
      }, {
        code: 'ZH3Z3_035',
        displayName: '035',
      }, {
        code: 'ZH3Z3_036',
        displayName: '036',
      }, {
        code: 'ZH3Z3_037',
        displayName: '037',
      }, {
        code: 'ZH3Z3_038',
        displayName: '038',
      }, {
        code: 'ZH3Z3_039',
        displayName: '039',
      }, {
        code: 'ZH3Z3_044',
        displayName: '044',
      }, {
        code: 'ZH3Z3_045',
        displayName: '045',
      }, {
        code: 'ZH3Z3_046',
        displayName: '046',
      }, {
        code: 'ZH3Z3_047',
        displayName: '047',
      }, {
        code: 'ZH3Z3_048',
        displayName: '048',
      }, {
        code: 'ZH3Z3_049',
        displayName: '049',
      }, {
        code: 'ZH3Z3_055',
        displayName: '055',
      }, {
        code: 'ZH3Z3_056',
        displayName: '056',
      }, {
        code: 'ZH3Z3_057',
        displayName: '057',
      }, {
        code: 'ZH3Z3_058',
        displayName: '058',
      }, {
        code: 'ZH3Z3_059',
        displayName: '059',
      }, {
        code: 'ZH3Z3_066',
        displayName: '066',
      }, {
        code: 'ZH3Z3_067',
        displayName: '067',
      }, {
        code: 'ZH3Z3_068',
        displayName: '068',
      }, {
        code: 'ZH3Z3_069',
        displayName: '069',
      }, {
        code: 'ZH3Z3_077',
        displayName: '077',
      }, {
        code: 'ZH3Z3_078',
        displayName: '078',
      }, {
        code: 'ZH3Z3_079',
        displayName: '079',
      }, {
        code: 'ZH3Z3_088',
        displayName: '088',
      }, {
        code: 'ZH3Z3_089',
        displayName: '089',
      }, {
        code: 'ZH3Z3_099',
        displayName: '099',
      }, {
        code: 'ZH3Z3_111',
        displayName: '111',
      }, {
        code: 'ZH3Z3_112',
        displayName: '112',
      }, {
        code: 'ZH3Z3_113',
        displayName: '113',
      }, {
        code: 'ZH3Z3_114',
        displayName: '114',
      }, {
        code: 'ZH3Z3_115',
        displayName: '115',
      }, {
        code: 'ZH3Z3_116',
        displayName: '116',
      }, {
        code: 'ZH3Z3_117',
        displayName: '117',
      }, {
        code: 'ZH3Z3_118',
        displayName: '118',
      }, {
        code: 'ZH3Z3_119',
        displayName: '129',
      }, {
        code: 'ZH3Z3_122',
        displayName: '122',
      }, {
        code: 'ZH3Z3_123',
        displayName: '123',
      }, {
        code: 'ZH3Z3_124',
        displayName: '124',
      }, {
        code: 'ZH3Z3_125',
        displayName: '125',
      }, {
        code: 'ZH3Z3_126',
        displayName: '126',
      }, {
        code: 'ZH3Z3_127',
        displayName: '127',
      }, {
        code: 'ZH3Z3_128',
        displayName: '128',
      }, {
        code: 'ZH3Z3_129',
        displayName: '129',
      }, {
        code: 'ZH3Z3_133',
        displayName: '133',
      }, {
        code: 'ZH3Z3_134',
        displayName: '134',
      }, {
        code: 'ZH3Z3_135',
        displayName: '135',
      }, {
        code: 'ZH3Z3_136',
        displayName: '136',
      }, {
        code: 'ZH3Z3_137',
        displayName: '137',
      }, {
        code: 'ZH3Z3_138',
        displayName: '138',
      }, {
        code: 'ZH3Z3_139',
        displayName: '139',
      }, {
        code: 'ZH3Z3_144',
        displayName: '144',
      }, {
        code: 'ZH3Z3_145',
        displayName: '145',
      }, {
        code: 'ZH3Z3_146',
        displayName: '146',
      }, {
        code: 'ZH3Z3_147',
        displayName: '147',
      }, {
        code: 'ZH3Z3_148',
        displayName: '148',
      }, {
        code: 'ZH3Z3_149',
        displayName: '149',
      }, {
        code: 'ZH3Z3_155',
        displayName: '155',
      }, {
        code: 'ZH3Z3_156',
        displayName: '156',
      }, {
        code: 'ZH3Z3_157',
        displayName: '157',
      }, {
        code: 'ZH3Z3_158',
        displayName: '158',
      }, {
        code: 'ZH3Z3_159',
        displayName: '159',
      }, {
        code: 'ZH3Z3_166',
        displayName: '166',
      }, {
        code: 'ZH3Z3_167',
        displayName: '167',
      }, {
        code: 'ZH3Z3_168',
        displayName: '168',
      }, {
        code: 'ZH3Z3_169',
        displayName: '169',
      }, {
        code: 'ZH3Z3_177',
        displayName: '177',
      }, {
        code: 'ZH3Z3_178',
        displayName: '178',
      }, {
        code: 'ZH3Z3_179',
        displayName: '179',
      }, {
        code: 'ZH3Z3_188',
        displayName: '188',
      }, {
        code: 'ZH3Z3_189',
        displayName: '189',
      }, {
        code: 'ZH3Z3_199',
        displayName: '199',
      }, {
        code: 'ZH3Z3_222',
        displayName: '222',
      }, {
        code: 'ZH3Z3_223',
        displayName: '223',
      }, {
        code: 'ZH3Z3_224',
        displayName: '224',
      }, {
        code: 'ZH3Z3_225',
        displayName: '225',
      }, {
        code: 'ZH3Z3_226',
        displayName: '226',
      }, {
        code: 'ZH3Z3_227',
        displayName: '227',
      }, {
        code: 'ZH3Z3_228',
        displayName: '228',
      }, {
        code: 'ZH3Z3_229',
        displayName: '229',
      }, {
        code: 'ZH3Z3_233',
        displayName: '233',
      }, {
        code: 'ZH3Z3_234',
        displayName: '234',
      }, {
        code: 'ZH3Z3_235',
        displayName: '235',
      }, {
        code: 'ZH3Z3_236',
        displayName: '236',
      }, {
        code: 'ZH3Z3_237',
        displayName: '237',
      }, {
        code: 'ZH3Z3_238',
        displayName: '238',
      }, {
        code: 'ZH3Z3_239',
        displayName: '239',
      }, {
        code: 'ZH3Z3_244',
        displayName: '244',
      }, {
        code: 'ZH3Z3_245',
        displayName: '245',
      }, {
        code: 'ZH3Z3_246',
        displayName: '246',
      }, {
        code: 'ZH3Z3_247',
        displayName: '247',
      }, {
        code: 'ZH3Z3_248',
        displayName: '248',
      }, {
        code: 'ZH3Z3_249',
        displayName: '249',
      }, {
        code: 'ZH3Z3_255',
        displayName: '255',
      }, {
        code: 'ZH3Z3_256',
        displayName: '256',
      }, {
        code: 'ZH3Z3_257',
        displayName: '257',
      }, {
        code: 'ZH3Z3_258',
        displayName: '258',
      }, {
        code: 'ZH3Z3_259',
        displayName: '259',
      }, {
        code: 'ZH3Z3_266',
        displayName: '266',
      }, {
        code: 'ZH3Z3_267',
        displayName: '267',
      }, {
        code: 'ZH3Z3_268',
        displayName: '268',
      }, {
        code: 'ZH3Z3_269',
        displayName: '269',
      }, {
        code: 'ZH3Z3_277',
        displayName: '277',
      }, {
        code: 'ZH3Z3_278',
        displayName: '278',
      }, {
        code: 'ZH3Z3_279',
        displayName: '279',
      }, {
        code: 'ZH3Z3_288',
        displayName: '288',
      }, {
        code: 'ZH3Z3_289',
        displayName: '289',
      }, {
        code: 'ZH3Z3_299',
        displayName: '299',
      }, {
        code: 'ZH3Z3_333',
        displayName: '333',
      }, {
        code: 'ZH3Z3_334',
        displayName: '334',
      }, {
        code: 'ZH3Z3_335',
        displayName: '335',
      }, {
        code: 'ZH3Z3_336',
        displayName: '336',
      }, {
        code: 'ZH3Z3_337',
        displayName: '337',
      }, {
        code: 'ZH3Z3_338',
        displayName: '338',
      }, {
        code: 'ZH3Z3_339',
        displayName: '339',
      }, {
        code: 'ZH3Z3_344',
        displayName: '344',
      }, {
        code: 'ZH3Z3_345',
        displayName: '345',
      }, {
        code: 'ZH3Z3_346',
        displayName: '346',
      }, {
        code: 'ZH3Z3_347',
        displayName: '347',
      }, {
        code: 'ZH3Z3_348',
        displayName: '348',
      }, {
        code: 'ZH3Z3_349',
        displayName: '349',
      }, {
        code: 'ZH3Z3_355',
        displayName: '355',
      }, {
        code: 'ZH3Z3_356',
        displayName: '356',
      }, {
        code: 'ZH3Z3_357',
        displayName: '357',
      }, {
        code: 'ZH3Z3_358',
        displayName: '358',
      }, {
        code: 'ZH3Z3_359',
        displayName: '359',
      }, {
        code: 'ZH3Z3_366',
        displayName: '366',
      }, {
        code: 'ZH3Z3_367',
        displayName: '367',
      }, {
        code: 'ZH3Z3_368',
        displayName: '368',
      }, {
        code: 'ZH3Z3_369',
        displayName: '369',
      }, {
        code: 'ZH3Z3_377',
        displayName: '377',
      }, {
        code: 'ZH3Z3_378',
        displayName: '378',
      }, {
        code: 'ZH3Z3_379',
        displayName: '379',
      }, {
        code: 'ZH3Z3_388',
        displayName: '388',
      }, {
        code: 'ZH3Z3_389',
        displayName: '389',
      }, {
        code: 'ZH3Z3_399',
        displayName: '399',
      }, {
        code: 'ZH3Z3_444',
        displayName: '444',
      }, {
        code: 'ZH3Z3_445',
        displayName: '445',
      }, {
        code: 'ZH3Z3_446',
        displayName: '446',
      }, {
        code: 'ZH3Z3_447',
        displayName: '447',
      }, {
        code: 'ZH3Z3_448',
        displayName: '448',
      }, {
        code: 'ZH3Z3_449',
        displayName: '449',
      }, {
        code: 'ZH3Z3_455',
        displayName: '455',
      }, {
        code: 'ZH3Z3_456',
        displayName: '456',
      }, {
        code: 'ZH3Z3_457',
        displayName: '457',
      }, {
        code: 'ZH3Z3_458',
        displayName: '458',
      }, {
        code: 'ZH3Z3_459',
        displayName: '459',
      }, {
        code: 'ZH3Z3_466',
        displayName: '466',
      }, {
        code: 'ZH3Z3_467',
        displayName: '467',
      }, {
        code: 'ZH3Z3_468',
        displayName: '468',
      }, {
        code: 'ZH3Z3_469',
        displayName: '469',
      }, {
        code: 'ZH3Z3_477',
        displayName: '477',
      }, {
        code: 'ZH3Z3_478',
        displayName: '478',
      }, {
        code: 'ZH3Z3_479',
        displayName: '479',
      }, {
        code: 'ZH3Z3_488',
        displayName: '488',
      }, {
        code: 'ZH3Z3_489',
        displayName: '489',
      }, {
        code: 'ZH3Z3_499',
        displayName: '499',
      }, {
        code: 'ZH3Z3_555',
        displayName: '555',
      }, {
        code: 'ZH3Z3_556',
        displayName: '556',
      }, {
        code: 'ZH3Z3_557',
        displayName: '557',
      }, {
        code: 'ZH3Z3_558',
        displayName: '558',
      }, {
        code: 'ZH3Z3_559',
        displayName: '559',
      }, {
        code: 'ZH3Z3_566',
        displayName: '566',
      }, {
        code: 'ZH3Z3_567',
        displayName: '567',
      }, {
        code: 'ZH3Z3_568',
        displayName: '568',
      }, {
        code: 'ZH3Z3_569',
        displayName: '569',
      }, {
        code: 'ZH3Z3_577',
        displayName: '577',
      }, {
        code: 'ZH3Z3_578',
        displayName: '578',
      }, {
        code: 'ZH3Z3_579',
        displayName: '579',
      }, {
        code: 'ZH3Z3_588',
        displayName: '588',
      }, {
        code: 'ZH3Z3_589',
        displayName: '589',
      }, {
        code: 'ZH3Z3_599',
        displayName: '599',
      }, {
        code: 'ZH3Z3_666',
        displayName: '666',
      }, {
        code: 'ZH3Z3_667',
        displayName: '667',
      }, {
        code: 'ZH3Z3_668',
        displayName: '668',
      }, {
        code: 'ZH3Z3_669',
        displayName: '669',
      }, {
        code: 'ZH3Z3_677',
        displayName: '677',
      }, {
        code: 'ZH3Z3_678',
        displayName: '678',
      }, {
        code: 'ZH3Z3_679',
        displayName: '679',
      }, {
        code: 'ZH3Z3_688',
        displayName: '688',
      }, {
        code: 'ZH3Z3_689',
        displayName: '689',
      }, {
        code: 'ZH3Z3_699',
        displayName: '699',
      }, {
        code: 'ZH3Z3_777',
        displayName: '777',
      }, {
        code: 'ZH3Z3_778',
        displayName: '778',
      }, {
        code: 'ZH3Z3_779',
        displayName: '779',
      }, {
        code: 'ZH3Z3_788',
        displayName: '788',
      }, {
        code: 'ZH3Z3_789',
        displayName: '789',
      }, {
        code: 'ZH3Z3_799',
        displayName: '799',
      }, {
        code: 'ZH3Z3_888',
        displayName: '888',
      }, {
        code: 'ZH3Z3_889',
        displayName: '889',
      }, {
        code: 'ZH3Z3_899',
        displayName: '899',
      }, {
        code: 'ZH3Z3_999',
        displayName: '999',
      }]
    }
  },
  {
    componentType: CollapseType,
    header: '三字后三',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [{
        code: 'ZH3H3_000', // keyCode
        displayName: '000',
      }, {
        code: 'ZH3H3_001', // keyCode
        displayName: '001',
      }, {
        code: 'ZH3H3_002',
        displayName: '002',
      }, {
        code: 'ZH3H3_003',
        displayName: '003',
      }, {
        code: 'ZH3H3_004',
        displayName: '004',
      }, {
        code: 'ZH3H3_005',
        displayName: '005',
      }, {
        code: 'ZH3H3_006',
        displayName: '006',
      }, {
        code: 'ZH3H3_007',
        displayName: '007',
      }, {
        code: 'ZH3H3_008',
        displayName: '008',
      }, {
        code: 'ZH3H3_009',
        displayName: '009',
      }, {
        code: 'ZH3H3_011', // keyCode
        displayName: '011',
      }, {
        code: 'ZH3H3_012',
        displayName: '012',
      }, {
        code: 'ZH3H3_013',
        displayName: '013',
      }, {
        code: 'ZH3H3_014',
        displayName: '014',
      }, {
        code: 'ZH3H3_015',
        displayName: '015',
      }, {
        code: 'ZH3H3_016',
        displayName: '016',
      }, {
        code: 'ZH3H3_017',
        displayName: '017',
      }, {
        code: 'ZH3H3_018',
        displayName: '018',
      }, {
        code: 'ZH3H3_019',
        displayName: '019',
      }, {
        code: 'ZH3H3_022',
        displayName: '022',
      }, {
        code: 'ZH3H3_023',
        displayName: '023',
      }, {
        code: 'ZH3H3_024',
        displayName: '024',
      }, {
        code: 'ZH3H3_025',
        displayName: '025',
      }, {
        code: 'ZH3H3_026',
        displayName: '026',
      }, {
        code: 'ZH3H3_027',
        displayName: '027',
      }, {
        code: 'ZH3H3_028',
        displayName: '028',
      }, {
        code: 'ZH3H3_029',
        displayName: '029',
      }, {
        code: 'ZH3H3_033',
        displayName: '033',
      }, {
        code: 'ZH3H3_034',
        displayName: '034',
      }, {
        code: 'ZH3H3_035',
        displayName: '035',
      }, {
        code: 'ZH3H3_036',
        displayName: '036',
      }, {
        code: 'ZH3H3_037',
        displayName: '037',
      }, {
        code: 'ZH3H3_038',
        displayName: '038',
      }, {
        code: 'ZH3H3_039',
        displayName: '039',
      }, {
        code: 'ZH3H3_044',
        displayName: '044',
      }, {
        code: 'ZH3H3_045',
        displayName: '045',
      }, {
        code: 'ZH3H3_046',
        displayName: '046',
      }, {
        code: 'ZH3H3_047',
        displayName: '047',
      }, {
        code: 'ZH3H3_048',
        displayName: '048',
      }, {
        code: 'ZH3H3_049',
        displayName: '049',
      }, {
        code: 'ZH3H3_055',
        displayName: '055',
      }, {
        code: 'ZH3H3_056',
        displayName: '056',
      }, {
        code: 'ZH3H3_057',
        displayName: '057',
      }, {
        code: 'ZH3H3_058',
        displayName: '058',
      }, {
        code: 'ZH3H3_059',
        displayName: '059',
      }, {
        code: 'ZH3H3_066',
        displayName: '066',
      }, {
        code: 'ZH3H3_067',
        displayName: '067',
      }, {
        code: 'ZH3H3_068',
        displayName: '068',
      }, {
        code: 'ZH3H3_069',
        displayName: '069',
      }, {
        code: 'ZH3H3_077',
        displayName: '077',
      }, {
        code: 'ZH3H3_078',
        displayName: '078',
      }, {
        code: 'ZH3H3_079',
        displayName: '079',
      }, {
        code: 'ZH3H3_088',
        displayName: '088',
      }, {
        code: 'ZH3H3_089',
        displayName: '089',
      }, {
        code: 'ZH3H3_099',
        displayName: '099',
      }, {
        code: 'ZH3H3_111',
        displayName: '111',
      }, {
        code: 'ZH3H3_112',
        displayName: '112',
      }, {
        code: 'ZH3H3_113',
        displayName: '113',
      }, {
        code: 'ZH3H3_114',
        displayName: '114',
      }, {
        code: 'ZH3H3_115',
        displayName: '115',
      }, {
        code: 'ZH3H3_116',
        displayName: '116',
      }, {
        code: 'ZH3H3_117',
        displayName: '117',
      }, {
        code: 'ZH3H3_118',
        displayName: '118',
      }, {
        code: 'ZH3H3_119',
        displayName: '129',
      }, {
        code: 'ZH3H3_122',
        displayName: '122',
      }, {
        code: 'ZH3H3_123',
        displayName: '123',
      }, {
        code: 'ZH3H3_124',
        displayName: '124',
      }, {
        code: 'ZH3H3_125',
        displayName: '125',
      }, {
        code: 'ZH3H3_126',
        displayName: '126',
      }, {
        code: 'ZH3H3_127',
        displayName: '127',
      }, {
        code: 'ZH3H3_128',
        displayName: '128',
      }, {
        code: 'ZH3H3_129',
        displayName: '129',
      }, {
        code: 'ZH3H3_133',
        displayName: '133',
      }, {
        code: 'ZH3H3_134',
        displayName: '134',
      }, {
        code: 'ZH3H3_135',
        displayName: '135',
      }, {
        code: 'ZH3H3_136',
        displayName: '136',
      }, {
        code: 'ZH3H3_137',
        displayName: '137',
      }, {
        code: 'ZH3H3_138',
        displayName: '138',
      }, {
        code: 'ZH3H3_139',
        displayName: '139',
      }, {
        code: 'ZH3H3_144',
        displayName: '144',
      }, {
        code: 'ZH3H3_145',
        displayName: '145',
      }, {
        code: 'ZH3H3_146',
        displayName: '146',
      }, {
        code: 'ZH3H3_147',
        displayName: '147',
      }, {
        code: 'ZH3H3_148',
        displayName: '148',
      }, {
        code: 'ZH3H3_149',
        displayName: '149',
      }, {
        code: 'ZH3H3_155',
        displayName: '155',
      }, {
        code: 'ZH3H3_156',
        displayName: '156',
      }, {
        code: 'ZH3H3_157',
        displayName: '157',
      }, {
        code: 'ZH3H3_158',
        displayName: '158',
      }, {
        code: 'ZH3H3_159',
        displayName: '159',
      }, {
        code: 'ZH3H3_166',
        displayName: '166',
      }, {
        code: 'ZH3H3_167',
        displayName: '167',
      }, {
        code: 'ZH3H3_168',
        displayName: '168',
      }, {
        code: 'ZH3H3_169',
        displayName: '169',
      }, {
        code: 'ZH3H3_177',
        displayName: '177',
      }, {
        code: 'ZH3H3_178',
        displayName: '178',
      }, {
        code: 'ZH3H3_179',
        displayName: '179',
      }, {
        code: 'ZH3H3_188',
        displayName: '188',
      }, {
        code: 'ZH3H3_189',
        displayName: '189',
      }, {
        code: 'ZH3H3_199',
        displayName: '199',
      }, {
        code: 'ZH3H3_222',
        displayName: '222',
      }, {
        code: 'ZH3H3_223',
        displayName: '223',
      }, {
        code: 'ZH3H3_224',
        displayName: '224',
      }, {
        code: 'ZH3H3_225',
        displayName: '225',
      }, {
        code: 'ZH3H3_226',
        displayName: '226',
      }, {
        code: 'ZH3H3_227',
        displayName: '227',
      }, {
        code: 'ZH3H3_228',
        displayName: '228',
      }, {
        code: 'ZH3H3_229',
        displayName: '229',
      }, {
        code: 'ZH3H3_233',
        displayName: '233',
      }, {
        code: 'ZH3H3_234',
        displayName: '234',
      }, {
        code: 'ZH3H3_235',
        displayName: '235',
      }, {
        code: 'ZH3H3_236',
        displayName: '236',
      }, {
        code: 'ZH3H3_237',
        displayName: '237',
      }, {
        code: 'ZH3H3_238',
        displayName: '238',
      }, {
        code: 'ZH3H3_239',
        displayName: '239',
      }, {
        code: 'ZH3H3_244',
        displayName: '244',
      }, {
        code: 'ZH3H3_245',
        displayName: '245',
      }, {
        code: 'ZH3H3_246',
        displayName: '246',
      }, {
        code: 'ZH3H3_247',
        displayName: '247',
      }, {
        code: 'ZH3H3_248',
        displayName: '248',
      }, {
        code: 'ZH3H3_249',
        displayName: '249',
      }, {
        code: 'ZH3H3_255',
        displayName: '255',
      }, {
        code: 'ZH3H3_256',
        displayName: '256',
      }, {
        code: 'ZH3H3_257',
        displayName: '257',
      }, {
        code: 'ZH3H3_258',
        displayName: '258',
      }, {
        code: 'ZH3H3_259',
        displayName: '259',
      }, {
        code: 'ZH3H3_266',
        displayName: '266',
      }, {
        code: 'ZH3H3_267',
        displayName: '267',
      }, {
        code: 'ZH3H3_268',
        displayName: '268',
      }, {
        code: 'ZH3H3_269',
        displayName: '269',
      }, {
        code: 'ZH3H3_277',
        displayName: '277',
      }, {
        code: 'ZH3H3_278',
        displayName: '278',
      }, {
        code: 'ZH3H3_279',
        displayName: '279',
      }, {
        code: 'ZH3H3_288',
        displayName: '288',
      }, {
        code: 'ZH3H3_289',
        displayName: '289',
      }, {
        code: 'ZH3H3_299',
        displayName: '299',
      }, {
        code: 'ZH3H3_333',
        displayName: '333',
      }, {
        code: 'ZH3H3_334',
        displayName: '334',
      }, {
        code: 'ZH3H3_335',
        displayName: '335',
      }, {
        code: 'ZH3H3_336',
        displayName: '336',
      }, {
        code: 'ZH3H3_337',
        displayName: '337',
      }, {
        code: 'ZH3H3_338',
        displayName: '338',
      }, {
        code: 'ZH3H3_339',
        displayName: '339',
      }, {
        code: 'ZH3H3_344',
        displayName: '344',
      }, {
        code: 'ZH3H3_345',
        displayName: '345',
      }, {
        code: 'ZH3H3_346',
        displayName: '346',
      }, {
        code: 'ZH3H3_347',
        displayName: '347',
      }, {
        code: 'ZH3H3_348',
        displayName: '348',
      }, {
        code: 'ZH3H3_349',
        displayName: '349',
      }, {
        code: 'ZH3H3_355',
        displayName: '355',
      }, {
        code: 'ZH3H3_356',
        displayName: '356',
      }, {
        code: 'ZH3H3_357',
        displayName: '357',
      }, {
        code: 'ZH3H3_358',
        displayName: '358',
      }, {
        code: 'ZH3H3_359',
        displayName: '359',
      }, {
        code: 'ZH3H3_366',
        displayName: '366',
      }, {
        code: 'ZH3H3_367',
        displayName: '367',
      }, {
        code: 'ZH3H3_368',
        displayName: '368',
      }, {
        code: 'ZH3H3_369',
        displayName: '369',
      }, {
        code: 'ZH3H3_377',
        displayName: '377',
      }, {
        code: 'ZH3H3_378',
        displayName: '378',
      }, {
        code: 'ZH3H3_379',
        displayName: '379',
      }, {
        code: 'ZH3H3_388',
        displayName: '388',
      }, {
        code: 'ZH3H3_389',
        displayName: '389',
      }, {
        code: 'ZH3H3_399',
        displayName: '399',
      }, {
        code: 'ZH3H3_444',
        displayName: '444',
      }, {
        code: 'ZH3H3_445',
        displayName: '445',
      }, {
        code: 'ZH3H3_446',
        displayName: '446',
      }, {
        code: 'ZH3H3_447',
        displayName: '447',
      }, {
        code: 'ZH3H3_448',
        displayName: '448',
      }, {
        code: 'ZH3H3_449',
        displayName: '449',
      }, {
        code: 'ZH3H3_455',
        displayName: '455',
      }, {
        code: 'ZH3H3_456',
        displayName: '456',
      }, {
        code: 'ZH3H3_457',
        displayName: '457',
      }, {
        code: 'ZH3H3_458',
        displayName: '458',
      }, {
        code: 'ZH3H3_459',
        displayName: '459',
      }, {
        code: 'ZH3H3_466',
        displayName: '466',
      }, {
        code: 'ZH3H3_467',
        displayName: '467',
      }, {
        code: 'ZH3H3_468',
        displayName: '468',
      }, {
        code: 'ZH3H3_469',
        displayName: '469',
      }, {
        code: 'ZH3H3_477',
        displayName: '477',
      }, {
        code: 'ZH3H3_478',
        displayName: '478',
      }, {
        code: 'ZH3H3_479',
        displayName: '479',
      }, {
        code: 'ZH3H3_488',
        displayName: '488',
      }, {
        code: 'ZH3H3_489',
        displayName: '489',
      }, {
        code: 'ZH3H3_499',
        displayName: '499',
      }, {
        code: 'ZH3H3_555',
        displayName: '555',
      }, {
        code: 'ZH3H3_556',
        displayName: '556',
      }, {
        code: 'ZH3H3_557',
        displayName: '557',
      }, {
        code: 'ZH3H3_558',
        displayName: '558',
      }, {
        code: 'ZH3H3_559',
        displayName: '559',
      }, {
        code: 'ZH3H3_566',
        displayName: '566',
      }, {
        code: 'ZH3H3_567',
        displayName: '567',
      }, {
        code: 'ZH3H3_568',
        displayName: '568',
      }, {
        code: 'ZH3H3_569',
        displayName: '569',
      }, {
        code: 'ZH3H3_577',
        displayName: '577',
      }, {
        code: 'ZH3H3_578',
        displayName: '578',
      }, {
        code: 'ZH3H3_579',
        displayName: '579',
      }, {
        code: 'ZH3H3_588',
        displayName: '588',
      }, {
        code: 'ZH3H3_589',
        displayName: '589',
      }, {
        code: 'ZH3H3_599',
        displayName: '599',
      }, {
        code: 'ZH3H3_666',
        displayName: '666',
      }, {
        code: 'ZH3H3_667',
        displayName: '667',
      }, {
        code: 'ZH3H3_668',
        displayName: '668',
      }, {
        code: 'ZH3H3_669',
        displayName: '669',
      }, {
        code: 'ZH3H3_677',
        displayName: '677',
      }, {
        code: 'ZH3H3_678',
        displayName: '678',
      }, {
        code: 'ZH3H3_679',
        displayName: '679',
      }, {
        code: 'ZH3H3_688',
        displayName: '688',
      }, {
        code: 'ZH3H3_689',
        displayName: '689',
      }, {
        code: 'ZH3H3_699',
        displayName: '699',
      }, {
        code: 'ZH3H3_777',
        displayName: '777',
      }, {
        code: 'ZH3H3_778',
        displayName: '778',
      }, {
        code: 'ZH3H3_779',
        displayName: '779',
      }, {
        code: 'ZH3H3_788',
        displayName: '788',
      }, {
        code: 'ZH3H3_789',
        displayName: '789',
      }, {
        code: 'ZH3H3_799',
        displayName: '799',
      }, {
        code: 'ZH3H3_888',
        displayName: '888',
      }, {
        code: 'ZH3H3_889',
        displayName: '889',
      }, {
        code: 'ZH3H3_899',
        displayName: '899',
      }, {
        code: 'ZH3H3_999',
        displayName: '999',
      }]
    }
  },
];

export default SZZH;