import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const LM = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '总和、龙虎',
      cycleHeaderTitle: true,
      mainColumnLength: 4,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-style-1th', 'column-3items-style-2th', 'column-3items-style-3th'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'ZH41',
          displayName: '总和大',
          className: 'label',
        }, {
          code: 'ZH43',
          displayName: '总和单',
          className: 'label',
        }, {
          code: 'ZH45',
          displayName: '总尾大',
          className: 'label',
        }, {
          code: 'ZH47',
          displayName: '龙',
          className: 'label',
        }, {
          code: 'ZH42',
          displayName: '总和小',
          className: 'label',
        }, {
          code: 'ZH44',
          displayName: '总和双',
          className: 'label',
        }, {
          code: 'ZH46',
          displayName: '总尾小',
          className: 'label',
        }, {
          code: 'ZH48',
          displayName: '虎',
          className: 'label',
        },
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: false,
      tableHeadertitle: ['第一球', '第二球', '第三球', '第四球', '第五球'],
      mainColumnLength: 5,
      subColumnLength: 1,
      subColumnStyle: ['column-1item-style'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'B1LM1',
          displayName: '大',
          className: 'label',
        }, {
          code: 'B2LM9',
          displayName: '大',
          className: 'label',
        }, {
          code: 'B3LM17',
          displayName: '大',
          className: 'label',
        }, {
          code: 'B4LM25',
          displayName: '大',
          className: 'label',
        }, {
          code: 'B5LM33',
          displayName: '大',
          className: 'label',
        },
        {
          code: 'B1LM2',
          displayName: '小',
          className: 'label',
        }, {
          code: 'B2LM10',
          displayName: '小',
          className: 'label',
        }, {
          code: 'B3LM18',
          displayName: '小',
          className: 'label',
        }, {
          code: 'B4LM26',
          displayName: '小',
          className: 'label',
        }, {
          code: 'B5LM34',
          displayName: '小',
          className: 'label',
        },
        {
          code: 'B1LM3',
          displayName: '单',
          className: 'label',
        }, {
          code: 'B2LM11',
          displayName: '单',
          className: 'label',
        }, {
          code: 'B3LM19',
          displayName: '单',
          className: 'label',
        }, {
          code: 'B4LM27',
          displayName: '单',
          className: 'label',
        }, {
          code: 'B5LM35',
          displayName: '单',
          className: 'label',
        },
        {
          code: 'B1LM4',
          displayName: '双',
          className: 'label',
        }, {
          code: 'B2LM12',
          displayName: '双',
          className: 'label',
        }, {
          code: 'B3LM20',
          displayName: '双',
          className: 'label',
        }, {
          code: 'B4LM28',
          displayName: '双',
          className: 'label',
        }, {
          code: 'B5LM36',
          displayName: '双',
          className: 'label',
        },
        {
          code: 'B1LM5',
          displayName: '尾大',
          className: 'label',
        }, {
          code: 'B2LM13',
          displayName: '尾大',
          className: 'label',
        }, {
          code: 'B3LM21',
          displayName: '尾大',
          className: 'label',
        }, {
          code: 'B4LM29',
          displayName: '尾大',
          className: 'label',
        }, {
          code: 'B5LM37',
          displayName: '尾大',
          className: 'label',
        },
        {
          code: 'B1LM6',
          displayName: '尾小',
          className: 'label',
        }, {
          code: 'B2LM14',
          displayName: '尾小',
          className: 'label',
        }, {
          code: 'B3LM22',
          displayName: '尾小',
          className: 'label',
        }, {
          code: 'B4LM30',
          displayName: '尾小',
          className: 'label',
        }, {
          code: 'B5LM38',
          displayName: '尾小',
          className: 'label',
        },
        {
          code: 'B1LM7',
          displayName: '合单',
          className: 'label',
        }, {
          code: 'B2LM15',
          displayName: '合单',
          className: 'label',
        }, {
          code: 'B3LM23',
          displayName: '合单',
          className: 'label',
        }, {
          code: 'B4LM31',
          displayName: '合单',
          className: 'label',
        }, {
          code: 'B5LM39',
          displayName: '合单',
          className: 'label',
        },
        {
          code: 'B1LM8',
          displayName: '合双',
          className: 'label',
        }, {
          code: 'B2LM16',
          displayName: '合双',
          className: 'label',
        }, {
          code: 'B3LM24',
          displayName: '合双',
          className: 'label',
        }, {
          code: 'B4LM32',
          displayName: '合双',
          className: 'label',
        }, {
          code: 'B5LM40',
          displayName: '合双',
          className: 'label',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default LM;