import React, { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { useTranslation } from 'react-i18next';

import { cartActions, gameActions, memberActions, placeBetActions, resultHistoryActions, trendActions } from '../../../redux/actions';
import Label from '../../Label';
import bell from '../../../assets/audio/bell.mp3';
import soundOff from '../../../assets/icon/GameBetIcon/icon_soundoff.svg';
import soundOn from '../../../assets/icon/GameBetIcon/icon_soundon.svg';

import './styles.scss';

momentDurationFormatSetup(moment);

const mapStateToProps = state => ({
  isHorizontal: state.appReducers.isHorizontal,
  gameInfo: state.placeBetReducers.gameInfo,
  countDownTimer: state.placeBetReducers.countDownTimer,
  lotteryType: state.gameReducers.lotteryType,
  userInfo: state.memberReducers.userInfo,
  template: state.gameReducers.template,
  oddsType: state.appReducers.oddsType,
  yiLouCount: state.appReducers.yiLouCount,
  securityData: state.cashReducers.securityData,
  userGames: state.homeReducers.userGames,
  presetEnabled: state.gameReducers.presetEnabled,

  selectedSubType: state.gameReducers.selectedSubType,
  sectionSelection: state.gameReducers.sectionSelection,
});

const mapDispatchToProps = dispatch => ({
  getGameOdds: (lotteryType, oddsType, isRefresh = false, iType = -1, isTmb, settingCode = '', midCode = '') => dispatch(placeBetActions.getGameOdds(lotteryType, oddsType, isRefresh, iType, isTmb, settingCode, midCode)),
  updateGameInfo: gameInfo => dispatch(placeBetActions.updateGameInfo(gameInfo)),
  updateCountDownTimer: countDownTimer => dispatch(placeBetActions.updateCountDownTimer(countDownTimer)),
  updateGameOdds: odds => dispatch(placeBetActions.updateGameOdds(odds)),
  updatePresetAmount: () => dispatch(gameActions.updatePresetAmount()),
  resetItemInCart: () => dispatch(cartActions.resetItemInCart()),
  updateCurrentBets: () => dispatch(placeBetActions.updateCurrentBets()),
  getMemberInfo: () => dispatch(memberActions.getInfo()),
  getResultList: (lotteryTime, lotteryType, page, row, showLatest, isLengReRequest) => dispatch(resultHistoryActions.getResult(lotteryTime, lotteryType, page, row, showLatest, isLengReRequest)),
  getMultiInfo: (lotteryType, currentGameCode, numOfRow) => dispatch(trendActions.getMultiInfo(lotteryType, currentGameCode, numOfRow)),
  getInfo: lotteryType => dispatch(trendActions.getInfo(lotteryType)),
  updateSelectedDan: dan => dispatch(gameActions.updateSelectedDan(dan)),
  updateCurrentSelected: val => dispatch(gameActions.updateCurrentSelected(val)),
});

const CurrentDrawPanel = ({
  GameVariable,
  userGames,
  oddsType,
  isHorizontal,
  template,
  gameInfo,
  getGameOdds,
  getMemberInfo,
  lotteryType,
  updatePresetAmount,
  updateGameInfo,
  resetItemInCart,
  updateCurrentBets,
  getMultiInfo,
  getInfo,
  securityData,
  presetEnabled,
  updateSelectedDan,
  selectedSubType,
  updateCurrentSelected,
  sectionSelection
}) => {
  const [enableBell, setEnableBell] = useState(true);
  const [_countDownTimer, setCountDownTimer] = useState(10);
  const [_gameInfo, setGameInfo] = useState();
  const { t } = useTranslation();
  const savedCallback = useRef();

  const getOdds = isRefresh => {
    if (selectedSubType && template) {
      const currentTab = GameVariable[`CJ_${template}`].children.find(x => x.tabId === selectedSubType);
      if (currentTab) {
        let isTmb;
        if (selectedSubType === 'ZMTB') {
          isTmb = 1;
        } else {
          isTmb = sectionSelection ? sectionSelection?.isTmb ?? undefined : undefined;
        }
        let settingCode;
        let midCode;
        if (currentTab?.settingCode || currentTab?.midCode) {
          settingCode = currentTab?.settingCode ?? '';
          midCode = currentTab?.midCode ?? '';
        } else {
          settingCode = sectionSelection?.settingCode ?? '';
          midCode = sectionSelection?.midCode ?? '';
        }
        if (settingCode || midCode) {
          getGameOdds(lotteryType.item, oddsType, isRefresh === undefined ? !!(gameInfo && gameInfo.oddsList && Object.keys(gameInfo.oddsList).length > 0) : isRefresh, (selectedSubType === 'LM' && (template === 'F36X7' || template === 'F31X7')) ? 7 : -1, isTmb, settingCode, midCode);
        }
      }
    }
  };


  const ResetCallback = () => {
    resetItemInCart();
    if (!presetEnabled) {
      updatePresetAmount(0);
    }
    updateSelectedDan([]);
    if (selectedSubType === 'KuaiJie') {
      updateCurrentSelected([new Set(), new Set()]);
    } else if ((selectedSubType === 'ZhiXuan' || selectedSubType === 'EZDW' || selectedSubType === 'SZDW' || selectedSubType === 'FSZH') && sectionSelection && sectionSelection?.numberOfSelectionBlock) {
      updateCurrentSelected([...new Array(sectionSelection?.numberOfSelectionBlock).fill('1').map(() => (new Set()))]);
    } else {
      updateCurrentSelected([]);
    }
  };

  useEffect(() => {
    setGameInfo(gameInfo);
  }, [gameInfo]);

  useEffect(() => {
    ResetCallback();
    setCountDownTimer(10);
  }, [lotteryType, selectedSubType, oddsType, sectionSelection]);

  useEffect(() => {
    if (lotteryType && selectedSubType && oddsType) {
      setGameInfo(undefined);
      updateGameInfo();
      getOdds(false);
    }
  }, [lotteryType, selectedSubType, oddsType]);

  useEffect(() => {
    if ((sectionSelection && (sectionSelection?.midCode || sectionSelection?.settingCode))) {
      setGameInfo(undefined);
      updateGameInfo();
      getOdds(false);
    }
  }, [sectionSelection]);

  useEffect(() => {
    savedCallback.current = () => {
      if (_countDownTimer !== 0 && _gameInfo?.closeTime === 0) {
        getOdds();
      }
      if (_gameInfo && _gameInfo?.openTime === 0) {
        updateGameInfo();
        updateCurrentBets();
        return getOdds(false);
      }
      if (_gameInfo && _gameInfo?.closeTime !== undefined && _gameInfo?.openTime !== undefined) {
        if (_gameInfo.closeTime > 1) {
          setGameInfo({
            ..._gameInfo,
            closeTime: _gameInfo.closeTime - 1,
            openTime: _gameInfo.openTime - 1,
          });
        }
        const temp = {
          ..._gameInfo
        };
        delete temp.oddsList;
        if (_gameInfo.closeTime === 1 && _gameInfo?.openTime !== 0) {
          ResetCallback();
          updateGameInfo({
            ...temp,
            closeTime: 0,
            openTime: _gameInfo.openTime - 1,
            isRefresh: false
          });
        }
        if (_gameInfo?.closeTime < 1) {
          updateGameInfo({
            ...temp,
            closeTime: _gameInfo.closeTime - 1,
            openTime: _gameInfo.openTime - 1,
            isRefresh: false
          });
        }
      }
      if (_gameInfo && _gameInfo?.closeTime === 10 && enableBell) {
        const audio = new Audio(bell);
        audio.load();
        audio.play();
      }

      if (_countDownTimer === 0) {
        setCountDownTimer(10);
        getMemberInfo();
        getOdds();
        const list = userGames.lotteryList;
        const tempList = securityData?.trendPreference?.list?.length > 0 ? securityData.trendPreference.list : list.filter(lottery => lottery.templateCode === template).slice(0, 4).map(item => item.code);
        getInfo(lotteryType.item);
        getMultiInfo(tempList.toString(), securityData?.trendPreference?.minCount, securityData?.trendPreference?.maxCount);
      } else {
        setCountDownTimer(_countDownTimer - 1);
      }
    };
  });

  useEffect(() => {
    setGameInfo(gameInfo);
  }, [gameInfo]);

  useEffect(() => {
    ResetCallback();
    setCountDownTimer(10);
  }, [lotteryType, selectedSubType, oddsType, sectionSelection]);

  useEffect(() => {
    if (lotteryType && selectedSubType && oddsType) {
      setGameInfo(undefined);
      updateGameInfo();
      getOdds(false);
    }
  }, [lotteryType, selectedSubType, oddsType]);

  useEffect(() => {
    if ((sectionSelection && (sectionSelection?.midCode || sectionSelection?.settingCode))) {
      setGameInfo(undefined);
      updateGameInfo();
      getOdds(false);
    }
  }, [sectionSelection]);

  useEffect(() => {
    let id;
    const tick = () => {
      savedCallback.current();
    };
    if (!id) {
      id = setInterval(tick, 1000);
    }
    return () => {
      clearInterval(id);
      updateGameInfo();
    };
  }, []);

  return isHorizontal ? (
    <div className="timerWrapperHorizontal">
      <Label className="mr-2" value={`${_gameInfo?.installment || '--'}${t('INSTALLMENTS')}`} />
      <Label className="mr-2" value={`${t('TIME_TO_CLOSE')}:`} />
      <Label
        className="mr-2"
        value={moment.duration((_gameInfo && _gameInfo.closeTime >= 0 && _gameInfo.closeTime) || 0, 'second').format(template === 'HK6' && lotteryType.item !== 'JSHK6' ? 'HH:mm:ss' : 'mm:ss', {
          trim: false
        })}
      />
      <Label className="mr-2" value={`${t('TIME_TO_OPEN')}:`} />
      <Label
        className="mr-2"
        value={moment.duration((_gameInfo && _gameInfo.openTime >= 0 && _gameInfo.openTime) || 0, 'second').format(template === 'HK6' && lotteryType.item !== 'JSHK6' ? 'HH:mm:ss' : 'mm:ss', {
          trim: false
        })}
      />
      <Label className="mr-2" value={`${_countDownTimer < 10 ? `0${_countDownTimer}` : _countDownTimer}${t('SECONDS')}`} />
      <div aria-hidden role="button" className={`bellWrapper ${enableBell ? 'bellOn' : 'bellOff'}`} onClick={() => setEnableBell(!enableBell)}>
        <img src={!enableBell ? soundOff : soundOn} alt="bell" />
      </div>
    </div>
  ) : (
    <div className="timerWrapperVertical">
      <div className="installmentWrapper">
        <div className="d-flex justify-content-end align-items-end">
          <Label value={`${_gameInfo?.installment || '--'} ${t('INSTALLMENTS')}`} className="text-right" />
        </div>
        <div className="d-flex justify-content-end align-items-end">
          <Label value={t('TIME_TO_CLOSE')} />
        </div>
      </div>
      <div className="installmentWrapper">
        {
          template === 'HK6' && lotteryType.item !== 'JSHK6' && (
            <>
              <Label
                value={moment.duration((_gameInfo && _gameInfo.closeTime >= 0 && _gameInfo.closeTime) || 0, 'second').format('HH:mm:ss', {
                  trim: false
                }).substr(0, 1)}
                className="timerText"
              />
              <Label
                value={moment.duration((_gameInfo && _gameInfo.closeTime >= 0 && _gameInfo.closeTime) || 0, 'second').format('HH:mm:ss', {
                  trim: false
                }).substr(1, 1)}
                className="timerText"
              />
              <Label value=":" className="colonText mx-1" />
            </>
          )
        }
        <Label
          value={moment.duration((_gameInfo && _gameInfo.closeTime >= 0 && _gameInfo.closeTime) || 0, 'second').format('HH:mm:ss', {
            trim: false
          }).substr(3, 1)}
          className="timerText"
        />
        <Label
          value={moment.duration((_gameInfo && _gameInfo.closeTime >= 0 && _gameInfo.closeTime) || 0, 'second').format('HH:mm:ss', {
            trim: false
          }).substr(4, 1)}
          className="timerText"
        />
        <Label value=":" className="colonText mx-1" />
        <Label
          value={moment.duration((_gameInfo && _gameInfo.closeTime >= 0 && _gameInfo.closeTime) || 0, 'second').format('HH:mm:ss', {
            trim: false
          }).substr(6, 1)}
          className="timerText"
        />
        <Label
          value={moment.duration((_gameInfo && _gameInfo.closeTime >= 0 && _gameInfo.closeTime) || 0, 'second').format('HH:mm:ss', {
            trim: false
          }).substr(7, 1)}
          className="timerText"
        />
      </div>
      <div className="installmentWrapper">
        <div className="d-flex justify-content-end align-items-end">
          <Label value={`${_gameInfo?.installment || '--'} ${t('INSTALLMENTS')}`} className="text-right" />
        </div>
        <div className="d-flex justify-content-end align-items-end">
          <Label value={t('TIME_TO_OPEN')} />
        </div>
      </div>
      <div className="installmentWrapper">
        {
          template === 'HK6' && lotteryType.item !== 'JSHK6' && (
            <>
              <Label
                value={moment.duration((_gameInfo && _gameInfo.openTime >= 0 && _gameInfo.openTime) || 0, 'second').format('HH:mm:ss', {
                  trim: false
                }).substr(0, 1)}
                className="timerText"
              />
              <Label
                value={moment.duration((_gameInfo && _gameInfo.openTime >= 0 && _gameInfo.openTime) || 0, 'second').format('HH:mm:ss', {
                  trim: false
                }).substr(1, 1)}
                className="timerText"
              />
              <Label value=":" className="colonText mx-1" />
            </>
          )
        }
        <Label
          value={moment.duration((_gameInfo && _gameInfo.openTime >= 0 && _gameInfo.openTime) || 0, 'second').format('HH:mm:ss', {
            trim: false
          }).substr(3, 1)}
          className="timerText"
        />
        <Label
          value={moment.duration((_gameInfo && _gameInfo.openTime >= 0 && _gameInfo.openTime) || 0, 'second').format('HH:mm:ss', {
            trim: false
          }).substr(4, 1)}
          className="timerText"
        />
        <Label value=":" className="colonText mx-1" />
        <Label
          value={moment.duration((_gameInfo && _gameInfo.openTime >= 0 && _gameInfo.openTime) || 0, 'second').format('HH:mm:ss', {
            trim: false
          }).substr(6, 1)}
          className="timerText"
        />
        <Label
          value={moment.duration((_gameInfo && _gameInfo.openTime >= 0 && _gameInfo.openTime) || 0, 'second').format('HH:mm:ss', {
            trim: false
          }).substr(7, 1)}
          className="timerText"
        />
      </div>
      <div className="d-inline-block mx-0">
        <Label value={`${_countDownTimer < 10 ? `0${_countDownTimer}` : _countDownTimer}${t('SECONDS')}`} />
      </div>
      <div className="d-inline-block ml-3">
        <div aria-hidden role="button" className={`bellWrapper ${enableBell ? 'bellOn' : 'bellOff'}`} onClick={() => setEnableBell(!enableBell)}>
          <img src={!enableBell ? soundOff : soundOn} alt="bell" />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentDrawPanel);