import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const ZM = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '正码',
      mainColumnLength: 4,
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'ZM1',
          displayName: '01',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM21',
          displayName: '21',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM41',
          displayName: '41',
          className: 'ball ballBlueDark'
        }, {
          code: 'ZM61',
          displayName: '61',
          className: 'ball ballBlueDark'
        },
        {
          code: 'ZM2',
          displayName: '02',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM22',
          displayName: '22',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM42',
          displayName: '42',
          className: 'ball ballBlueDark'
        }, {
          code: 'ZM62',
          displayName: '62',
          className: 'ball ballBlueDark'
        },
        {
          code: 'ZM3',
          displayName: '03',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM23',
          displayName: '23',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM43',
          displayName: '43',
          className: 'ball ballBlueDark'
        }, {
          code: 'ZM63',
          displayName: '63',
          className: 'ball ballBlueDark'
        },
        {
          code: 'ZM4',
          displayName: '04',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM24',
          displayName: '24',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM44',
          displayName: '44',
          className: 'ball ballBlueDark'
        }, {
          code: 'ZM64',
          displayName: '64',
          className: 'ball ballBlueDark'
        },
        {
          code: 'ZM5',
          displayName: '05',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM25',
          displayName: '25',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM45',
          displayName: '45',
          className: 'ball ballBlueDark'
        }, {
          code: 'ZM65',
          displayName: '65',
          className: 'ball ballBlueDark'
        },
        {
          code: 'ZM6',
          displayName: '06',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM26',
          displayName: '26',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM46',
          displayName: '46',
          className: 'ball ballBlueDark'
        }, {
          code: 'ZM66',
          displayName: '66',
          className: 'ball ballBlueDark'
        },
        {
          code: 'ZM7',
          displayName: '07',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM27',
          displayName: '27',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM47',
          displayName: '47',
          className: 'ball ballBlueDark'
        }, {
          code: 'ZM67',
          displayName: '67',
          className: 'ball ballBlueDark'
        },
        {
          code: 'ZM8',
          displayName: '08',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM28',
          displayName: '28',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM48',
          displayName: '48',
          className: 'ball ballBlueDark'
        }, {
          code: 'ZM68',
          displayName: '68',
          className: 'ball ballBlueDark'
        },
        {
          code: 'ZM9',
          displayName: '09',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM29',
          displayName: '29',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM49',
          displayName: '49',
          className: 'ball ballBlueDark'
        }, {
          code: 'ZM69',
          displayName: '69',
          className: 'ball ballBlueDark'
        },
        {
          code: 'ZM10',
          displayName: '10',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM30',
          displayName: '30',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM50',
          displayName: '50',
          className: 'ball ballBlueDark'
        }, {
          code: 'ZM70',
          displayName: '70',
          className: 'ball ballBlueDark'
        },
        {
          code: 'ZM11',
          displayName: '11',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM31',
          displayName: '31',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM51',
          displayName: '51',
          className: 'ball ballBlueDark'
        }, {
          code: 'ZM71',
          displayName: '71',
          className: 'ball ballBlueDark'
        },
        {
          code: 'ZM12',
          displayName: '12',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM32',
          displayName: '32',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM52',
          displayName: '52',
          className: 'ball ballBlueDark'
        }, {
          code: 'ZM72',
          displayName: '72',
          className: 'ball ballBlueDark'
        },
        {
          code: 'ZM13',
          displayName: '13',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM33',
          displayName: '33',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM53',
          displayName: '53',
          className: 'ball ballBlueDark'
        }, {
          code: 'ZM73',
          displayName: '73',
          className: 'ball ballBlueDark'
        },
        {
          code: 'ZM14',
          displayName: '14',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM34',
          displayName: '34',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM54',
          displayName: '54',
          className: 'ball ballBlueDark'
        }, {
          code: 'ZM74',
          displayName: '74',
          className: 'ball ballBlueDark'
        },
        {
          code: 'ZM15',
          displayName: '15',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM35',
          displayName: '35',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM55',
          displayName: '55',
          className: 'ball ballBlueDark'
        }, {
          code: 'ZM75',
          displayName: '75',
          className: 'ball ballBlueDark'
        },
        {
          code: 'ZM16',
          displayName: '16',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM36',
          displayName: '36',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM56',
          displayName: '56',
          className: 'ball ballBlueDark'
        }, {
          code: 'ZM76',
          displayName: '76',
          className: 'ball ballBlueDark'
        },
        {
          code: 'ZM17',
          displayName: '17',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM37',
          displayName: '37',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM57',
          displayName: '57',
          className: 'ball ballBlueDark'
        }, {
          code: 'ZM77',
          displayName: '77',
          className: 'ball ballBlueDark'
        },
        {
          code: 'ZM18',
          displayName: '18',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM38',
          displayName: '38',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM58',
          displayName: '58',
          className: 'ball ballBlueDark'
        }, {
          code: 'ZM78',
          displayName: '78',
          className: 'ball ballBlueDark'
        },
        {
          code: 'ZM19',
          displayName: '19',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM39',
          displayName: '39',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM59',
          displayName: '59',
          className: 'ball ballBlueDark'
        }, {
          code: 'ZM79',
          displayName: '79',
          className: 'ball ballBlueDark'
        },
        {
          code: 'ZM20',
          displayName: '20',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM40',
          displayName: '40',
          className: 'ball ballBlueLight'
        }, {
          code: 'ZM60',
          displayName: '60',
          className: 'ball ballBlueDark'
        }, {
          code: 'ZM80',
          displayName: '80',
          className: 'ball ballBlueDark'
        },
      ]
    }
  },
  {
    componentType: QuickBet,
  }
];

export default ZM;