
import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const ZMT = [
  {
    componentType: CollapseType,
    header: '正一特',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper threeColumnStyle',
      list: [
        {
          code: 'ZMT11',
          displayName: '01',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT12',
          displayName: '02',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT13',
          displayName: '03',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT14',
          displayName: '04',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT15',
          displayName: '05',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT16',
          displayName: '06',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT17',
          displayName: '07',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT18',
          displayName: '08',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT19',
          displayName: '09',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT110',
          displayName: '10',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT111',
          displayName: '11',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT112',
          displayName: '12',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT113',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT114',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT115',
          displayName: '15',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT116',
          displayName: '16',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT117',
          displayName: '17',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT118',
          displayName: '18',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT119',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT120',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT121',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT122',
          displayName: '22',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT123',
          displayName: '23',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT124',
          displayName: '24',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT125',
          displayName: '25',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT126',
          displayName: '26',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT127',
          displayName: '27',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT128',
          displayName: '28',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT129',
          displayName: '29',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT130',
          displayName: '30',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT131',
          displayName: '31',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT132',
          displayName: '32',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT133',
          displayName: '33',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT134',
          displayName: '34',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT135',
          displayName: '35',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT136',
          displayName: '36',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT137',
          displayName: '37',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT138',
          displayName: '38',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT139',
          displayName: '39',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT140',
          displayName: '40',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT141',
          displayName: '41',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT142',
          displayName: '42',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT143',
          displayName: '43',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT144',
          displayName: '44',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT145',
          displayName: '45',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT146',
          displayName: '46',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT147',
          displayName: '47',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT148',
          displayName: '48',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT149',
          displayName: '49',
          drawImgClassName: 'circle circleGreen'
        }
      ]
    }
  },
  // {
  //   componentType: CollapseType,
  //   header: '两面',
  //   headerStyle: '',
  //   data: {
  //     itemType: OptionItem,
  //     itemWrapperStyle: 'Hk6ItemWrapper',
  //     list: [
  //       {
  //         code: 'ZM1LM1',
  //         displayName: '正1-单',
  //       }, {
  //         code: 'ZM1LM3',
  //         displayName: '正1-大',
  //       }, {
  //         code: 'ZM1LM5',
  //         displayName: '正1-合单',
  //       }, {
  //         code: 'ZM1LM7',
  //         displayName: '正1-合大',
  //       }, {
  //         code: 'ZM1LM9',
  //         displayName: '正1-尾大',
  //       }, {
  //         code: 'ZM1LM2',
  //         displayName: '正1-双',
  //       }, {
  //         code: 'ZM1LM4',
  //         displayName: '正1-小',
  //       }, {
  //         code: 'ZM1LM6',
  //         displayName: '正1-合双',
  //       }, {
  //         code: 'ZM1LM8',
  //         displayName: '正1-合小',
  //       }, {
  //         code: 'ZM1LM10',
  //         displayName: '正1-尾小',
  //       }, {
  //         code: 'ZM1SB11',
  //         displayName: '正1-红波',
  //         drawImgClassName: 'fillRed',
  //       }, {
  //         code: 'ZM1SB12',
  //         displayName: '正1-蓝波',
  //         drawImgClassName: 'fillBlue',
  //       }, {
  //         code: 'ZM1SB13',
  //         displayName: '正1-绿波',
  //         drawImgClassName: 'fillGreen',
  //       }
  //     ]
  //   }
  // },
  {
    componentType: CollapseType,
    header: '正二特',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper threeColumnStyle',
      list: [
        {
          code: 'ZMT21',
          displayName: '01',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT22',
          displayName: '02',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT23',
          displayName: '03',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT24',
          displayName: '04',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT25',
          displayName: '05',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT26',
          displayName: '06',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT27',
          displayName: '07',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT28',
          displayName: '08',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT29',
          displayName: '09',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT210',
          displayName: '10',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT211',
          displayName: '11',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT212',
          displayName: '12',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT213',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT214',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT215',
          displayName: '15',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT216',
          displayName: '16',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT217',
          displayName: '17',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT218',
          displayName: '18',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT219',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT220',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT221',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT222',
          displayName: '22',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT223',
          displayName: '23',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT224',
          displayName: '24',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT225',
          displayName: '25',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT226',
          displayName: '26',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT227',
          displayName: '27',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT228',
          displayName: '28',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT229',
          displayName: '29',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT230',
          displayName: '30',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT231',
          displayName: '31',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT232',
          displayName: '32',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT233',
          displayName: '33',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT234',
          displayName: '34',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT235',
          displayName: '35',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT236',
          displayName: '36',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT237',
          displayName: '37',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT238',
          displayName: '38',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT239',
          displayName: '39',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT240',
          displayName: '40',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT241',
          displayName: '41',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT242',
          displayName: '42',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT243',
          displayName: '43',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT244',
          displayName: '44',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT245',
          displayName: '45',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT246',
          displayName: '46',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT247',
          displayName: '47',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT248',
          displayName: '48',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT249',
          displayName: '49',
          drawImgClassName: 'circle circleGreen'
        }
      ]
    }
  },
  // {
  //   componentType: CollapseType,
  //   header: '两面',
  //   headerStyle: '',
  //   data: {
  //     itemType: OptionItem,
  //     itemWrapperStyle: 'Hk6ItemWrapper',
  //     list: [
  //       {
  //         code: 'ZM2LM1',
  //         displayName: '正2-单',
  //       }, {
  //         code: 'ZM2LM3',
  //         displayName: '正2-大',
  //       }, {
  //         code: 'ZM2LM5',
  //         displayName: '正2-合单',
  //       }, {
  //         code: 'ZM2LM7',
  //         displayName: '正2-合大',
  //       }, {
  //         code: 'ZM2LM9',
  //         displayName: '正2-尾大',
  //       }, {
  //         code: 'ZM2LM2',
  //         displayName: '正2-双',
  //       }, {
  //         code: 'ZM2LM4',
  //         displayName: '正2-小',
  //       }, {
  //         code: 'ZM2LM6',
  //         displayName: '正2-合双',
  //       }, {
  //         code: 'ZM2LM8',
  //         displayName: '正2-合小',
  //       }, {
  //         code: 'ZM2LM10',
  //         displayName: '正2-尾小',
  //       }, {
  //         code: 'ZM2SB11',
  //         displayName: '正2-红波',
  //         drawImgClassName: 'fillRed',
  //       }, {
  //         code: 'ZM2SB12',
  //         displayName: '正2-蓝波',
  //         drawImgClassName: 'fillBlue',
  //       }, {
  //         code: 'ZM2SB13',
  //         displayName: '正2-绿波',
  //         drawImgClassName: 'fillGreen',
  //       }
  //     ]
  //   }
  // },
  {
    componentType: CollapseType,
    header: '正三特',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper threeColumnStyle',
      list: [
        {
          code: 'ZMT31',
          displayName: '01',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT32',
          displayName: '02',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT33',
          displayName: '03',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT34',
          displayName: '04',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT35',
          displayName: '05',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT36',
          displayName: '06',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT37',
          displayName: '07',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT38',
          displayName: '08',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT39',
          displayName: '09',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT310',
          displayName: '10',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT311',
          displayName: '11',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT312',
          displayName: '12',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT313',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT314',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT315',
          displayName: '15',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT316',
          displayName: '16',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT317',
          displayName: '17',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT318',
          displayName: '18',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT319',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT320',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT321',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT322',
          displayName: '22',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT323',
          displayName: '23',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT324',
          displayName: '24',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT325',
          displayName: '25',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT326',
          displayName: '26',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT327',
          displayName: '27',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT328',
          displayName: '28',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT329',
          displayName: '29',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT330',
          displayName: '30',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT331',
          displayName: '31',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT332',
          displayName: '32',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT333',
          displayName: '33',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT334',
          displayName: '34',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT335',
          displayName: '35',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT336',
          displayName: '36',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT337',
          displayName: '37',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT338',
          displayName: '38',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT339',
          displayName: '39',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT340',
          displayName: '40',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT341',
          displayName: '41',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT342',
          displayName: '42',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT343',
          displayName: '43',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT344',
          displayName: '44',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT345',
          displayName: '45',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT346',
          displayName: '46',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT347',
          displayName: '47',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT348',
          displayName: '48',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT349',
          displayName: '49',
          drawImgClassName: 'circle circleGreen'
        }
      ]
    }
  },
  // {
  //   componentType: CollapseType,
  //   header: '两面',
  //   headerStyle: '',
  //   data: {
  //     itemType: OptionItem,
  //     itemWrapperStyle: 'Hk6ItemWrapper',
  //     list: [
  //       {
  //         code: 'ZM3LM1',
  //         displayName: '正3-单',
  //       }, {
  //         code: 'ZM3LM3',
  //         displayName: '正3-大',
  //       }, {
  //         code: 'ZM3LM5',
  //         displayName: '正3-合单',
  //       }, {
  //         code: 'ZM3LM7',
  //         displayName: '正3-合大',
  //       }, {
  //         code: 'ZM3LM9',
  //         displayName: '正3-尾大',
  //       }, {
  //         code: 'ZM3LM2',
  //         displayName: '正3-双',
  //       }, {
  //         code: 'ZM3LM4',
  //         displayName: '正3-小',
  //       }, {
  //         code: 'ZM3LM6',
  //         displayName: '正3-合双',
  //       }, {
  //         code: 'ZM3LM8',
  //         displayName: '正3-合小',
  //       }, {
  //         code: 'ZM3LM10',
  //         displayName: '正3-尾小',
  //       }, {
  //         code: 'ZM3SB11',
  //         displayName: '正3-红波',
  //         drawImgClassName: 'fillRed',
  //       }, {
  //         code: 'ZM3SB12',
  //         displayName: '正3-蓝波',
  //         drawImgClassName: 'fillBlue',
  //       }, {
  //         code: 'ZM3SB13',
  //         displayName: '正3-绿波',
  //         drawImgClassName: 'fillGreen',
  //       }
  //     ]
  //   }
  // },
  {
    componentType: CollapseType,
    header: '正四特',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper threeColumnStyle',
      list: [
        {
          code: 'ZMT41',
          displayName: '01',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT42',
          displayName: '02',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT43',
          displayName: '03',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT44',
          displayName: '04',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT45',
          displayName: '05',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT46',
          displayName: '06',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT47',
          displayName: '07',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT48',
          displayName: '08',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT49',
          displayName: '09',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT410',
          displayName: '10',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT411',
          displayName: '11',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT412',
          displayName: '12',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT413',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT414',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT415',
          displayName: '15',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT416',
          displayName: '16',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT417',
          displayName: '17',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT418',
          displayName: '18',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT419',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT420',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT421',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT422',
          displayName: '22',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT423',
          displayName: '23',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT424',
          displayName: '24',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT425',
          displayName: '25',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT426',
          displayName: '26',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT427',
          displayName: '27',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT428',
          displayName: '28',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT429',
          displayName: '29',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT430',
          displayName: '30',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT431',
          displayName: '31',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT432',
          displayName: '32',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT433',
          displayName: '33',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT434',
          displayName: '34',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT435',
          displayName: '35',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT436',
          displayName: '36',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT437',
          displayName: '37',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT438',
          displayName: '38',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT439',
          displayName: '39',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT440',
          displayName: '40',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT441',
          displayName: '41',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT442',
          displayName: '42',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT443',
          displayName: '43',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT444',
          displayName: '44',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT445',
          displayName: '45',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT446',
          displayName: '46',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT447',
          displayName: '47',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT448',
          displayName: '48',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT449',
          displayName: '49',
          drawImgClassName: 'circle circleGreen'
        }
      ]
    }
  },
  // {
  //   componentType: CollapseType,
  //   header: '正一特',
  //   headerStyle: '',
  //   data: {
  //     itemType: OptionItem,
  //     itemWrapperStyle: 'Hk6ItemWrapper',
  //     list: [
  //       {
  //         code: 'ZM4LM1',
  //         displayName: '正4-单',
  //       }, {
  //         code: 'ZM4LM3',
  //         displayName: '正4-大',
  //       }, {
  //         code: 'ZM4LM5',
  //         displayName: '正4-合单',
  //       }, {
  //         code: 'ZM4LM7',
  //         displayName: '正4-合大',
  //       }, {
  //         code: 'ZM4LM9',
  //         displayName: '正4-尾大',
  //       }, {
  //         code: 'ZM4LM2',
  //         displayName: '正4-双',
  //       }, {
  //         code: 'ZM4LM4',
  //         displayName: '正4-小',
  //       }, {
  //         code: 'ZM4LM6',
  //         displayName: '正4-合双',
  //       }, {
  //         code: 'ZM4LM8',
  //         displayName: '正4-合小',
  //       }, {
  //         code: 'ZM4LM10',
  //         displayName: '正4-尾小',
  //       }, {
  //         code: 'ZM4SB11',
  //         displayName: '正4-红波',
  //         drawImgClassName: 'fillRed',
  //       }, {
  //         code: 'ZM4SB12',
  //         displayName: '正4-蓝波',
  //         drawImgClassName: 'fillBlue',
  //       }, {
  //         code: 'ZM4SB13',
  //         displayName: '正4-绿波',
  //         drawImgClassName: 'fillGreen',
  //       }
  //     ]
  //   }
  // },
  {
    componentType: CollapseType,
    header: '正五特',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper threeColumnStyle',
      list: [
        {
          code: 'ZMT51',
          displayName: '01',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT52',
          displayName: '02',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT53',
          displayName: '03',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT54',
          displayName: '04',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT55',
          displayName: '05',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT56',
          displayName: '06',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT57',
          displayName: '07',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT58',
          displayName: '08',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT59',
          displayName: '09',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT510',
          displayName: '10',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT511',
          displayName: '11',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT512',
          displayName: '12',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT513',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT514',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT515',
          displayName: '15',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT516',
          displayName: '16',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT517',
          displayName: '17',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT518',
          displayName: '18',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT519',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT520',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT521',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT522',
          displayName: '22',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT523',
          displayName: '23',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT524',
          displayName: '24',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT525',
          displayName: '25',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT526',
          displayName: '26',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT527',
          displayName: '27',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT528',
          displayName: '28',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT529',
          displayName: '29',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT530',
          displayName: '30',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT531',
          displayName: '31',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT532',
          displayName: '32',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT533',
          displayName: '33',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT534',
          displayName: '34',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT535',
          displayName: '35',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT536',
          displayName: '36',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT537',
          displayName: '37',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT538',
          displayName: '38',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT539',
          displayName: '39',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT540',
          displayName: '40',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT541',
          displayName: '41',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT542',
          displayName: '42',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT543',
          displayName: '43',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT544',
          displayName: '44',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT545',
          displayName: '45',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT546',
          displayName: '46',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT547',
          displayName: '47',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT548',
          displayName: '48',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT549',
          displayName: '49',
          drawImgClassName: 'circle circleGreen'
        }
      ]
    }
  },
  //   {
  //     componentType: Template2,
  //     data: {
  //       cycleHeaderTitle: true,
  //       tableHeadertitle: ['号码', '赔率', '金额'],
  //       mainColumnLength: 5,
  //       subColumnLength: 3,
  //       subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
  //       betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
  //       betItemList: ['DisplayText', 'Odds', 'Input'],
  //       betItemColumn: 3,
  //       list: [
  //         {
  //           code: 'ZM5LM1',
  //           displayName: '正5-单',
  //         }, {
  //           code: 'ZM5LM3',
  //           displayName: '正5-大',
  //         }, {
  //           code: 'ZM5LM5',
  //           displayName: '正5-合单',
  //         }, {
  //           code: 'ZM5LM7',
  //           displayName: '正5-合大',
  //         }, {
  //           code: 'ZM5LM9',
  //           displayName: '正5-尾大',
  //         }, {
  //           code: 'ZM5LM2',
  //           displayName: '正5-双',
  //         }, {
  //           code: 'ZM5LM4',
  //           displayName: '正5-小',
  //         }, {
  //           code: 'ZM5LM6',
  //           displayName: '正5-合双',
  //         }, {
  //           code: 'ZM5LM8',
  //           displayName: '正5-合小',
  //         }, {
  //           code: 'ZM5LM10',
  //           displayName: '正5-尾小',
  //         }, {
  //           code: 'ZM5SB11',
  //           displayName: '正5-红波',
  //           drawImgClassName: 'fillRed',
  //         }, {
  //           code: 'ZM5SB12',
  //           displayName: '正5-蓝波',
  //           drawImgClassName: 'fillBlue',
  //         }, {
  //           code: 'ZM5SB13',
  //           displayName: '正5-绿波',
  //           drawImgClassName: 'fillGreen',
  //         }
  //       ]
  //     }
  // },
  {
    componentType: CollapseType,
    header: '正六特',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper threeColumnStyle',
      list: [
        {
          code: 'ZMT61',
          displayName: '01',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT62',
          displayName: '02',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT63',
          displayName: '03',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT64',
          displayName: '04',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT65',
          displayName: '05',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT66',
          displayName: '06',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT67',
          displayName: '07',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT68',
          displayName: '08',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT69',
          displayName: '09',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT610',
          displayName: '10',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT611',
          displayName: '11',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT612',
          displayName: '12',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT613',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT614',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT615',
          displayName: '15',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT616',
          displayName: '16',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT617',
          displayName: '17',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT618',
          displayName: '18',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT619',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT620',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT621',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT622',
          displayName: '22',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT623',
          displayName: '23',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT624',
          displayName: '24',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT625',
          displayName: '25',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT626',
          displayName: '26',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT627',
          displayName: '27',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT628',
          displayName: '28',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT629',
          displayName: '29',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT630',
          displayName: '30',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT631',
          displayName: '31',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT632',
          displayName: '32',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT633',
          displayName: '33',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT634',
          displayName: '34',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT635',
          displayName: '35',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT636',
          displayName: '36',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT637',
          displayName: '37',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT638',
          displayName: '38',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT639',
          displayName: '39',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT640',
          displayName: '40',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT641',
          displayName: '41',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT642',
          displayName: '42',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT643',
          displayName: '43',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT644',
          displayName: '44',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZMT645',
          displayName: '45',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT646',
          displayName: '46',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZMT647',
          displayName: '47',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT648',
          displayName: '48',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZMT649',
          displayName: '49',
          drawImgClassName: 'circle circleGreen'
        }
      ]
    }
  },
  // {
  //   componentType: Template2,
  //   data: {
  //     cycleHeaderTitle: true,
  //     tableHeadertitle: ['号码', '赔率', '金额'],
  //     mainColumnLength: 5,
  //     subColumnLength: 3,
  //     subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
  //     betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
  //     betItemList: ['DisplayText', 'Odds', 'Input'],
  //     betItemColumn: 3,
  //     list: [
  //       {
  //         code: 'ZM6LM1',
  //         displayName: '正6-单',
  //       }, {
  //         code: 'ZM6LM3',
  //         displayName: '正6-大',
  //       }, {
  //         code: 'ZM6LM5',
  //         displayName: '正6-合单',
  //       }, {
  //         code: 'ZM6LM7',
  //         displayName: '正6-合大',
  //       }, {
  //         code: 'ZM6LM9',
  //         displayName: '正6-尾大',
  //       }, {
  //         code: 'ZM6LM2',
  //         displayName: '正6-双',
  //       }, {
  //         code: 'ZM6LM4',
  //         displayName: '正6-小',
  //       }, {
  //         code: 'ZM6LM6',
  //         displayName: '正6-合双',
  //       }, {
  //         code: 'ZM6LM8',
  //         displayName: '正6-合小',
  //       }, {
  //         code: 'ZM6LM10',
  //         displayName: '正6-尾小',
  //       }, {
  //         code: 'ZM6SB11',
  //         displayName: '正6-红波',
  //         drawImgClassName: 'fillRed',
  //       }, {
  //         code: 'ZM6SB12',
  //         displayName: '正6-蓝波',
  //         drawImgClassName: 'fillBlue',
  //       }, {
  //         code: 'ZM6SB13',
  //         displayName: '正6-绿波',
  //         drawImgClassName: 'fillGreen',
  //       }
  //     ]
  //   }
  // }
];

export default ZMT;