import { ProbabilitySelectionPanel } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const ZH = [
  {
    componentType: ProbabilitySelectionPanel,
  },
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '总和、龙虎和',
      mainColumnLength: 4,
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'ZH21', // keyCode
          displayName: '总和大',
        }, {
          code: 'ZH23',
          displayName: '总和单',
        }, {
          code: 'LHH25',
          displayName: '龙',
        }, {
          code: 'LHH27',
          displayName: '和',
        }, {
          code: 'ZH22',
          displayName: '总和小',
        }, {
          code: 'ZH24',
          displayName: '总和双',
        }, {
          code: 'LHH26',
          displayName: '虎',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: false,
      tableHeadertitle: ['第一球', '第二球', '第三球', '第四球', '第五球'],
      mainColumnLength: 5,
      subColumnLength: 1,
      subColumnStyle: ['column-1item-style'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'B1LM1',
          displayName: '大',
        }, {
          code: 'B2LM5',
          displayName: '大',
        }, {
          code: 'B3LM9',
          displayName: '大',
        }, {
          code: 'B4LM13',
          displayName: '大',
        }, {
          code: 'B5LM17',
          displayName: '大',
        },
        {
          code: 'B1LM2',
          displayName: '小',
        }, {
          code: 'B2LM6',
          displayName: '小',
        }, {
          code: 'B3LM10',
          displayName: '小',
        }, {
          code: 'B4LM14',
          displayName: '小',
        }, {
          code: 'B5LM18',
          displayName: '小',
        },
        {
          code: 'B1LM3',
          displayName: '单',
        }, {
          code: 'B2LM7',
          displayName: '单',
        }, {
          code: 'B3LM11',
          displayName: '单',
        }, {
          code: 'B4LM15',
          displayName: '单',
        }, {
          code: 'B5LM19',
          displayName: '单',
        },
        {
          code: 'B1LM4',
          displayName: '双',
        }, {
          code: 'B2LM8',
          displayName: '双',
        }, {
          code: 'B3LM12',
          displayName: '双',
        }, {
          code: 'B4LM16',
          displayName: '双',
        }, {
          code: 'B5LM20',
          displayName: '双',
        },
        {
          code: 'B1QH1',
          displayName: '0',
          className: 'circle',
        }, {
          code: 'B2QH1',
          displayName: '0',
          className: 'circle',
        }, {
          code: 'B3QH1',
          displayName: '0',
          className: 'circle',
        }, {
          code: 'B4QH1',
          displayName: '0',
          className: 'circle',
        }, {
          code: 'B5QH1',
          displayName: '0',
          className: 'circle',
        },
        {
          code: 'B1QH2',
          displayName: '1',
          className: 'circle',
        }, {
          code: 'B2QH2',
          displayName: '1',
          className: 'circle',
        }, {
          code: 'B3QH2',
          displayName: '1',
          className: 'circle',
        }, {
          code: 'B4QH2',
          displayName: '1',
          className: 'circle',
        }, {
          code: 'B5QH2',
          displayName: '1',
          className: 'circle',
        },
        {
          code: 'B1QH3',
          displayName: '2',
          className: 'circle',
        }, {
          code: 'B2QH3',
          displayName: '2',
          className: 'circle',
        }, {
          code: 'B3QH3',
          displayName: '2',
          className: 'circle',
        }, {
          code: 'B4QH3',
          displayName: '2',
          className: 'circle',
        }, {
          code: 'B5QH3',
          displayName: '2',
          className: 'circle',
        },
        {
          code: 'B1QH4',
          displayName: '3',
          className: 'circle',
        }, {
          code: 'B2QH4',
          displayName: '3',
          className: 'circle',
        }, {
          code: 'B3QH4',
          displayName: '3',
          className: 'circle',
        }, {
          code: 'B4QH4',
          displayName: '3',
          className: 'circle',
        }, {
          code: 'B5QH4',
          displayName: '3',
          className: 'circle',
        },
        {
          code: 'B1QH5',
          displayName: '4',
          className: 'circle',
        }, {
          code: 'B2QH5',
          displayName: '4',
          className: 'circle',
        }, {
          code: 'B3QH5',
          displayName: '4',
          className: 'circle',
        }, {
          code: 'B4QH5',
          displayName: '4',
          className: 'circle',
        }, {
          code: 'B5QH5',
          displayName: '4',
          className: 'circle',
        },
        {
          code: 'B1QH6',
          displayName: '5',
          className: 'circle',
        }, {
          code: 'B2QH6',
          displayName: '5',
          className: 'circle',
        }, {
          code: 'B3QH6',
          displayName: '5',
          className: 'circle',
        }, {
          code: 'B4QH6',
          displayName: '5',
          className: 'circle',
        }, {
          code: 'B5QH6',
          displayName: '5',
          className: 'circle',
        },
        {
          code: 'B1QH7',
          displayName: '6',
          className: 'circle',
        }, {
          code: 'B2QH7',
          displayName: '6',
          className: 'circle',
        }, {
          code: 'B3QH7',
          displayName: '6',
          className: 'circle',
        }, {
          code: 'B4QH7',
          displayName: '6',
          className: 'circle',
        }, {
          code: 'B5QH7',
          displayName: '6',
          className: 'circle',
        },
        {
          code: 'B1QH8',
          displayName: '7',
          className: 'circle',
        }, {
          code: 'B2QH8',
          displayName: '7',
          className: 'circle',
        }, {
          code: 'B3QH8',
          displayName: '7',
          className: 'circle',
        }, {
          code: 'B4QH8',
          displayName: '7',
          className: 'circle',
        }, {
          code: 'B5QH8',
          displayName: '7',
          className: 'circle',
        },
        {
          code: 'B1QH9',
          displayName: '8',
          className: 'circle',
        }, {
          code: 'B2QH9',
          displayName: '8',
          className: 'circle',
        }, {
          code: 'B3QH9',
          displayName: '8',
          className: 'circle',
        }, {
          code: 'B4QH9',
          displayName: '8',
          className: 'circle',
        }, {
          code: 'B5QH9',
          displayName: '8',
          className: 'circle',
        },
        {
          code: 'B1QH10',
          displayName: '9',
          className: 'circle',
        }, {
          code: 'B2QH10',
          displayName: '9',
          className: 'circle',
        }, {
          code: 'B3QH10',
          displayName: '9',
          className: 'circle',
        }, {
          code: 'B4QH10',
          displayName: '9',
          className: 'circle',
        }, {
          code: 'B5QH10',
          displayName: '9',
          className: 'circle',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '前三',
      mainColumnLength: 5,
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'Q31', // keyCode
          displayName: '豹子',
        }, {
          code: 'Q32',
          displayName: '顺子',
        }, {
          code: 'Q33',
          displayName: '对子',
        }, {
          code: 'Q34',
          displayName: '半顺',
        }, {
          code: 'Q35',
          displayName: '杂六',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '中三',
      mainColumnLength: 5,
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'Z31', // keyCode
          displayName: '豹子',
        }, {
          code: 'Z32',
          displayName: '顺子',
        }, {
          code: 'Z33',
          displayName: '对子',
        }, {
          code: 'Z34',
          displayName: '半顺',
        }, {
          code: 'Z35',
          displayName: '杂六',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '后三',
      mainColumnLength: 5,
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'H31', // keyCode
          displayName: '豹子',
        }, {
          code: 'H32',
          displayName: '顺子',
        }, {
          code: 'H33',
          displayName: '对子',
        }, {
          code: 'H34',
          displayName: '半顺',
        }, {
          code: 'H35',
          displayName: '杂六',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  }
];

export default ZH;