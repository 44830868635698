import DH from './DH';
import GYJZH from './GYJZH';
import LM from './LM';
import { renderResultDateCell } from '../../utils';

const balls = {
  1: {
    value: 1,
    className: 'square square_1'
  },
  2: {
    value: 2,
    className: 'square square_2'
  },
  3: {
    value: 3,
    className: 'square square_3'
  },
  4: {
    value: 4,
    className: 'square square_4'
  },
  5: {
    value: 5,
    className: 'square square_5'
  },
  6: {
    value: 6,
    className: 'square square_6'
  },
  7: {
    value: 7,
    className: 'square square_7'
  },
  8: {
    value: 8,
    className: 'square square_8'
  },
  9: {
    value: 9,
    className: 'square square_9'
  },
  10: {
    value: 10,
    className: 'square square_10'
  }
};

const renderQiu = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  return result.map(item => (
    {
      value: item, className: balls[item] ? balls[item].className : ''
    }
  ));
};

const renderGYH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  const championSum = parseInt(result[0], 10) + parseInt(result[1], 10);

  tempResult.push({
    value: championSum
  });

  tempResult.push({
    value: championSum > 11 ? '大' : '小',
    className: championSum > 11 ? 'redText' : '',
  });

  tempResult.push({
    value: championSum % 2 ? '单' : '双',
    className: championSum % 2 ? 'redText' : '',
  });
  return tempResult;
};

const renderLH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  for (let i = 0; i < result.length / 2; i++) {
    if ((parseInt(result[i], 10)) > parseInt(result[result.length - 1 - i], 10)) {
      tempResult.push({
        value: '龙',
        className: 'redText'
      });
    } else {
      tempResult.push({
        value: '虎',
      });
    }
  }
  return tempResult;
};

const PK10 = {
  children: [
    {
      tabId: 'LIANGMIAN',
      label: '两面',
      children: LM.LM,
      settingCode: 'LM,GYJHDX,GYJHDS',
    },
    {
      tabId: 'CH',
      label: '1~10号车',
      children: DH.DH,
      settingCode: 'CH'
    },
    {
      tabId: 'GYZH',
      label: '冠亚军组合',
      children: GYJZH.GYJZH,
      settingCode: 'GYJH,GYJHDX,GYJHDS'
    }
  ],
  resultTab: {
    headerCell: [{
      attr: '期数',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '日期',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '彩球号码',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '冠亚和',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '1-5球龙虎',
      wrapperStyle: 'pk10Header'
    }],
    bodyCell: [{
      attr: 'installments',
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderResultDateCell,
      wrapperStyle: 'dateCol'
    }, {
      attr: renderQiu,
      wrapperStyle: 'd-flex justify-content-center align-items-center pk10Num'
    }, {
      attr: renderGYH,
      wrapperStyle: 'd-flex justify-content-center align-items-center pk10GYH'
    }, {
      attr: renderLH,
      wrapperStyle: 'd-flex justify-content-center align-items-center pk10LH'
    }],
  },
  generateLengReObj: pastResult => {
    let resultItemCount = {};
    pastResult.forEach(item => {
      const openResultArr = item.openResult && item.openResult.split(',').map(x => parseInt(x, 10));
      if (openResultArr) {
        resultItemCount = {
          ...resultItemCount,
          GYJH18: (openResultArr[0] + openResultArr[1]) > 10 ? (resultItemCount.GYJH18 ? resultItemCount.GYJH18 + 1 : 1) : resultItemCount.GYJH18 || 0,
          GYJH19: (openResultArr[0] + openResultArr[1]) <= 10 ? (resultItemCount.GYJH19 ? resultItemCount.GYJH19 + 1 : 1) : resultItemCount.GYJH19 || 0,
          GYJH20: (openResultArr[0] + openResultArr[1]) % 2 === 1 ? (resultItemCount.GYJH20 ? resultItemCount.GYJH20 + 1 : 1) : resultItemCount.GYJH20 || 0,
          GYJH21: (openResultArr[0] + openResultArr[1]) % 2 !== 1 ? (resultItemCount.GYJH21 ? resultItemCount.GYJH21 + 1 : 1) : resultItemCount.GYJH21 || 0,
          GJLM1: openResultArr[0] > 5 ? (resultItemCount.GJLM1 ? resultItemCount.GJLM1 + 1 : 1) : resultItemCount.GJLM1 || 0,
          YJLM1: openResultArr[1] > 5 ? (resultItemCount.YJLM1 ? resultItemCount.YJLM1 + 1 : 1) : resultItemCount.YJLM1 || 0,
          B3LM1: openResultArr[2] > 5 ? (resultItemCount.B3LM1 ? resultItemCount.B3LM1 + 1 : 1) : resultItemCount.B3LM1 || 0,
          B4LM1: openResultArr[3] > 5 ? (resultItemCount.B4LM1 ? resultItemCount.B4LM1 + 1 : 1) : resultItemCount.B4LM1 || 0,
          B5LM1: openResultArr[4] > 5 ? (resultItemCount.B5LM1 ? resultItemCount.B5LM1 + 1 : 1) : resultItemCount.B5LM1 || 0,
          B6LM1: openResultArr[5] > 5 ? (resultItemCount.B6LM1 ? resultItemCount.B6LM1 + 1 : 1) : resultItemCount.B6LM1 || 0,
          B7LM1: openResultArr[6] > 5 ? (resultItemCount.B7LM1 ? resultItemCount.B7LM1 + 1 : 1) : resultItemCount.B7LM1 || 0,
          B8LM1: openResultArr[7] > 5 ? (resultItemCount.B8LM1 ? resultItemCount.B8LM1 + 1 : 1) : resultItemCount.B8LM1 || 0,
          B9LM1: openResultArr[8] > 5 ? (resultItemCount.B9LM1 ? resultItemCount.B9LM1 + 1 : 1) : resultItemCount.B9LM1 || 0,
          B10LM1: openResultArr[9] > 5 ? (resultItemCount.B10LM1 ? resultItemCount.B10LM1 + 1 : 1) : resultItemCount.B10LM1 || 0,
          GJLM2: openResultArr[0] <= 5 ? (resultItemCount.GJLM2 ? resultItemCount.GJLM2 + 1 : 1) : resultItemCount.GJLM2 || 0,
          YJLM2: openResultArr[1] <= 5 ? (resultItemCount.YJLM2 ? resultItemCount.YJLM2 + 1 : 1) : resultItemCount.YJLM2 || 0,
          B3LM2: openResultArr[2] <= 5 ? (resultItemCount.B3LM2 ? resultItemCount.B3LM2 + 1 : 1) : resultItemCount.B3LM2 || 0,
          B4LM2: openResultArr[3] <= 5 ? (resultItemCount.B4LM2 ? resultItemCount.B4LM2 + 1 : 1) : resultItemCount.B4LM2 || 0,
          B5LM2: openResultArr[4] <= 5 ? (resultItemCount.B5LM2 ? resultItemCount.B5LM2 + 1 : 1) : resultItemCount.B5LM2 || 0,
          B6LM2: openResultArr[5] <= 5 ? (resultItemCount.B6LM2 ? resultItemCount.B6LM2 + 1 : 1) : resultItemCount.B6LM2 || 0,
          B7LM2: openResultArr[6] <= 5 ? (resultItemCount.B7LM2 ? resultItemCount.B7LM2 + 1 : 1) : resultItemCount.B7LM2 || 0,
          B8LM2: openResultArr[7] <= 5 ? (resultItemCount.B8LM2 ? resultItemCount.B8LM2 + 1 : 1) : resultItemCount.B8LM2 || 0,
          B9LM2: openResultArr[8] <= 5 ? (resultItemCount.B9LM2 ? resultItemCount.B9LM2 + 1 : 1) : resultItemCount.B9LM2 || 0,
          B10LM2: openResultArr[9] <= 5 ? (resultItemCount.B10LM2 ? resultItemCount.B10LM2 + 1 : 1) : resultItemCount.B10LM2 || 0,
          GJLM3: openResultArr[0] % 2 === 1 ? (resultItemCount.GJLM3 ? resultItemCount.GJLM3 + 1 : 1) : resultItemCount.GJLM3 || 0,
          YJLM3: openResultArr[1] % 2 === 1 ? (resultItemCount.YJLM3 ? resultItemCount.YJLM3 + 1 : 1) : resultItemCount.YJLM3 || 0,
          B3LM3: openResultArr[2] % 2 === 1 ? (resultItemCount.B3LM3 ? resultItemCount.B3LM3 + 1 : 1) : resultItemCount.B3LM3 || 0,
          B4LM3: openResultArr[3] % 2 === 1 ? (resultItemCount.B4LM3 ? resultItemCount.B4LM3 + 1 : 1) : resultItemCount.B4LM3 || 0,
          B5LM3: openResultArr[4] % 2 === 1 ? (resultItemCount.B5LM3 ? resultItemCount.B5LM3 + 1 : 1) : resultItemCount.B5LM3 || 0,
          B6LM3: openResultArr[5] % 2 === 1 ? (resultItemCount.B6LM3 ? resultItemCount.B6LM3 + 1 : 1) : resultItemCount.B6LM3 || 0,
          B7LM3: openResultArr[6] % 2 === 1 ? (resultItemCount.B7LM3 ? resultItemCount.B7LM3 + 1 : 1) : resultItemCount.B7LM3 || 0,
          B8LM3: openResultArr[7] % 2 === 1 ? (resultItemCount.B8LM3 ? resultItemCount.B8LM3 + 1 : 1) : resultItemCount.B8LM3 || 0,
          B9LM3: openResultArr[8] % 2 === 1 ? (resultItemCount.B9LM3 ? resultItemCount.B9LM3 + 1 : 1) : resultItemCount.B9LM3 || 0,
          B10LM3: openResultArr[9] % 2 === 1 ? (resultItemCount.B10LM3 ? resultItemCount.B10LM3 + 1 : 1) : resultItemCount.B10LM3 || 0,
          GJLM4: openResultArr[0] % 2 !== 1 ? (resultItemCount.GJLM4 ? resultItemCount.GJLM4 + 1 : 1) : resultItemCount.GJLM4 || 0,
          YJLM4: openResultArr[1] % 2 !== 1 ? (resultItemCount.YJLM4 ? resultItemCount.YJLM4 + 1 : 1) : resultItemCount.YJLM4 || 0,
          B3LM4: openResultArr[2] % 2 !== 1 ? (resultItemCount.B3LM4 ? resultItemCount.B3LM4 + 1 : 1) : resultItemCount.B3LM4 || 0,
          B4LM4: openResultArr[3] % 2 !== 1 ? (resultItemCount.B4LM4 ? resultItemCount.B4LM4 + 1 : 1) : resultItemCount.B4LM4 || 0,
          B5LM4: openResultArr[4] % 2 !== 1 ? (resultItemCount.B5LM4 ? resultItemCount.B5LM4 + 1 : 1) : resultItemCount.B5LM4 || 0,
          B6LM4: openResultArr[5] % 2 !== 1 ? (resultItemCount.B6LM4 ? resultItemCount.B6LM4 + 1 : 1) : resultItemCount.B6LM4 || 0,
          B7LM4: openResultArr[6] % 2 !== 1 ? (resultItemCount.B7LM4 ? resultItemCount.B7LM4 + 1 : 1) : resultItemCount.B7LM4 || 0,
          B8LM4: openResultArr[7] % 2 !== 1 ? (resultItemCount.B8LM4 ? resultItemCount.B8LM4 + 1 : 1) : resultItemCount.B8LM4 || 0,
          B9LM4: openResultArr[8] % 2 !== 1 ? (resultItemCount.B9LM4 ? resultItemCount.B9LM4 + 1 : 1) : resultItemCount.B9LM4 || 0,
          B10LM4: openResultArr[9] % 2 !== 1 ? (resultItemCount.B10LM4 ? resultItemCount.B10LM4 + 1 : 1) : resultItemCount.B10LM4 || 0,
          GJLM5: openResultArr[0] > openResultArr[9] ? (resultItemCount.GJLM5 ? resultItemCount.GJLM5 + 1 : 1) : resultItemCount.GJLM5 || 0,
          YJLM5: openResultArr[1] > openResultArr[8] ? (resultItemCount.YJLM5 ? resultItemCount.YJLM5 + 1 : 1) : resultItemCount.YJLM5 || 0,
          B3LM5: openResultArr[2] > openResultArr[7] ? (resultItemCount.B3LM5 ? resultItemCount.B3LM5 + 1 : 1) : resultItemCount.B3LM5 || 0,
          B4LM5: openResultArr[3] > openResultArr[6] ? (resultItemCount.B4LM5 ? resultItemCount.B4LM5 + 1 : 1) : resultItemCount.B4LM5 || 0,
          B5LM5: openResultArr[4] > openResultArr[5] ? (resultItemCount.B5LM5 ? resultItemCount.B5LM5 + 1 : 1) : resultItemCount.B5LM5 || 0,
          B6LM5: openResultArr[5] > openResultArr[4] ? (resultItemCount.B6LM5 ? resultItemCount.B6LM5 + 1 : 1) : resultItemCount.B6LM5 || 0,
          B7LM5: openResultArr[6] > openResultArr[3] ? (resultItemCount.B7LM5 ? resultItemCount.B7LM5 + 1 : 1) : resultItemCount.B7LM5 || 0,
          B8LM5: openResultArr[7] > openResultArr[2] ? (resultItemCount.B8LM5 ? resultItemCount.B8LM5 + 1 : 1) : resultItemCount.B8LM5 || 0,
          B9LM5: openResultArr[8] > openResultArr[1] ? (resultItemCount.B9LM5 ? resultItemCount.B9LM5 + 1 : 1) : resultItemCount.B9LM5 || 0,
          B10LM5: openResultArr[9] > openResultArr[0] ? (resultItemCount.B10LM5 ? resultItemCount.B10LM5 + 1 : 1) : resultItemCount.B10LM5 || 0,
          GJLM6: openResultArr[0] <= openResultArr[9] ? (resultItemCount.GJLM6 ? resultItemCount.GJLM6 + 1 : 1) : resultItemCount.GJLM6 || 0,
          YJLM6: openResultArr[1] <= openResultArr[8] ? (resultItemCount.YJLM6 ? resultItemCount.YJLM6 + 1 : 1) : resultItemCount.YJLM6 || 0,
          B3LM6: openResultArr[2] <= openResultArr[7] ? (resultItemCount.B3LM6 ? resultItemCount.B3LM6 + 1 : 1) : resultItemCount.B3LM6 || 0,
          B4LM6: openResultArr[3] <= openResultArr[6] ? (resultItemCount.B4LM6 ? resultItemCount.B4LM6 + 1 : 1) : resultItemCount.B4LM6 || 0,
          B5LM6: openResultArr[4] <= openResultArr[5] ? (resultItemCount.B5LM6 ? resultItemCount.B5LM6 + 1 : 1) : resultItemCount.B5LM6 || 0,
          B6LM6: openResultArr[5] <= openResultArr[4] ? (resultItemCount.B6LM6 ? resultItemCount.B6LM6 + 1 : 1) : resultItemCount.B6LM6 || 0,
          B7LM6: openResultArr[6] <= openResultArr[3] ? (resultItemCount.B7LM6 ? resultItemCount.B7LM6 + 1 : 1) : resultItemCount.B7LM6 || 0,
          B8LM6: openResultArr[7] <= openResultArr[2] ? (resultItemCount.B8LM6 ? resultItemCount.B8LM6 + 1 : 1) : resultItemCount.B8LM6 || 0,
          B9LM6: openResultArr[8] <= openResultArr[1] ? (resultItemCount.B9LM6 ? resultItemCount.B9LM6 + 1 : 1) : resultItemCount.B9LM6 || 0,
          B10LM6: openResultArr[9] <= openResultArr[0] ? (resultItemCount.B10LM6 ? resultItemCount.B10LM6 + 1 : 1) : resultItemCount.B10LM6 || 0,
          GJHM7: openResultArr[0] === 1 ? (resultItemCount.GJHM7 ? resultItemCount.GJHM7 + 1 : 1) : resultItemCount.GJHM7 || 0,
          GJHM8: openResultArr[0] === 2 ? (resultItemCount.GJHM8 ? resultItemCount.GJHM8 + 1 : 1) : resultItemCount.GJHM8 || 0,
          GJHM9: openResultArr[0] === 3 ? (resultItemCount.GJHM9 ? resultItemCount.GJHM9 + 1 : 1) : resultItemCount.GJHM9 || 0,
          GJHM10: openResultArr[0] === 4 ? (resultItemCount.GJHM10 ? resultItemCount.GJHM10 + 1 : 1) : resultItemCount.GJHM10 || 0,
          GJHM11: openResultArr[0] === 5 ? (resultItemCount.GJHM11 ? resultItemCount.GJHM11 + 1 : 1) : resultItemCount.GJHM11 || 0,
          GJHM12: openResultArr[0] === 6 ? (resultItemCount.GJHM12 ? resultItemCount.GJHM12 + 1 : 1) : resultItemCount.GJHM12 || 0,
          GJHM13: openResultArr[0] === 7 ? (resultItemCount.GJHM13 ? resultItemCount.GJHM13 + 1 : 1) : resultItemCount.GJHM13 || 0,
          GJHM14: openResultArr[0] === 8 ? (resultItemCount.GJHM14 ? resultItemCount.GJHM14 + 1 : 1) : resultItemCount.GJHM14 || 0,
          GJHM15: openResultArr[0] === 9 ? (resultItemCount.GJHM15 ? resultItemCount.GJHM15 + 1 : 1) : resultItemCount.GJHM15 || 0,
          GJHM16: openResultArr[0] === 10 ? (resultItemCount.GJHM16 ? resultItemCount.GJHM16 + 1 : 1) : resultItemCount.GJHM16 || 0,
          YJHM7: openResultArr[1] === 1 ? (resultItemCount.YJHM7 ? resultItemCount.YJHM7 + 1 : 1) : resultItemCount.YJHM7 || 0,
          YJHM8: openResultArr[1] === 2 ? (resultItemCount.YJHM8 ? resultItemCount.YJHM8 + 1 : 1) : resultItemCount.YJHM8 || 0,
          YJHM9: openResultArr[1] === 3 ? (resultItemCount.YJHM9 ? resultItemCount.YJHM9 + 1 : 1) : resultItemCount.YJHM9 || 0,
          YJHM10: openResultArr[1] === 4 ? (resultItemCount.YJHM10 ? resultItemCount.YJHM10 + 1 : 1) : resultItemCount.YJHM10 || 0,
          YJHM11: openResultArr[1] === 5 ? (resultItemCount.YJHM11 ? resultItemCount.YJHM11 + 1 : 1) : resultItemCount.YJHM11 || 0,
          YJHM12: openResultArr[1] === 6 ? (resultItemCount.YJHM12 ? resultItemCount.YJHM12 + 1 : 1) : resultItemCount.YJHM12 || 0,
          YJHM13: openResultArr[1] === 7 ? (resultItemCount.YJHM13 ? resultItemCount.YJHM13 + 1 : 1) : resultItemCount.YJHM13 || 0,
          YJHM14: openResultArr[1] === 8 ? (resultItemCount.YJHM14 ? resultItemCount.YJHM14 + 1 : 1) : resultItemCount.YJHM14 || 0,
          YJHM15: openResultArr[1] === 9 ? (resultItemCount.YJHM15 ? resultItemCount.YJHM15 + 1 : 1) : resultItemCount.YJHM15 || 0,
          YJHM16: openResultArr[1] === 10 ? (resultItemCount.YJHM16 ? resultItemCount.YJHM16 + 1 : 1) : resultItemCount.YJHM16 || 0,
          B3CH7: openResultArr[2] === 1 ? (resultItemCount.B3CH7 ? resultItemCount.B3CH7 + 1 : 1) : resultItemCount.B3CH7 || 0,
          B3CH8: openResultArr[2] === 2 ? (resultItemCount.B3CH8 ? resultItemCount.B3CH8 + 1 : 1) : resultItemCount.B3CH8 || 0,
          B3CH9: openResultArr[2] === 3 ? (resultItemCount.B3CH9 ? resultItemCount.B3CH9 + 1 : 1) : resultItemCount.B3CH9 || 0,
          B3CH10: openResultArr[2] === 4 ? (resultItemCount.B3CH10 ? resultItemCount.B3CH10 + 1 : 1) : resultItemCount.B3CH10 || 0,
          B3CH11: openResultArr[2] === 5 ? (resultItemCount.B3CH11 ? resultItemCount.B3CH11 + 1 : 1) : resultItemCount.B3CH11 || 0,
          B3CH12: openResultArr[2] === 6 ? (resultItemCount.B3CH12 ? resultItemCount.B3CH12 + 1 : 1) : resultItemCount.B3CH12 || 0,
          B3CH13: openResultArr[2] === 7 ? (resultItemCount.B3CH13 ? resultItemCount.B3CH13 + 1 : 1) : resultItemCount.B3CH13 || 0,
          B3CH14: openResultArr[2] === 8 ? (resultItemCount.B3CH14 ? resultItemCount.B3CH14 + 1 : 1) : resultItemCount.B3CH14 || 0,
          B3CH15: openResultArr[2] === 9 ? (resultItemCount.B3CH15 ? resultItemCount.B3CH15 + 1 : 1) : resultItemCount.B3CH15 || 0,
          B3CH16: openResultArr[2] === 10 ? (resultItemCount.B3CH16 ? resultItemCount.B3CH16 + 1 : 1) : resultItemCount.B3CH16 || 0,
          B4CH7: openResultArr[3] === 1 ? (resultItemCount.B4CH7 ? resultItemCount.B4CH7 + 1 : 1) : resultItemCount.B4CH7 || 0,
          B4CH8: openResultArr[3] === 2 ? (resultItemCount.B4CH8 ? resultItemCount.B4CH8 + 1 : 1) : resultItemCount.B4CH8 || 0,
          B4CH9: openResultArr[3] === 3 ? (resultItemCount.B4CH9 ? resultItemCount.B4CH9 + 1 : 1) : resultItemCount.B4CH9 || 0,
          B4CH10: openResultArr[3] === 4 ? (resultItemCount.B4CH10 ? resultItemCount.B4CH10 + 1 : 1) : resultItemCount.B4CH10 || 0,
          B4CH11: openResultArr[3] === 5 ? (resultItemCount.B4CH11 ? resultItemCount.B4CH11 + 1 : 1) : resultItemCount.B4CH11 || 0,
          B4CH12: openResultArr[3] === 6 ? (resultItemCount.B4CH12 ? resultItemCount.B4CH12 + 1 : 1) : resultItemCount.B4CH12 || 0,
          B4CH13: openResultArr[3] === 7 ? (resultItemCount.B4CH13 ? resultItemCount.B4CH13 + 1 : 1) : resultItemCount.B4CH13 || 0,
          B4CH14: openResultArr[3] === 8 ? (resultItemCount.B4CH14 ? resultItemCount.B4CH14 + 1 : 1) : resultItemCount.B4CH14 || 0,
          B4CH15: openResultArr[3] === 9 ? (resultItemCount.B4CH15 ? resultItemCount.B4CH15 + 1 : 1) : resultItemCount.B4CH15 || 0,
          B4CH16: openResultArr[3] === 10 ? (resultItemCount.B4CH16 ? resultItemCount.B4CH16 + 1 : 1) : resultItemCount.B4CH16 || 0,
          B5CH7: openResultArr[4] === 1 ? (resultItemCount.B5CH7 ? resultItemCount.B5CH7 + 1 : 1) : resultItemCount.B5CH7 || 0,
          B5CH8: openResultArr[4] === 2 ? (resultItemCount.B5CH8 ? resultItemCount.B5CH8 + 1 : 1) : resultItemCount.B5CH8 || 0,
          B5CH9: openResultArr[4] === 3 ? (resultItemCount.B5CH9 ? resultItemCount.B5CH9 + 1 : 1) : resultItemCount.B5CH9 || 0,
          B5CH10: openResultArr[4] === 4 ? (resultItemCount.B5CH10 ? resultItemCount.B5CH10 + 1 : 1) : resultItemCount.B5CH10 || 0,
          B5CH11: openResultArr[4] === 5 ? (resultItemCount.B5CH11 ? resultItemCount.B5CH11 + 1 : 1) : resultItemCount.B5CH11 || 0,
          B5CH12: openResultArr[4] === 6 ? (resultItemCount.B5CH12 ? resultItemCount.B5CH12 + 1 : 1) : resultItemCount.B5CH12 || 0,
          B5CH13: openResultArr[4] === 7 ? (resultItemCount.B5CH13 ? resultItemCount.B5CH13 + 1 : 1) : resultItemCount.B5CH13 || 0,
          B5CH14: openResultArr[4] === 8 ? (resultItemCount.B5CH14 ? resultItemCount.B5CH14 + 1 : 1) : resultItemCount.B5CH14 || 0,
          B5CH15: openResultArr[4] === 9 ? (resultItemCount.B5CH15 ? resultItemCount.B5CH15 + 1 : 1) : resultItemCount.B5CH15 || 0,
          B5CH16: openResultArr[4] === 10 ? (resultItemCount.B5CH16 ? resultItemCount.B5CH16 + 1 : 1) : resultItemCount.B5CH16 || 0,
          B6CH7: openResultArr[5] === 1 ? (resultItemCount.B6CH7 ? resultItemCount.B6CH7 + 1 : 1) : resultItemCount.B6CH7 || 0,
          B6CH8: openResultArr[5] === 2 ? (resultItemCount.B6CH8 ? resultItemCount.B6CH8 + 1 : 1) : resultItemCount.B6CH8 || 0,
          B6CH9: openResultArr[5] === 3 ? (resultItemCount.B6CH9 ? resultItemCount.B6CH9 + 1 : 1) : resultItemCount.B6CH9 || 0,
          B6CH10: openResultArr[5] === 4 ? (resultItemCount.B6CH10 ? resultItemCount.B6CH10 + 1 : 1) : resultItemCount.B6CH10 || 0,
          B6CH11: openResultArr[5] === 5 ? (resultItemCount.B6CH11 ? resultItemCount.B6CH11 + 1 : 1) : resultItemCount.B6CH11 || 0,
          B6CH12: openResultArr[5] === 6 ? (resultItemCount.B6CH12 ? resultItemCount.B6CH12 + 1 : 1) : resultItemCount.B6CH12 || 0,
          B6CH13: openResultArr[5] === 7 ? (resultItemCount.B6CH13 ? resultItemCount.B6CH13 + 1 : 1) : resultItemCount.B6CH13 || 0,
          B6CH14: openResultArr[5] === 8 ? (resultItemCount.B6CH14 ? resultItemCount.B6CH14 + 1 : 1) : resultItemCount.B6CH14 || 0,
          B6CH15: openResultArr[5] === 9 ? (resultItemCount.B6CH15 ? resultItemCount.B6CH15 + 1 : 1) : resultItemCount.B6CH15 || 0,
          B6CH16: openResultArr[5] === 10 ? (resultItemCount.B6CH16 ? resultItemCount.B6CH16 + 1 : 1) : resultItemCount.B6CH16 || 0,
          B7CH7: openResultArr[6] === 1 ? (resultItemCount.B7CH7 ? resultItemCount.B7CH7 + 1 : 1) : resultItemCount.B7CH7 || 0,
          B7CH8: openResultArr[6] === 2 ? (resultItemCount.B7CH8 ? resultItemCount.B7CH8 + 1 : 1) : resultItemCount.B7CH8 || 0,
          B7CH9: openResultArr[6] === 3 ? (resultItemCount.B7CH9 ? resultItemCount.B7CH9 + 1 : 1) : resultItemCount.B7CH9 || 0,
          B7CH10: openResultArr[6] === 4 ? (resultItemCount.B7CH10 ? resultItemCount.B7CH10 + 1 : 1) : resultItemCount.B7CH10 || 0,
          B7CH11: openResultArr[6] === 5 ? (resultItemCount.B7CH11 ? resultItemCount.B7CH11 + 1 : 1) : resultItemCount.B7CH11 || 0,
          B7CH12: openResultArr[6] === 6 ? (resultItemCount.B7CH12 ? resultItemCount.B7CH12 + 1 : 1) : resultItemCount.B7CH12 || 0,
          B7CH13: openResultArr[6] === 7 ? (resultItemCount.B7CH13 ? resultItemCount.B7CH13 + 1 : 1) : resultItemCount.B7CH13 || 0,
          B7CH14: openResultArr[6] === 8 ? (resultItemCount.B7CH14 ? resultItemCount.B7CH14 + 1 : 1) : resultItemCount.B7CH14 || 0,
          B7CH15: openResultArr[6] === 9 ? (resultItemCount.B7CH15 ? resultItemCount.B7CH15 + 1 : 1) : resultItemCount.B7CH15 || 0,
          B7CH16: openResultArr[6] === 10 ? (resultItemCount.B7CH16 ? resultItemCount.B7CH16 + 1 : 1) : resultItemCount.B7CH16 || 0,
          B8CH7: openResultArr[7] === 1 ? (resultItemCount.B8CH7 ? resultItemCount.B8CH7 + 1 : 1) : resultItemCount.B8CH7 || 0,
          B8CH8: openResultArr[7] === 2 ? (resultItemCount.B8CH8 ? resultItemCount.B8CH8 + 1 : 1) : resultItemCount.B8CH8 || 0,
          B8CH9: openResultArr[7] === 3 ? (resultItemCount.B8CH9 ? resultItemCount.B8CH9 + 1 : 1) : resultItemCount.B8CH9 || 0,
          B8CH10: openResultArr[7] === 4 ? (resultItemCount.B8CH10 ? resultItemCount.B8CH10 + 1 : 1) : resultItemCount.B8CH10 || 0,
          B8CH11: openResultArr[7] === 5 ? (resultItemCount.B8CH11 ? resultItemCount.B8CH11 + 1 : 1) : resultItemCount.B8CH11 || 0,
          B8CH12: openResultArr[7] === 6 ? (resultItemCount.B8CH12 ? resultItemCount.B8CH12 + 1 : 1) : resultItemCount.B8CH12 || 0,
          B8CH13: openResultArr[7] === 7 ? (resultItemCount.B8CH13 ? resultItemCount.B8CH13 + 1 : 1) : resultItemCount.B8CH13 || 0,
          B8CH14: openResultArr[7] === 8 ? (resultItemCount.B8CH14 ? resultItemCount.B8CH14 + 1 : 1) : resultItemCount.B8CH14 || 0,
          B8CH15: openResultArr[7] === 9 ? (resultItemCount.B8CH15 ? resultItemCount.B8CH15 + 1 : 1) : resultItemCount.B8CH15 || 0,
          B8CH16: openResultArr[7] === 10 ? (resultItemCount.B8CH16 ? resultItemCount.B8CH16 + 1 : 1) : resultItemCount.B8CH16 || 0,
          B9CH7: openResultArr[8] === 1 ? (resultItemCount.B9CH7 ? resultItemCount.B9CH7 + 1 : 1) : resultItemCount.B9CH7 || 0,
          B9CH8: openResultArr[8] === 2 ? (resultItemCount.B9CH8 ? resultItemCount.B9CH8 + 1 : 1) : resultItemCount.B9CH8 || 0,
          B9CH9: openResultArr[8] === 3 ? (resultItemCount.B9CH9 ? resultItemCount.B9CH9 + 1 : 1) : resultItemCount.B9CH9 || 0,
          B9CH10: openResultArr[8] === 4 ? (resultItemCount.B9CH10 ? resultItemCount.B9CH10 + 1 : 1) : resultItemCount.B9CH10 || 0,
          B9CH11: openResultArr[8] === 5 ? (resultItemCount.B9CH11 ? resultItemCount.B9CH11 + 1 : 1) : resultItemCount.B9CH11 || 0,
          B9CH12: openResultArr[8] === 6 ? (resultItemCount.B9CH12 ? resultItemCount.B9CH12 + 1 : 1) : resultItemCount.B9CH12 || 0,
          B9CH13: openResultArr[8] === 7 ? (resultItemCount.B9CH13 ? resultItemCount.B9CH13 + 1 : 1) : resultItemCount.B9CH13 || 0,
          B9CH14: openResultArr[8] === 8 ? (resultItemCount.B9CH14 ? resultItemCount.B9CH14 + 1 : 1) : resultItemCount.B9CH14 || 0,
          B9CH15: openResultArr[8] === 9 ? (resultItemCount.B9CH15 ? resultItemCount.B9CH15 + 1 : 1) : resultItemCount.B9CH15 || 0,
          B9CH16: openResultArr[8] === 10 ? (resultItemCount.B9CH16 ? resultItemCount.B9CH16 + 1 : 1) : resultItemCount.B9CH16 || 0,
          B10CH7: openResultArr[9] === 1 ? (resultItemCount.B10CH7 ? resultItemCount.B10CH7 + 1 : 1) : resultItemCount.B10CH7 || 0,
          B10CH8: openResultArr[9] === 2 ? (resultItemCount.B10CH8 ? resultItemCount.B10CH8 + 1 : 1) : resultItemCount.B10CH8 || 0,
          B10CH9: openResultArr[9] === 3 ? (resultItemCount.B10CH9 ? resultItemCount.B10CH9 + 1 : 1) : resultItemCount.B10CH9 || 0,
          B10CH10: openResultArr[9] === 4 ? (resultItemCount.B10CH10 ? resultItemCount.B10CH10 + 1 : 1) : resultItemCount.B10CH10 || 0,
          B10CH11: openResultArr[9] === 5 ? (resultItemCount.B10CH11 ? resultItemCount.B10CH11 + 1 : 1) : resultItemCount.B10CH11 || 0,
          B10CH12: openResultArr[9] === 6 ? (resultItemCount.B10CH12 ? resultItemCount.B10CH12 + 1 : 1) : resultItemCount.B10CH12 || 0,
          B10CH13: openResultArr[9] === 7 ? (resultItemCount.B10CH13 ? resultItemCount.B10CH13 + 1 : 1) : resultItemCount.B10CH13 || 0,
          B10CH14: openResultArr[9] === 8 ? (resultItemCount.B10CH14 ? resultItemCount.B10CH14 + 1 : 1) : resultItemCount.B10CH14 || 0,
          B10CH15: openResultArr[9] === 9 ? (resultItemCount.B10CH15 ? resultItemCount.B10CH15 + 1 : 1) : resultItemCount.B10CH15 || 0,
          B10CH16: openResultArr[9] === 10 ? (resultItemCount.B10CH16 ? resultItemCount.B10CH16 + 1 : 1) : resultItemCount.B10CH16 || 0,
        };
      }
    });
    return resultItemCount;
  },
  probabilityResult: {
    dropdownList: [{
      label: '冠军',
      value: 'GJ',
      resultPosition: 1,
    }, {
      label: '亚军',
      value: 'YJ',
      resultPosition: 2,
    }, {
      label: '第三名',
      value: 'D3M',
      resultPosition: 3,
    }, {
      label: '第四名',
      value: 'D4M',
      resultPosition: 4,
    }, {
      label: '第五名',
      value: 'D5M',
      resultPosition: 5,
    }, {
      label: '第六名',
      value: 'D6M',
      resultPosition: 6,
    }, {
      label: '第七名',
      value: 'D7M',
      resultPosition: 7,
    }, {
      label: '第八名',
      value: 'D8M',
      resultPosition: 8,
    }, {
      label: '第九名',
      value: 'D9M',
      resultPosition: 9,
    }, {
      label: '第十名',
      value: 'D10M',
      resultPosition: 10,
    }],
    sideBetNavList: [{
      label: '大小',
      value: 'DX'
    }, {
      label: '单双',
      value: 'DS'
    }, {
      label: '冠、亚军和',
      value: 'GYJH'
    }, {
      label: '冠、亚军和 大小',
      value: 'GYJHDX'
    }, {
      label: '冠、亚军和 单双',
      value: 'GYJHDS'
    }],
    result: {
      GJ: {
        render: (data, resultPosition) => {
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',')[resultPosition - 1];
            if (currentItem) {
              if (_result.length >= 25) return;
              if (_result.length > 0 && _result[_result.length - 1].includes(currentItem)) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, currentItem]);
              } else {
                _result.push(currentItem);
              }
            }
          });
          return _result;
        }
      },
      YJ: {
        render: (data, resultPosition) => {
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',')[resultPosition - 1];
            if (currentItem) {
              if (_result.length >= 25) return;
              if (_result.length > 0 && _result[_result.length - 1].includes(currentItem)) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, currentItem]);
              } else {
                _result.push(currentItem);
              }
            }
          });
          return _result;
        }
      },
      D3M: {
        render: (data, resultPosition) => {
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',')[resultPosition - 1];
            if (currentItem) {
              if (_result.length >= 25) return;
              if (_result.length > 0 && _result[_result.length - 1].includes(currentItem)) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, currentItem]);
              } else {
                _result.push(currentItem);
              }
            }
          });
          return _result;
        }
      },
      D4M: {
        render: (data, resultPosition) => {
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',')[resultPosition - 1];
            if (currentItem) {
              if (_result.length >= 25) return;
              if (_result.length > 0 && _result[_result.length - 1].includes(currentItem)) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, currentItem]);
              } else {
                _result.push(currentItem);
              }
            }
          });
          return _result;
        }
      },
      D5M: {
        render: (data, resultPosition) => {
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',')[resultPosition - 1];
            if (currentItem) {
              if (_result.length >= 25) return;
              if (_result.length > 0 && _result[_result.length - 1].includes(currentItem)) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, currentItem]);
              } else {
                _result.push(currentItem);
              }
            }
          });
          return _result;
        }
      },
      D6M: {
        render: (data, resultPosition) => {
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',')[resultPosition - 1];
            if (currentItem) {
              if (_result.length >= 25) return;
              if (_result.length > 0 && _result[_result.length - 1].includes(currentItem)) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, currentItem]);
              } else {
                _result.push(currentItem);
              }
            }
          });
          return _result;
        }
      },
      D7M: {
        render: (data, resultPosition) => {
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',')[resultPosition - 1];
            if (currentItem) {
              if (_result.length >= 25) return;
              if (_result.length > 0 && _result[_result.length - 1].includes(currentItem)) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, currentItem]);
              } else {
                _result.push(currentItem);
              }
            }
          });
          return _result;
        }
      },
      D8M: {
        render: (data, resultPosition) => {
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',')[resultPosition - 1];
            if (currentItem) {
              if (_result.length >= 25) return;
              if (_result.length > 0 && _result[_result.length - 1].includes(currentItem)) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, currentItem]);
              } else {
                _result.push(currentItem);
              }
            }
          });
          return _result;
        }
      },
      D9M: {
        render: (data, resultPosition) => {
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',')[resultPosition - 1];
            if (currentItem) {
              if (_result.length >= 25) return;
              if (_result.length > 0 && _result[_result.length - 1].includes(currentItem)) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, currentItem]);
              } else {
                _result.push(currentItem);
              }
            }
          });
          return _result;
        }
      },
      D10M: {
        render: (data, resultPosition) => {
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',')[resultPosition - 1];
            if (currentItem) {
              if (_result.length >= 25) return;
              if (_result.length > 0 && _result[_result.length - 1].includes(currentItem)) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, currentItem]);
              } else {
                _result.push(currentItem);
              }
            }
          });
          return _result;
        }
      },
      DX: {
        render: (data, resultPosition) => {
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',')[resultPosition - 1];
            if (currentItem) {
              if (_result.length >= 25) return;
              if (_result.length > 0 && _result[_result.length - 1].includes(currentItem <= 5 ? '小' : '大')) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, currentItem <= 5 ? '小' : '大']);
              } else {
                _result.push(currentItem <= 5 ? '小' : '大');
              }
            }
          });
          return _result;
        }
      },
      DS: {
        render: (data, resultPosition) => {
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',')[resultPosition - 1];
            if (currentItem) {
              if (_result.length >= 25) return;
              if (_result.length > 0 && _result[_result.length - 1].includes(currentItem % 2 ? '单' : '双')) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, currentItem % 2 ? '单' : '双']);
              } else {
                _result.push(currentItem % 2 ? '单' : '双');
              }
            }
          });
          return _result;
        }
      },
      GYJH: {
        render: data => {
          let championSum = 0;
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',');
            if (currentItem) {
              if (_result.length >= 25) return;
              championSum = (parseInt(currentItem[0], 10) + parseInt(currentItem[1], 10)).toString();
              if (_result.length > 0 && _result[_result.length - 1].includes(championSum)) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, championSum]);
              } else {
                _result.push(championSum);
              }
            }
          });
          return _result;
        }
      },
      GYJHDX: {
        render: data => {
          let championSum = 0;
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',');
            if (currentItem) {
              if (_result.length >= 25) return;
              championSum = parseInt(currentItem[0], 10) + parseInt(currentItem[1], 10);
              if (_result.length > 0 && _result[_result.length - 1].includes(championSum > 10 ? '大' : '小')) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, championSum > 10 ? '大' : '小']);
              } else {
                _result.push(championSum > 10 ? '大' : '小');
              }
            }
          });
          return _result;
        }
      },
      GYJHDS: {
        render: data => {
          let championSum = 0;
          const { list } = data || {};
          const _result = [];
          list.forEach(item => {
            const currentItem = item.openResult && item.openResult.split(',');
            if (currentItem) {
              if (_result.length >= 25) return;
              championSum = parseInt(currentItem[0], 10) + parseInt(currentItem[1], 10);
              if (_result.length > 0 && _result[_result.length - 1].includes(championSum % 2 ? '单' : '双')) {
                const tempPrev = _result[_result.length - 1];
                _result.splice(-1);
                _result.push([...tempPrev, championSum % 2 ? '单' : '双']);
              } else {
                _result.push(championSum % 2 ? '单' : '双');
              }
            }
          });
          return _result;
        }
      },
    },
  },
  results: {
    header: [{
      value: '开奖结果'
    }],
    TabList: [{
      code: 'NUMBER',
      renderFunc: rowItem => rowItem && rowItem.openResult && rowItem.openResult.split(',').map(item => ({
        value: item,
      }))
    },
    {
      code: 'BIG_SMALL',
      renderFunc: rowItem => rowItem && rowItem.openResult && rowItem.openResult.split(',').map(item => ({
        value: item <= 5 ? '小' : '大',
        className: item <= 5 ? 'smallValue' : '',
      }))
    },
    {
      code: 'ODD_EVEN',
      renderFunc: rowItem => rowItem && rowItem.openResult && rowItem.openResult.split(',').map(item => ({
        value: item % 2 ? '单' : '双',
        className: item % 2 ? '' : 'even'
      }))
    }]
  }
};

export default PK10;