import React, { useEffect, useState } from 'react';

import { Col, Row } from 'reactstrap';
import { BsFillCircleFill } from 'react-icons/bs';
import { FaQuestionCircle } from 'react-icons/fa';
import { Popover } from 'element-react';
import { connect } from 'react-redux';
import moment from 'moment';
import { animateScroll as scroll } from 'react-scroll';
import { useTranslation } from 'react-i18next';


import { appActions, cartActions, gameActions, resultHistoryActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  sectionSelection: state.gameReducers.sectionSelection,
  yiLouCount: state.appReducers.yiLouCount,
  lotteryType: state.gameReducers.lotteryType,
  showLengRe: state.appReducers.showLengRe,
  showYiLou: state.appReducers.showYiLou,
  quickBetBtn: state.appReducers.quickBetBtn,
  templateCode: state.gameReducers.template,
  selectedSubType: state.gameReducers.selectedSubType,
});

const mapDispatchToProps = dispatch => ({
  updateSectionSelection: selection => dispatch(gameActions.updateSectionSelection(selection)),
  resetItemInCart: () => dispatch(cartActions.resetItemInCart()),
  updateYiLouCount: value => dispatch(appActions.updateYiLouCount(value)),
  getResultList: (lotteryTime, lotteryType, page, row, showLatest, isLengReRequest) => dispatch(resultHistoryActions.getResult(lotteryTime, lotteryType, page, row, showLatest, isLengReRequest)),
  updateLengReButton: () => dispatch(appActions.updateLengReButton()),
  updateYiLouButton: () => dispatch(appActions.updateYiLouButton()),
  updateQuickBetToggle: val => dispatch(appActions.updateQuickBetToggle(val)),
});

const ProbabilitySelectionPanel = ({ midCodeList = [], updateLengReButton, updateYiLouButton, updateQuickBetToggle, quickBetBtn, templateCode, selectedSubType, showYiLou, showLengRe, showProbability = true, yiLouCount, lotteryType, updateYiLouCount, getResultList, sectionSelection, updateSectionSelection, resetItemInCart }) => {
  const { t } = useTranslation();
  const [_showLengRe, setShowLengRe] = useState(showLengRe);
  const [_showYiLou, setShowYiLou] = useState(showYiLou);
  const [_activeOption, setActionOption] = useState(midCodeList[0]);
  const [_selectedInstallmentRow, setSelectedInstallmentRow] = useState(yiLouCount);
  const lengReRowList = [100, 50, 20];

  useEffect(() => {
    if (templateCode === 'HK6' && selectedSubType === 'TM') {
      updateQuickBetToggle('QUICKBET');
    } else {
      updateQuickBetToggle('LIANG_MIAN_CHANG_LONG');
    }
  }, [selectedSubType, templateCode]);

  useEffect(() => {
    if (_showLengRe !== showLengRe) {
      setShowLengRe(showLengRe);
    }
  }, [showLengRe]);

  useEffect(() => {
    if (_showYiLou !== showYiLou) {
      setShowYiLou(showYiLou);
    }
  }, [showYiLou]);

  useEffect(() => {
    if (yiLouCount !== _selectedInstallmentRow) {
      setSelectedInstallmentRow(yiLouCount);
    }
  }, [yiLouCount]);

  useEffect(() => {
    if (midCodeList.length > 0) {
      updateSectionSelection(midCodeList[0]);
    }
  }, [midCodeList, lotteryType]);

  useEffect(() => {
    if (sectionSelection) {
      setActionOption(sectionSelection);
    }
  }, [sectionSelection]);

  const scrollPrev = () => {
    scroll.scrollMore(-80, {
      containerId: 'optionItemListContainer',
      horizontal: true,
      smooth: true,
      spy: true,
      duration: 300,
    });
  };

  const scrollNext = () => {
    scroll.scrollMore(80, {
      containerId: 'optionItemListContainer',
      horizontal: true,
      smooth: true,
      spy: true,
      duration: 300,
    });
  };

  return (
    <Row className="probabilitySelectionWrapper pb-2 mt-2 m-1">
      <Col md="6" className="optionListWrapper w-100">
        {
          midCodeList && (
            <Row className="w-100">
              {
                midCodeList.length > 4 && (
                  <div className="arrowWrapper">
                    <span className="arrow" onClick={scrollPrev}>{'<'}</span>
                  </div>
                )
              }
              <div id="optionItemListContainer" className="optionItemListWrapper pl-4">
                {
                  midCodeList.map(item => (
                    <div
                      key={item.value}
                      className={`optionItemWrapper ${_activeOption && _activeOption.value === item.value ? 'active' : 'inactive'}`}
                      onClick={() => {
                        updateSectionSelection(item);
                        resetItemInCart();
                      }}
                    >
                      <Row>
                        <span className={`optionWrapper ${_activeOption && _activeOption.value === item.value ? 'active' : 'inactive'}`}>
                          <BsFillCircleFill className={_activeOption && _activeOption.value === item.value ? 'active' : 'inactive'} />
                        </span>
                        {item.label}
                      </Row>
                    </div>
                  ))
                }
              </div>
              {
                midCodeList.length > 4 && (
                  <div className="arrowWrapper">
                    <span className="arrow" onClick={scrollNext}>{'>'}</span>
                  </div>
                )
              }
            </Row>
          )
        }
      </Col>
      {
        showProbability && (
          <Col md="6" className="pr-0 d-flex w-100 align-items-center">
            <Row className="rightFilter w-100">
              <Col
                sm="4"
                md="4"
                className={`d-flex align-items-center justify-content-center p-0 modeWrapper ${_showYiLou ? 'active' : ''}`}
                onClick={updateYiLouButton}
              >
                <span className={`optionWrapper ${_showYiLou ? 'active' : ''}`}>
                  {
                    _showYiLou && (
                      <BsFillCircleFill className={_showYiLou ? 'active' : ''} size={10} />
                    )
                  }
                </span>
                <span className="mr-2">
                  {t('CURRENTLY_MISSING')}
                </span>
                <Popover
                  placement="bottom-end"
                  width="250"
                  trigger="hover"
                  content={(
                    <div>
                      指选择的期数内，号码或形态的出现次数。出现较多的为热
                      <span className="lengReHot">1</span>
                      ；
                      较少的为冷
                      <span className="lengReCold">1</span>
                    </div>
                  )}
                >
                  <span>
                    <FaQuestionCircle size={20} color="#D7D7D8" />
                  </span>
                </Popover>
              </Col>
              <Col
                sm="4"
                md="4"
                className={`d-flex align-items-center justify-content-center p-0 modeWrapper ${_showLengRe ? 'active' : ''}`}
                onClick={updateLengReButton}
              >
                <span className={`optionWrapper ${_showLengRe ? 'active' : ''}`}>
                  {
                    _showLengRe && (
                      <BsFillCircleFill className={_showLengRe ? 'active' : ''} size={10} />
                    )
                  }
                </span>
                <span className="mr-2">
                  {`${_selectedInstallmentRow} ${t('HOT_COLD_MISSING')}`}
                </span>
                <Popover
                  placement="bottom-end"
                  width="250"
                  trigger="hover"
                  content={(
                    <div>
                      指选择的期数内，号码或形态的出现次数。出现较多的为热
                      <span className="yiLouHot">1</span>
                      ；
                      较少的为冷
                      <span className="yiLouCold">1</span>
                    </div>
                  )}
                >
                  <span>
                    <FaQuestionCircle size={20} color="#D7D7D8" />
                  </span>
                </Popover>
              </Col>
              <Col sm="3" md="3" className="p-0 d-flex qiContainer">
                {
                  lengReRowList.map(item => (
                    <Col
                      key={item}
                      md="4"
                      onClick={() => {
                        getResultList(moment().format('YYYY-MM-DD'), lotteryType.item, 1, item, true);
                        updateYiLouCount(item);
                        setSelectedInstallmentRow(item);
                      }}
                      className={`d-flex ${_selectedInstallmentRow === item ? 'active' : ''}`}
                    >
                      {item}
                    </Col>
                  ))
                }
              </Col>
              {templateCode === 'HK6' && selectedSubType === 'TM' && (
              <Col sm="3" md="3" className="p-0 d-flex justify-content-center align-items-center">
                <span onClick={() => updateQuickBetToggle(quickBetBtn === 'QUICKBET' ? 'LIANG_MIAN_CHANG_LONG' : 'QUICKBET')} className="quickBetBtn">{quickBetBtn === 'QUICKBET' ? t('LIANG_MIAN_CHANG_LONG') : t('QUICKBET')}</span>
              </Col>
              )}
            </Row>
          </Col>
        )
      }
    </Row>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProbabilitySelectionPanel);