// import Template2 from '../../../../components/GameTemplates/Template2';

import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import TeMaAndLianMa from '../../../../components/Mobile/ComponentType/TeMaAndLianMa';

import OptionItem from '../../../../components/Mobile/OptionItem';

const TM = [
  {
    componentType: TeMaAndLianMa,
    data: {
      list: [{
        code: 'TMA',
        displayName: '特码A',
        midCode: 'TMA,TMDSDX,TMSB',
        isTmb: 0
      }, {
        code: 'TMB',
        displayName: '特码B',
        midCode: 'TMA,TMDSDX,TMSB',
        isTmb: 1
      }]
    }
  },
  {
    componentType: CollapseType,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper threeColumnStyle',
      list: [
        {
          code: 'TMA1',
          displayName: '01',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA2',
          displayName: '02',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA3',
          displayName: '03',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA4',
          displayName: '04',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA5',
          displayName: '05',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA6',
          displayName: '06',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA7',
          displayName: '07',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA8',
          displayName: '08',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA9',
          displayName: '09',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA10',
          displayName: '10',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA11',
          displayName: '11',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA12',
          displayName: '12',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA13',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA14',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA15',
          displayName: '15',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA16',
          displayName: '16',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA17',
          displayName: '17',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA18',
          displayName: '18',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA19',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA20',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA21',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA22',
          displayName: '22',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA23',
          displayName: '23',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA24',
          displayName: '24',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA25',
          displayName: '25',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA26',
          displayName: '26',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA27',
          displayName: '27',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA28',
          displayName: '28',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA29',
          displayName: '29',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA30',
          displayName: '30',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA31',
          displayName: '31',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA32',
          displayName: '32',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA33',
          displayName: '33',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA34',
          displayName: '34',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA35',
          displayName: '35',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA36',
          displayName: '36',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA37',
          displayName: '37',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA38',
          displayName: '38',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA39',
          displayName: '39',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA40',
          displayName: '40',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA41',
          displayName: '41',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA42',
          displayName: '42',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA43',
          displayName: '43',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA44',
          displayName: '44',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA45',
          displayName: '45',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA46',
          displayName: '46',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA47',
          displayName: '47',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA48',
          displayName: '48',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA49',
          displayName: '49',
          drawImgClassName: 'circle circleGreen'
        }
      ]
    }
  },
];

export default TM;