import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import PengAndDan from '../../../../components/Mobile/ComponentType/PengAndDan';
import TeMaAndLianMa from '../../../../components/Mobile/ComponentType/TeMaAndLianMa';

import OptionItem from '../../../../components/Mobile/OptionItem';

const LW = [
  {
    componentType: TeMaAndLianMa,
    data: {
      list: [
        {
          code: 'L2LW',
          displayName: '二尾',
          minimumSelection: 2,
          maximumSelection: 6,
          danList: ['1'],
          className: 'cellWrapper',
        }, {
          code: 'L3LW',
          displayName: '三尾',
          minimumSelection: 3,
          maximumSelection: 6,
          danList: ['1', '2'],
          className: 'cellWrapper',
        }, {
          code: 'L4LW',
          displayName: '四尾',
          minimumSelection: 4,
          maximumSelection: 7,
          danList: ['1', '2', '3'],
          className: 'cellWrapper',
        }, {
          code: 'L2LWBZ',
          displayName: '二尾不中',
          minimumSelection: 2,
          maximumSelection: 6,
          danList: ['1'],
          className: 'cellWrapper',
        }, {
          code: 'L3LWBZ',
          displayName: '三尾不中',
          minimumSelection: 3,
          maximumSelection: 6,
          danList: ['1', '2'],
          className: 'cellWrapper',
        }, {
          code: 'L4LWBZ',
          displayName: '四尾不中',
          minimumSelection: 4,
          maximumSelection: 7,
          danList: ['1', '2', '3'],
          className: 'cellWrapper',
        }
      ]
    }
  },
  {
    componentType: PengAndDan,
    data: {}
  },
  {
    componentType: CollapseType,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper PK10ItemWrapperGY',
      list: [{
        code: '1',
        displayName: '0尾',
      }, {
        code: '2',
        displayName: '1尾',
      }, {
        code: '3',
        displayName: '2尾',
      }, {
        code: '4',
        displayName: '3尾',
      }, {
        code: '5',
        displayName: '4尾',
      }, {
        code: '6',
        displayName: '5尾',
      }, {
        code: '7',
        displayName: '6尾',
      }, {
        code: '8',
        displayName: '7尾',
      }, {
        code: '9',
        displayName: '8尾',
      }, {
        code: '10',
        displayName: '9尾',
      }]
    }
  }
];

export default LW;