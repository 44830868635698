import { BetTypeContainer, MultipleTypeSelectionPanel, Tab } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const BZ = [
  {
    componentType: QuickBet,
  },
  {
    componentType: MultipleTypeSelectionPanel,
    data: {
      list: [
        {
          rowType: Tab,
          wrapperStyle: 'tabRow',
          onCheckBoxChange: (updateSectionSelection, subItem) => {
            updateSectionSelection(subItem);
          },
          rowData: [{
            code: 'BZ3BZ',
            displayName: '3不中',
            minimumSelection: 3,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1', '2'],
          }, {
            code: 'BZ4BZ',
            displayName: '4不中',
            minimumSelection: 4,
            maximumSelection: 9,
            className: 'cellWrapper',
            danList: ['1', '2', '3'],
          }, {
            code: 'BZ5BZ',
            displayName: '5不中',
            minimumSelection: 5,
            maximumSelection: 9,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4'],
          }, {
            code: 'BZ6BZ',
            displayName: '6不中',
            minimumSelection: 6,
            maximumSelection: 10,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5'],
          }, {
            code: 'BZ7BZ',
            displayName: '7不中',
            minimumSelection: 7,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5', '6'],
          }, {
            code: 'BZ8BZ',
            displayName: '8不中',
            minimumSelection: 8,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5', '6', '7'],
          }, {
            code: 'BZ9BZ',
            displayName: '9不中',
            minimumSelection: 9,
            maximumSelection: 12,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5', '6', '7', '8'],
          }, {
            code: 'BZ10BZ',
            displayName: '10不中',
            minimumSelection: 10,
            maximumSelection: 13,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
          }, {
            code: 'BZ11BZ',
            displayName: '11不中',
            minimumSelection: 11,
            maximumSelection: 14,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
          }, {
            code: 'BZ12BZ',
            displayName: '12不中',
            minimumSelection: 12,
            maximumSelection: 15,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
          }, {
            code: 'BZ13BZ',
            displayName: '13不中',
            minimumSelection: 13,
            maximumSelection: 16,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
          }]
        },
        {
          rowTitleClassName: 'p-2 text-center',
          rowType: BetTypeContainer,
          wrapperStyle: 'p-2',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '赔率', '金额'],
      mainColumnLength: 4,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-checkbox'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-checkbox'],
      emptyItemStyle: ['column-3items-empty-label', 'column-3items-empty-odds', 'column-3items-empty-input'],
      betItemList: ['DisplayText', 'Odds', 'CheckBox'],
      betItemColumn: 3,
      list: [
        {
          code: '1',
          displayName: '01',
          className: 'circle circleRed',
        }, {
          code: '11',
          displayName: '11',
          className: 'circle circleGreen',
        }, {
          code: '21',
          displayName: '21',
          className: 'circle circleGreen',
        }, {
          code: '31',
          displayName: '31',
          className: 'circle circleBlue',
        }, {
          code: '2',
          displayName: '02',
          className: 'circle circleRed',
        }, {
          code: '12',
          displayName: '12',
          className: 'circle circleRed',
        }, {
          code: '22',
          displayName: '22',
          className: 'circle circleGreen',
        }, {
          code: '3',
          displayName: '03',
          className: 'circle circleBlue',
          newLine: true,
        }, {
          code: '13',
          displayName: '13',
          className: 'circle circleRed',
        }, {
          code: '23',
          displayName: '23',
          className: 'circle circleRed',
        }, {
          code: '4',
          displayName: '04',
          className: 'circle',
          newLine: true,
        }, {
          code: '14',
          displayName: '14',
          className: 'circle',
        }, {
          code: '24',
          displayName: '24',
          className: 'circle circleRed',
        }, {
          code: '5',
          displayName: '05',
          className: 'circle circleGreen',
          newLine: true,
        }, {
          code: '15',
          displayName: '15',
          className: 'circle',
        }, {
          code: '25',
          displayName: '25',
          className: 'circle',
        }, {
          code: '6',
          displayName: '06',
          className: 'circle circleGreen',
          newLine: true,
        }, {
          code: '16',
          displayName: '16',
          className: 'circle circleGreen',
        }, {
          code: '26',
          displayName: '26',
          className: 'circle',
        }, {
          code: '7',
          displayName: '07',
          className: 'circle circleRed',
          newLine: true,
        }, {
          code: '17',
          displayName: '17',
          className: 'circle circleGreen',
        }, {
          code: '27',
          displayName: '27',
          className: 'circle circleGreen',
        }, {
          code: '8',
          displayName: '08',
          className: 'circle circleRed',
          newLine: true,
        }, {
          code: '18',
          displayName: '18',
          className: 'circle circleRed',
        }, {
          code: '28',
          displayName: '28',
          className: 'circle circleGreen',
        }, {
          code: '9',
          displayName: '09',
          className: 'circle',
          newLine: true,
        }, {
          code: '19',
          displayName: '19',
          className: 'circle circleRed',
        }, {
          code: '29',
          displayName: '29',
          className: 'circle circleRed',
        }, {
          code: '10',
          displayName: '10',
          className: 'circle',
          newLine: true,
        }, {
          code: '20',
          displayName: '20',
          className: 'circle',
        }, {
          code: '30',
          displayName: '30',
          className: 'circle circleRed',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  }
];

export default BZ;