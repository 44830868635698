import { ProbabilitySelectionPanel } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const GJDXGroup = ['GYJH18', 'GYJH19'];
const GJDSGroup = ['GYJH20', 'GYJH21'];
const GJGroup = ['GJLM1', 'GJLM2', 'GJLM3', 'GJLM4', 'GJLM5', 'GJLM6'];
const YJGroup = ['YJLM1', 'YJLM2', 'YJLM3', 'YJLM4', 'YJLM5', 'YJLM6'];
const B3Group = ['B3LM1', 'B3LM2', 'B3LM3', 'B3LM4', 'B3LM5', 'B3LM6'];
const B4Group = ['B4LM1', 'B4LM2', 'B4LM3', 'B4LM4', 'B4LM5', 'B4LM6'];
const B5Group = ['B5LM1', 'B5LM2', 'B5LM3', 'B5LM4', 'B5LM5', 'B5LM6'];
const B6Group = ['B6LM1', 'B6LM2', 'B6LM3', 'B6LM4'];
const B7Group = ['B7LM1', 'B7LM2', 'B7LM3', 'B7LM4'];
const B8Group = ['B8LM1', 'B8LM2', 'B8LM3', 'B8LM4'];
const B9Group = ['B9LM1', 'B9LM2', 'B9LM3', 'B9LM4'];
const B10Group = ['B10LM1', 'B10LM2', 'B10LM3', 'B10LM4'];

const transformLengReValue = (lengRe, yiLouCount, group, currentKeyCode) => {
  const lengReArr = group.map(item => lengRe[item] || 0);
  const yiLouArr = lengReArr.map(x => yiLouCount - x);
  return {
    value: lengRe[currentKeyCode],
    className: Math.max(...lengReArr) === lengRe[currentKeyCode] ? 'hot' : (Math.min(...lengReArr) === lengRe[currentKeyCode] ? 'cold' : ''),
    classNameYiLou: Math.max(...yiLouArr) === (yiLouCount - lengRe[currentKeyCode]) ? 'hot' : (Math.min(...yiLouArr) === (yiLouCount - lengRe[currentKeyCode]) ? 'cold' : ''),
  };
};

const LM = [
  {
    componentType: ProbabilitySelectionPanel,
  },
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '冠亚军和',
      cycleHeaderTitle: true,
      mainColumnLength: 4,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      showProbability: true,
      list: [
        {
          code: 'GYJH18',
          displayName: '冠亚大',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, GJDXGroup, 'GYJH18');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'GYJH19',
          displayName: '冠亚小',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, GJDXGroup, 'GYJH19');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'GYJH20',
          displayName: '冠亚单',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, GJDSGroup, 'GYJH20');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'GYJH21',
          displayName: '冠亚双',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, GJDSGroup, 'GYJH21');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        },
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: false,
      tableHeadertitle: ['冠军', '亚军', '第三名', '第四名', '第五名'],
      mainColumnLength: 5,
      subColumnLength: 1,
      subColumnStyle: ['column-1item-style'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      showProbability: true,
      list: [
        {
          code: 'GJLM1',
          displayName: '大',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, GJGroup, 'GJLM1');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'YJLM1',
          displayName: '大',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, YJGroup, 'YJLM1');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B3LM1',
          displayName: '大',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B3Group, 'B3LM1');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B4LM1',
          displayName: '大',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B4Group, 'B4LM1');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B5LM1',
          displayName: '大',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B5Group, 'B5LM1');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'GJLM2',
          displayName: '小',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, GJGroup, 'GJLM2');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'YJLM2',
          displayName: '小',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, YJGroup, 'YJLM2');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B3LM2',
          displayName: '小',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B3Group, 'B3LM2');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B4LM2',
          displayName: '小',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B4Group, 'B4LM2');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B5LM2',
          displayName: '小',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B5Group, 'B5LM2');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'GJLM3',
          displayName: '单',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, GJGroup, 'GJLM3');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'YJLM3',
          displayName: '单',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, YJGroup, 'YJLM3');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B3LM3',
          displayName: '单',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B3Group, 'B3LM3');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B4LM3',
          displayName: '单',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B4Group, 'B4LM3');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B5LM3',
          displayName: '单',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B5Group, 'B5LM3');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'GJLM4',
          displayName: '双',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, GJGroup, 'GJLM4');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'YJLM4',
          displayName: '双',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, YJGroup, 'YJLM4');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B3LM4',
          displayName: '双',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B3Group, 'B3LM4');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B4LM4',
          displayName: '双',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B4Group, 'B4LM4');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B5LM4',
          displayName: '双',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B5Group, 'B5LM4');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'GJLM5',
          displayName: '龙',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, GJGroup, 'GJLM5');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'YJLM5',
          displayName: '龙',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, YJGroup, 'YJLM5');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B3LM5',
          displayName: '龙',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B3Group, 'B3LM5');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B4LM5',
          displayName: '龙',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B4Group, 'B4LM5');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B5LM5',
          displayName: '龙',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B5Group, 'B5LM5');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'GJLM6',
          displayName: '虎',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, GJGroup, 'GJLM6');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'YJLM6',
          displayName: '虎',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, YJGroup, 'YJLM6');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B3LM6',
          displayName: '虎',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B3Group, 'B3LM6');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B4LM6',
          displayName: '虎',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B4Group, 'B4LM6');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B5LM6',
          displayName: '虎',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B5Group, 'B5LM6');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: false,
      tableHeadertitle: ['第六名', '第七名', '第八名', '第九名', '第十名'],
      mainColumnLength: 5,
      subColumnLength: 1,
      subColumnStyle: ['column-1item-style'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      showProbability: true,
      list: [
        {
          code: 'B6LM1',
          displayName: '大',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B6Group, 'B6LM1');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B7LM1',
          displayName: '大',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B7Group, 'B7LM1');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B8LM1',
          displayName: '大',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B8Group, 'B8LM1');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B9LM1',
          displayName: '大',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B9Group, 'B9LM1');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B10LM1',
          displayName: '大',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B10Group, 'B10LM1');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B6LM2',
          displayName: '小',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B6Group, 'B6LM2');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B7LM2',
          displayName: '小',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B7Group, 'B7LM2');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B8LM2',
          displayName: '小',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B8Group, 'B8LM2');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B9LM2',
          displayName: '小',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B9Group, 'B9LM2');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B10LM2',
          displayName: '小',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B10Group, 'B10LM2');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B6LM3',
          displayName: '单',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B6Group, 'B6LM3');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B7LM3',
          displayName: '单',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B7Group, 'B7LM3');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B8LM3',
          displayName: '单',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B8Group, 'B8LM3');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B9LM3',
          displayName: '单',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B9Group, 'B9LM3');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B10LM3',
          displayName: '单',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B10Group, 'B10LM3');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B6LM4',
          displayName: '双',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B6Group, 'B6LM4');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B7LM4',
          displayName: '双',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B7Group, 'B7LM4');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B8LM4',
          displayName: '双',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B8Group, 'B8LM4');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B9LM4',
          displayName: '双',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B9Group, 'B9LM4');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B10LM4',
          displayName: '双',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B10Group, 'B10LM4');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default LM;