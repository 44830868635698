import React from 'react';
import './styles.scss';

const Divider = ({ title, subTitle, icon }) => (
  <div className="d-flex flew-row mt-5 mb-4 align-items-center">
    <div className="divider-icon p-1 rounded-lg">{icon}</div>
    <div>
      <h2 className="ml-2 mt-2 divider-title">{title}</h2>
    </div>
    <div className="ml-4 divider-dot rounded-circle" />
    <div className="ml-1 divider-dot-long rounded-pill" />
    <div className="font-weight-light">{subTitle}</div>
  </div>
);

export default Divider;