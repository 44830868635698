import { ProbabilitySelectionPanel } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const KD = [
  {
    componentType: ProbabilitySelectionPanel,
    data: {
      showProbability: false,
      midCodeList: [{
        label: '前三',
        value: 'KDQ3',
      }, {
        label: '中三',
        value: 'KDZ3',
      }, {
        label: '后三',
        value: 'KDH3',
      }]
    }
  },
  {
    componentType: QuickBet,
  }, {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '赔率', '金额'],
      mainColumnLength: 2,
      subColumnLength: 3,
      subColumnStyle: ['w-25', 'w-50', 'w-25'],
      betItemStyle: ['w-25', 'w-50', 'w-25'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: '_0', // keyCode
          displayName: '0',
        }, {
          code: '_1',
          displayName: '1',
        }, {
          code: '_2',
          displayName: '2',
        }, {
          code: '_3',
          displayName: '3',
        }, {
          code: '_4',
          displayName: '4',
        }, {
          code: '_5',
          displayName: '5',
        }, {
          code: '_6',
          displayName: '6',
        }, {
          code: '_7',
          displayName: '7',
        }, {
          code: '_8',
          displayName: '8',
        }, {
          code: '_9',
          displayName: '9',
        }
      ]
    }
  }, {
    componentType: QuickBet,
  }
];

export default KD;