import { ProbabilitySelectionPanel, ShortCutInput } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import TabContainer from '../../../../components/Web/TabContainer';
import Template2 from '../../../../components/GameTemplates/Template2';

const SZZH = [
  {
    componentType: ProbabilitySelectionPanel,
    data: {
      showProbability: false,
      midCodeList: [{
        label: '前三',
        value: 'ZH3Q3',
      }, {
        label: '中三',
        value: 'ZH3Z3',
      }, {
        label: '后三',
        value: 'ZH3H3',
      }],
    }
  },
  {
    componentType: QuickBet,
  },
  {
    componentType: ShortCutInput,
    data: {
      padLeadingZero: 3,
      list: [{
        code: '000', // keyCode
        displayName: '000',
      }, {
        code: '001', // keyCode
        displayName: '001',
      }, {
        code: '002',
        displayName: '002',
      }, {
        code: '003',
        displayName: '003',
      }, {
        code: '004',
        displayName: '004',
      }, {
        code: '005',
        displayName: '005',
      }, {
        code: '006',
        displayName: '006',
      }, {
        code: '007',
        displayName: '007',
      }, {
        code: '008',
        displayName: '008',
      }, {
        code: '009',
        displayName: '009',
      }, {
        code: '011', // keyCode
        displayName: '011',
      }, {
        code: '012',
        displayName: '012',
      }, {
        code: '013',
        displayName: '013',
      }, {
        code: '014',
        displayName: '014',
      }, {
        code: '015',
        displayName: '015',
      }, {
        code: '016',
        displayName: '016',
      }, {
        code: '017',
        displayName: '017',
      }, {
        code: '018',
        displayName: '018',
      }, {
        code: '019',
        displayName: '019',
      }, {
        code: '022',
        displayName: '022',
      }, {
        code: '023',
        displayName: '023',
      }, {
        code: '024',
        displayName: '024',
      }, {
        code: '025',
        displayName: '025',
      }, {
        code: '026',
        displayName: '026',
      }, {
        code: '027',
        displayName: '027',
      }, {
        code: '028',
        displayName: '028',
      }, {
        code: '029',
        displayName: '029',
      }, {
        code: '033',
        displayName: '033',
      }, {
        code: '034',
        displayName: '034',
      }, {
        code: '035',
        displayName: '035',
      }, {
        code: '036',
        displayName: '036',
      }, {
        code: '037',
        displayName: '037',
      }, {
        code: '038',
        displayName: '038',
      }, {
        code: '039',
        displayName: '039',
      }, {
        code: '044',
        displayName: '044',
      }, {
        code: '045',
        displayName: '045',
      }, {
        code: '046',
        displayName: '046',
      }, {
        code: '047',
        displayName: '047',
      }, {
        code: '048',
        displayName: '048',
      }, {
        code: '049',
        displayName: '049',
      }, {
        code: '055',
        displayName: '055',
      }, {
        code: '056',
        displayName: '056',
      }, {
        code: '057',
        displayName: '057',
      }, {
        code: '058',
        displayName: '058',
      }, {
        code: '059',
        displayName: '059',
      }, {
        code: '066',
        displayName: '066',
      }, {
        code: '067',
        displayName: '067',
      }, {
        code: '068',
        displayName: '068',
      }, {
        code: '069',
        displayName: '069',
      }, {
        code: '077',
        displayName: '077',
      }, {
        code: '078',
        displayName: '078',
      }, {
        code: '079',
        displayName: '079',
      }, {
        code: '088',
        displayName: '088',
      }, {
        code: '089',
        displayName: '089',
      }, {
        code: '099',
        displayName: '099',
      }, {
        code: '111',
        displayName: '111',
      }, {
        code: '112',
        displayName: '112',
      }, {
        code: '113',
        displayName: '113',
      }, {
        code: '114',
        displayName: '114',
      }, {
        code: '115',
        displayName: '115',
      }, {
        code: '116',
        displayName: '116',
      }, {
        code: '117',
        displayName: '117',
      }, {
        code: '118',
        displayName: '118',
      }, {
        code: '119',
        displayName: '129',
      }, {
        code: '122',
        displayName: '122',
      }, {
        code: '123',
        displayName: '123',
      }, {
        code: '124',
        displayName: '124',
      }, {
        code: '125',
        displayName: '125',
      }, {
        code: '126',
        displayName: '126',
      }, {
        code: '127',
        displayName: '127',
      }, {
        code: '128',
        displayName: '128',
      }, {
        code: '129',
        displayName: '129',
      }, {
        code: '133',
        displayName: '133',
      }, {
        code: '134',
        displayName: '134',
      }, {
        code: '135',
        displayName: '135',
      }, {
        code: '136',
        displayName: '136',
      }, {
        code: '137',
        displayName: '137',
      }, {
        code: '138',
        displayName: '138',
      }, {
        code: '139',
        displayName: '139',
      }, {
        code: '144',
        displayName: '144',
      }, {
        code: '145',
        displayName: '145',
      }, {
        code: '146',
        displayName: '146',
      }, {
        code: '147',
        displayName: '147',
      }, {
        code: '148',
        displayName: '148',
      }, {
        code: '149',
        displayName: '149',
      }, {
        code: '155',
        displayName: '155',
      }, {
        code: '156',
        displayName: '156',
      }, {
        code: '157',
        displayName: '157',
      }, {
        code: '158',
        displayName: '158',
      }, {
        code: '159',
        displayName: '159',
      }, {
        code: '166',
        displayName: '166',
      }, {
        code: '167',
        displayName: '167',
      }, {
        code: '168',
        displayName: '168',
      }, {
        code: '169',
        displayName: '169',
      }, {
        code: '177',
        displayName: '177',
      }, {
        code: '178',
        displayName: '178',
      }, {
        code: '179',
        displayName: '179',
      }, {
        code: '188',
        displayName: '188',
      }, {
        code: '189',
        displayName: '189',
      }, {
        code: '199',
        displayName: '199',
      }, {
        code: '222',
        displayName: '222',
      }, {
        code: '223',
        displayName: '223',
      }, {
        code: '224',
        displayName: '224',
      }, {
        code: '225',
        displayName: '225',
      }, {
        code: '226',
        displayName: '226',
      }, {
        code: '227',
        displayName: '227',
      }, {
        code: '228',
        displayName: '228',
      }, {
        code: '229',
        displayName: '229',
      }, {
        code: '233',
        displayName: '233',
      }, {
        code: '234',
        displayName: '234',
      }, {
        code: '235',
        displayName: '235',
      }, {
        code: '236',
        displayName: '236',
      }, {
        code: '237',
        displayName: '237',
      }, {
        code: '238',
        displayName: '238',
      }, {
        code: '239',
        displayName: '239',
      }, {
        code: '244',
        displayName: '244',
      }, {
        code: '245',
        displayName: '245',
      }, {
        code: '246',
        displayName: '246',
      }, {
        code: '247',
        displayName: '247',
      }, {
        code: '248',
        displayName: '248',
      }, {
        code: '249',
        displayName: '249',
      }, {
        code: '255',
        displayName: '255',
      }, {
        code: '256',
        displayName: '256',
      }, {
        code: '257',
        displayName: '257',
      }, {
        code: '258',
        displayName: '258',
      }, {
        code: '259',
        displayName: '259',
      }, {
        code: '266',
        displayName: '266',
      }, {
        code: '267',
        displayName: '267',
      }, {
        code: '268',
        displayName: '268',
      }, {
        code: '269',
        displayName: '269',
      }, {
        code: '277',
        displayName: '277',
      }, {
        code: '278',
        displayName: '278',
      }, {
        code: '279',
        displayName: '279',
      }, {
        code: '288',
        displayName: '288',
      }, {
        code: '289',
        displayName: '289',
      }, {
        code: '299',
        displayName: '299',
      }, {
        code: '333',
        displayName: '333',
      }, {
        code: '334',
        displayName: '334',
      }, {
        code: '335',
        displayName: '335',
      }, {
        code: '336',
        displayName: '336',
      }, {
        code: '337',
        displayName: '337',
      }, {
        code: '338',
        displayName: '338',
      }, {
        code: '339',
        displayName: '339',
      }, {
        code: '344',
        displayName: '344',
      }, {
        code: '345',
        displayName: '345',
      }, {
        code: '346',
        displayName: '346',
      }, {
        code: '347',
        displayName: '347',
      }, {
        code: '348',
        displayName: '348',
      }, {
        code: '349',
        displayName: '349',
      }, {
        code: '355',
        displayName: '355',
      }, {
        code: '356',
        displayName: '356',
      }, {
        code: '357',
        displayName: '357',
      }, {
        code: '358',
        displayName: '358',
      }, {
        code: '359',
        displayName: '359',
      }, {
        code: '366',
        displayName: '366',
      }, {
        code: '367',
        displayName: '367',
      }, {
        code: '368',
        displayName: '368',
      }, {
        code: '369',
        displayName: '369',
      }, {
        code: '377',
        displayName: '377',
      }, {
        code: '378',
        displayName: '378',
      }, {
        code: '379',
        displayName: '379',
      }, {
        code: '388',
        displayName: '388',
      }, {
        code: '389',
        displayName: '389',
      }, {
        code: '399',
        displayName: '399',
      }, {
        code: '444',
        displayName: '444',
      }, {
        code: '445',
        displayName: '445',
      }, {
        code: '446',
        displayName: '446',
      }, {
        code: '447',
        displayName: '447',
      }, {
        code: '448',
        displayName: '448',
      }, {
        code: '449',
        displayName: '449',
      }, {
        code: '455',
        displayName: '455',
      }, {
        code: '456',
        displayName: '456',
      }, {
        code: '457',
        displayName: '457',
      }, {
        code: '458',
        displayName: '458',
      }, {
        code: '459',
        displayName: '459',
      }, {
        code: '466',
        displayName: '466',
      }, {
        code: '467',
        displayName: '467',
      }, {
        code: '468',
        displayName: '468',
      }, {
        code: '469',
        displayName: '469',
      }, {
        code: '477',
        displayName: '477',
      }, {
        code: '478',
        displayName: '478',
      }, {
        code: '479',
        displayName: '479',
      }, {
        code: '488',
        displayName: '488',
      }, {
        code: '489',
        displayName: '489',
      }, {
        code: '499',
        displayName: '499',
      }, {
        code: '555',
        displayName: '555',
      }, {
        code: '556',
        displayName: '556',
      }, {
        code: '557',
        displayName: '557',
      }, {
        code: '558',
        displayName: '558',
      }, {
        code: '559',
        displayName: '559',
      }, {
        code: '566',
        displayName: '566',
      }, {
        code: '567',
        displayName: '567',
      }, {
        code: '568',
        displayName: '568',
      }, {
        code: '569',
        displayName: '569',
      }, {
        code: '577',
        displayName: '577',
      }, {
        code: '578',
        displayName: '578',
      }, {
        code: '579',
        displayName: '579',
      }, {
        code: '588',
        displayName: '588',
      }, {
        code: '589',
        displayName: '589',
      }, {
        code: '599',
        displayName: '599',
      }, {
        code: '666',
        displayName: '666',
      }, {
        code: '667',
        displayName: '667',
      }, {
        code: '668',
        displayName: '668',
      }, {
        code: '669',
        displayName: '669',
      }, {
        code: '677',
        displayName: '677',
      }, {
        code: '678',
        displayName: '678',
      }, {
        code: '679',
        displayName: '679',
      }, {
        code: '688',
        displayName: '688',
      }, {
        code: '689',
        displayName: '689',
      }, {
        code: '699',
        displayName: '699',
      }, {
        code: '777',
        displayName: '777',
      }, {
        code: '778',
        displayName: '778',
      }, {
        code: '779',
        displayName: '779',
      }, {
        code: '788',
        displayName: '788',
      }, {
        code: '789',
        displayName: '789',
      }, {
        code: '799',
        displayName: '799',
      }, {
        code: '888',
        displayName: '888',
      }, {
        code: '889',
        displayName: '889',
      }, {
        code: '899',
        displayName: '899',
      }, {
        code: '999',
        displayName: '999',
      }]
    }
  },
  {
    componentType: TabContainer,
    data: {
      navMenu: [{
        label: '0头',
        value: 'LT'
      }, {
        label: '1头',
        value: 'YT'
      }, {
        label: '2头',
        value: 'ET'
      }, {
        label: '3头',
        value: 'ST'
      }, {
        label: '4 ~ 9头',
        value: 'SJT'
      }],
      content: [{
        key: 'LT',
        children: [{
          componentType: Template2,
          data: {
            cycleHeaderTitle: true,
            tableHeadertitle: ['号码', '赔率', '金额'],
            mainColumnLength: 5,
            subColumnLength: 3,
            subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemList: ['DisplayText', 'Odds', 'Input'],
            betItemColumn: 3,
            list: [
              {
                code: '_000',
                displayName: '000',
              }, {
                code: '_012',
                displayName: '012',
              }, {
                code: '_025',
                displayName: '025',
              }, {
                code: '_039',
                displayName: '039',
              }, {
                code: '_059',
                displayName: '059',
              }, {
                code: '_001',
                displayName: '001',
              }, {
                code: '_013',
                displayName: '013',
              }, {
                code: '_026',
                displayName: '026',
              }, {
                code: '_044',
                displayName: '044',
              }, {
                code: '_066',
                displayName: '066',
              }, {
                code: '_002',
                displayName: '002',
              }, {
                code: '_014',
                displayName: '014',
              }, {
                code: '_027',
                displayName: '027',
              }, {
                code: '_045',
                displayName: '045',
              }, {
                code: '_067',
                displayName: '067',
              }, {
                code: '_003',
                displayName: '003',
              }, {
                code: '_015',
                displayName: '015',
              }, {
                code: '_028',
                displayName: '028',
              }, {
                code: '_046',
                displayName: '046',
              }, {
                code: '_068',
                displayName: '068',
              }, {
                code: '_004',
                displayName: '004',
              }, {
                code: '_016',
                displayName: '016',
              }, {
                code: '_029',
                displayName: '029',
              }, {
                code: '_047',
                displayName: '047',
              }, {
                code: '_069',
                displayName: '069',
              }, {
                code: '_005',
                displayName: '005',
              }, {
                code: '_017',
                displayName: '017',
              }, {
                code: '_033',
                displayName: '033',
              }, {
                code: '_048',
                displayName: '048',
              }, {
                code: '_077',
                displayName: '077',
              }, {
                code: '_006',
                displayName: '006',
              }, {
                code: '_018',
                displayName: '018',
              }, {
                code: '_034',
                displayName: '034',
              }, {
                code: '_049',
                displayName: '049',
              }, {
                code: '_078',
                displayName: '078',
              }, {
                code: '_007',
                displayName: '007',
              }, {
                code: '_019',
                displayName: '019',
              }, {
                code: '_035',
                displayName: '035',
              }, {
                code: '_055',
                displayName: '055',
              }, {
                code: '_079',
                displayName: '079',
              }, {
                code: '_008',
                displayName: '008',
              }, {
                code: '_022',
                displayName: '022',
              }, {
                code: '_036',
                displayName: '036',
              }, {
                code: '_056',
                displayName: '056',
              }, {
                code: '_088',
                displayName: '088',
              }, {
                code: '_009',
                displayName: '009',
              }, {
                code: '_023',
                displayName: '023',
              }, {
                code: '_037',
                displayName: '037',
              }, {
                code: '_057',
                displayName: '057',
              }, {
                code: '_089',
                displayName: '089',
              }, {
                code: '_011',
                displayName: '011',
              }, {
                code: '_024',
                displayName: '024',
              }, {
                code: '_038',
                displayName: '038',
              }, {
                code: '_058',
                displayName: '058',
              }, {
                code: '_099',
                displayName: '099',
              }
            ]
          }
        }]
      }, {
        key: 'YT',
        children: [{
          componentType: Template2,
          data: {
            cycleHeaderTitle: true,
            tableHeadertitle: ['号码', '赔率', '金额'],
            mainColumnLength: 4,
            subColumnLength: 3,
            subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemList: ['DisplayText', 'Odds', 'Input'],
            betItemColumn: 3,
            list: [
              {
                code: '_111',
                displayName: '111',
              }, {
                code: '_125',
                displayName: '125',
              }, {
                code: '_144',
                displayName: '144',
              }, {
                code: '_167',
                displayName: '167',
              }, {
                code: '_112',
                displayName: '112',
              }, {
                code: '_126',
                displayName: '126',
              }, {
                code: '_145',
                displayName: '145',
              }, {
                code: '_168',
                displayName: '168',
              }, {
                code: '_113',
                displayName: '113',
              }, {
                code: '_127',
                displayName: '127',
              }, {
                code: '_146',
                displayName: '146',
              }, {
                code: '_169',
                displayName: '169',
              }, {
                code: '_114',
                displayName: '114',
              }, {
                code: '_128',
                displayName: '128',
              }, {
                code: '_147',
                displayName: '147',
              }, {
                code: '_177',
                displayName: '177',
              }, {
                code: '_115',
                displayName: '115',
              }, {
                code: '_129',
                displayName: '129',
              }, {
                code: '_148',
                displayName: '148',
              }, {
                code: '_178',
                displayName: '178',
              }, {
                code: '_116',
                displayName: '116',
              }, {
                code: '_133',
                displayName: '133',
              }, {
                code: '_149',
                displayName: '149',
              }, {
                code: '_179',
                displayName: '179',
              }, {
                code: '_117',
                displayName: '117',
              }, {
                code: '_134',
                displayName: '134',
              }, {
                code: '_155',
                displayName: '155',
              }, {
                code: '_188',
                displayName: '188',
              }, {
                code: '_118',
                displayName: '118',
              }, {
                code: '_135',
                displayName: '135',
              }, {
                code: '_156',
                displayName: '156',
              }, {
                code: '_189',
                displayName: '189',
              }, {
                code: '_119',
                displayName: '119',
              }, {
                code: '_136',
                displayName: '136',
              }, {
                code: '_157',
                displayName: '157',
              }, {
                code: '_199',
                displayName: '199',
              }, {
                code: '_122',
                displayName: '122',
              }, {
                code: '_137',
                displayName: '137',
              }, {
                code: '_158',
                displayName: '158',
              }, {
                code: '_123',
                displayName: '123',
                newLine: true,
              }, {
                code: '_138',
                displayName: '138',
              }, {
                code: '_159',
                displayName: '159',
              }, {
                code: '_124',
                displayName: '124',
                newLine: true,
              }, {
                code: '_139',
                displayName: '139',
              }, {
                code: '_166',
                displayName: '166',
              }
            ]
          }
        }]
      }, {
        key: 'ET',
        children: [{
          componentType: Template2,
          data: {
            cycleHeaderTitle: true,
            tableHeadertitle: ['号码', '赔率', '金额'],
            mainColumnLength: 4,
            subColumnLength: 3,
            subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemList: ['DisplayText', 'Odds', 'Input'],
            betItemColumn: 3,
            list: [
              {
                code: '_222',
                displayName: '222',
              }, {
                code: '_234',
                displayName: '234',
              }, {
                code: '_247',
                displayName: '247',
              }, {
                code: '_267',
                displayName: '267',
              }, {
                code: '_223',
                displayName: '223',
              }, {
                code: '_235',
                displayName: '235',
              }, {
                code: '_248',
                displayName: '248',
              }, {
                code: '_268',
                displayName: '268',
              }, {
                code: '_224',
                displayName: '224',
              }, {
                code: '_236',
                displayName: '236',
              }, {
                code: '_249',
                displayName: '249',
              }, {
                code: '_269',
                displayName: '269',
              }, {
                code: '_225',
                displayName: '225',
              }, {
                code: '_237',
                displayName: '237',
              }, {
                code: '_255',
                displayName: '255',
              }, {
                code: '_277',
                displayName: '277',
              }, {
                code: '_226',
                displayName: '226',
              }, {
                code: '_238',
                displayName: '238',
              }, {
                code: '_256',
                displayName: '256',
              }, {
                code: '_278',
                displayName: '278',
              }, {
                code: '_227',
                displayName: '227',
              }, {
                code: '_239',
                displayName: '239',
              }, {
                code: '_257',
                displayName: '257',
              }, {
                code: '_279',
                displayName: '279',
              }, {
                code: '_228',
                displayName: '228',
              }, {
                code: '_244',
                displayName: '244',
              }, {
                code: '_258',
                displayName: '258',
              }, {
                code: '_288',
                displayName: '288',
              }, {
                code: '_229',
                displayName: '229',
              }, {
                code: '_245',
                displayName: '245',
              }, {
                code: '_259',
                displayName: '259',
              }, {
                code: '_289',
                displayName: '289',
              }, {
                code: '_233',
                displayName: '233',
              }, {
                code: '_246',
                displayName: '246',
              }, {
                code: '_266',
                displayName: '266',
              }, {
                code: '_299',
                displayName: '299',
              }
            ]
          }
        }]
      }, {
        key: 'ST',
        children: [{
          componentType: Template2,
          data: {
            cycleHeaderTitle: true,
            tableHeadertitle: ['号码', '赔率', '金额'],
            mainColumnLength: 4,
            subColumnLength: 3,
            subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemList: ['DisplayText', 'Odds', 'Input'],
            betItemColumn: 3,
            list: [
              {
                code: '_333',
                displayName: '333',
              }, {
                code: '_344',
                displayName: '344',
              }, {
                code: '_356',
                displayName: '356',
              }, {
                code: '_369',
                displayName: '369',
              }, {
                code: '_334',
                displayName: '334',
              }, {
                code: '_345',
                displayName: '345',
              }, {
                code: '_357',
                displayName: '357',
              }, {
                code: '_377',
                displayName: '377',
              }, {
                code: '_335',
                displayName: '335',
              }, {
                code: '_346',
                displayName: '346',
              }, {
                code: '_358',
                displayName: '358',
              }, {
                code: '_378',
                displayName: '378',
              }, {
                code: '_336',
                displayName: '336',
              }, {
                code: '_347',
                displayName: '347',
              }, {
                code: '_359',
                displayName: '359',
              }, {
                code: '_379',
                displayName: '379',
              }, {
                code: '_337',
                displayName: '337',
              }, {
                code: '_348',
                displayName: '348',
              }, {
                code: '_366',
                displayName: '366',
              }, {
                code: '_388',
                displayName: '388',
              }, {
                code: '_338',
                displayName: '338',
              }, {
                code: '_349',
                displayName: '349',
              }, {
                code: '_367',
                displayName: '367',
              }, {
                code: '_389',
                displayName: '389',
              }, {
                code: '_339',
                displayName: '339',
              }, {
                code: '_355',
                displayName: '355',
              }, {
                code: '_368',
                displayName: '368',
              }, {
                code: '_399',
                displayName: '399',
              }
            ]
          }
        }]
      }, {
        key: 'SJT',
        children: [{
          componentType: Template2,
          data: {
            cycleHeaderTitle: true,
            tableHeadertitle: ['号码', '赔率', '金额'],
            mainColumnLength: 4,
            subColumnLength: 3,
            subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemList: ['DisplayText', 'Odds', 'Input'],
            betItemColumn: 3,
            list: [
              {
                code: '_444',
                displayName: '444',
              }, {
                code: '_469',
                displayName: '469',
              }, {
                code: '_568',
                displayName: '568',
              }, {
                code: '_679',
                displayName: '679',
              }, {
                code: '_445',
                displayName: '445',
              }, {
                code: '_477',
                displayName: '477',
              }, {
                code: '_569',
                displayName: '569',
              }, {
                code: '_688',
                displayName: '688',
              }, {
                code: '_446',
                displayName: '446',
              }, {
                code: '_478',
                displayName: '478',
              }, {
                code: '_577',
                displayName: '577',
              }, {
                code: '_689',
                displayName: '689',
              }, {
                code: '_447',
                displayName: '447',
              }, {
                code: '_479',
                displayName: '479',
              }, {
                code: '_578',
                displayName: '578',
              }, {
                code: '_699',
                displayName: '699',
              }, {
                code: '_448',
                displayName: '448',
              }, {
                code: '_488',
                displayName: '488',
              }, {
                code: '_579',
                displayName: '579',
              }, {
                code: '_777',
                displayName: '777',
              }, {
                code: '_449',
                displayName: '449',
              }, {
                code: '_489',
                displayName: '489',
              }, {
                code: '_588',
                displayName: '588',
              }, {
                code: '_778',
                displayName: '778',
              }, {
                code: '_455',
                displayName: '455',
              }, {
                code: '_499',
                displayName: '499',
              }, {
                code: '_589',
                displayName: '589',
              }, {
                code: '_779',
                displayName: '779',
              }, {
                code: '_456',
                displayName: '456',
              }, {
                code: '_555',
                displayName: '555',
              }, {
                code: '_599',
                displayName: '599',
              }, {
                code: '_788',
                displayName: '788',
              }, {
                code: '_457',
                displayName: '457',
              }, {
                code: '_556',
                displayName: '556',
              }, {
                code: '_666',
                displayName: '666',
              }, {
                code: '_789',
                displayName: '789',
              }, {
                code: '_458',
                displayName: '458',
              }, {
                code: '_557',
                displayName: '557',
              }, {
                code: '_667',
                displayName: '667',
              }, {
                code: '_799',
                displayName: '799',
              }, {
                code: '_459',
                displayName: '459',
              }, {
                code: '_558',
                displayName: '558',
              }, {
                code: '_668',
                displayName: '668',
              }, {
                code: '_888',
                displayName: '888',
              }, {
                code: '_466',
                displayName: '466',
              }, {
                code: '_559',
                displayName: '559',
              }, {
                code: '_669',
                displayName: '669',
              }, {
                code: '_889',
                displayName: '889',
              }, {
                code: '_467',
                displayName: '467',
              }, {
                code: '_566',
                displayName: '566',
              }, {
                code: '_677',
                displayName: '677',
              }, {
                code: '_899',
                displayName: '899',
              }, {
                code: '_468',
                displayName: '468',
              }, {
                code: '_567',
                displayName: '567',
              }, {
                code: '_678',
                displayName: '678',
              }, {
                code: '_999',
                displayName: '999',
              }
            ]
          }
        }]
      }]
    }
  },
  {
    componentType: QuickBet,
  }
];

export default SZZH;