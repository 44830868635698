import React, { useEffect, useState } from 'react';

import { Button, Col, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, cartActions, gameActions } from '../../../redux/actions';
import { GameVariable } from '../../../constants';
import { validateNumber } from '../../../constants/utils';
import './styles.scss';
import 'element-theme-default';

const mapStateToProps = state => ({
  presetAmount: state.gameReducers.presetAmount,
  gameInfo: state.placeBetReducers.gameInfo,
  templateCode: state.gameReducers.template,
  sectionSelection: state.gameReducers.sectionSelection,
  selectedSubType: state.gameReducers.selectedSubType,
  cart: state.cartReducers.cart,
  companyInfo: state.companyReducers.companyInfo,
});

const mapDispatchToProps = dispatch => ({
  addItemToCart: (item, reset) => dispatch(cartActions.addItemToCart(item, reset)),
  removeItemInCart: keyCode => dispatch(cartActions.removeItemInCart(keyCode)),
  resetItemInCart: () => dispatch(cartActions.resetItemInCart()),
  updatePresetAmount: amount => dispatch(gameActions.updatePresetAmount(amount)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const QuickBetPanel = ({ presetAmount, companyInfo, gameInfo, sectionSelection, selectedSubType, cart, addItemToCart, removeItemInCart, updateModalAlert, resetItemInCart, updatePresetAmount }) => {
  const [tabType, setTabType] = useState('quickSelection');
  const { t } = useTranslation();
  const [_selectedItemArray, setSelectedItemArray] = useState({});
  const [_shortCutValue, setShortCutValue] = useState();
  const [_presetAmount, setPresetAmount] = useState();
  const { KuaiJieShortCut } = GameVariable.CJ_HK6;

  useEffect(() => {
    if (!gameInfo?.oddsList) {
      setSelectedItemArray({});
      setShortCutValue();
    }
  }, [gameInfo]);

  useEffect(() => {
    if (!cart) {
      setSelectedItemArray({});
    }
  }, [cart]);

  useEffect(() => {
    if (presetAmount !== _presetAmount) {
      setPresetAmount(presetAmount);
    }
  }, [presetAmount]);

  const onItemClick = (obj, removeDisabled, amount) => {
    if (!gameInfo.oddsList) return;
    const keyCode = obj.code;
    const currentOddItem = gameInfo && gameInfo.oddsList && gameInfo.oddsList[obj.code];
    const { midType, odds, oddsB, enabled } = currentOddItem || {};
    if (enabled === 0) return;
    if (cart && cart[keyCode] && !amount && !removeDisabled) {
      removeItemInCart(keyCode);
    } else if (obj && currentOddItem) {
      if (selectedSubType === 'TM') {
        const tempMidType = selectedSubType === 'TM' ? (sectionSelection.value === 'TMA' ? '特A' : '特B') : midType;
        return addItemToCart({
          [keyCode]: {
            displayName: obj.displayName,
            amount: amount || _presetAmount || 0,
            midType: tempMidType,
            isTmb: sectionSelection.value === 'TMA' ? 0 : 1,
            odds: sectionSelection.value === 'TMA' ? odds : oddsB,
            keyCode,
          }
        });
      }
    }
  };

  const padLeadingZeros = (num, size) => {
    let s = `${num}`;
    while (s.length < size) s = `0${s}`;
    return s;
  };

  const onShortCutInputChange = ({ target: { value: text } }) => {
    if (text === ' ') return setShortCutValue('');
    resetItemInCart();
    const selectionArr = text.split(' ');
    selectionArr.forEach(item => {
      const selectionItemArr = item.split('=');
      const { padLeadingZero } = KuaiJieShortCut.numberPanel;
      const listItem = KuaiJieShortCut.numberPanel.list.find(x => x.displayName === (padLeadingZero ? padLeadingZeros(selectionItemArr[0], padLeadingZero) : selectionItemArr[0]));
      if (listItem && selectionItemArr.filter(x => x !== '').length === 2) {
        const keyCode = listItem.code;
        const { midType, odds, enabled } = gameInfo.oddsList[keyCode] || {};
        if (enabled === 0) return;
        return addItemToCart({
          [keyCode]: {
            displayName: listItem.displayName,
            amount: validateNumber(selectionItemArr[1]),
            keyCode,
            midType,
            odds,
          }
        });
      }
    });
    setShortCutValue(validateNumber(text, ['=', ''], true));
  };

  const renderTable = ({ list, numberOfColumn, checkOdds, itemClickEvent }) => {
    const tableBody = [];
    let count = 0;
    let tableBodyRow = [];
    const fillEmptyRow = isEnd => {
      const _className = gameInfo?.oddsList ? '' : 'disabledCell';
      if (isEnd) {
        if (tableBodyRow.length < numberOfColumn) {
          tableBodyRow.push(
            <td key={`choose_bet_empty_item_${tableBodyRow.length.toString()}`} colSpan={numberOfColumn - tableBodyRow.length} className={_className} />
          );
        }
      } else {
        while (tableBodyRow.length < numberOfColumn) {
          tableBodyRow.push(
            <td key={`choose_bet_empty_item_${tableBodyRow.length.toString()}`} className={_className} />
          );
        }
      }
      tableBody.push(<tr key={`table_body_${count.toString()}`}>{tableBodyRow}</tr>);
    };
    const fillItemRow = obj => {
      let _className = '';
      if (checkOdds) {
        if (gameInfo?.oddsList) {
          _className = gameInfo?.oddsList[obj.code]?.enabled === 0 ? 'disabledCell' : cart?.[obj.code] ? 'selectedCell' : '';
        } else {
          _className = 'disabledCell';
        }
      } else if (gameInfo?.oddsList) {
        _className = _selectedItemArray[obj.displayName] ? 'selectedCell' : '';
      } else {
        _className = 'disabledCell';
      }
      tableBodyRow.push(
        <td key={`choose_bet_item_${count.toString()}`}>
          <span
            onClick={() => {
              if (_className === 'disabledCell') return;
              const { zodiac } = companyInfo.company || {};
              itemClickEvent({
                onItemClick, obj, cart, _selectedItemArray, resetItemInCart, setSelectedItemArray, zodiac
              });
            }}
            className={`py-1 d-block w-100 h-100 ${_className} ${obj.className}`}
          >
            {obj.displayName}
          </span>
        </td>
      );
      count += 1;
    };
    while (count < list.length) {
      if (list[count] && list[count].newLine) {
        fillEmptyRow();
        tableBodyRow = [];
        fillItemRow(list[count]);
      } else if (tableBodyRow.length < numberOfColumn) {
        fillItemRow(list[count]);
      } else {
        tableBody.push(<tr key={`table_body_${count.toString()}`}>{tableBodyRow}</tr>);
        tableBodyRow = [];
      }
    }
    fillEmptyRow(true);
    return (
      <tbody>
        {tableBody}
      </tbody>
    );
  };

  return (
    <Col md="12" className="quickBetPanelWrapper">
      <Col className="d-flex quickBetPanelPanelHeader">
        <Col md="6" className={`quickSelectionWrapper ${tabType === 'quickSelection' ? 'activeType' : 'inactiveType'}`} onClick={() => setTabType('quickSelection')}>{t('QUICK_BET_SELECTION')}</Col>
        <Col md="6" className={`quickBetWrapper ${tabType === 'quickBet' ? 'activeType' : 'inactiveType'}`} onClick={() => setTabType('quickBet')}>{t('SHORTCUT_SELECT_BET')}</Col>
      </Col>
      <Col className="p-0 quickBetPanelContent">
        {tabType === 'quickSelection' ? (
          <table className="w-100 table table-borderless text-center mb-0">
            {renderTable(KuaiJieShortCut?.numberPanel)}
          </table>
        ) : (<textarea value={_shortCutValue} className="quickSelectionContent" onChange={onShortCutInputChange} disabled={!gameInfo?.oddsList} />)}
        {tabType === 'quickSelection' && (
        <Col className={`quickInputRow d-flex justify-content-center align-items-center ${gameInfo?.oddsList ? '' : 'disabledCell'}`}>
          <span>{t('AMOUNT')}</span>
          <Input
            className="inputBox"
            value={_presetAmount || ''}
            onChange={({ target: { value: text } }) => {
              const validatedText = validateNumber(text);
              updatePresetAmount(validatedText || '');
            }}
            disabled={!gameInfo?.oddsList}
          />
        </Col>
        )}
        <Col className={`d-flex justify-content-center align-items-center quickBetBtnRow ${gameInfo?.oddsList ? '' : 'disabledCell'}`}>
          <Button
            className="quickBetBtn border-0"
            onClick={() => {
              if (!cart || Object.keys(cart).length === 0) return;
              setShortCutValue();
              updateModalAlert({
                type: 'confirmBet',
                visible: true
              });
            }}
          >
            {t('BET')}
          </Button>
          <Button
            className="quickBetBtn border-0"
            onClick={() => {
              setSelectedItemArray({});
              setShortCutValue();
              updatePresetAmount('');
              resetItemInCart();
            }}
          >
            {t('RESET')}
          </Button>
        </Col>
        {tabType === 'quickSelection' && (
        <table className="w-100 able table-borderless text-center mb-0">
          {renderTable(KuaiJieShortCut.quickSelectPanel)}
        </table>
        )}
      </Col>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickBetPanel);