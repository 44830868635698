import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const HM = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '一中一',
      cycleHeaderTitle: true,
      mainColumnLength: 5,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'YZY1',
          displayName: '1',
          className: 'circle',
        }, {
          code: 'YZY2',
          displayName: '2',
          className: 'circle',
        }, {
          code: 'YZY3',
          displayName: '3',
          className: 'circle',
        }, {
          code: 'YZY4',
          displayName: '4',
          className: 'circle',
        }, {
          code: 'YZY5',
          displayName: '5',
          className: 'circle',
        }, {
          code: 'YZY6',
          displayName: '6',
          className: 'circle',
        }, {
          code: 'YZY7',
          displayName: '7',
          className: 'circle',
        }, {
          code: 'YZY8',
          displayName: '8',
          className: 'circle',
        }, {
          code: 'YZY9',
          displayName: '9',
          className: 'circle',
        }, {
          code: 'YZY10',
          displayName: '10',
          className: 'circle',
        }, {
          code: 'YZY11',
          displayName: '11',
          className: 'circle',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: false,
      tableHeadertitle: ['第一球', '第二球', '第三球', '第四球', '第五球'],
      mainColumnLength: 5,
      subColumnLength: 1,
      subColumnStyle: ['column-1item-style'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'B1QH1',
          displayName: '1',
          className: 'circle',
        },
        {
          code: 'B2QH1',
          displayName: '1',
          className: 'circle',
        },
        {
          code: 'B3QH1',
          displayName: '1',
          className: 'circle',
        },
        {
          code: 'B4QH1',
          displayName: '1',
          className: 'circle',
        },
        {
          code: 'B5QH1',
          displayName: '1',
          className: 'circle',
        },
        {
          code: 'B1QH2',
          displayName: '2',
          className: 'circle',
        },
        {
          code: 'B2QH2',
          displayName: '2',
          className: 'circle',
        },
        {
          code: 'B3QH2',
          displayName: '2',
          className: 'circle',
        },
        {
          code: 'B4QH2',
          displayName: '2',
          className: 'circle',
        },
        {
          code: 'B5QH2',
          displayName: '2',
          className: 'circle',
        },
        {
          code: 'B1QH3',
          displayName: '3',
          className: 'circle',
        },
        {
          code: 'B2QH3',
          displayName: '3',
          className: 'circle',
        },
        {
          code: 'B3QH3',
          displayName: '3',
          className: 'circle',
        },
        {
          code: 'B4QH3',
          displayName: '3',
          className: 'circle',
        },
        {
          code: 'B5QH3',
          displayName: '3',
          className: 'circle',
        },
        {
          code: 'B1QH4',
          displayName: '4',
          className: 'circle',
        },
        {
          code: 'B2QH4',
          displayName: '4',
          className: 'circle',
        },
        {
          code: 'B3QH4',
          displayName: '4',
          className: 'circle',
        },
        {
          code: 'B4QH4',
          displayName: '4',
          className: 'circle',
        },
        {
          code: 'B5QH4',
          displayName: '4',
          className: 'circle',
        },
        {
          code: 'B1QH5',
          displayName: '5',
          className: 'circle',
        },
        {
          code: 'B2QH5',
          displayName: '5',
          className: 'circle',
        },
        {
          code: 'B3QH5',
          displayName: '5',
          className: 'circle',
        },
        {
          code: 'B4QH5',
          displayName: '5',
          className: 'circle',
        },
        {
          code: 'B5QH5',
          displayName: '5',
          className: 'circle',
        },
        {
          code: 'B1QH6',
          displayName: '6',
          className: 'circle',
        },
        {
          code: 'B2QH6',
          displayName: '6',
          className: 'circle',
        },
        {
          code: 'B3QH6',
          displayName: '6',
          className: 'circle',
        },
        {
          code: 'B4QH6',
          displayName: '6',
          className: 'circle',
        },
        {
          code: 'B5QH6',
          displayName: '6',
          className: 'circle',
        },
        {
          code: 'B1QH7',
          displayName: '7',
          className: 'circle',
        },
        {
          code: 'B2QH7',
          displayName: '7',
          className: 'circle',
        },
        {
          code: 'B3QH7',
          displayName: '7',
          className: 'circle',
        },
        {
          code: 'B4QH7',
          displayName: '7',
          className: 'circle',
        },
        {
          code: 'B5QH7',
          displayName: '7',
          className: 'circle',
        },
        {
          code: 'B1QH8',
          displayName: '8',
          className: 'circle',
        },
        {
          code: 'B2QH8',
          displayName: '8',
          className: 'circle',
        },
        {
          code: 'B3QH8',
          displayName: '8',
          className: 'circle',
        },
        {
          code: 'B4QH8',
          displayName: '8',
          className: 'circle',
        },
        {
          code: 'B5QH8',
          displayName: '8',
          className: 'circle',
        },
        {
          code: 'B1QH9',
          displayName: '9',
          className: 'circle',
        },
        {
          code: 'B2QH9',
          displayName: '9',
          className: 'circle',
        },
        {
          code: 'B3QH9',
          displayName: '9',
          className: 'circle',
        },
        {
          code: 'B4QH9',
          displayName: '9',
          className: 'circle',
        },
        {
          code: 'B5QH9',
          displayName: '9',
          className: 'circle',
        },
        {
          code: 'B1QH10',
          displayName: '10',
          className: 'circle',
        },
        {
          code: 'B2QH10',
          displayName: '10',
          className: 'circle',
        },
        {
          code: 'B3QH10',
          displayName: '10',
          className: 'circle',
        },
        {
          code: 'B4QH10',
          displayName: '10',
          className: 'circle',
        },
        {
          code: 'B5QH10',
          displayName: '10',
          className: 'circle',
        },
        {
          code: 'B1QH11',
          displayName: '11',
          className: 'circle',
        },
        {
          code: 'B2QH11',
          displayName: '11',
          className: 'circle',
        },
        {
          code: 'B3QH11',
          displayName: '11',
          className: 'circle',
        },
        {
          code: 'B4QH11',
          displayName: '11',
          className: 'circle',
        },
        {
          code: 'B5QH11',
          displayName: '11',
          className: 'circle',
        },
        {
          code: 'B1LM1',
          displayName: '大',
        }, {
          code: 'B2LM9',
          displayName: '大',
        }, {
          code: 'B3LM17',
          displayName: '大',
        }, {
          code: 'B4LM25',
          displayName: '大',
        }, {
          code: 'B5LM33',
          displayName: '大',
        },
        {
          code: 'B1LM2',
          displayName: '小',
        }, {
          code: 'B2LM10',
          displayName: '小',
        }, {
          code: 'B3LM18',
          displayName: '小',
        }, {
          code: 'B4LM26',
          displayName: '小',
        }, {
          code: 'B5LM34',
          displayName: '小',
        },
        {
          code: 'B1LM3',
          displayName: '单',
        }, {
          code: 'B2LM11',
          displayName: '单',
        }, {
          code: 'B3LM19',
          displayName: '单',
        }, {
          code: 'B4LM27',
          displayName: '单',
        }, {
          code: 'B5LM35',
          displayName: '单',
        },
        {
          code: 'B1LM4',
          displayName: '双',
        }, {
          code: 'B2LM12',
          displayName: '双',
        }, {
          code: 'B3LM20',
          displayName: '双',
        }, {
          code: 'B4LM28',
          displayName: '双',
        }, {
          code: 'B5LM36',
          displayName: '双',
        },
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default HM;