const menuList = [
  {
    label: 'unsettled',
    url: '/user/unsettled',
    visitor: true
  },
  {
    label: 'settled',
    url: '/user/settled',
    visitor: true
  },
  {
    label: 'bet_report',
    url: '/user/report',
    visitor: true
  },
  {
    label: 'result',
    url: '/user/result',
    visitor: true
  },
  {
    label: 'info',
    url: '/user/betlimit'
  },
  {
    label: 'rules',
    url: '/user/lotteryrules',
    visitor: true
  },
  {
    label: 'change_password',
    url: '/user/change-password'
  },
  {
    label: 'suggestion_feedback',
    url: '/user/announcement'
  },
];

export default menuList;