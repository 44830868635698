import React from 'react';

import './styles.scss';

const SubMenuCard = ({ img, title, character, bottom, left }) => (
  <div className="card cardWrapper">
    <img
      src={img}
      className="card-img-top"
      alt="..."
    />
    <div className="card-content rounded-bottom">
      <div className="text-center p-2">
        <h4 className="card-text text-white">{title}</h4>
      </div>
    </div>
    <img
      src={character}
      className="card-img-character"
      style={{
        bottom, left
      }}
      alt="..."
    />
  </div>
);

export default SubMenuCard;