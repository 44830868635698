import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';
import gameItemIconMap from '../../../GameItemIcon';

const HM7 = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '第七球',
      cycleHeaderTitle: false,
      mainColumnLength: 4,
      subColumnLength: 1,
      subColumnStyle: ['column-1item-style'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      emptyItemStyle: ['column-3items-empty-label', 'column-3items-empty-odds', 'column-3items-empty-input'],
      betItemList: ['DisplayOrText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'B7HM1',
          displayName: '1',
          className: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'B7HM6',
          displayName: '6',
          className: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'B7HM11',
          displayName: '11',
          className: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'B7HM16',
          displayName: '16',
          className: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'B7HM2',
          displayName: '2',
          className: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'B7HM7',
          displayName: '7',
          className: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'B7HM12',
          displayName: '12',
          className: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'B7HM17',
          displayName: '17',
          className: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'B7HM3',
          displayName: '3',
          className: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'B7HM8',
          displayName: '8',
          className: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'B7HM13',
          displayName: '13',
          className: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'B7HM18',
          displayName: '18',
          className: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'B7HM4',
          displayName: '4',
          className: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'B7HM9',
          displayName: '9',
          className: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'B7HM14',
          displayName: '14',
          className: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'B7HM19',
          displayName: '19',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'B7HM5',
          displayName: '5',
          className: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'B7HM10',
          displayName: '10',
          className: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'B7HM15',
          displayName: '15',
          className: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'B7HM20',
          displayName: '20',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }, {
          code: 'B7LM21',
          displayName: '大',
        }, {
          code: 'B7LM23',
          displayName: '单',
        }, {
          code: 'B7LM25',
          displayName: '尾大',
        }, {
          code: 'B7LM27',
          displayName: '合单',
        }, {
          code: 'B7LM22',
          displayName: '小',
        }, {
          code: 'B7LM24',
          displayName: '双',
        }, {
          code: 'B7LM26',
          displayName: '尾小',
        }, {
          code: 'B7LM28',
          displayName: '合双',
        }, {
          code: 'B7FW31',
          displayName: '东',
        }, {
          code: 'B7FW32',
          displayName: '南',
        }, {
          code: 'B7FW33',
          displayName: '西',
        }, {
          code: 'B7FW34',
          displayName: '北',
        }, {
          code: 'B7ZFB35',
          displayName: '中',
        }, {
          code: 'B7ZFB36',
          displayName: '发',
        }, {
          code: 'B7ZFB37',
          displayName: '白',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default HM7;