import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';
import PengAndDan from '../../../../components/Mobile/ComponentType/PengAndDan';
import TeMaAndLianMa from '../../../../components/Mobile/ComponentType/TeMaAndLianMa';

const LIANMA = [
  {
    componentType: TeMaAndLianMa,
    data: {
      list: [{
        code: 'LIANMA1',
        displayName: '任选二中二',
        minimumSelection: 2,
        maximumSelection: 8,
        children: [{
          code: 'LIANMA1'
        }]

      }, {
        code: 'LIANMA2',
        displayName: '任选三中三',
        minimumSelection: 3,
        maximumSelection: 8,
        children: [{
          code: 'LIANMA2'
        }]

      }, {
        code: 'LIANMA3',
        displayName: '任选四中四',
        minimumSelection: 4,
        maximumSelection: 8,
        children: [{
          code: 'LIANMA3'
        }]

      }, {
        code: 'LIANMA4',
        displayName: '任选五中五',
        minimumSelection: 5,
        maximumSelection: 8,
        children: [{
          code: 'LIANMA4'
        }]

      }, {
        code: 'LIANMA5',
        displayName: '任选六中五',
        minimumSelection: 6,
        maximumSelection: 8,
        children: [{
          code: 'LIANMA5'
        }]

      }, {
        code: 'LIANMA6',
        displayName: '任选七中五',
        minimumSelection: 7,
        maximumSelection: 8,
        children: [{
          code: 'LIANMA6'
        }]

      }, {
        code: 'LIANMA7',
        displayName: '任选八中五',
        minimumSelection: 8,
        maximumSelection: 8,
        children: [{
          code: 'LIANMA7'
        }]

      }, {
        code: 'LIANMA8',
        displayName: '前二组选',
        minimumSelection: 2,
        maximumSelection: 8,
        children: [{
          code: 'LIANMA8'
        }]

      }, {
        code: 'LIANMA9',
        displayName: '前三组选',
        minimumSelection: 3,
        maximumSelection: 8,
        children: [{
          code: 'LIANMA9'
        }]

      }]
    }
  },
  {
    componentType: PengAndDan,
    data: {}
  },
  {
    componentType: CollapseType,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: '1',
          displayName: '1',
          drawImgClassName: 'circle',
        }, {
          code: '7',
          displayName: '7',
          drawImgClassName: 'circle',
        }, {
          code: '2',
          displayName: '2',
          drawImgClassName: 'circle',
        }, {
          code: '8',
          displayName: '8',
          drawImgClassName: 'circle',
        }, {
          code: '3',
          displayName: '3',
          drawImgClassName: 'circle',
        }, {
          code: '9',
          displayName: '9',
          drawImgClassName: 'circle',
        }, {
          code: '4',
          displayName: '4',
          drawImgClassName: 'circle',
        }, {
          code: '10',
          displayName: '10',
          drawImgClassName: 'circle',
        }, {
          code: '5',
          displayName: '5',
          drawImgClassName: 'circle',
        }, {
          code: '11',
          displayName: '11',
          drawImgClassName: 'circle',
        }, {
          code: '6',
          displayName: '6',
          drawImgClassName: 'circle',
        }
      ]
    }
  }
];

export default LIANMA;