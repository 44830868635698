export const SET_USER_GAMES = 'SET_USER_GAMES';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_PLATFORM = 'SET_PLATFORM';
export const SET_MODAL_ALERT = 'SET_MODAL_ALERT';
export const SET_SUB_TYPE = 'SET_SUB_TYPE';
export const TOGGLE_HORIZONTAL = 'TOGGLE_HORIZONTAL';
export const SET_THEME_COLOR = 'SET_THEME_COLOR';
export const SET_LOTTERY_AND_TEMPLATE = 'SET_LOTTERY_AND_TEMPLATE';
export const TOGGLE_SUB_GAME = 'TOGGLE_SUB_GAME';
export const SET_CAPTCHA_DATA = 'SET_CAPTCHA_DATA';
export const SET_COMPANYINFO = 'SET_COMPANYINFO';
export const SET_PRESET_AMOUNT = 'SET_PRESET_AMOUNT';
export const SET_PRESET_AMOUNT_LIST = 'SET_PRESET_AMOUNT_LIST';
export const RESET_PRESET_AMOUNT_LIST = 'RESET_PRESET_AMOUNT_LIST';
export const SET_GAME_INFO = 'SET_GAME_INFO';
export const SET_COUNT_DOWN_TIMER = 'SET_COUNT_DOWN_TIMER';
export const USER_LOGOUT = 'USER_LOGOUT';
export const INSERT_ITEM_TO_CART = 'INSERT_ITEM_TO_CART';
export const DELETE_ITEM_IN_CART = 'DELETE_ITEM_IN_CART';
export const RESET_ITEM_IN_CART = 'RESET_ITEM_IN_CART';
export const UPDATE_PRESET_AMOUNT = 'UPDATE_PRESET_AMOUNT';
export const SET_GAME_ODDS = 'SET_GAME_ODDS';
export const UPDATE_ENTIRE_CART = 'UPDATE_ENTIRE_CART';
export const SET_CURRENT_BETS = 'SET_CURRENT_BETS';
export const APPEND_CURRENT_BETS = 'APPEND_CURRENT_BETS';
export const RESET_CURRENT_BETS = 'RESET_CURRENT_BETS';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_BET_REPORT_LIST = 'SET_BET_REPORT_LIST';
export const SET_UNSETTLED_BET_LIST = 'SET_UNSETTLED_BET_LIST';
export const SET_SETTLED_BET_LIST = 'SET_SETTLED_BET_LIST';
export const SET_BET_LIMIT = 'SET_BET_LIMIT';
export const SET_BALANCE_LOG = 'SET_BALANCE_LOG';
export const SET_LOTTERY_RULES_DATA = 'SET_LOTTERY_RULES_DATA';
export const SET_BET_RESULT_LIST = 'SET_BET_RESULT_LIST';
export const SET_SECTION_SELECTION = 'SET_SECTION_SELECTION';
export const SET_SUBGAME_LIST = 'SET_SUBGAME_LIST';
export const SET_SELECTED_SUBGAME_LIST = 'SET_SELECTED_SUBGAME_LIST';
export const SET_SECURITY_INFO = 'SET_SECURITY_INFO';
export const SET_TOP_UP_OPTIONS = 'SET_TOP_UP_OPTIONS';
export const SET_BET_TYPE_SELECTION = 'SET_BET_TYPE_SELECTION';
export const SET_THIRD_PARTY_BET_RECORDS = 'SET_THIRD_PARTY_BET_RECORDS';
export const SET_SELECTED_DAN = 'SET_SELECTED_DAN';
export const SET_CURRENT_SELECTED = 'SET_CURRENT_SELECTED';
export const SET_TRANSACTION_RECORDS = 'SET_TRANSACTION_RECORDS';
export const SET_THIRD_PARTY_GAMES_OPTIONS = 'SET_THIRD_PARTY_GAMES_OPTIONS';
export const SET_THIRD_PARTY_GAME_AMOUNT = 'SET_THIRD_PARTY_GAME_AMOUNT';
export const SET_BET_DETAIL_ITEM = 'SET_BET_DETAIL_ITEM';
export const SET_WITHDRAWAL_INFO = 'SET_WITHDRAWAL_INFO';
export const SET_USER_NOTIFICATIONS = 'SET_USER_NOTIFICATIONS';
export const SET_NEWS_LIST = 'SET_NEWS_LIST';
export const SET_LATEST_BET_RESULT_LIST = 'SET_LATEST_BET_RESULT_LIST';
export const SET_CHANGLONG_DATA = 'SET_CHANGLONG_DATA';
export const SET_TREND_PREFERENCE = 'SET_TREND_PREFERENCE';
export const SET_LENG_RE_RESULT = 'SET_LENG_RE_RESULT';
export const SET_YI_LOU_COUNT = 'SET_YI_LOU_COUNT';
export const TOGGLE_YI_LOU_BUTTON = 'TOGGLE_YI_LOU_BUTTON';
export const TOGGLE_LENG_RE_BUTTON = 'TOGGLE_LENG_RE_BUTTON';
export const SET_CUSTOM_CHANGLONG = 'SET_CUSTOM_CHANGLONG';
export const SET_LATEST_ANNOUNCEMENT = 'SET_LATEST_ANNOUNCEMENT';
export const SET_PRESET_AMOUNT_ENABLED = 'SET_PRESET_AMOUNT_ENABLED';
export const TOGGLE_QUICK_BET_BUTTON = 'TOGGLE_QUICK_BET_BUTTON';
export const SET_ACTIVE_THIRD_PARTY_GAME_URL = 'SET_ACTIVE_THIRD_PARTY_GAME_URL';
export const SET_PRESET_DEPOSIT_AMOUNT = 'SET_PRESET_DEPOSIT_AMOUNT';
export const TOGGLE_GAME_MENU = 'TOGGLE_GAME_MENU';
export const TOGGLE_GAME_BALANCE_PANEL = 'TOGGLE_GAME_BALANCE_PANEL';
export const SET_TEMP_GIFT_INFO = 'SET_TEMP_GIFT_INFO';
export const SET_TEMP_PERSONAL_INFOMATION = 'SET_TEMP_PERSONAL_INFOMATION';
export const SET_TRANSACTION_DETAILS = 'SET_TRANSACTION_DETAILS';
export const SET_CHECK_LATENCY = 'SET_CHECK_LATENCY';
export const SET_PING_RESULT = 'SET_PING_RESULT';
export const SET_INIT_DATA = 'SET_INIT_DATA';
export const SET_ACTIVE_OPTION = 'SET_ACTIVE_OPTION';
export const LATEST_RESULT = 'LATEST_RESULT';
export const SET_CURRENT_PANKOU = 'SET_CURRENT_PANKOU';
export const SET_TOKEN = 'SET_TOKEN';