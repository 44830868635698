import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const HM1 = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '第一球',
      cycleHeaderTitle: true,
      mainColumnLength: 4,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-style-1th', 'column-3items-style-2th', 'column-3items-style-3th'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'B1QH1',
          displayName: '1',
          className: 'circle circleRed',
        }, {
          code: 'B1QH2',
          displayName: '2',
          className: 'circle circleBlue',
        }, {
          code: 'B1QH3',
          displayName: '3',
          className: 'circle circleGreen',
        }, {
          code: 'B1QH4',
          displayName: '4',
          className: 'circle circleRed',
        }, {
          code: 'B1QH5',
          displayName: '5',
          className: 'circle circleBlue',
        }, {
          code: 'B1QH6',
          displayName: '6',
          className: 'circle circleGreen',
        }, {
          code: 'B1QH7',
          displayName: '7',
          className: 'circle circleRed',
        }, {
          code: 'B1QH8',
          displayName: '8',
          className: 'circle circleBlue',
        }, {
          code: 'B1QH9',
          displayName: '9',
          className: 'circle circleGreen',
        }, {
          code: 'B1QH10',
          displayName: '10',
          className: 'circle circleRed',
        }, {
          code: 'B1QH11',
          displayName: '11',
          className: 'circle circleBlue',
        }, {
          code: 'B1QH12',
          displayName: '12',
          className: 'circle circleGreen',
        }, {
          code: 'B1QH13',
          displayName: '13',
          className: 'circle circleRed',
        }, {
          code: 'B1QH14',
          displayName: '14',
          className: 'circle circleBlue',
        }, {
          code: 'B1QH15',
          displayName: '15',
          className: 'circle circleGreen',
        }, {
          code: 'B1QH16',
          displayName: '16',
          className: 'circle circleRed',
        }, {
          code: 'B1QH17',
          displayName: '17',
          className: 'circle circleBlue',
        }, {
          code: 'B1QH18',
          displayName: '18',
          className: 'circle circleGreen',
        }, {
          code: 'B1QH19',
          displayName: '19',
          className: 'circle circleRed',
        }, {
          code: 'B1QH20',
          displayName: '20',
          className: 'circle circleBlue',
        }, {
          code: 'B1QH21',
          displayName: '21',
          className: 'circle circleGreen',
        }, {
          code: 'B1QH22',
          displayName: '红',
          className: 'fillRed',
        }, {
          code: 'B1QH23',
          displayName: '绿',
          className: 'fillGreen',
        }, {
          code: 'B1QH24',
          displayName: '蓝',
          className: 'fillBlue',
        }, {
          code: 'B1QH25',
          displayName: '福',
          className: 'label',
        }, {
          code: 'B1QH26',
          displayName: '禄',
          className: 'label',
        }, {
          code: 'B1QH27',
          displayName: '寿',
          className: 'label',
        }, {
          code: 'B1QH28',
          displayName: '喜',
          className: 'label',
        }, {
          code: 'B1LM1',
          displayName: '大',
          className: 'label',
        }, {
          code: 'B1LM2',
          displayName: '小',
          className: 'label',
        }, {
          code: 'B1LM3',
          displayName: '单',
          className: 'label',
        }, {
          code: 'B1LM4',
          displayName: '双',
          className: 'label',
        }, {
          code: 'B1LM5',
          displayName: '尾大',
          className: 'label',
        }, {
          code: 'B1LM6',
          displayName: '尾小',
          className: 'label',
        }, {
          code: 'B1LM7',
          displayName: '合单',
          className: 'label',
        }, {
          code: 'B1LM8',
          displayName: '合双',
          className: 'label',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default HM1;