import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';
import gameItemIconMap from '../../../GameItemIcon';

const HM2 = [
  {
    componentType: CollapseType,
    header: '第二球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B2M1',
          displayName: '1',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'B2M6',
          displayName: '6',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'B2M11',
          displayName: '11',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'B2M16',
          displayName: '16',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'B2M2',
          displayName: '2',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'B2M7',
          displayName: '7',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'B2M12',
          displayName: '12',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'B2M17',
          displayName: '17',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'B2M3',
          displayName: '3',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'B2M8',
          displayName: '8',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'B2M13',
          displayName: '13',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'B2M18',
          displayName: '18',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'B2M4',
          displayName: '4',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'B2M9',
          displayName: '9',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'B2M14',
          displayName: '14',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'B2M19',
          displayName: '19',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'B2M5',
          displayName: '5',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'B2M10',
          displayName: '10',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'B2M15',
          displayName: '15',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'B2M20',
          displayName: '20',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '两面',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B2LM21',
          displayName: '大',
        }, {
          code: 'B2LM23',
          displayName: '单',
        }, {
          code: 'B2LM25',
          displayName: '尾大',
        }, {
          code: 'B2LM27',
          displayName: '合单',
        }, {
          code: 'B2LM22',
          displayName: '小',
        }, {
          code: 'B2LM24',
          displayName: '双',
        }, {
          code: 'B2LM26',
          displayName: '尾小',
        }, {
          code: 'B2LM28',
          displayName: '合双',
        }, {
          code: 'B2FW31',
          displayName: '东',
        }, {
          code: 'B2FW32',
          displayName: '南',
        }, {
          code: 'B2FW33',
          displayName: '西',
        }, {
          code: 'B2FW34',
          displayName: '北',
        }, {
          code: 'B2ZFB35',
          displayName: '中',
        }, {
          code: 'B2ZFB36',
          displayName: '发',
        }, {
          code: 'B2ZFB37',
          displayName: '白',
        }, {
          code: 'B2LM29',
          displayName: '龙',
        }, {
          code: 'B2LM30',
          displayName: '虎',
        }
      ]
    }
  }
];

export default HM2;