import React, { useEffect, useState } from 'react';

import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CurrentDrawPanel, ProbabilityResultPanel } from '../Web';
import { ReactComponent as CrownIcon } from '../../assets/image/icon_crown.svg';
import './styles.scss';

const mapStateToProps = state => ({
  selectedSubType: state.gameReducers.selectedSubType,
  lotteryType: state.gameReducers.lotteryType,
  templateCode: state.gameReducers.template,
  isHorizontal: state.appReducers.isHorizontal,
  userInfo: state.memberReducers.userInfo,
  gameInfo: state.placeBetReducers.gameInfo,
  lengReResult: state.resultHistoryReducers.lengReResult,
});

const GameBetPanel = ({
  GameVariable,
  selectedSubType,
  lotteryType,
  templateCode,
  isHorizontal,
  userInfo,
  gameInfo,
  lengReResult
}) => {
  const [_tabId, setTabId] = useState(selectedSubType);
  const [_gameInfo, setGameInfo] = useState();
  const [_templateCode, setTemplateCode] = useState(templateCode);
  const [_lotteryType, setLotteryType] = useState();
  const includeProbability = ['SSC', 'PK10'];
  const { t } = useTranslation();

  useEffect(() => {
    if (JSON.stringify(gameInfo) !== JSON.stringify(_gameInfo)) {
      setGameInfo(gameInfo);
    }
  }, [gameInfo]);

  useEffect(() => {
    if (JSON.stringify(lotteryType) !== JSON.stringify(_lotteryType)) {
      setLotteryType(lotteryType);
    }
    if (JSON.stringify(templateCode) !== JSON.stringify(_templateCode)) {
      setTemplateCode(templateCode);
    }
    if (selectedSubType !== _tabId) {
      setTabId(selectedSubType);
    }
  }, [lotteryType, templateCode, selectedSubType]);

  const renderBetContent = () => {
    const result = [];
    const currentTab = GameVariable[`CJ_${_templateCode}`] && GameVariable[`CJ_${_templateCode}`].children.find(x => x.tabId === _tabId);
    const { generateLengReObj } = GameVariable[`CJ_${_templateCode}`];
    let _currentLengReResult;
    if (lengReResult && generateLengReObj) {
      const { list } = lengReResult || {};
      if (list) {
        _currentLengReResult = generateLengReObj(list);
      }
    }

    const { children } = currentTab || {};
    if (children) {
      children.forEach((item, index) => {
        const {
          componentType,
          data
        } = item || {};
        const Template = componentType;
        result.push(<Template _currentLengReResult={_currentLengReResult} gameInfo={_gameInfo} key={`template_${index.toString()}`} {...data} />);
      });
    }
    return result;
  };

  const renderProbabilityPanel = () => {
    const { dropdownList, sideBetNavList, result } = GameVariable[`CJ_${_templateCode}`].probabilityResult || {};
    return (<ProbabilityResultPanel dropdownList={dropdownList} sideBetNavList={sideBetNavList} result={result} />);
  };

  return (
    <Col md="12" className="betPanelWrapper mb-3 px-0">
      {
        isHorizontal && (
          <Col md="12" className="d-flex betPanelHeader p-0">
            <Col md="4" className="subGameWrapper">
              <CrownIcon />
              {`${t('TODAY_WINNING')}: ${(userInfo && userInfo.winLostAmount) || 0}`}
            </Col>
            <Col md="8" className="d-flex justify-content-end align-items-center">
              <CurrentDrawPanel GameVariable={GameVariable} />
            </Col>
          </Col>
        )
      }
      <Col md="12" className={`d-flex flex-column betPanelContent ${_templateCode === 'HK6' && `${_lotteryType?.item}WaterMark`} ${!isHorizontal && 'isHorizontal'}`}>
        {renderBetContent()}
        {includeProbability.includes(_templateCode) ? renderProbabilityPanel() : ''}
      </Col>
    </Col>
  );
};

export default connect(mapStateToProps)(GameBetPanel);