import { ProbabilitySelectionPanel } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const LMPL = [
  {
    componentType: ProbabilitySelectionPanel,
  },
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      tableTitle: ['形式', '特码', '正1码', '正2码', '正3码'],
      mainColumnLength: 4,
      betItemStyle: ['column-2items-odds FJ_LMPL_Cell', 'column-2items-input'],
      betItemList: ['Odds', 'Input'],
      betItemColumn: 2,
      list: [
        {
          code: 'TMDS_D',
          displayName: '单',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '单',
        }, {
          code: 'ZM1DS_D',
          displayName: '单',
        }, {
          code: 'ZM2DS_D',
          displayName: '单',
        }, {
          code: 'ZM3DS_D',
          displayName: '单',
        }, {
          code: 'TMDS_S',
          displayName: '双',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '双',
        }, {
          code: 'ZM1DS_S',
          displayName: '双',
        }, {
          code: 'ZM2DS_S',
          displayName: '双',
        }, {
          code: 'ZM3DS_S',
          displayName: '双',
        },
        {
          code: 'TMDX_D',
          displayName: '大',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '大',
        }, {
          code: 'ZM1DX_D',
          displayName: '大',
        }, {
          code: 'ZM2DX_D',
          displayName: '大',
        }, {
          code: 'ZM3DX_D',
          displayName: '大',
        }, {
          code: 'TMDX_X',
          displayName: '小',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '小',
        }, {
          code: 'ZM1DX_X',
          displayName: '小',
        }, {
          code: 'ZM2DX_X',
          displayName: '小',
        }, {
          code: 'ZM3DX_X',
          displayName: '小',
        }, //
        {
          code: 'TMHDS_D',
          displayName: '合单',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '合单',
        }, {
          code: 'ZM1HDS_D',
          displayName: '合单',
        }, {
          code: 'ZM2HDS_D',
          displayName: '合单',
        }, {
          code: 'ZM3HDS_D',
          displayName: '合单',
        }, {
          code: 'TMHDS_S',
          displayName: '合双',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '合双',
        }, {
          code: 'ZM1HDS_S',
          displayName: '合双',
        }, {
          code: 'ZM2HDS_S',
          displayName: '合双',
        }, {
          code: 'ZM3HDS_S',
          displayName: '合双',
        },
        {
          code: 'TMHDX_D',
          displayName: '合大',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '合大',
        }, {
          code: 'ZM1HDX_D',
          displayName: '合大',
        }, {
          code: 'ZM2HDX_D',
          displayName: '合大',
        }, {
          code: 'ZM3HDX_D',
          displayName: '合大',
        }, {
          code: 'TMHDX_X',
          displayName: '合小',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '合小',
        }, {
          code: 'ZM1HDX_X',
          displayName: '合小',
        }, {
          code: 'ZM2HDX_X',
          displayName: '合小',
        }, {
          code: 'ZM3HDX_X',
          displayName: '合小',
        },
        {
          code: 'TMWDX_D',
          displayName: '大尾',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '大尾',
        }, {
          code: 'ZM1WDX_D',
          displayName: '大尾',
        }, {
          code: 'ZM2WDX_D',
          displayName: '大尾',
        }, {
          code: 'ZM3WDX_D',
          displayName: '大尾',
        }, {
          code: 'TMWDX_X',
          displayName: '小尾',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '小尾',
        }, {
          code: 'ZM1WDX_X',
          displayName: '小尾',
        }, {
          code: 'ZM2WDX_X',
          displayName: '小尾',
        }, {
          code: 'ZM3WDX_X',
          displayName: '小尾',
        },
        {
          code: 'TMSB_R',
          displayName: '红',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '红',
        }, {
          code: 'ZM1SB_R',
          displayName: '红',
        }, {
          code: 'ZM2SB_R',
          displayName: '红',
        }, {
          code: 'ZM3SB_R',
          displayName: '红',
        }, {
          code: 'TMSB_B',
          displayName: '蓝',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '蓝',
        }, {
          code: 'ZM1SB_B',
          displayName: '蓝',
        }, {
          code: 'ZM2SB_B',
          displayName: '蓝',
        }, {
          code: 'ZM3SB_B',
          displayName: '蓝',
        },
        {
          code: 'TMSB_G',
          displayName: '绿',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '绿',
        }, {
          code: 'ZM1SB_G',
          displayName: '绿',
        }, {
          code: 'ZM2SB_G',
          displayName: '绿',
        }, {
          code: 'ZM3SB_G',
          displayName: '绿',
        },
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: ['形式', '正4码', '正5码', '正6码', '正7码'],
      mainColumnLength: 4,
      betItemStyle: ['column-2items-odds FJ_LMPL_Cell', 'column-2items-input'],
      betItemList: ['Odds', 'Input'],
      betItemColumn: 2,
      list: [
        {
          code: 'ZM4DS_D',
          displayName: '单',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '单',
        }, {
          code: 'ZM5DS_D',
          displayName: '单',
        }, {
          code: 'ZM6DS_D',
          displayName: '单',
        }, {
          code: 'ZM7DS_D',
          displayName: '单',
        }, {
          code: 'ZM4DS_S',
          displayName: '双',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '双',
        }, {
          code: 'ZM5DS_S',
          displayName: '双',
        }, {
          code: 'ZM6DS_S',
          displayName: '双',
        }, {
          code: 'ZM7DS_S',
          displayName: '双',
        },
        {
          code: 'ZM4DX_D',
          displayName: '大',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '大',
        }, {
          code: 'ZM5DX_D',
          displayName: '大',
        }, {
          code: 'ZM6DX_D',
          displayName: '大',
        }, {
          code: 'ZM7DX_D',
          displayName: '大',
        }, {
          code: 'ZM4DX_X',
          displayName: '小',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '小',
        }, {
          code: 'ZM5DX_X',
          displayName: '小',
        }, {
          code: 'ZM6DX_X',
          displayName: '小',
        }, {
          code: 'ZM7DX_X',
          displayName: '小',
        }, //
        {
          code: 'ZM4HDS_D',
          displayName: '合单',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '合单',
        }, {
          code: 'ZM5HDS_D',
          displayName: '合单',
        }, {
          code: 'ZM6HDS_D',
          displayName: '合单',
        }, {
          code: 'ZM7HDS_D',
          displayName: '合单',
        }, {
          code: 'ZM4HDS_S',
          displayName: '合双',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '合双',
        }, {
          code: 'ZM5HDS_S',
          displayName: '合双',
        }, {
          code: 'ZM6HDS_S',
          displayName: '合双',
        }, {
          code: 'ZM7HDS_S',
          displayName: '合双',
        },
        {
          code: 'ZM4HDX_D',
          displayName: '合大',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '合大',
        }, {
          code: 'ZM5HDX_D',
          displayName: '合大',
        }, {
          code: 'ZM6HDX_D',
          displayName: '合大',
        }, {
          code: 'ZM7HDX_D',
          displayName: '合大',
        }, {
          code: 'ZM4HDX_X',
          displayName: '合小',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '合小',
        }, {
          code: 'ZM5HDX_X',
          displayName: '合小',
        }, {
          code: 'ZM6HDX_X',
          displayName: '合小',
        }, {
          code: 'ZM7HDX_X',
          displayName: '合小',
        },
        {
          code: 'ZM4WDX_D',
          displayName: '大尾',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '大尾',
        }, {
          code: 'ZM5WDX_D',
          displayName: '大尾',
        }, {
          code: 'ZM6WDX_D',
          displayName: '大尾',
        }, {
          code: 'ZM7WDX_D',
          displayName: '大尾',
        }, {
          code: 'ZM4WDX_X',
          displayName: '小尾',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '小尾',
        }, {
          code: 'ZM5WDX_X',
          displayName: '小尾',
        }, {
          code: 'ZM6WDX_X',
          displayName: '小尾',
        }, {
          code: 'ZM7WDX_X',
          displayName: '小尾',
        },
        {
          code: 'ZM4SB_R',
          displayName: '红',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '红',
        }, {
          code: 'ZM5SB_R',
          displayName: '红',
        }, {
          code: 'ZM6SB_R',
          displayName: '红',
        }, {
          code: 'ZM7SB_R',
          displayName: '红',
        }, {
          code: 'ZM4SB_B',
          displayName: '蓝',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '蓝',
        }, {
          code: 'ZM5SB_B',
          displayName: '蓝',
        }, {
          code: 'ZM6SB_B',
          displayName: '蓝',
        }, {
          code: 'ZM7SB_B',
          displayName: '蓝',
        },
        {
          code: 'ZM4SB_G',
          displayName: '绿',
          rowTitleClassName: 'FJ_LMPL',
          rowTitle: '绿',
        }, {
          code: 'ZM5SB_G',
          displayName: '绿',
        }, {
          code: 'ZM6SB_G',
          displayName: '绿',
        }, {
          code: 'ZM7SB_G',
          displayName: '绿',
        },
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default LMPL;