import { RESET_PRESET_AMOUNT_LIST, SET_BET_TYPE_SELECTION, SET_CURRENT_SELECTED, SET_LOTTERY_AND_TEMPLATE, SET_PRESET_AMOUNT, SET_PRESET_AMOUNT_ENABLED, SET_PRESET_AMOUNT_LIST, SET_SECTION_SELECTION, SET_SELECTED_DAN, SET_SELECTED_SUBGAME_LIST, SET_SUB_TYPE } from '../../actions/actionTypes';

const initialState = {
  selectedSubType: '',
  template: '',
  lotteryType: '',
  selectedSubGameList: [],
  sectionSelection: undefined,
  betTypeSelection: undefined,
  presetEnabled: false,
  presetAmount: 0,
  presetAmountList: [5, 10, 20, 50, 100, 500],
  selectedDan: [],
  currentSelected: [],
};

const gameReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_SELECTED:
      return {
        ...state, currentSelected: action.value
      };

    case SET_SELECTED_DAN:
      return {
        ...state, selectedDan: action.value
      };

    case SET_BET_TYPE_SELECTION:
      return {
        ...state, betTypeSelection: action.value
      };

    case SET_SELECTED_SUBGAME_LIST:
      return {
        ...state, selectedSubGameList: action.value
      };

    case SET_SECTION_SELECTION:
      return {
        ...state, sectionSelection: action.value
      };

    case SET_SUB_TYPE:
      return {
        ...state, selectedSubType: action.value
      };

    case SET_LOTTERY_AND_TEMPLATE:
      return {
        ...state, template: action.value.template, lotteryType: action.value.lotteryType, selectedSubType: action.value.selectedSubType
      };

    case SET_PRESET_AMOUNT_ENABLED:
      return {
        ...state, presetEnabled: action.value
      };

    case SET_PRESET_AMOUNT:
      return {
        ...state, presetAmount: action.value
      };

    case SET_PRESET_AMOUNT_LIST:
      return {
        ...state, presetAmountList: action.value
      };

    case RESET_PRESET_AMOUNT_LIST:
      return {
        ...state, presetAmountList: initialState.presetAmountList
      };

    default:
      return state;
  }
};

export default gameReducers;