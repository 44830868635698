import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const TM = [
  {
    componentType: CollapseType,
    header: '特码',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'TM1',
          displayName: '01',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TM2',
          displayName: '02',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TM3',
          displayName: '03',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TM4',
          displayName: '04',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TM5',
          displayName: '05',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TM6',
          displayName: '06',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TM7',
          displayName: '07',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TM8',
          displayName: '08',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TM9',
          displayName: '09',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TM10',
          displayName: '10',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TM11',
          displayName: '11',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TM12',
          displayName: '12',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TM13',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TM14',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TM15',
          displayName: '15',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TM16',
          displayName: '16',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TM17',
          displayName: '17',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TM18',
          displayName: '18',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TM19',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TM20',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TM21',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TM22',
          displayName: '22',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TM23',
          displayName: '23',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TM24',
          displayName: '24',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TM25',
          displayName: '25',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TM26',
          displayName: '26',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TM27',
          displayName: '27',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TM28',
          displayName: '28',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TM29',
          displayName: '29',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TM30',
          displayName: '30',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TM31',
          displayName: '31',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TM32',
          displayName: '32',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TM33',
          displayName: '33',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TM34',
          displayName: '34',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TM35',
          displayName: '35',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TM36',
          displayName: '36',
          drawImgClassName: 'circle circleBlue'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '两面',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper PK10ItemWrapperGY',
      list: [
        {
          code: 'TMDS_D',
          displayName: '单'
        },
        {
          code: 'TMDS_S',
          displayName: '双'
        },
        {
          code: 'TMDX_D',
          displayName: '大'
        },
        {
          code: 'TMDX_X',
          displayName: '小'
        },
        {
          code: 'TMHDS_D',
          displayName: '合单'
        },
        {
          code: 'TMHDS_S',
          displayName: '合双'
        },
        {
          code: 'TMHDX_D',
          displayName: '合大'
        },
        {
          code: 'TMHDX_X',
          displayName: '合小'
        },
        {
          code: 'TMWDX_D',
          displayName: '大尾'
        },
        {
          code: 'TMWDX_X',
          displayName: '小尾'
        },
        {
          code: 'TMSB_R',
          displayName: '红'
        },
        {
          code: 'TMSB_B',
          displayName: '蓝'
        },
        {
          code: 'TMSB_G',
          displayName: '绿'
        }
      ]
    }
  },
];

export default TM;