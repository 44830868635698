import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import './styles.scss';

const Pagination = ({ onClick, totalPage }) => {
  const { t } = useTranslation();
  const [_currentPage, setCurrentPage] = useState(1);

  return (
    <div className="pagination d-flex justify-content-center my-2">
      <span onClick={() => {
        if (_currentPage === 1) return;
        onClick(1);
        setCurrentPage(1);
      }}
      >
        {t('HOME_PAGE')}
      </span>
      <span onClick={() => {
        if (_currentPage === 1) return;
        onClick(_currentPage - 1 <= 1 ? 1 : _currentPage - 1);
        setCurrentPage(_currentPage - 1 <= 1 ? 1 : _currentPage - 1);
      }}
      >
        {t('PREVIOUS_PAGE')}
      </span>
      {
        new Array(totalPage || 1).fill('').map((item, index) => (
          <span
            onClick={() => {
              onClick(index + 1);
              setCurrentPage(index + 1);
            }}
            className={`${(_currentPage === (index + 1)) && 'paginationActive'}`}
          >
            {index + 1}
          </span>
        ))
      }
      <span onClick={() => {
        if (_currentPage >= totalPage) return;
        onClick(_currentPage + 1 >= totalPage ? totalPage : _currentPage + 1);
        setCurrentPage(_currentPage + 1 >= totalPage ? totalPage : _currentPage + 1);
      }}
      >
        {t('NEXT_PAGE')}
      </span>

      <span onClick={() => {
        if (_currentPage >= totalPage) return;
        onClick(totalPage);
        setCurrentPage(totalPage);
      }}
      >
        {t('LAST_PAGE')}
      </span>
    </div>
  );
};

export default Pagination;