import BZ from './BZ';
// import L3Z2 from './L3Z2';
// import L7T4 from './L7T4';
// import L7Z4 from './L7Z4';
import LIANGMIAN from './LIANGMIAN';
import LIUXIAO from './LIUXIAO';
import LM from './LM';
import TEMA from './TEMA';
// import TUOTOU from './TUOTOU';
import ZHENGMA from './ZHENGMA';
import ZTM from './ZTM';

const F36X7 = {
  children: [
    {
      tabId: 'LIANGMIAN',
      label: '两面',
      children: LIANGMIAN.LIANGMIAN,
      settingCode: 'TMLM,ZMLM'
    },
    {
      tabId: 'TEMA',
      label: '特码',
      children: TEMA.TEMA,
      settingCode: 'TM,TMLM'
    },
    {
      tabId: 'ZHENGMA',
      label: '正码',
      children: ZHENGMA.ZHENGMA,
      settingCode: 'ZM,ZFLM'
    }, {
      tabId: 'ZTM',
      label: '正特码',
      children: ZTM.ZTM,
      settingCode: 'ZTM'
    },
    {
      tabId: 'LIUXIAO',
      label: '六肖',
      children: LIUXIAO.LIUXIAO,
      settingCode: 'LX'
    },
    {
      tabId: 'BZ',
      label: '不中',
      children: BZ.BZ,
      settingCode: 'BZ'
    },
    {
      tabId: 'LM',
      label: '连码',
      children: LM.LM,
      settingCode: '2QZ,1ZB,3QZ,2ZB,4QZ,3ZB,3ZT,4ZT'
    },
    // {
    //   tabId: 'TUOTOU',
    //   label: '拖头',
    //   children: TUOTOU.TUOTOU,
    // },
    // {
    //   tabId: 'L3Z2',
    //   label: '三中二',
    //   children: L3Z2.L3Z2,
    // },
    // {
    //   tabId: 'L7Z4',
    //   label: '七中四',
    //   children: L7Z4.L7Z4,
    // },
    // {
    //   tabId: 'L7T4',
    //   label: '七拖四',
    //   children: L7T4.L7T4,
    // },
  ],
  results: {
    header: [{
      value: '开奖结果'
    }],
    // TabList: [{
    //   code: 'NUMBER',
    //   renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
    //     value: item, className: ''
    //   }))
    // },
    // {
    //   code: 'BIG_SMALL',
    //   renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
    //     value: item <= 5 ? '小' : '大', className: ''
    //   }))
    // },
    // {
    //   code: 'ODD_EVEN',
    //   renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
    //     value: item % 2 ? '单' : '双', className: ''
    //   }))
    // }],
    defaultFunc: () => {}
  }
};

export default F36X7;