import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import PengAndDan from '../../../../components/Mobile/ComponentType/PengAndDan';
import TeMaAndLianMa from '../../../../components/Mobile/ComponentType/TeMaAndLianMa';

import OptionItem from '../../../../components/Mobile/OptionItem';
import { renderHeXiaoBalls } from '../../../utils';

const LX = [
  {
    componentType: TeMaAndLianMa,
    data: {
      list: [
        {
          code: 'L2LX',
          displayName: '二肖',
          minimumSelection: 2,
          maximumSelection: 6,
          className: 'cellWrapper',
          danList: ['1'],
        }, {
          code: 'L3LX',
          displayName: '三肖',
          minimumSelection: 3,
          maximumSelection: 6,
          className: 'cellWrapper',
          danList: ['1', '2'],
        }, {
          code: 'L4LX',
          displayName: '四肖',
          minimumSelection: 4,
          maximumSelection: 7,
          className: 'cellWrapper',
          danList: ['1', '2', '3'],
        }, {
          code: 'L5LX',
          displayName: '五肖',
          minimumSelection: 5,
          maximumSelection: 8,
          className: 'cellWrapper',
          danList: ['1', '2', '3', '4'],
        }, {
          code: 'L2LXBZ',
          displayName: '二肖不中',
          minimumSelection: 2,
          maximumSelection: 6,
          className: 'cellWrapper',
          danList: ['1'],
        }, {
          code: 'L3LXBZ',
          displayName: '三肖不中',
          minimumSelection: 3,
          maximumSelection: 6,
          className: 'cellWrapper',
          danList: ['1', '2'],
        }, {
          code: 'L4LXBZ',
          displayName: '四肖不中',
          minimumSelection: 4,
          maximumSelection: 7,
          className: 'cellWrapper',
          danList: ['1', '2', '3'],
        }, {
          code: 'L5LXBZ',
          displayName: '五肖不中',
          minimumSelection: 5,
          maximumSelection: 8,
          className: 'cellWrapper',
          danList: ['1', '2', '3', '4'],
        }
      ]
    }
  },
  {
    componentType: PengAndDan,
    data: {}
  },
  {
    componentType: CollapseType,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper HK6TeXiao',
      list: [{
        code: '1',
        displayName: '鼠',
        generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
        generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
      }, {
        code: '2',
        displayName: '牛',
        generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
        generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
      }, {
        code: '3',
        displayName: '虎',
        generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
        generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
      }, {
        code: '4',
        displayName: '兔',
        generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
        generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
      }, {
        code: '5',
        displayName: '龙',
        generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
        generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
      }, {
        code: '6',
        displayName: '蛇',
        generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
        generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
      }, {
        code: '7',
        displayName: '马',
        generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
        generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
      }, {
        code: '8',
        displayName: '羊',
        generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
        generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
      }, {
        code: '9',
        displayName: '猴',
        generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
        generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
      }, {
        code: '10',
        displayName: '鸡',
        generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
        generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
      }, {
        code: '11',
        displayName: '狗',
        generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
        generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
      }, {
        code: '12',
        displayName: '猪',
        generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
        generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
      }]
    }
  }
];

export default LX;