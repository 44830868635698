import React, { useState } from 'react';

import { Tabs } from 'element-react';
import { connect } from 'react-redux';

import Banner4 from '../../../../../assets/image/home/banner_4.jpg';

import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
});

const CollaborateContent = () => {
  const [selectedTopGame, setSelectedTopGame] = useState('agentCollaborate');

  return (
    <>
      <div className="CollaborateBannerWrapper my-4">
        <div><img className="bannerImage" src={Banner4} alt="banner" /></div>
      </div>
      <div className="CollaborateContentWrapper">
        <div className="topGameWrapper">
          <Tabs
            type="border-card"
            activeName={selectedTopGame}
            onTabClick={tab => {
              setSelectedTopGame(tab.props.name);
            }}
          >
            <Tabs.Pane label="代理合作" name="agentCollaborate">
              <div className="w-100">
                <div className="mainTitleStyle">代理合作</div>
                <h6>合作伙伴拥有自己的账户，兼容所有设备随时登录查账，合作共赢是我们长期努力的方向。</h6>
                <div className="d-flex w-100">
                  <div className="w-100">
                    <div className="secondTitleStyle">合作优势：</div>
                    <h6>1.代理免流水要求，返水日结！</h6>
                    <h6>2.ABCD四个会员盘口自由选择开放！</h6>
                    <h6>3.代理扶持系统，尽力配合每一个代理发展！</h6>
                    <h6>4.无限级代理制，让你开发市场不再受等级上的限制！</h6>
                    <h6>5.公司一手放线，最高的占成条件，占成可自由设置！</h6>
                    <h6>6.不合适您推广可以按您要求设定合适推广的盘口！</h6>
                  </div>
                </div>
              </div>
            </Tabs.Pane>
            <Tabs.Pane label="代理协议" name="agentagreement">
              <div className="w-100">
                <div className="mainTitleStyle">代理协议</div>
                <h6>合作伙伴拥有自己的账户，兼容所有设备随时登录查账，合作共赢是我们长期努力的方向。</h6>

                <div className="secondTitleStyle">星辉科技对代理联盟的权利与义务</div>
                <h6>1.星辉科技的客服部会登记联盟的会员并会观察他们的投注状况。联盟及会员必须同意并遵守星辉科技的会员条例，政策及操作程序。星辉科技保留拒绝或冻结联盟/会员账户权利。</h6>
                <h6>2.代理联盟可随时登入接口观察旗下会员的下注状况及会员在网站的活动概况。星辉科技的客服部会根据代理联盟旗下的会员计算所得的佣金。</h6>
                <h6>3.星辉科技保留可以修改合约书上的任何条例，包括: 现有的佣金范围、佣金计划、付款程序、及参考计划条例的权力，星辉科技会以电邮、网站公告等方法通知代理联盟。代理联盟对于所做的修改有异议，代理联盟可选择终止合约，或洽客服人员反映意见。如修改后代理联盟无任何异议，便视作默认合约修改，代理联盟必须遵守更改后的相关规定。</h6>


                <div className="secondTitleStyle">代理联盟对星辉科技的权力及义务</div>
                <h6>1.代理联盟应尽其所能，广泛地宣传、销售及推广星辉科技，使代理本身及星辉科技的利润最大化。代理联盟可在不违反法律下，以正面形象宣传、销售及推广星辉科技，并有责任义务告知旗下会员所有星辉科技的相关优惠条件及产品。</h6>
                <h6>2.代理联盟选择的星辉科技推广手法若需付费，则代理应承担该费用。</h6>
                <h6>3.任何星辉科技相关信息包括: 标志、报表、游戏画面、图样、文案等，代理联盟不得私自复制、公开、分发有关材料，星辉科技保留法律追诉权。如代理在做业务推广有相关需要，请随时洽星辉科技。</h6>


                <div className="secondTitleStyle">规则条款</div>
                <h6>1.各阶层代理联盟不可在未经星辉科技许可情况下开设双/多个的代理账号，也不可从星辉科技账户或相关人士赚取佣金。请谨记任何阶层代理不能用代理帐户下注，星辉科技有权终止并封存账号及所有在游戏中赚取的佣金。</h6>
                <h6>2.为确保所有星辉科技会员账号隐私与权益，星辉科技不会提供任何会员密码，或会员个人资料。各阶层代理联盟亦不得以任何方式取得会员数据，或任意登入下层会员账号，如发现代理联盟有侵害星辉科技会员隐私行为，星辉科技有权取消代理联盟红利，并取消代理联盟账号。</h6>
                <h6>3.代理联盟旗下的会员不得开设多于一个的账户。星辉科技有权要求会员提供有效的身份证明以验证会员的身份，并保留以IP判定是否重复会员的权利。如违反上述事项，星辉科技有权终止玩家进行游戏并封存账号及所有于游戏中赚取的佣金。</h6>
                <h6>4.代理联盟不可为自己或其他联盟下的有效投注会员，只能是公司直属下的有效投注会员，同时代理每月需完成公司规定有效投注方可记为合格，如有违反联盟协议星辉科技有权终止并封存账号及所有在游戏中赚取的佣金。</h6>
                <h6>5.如代理联盟旗下的会员因为违反条例而被禁止享用星辉科技的游戏，或星辉科技退回存款给会员，星辉科技将不会分配相应的佣金给代理联盟。如代理联盟旗下会员存款用的信用卡、银行资料须经审核，星辉科技保留相关佣金直至审核完成。</h6>
                <h6>6.合约内的条件会以星辉科技通知接受代理联盟加入后开始执行。星辉科技及代理联盟可随时终止此合约，在任何情况下，代理联盟如果想终止合约，都必须以书面/电邮方式提早于七日内通知星辉科技。代理联盟的表现会3个月审核一次，如代理联盟已不是现有的合作成员则本合约书可以在任何时间终止。如合作伙伴违反合约条例，星辉科技有权立即终止合约。</h6>
                <h6>7.在没有星辉科技许可下，代理联盟不能透露及授权星辉科技相关密数据，包括代理联盟所获得的回馈、佣金报表、计算等;代理联盟有义务在合约终止后仍执行机密文件及数据的保密。</h6>
                <h6>8.在合约终止后，代理联盟及星辉科技将不须要履行双方的权利及义务。终止合约并不会解除代理联盟于终止合约前应履行的义务。</h6>
              </div>
            </Tabs.Pane>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, null)(CollaborateContent);