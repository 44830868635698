import HM1 from './HM1';
import HM15 from './HM15';
import HM2 from './HM2';
import HM3 from './HM3';
import HM4 from './HM4';
import HM5 from './HM5';
import LiangMian from './LiangMian';

const GXK10 = {
  children: [
    {
      tabId: 'LiangMian',
      children: LiangMian.LiangMian,
      label: ' 两面',
      settingCode: 'LM,LH,ZHDX,ZHDS,ZHWDX'
    },
    {
      tabId: 'DanHao1-5',
      children: HM15.HM15,
      label: ' 单号1-5',
      settingCode: 'QH15'
    },
    {
      tabId: 'Qiu1',
      children: HM1.HM1,
      label: ' 第一球',
      midCode: 'B1QH,B1LM,B1FLSX,B1SB'
    },
    {
      tabId: 'Qiu2',
      children: HM2.HM2,
      label: ' 第二球',
      midCode: 'B2QH,B2LM,B2FLSX,B2SB'
    },
    {
      tabId: 'Qiu3',
      children: HM3.HM3,
      label: ' 第三球',
      midCode: 'B3QH,B3LM,B3FLSX,B3SB'
    },
    {
      tabId: 'Qiu4',
      children: HM4.HM4,
      label: ' 第四球',
      midCode: 'B4QH,B4LM,B4FLSX,B4SB'
    },
    {
      tabId: 'Qiu5',
      children: HM5.HM5,
      label: ' 第五球',
      midCode: 'B5QH,B5LM,B5FLSX,B5SB'
    }
  ],
  results: {
    header: [{
      value: '开奖结果'
    }],
    TabList: [{
      code: 'NUMBER',
      renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
        value: item, className: ''
      }))
    },
    {
      code: 'BIG_SMALL',
      renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
        value: item <= 5 ? '小' : '大', className: ''
      }))
    },
    {
      code: 'ODD_EVEN',
      renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
        value: item % 2 ? '单' : '双', className: ''
      }))
    }, {
      code: 'HE_DAN_SHUANG',
      renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
        value: item, className: ''
      }))
    },
    {
      code: 'DA_XIAO_WEI',
      renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
        value: item <= 5 ? '小' : '大', className: ''
      }))
    },
    {
      code: 'SE_BO',
      renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
        value: item % 2 ? '单' : '双', className: ''
      }))
    },
    {
      code: 'FU_LU_SHOU_XI',
      renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
        value: item % 2 ? '单' : '双', className: ''
      }))
    }]
  }
};

export default GXK10;