import { BetTypeContainer, CheckBox, MultipleTypeSelectionPanel, Odds } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const HX = [
  {
    componentType: QuickBet,
  },
  {
    componentType: MultipleTypeSelectionPanel,
    data: {
      list: [
        {
          rowTitle: '类别',
          rowTitleClassName: 'text-center',
          rowType: CheckBox,
          wrapperStyle: 'checkBoxRow',
          onCheckBoxChange: (updateSectionSelection, subItem) => {
            updateSectionSelection(subItem);
          },
          rowData: [{
            code: 'TMHX3',
            displayName: '2肖中',
            minimumSelection: 2,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1'],
          }, {
            code: 'TMHX4',
            displayName: '2肖不中',
            minimumSelection: 2,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1'],
          }, {
            code: 'TMHX5',
            displayName: '3肖中',
            minimumSelection: 3,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1', '2'],
          }, {
            code: 'TMHX6',
            displayName: '3肖不中',
            minimumSelection: 3,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1', '2'],
          }, {
            code: 'TMHX7',
            displayName: '4肖中',
            minimumSelection: 4,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1', '2', '3'],
          }, {
            code: 'TMHX8',
            displayName: '4肖不中',
            minimumSelection: 4,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1', '2', '3'],
          }, {
            code: 'TMHX9',
            displayName: '5肖中',
            minimumSelection: 5,
            maximumSelection: 10,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4'],
          }, {
            code: 'TMHX10',
            displayName: '5肖不中',
            minimumSelection: 5,
            maximumSelection: 10,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4'],
          }, {
            code: 'TMHX11',
            displayName: '6肖中',
            minimumSelection: 6,
            maximumSelection: 10,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5'],
          }, {
            code: 'TMHX12',
            displayName: '6肖不中',
            minimumSelection: 6,
            maximumSelection: 10,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5'],
          }]
        },
        {
          rowTitle: '赔率',
          rowTitleClassName: 'text-center',
          rowType: Odds,
          wrapperStyle: 'oddsRow',
          className: 'chooseBetOdds',
          rowData: [{
            code: 'TMHX3',
            className: 'chooseBetOdds',
          }, {
            code: 'TMHX4',
            className: 'chooseBetOdds',
          }, {
            code: 'TMHX5',
            className: 'chooseBetOdds',
          }, {
            code: 'TMHX6',
            className: 'chooseBetOdds',
          }, {
            code: 'TMHX7',
            className: 'chooseBetOdds',
          }, {
            code: 'TMHX8',
            className: 'chooseBetOdds',
          }, {
            code: 'TMHX9',
            className: 'chooseBetOdds',
          }, {
            code: 'TMHX10',
            className: 'chooseBetOdds',
          }, {
            code: 'TMHX11',
            className: 'chooseBetOdds',
          }, {
            code: 'TMHX12',
            className: 'chooseBetOdds',
          }]
        },
        {
          rowTitle: '类别',
          rowTitleClassName: 'text-center',
          rowType: CheckBox,
          wrapperStyle: 'checkBoxRow',
          onCheckBoxChange: (updateSectionSelection, subItem) => {
            updateSectionSelection(subItem);
          },
          rowData: [{
            code: 'TMHX13',
            displayName: '7肖中',
            minimumSelection: 7,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5', '6'],
          }, {
            code: 'TMHX14',
            displayName: '7肖不中',
            minimumSelection: 7,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5', '6'],
          }, {
            code: 'TMHX15',
            displayName: '8肖中',
            minimumSelection: 8,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5', '6', '7'],
          }, {
            code: 'TMHX16',
            displayName: '8肖不中',
            minimumSelection: 8,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5', '6', '7'],
          }, {
            code: 'TMHX17',
            displayName: '9肖中',
            minimumSelection: 9,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5', '6', '7', '8'],
          }, {
            code: 'TMHX18',
            displayName: '9肖不中',
            minimumSelection: 9,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5', '6', '7', '8'],
          }, {
            code: 'TMHX19',
            displayName: '10肖中',
            minimumSelection: 10,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
          }, {
            code: 'TMHX20',
            displayName: '10肖不中',
            minimumSelection: 10,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
          }, {
            code: 'TMHX21',
            displayName: '11肖中',
            minimumSelection: 11,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
          }, {
            code: 'TMHX22',
            displayName: '11肖不中',
            minimumSelection: 11,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
          }]
        },
        {
          rowTitle: '赔率',
          rowTitleClassName: 'text-center',
          rowType: Odds,
          wrapperStyle: 'oddsRow',
          className: 'chooseBetOdds',
          rowData: [{
            code: 'TMHX13',
            className: 'chooseBetOdds',
          }, {
            code: 'TMHX14',
            className: 'chooseBetOdds',
          }, {
            code: 'TMHX15',
            className: 'chooseBetOdds',
          }, {
            code: 'TMHX16',
            className: 'chooseBetOdds',
          }, {
            code: 'TMHX17',
            className: 'chooseBetOdds',
          }, {
            code: 'TMHX18',
            className: 'chooseBetOdds',
          }, {
            code: 'TMHX19',
            className: 'chooseBetOdds',
          }, {
            code: 'TMHX20',
            className: 'chooseBetOdds',
          }, {
            code: 'TMHX21',
            className: 'chooseBetOdds',
          }, {
            code: 'TMHX22',
            className: 'chooseBetOdds',
          }]
        },
        {
          rowTitleClassName: 'p-2 text-center',
          rowType: BetTypeContainer,
          wrapperStyle: 'p-2',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['生肖', '勾选', '号码'],
      mainColumnLength: 2,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label hk6-hx-label', 'column-3items-checkbox hk6-hx-checkbox', 'column-3items-input hk6-hx-ball'],
      betItemStyle: ['column-3items-label hk6-hx-label', 'column-3items-checkbox hk6-hx-checkbox', 'column-3items-array-balls hk6-hx-ball column-3items-input'],
      betItemList: ['DisplayText', 'CheckBox', 'HK6_Zodiac'],
      betItemColumn: 3,
      list: [
        {
          displayName: '鼠',
        }, {
          displayName: '马',
        }, {
          displayName: '牛',
        }, {
          displayName: '羊',
        }, {
          displayName: '虎',
        }, {
          displayName: '猴',
        }, {
          displayName: '兔',
        }, {
          displayName: '鸡',
        }, {
          displayName: '龙',
        }, {
          displayName: '狗',
        }, {
          displayName: '蛇',
        }, {
          displayName: '猪',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default HX;