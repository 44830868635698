import React, { useImperativeHandle } from 'react';

import { connect } from 'react-redux';

import { gameActions, homeActions, placeBetActions, resultHistoryActions } from '../../../redux/actions';
import hot from '../../../assets/image/hots.png';
import './styles.scss';

const mapStateToProps = state => ({
  userGames: state.appReducers.userGames,
  userInfo: state.memberReducers.userInfo,
  yiLouCount: state.appReducers.yiLouCount,
});

const mapDispatchToProps = dispatch => ({
  getResultList: (lotteryTime, lotteryType, page, row, showLatest, isLengReRequest) => dispatch(resultHistoryActions.getResult(lotteryTime, lotteryType, page, row, showLatest, isLengReRequest)),
  updateLotteryAndTemplate: val => dispatch(gameActions.updateLotteryAndTemplate(val)),
  updateGameInfo: gameInfo => dispatch(placeBetActions.updateGameInfo(gameInfo)),
  updateCountDownTimer: countDownTimer => dispatch(placeBetActions.updateCountDownTimer(countDownTimer)),
  updateSelectedSubGameList: selectedSubgameList => dispatch(gameActions.updateSelectedSubGameList(selectedSubgameList)),
  updateGameResult: () => dispatch(resultHistoryActions.updateGameResult()),
  LotteryNotice: lotteryType => dispatch(homeActions.LotteryNotice(lotteryType)),
  updateSectionSelection: selection => dispatch(gameActions.updateSectionSelection(selection)),
});

const GameList = ({ updateSectionSelection, showCheckAll = false, hideTitle, onRef, lotteryList, redirect, local = false, gameVariable, updateGameResult, updateGameInfo, onGameTitleClick, updateLotteryAndTemplate, updateSelectedSubGameList }) => {
  const updateSelectedLottery = val => {
    updateGameInfo();
    const { gameStatus, templateCode, code, isSupportZmt } = val.item || {};
    const newSubGameList = gameVariable[`CJ_${templateCode}`].children.filter(
      _item => ((!gameStatus.find(_gameStatus => _gameStatus.pageCode.toUpperCase() === _item.tabId)) || (
        isSupportZmt && _item.tabId === 'ZMTB'
      ))
    );
    updateLotteryAndTemplate({
      template: templateCode,
      lotteryType: {
        item: code,
        redirect,
      },
      selectedSubType: newSubGameList[0].tabId
    });
    updateSelectedSubGameList(newSubGameList);
  };

  const onItemClick = item => {
    if (item.templateCode) {
      if (!gameVariable[`CJ_${item.templateCode}`]) return;
      if (!local) {
        updateSectionSelection();
        updateGameResult();
        updateSelectedLottery({
          item,
          redirect
        });
      }
    }
    if (onGameTitleClick) {
      onGameTitleClick(item);
    }
  };

  useImperativeHandle(onRef, () => ({
    func: onItemClick,
  }));

  const renderMenu = () => {
    const content = [];
    const temp = new Map();
    (lotteryList).forEach(item => {
      let { categoryName } = item;
      if (item?.templateCode === 'HK6') {
        categoryName = '六合彩';
      }
      if (temp.has(categoryName)) {
        const _tempArr = [...temp.get(categoryName), item];
        temp.set(categoryName, _tempArr);
      } else {
        temp.set(categoryName, [item]);
      }
    });

    temp.forEach((item, key) => {
      if (item.length !== 0) {
        const title = key;
        const tempList = (
          <>
            {item.map((item, index) => (
              <div
                key={`menu_item_${index.toString()}`}
                className=" lotteryName"
                onClick={() => onItemClick(item)}
              >
                {item.lotteryName ?? item?.gameName}
                {
                  item?.popular === 1 && (
                    <img src={hot} alt="hot" />
                  )
                }
              </div>
            ))}
          </>
        );
        content.push(
          <div className="menuSection" key={`menu_section_${title}`}>
            {!hideTitle
              && (
              <>
                <div className="title">{title}</div>
                <div className="gapLine" />
              </>
              )}
            <div className="content">{tempList}</div>
          </div>
        );
      }
    });
    return content;
  };

  return (
    <div className="outWrapper">
      {(lotteryList && lotteryList.length > 0)
        && (
        <div className="lotteryMenuWrapper">
          {
            renderMenu()
          }
        </div>
        )}
      {showCheckAll && (
      <div
        className="checkAllBtn"
        onClick={() => onItemClick({
          code: 'ALL_GAMES'
        })}
      >
        查看全部
      </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GameList);