import { OddsTypeTable, ProbabilitySelectionPanel } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const Q2ZX = [
  {
    componentType: ProbabilitySelectionPanel,
    data: {
      midCodeList: [{
        label: '前二复式',
        value: 'ZX1',
        minimumSelection: 2,
        maximumSelection: 22,
        iteration: 2,
      }]
    }
  },
  {
    componentType: QuickBet,
  },
  {
    componentType: OddsTypeTable,
    data: {
      renderLabel: () => '前二复式'
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '第一球',
      cycleHeaderTitle: true,
      mainColumnLength: 3,
      subColumnLength: 2,
      subColumnStyle: ['w-50', 'w-50'],
      betItemStyle: ['w-50', 'w-50'],
      betItemList: ['DisplayText', 'CheckBox'],
      betItemColumn: 2,
      list: [
        {
          code: 'A1',
          displayName: '1',
          className: 'circle',
        },
        {
          code: 'A5',
          displayName: '5',
          className: 'circle',
        },
        {
          code: 'A9',
          displayName: '9',
          className: 'circle',
        },
        {
          code: 'A2',
          displayName: '2',
          className: 'circle',
        },
        {
          code: 'A6',
          displayName: '6',
          className: 'circle',
        },
        {
          code: 'A10',
          displayName: '10',
          className: 'circle',
        },
        {
          code: 'A3',
          displayName: '3',
          className: 'circle',
        },
        {
          code: 'A7',
          displayName: '7',
          className: 'circle',
        },
        {
          code: 'A11',
          displayName: '11',
          className: 'circle',
        },
        {
          code: 'A4',
          displayName: '4',
          className: 'circle',
        },
        {
          code: 'A8',
          displayName: '8',
          className: 'circle',
        },
      ]
    }
  }, {
    componentType: Template2,
    data: {
      tableTitle: '第二球',
      cycleHeaderTitle: true,
      mainColumnLength: 3,
      subColumnLength: 2,
      subColumnStyle: ['w-50', 'w-50'],
      betItemStyle: ['w-50', 'w-50'],
      betItemList: ['DisplayText', 'CheckBox'],
      betItemColumn: 2,
      list: [
        {
          code: 'B1',
          displayName: '1',
          className: 'circle',
        },
        {
          code: 'B5',
          displayName: '5',
          className: 'circle',
        },
        {
          code: 'B9',
          displayName: '9',
          className: 'circle',
        },
        {
          code: 'B2',
          displayName: '2',
          className: 'circle',
        },
        {
          code: 'B6',
          displayName: '6',
          className: 'circle',
        },
        {
          code: 'B10',
          displayName: '10',
          className: 'circle',
        },
        {
          code: 'B3',
          displayName: '3',
          className: 'circle',
        },
        {
          code: 'B7',
          displayName: '7',
          className: 'circle',
        },
        {
          code: 'B11',
          displayName: '11',
          className: 'circle',
        },
        {
          code: 'B4',
          displayName: '4',
          className: 'circle',
        },
        {
          code: 'B8',
          displayName: '8',
          className: 'circle',
        },
      ]
    }
  }, {
    componentType: QuickBet,
  }
];

export default Q2ZX;