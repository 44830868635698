import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const HM1 = [
  {
    componentType: CollapseType,
    header: '第一球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'B1QH1',
          displayName: '1',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B1QH2',
          displayName: '2',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B1QH3',
          displayName: '3',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B1QH4',
          displayName: '4',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B1QH5',
          displayName: '5',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B1QH6',
          displayName: '6',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B1QH7',
          displayName: '7',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B1QH8',
          displayName: '8',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B1QH9',
          displayName: '9',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B1QH10',
          displayName: '10',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B1QH11',
          displayName: '11',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B1QH12',
          displayName: '12',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B1QH13',
          displayName: '13',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B1QH14',
          displayName: '14',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B1QH15',
          displayName: '15',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B1QH16',
          displayName: '16',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B1QH17',
          displayName: '17',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B1QH18',
          displayName: '18',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B1QH19',
          displayName: '19',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B1QH20',
          displayName: '20',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B1QH21',
          displayName: '21',
          drawImgClassName: 'circle circleGreen',
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '色波，福禄寿喜，两面',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'B1LM1',
          displayName: '大',
        }, {
          code: 'B1LM2',
          displayName: '小',
        }, {
          code: 'B1LM3',
          displayName: '单',
        }, {
          code: 'B1LM4',
          displayName: '双',
        }, {
          code: 'B1LM5',
          displayName: '尾大',
        }, {
          code: 'B1LM6',
          displayName: '尾小',
        }, {
          code: 'B1LM7',
          displayName: '合单',
        }, {
          code: 'B1LM8',
          displayName: '合双',
        }, {
          code: 'B1QH22',
          displayName: '红',
        }, {
          code: 'B1QH23',
          displayName: '绿',
        }, {
          code: 'B1QH24',
          displayName: '蓝',
        }, {
          code: 'B1QH25',
          displayName: '福',
        }, {
          code: 'B1QH26',
          displayName: '禄',
        }, {
          code: 'B1QH27',
          displayName: '寿',
        }, {
          code: 'B1QH28',
          displayName: '喜',
        },
      ]
    }
  }
];

export default HM1;