import React, { useState } from 'react';
import './styles.scss';

import { Tooltip } from 'reactstrap';

import { AnimatePresence, motion } from 'framer-motion';

import { FaClock, FaPhoneAlt, FaTelegram } from 'react-icons/fa';

const ContactList = () => {
  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const [tooltipOpen3, setTooltipOpen3] = useState(false);

  const toggle1 = () => setTooltipOpen1(!tooltipOpen1);
  const toggle2 = () => setTooltipOpen2(!tooltipOpen2);
  const toggle3 = () => setTooltipOpen3(!tooltipOpen3);

  const contactItems = [
    {
      name: '服务电话',
      title: '+855 97 712 2186',
      link: 'tel:+855977122186',
      icon: <FaPhoneAlt size={25} />,
      isOpen: tooltipOpen1,
      toggle: toggle1
    },
    {
      name: 'Telegram',
      title: 'XingHui18',
      link: 'https://t.me/XingHui18',
      icon: <FaTelegram size={25} />,
      isOpen: tooltipOpen2,
      toggle: toggle2
    },
    {
      name: '工作时间',
      title: '08:00Am - 23:59PM',
      link: '',
      icon: <FaClock size={25} />,
      isOpen: tooltipOpen3,
      toggle: toggle3
    }
  ];
  return (
    <>
      <AnimatePresence>
        <motion.div
          initial={{
            opacity: 0, y: '10%'
          }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.5,
            },
          }}
          exit={{
            opacity: 0,
            y: '10%',
            transition: {
              duration: 0.5,
            },
          }}
        >
          {contactItems.map((item, index) => (
            <div key={`${index.toString()}`}>
              <div className="contactCircleButton p-2 my-4" id={item.name}>
                {item.link !== '' ? (
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                    className="customLink"
                  >
                    <motion.div
                      whileHover={{
                        scale: 1.2
                      }}
                      whileTap={{
                        scale: 0.8
                      }}
                    >
                      {item.icon}
                    </motion.div>
                  </a>
                ) : (
                  item.icon
                )}
              </div>
              <Tooltip placement="left" isOpen={item.isOpen} target={item.name} toggle={item.toggle}>
                <div className="d-flex flex-column">
                  <div className="font-weight-bold">{item.name}</div>
                  <div>{item.title}</div>
                </div>
              </Tooltip>
            </div>
          ))}
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default ContactList;