import React, { useState } from 'react';

import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { connect } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const MobileNotificationModal = ({ updateModalAlert, data }) => {
  const [checked, setChecked] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="mobileNotificationModalWrapper">
      <div className="modalHeader pl-3">
        {t('IMPORTANT_NOTICE')}
      </div>

      <div className="modalContent p-3 text-center">
        <div className="font-weight-bold mb-2">{t('NOTICE_DETAIL')}</div>
        <div className="content">
          {data.noticeContent}
        </div>
      </div>

      <div className="modalFooter text-center p-3">
        <div className="d-flex justify-content-center align-items-center">
          <span
            className="promotionButton w-50 py-2 text-white"
            onClick={() => {
              updateModalAlert({
                visible: false
              }); history.push('/user/promotions');
            }}
          >
            {t('_PROMOTION')}
          </span>
          <span
            onClick={() => {
              if (checked) {
                const balanceSecond = moment().endOf('day').unix() - moment().unix();
                document.cookie = `doNotRemind=${checked}; max-age=${balanceSecond}`;
              }
              updateModalAlert({
                visible: false
              });
            }}
            className="ml-3 py-2 confirmButton w-50"
          >
            {t('MODAL_ACKNOWLEDGE')}
          </span>
        </div>
        <div className="mt-2">
          <div
            className="d-flex justify-content-center align-items-center "
            onClick={() => {
              setChecked(!checked);
            }}
          >
            {
              checked ? <MdCheckBox size={22} /> : <MdCheckBoxOutlineBlank size={22} />
            }
            <span className="ml-1">{t('DO_NOT_REMIND_TODAY')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(MobileNotificationModal);