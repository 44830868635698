import HX from './HX';
import KuaiJie from './KuaiJie';
import LIANGMIAN from './LIANGMIAN';
import LM from './LM';
import LW from './LW';
import LX from './LX';
import QSB from './QSB';
import SB from './SB';
import TM from './TM';
import TWS from './TWS';
import TX from './TX';
import WX from './WX';
import YXWS from './YXWS';
import ZHENGXIAO from './ZHENGXIAO';
import ZM16 from './ZM16';
import ZMA from './ZMA';
import ZMT from './ZMT';
import ZONGXIAO from './ZONGXIAO';
import ZXBZ from './ZXBZ';

const renderZH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  let championSum = 0;
  const tempResult = [];
  const lastBall = parseInt(result[6], 10);
  result.forEach(item => championSum += parseInt(item, 10));
  tempResult.push({
    value: championSum
  }, {
    value: championSum % 2 ? '单' : '双',
    className: championSum % 2 ? 'resultItem resultItemBlue' : 'resultItem resultItemOrange',
  }, {
    value: (championSum > 174) ? '大' : '小',
    className: (championSum > 174) ? 'resultItem resultItemBlue' : 'resultItem resultItemOrange',
  }, {
    value: (lastBall === 1 || lastBall === 2 || lastBall === 7 || lastBall === 8 || lastBall === 12 || lastBall === 13 || lastBall === 18 || lastBall === 19 || lastBall === 23 || lastBall === 24 || lastBall === 29 || lastBall === 30 || lastBall === 34 || lastBall === 35 || lastBall === 40 || lastBall === 45 || lastBall === 46) ? '红' : (lastBall === 3 || lastBall === 4 || lastBall === 9 || lastBall === 10 || lastBall === 14 || lastBall === 15 || lastBall === 20 || lastBall === 25 || lastBall === 26 || lastBall === 31 || lastBall === 36 || lastBall === 37 || lastBall === 41 || lastBall === 42 || lastBall === 47 || lastBall === 48) ? '蓝' : '绿',
  });
  return tempResult;
};

const renderTHW = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  const tm = parseInt(result[6], 10);
  tempResult.push({
    value: '和',
  }, {
    value: parseInt(result[6], 10) === 49 ? '和' : getTMHS(tm, result) % 2 ? '单' : '双',
    className: parseInt(result[6], 10) === 49 ? 'resultItem resultItemGreen' : getTMHS(tm, result) % 2 ? 'resultItem resultItemBlue' : 'resultItem resultItemOrange',
  }, {
    value: getTMDS(tm, result),
    className: getTMDS(tm, result) === '和' ? 'resultItem resultItemGreen' : getTMDS(tm, result) === '大' ? 'resultItem resultItemBlue' : 'resultItem resultItemOrange',
  }, {
    value: getTMWDX(tm, result),
    className: getTMWDX(tm, result) === '和' ? 'resultItem resultItemGreen' : getTMWDX(tm, result) === '大' ? 'resultItem resultItemBlue' : 'resultItem resultItemOrange',
  });
  return tempResult;
};

const renderTDX = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const lastBall = parseInt(result[6], 10);
  const tempResult = [];
  tempResult.push({
    value: lastBall === 49 ? '和' : lastBall % 2 ? '单' : '双',
    className: lastBall === 49 ? 'resultItem resultItemGreen' : (lastBall % 2) ? 'resultItem resultItemBlue' : 'resultItem resultItemOrange',
  }, {
    value: lastBall === 49 ? '和' : (lastBall > 24) ? '大' : '小',
    className: lastBall === 49 ? 'resultItem resultItemGreen' : (lastBall > 24) ? 'resultItem resultItemBlue' : 'resultItem resultItemOrange',
  }, {
    value: lastBall === 49 ? '和' : (lastBall >= 17 && lastBall <= 30) ? '中' : (lastBall > 22) ? '大' : '小',
    className: lastBall === 49 ? 'resultItem resultItemGreen' : (lastBall >= 17 && lastBall <= 30) ? 'resultItem resultItemGreen' : (lastBall > 22) ? 'resultItem resultItemBlue' : 'resultItem resultItemOrange',
  });
  return tempResult;
};

const getTMHS = (tm, result) => {
  let htm = 0;
  if (result[6] < 10) {
    htm = tm;
  } else {
    htm = parseInt((tm % 10) + (tm / 10), 10);
  }
  return htm;
};
const getTMDS = (tm, result) => {
  if (tm === 49) {
    return '和';
  }
  if (getTMHS(tm, result) <= 6) {
    return '小';
  }
  if (getTMHS(tm, result) >= 7 && getTMHS(tm, result) <= 12) {
    return '大';
  }
  return '和';
};
const getTMWDX = (tm, result) => {
  if (tm === 25) {
    return '和';
  }
  if (getTMHS(tm, result) < 10) {
    if (getTMHS(tm, result) >= 5) {
      return '大';
    }
    return '小';
  }
  if (parseInt(tm / 10, 10) >= 5) {
    return '大';
  }
  return '小';
};

const getBallClass = value => {
  const REDBALL = ['01', '02', '07', '08', '12', '13', '18', '19', '23', '24', '29', '30', '34', '35', '40', '45', '46'];
  const BLUEBALL = ['03', '04', '09', '10', '14', '15', '20', '25', '26', '31', '36', '37', '41', '42', '47', '48'];
  const GREENBALL = ['05', '06', '11', '16', '17', '21', '22', '27', '28', '32', '33', '38', '39', '43', '44', '49'];
  if (REDBALL.includes(value)) {
    return 'circle circleRed';
  }
  if (BLUEBALL.includes(value)) {
    return 'circle circleBlue';
  }
  if (GREENBALL.includes(value)) {
    return 'circle circleGreen';
  }
};

const renderBall = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [[{
    value: result[0],
    className: getBallClass(result[0])
  }, {
    value: data.p1sx,
  }], [{
    value: result[1],
    className: getBallClass(result[1])
  }, {
    value: data.p2sx,
  }], [{
    value: result[2],
    className: getBallClass(result[2])
  }, {
    value: data.p3sx,
  }], [{
    value: result[3],
    className: getBallClass(result[3])
  }, {
    value: data.p4sx,
  }], [{
    value: result[4],
    className: getBallClass(result[4])
  }, {
    value: data.p5sx,
  }], [{
    value: result[5],
    className: getBallClass(result[5])
  }, {
    value: data.p6sx,
  }], {
    value: '+',
  }, [{
    value: result[6],
    className: getBallClass(result[6])
  }, {
    value: data.sx,
  }]];
  return tempResult;
};

const HK6 = {
  children: [
    {
      tabId: 'KuaiJie',
      label: '快捷',
      children: KuaiJie.KuaiJie,
    },
    {
      tabId: 'TM',
      label: '特码',
      children: TM.TM,
    },
    {
      tabId: 'LIANGMIAN',
      label: '两面',
      children: LIANGMIAN.LIANGMIAN,
      settingCode: 'LM,TMDSDX'
    },
    {
      tabId: 'SB',
      label: '色波',
      children: SB.SB,
      settingCode: 'TMSB,TBBDS,TBBDX,TMBBB'
    },
    {
      tabId: 'TX',
      label: '特肖',
      children: TX.TX,
      settingCode: 'TMSX'
    },
    {
      tabId: 'HX',
      label: '合肖',
      children: HX.HX,
      settingCode: 'TMHX'
    },
    {
      tabId: 'TWS',
      label: '头尾数',
      children: TWS.TWS,
      settingCode: 'TMTS,TMWS'
    },
    {
      tabId: 'ZMA',
      label: '正码',
      children: ZMA.ZMA,
      midCode: 'ZMA,ZMLM'
    },
    {
      tabId: 'ZMT',
      label: '正码特',
      children: ZMT.ZMT,
      settingCode: 'ZMT,ZMLM'
    },
    {
      tabId: 'ZM16',
      label: '正码1-6',
      children: ZM16.ZM16,
      settingCode: 'ZMLM,Z16SB'
    },
    {
      tabId: 'WX',
      label: '五行',
      children: WX.WX,
      settingCode: 'TMWX'
    },
    {
      tabId: 'QSB',
      label: '七色波',
      children: QSB.QSB,
      settingCode: '7SB'
    },
    {
      tabId: 'YXWS',
      label: '一肖尾数',
      children: YXWS.YXWS,
      settingCode: 'PTYX,PTWS'
    },
    {
      tabId: 'ZHENGXIAO',
      label: '正肖',
      children: ZHENGXIAO.ZHENGXIAO,
      settingCode: 'ZENGX'
    },
    {
      tabId: 'ZONGXIAO',
      label: '总肖',
      children: ZONGXIAO.ZONGXIAO,
      settingCode: 'ZX,ZXDS'
    },
    {
      tabId: 'ZXBZ',
      label: '自选不中',
      children: ZXBZ.ZXBZ,
      settingCode: 'ZXBZ'
    },
    {
      tabId: 'LX',
      label: '连肖',
      children: LX.LX,
      settingCode: '2LX,3LX,4LX,5LX,2LXBZ,3LXBZ,4LXBZ,5LXBZ'
    },
    {
      tabId: 'LW',
      label: '连尾',
      children: LW.LW,
      settingCode: '2LW,3LW,SLW,2LWBZ,3LWBZ,4LWBZ'
    },
    {
      tabId: 'LM',
      label: '连码',
      children: LM.LM,
      midCode: 'L3QZ,L3Z2Z2,L2QZ,L2ZT,LTC'
    }
  ],
  results: {
    TabList: [{
      code: 'NUMBER',
      renderFunc: data => renderBall(data)
    },
    {
      code: 'TEDAXIAO',
      renderFunc: data => renderTDX(data)
    },
    {
      code: 'TEHEWEI',
      renderFunc: data => renderTHW(data)
    },
    {
      code: 'SUM',
      renderFunc: data => renderZH(data)
    }]
  }
};

export default HK6;