/* eslint-disable space-before-function-paren */
import { SET_CHECK_LATENCY, SET_PING_RESULT } from '../../actions/actionTypes';

const initialState = {
  checkLatencyData: undefined,
  pingResultData: undefined
};

const pingReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHECK_LATENCY:
      return {
        ...state, checkLatencyData: action.value
      };
    case SET_PING_RESULT:
      if (state.checkLatencyData) {
        const tempMain = state.checkLatencyData;
        const tempDomain = [...state.checkLatencyData?.domainList];
        const index = tempDomain.findIndex(({ domain }) => domain === action.value.route);
        if (index !== -1) {
          tempDomain[index].time = action.value.latency;
        }
        tempMain.domainList = tempDomain;
        return {
          ...state,
          checkLatencyData: tempMain
        };
      }
      return {
        ...state, checkLatencyData: state.checkLatencyData
      };

    default:
      return state;
  }
};

export default pingReducers;