import { ProbabilitySelectionPanel } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const TMGroup = [
  'TMA1', 'TMA2', 'TMA3', 'TMA4', 'TMA5', 'TMA6', 'TMA7', 'TMA8', 'TMA9', 'TMA10',
  'TMA11', 'TMA12', 'TMA13', 'TMA14', 'TMA15', 'TMA16', 'TMA17', 'TMA18', 'TMA19', 'TMA20',
  'TMA21', 'TMA22', 'TMA23', 'TMA24', 'TMA25', 'TMA26', 'TMA27', 'TMA28', 'TMA29', 'TMA30',
  'TMA31', 'TMA32', 'TMA33', 'TMA34', 'TMA35', 'TMA36', 'TMA37', 'TMA38', 'TMA39', 'TMA40',
  'TMA41', 'TMA42', 'TMA43', 'TMA44', 'TMA45', 'TMA46', 'TMA47', 'TMA48', 'TMA49'];

const transformLengReValue = (lengRe, yiLouCount, group, currentKeyCode) => {
  const lengReArr = group.map(item => lengRe[item] || 0);
  const yiLouArr = lengReArr.map(x => yiLouCount - x);
  return {
    value: lengRe[currentKeyCode],
    className: Math.max(...lengReArr) === (lengRe[currentKeyCode] || 0) ? 'hot' : (Math.min(...lengReArr) === (lengRe[currentKeyCode] || 0) ? 'cold' : ''),
    classNameYiLou: Math.max(...yiLouArr) === (yiLouCount - (lengRe[currentKeyCode] || 0)) ? 'hot' : (Math.min(...yiLouArr) === (yiLouCount - (lengRe[currentKeyCode] || 0)) ? 'cold' : ''),
  };
};

const TM = [
  {
    componentType: ProbabilitySelectionPanel,
    data: {
      midCodeList: [{
        label: '特码A',
        value: 'TMA',
        midCode: 'TMA,TMDSDX,TMSB'
      }, {
        label: '特码B',
        value: 'TMB',
        midCode: 'TMA,TMDSDX,TMSB',
        isTmb: 1
      }],
    }
  },
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '赔率', '金额'],
      mainColumnLength: 5,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      emptyItemStyle: ['column-3items-empty-label', 'column-3items-empty-odds', 'column-3items-empty-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      showProbability: true,
      list: [
        {
          code: 'TMA1',
          displayName: '01',
          className: 'circle circleRed',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA1');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA11',
          displayName: '11',
          className: 'circle circleGreen',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA11');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA21',
          displayName: '21',
          className: 'circle circleGreen',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA21');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA31',
          displayName: '31',
          className: 'circle circleBlue',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA31');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA41',
          displayName: '41',
          className: 'circle circleBlue',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA41');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA2',
          displayName: '02',
          className: 'circle circleRed',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA2');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA12',
          displayName: '12',
          className: 'circle circleRed',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA12');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA22',
          displayName: '22',
          className: 'circle circleGreen',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA22');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA32',
          displayName: '32',
          className: 'circle circleGreen',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA32');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA42',
          displayName: '42',
          className: 'circle circleBlue',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA42');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA3',
          displayName: '03',
          className: 'circle circleBlue',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA3');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA13',
          displayName: '13',
          className: 'circle circleRed',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA13');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA23',
          displayName: '23',
          className: 'circle circleRed',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA23');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA33',
          displayName: '33',
          className: 'circle circleGreen',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA33');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA43',
          displayName: '43',
          className: 'circle circleGreen',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA43');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA4',
          displayName: '04',
          className: 'circle',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA4');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA14',
          displayName: '14',
          className: 'circle',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA14');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA24',
          displayName: '24',
          className: 'circle circleRed',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA24');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA34',
          displayName: '34',
          className: 'circle circleRed',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA34');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA44',
          displayName: '44',
          className: 'circle circleGreen',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA44');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA5',
          displayName: '05',
          className: 'circle circleGreen',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA5');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA15',
          displayName: '15',
          className: 'circle',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA15');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA25',
          displayName: '25',
          className: 'circle',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA25');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA35',
          displayName: '35',
          className: 'circle circleRed',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA35');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA45',
          displayName: '45',
          className: 'circle circleRed',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA45');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA6',
          displayName: '06',
          className: 'circle circleGreen',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA6');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA16',
          displayName: '16',
          className: 'circle circleGreen',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA16');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA26',
          displayName: '26',
          className: 'circle',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA26');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA36',
          displayName: '36',
          className: 'circle',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA36');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA46',
          displayName: '46',
          className: 'circle circleRed',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA46');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA7',
          displayName: '07',
          className: 'circle circleRed',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA7');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA17',
          displayName: '17',
          className: 'circle circleGreen',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA17');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA27',
          displayName: '27',
          className: 'circle circleGreen',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA27');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA37',
          displayName: '37',
          className: 'circle',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA37');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA47',
          displayName: '47',
          className: 'circle',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA47');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA8',
          displayName: '08',
          className: 'circle circleRed',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA8');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA18',
          displayName: '18',
          className: 'circle circleRed',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA18');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA28',
          displayName: '28',
          className: 'circle circleGreen',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA28');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA38',
          displayName: '38',
          className: 'circle circleGreen',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA38');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA48',
          displayName: '48',
          className: 'circle',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA48');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA9',
          displayName: '09',
          className: 'circle',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA9');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA19',
          displayName: '19',
          className: 'circle circleRed',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA19');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA29',
          displayName: '29',
          className: 'circle circleRed',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA29');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA39',
          displayName: '39',
          className: 'circle circleGreen',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA39');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA49',
          displayName: '49',
          className: 'circle circleGreen',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA49');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA10',
          displayName: '10',
          className: 'circle',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA10');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA20',
          displayName: '20',
          className: 'circle',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA20');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA30',
          displayName: '30',
          className: 'circle circleRed',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA30');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'TMA40',
          displayName: '40',
          className: 'circle circleRed',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, TMGroup, 'TMA40');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '赔率', '金额'],
      mainColumnLength: 5,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      emptyItemStyle: ['column-3items-empty-label', 'column-3items-empty-odds', 'column-3items-empty-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'TMDSDX50',
          displayName: '特单',
        }, {
          code: 'TMDSDX52',
          displayName: '特大',
        }, {
          code: 'TMDSDX54',
          displayName: '特合单',
        }, {
          code: 'TMDSDX56',
          displayName: '特合大',
        }, {
          code: 'TMDSDX58',
          displayName: '特尾大',
        }, {
          code: 'TMDSDX51',
          displayName: '特双',
        }, {
          code: 'TMDSDX53',
          displayName: '特小',
        }, {
          code: 'TMDSDX55',
          displayName: '特合双',
        }, {
          code: 'TMDSDX57',
          displayName: '特合小',
        }, {
          code: 'TMDSDX59',
          displayName: '特尾小',
        }, {
          code: 'TMSB60',
          displayName: '红波',
          className: 'fillRed',
        }, {
          code: 'TMSB61',
          displayName: '蓝波',
          className: 'fillBlue',
        }, {
          code: 'TMSB62',
          displayName: '绿波',
          className: 'fillGreen',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default TM;