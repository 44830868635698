import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';
import gameItemIconMap from '../../../GameItemIcon';

const YXXTB = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '鱼虾蟹骰宝',
      mainColumnLength: 3,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-style-4th', 'column-3items-style-2th', 'column-3items-style-3th'],
      betItemStyle: ['column-3items-label-and-image', 'column-3items-odds column-3items-label-and-image', 'column-3items-input column-3items-label-and-image'],
      betItemList: ['ImageAndText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'SJ11',
          image: gameItemIconMap.CJ_FISH,
          displayName: '1/鱼'
        }, {
          code: 'SJ13',
          image: gameItemIconMap.CJ_GOURD,
          displayName: '3/葫芦'
        }, {
          code: 'SJ15',
          image: gameItemIconMap.CJ_CRAB,
          displayName: '5/螃蟹'
        }, {
          code: 'SJ12',
          image: gameItemIconMap.CJ_PRAWN,
          displayName: '2/虾',
          newLine: true
        }, {
          code: 'SJ14',
          image: gameItemIconMap.CJ_COIN,
          displayName: '4/金钱'
        }, {
          code: 'SJ16',
          image: gameItemIconMap.CJ_CHICKEN,
          displayName: '6/鸡'
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default YXXTB;