import { renderHeXiaoBalls, renderResultDateCell } from '../../utils';
import HX from './HX';
import LIANGMIAN from './LIANGMIAN';
import LM from './LM';
import LW from './LW';
import LX from './LX';
import SB from './SB';
import TM from './TM';
import TX from './TX';
import WX from './WX';
import YXWS from './YXWS';
import ZM16 from './ZM16';
import ZMA from './ZMA';
import ZMT from './ZMT';
import ZXBZ from './ZXBZ';
import ZXZX from './ZXZX';


const getTMHS = (tm, result) => {
  let htm = 0;
  if (result[6] < 10) {
    htm = tm;
  } else {
    htm = parseInt((tm % 10) + (tm / 10), 10);
  }
  return htm;
};
const getTMDS = (tm, result) => {
  if (tm === 49) {
    return '和';
  }
  if (getTMHS(tm, result) <= 6) {
    return '小';
  }
  if (getTMHS(tm, result) >= 7 && getTMHS(tm, result) <= 12) {
    return '大';
  }
  return '和';
};
const getTMWDX = (tm, result) => {
  if (tm === 25) {
    return '和';
  }
  if (getTMHS(tm, result) < 10) {
    if (getTMHS(tm, result) >= 5) {
      return '大';
    }
    return '小';
  }
  if (parseInt(tm / 10, 10) >= 5) {
    return '大';
  }
  return '小';
};

const getBallClass = value => {
  const REDBALL = ['01', '02', '07', '08', '12', '13', '18', '19', '23', '24', '29', '30', '34', '35', '40', '45', '46'];
  const BLUEBALL = ['03', '04', '09', '10', '14', '15', '20', '25', '26', '31', '36', '37', '41', '42', '47', '48'];
  const GREENBALL = ['05', '06', '11', '16', '17', '21', '22', '27', '28', '32', '33', '38', '39', '43', '44', '49'];
  if (REDBALL.includes(value)) {
    return 'circle circleRed';
  }
  if (BLUEBALL.includes(value)) {
    return 'circle circleBlue';
  }
  if (GREENBALL.includes(value)) {
    return 'circle circleGreen';
  }
};

const renderBall = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [[{
    value: result[0],
    className: getBallClass(result[0])
  }, {
    value: data.p1sx,
  }], [{
    value: result[1],
    className: getBallClass(result[1])
  }, {
    value: data.p2sx,
  }], [{
    value: result[2],
    className: getBallClass(result[2])
  }, {
    value: data.p3sx,
  }], [{
    value: result[3],
    className: getBallClass(result[3])
  }, {
    value: data.p4sx,
  }], [{
    value: result[4],
    className: getBallClass(result[4])
  }, {
    value: data.p5sx,
  }], [{
    value: result[5],
    className: getBallClass(result[5])
  }, {
    value: data.p6sx,
  }], [{
    value: result[6],
    className: getBallClass(result[6])
  }, {
    value: data.sx,
  }]];
  return tempResult;
};

const TDX_DS = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  const lastBall = parseInt(result[6], 10);
  const tempResult = [];
  tempResult.push({
    value: lastBall === 49 ? '和' : lastBall % 2 ? '单' : '双',
    className: `d-flex h-100 justify-content-center align-items-center ${(lastBall % 2) && 'redText'}`
  });
  return tempResult;
};
const TDX_DX = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  const lastBall = parseInt(result[6], 10);
  const tempResult = [];
  tempResult.push({
    value: lastBall === 49 ? '和' : (lastBall > 24) ? '大' : '小',
    className: `d-flex h-100 justify-content-center align-items-center ${(lastBall > 24) && 'redText'}`
  });
  return tempResult;
};

const TDX_DZX = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  const lastBall = parseInt(result[6], 10);
  const tempResult = [];
  tempResult.push({
    value: lastBall === 49 ? '和' : (lastBall >= 17 && lastBall <= 30) ? '中' : (lastBall > 22) ? '大' : '小',
    className: `d-flex h-100 justify-content-center align-items-center ${(lastBall > 22) && 'redText'}`
  });
  return tempResult;
};

const TW_DX = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  const tm = parseInt(result[6], 10);
  const val = getTMDS(tm, result);
  tempResult.push({
    value: getTMDS(tm, result),
    className: `d-flex h-100 justify-content-center align-items-center ${val === '大' && 'redText'}`
  });
  return tempResult;
};
const TH_DS = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  const tm = parseInt(result[6], 10);
  const val = parseInt(result[6], 10) === 49 ? '和' : getTMHS(tm, result) % 2 ? '单' : '双';
  tempResult.push({
    value: val,
    className: `d-flex h-100 justify-content-center align-items-center ${(val === '单') && 'redText'}`
  });
  return tempResult;
};
const THW_DX = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  const tm = parseInt(result[6], 10);
  const val = getTMWDX(tm, result);
  tempResult.push({
    value: val,
    className: `d-flex h-100 justify-content-center align-items-center ${(val === '大') && 'redText'}`
  });
  return tempResult;
};

const Z = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  let championSum = 0;
  const tempResult = [];
  result.forEach(item => championSum += parseInt(item, 10));
  tempResult.push({
    value: championSum,
    className: 'd-flex h-100 justify-content-center align-items-center'
  });
  return tempResult;
};

const Z_DS = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  let championSum = 0;
  const tempResult = [];
  result.forEach(item => championSum += parseInt(item, 10));
  tempResult.push({
    value: championSum % 2 ? '单' : '双',
    className: `d-flex h-100 justify-content-center align-items-center ${championSum % 2 && 'redText'}`
  });
  return tempResult;
};

const Z_DX = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  let championSum = 0;
  const tempResult = [];
  result.forEach(item => championSum += parseInt(item, 10));
  tempResult.push({
    value: (championSum > 174) ? '大' : '小',
    className: `d-flex h-100 justify-content-center align-items-center ${(championSum > 174) && 'redText'}`
  });
  return tempResult;
};

const BD = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  let championSum = 0;
  const tempResult = [];
  const lastBall = parseInt(result[6], 10);
  result.forEach(item => championSum += parseInt(item, 10));
  tempResult.push({
    value: (lastBall === 1 || lastBall === 2 || lastBall === 7 || lastBall === 8 || lastBall === 12 || lastBall === 13 || lastBall === 18 || lastBall === 19 || lastBall === 23 || lastBall === 24 || lastBall === 29 || lastBall === 30 || lastBall === 34 || lastBall === 35 || lastBall === 40 || lastBall === 45 || lastBall === 46) ? '红' : (lastBall === 3 || lastBall === 4 || lastBall === 9 || lastBall === 10 || lastBall === 14 || lastBall === 15 || lastBall === 20 || lastBall === 25 || lastBall === 26 || lastBall === 31 || lastBall === 36 || lastBall === 37 || lastBall === 41 || lastBall === 42 || lastBall === 47 || lastBall === 48) ? '蓝' : '绿',
    className: (lastBall === 1 || lastBall === 2 || lastBall === 7 || lastBall === 8 || lastBall === 12 || lastBall === 13 || lastBall === 18 || lastBall === 19 || lastBall === 23 || lastBall === 24 || lastBall === 29 || lastBall === 30 || lastBall === 34 || lastBall === 35 || lastBall === 40 || lastBall === 45 || lastBall === 46) ? 'redBoDuan d-flex justify-content-center align-items-center w-100 h-100' : (lastBall === 3 || lastBall === 4 || lastBall === 9 || lastBall === 10 || lastBall === 14 || lastBall === 15 || lastBall === 20 || lastBall === 25 || lastBall === 26 || lastBall === 31 || lastBall === 36 || lastBall === 37 || lastBall === 41 || lastBall === 42 || lastBall === 47 || lastBall === 48) ? 'blueBoDuan  d-flex justify-content-center align-items-center w-100 h-100' : 'greenBoDuan  d-flex justify-content-center align-items-center w-100 h-100',
  });
  return tempResult;
};

const quickBetDictionary = {
  红波: [1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46],
  蓝波: [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48],
  绿波: [5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49],
  红大: [29, 30, 34, 35, 40, 45, 46],
  蓝大: [25, 26, 31, 36, 37, 41, 42, 47, 48],
  绿大: [27, 28, 32, 33, 38, 39, 43, 44, 49],
  红小: [1, 2, 7, 8, 12, 13, 18, 19, 23, 24],
  蓝小: [3, 4, 9, 10, 14, 15, 20],
  绿小: [5, 6, 11, 16, 17, 21, 22],
  红单: [1, 7, 13, 19, 23, 29, 35, 45],
  蓝单: [3, 9, 15, 25, 31, 37, 41, 47],
  绿单: [5, 11, 17, 21, 27, 33, 39, 43, 49],
  红双: [2, 8, 12, 18, 24, 30, 34, 40, 46],
  蓝双: [4, 10, 14, 20, 26, 36, 42, 48],
  绿双: [6, 16, 22, 28, 32, 38, 44],
  大号: [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49],
  小号: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
  单号: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37, 39, 41, 43, 45, 47, 49],
  双号: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48],
  大单: [25, 27, 29, 31, 33, 35, 37, 39, 41, 43, 45, 47, 49],
  大双: [26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48],
  小单: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23],
  小双: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24],
  合单: [1, 3, 5, 7, 9, 10, 12, 14, 16, 18, 21, 23, 25, 27, 29, 30, 32, 34, 36, 38, 41, 43, 45, 47, 49],
  合双: [2, 4, 6, 8, 11, 13, 15, 17, 19, 20, 22, 24, 26, 28, 31, 33, 35, 37, 39, 40, 42, 44, 46, 48],
};

const HK6 = {
  children: [
    {
      tabId: 'TM',
      label: '特码',
      children: TM.TM,
    },
    {
      tabId: 'LIANGMIAN',
      label: '两面',
      children: LIANGMIAN.LIANGMIAN,
      settingCode: 'TMDSDX,LM'
    },
    {
      tabId: 'SB',
      label: '色波',
      children: SB.SB,
      settingCode: 'TMSB,TBBDS,TBBDX,TMBBB'
    },
    {
      tabId: 'TX',
      label: '特肖',
      children: TX.TX,
      settingCode: 'TMWS,TMTS,TMSX'
    },
    {
      tabId: 'HX',
      label: '合肖',
      children: HX.HX,
      settingCode: 'TMHX'
    },
    {
      tabId: 'ZMA',
      label: '正码',
      children: ZMA.ZMA,
      midCode: 'ZMA,LM'
    },
    {
      tabId: 'ZMT',
      label: '正码特',
      children: ZMT.ZMT,
      midCode: 'ZMT1,ZM1LM,ZM1SB,ZMT2,ZM2LM,ZM2SB,ZMT3,ZM3LM,ZM3SB,ZMT4,ZM4LM,ZM4SB,ZMT5,ZM5LM,ZM5SB,ZMT6,ZM6LM,ZM6SB',
    },
    {
      tabId: 'ZM16',
      label: '正码1-6',
      children: ZM16.ZM16,
      midCode: 'ZM1LM,ZM1SB,ZM2LM,ZM2SB,ZM3LM,ZM3SB,ZM4LM,ZM4SB,ZM5LM,ZM5SB,ZM6LM,ZM6SB'
    },
    {
      tabId: 'WX',
      label: '五行7色波',
      children: WX.WX,
      settingCode: 'TMWX,7SB'
    },
    {
      tabId: 'YXWS',
      label: '平特一肖尾数',
      children: YXWS.YXWS,
      settingCode: 'PTYX,PTWS'
    },
    {
      tabId: 'ZXZX',
      label: '正肖总肖',
      children: ZXZX.ZXZX,
      settingCode: 'ZENGX,ZX,ZXDS'
    },
    {
      tabId: 'ZXBZ',
      label: '自选不中',
      children: ZXBZ.ZXBZ,
    },
    {
      tabId: 'LX',
      label: '连肖',
      children: LX.LX,
    },
    {
      tabId: 'LW',
      label: '连尾',
      children: LW.LW,
    },
    {
      tabId: 'LM',
      label: '连码',
      children: LM.LM,
      midCode: 'L3QZ,L3Z2Z2,L2QZ,L2ZT,LTC'
    }
  ],
  resultTab: {
    headerCell: [{
      attr: '期数'
    }, {
      attr: '日期'
    }, {
      attr: () => [[{
        value: '彩球号码'
      }], [{
        value: '一'
      }, {
        value: '二'
      }, {
        value: '三'
      }, {
        value: '四'
      }, {
        value: '五'
      }, {
        value: '六'
      }, {
        value: '特'
      }]],
      wrapperStyle: 'hk6Header',
    },
    {
      wrapperStyle: 'hk6Header',
      attr: () => [[{
        value: '特',
        className: 'singleCol m-auto'
      }], [{
        value: '单双',
        className: 'singleCol m-auto'
      }]]
    }, {
      wrapperStyle: 'hk6Header',
      attr: () => [[{
        value: '特',
        className: 'singleCol m-auto'
      }], [{
        value: '大小',
        className: 'singleCol m-auto'
      }]]
    }, {
      wrapperStyle: 'hk6Header',
      attr: () => [[{
        value: '特',
        className: 'singleCol m-auto'
      }], [{
        value: '大中小',
        className: 'doubleCol m-auto'
      }]]
    }, {
      wrapperStyle: 'hk6Header',
      attr: () => [[{
        value: '特合',
        className: 'singleCol m-auto'
      }], [{
        value: '大小',
        className: 'singleCol m-auto'
      }]]
    }, {
      wrapperStyle: 'hk6Header',
      attr: () => [[{
        value: '特合',
        className: 'singleCol m-auto'
      }], [{
        value: '单双',
        className: 'singleCol m-auto'
      }]]
    }, {
      wrapperStyle: 'hk6Header',
      attr: () => [[{
        value: '特合尾',
        className: 'doubleCol m-auto'
      }], [{
        value: '大小',
        className: 'doubleCol m-auto'
      }]]
    }, {
      attr: '总分',
      wrapperStyle: 'singleCol m-auto p-0',
    }, {
      wrapperStyle: 'hk6Header',
      attr: () => [[{
        value: '总',
        className: 'singleCol m-auto'
      }], [{
        value: '单双',
        className: 'singleCol m-auto'
      }]]
    }, {
      wrapperStyle: 'hk6Header',
      attr: () => [[{
        value: '总',
        className: 'singleCol m-auto'
      }], [{
        value: '大小',
        className: 'singleCol m-auto'
      }]]
    },
    {
      attr: '波段',
      wrapperStyle: 'singleCol m-auto p-0',
    }],
    bodyCell: [{
      attr: 'installments',
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: renderResultDateCell,
      wrapperStyle: 'dateCol'
    }, {
      attr: renderBall,
      wrapperStyle: 'd-flex hk6BallCol'
    },
    {
      attr: TDX_DS,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: TDX_DX,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: TDX_DZX,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: TW_DX,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: TH_DS,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: THW_DX,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: Z,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: Z_DS,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: Z_DX,
      wrapperStyle: 'd-flex justify-content-center align-items-center'
    }, {
      attr: BD,
      wrapperStyle: 'd-flex justify-content-center align-items-center hk6BallCol'
    }
    ]
  },
  KuaiJieShortCut: {
    numberPanel: {
      numberOfColumn: 5,
      checkOdds: true,
      padLeadingZero: 2,
      itemClickEvent: ({ onItemClick, obj }) => onItemClick(obj),
      list: [
        {
          displayName: '01',
          code: 'TMA1',
          className: 'redText'
        },
        {
          displayName: '11',
          code: 'TMA11',
          className: 'greenText'
        },
        {
          displayName: '21',
          code: 'TMA21',
          className: 'greenText'
        },
        {
          displayName: '31',
          code: 'TMA31',
          className: 'blueText'
        },
        {
          displayName: '41',
          code: 'TMA41',
          className: 'blueText'
        },
        {
          displayName: '02',
          code: 'TMA2',
          className: 'redText'
        },
        {
          displayName: '12',
          code: 'TMA12',
          className: 'redText'
        },
        {
          displayName: '22',
          code: 'TMA22',
          className: 'greenText'
        },
        {
          displayName: '32',
          code: 'TMA32',
          className: 'greenText'
        },
        {
          displayName: '42',
          code: 'TMA42',
          className: 'blueText'
        },
        {
          displayName: '03',
          code: 'TMA3',
          className: 'blueText'
        },
        {
          displayName: '13',
          code: 'TMA13',
          className: 'redText'
        },
        {
          displayName: '23',
          code: 'TMA23',
          className: 'redText'
        },
        {
          displayName: '33',
          code: 'TMA33',
          className: 'greenText'
        },
        {
          displayName: '43',
          code: 'TMA43',
          className: 'greenText'
        },
        {
          displayName: '04',
          code: 'TMA4',
          className: 'blueText'
        },
        {
          displayName: '14',
          code: 'TMA14',
          className: 'blueText'
        },
        {
          displayName: '24',
          code: 'TMA24',
          className: 'redText'
        },
        {
          displayName: '34',
          code: 'TMA34',
          className: 'redText'
        },
        {
          displayName: '44',
          code: 'TMA44',
          className: 'greenText'
        },
        {
          displayName: '05',
          code: 'TMA5',
          className: 'greenText'
        },
        {
          displayName: '15',
          code: 'TMA15',
          className: 'blueText'
        },
        {
          displayName: '25',
          code: 'TMA25',
          className: 'blueText'
        },
        {
          displayName: '35',
          code: 'TMA35',
          className: 'redText'
        },
        {
          displayName: '45',
          code: 'TMA45',
          className: 'redText'
        },
        {
          displayName: '06',
          code: 'TMA6',
          className: 'greenText'
        },
        {
          displayName: '16',
          code: 'TMA16',
          className: 'greenText'
        },
        {
          displayName: '26',
          code: 'TMA26',
          className: 'blueText'
        },
        {
          displayName: '36',
          code: 'TMA36',
          className: 'blueText'
        },
        {
          displayName: '46',
          code: 'TMA46',
          className: 'redText'
        },
        {
          displayName: '07',
          code: 'TMA7',
          className: 'redText'
        },
        {
          displayName: '17',
          code: 'TMA17',
          className: 'greenText'
        },
        {
          displayName: '27',
          code: 'TMA27',
          className: 'greenText'
        },
        {
          displayName: '37',
          code: 'TMA37',
          className: 'blueText'
        },
        {
          displayName: '47',
          code: 'TMA47',
          className: 'blueText'
        },
        {
          displayName: '08',
          code: 'TMA8',
          className: 'redText'
        },
        {
          displayName: '18',
          code: 'TMA18',
          className: 'redText'
        },
        {
          displayName: '28',
          code: 'TMA28',
          className: 'greenText'
        },
        {
          displayName: '38',
          code: 'TMA38',
          className: 'greenText'
        },
        {
          displayName: '48',
          code: 'TMA48',
          className: 'blueText'
        },
        {
          displayName: '09',
          code: 'TMA9',
          className: 'blueText'
        },
        {
          displayName: '19',
          code: 'TMA19',
          className: 'redText'
        },
        {
          displayName: '29',
          code: 'TMA29',
          className: 'redText'
        },
        {
          displayName: '39',
          code: 'TMA39',
          className: 'greenText'
        },
        {
          displayName: '49',
          code: 'TMA49',
          className: 'greenText'
        },
        {
          displayName: '10',
          code: 'TMA10',
          className: 'blueText'
        },
        {
          displayName: '20',
          code: 'TMA20',
          className: 'blueText'
        },
        {
          displayName: '30',
          code: 'TMA30',
          className: 'redText'
        },
        {
          displayName: '40',
          code: 'TMA40',
          className: 'redText'
        }
      ]
    },
    quickSelectPanel: {
      numberOfColumn: 4,
      itemClickEvent: ({ onItemClick, obj, resetItemInCart, _selectedItemArray, setSelectedItemArray, zodiac }) => {
        let result = [];
        resetItemInCart();
        const selectedItemArray = _selectedItemArray;
        if (selectedItemArray?.[obj.displayName]) {
          delete selectedItemArray[obj.displayName];
        } else {
          selectedItemArray[obj.displayName] = quickBetDictionary[obj.displayName] || (renderHeXiaoBalls(zodiac, obj.displayName, 49).map(item => item.value));
        }
        [...Object.values(selectedItemArray)].forEach(item => result = [...new Set(result.concat(item))]);
        result.forEach(item => {
          const _obj = {
            displayName: item < 10 ? `0${item}` : `${item}`,
            code: `TMA${item}`,
          };
          onItemClick(_obj, true);
        });
        setSelectedItemArray(selectedItemArray);
      },
      list: [
        {
          displayName: '红波',
          className: 'redText'
        },
        {
          displayName: '蓝波',
          className: 'blueText'
        },
        {
          displayName: '绿波',
          className: 'greenText'
        },
        {
          displayName: '红大',
          className: 'redText',
          newLine: true
        },
        {
          displayName: '蓝大',
          className: 'blueText'
        },
        {
          displayName: '绿大',
          className: 'greenText'
        },
        {
          displayName: '大号',
          className: 'redText'
        },
        {
          displayName: '红小',
          className: 'redText'
        },
        {
          displayName: '蓝小',
          className: 'blueText'
        },
        {
          displayName: '绿小',
          className: 'greenText'
        },
        {
          displayName: '小号',
          className: 'redText'
        },
        {
          displayName: '红单',
          className: 'redText'
        },
        {
          displayName: '蓝单',
          className: 'blueText'
        },
        {
          displayName: '绿单',
          className: 'greenText'
        },
        {
          displayName: '单号',
          className: 'redText'
        },
        {
          displayName: '红双',
          className: 'redText'
        },
        {
          displayName: '蓝双',
          className: 'blueText'
        },
        {
          displayName: '绿双',
          className: 'greenText'
        },
        {
          displayName: '双号',
          className: 'redText'
        },
        {
          displayName: '大单',
          className: 'redText'
        },
        {
          displayName: '大双',
          className: 'blueText'
        },
        {
          displayName: '小单',
          className: 'greenText'
        },
        {
          displayName: '小双',
          className: 'redText'
        },
        {
          displayName: '合单',
          className: 'redText'
        },
        {
          displayName: '合双',
          className: 'redText'
        },
        {
          displayName: '鼠',
          className: 'redText',
          newLine: true
        },
        {
          displayName: '牛',
          className: 'redText',
        },
        {
          displayName: '虎',
          className: 'redText',
        },
        {
          displayName: '兔',
          className: 'redText',
        },
        {
          displayName: '龙',
          className: 'redText',
        },
        {
          displayName: '蛇',
          className: 'redText',
        },
        {
          displayName: '马',
          className: 'redText',
        },
        {
          displayName: '羊',
          className: 'redText',
        },
        {
          displayName: '猴',
          className: 'redText',
        },
        {
          displayName: '鸡',
          className: 'redText',
        },
        {
          displayName: '狗',
          className: 'redText',
        },
        {
          displayName: '猪',
          className: 'redText',
        },
      ]
    }
  },
  generateLengReObj: pastResult => {
    const resultItemCount = {};
    pastResult.forEach(item => {
      const openResultArr = item.openResult && item.openResult.split(',').map(x => parseInt(x, 10));
      if (openResultArr) {
        const TMKeyCode = `TMA${parseInt(openResultArr[openResultArr.length - 1], 10)}`;
        resultItemCount[TMKeyCode] = (resultItemCount[TMKeyCode] ? resultItemCount[TMKeyCode] + 1 : 1);
      }
    });

    return resultItemCount;
  },
  results: {}
};

export default HK6;