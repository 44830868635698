import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const Z3T = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '赔率', '金额'],
      mainColumnLength: 4,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      emptyItemStyle: ['column-3items-empty-label', 'column-3items-empty-odds', 'column-3items-empty-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'Z3T1',
          displayName: '01',
          className: 'circle circleRed',
        }, {
          code: 'Z3T11',
          displayName: '11',
          className: 'circle circleGreen',
        }, {
          code: 'Z3T21',
          displayName: '21',
          className: 'circle circleGreen',
        }, {
          code: 'Z3T31',
          displayName: '31',
          className: 'circle circleBlue',
        }, {
          code: 'Z3T2',
          displayName: '02',
          className: 'circle circleRed',
        }, {
          code: 'Z3T12',
          displayName: '12',
          className: 'circle circleRed',
        }, {
          code: 'Z3T22',
          displayName: '22',
          className: 'circle circleGreen',
        }, {
          code: 'Z3T32',
          displayName: '32',
          className: 'circle circleGreen',
        }, {
          code: 'Z3T3',
          displayName: '03',
          className: 'circle circleBlue',
        }, {
          code: 'Z3T13',
          displayName: '13',
          className: 'circle circleRed',
        }, {
          code: 'Z3T23',
          displayName: '23',
          className: 'circle circleRed',
        }, {
          code: 'Z3T33',
          displayName: '33',
          className: 'circle circleGreen',
        }, {
          code: 'Z3T4',
          displayName: '04',
          className: 'circle',
        }, {
          code: 'Z3T14',
          displayName: '14',
          className: 'circle',
        }, {
          code: 'Z3T24',
          displayName: '24',
          className: 'circle circleRed',
        }, {
          code: 'Z3T34',
          displayName: '34',
          className: 'circle circleRed',
        }, {
          code: 'Z3T5',
          displayName: '05',
          className: 'circle circleGreen',
        }, {
          code: 'Z3T15',
          displayName: '15',
          className: 'circle',
        }, {
          code: 'Z3T25',
          displayName: '25',
          className: 'circle',
        }, {
          code: 'Z3T35',
          displayName: '35',
          className: 'circle circleRed',
        }, {
          code: 'Z3T6',
          displayName: '06',
          className: 'circle circleGreen',
        }, {
          code: 'Z3T16',
          displayName: '16',
          className: 'circle circleGreen',
        }, {
          code: 'Z3T26',
          displayName: '26',
          className: 'circle',
        }, {
          code: 'Z3T36',
          displayName: '36',
          className: 'circle',
        }, {
          code: 'Z3T7',
          displayName: '07',
          className: 'circle circleRed',
        }, {
          code: 'Z3T17',
          displayName: '17',
          className: 'circle circleGreen',
        }, {
          code: 'Z3T27',
          displayName: '27',
          className: 'circle circleGreen',
        }, {
          code: 'Z3T8',
          displayName: '08',
          className: 'circle circleRed',
          newLine: true,
        }, {
          code: 'Z3T18',
          displayName: '18',
          className: 'circle circleRed',
        }, {
          code: 'Z3T28',
          displayName: '28',
          className: 'circle circleGreen',
        }, {
          code: 'Z3T9',
          displayName: '09',
          className: 'circle',
          newLine: true,
        }, {
          code: 'Z3T19',
          displayName: '19',
          className: 'circle circleRed',
        }, {
          code: 'Z3T29',
          displayName: '29',
          className: 'circle circleRed',
        }, {
          code: 'Z3T10',
          displayName: '10',
          className: 'circle',
          newLine: true,
        }, {
          code: 'Z3T20',
          displayName: '20',
          className: 'circle',
        }, {
          code: 'Z3T30',
          displayName: '30',
          className: 'circle circleRed',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default Z3T;