const F36X7 = {
  lotterySpecific: gameData => {
    const { code } = gameData || {};
    switch (code) {
      case 'FJ36X7':
        return '以下所有投注皆含本金 \n 该游戏的投注时间、开奖时间和开奖号码与“福建36选7 ”完全同步，每周二、周四、周六开奖。';
      default:
        return '';
    }
  },
  templateSpecific: () => ([{
    header: '两面',
    body: [
      '单双：依正码/特码开奖结果，单数球号为单、双数球号为双。',
      '大小：依正码/特码开奖结果，1~18为小、19~36为大。',
      '合单双：依正码/特码开奖结果，十位数与个位数相加为单数，是合单；为双数，是合双；当结果为1、36，视为和局。 例 开奖结果为5，十位数0与个位数5相加为单数，是合单。 例 开奖结果为22，十位数2与个位数2相加为双数，是合双。',
      '合大小：依正码/特码开奖结果，十位数与个位数相加大于5，为合大；小于等于5，为合小；当结果为35、36，视为和局。 例 开奖结果为5，十位数0与个位数5相加等于5，是合小。 例 开奖结果为33，十位数3与个位数3相加等于6，是合大。',
      '大小尾：依正码/特码开奖结果，球号之个位数大于等于5，为大尾；小于5，为小尾；当结果为1、2，视为和局。',
      '总分单双：依开奖结果，第1~8球总和为单数，是总分单；为双数，是总分双。',
      '总分大小：依开奖结果，第1~8球总和大于等于148，是总分大；小于148，是总分小。',
      '六肖：依生肖分组，每组三球，当特码开奖结果在所选的六组(共十八球)中，视为中奖。',
      '色波：依正码/特码开奖结果之球色，区分红波、蓝波、绿波。 球色号码分布如下： 红波：14 蓝波：11 绿波：11',
    ]
  }, {
    header: '连码',
    body: [
      '二全中 选择之号码每两个为一组，当组合内两个号码均为开奖结果之正码，视为中奖。 ***拖头：为连码投注方式，适用二全中。 例 以3拖5、22，即成立两注3-5、3-22。 例 以7拖6、8、25，即成立三注7-6、7-8、7-25。',
      '一字半：选择之号码每两个为一组，当组合内两个号码为特码及任一正码，视为中奖。',
      '三全中：选择之号码每三个为一组，当组合内三个号码均为开奖结果之正码，视为中奖。',
      '二字半：选择之号码每三个为一组，当组合内三个号码为特码及任两个正码，视为中奖。',
      '四全中：选择之号码每四个为一组，当组合内四个号码均为开奖结果之正码，视为中奖。',
      '三字半：选择之号码每四个为一组，当组合内三个号码为特码及任三个正码，视为中奖。',
    ]
  }, {
    header: '不中',
    body: [
      '投注4~14个号码为一组合，当开奖结果之正码、特码均未出现在组合内视为中奖。',
    ]
  }])
};

export default F36X7;