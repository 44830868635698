import React, { useEffect, useState } from 'react';

import { Col, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, cartActions, gameActions } from '../../../../redux/actions';
import { renderHeXiaoBalls, renderSeBoBalls, renderWSBalls, renderWuXingBalls, validateNumber } from '../../../../constants/utils';
import Images from '../../../Images';
import Label from '../../../Label';
import RenderMultipleCells from '../RenderMultipleCells';

import './styles.scss';

const mapStateToProps = state => ({
  gameInfo: state.placeBetReducers.gameInfo,
  presetAmount: state.gameReducers.presetAmount,
  cart: state.cartReducers.cart,
  sectionSelection: state.gameReducers.sectionSelection,
  companyInfo: state.companyReducers.companyInfo,
  selectedSubType: state.gameReducers.selectedSubType,
  betTypeSelection: state.gameReducers.betTypeSelection,
  selectedDan: state.gameReducers.selectedDan,
  currentSelected: state.gameReducers.currentSelected,
  lotteryType: state.gameReducers.lotteryType,
  template: state.gameReducers.template,
  lengReResult: state.resultHistoryReducers.lengReResult,
  yiLouCount: state.appReducers.yiLouCount,
  showLengRe: state.appReducers.showLengRe,
  showYiLou: state.appReducers.showYiLou,
});

const mapDispatchToProps = dispatch => ({
  addItemToCart: (item, reset) => dispatch(cartActions.addItemToCart(item, reset)),
  removeItemInCart: keyCode => dispatch(cartActions.removeItemInCart(keyCode)),
  updateSelectedDan: dan => dispatch(gameActions.updateSelectedDan(dan)),
  updateCurrentSelected: val => dispatch(gameActions.updateCurrentSelected(val)),
  resetItemInCart: () => dispatch(cartActions.resetItemInCart()),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  updateCart: cart => dispatch(cartActions.updateCart(cart)),
});

const ChooseBet = ({ _currentLengReResult, obj, showYiLou, updateCart, updateModalAlert, showLengRe, yiLouCount, betItemStyle, betItemList, gameInfo, template, cart, resetItemInCart, zodiacTotalBall, selectedDan, currentSelected, presetAmount, removeItemInCart, addItemToCart, sectionSelection, betTypeSelection, selectedSubType, companyInfo, updateSelectedDan, updateCurrentSelected, showProbability, lotteryType }) => {
  const { t } = useTranslation();
  const [_currentSelection, setCurrentSelection] = useState([]);
  const [_sectionSelection, setSectionSelection] = useState();
  const [_selectedDan, setSelectedDan] = useState([]);
  const [_gameInfo, setGameInfo] = useState();
  if (_sectionSelection && (template === 'HK6' || template === 'F36X7' || template === 'F31X7')) {
    switch (selectedSubType) {
      case 'ZXBZ':
        obj = {
          ...obj, code: _sectionSelection.code + obj.code
        };
        break;
      case 'LM':
        obj = {
          ...obj, code: _sectionSelection?.code?.slice(0, -1) + obj.code
        };
        break;
      case 'LX':
        obj = {
          ...obj, code: `${_sectionSelection?.code}${(_sectionSelection?.code?.slice(1, 2) - 2) * 12 + Number(obj.code)}`
        };
        break;
      case 'LW':
        obj = {
          ...obj, code: `${_sectionSelection?.code}${(_sectionSelection?.code?.slice(1, 2) - 2) * 10 + (_sectionSelection?.code?.includes('LWBZ') ? 30 : 0) + Number(obj.code)}`
        };
        break;
      case 'BZ':
        obj = {
          ...obj, code: `${_sectionSelection?.code}${Number(obj.code)}`
        };
        break;
      default:
        break;
    }
  }
  betItemList = obj.betItemList ? obj.betItemList : betItemList;
  betItemStyle = obj.betItemStyle ? obj.betItemStyle : betItemStyle;

  useEffect(() => {
    if (JSON.stringify(sectionSelection) !== JSON.stringify(_sectionSelection)) {
      setSectionSelection(sectionSelection);
    }
  }, [sectionSelection]);

  useEffect(() => {
    if (JSON.stringify(selectedDan) !== JSON.stringify(_selectedDan)) {
      setSelectedDan(selectedDan || []);
    }
  }, [selectedDan]);

  useEffect(() => {
    if (JSON.stringify(currentSelected) !== JSON.stringify(_currentSelection)) {
      setCurrentSelection(currentSelected || []);
    }
  }, [currentSelected]);

  useEffect(() => {
    if (JSON.stringify(gameInfo) !== JSON.stringify(_gameInfo)) {
      setGameInfo(gameInfo);
    }
  }, [gameInfo]);

  const checkChangesInCart = diff => {
    if (!cart) return;
    let count = 0;
    Object.keys(cart).forEach(key => {
      if (diff[key] !== undefined) count += 1;
    });
    return count;
  };

  useEffect(() => {
    if (JSON.stringify(gameInfo) !== JSON.stringify(_gameInfo)) {
      if (gameInfo && _gameInfo) {
        const { oddsList: newOddsList } = gameInfo || {};
        const { oddsList } = _gameInfo || {};
        if (newOddsList && oddsList) {
          const diff = difference(oddsList, newOddsList);
          if (Object.keys(diff).length > 0) {
            const changes = checkChangesInCart(diff);
            if (changes > 0) {
              updateModalAlert({
                visible: true,
                type: 'confirmation',
                data: {
                  message: t('X_AMOUNT_ODDS_CHANGE_ARE_YOU_SURE_TO_UPDATE').replace('X', changes),
                  confirmationText: t('UPDATE_IMMEDIATELY'),
                  onConfirmationClick: () => {
                    const tempCart = JSON.parse(JSON.stringify(cart));
                    Object.entries(tempCart).forEach(([key, item]) => {
                      if (diff[key]) {
                        tempCart[key] = {
                          ...item,
                          ...diff[key]
                        };
                      }
                    });
                    updateCart(tempCart);
                  },
                  cancelText: t('CANCEL_IMMEDIATELY'),
                  onCancelClick: () => {
                    const tempCart = JSON.parse(JSON.stringify(cart));
                    Object.entries(tempCart).forEach(([key]) => {
                      if (diff[key]) {
                        delete tempCart[key];
                      }
                    });
                    updateCart(tempCart);
                    updateModalAlert({
                      visible: false,
                    });
                  }
                }
              });
            }
          }
        }
      }
      setGameInfo(gameInfo);
    }
  }, [gameInfo]);

  const difference = (obj1, obj2) => {
    let keyFound = {};
    Object.keys(obj1).forEach(key => {
      if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
        keyFound = {
          ...keyFound,
          [key]: obj2[key]
        };
      }
    });
    return keyFound;
  };

  const generateClassName = (item, obj) => {
    switch (item) {
      case 'Odds':
        return 'chooseBetOdds';
      case 'ImageAndText':
      case 'DisplayOrText':
      case 'Type':
      case 'DisplayText':
        return obj.className;
      default:
        return '';
    }
  };

  const generateValue = (item, obj) => {
    const keyCode = obj.code;
    switch (item) {
      case 'Odds':
        const currentOddItem = _gameInfo && _gameInfo.oddsList && (_gameInfo.oddsList[`${_sectionSelection && _sectionSelection.value}${keyCode}`] || _gameInfo.oddsList[`${_sectionSelection && _sectionSelection.value}_${keyCode}`] || _gameInfo.oddsList[keyCode]);
        if (currentOddItem && currentOddItem?.enabled) {
          if (_sectionSelection && _sectionSelection.value === 'TMB' && keyCode.startsWith('TMA')) {
            return currentOddItem?.odds;
          }
          return currentOddItem?.odds;
        }
        return '--';

      case 'ImageAndText':
      case 'DisplayOrText':
      case 'DisplayText':
        if (typeof obj.displayName === 'function') {
          return obj.displayName(obj);
        }
        return obj.displayName;
      case 'Type':
        return (_gameInfo && _gameInfo.oddsList && _gameInfo.oddsList[keyCode] && _gameInfo.oddsList[keyCode].midType);
      default:
        return '';
    }
  };

  const checkCartForItem = obj => {
    if (selectedSubType === 'TM') {
      return cart && cart[obj.code] ? 'selected' : '';
    }
    return cart && (cart[(_sectionSelection && `${_sectionSelection.value}_${obj.code}`)] || cart[(_sectionSelection && `${_sectionSelection.value}${obj.code}`)] || cart[obj.code || obj.displayName]) ? 'selected' : '';
  };

  const handlePermutation = (arr, permutationLength, tempDan, amount) => {
    const tempResult = {};
    const result = [];
    function combine(input, len, start) {
      if (len === 0) {
        const _result = result.map(x => x.displayName).sort().join(',');
        const _dan = tempDan.map(x => x.displayName).sort().join(',');
        let oddsResult;
        if (selectedSubType === 'HX' || selectedSubType === 'LIUXIAO' || selectedSubType === 'LIANMA11X5' || selectedSubType === 'LM') {
          oddsResult = _gameInfo && _gameInfo.oddsList[_sectionSelection.code];
        } else if ((sectionSelection.code.includes('BZ') && selectedSubType !== 'LX') || (selectedSubType === 'LX' && !sectionSelection.code.includes('BZ'))) {
          // select smaller odds
          (tempDan.length > 0 ? tempDan : result).forEach(item => (oddsResult ? item.odds <= oddsResult.odds ? oddsResult = item : oddsResult : oddsResult = item));
        } else {
          // select bigger odds
          (tempDan.length > 0 ? tempDan : result).forEach(item => (oddsResult ? item.odds >= oddsResult.odds ? oddsResult = item : oddsResult : oddsResult = item));
        }
        const tempDisplayName = tempDan.length > 0 ? [_dan, _result].join(',') : _result;

        return tempResult[tempDisplayName] = {
          categoryName: oddsResult.midType,
          displayName: tempDisplayName,
          odds: oddsResult.odds,
          midType: oddsResult.midType,
          keyCode: oddsResult.keyCode,
          amount: amount || parseInt(presetAmount, 10) || 0,
        };
      }

      for (let i = start; i <= input.length - len; i++) {
        if (tempDan.length > 0) result[permutationLength - len] = input[i];
        else result[_sectionSelection.minimumSelection - len] = input[i];
        combine(input, len - 1, i + 1);
      }

      return null;
    }

    combine(arr, tempDan.length > 0 ? permutationLength : _sectionSelection.minimumSelection, 0);

    return tempResult;
  };


  const nestCalculation = (temp, oddsResult, amount) => {
    const tempResult = {};
    const tempA = [];
    const tempB = [];
    const tempC = [];
    if (selectedSubType === 'ZSS' || selectedSubType === 'ZSL') {
      const currentOddItem = _gameInfo && _gameInfo.oddsList && _gameInfo.oddsList[`${_sectionSelection && _sectionSelection.value}_${temp.length}`];

      const tempDisplayName = temp.map(x => x.displayName).join(',');
      tempResult[tempDisplayName] = {
        categoryName: currentOddItem.midType,
        displayName: tempDisplayName,
        odds: currentOddItem.odds,
        midType: currentOddItem.midType,
        keyCode: `${_sectionSelection.value}_${temp.length}`,
        amount: amount || parseInt(presetAmount, 10) || 0,
      };
    } else {
      temp.forEach(item => {
        if (item.keyCode.includes('A')) {
          tempA.push(item);
        }
        if (item.keyCode.includes('B')) {
          tempB.push(item);
        }
        if (item.keyCode.includes('C')) {
          tempC.push(item);
        }
      });
      for (let i = 0; i < tempA.length; i++) {
        for (let j = 0; j < tempB.length; j++) {
          if (_sectionSelection.iteration === 2) {
            const tempDisplayName = `${tempA[i].displayName},${tempB[j].displayName}`;
            tempResult[tempDisplayName] = {
              categoryName: oddsResult.midType,
              displayName: tempDisplayName,
              odds: oddsResult.odds,
              midType: oddsResult.midType,
              keyCode: _sectionSelection.value,
              amount: amount || parseInt(presetAmount, 10) || 0,
            };
          }
          if (_sectionSelection.iteration === 3) {
            for (let k = 0; k < tempC.length; k++) {
              const tempDisplayName = `${tempA[i].displayName},${tempB[j].displayName},${tempC[k].displayName}`;
              tempResult[tempDisplayName] = {
                categoryName: oddsResult.midType,
                displayName: tempDisplayName,
                odds: oddsResult.odds,
                midType: oddsResult.midType,
                keyCode: _sectionSelection.value,
                amount: amount || parseInt(presetAmount, 10) || 0,
              };
            }
          }
        }
      }
    }
    return tempResult;
  };

  const onItemClick = (obj, amount) => {
    if (!_gameInfo.oddsList) return;
    const keyCode = obj.code;
    const currentOddItem = _gameInfo && _gameInfo.oddsList && (_gameInfo.oddsList[`${_sectionSelection && _sectionSelection.value}${obj.code}`] || _gameInfo.oddsList[`${_sectionSelection && _sectionSelection.value}_${obj.code}`] || _gameInfo.oddsList[`${_sectionSelection && _sectionSelection.value}`] || _gameInfo.oddsList[obj.code]);

    const { midType, odds, enabled } = currentOddItem || {};

    if (enabled === 0) return;

    if (selectedSubType === 'LIANMA11X5' || selectedSubType === 'Q2ZX' || selectedSubType === 'Q3ZX' || selectedSubType === 'ZSS' || selectedSubType === 'ZSL' || selectedSubType === 'EZDW' || selectedSubType === 'SZDW' || selectedSubType === 'FSZH' || ((selectedSubType === 'BZ' || selectedSubType === 'LIUXIAO' || selectedSubType === 'L7T4' || selectedSubType === 'L3Z2' || selectedSubType === 'L7Z4') && template === 'F36X7') || (selectedSubType === 'BZ' && template === 'F31X7') || selectedSubType === 'LIANMA' || selectedSubType === 'HX' || selectedSubType === 'LX' || selectedSubType === 'LW' || selectedSubType === 'ZXBZ' || (selectedSubType === 'LM' && template === 'HK6')) {
      let tempSelectedBet;
      let tempDan;

      const isDan = betTypeSelection && betTypeSelection.value === t('DT');
      const isItemADan = selectedSubType !== 'HX' && selectedSubType !== 'LIUXIAO' && selectedSubType !== 'LIANMA11X5' ? _selectedDan.find(dan => dan.keyCode === obj.code) : _selectedDan.find(dan => dan.displayName === obj.displayName);
      const isItemABet = selectedSubType !== 'HX' && selectedSubType !== 'LIUXIAO' && selectedSubType !== 'LIANMA11X5' ? _currentSelection.find(bet => bet.keyCode === obj.code) : _currentSelection.find(bet => bet.displayName === obj.displayName);

      const item = {
        displayName: obj.displayName,
        amount: amount || presetAmount || 0,
        keyCode,
        midType,
        odds,
      };

      if (isDan) {
        if (_selectedDan.length < betTypeSelection.selectedDan && !isItemABet) {
          tempDan = [..._selectedDan, item];
          tempSelectedBet = _currentSelection;
        } else if (isItemADan) {
          tempDan = _selectedDan.filter(x => (selectedSubType !== 'LIANMA11X5' && selectedSubType !== 'HX' && selectedSubType !== 'LIUXIAO' ? x.keyCode !== item.keyCode : x.displayName !== item.displayName));
          tempSelectedBet = _currentSelection;
        } else if (isItemABet) {
          tempDan = _selectedDan;
          tempSelectedBet = _currentSelection.filter(x => (selectedSubType !== 'LIANMA11X5' && selectedSubType !== 'HX' && selectedSubType !== 'LIUXIAO' ? x.keyCode !== item.keyCode : x.displayName !== item.displayName));
        } else {
          tempDan = _selectedDan;
          tempSelectedBet = [..._currentSelection, item].sort((a, b) => a.displayName - b.displayName);
        }

        if (tempDan && tempSelectedBet && tempDan.length >= betTypeSelection.selectedDan && tempSelectedBet.length + tempDan.length >= _sectionSelection.minimumSelection) {
          const tempResult = tempSelectedBet || _currentSelection;
          const permutationLength = _sectionSelection.minimumSelection - tempDan.length;
          const result = handlePermutation(tempResult, permutationLength, tempDan, amount);
          addItemToCart(result, true);
        } else {
          resetItemInCart();
        }
        updateSelectedDan(tempDan);
        return updateCurrentSelected(tempSelectedBet);
      }
      if (_currentSelection.find(x => (x.keyCode === item.keyCode && ((selectedSubType === 'LIANMA11X5' || selectedSubType === 'Q2ZX' || selectedSubType === 'Q3ZX' || selectedSubType === 'SZDW' || selectedSubType === 'FSZH' || selectedSubType === 'EZDW' || selectedSubType === 'HX' || selectedSubType === 'LIUXIAO' || selectedSubType === 'L3Z2' || selectedSubType === 'L7T4' || selectedSubType === 'L3Z2' || selectedSubType === 'L7Z4' || selectedSubType === 'LM' || selectedSubType === 'LX' || selectedSubType === 'ZXBZ' || selectedSubType === 'LW' || selectedSubType === 'BZ') ? x.displayName === item.displayName : true)))) {
        if (selectedSubType === 'Q2ZX' || selectedSubType === 'Q3ZX' || selectedSubType === 'SZDW' || selectedSubType === 'EZDW' || selectedSubType === 'FSZH') {
          tempSelectedBet = _currentSelection.filter(x => !(x.displayName === item.displayName && x.keyCode === item.keyCode)).sort((a, b) => a.displayName - b.displayName);
        } else {
          tempSelectedBet = selectedSubType === 'LIANMA11X5' || selectedSubType === 'HX' || selectedSubType === 'LIUXIAO' || selectedSubType === 'L3Z2' || selectedSubType === 'L7T4' || selectedSubType === 'L3Z2' || selectedSubType === 'L7Z4' || selectedSubType === 'LM' || selectedSubType === 'LX' || selectedSubType === 'ZXBZ' || selectedSubType === 'LW' || selectedSubType === 'BZ' ? _currentSelection.filter(x => x.displayName !== item.displayName).sort((a, b) => a.displayName - b.displayName) : _currentSelection.filter(x => x.keyCode !== item.keyCode).sort((a, b) => a.displayName - b.displayName);
        }
      } else {
        tempSelectedBet = [..._currentSelection, item].sort((a, b) => a.displayName - b.displayName);
      }
      if (tempSelectedBet && sectionSelection && tempSelectedBet.length >= sectionSelection.minimumSelection && tempSelectedBet.length <= sectionSelection.maximumSelection) {
        const tempResult = tempSelectedBet || _currentSelection;
        let result;
        if (selectedSubType === 'Q2ZX' || selectedSubType === 'Q3ZX' || selectedSubType === 'SZDW' || selectedSubType === 'EZDW' || selectedSubType === 'FSZH' || selectedSubType === 'ZSS' || selectedSubType === 'ZSL') {
          result = nestCalculation(tempResult, item, amount);
          if (Object.keys(result).length > 400) {
            return updateModalAlert({
              type: 'confirmation',
              visible: true,
              data: {
                message: '单次投注不能超过400注！',
                overBet: true,
                onConfirmationClick: () => {
                  tempSelectedBet = _currentSelection.filter(x => !(x.displayName === item.displayName && x.keyCode === item.keyCode)).sort((a, b) => a.displayName - b.displayName);
                  updateCurrentSelected(tempSelectedBet);
                },
                showCancelButton: false
              }
            });
          }
        } else {
          result = handlePermutation(tempResult, _sectionSelection.minimumSelection, [], amount);
        }
        addItemToCart(result, true);
      } else {
        resetItemInCart();
      }
      return updateCurrentSelected(tempSelectedBet);
    }
    if (cart && cart[keyCode] && !amount) {
      removeItemInCart(keyCode);
    } else if (obj && currentOddItem) {
      if (selectedSubType === 'TM') {
        const tempMidType = selectedSubType === 'TM' ? (_sectionSelection.value === 'TMA' ? '特A' : '特B') : midType;
        return addItemToCart({
          [keyCode]: {
            displayName: obj.displayName,
            amount: amount || presetAmount || 0,
            midType: tempMidType,
            isTmb: _sectionSelection.value === 'TMA' ? 0 : 1,
            odds,
            keyCode,
          }
        });
      }
      if (selectedSubType === 'EZHS' || selectedSubType === 'SZHS' || selectedSubType === 'EZZH' || selectedSubType === 'SZZH') {
        const keyCode = `${_sectionSelection.value}${obj.code}`;
        const currentOddItem = _gameInfo.oddsList[keyCode];
        const { midType, odds, enabled } = currentOddItem || {};
        if (enabled === 0) return;
        if (cart && cart[keyCode]) {
          return removeItemInCart(keyCode);
        }
        return addItemToCart({
          [keyCode]: {
            displayName: obj.displayName,
            amount: presetAmount || 0,
            midType,
            keyCode,
            odds,
          }
        });
      }
      return addItemToCart({
        [keyCode]: {
          displayName: obj.displayName,
          amount: amount || presetAmount || 0,
          midType,
          keyCode,
          odds,
          isTmb: selectedSubType === 'ZMTB' ? 1 : undefined,
        }
      });
    }
  };

  const checkDisabled = obj => {
    let totalSelected = 0;
    if (currentSelected) {
      totalSelected += currentSelected.length;
    }

    if (selectedDan) {
      totalSelected += selectedDan.length;
    }
    if (currentSelected && currentSelected.find(x => (selectedSubType !== 'LIANMA11X5' && selectedSubType !== 'HX' && selectedSubType !== 'LIUXIAO' ? x.keyCode === obj.code : x.displayName === obj.displayName))) {
      return false;
    } if (selectedDan && selectedDan.find(x => (selectedSubType !== 'LIANMA11X5' && selectedSubType !== 'HX' && selectedSubType !== 'LIUXIAO' ? x.keyCode === obj.code : x.displayName === obj.displayName))) {
      return false;
    } if (_sectionSelection && _sectionSelection.maximumSelection === totalSelected) {
      return true;
    } if (!_gameInfo?.oddsList) {
      return true;
    } if (selectedSubType === 'HX' && selectedSubType === 'LIUXIAO' && !_sectionSelection) {
      return true;
    }
    if ((selectedSubType === 'Q2ZX' || selectedSubType === 'Q3ZX') && currentSelected) {
      if (currentSelected.find(x => x.displayName === obj.displayName && x.keyCode !== obj.code)) {
        return true;
      }
      return false;
    }
    return false;
  };

  const renderProbability = (obj, currentItem) => {
    if (showProbability && currentItem === 'DisplayText') {
      const { value, className, classNameYiLou } = (obj.generateLengReValue && _currentLengReResult && obj.generateLengReValue(_currentLengReResult, yiLouCount)) || {};
      return (
        <div className="probabilityWrapper">
          {
            showLengRe && (
              <Label className={`lengreValueWrapper ${className}`} value={(value) || 0} />
            )
          }
          {
            showYiLou && (
              <Label className={`yilouValueWrapper ${classNameYiLou}`} value={Number.isNaN(yiLouCount - value) ? 0 : (yiLouCount - value)} />
            )
          }
        </div>
      );
    }
  };
  return (
    <div className="d-flex justify-content-center chooseBet">
      { betItemList.map((item, index) => {
        let Component;
        if (item === 'DisplayText' || item === 'Odds' || item === 'Type') {
          Component = <Label className={`label ${generateClassName(item, obj)} ${item === 'DisplayText' && showProbability && (showLengRe || showYiLou) ? 'probabilityExist' : ''}`} value={generateValue(item, obj)} />;
        } else if (item === 'DisplayOrText') {
          if (obj.image) {
            if (template === 'KL10' && lotteryType.item !== 'CQXYNC') {
              Component = <Label className={generateClassName(item, obj)} value={generateValue(item, obj)} />;
            } else {
              Component = <Images horizontal={obj.isHorizontal} source={obj.image} />;
            }
          } else {
            Component = <Label className={generateClassName(item, obj)} value={generateValue(item, obj)} />;
          }
        } else if (item === 'ImageAndText') {
          Component = (
            <Col className="py-2">
              <Images horizontal={obj.isHorizontal} source={obj.image} />
              <Label className={generateClassName(item, obj)} value={generateValue(item, obj)} />
            </Col>
          );
        } else if (item === 'Input') {
          Component = (
            <Input
              type="text"
              value={(cart && (cart[(_sectionSelection && `${_sectionSelection.value}_${obj.code}`)] || cart[(_sectionSelection && `${_sectionSelection.value}${obj.code}`)] || cart[obj.code || obj.displayName]) && (cart[(_sectionSelection && `${_sectionSelection.value}_${obj.code}`)] || cart[(_sectionSelection && `${_sectionSelection.value}${obj.code}`)] || cart[obj.code || obj.displayName]).amount) || ''}
              className={`${obj.inputGroupStyle || 'chooseBetInput'} oddsInput`}
              onClick={e => e.stopPropagation()}
              onChange={({ target: { value: text } }) => {
                if (checkDisabled(obj)) return;
                onItemClick(obj, validateNumber(text));
              }}
            />
          );
        } else if (item === 'HK6_Zodiac') {
          const { zodiac } = companyInfo.company || {};
          Component = <RenderMultipleCells data={renderHeXiaoBalls(zodiac, obj.displayName, zodiacTotalBall)} />;
        } else if (item === 'HK6_SeBo_Balls') {
          Component = <RenderMultipleCells data={renderSeBoBalls(obj.displayName)} />;
        } else if (item === 'HK6_WX') {
          Component = <RenderMultipleCells data={renderWuXingBalls(obj.displayName)} />;
        } else if (item === 'HK6_WS') {
          Component = <RenderMultipleCells data={renderWSBalls(obj.displayName)} />;
        } else {
          Component = (
            <>
              <Input
                type="checkbox"
                disabled={checkDisabled(obj)}
                checked={!!((currentSelected && (selectedSubType === 'Q2ZX' || selectedSubType === 'Q3ZX' || selectedSubType === 'EZDW' || selectedSubType === 'SZDW' || selectedSubType === 'FSZH') && currentSelected.find(x => x.keyCode === obj.code && x.displayName === obj.displayName)) || (currentSelected && (selectedSubType !== 'LIANMA11X5' && selectedSubType !== 'HX' && selectedSubType !== 'LIUXIAO' ? currentSelected.find(x => x.keyCode === obj.code) : currentSelected.find(x => x.displayName === obj.displayName))) || (selectedDan && (selectedSubType === 'LIANMA11X5' || selectedSubType === 'HX' || selectedSubType === 'LIUXIAO' ? selectedDan.find(x => x.displayName === obj.displayName) : selectedDan.find(x => x.keyCode === obj.code))))}
                className={`${_selectedDan.find(x => (selectedSubType !== 'LIANMA11X5' && selectedSubType !== 'HX' && selectedSubType !== 'LIUXIAO' ? x.keyCode === obj.code : x.displayName === obj.displayName)) && 'showDan'} ${obj.inputGroupStyle || 'chooseBetCheckbox'}`}
              />
              {
                _selectedDan.find(x => (selectedSubType !== 'LIANMA11X5' && selectedSubType !== 'HX' && selectedSubType !== 'LIUXIAO' ? x.keyCode === obj.code : x.displayName === obj.displayName)) && (
                  <span className="danText">{t('DAN')}</span>
                )
              }
            </>
          );
        }
        return (
          <div
            key={`choose_bet_${index.toString()}`}
            className={`${obj && betItemStyle[index]} ${checkCartForItem(obj)} ${template === 'HK6' && 'hk6CellBackgroundColor'}`}
            onClick={() => {
              if (checkDisabled(obj)) return;
              onItemClick(obj);
            }}
          >
            {obj && Component }
            {renderProbability(obj, item)}
          </div>
        );
      }) }
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseBet);