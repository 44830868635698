import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const LM = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '总和',
      cycleHeaderTitle: true,
      mainColumnLength: 3,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'ZM21',
          displayName: '总和大',
        }, {
          code: 'ZM23',
          displayName: '总和单',
        }, {
          code: 'ZM25',
          displayName: '总和尾大',
        }, {
          code: 'ZM22',
          displayName: '总和小',
        }, {
          code: 'ZM24',
          displayName: '总和双',
        }, {
          code: 'ZM26',
          displayName: '总和尾小',
        },
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: false,
      tableHeadertitle: ['第一球', '第二球', '第三名', '第四名'],
      mainColumnLength: 4,
      subColumnLength: 1,
      subColumnStyle: ['column-1item-style'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'B1LM21',
          displayName: '大',
        }, {
          code: 'B2LM21',
          displayName: '大',
        }, {
          code: 'B3LM21',
          displayName: '大',
        }, {
          code: 'B4LM21',
          displayName: '大',
        }, {
          code: 'B1LM22',
          displayName: '小',
        }, {
          code: 'B2LM22',
          displayName: '小',
        }, {
          code: 'B3LM22',
          displayName: '小',
        }, {
          code: 'B4LM22',
          displayName: '小',
        }, {
          code: 'B1LM23',
          displayName: '单',
        }, {
          code: 'B2LM23',
          displayName: '单',
        }, {
          code: 'B3LM23',
          displayName: '单',
        }, {
          code: 'B4LM23',
          displayName: '单',
        }, {
          code: 'B1LM24',
          displayName: '双',
        }, {
          code: 'B2LM24',
          displayName: '双',
        }, {
          code: 'B3LM24',
          displayName: '双',
        }, {
          code: 'B4LM24',
          displayName: '双',
        }, {
          code: 'B1LM25',
          displayName: '尾大',
        }, {
          code: 'B2LM25',
          displayName: '尾大',
        }, {
          code: 'B3LM25',
          displayName: '尾大',
        }, {
          code: 'B4LM25',
          displayName: '尾大',
        }, {
          code: 'B1LM26',
          displayName: '尾小',
        }, {
          code: 'B2LM26',
          displayName: '尾小',
        }, {
          code: 'B3LM26',
          displayName: '尾小',
        }, {
          code: 'B4LM26',
          displayName: '尾小',
        }, {
          code: 'B1LM27',
          displayName: '合单',
        }, {
          code: 'B2LM27',
          displayName: '合单',
        }, {
          code: 'B3LM27',
          displayName: '合单',
        }, {
          code: 'B4LM27',
          displayName: '合单',
        }, {
          code: 'B1LM28',
          displayName: '合双',
        }, {
          code: 'B2LM28',
          displayName: '合双',
        }, {
          code: 'B3LM28',
          displayName: '合双',
        }, {
          code: 'B4LM28',
          displayName: '合双',
        }, {
          code: 'B1LM29',
          displayName: '龙',
        }, {
          code: 'B2LM29',
          displayName: '龙',
        }, {
          code: 'B3LM29',
          displayName: '龙',
        }, {
          code: 'B4LM29',
          displayName: '龙',
        }, {
          code: 'B1LM30',
          displayName: '虎',
        }, {
          code: 'B2LM30',
          displayName: '虎',
        }, {
          code: 'B3LM30',
          displayName: '虎',
        }, {
          code: 'B4LM30',
          displayName: '虎',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: false,
      tableHeadertitle: ['第五名', '第六球', '第七球', '第八名'],
      mainColumnLength: 4,
      subColumnLength: 1,
      subColumnStyle: ['column-1item-style'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'B5LM21',
          displayName: '大',
        }, {
          code: 'B6LM21',
          displayName: '大',
        }, {
          code: 'B7LM21',
          displayName: '大',
        }, {
          code: 'B8LM21',
          displayName: '大',
        }, {
          code: 'B5LM22',
          displayName: '小',
        }, {
          code: 'B6LM22',
          displayName: '小',
        }, {
          code: 'B7LM22',
          displayName: '小',
        }, {
          code: 'B8LM22',
          displayName: '小',
        }, {
          code: 'B5LM23',
          displayName: '单',
        }, {
          code: 'B6LM23',
          displayName: '单',
        }, {
          code: 'B7LM23',
          displayName: '单',
        }, {
          code: 'B8LM23',
          displayName: '单',
        }, {
          code: 'B5LM24',
          displayName: '双',
        }, {
          code: 'B6LM24',
          displayName: '双',
        }, {
          code: 'B7LM24',
          displayName: '双',
        }, {
          code: 'B8LM24',
          displayName: '双',
        }, {
          code: 'B5LM25',
          displayName: '尾大',
        }, {
          code: 'B6LM25',
          displayName: '尾大',
        }, {
          code: 'B7LM25',
          displayName: '尾大',
        }, {
          code: 'B8LM25',
          displayName: '尾大',
        }, {
          code: 'B5LM26',
          displayName: '尾小',
        }, {
          code: 'B6LM26',
          displayName: '尾小',
        }, {
          code: 'B7LM26',
          displayName: '尾小',
        }, {
          code: 'B8LM26',
          displayName: '尾小',
        }, {
          code: 'B5LM27',
          displayName: '合单',
        }, {
          code: 'B6LM27',
          displayName: '合单',
        }, {
          code: 'B7LM27',
          displayName: '合单',
        }, {
          code: 'B8LM27',
          displayName: '合单',
        }, {
          code: 'B5LM28',
          displayName: '合双',
        }, {
          code: 'B6LM28',
          displayName: '合双',
        }, {
          code: 'B7LM28',
          displayName: '合双',
        }, {
          code: 'B8LM28',
          displayName: '合双',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default LM;