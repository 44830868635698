const HK6 = {
  lotterySpecific: gameData => {
    const { interval, lotteryBeginTimeBase, lotteryEndTimeBase, code, lotteryTermCycle } = gameData || {};
    const endTimeString = lotteryEndTimeBase ? `开到${lotteryEndTimeBase}` : '';
    const intervalString = interval % 60 === 0 ? `${interval / 60}分` : `${interval}秒`;
    switch (code) {
      case 'MCHK6':
        return '以下所有投注皆含本金\n该游戏保证公平公正；您可以放心投注、开奖时间，北京时间（GMT+8）21:32，每天一期.';
      case 'TWHK6':
        return '以下所有投注皆含本金\n该游戏保证公平公正；您可以放心投注、开奖时间北京时间（GMT+8）20:50，每周二，周五开奖';
      case 'JSHK6':
      case 'YHHK6':
        return `以下所有投注皆含本金\n该游戏保证公平公正；您可以放心投注、开奖时间，北京时间（GMT+8）每天从${lotteryBeginTimeBase}开始，每${intervalString}钟开一次奖${endTimeString},每天开奖${lotteryTermCycle}期。`;
      case 'HK6':
        return '以下所有投注皆含本金\n该游戏保证公平公正；您可以放心投注、开奖时间，每周二、周五开奖.';
      default:
        return '';
    }
  },
  templateSpecific: activeGame => {
    const { lotteryName } = activeGame || {};
    return ([{
      header: '特码',
      body: [
        `${lotteryName}公司当期开出的最後一码为特码。`,
      ]
    }, {
      header: '特码大小',
      body: [
        '特小：开出的特码，(01~24)小于或等于24。',
        '特大：开出的特码，(25~48)小于或等于48。',
        '和局：特码为49时。',
      ]
    }, {
      header: '特码单双',
      body: [
        '特双：特码为双数，如18、20、34、42。',
        '特单：特码为单数，如01，11，35，47。',
        '和局：特码为49时。',
      ]
    }, {
      header: '特码合数单双',
      body: [
        '特双：指开出特码的个位加上十位之和为‘双数’，如02，11，33，44。',
        '特单：指开出特码的个位加上十位之和为‘单数’，如01，14，36，47。',
        '和局：特码为49时。',
      ]
    }, {
      header: '特码尾数大小',
      body: [
        '特尾大：5尾~9尾为大，如05、18、19。',
        '特尾小：0尾~4尾为小，如01，32，44。',
        '和局：特码为49时。',
      ]
    }, {
      header: '特码半特',
      body: [
        '以特码大小与特码单双游戏为一个投注组合；当期特码开出符合投注组合，即视为中奖；若当期特码开出49号，其余情形视为不中奖。',
        '大单：25、27、29、31、33、35、37、39，41、43、45、47',
        '大双：26、28、30、32、34、36、38、40，42、44、46、48',
        '小单：01、03、05、07、09、11、13、15，17、19、21、23',
        '小双：02、04、06、08、10、12、14、16，18、20、22、24',
        '和局：特码为49时。',
      ]
    }, {
      header: '特码合数大小',
      body: [
        '合数大：特码的个位加上十位之和来决定大小，和数大于或等于7为大。',
        '合数小：特码的个位加上十位之和来决定大小，和数小于或等于6为小。',
        '和局：特码为49时。',
      ]
    }, {
      header: '正码',
      body: [
        `${lotteryName}彩公司每期开出的前面六个号码为正码，下注号码如在六个正码号码里中奖。`,
      ]
    }, {
      header: '总和大小',
      body: [
        '总和大：所以七个开奖号码的分数总和大于或等于175。',
        '总和小：所以七个开奖号码的分数总和小于或等于174。',
      ]
    }, {
      header: '总和单双',
      body: [
        '总和单：所以七个开奖号码的分数总和是‘单数’，如分数总和是133、197。',
        '总和双：所以七个开奖号码的分数总和是‘双数’，如分数总和是120、188。',
      ]
    }, {
      header: '正肖',
      body: [
        '以开出的6个正码做游戏，只要有1个落在下注生肖范围内，视为中奖。如超过1个正码落在下注生肖范围内 ，派彩将倍增！如：下注＄100.-正肖龙倍率1.8。',
        '6个正码开出01，派彩为＄80',
        '6个正码开出01，13，派彩为＄160',
        '6个正码开出01，13，25，派彩为＄240',
        '6个正码开出01，13，25，37，派彩为＄320',
        '6个正码开出01，13，25，37，49，派彩为＄320',
      ]
    }, {
      header: '正码特',
      body: [
        '正码特是指 正1特、正2特、正3特、正4特、正5特、正6特。',
        '其下注的正码特号与现场摇珠开出之正码其开奖顺序及开奖号码相同，视为中奖。',
        '如现场摇珠第一个正码开奖为49号，下注第一个正码特为49则视为中奖，其它号码视为不中奖。',
      ]
    }, {
      header: '正码过关',
      body: [
        '可同时挑选多项赛事，串联成投注组合，其赔率为所选串当时赔率的总乘积。',
        '只要当期所开出之中奖结果符合您所选定之串联赛事，即视为中奖。',
        '如投注组合中某一个号码为49号和时，该组合将该串赔率以1为计算。',
      ]
    }, {
      header: '正码1-6',
      body: [
        `${lotteryName}彩公司当期开出之前6个号码叫正码。第一时间出来的叫正码1，依次为正码2、正码3┅┅ 正码6(并不以号码大小排序)。正码1、正码2、正码3、正码4、正码5、正码6的大小单双合单双和特别号码的大小单双规则相同，如正码1为31，则正码1为大，为单，为合双，为合小；正码2为08，则正码2为小，为双，为合双，为合大；号码为49则为和。假如投注组合符合中奖结果，视为中奖。 正码1-6色波下注开奖之球色与下注之颜色相同时，视为中奖。其余情形视为不中奖。`,
      ]
    }, {
      header: '半波',
      body: [
        '以特码色波和特单，特双，特大，特小为一个投注组合，当期特码开出符合投注组合，即视为中奖； 若当期特码开出49号，则视为和局；其余情形视为不中奖。',
      ]
    }, {
      header: '半半波',
      body: [
        '以特码色波和特单，特双，特大，特小为一个投注组合，当期特码开出符合投注组合，即视为中奖； 若当期特码开出49号，则视为和局；其余情形视为不中奖。',
      ]
    }, {
      header: '特码生肖',
      body: [
        '生肖顺序为 鼠 >牛 >虎 >兔 >龙 >蛇 >马 >羊 >猴 >鸡 >狗 >猪 。',
        '如今年是羊年，就以羊为开始，依顺序将49个号码分为12个生肖『如下』',
        '羊：01、13、25、37、49',
        '猴：12、24、36、48',
        '鸡：11、23、35、47',
        '狗：10、22、34、46',
        '猪：09、21、33、45',
        '鼠：08、20、32、44',
        '牛：07、19、31、43',
        '虎：06、18、30、42',
        '兔：05、17、29、41',
        '龙：04、16、28、40',
        '蛇：03、15、27、39',
        '马：02、14、26、38',
        ' 若当期特别号，落在下注生肖范围内，视为中奖 。',
      ]
    }, {
      header: '特码色波',
      body: [
        `${lotteryName}彩49个号码球分别有红、蓝、绿三种颜色，以特码开出的颜色和投注的颜色相同视为中奖，颜色代表如下:`,
        '红波：01 ,02 ,07 ,08 ,12 ,13 ,18 ,19 ,23 ,24 ,29 ,30 ,34 ,35 ,40 ,45 ,46',
        '蓝波：03 ,04 ,09 ,10 ,14 ,15 ,20 ,25 ,26 ,31 ,36 ,37 ,41 ,42 ,47 ,48',
        '绿波：05 ,06 ,11 ,16 ,17 ,21 ,22 ,27 ,28 ,32 ,33 ,38 ,39 ,43 ,44 ,49',
      ]
    }, {
      header: '特码头数',
      body: [
        '特码头数：是指特码属头数的号码',
        '"0"头：01、02、03、04、05、06、07、08、09',
        '"1"头：10、11、12、13、14、15、16、17、18、19',
        '"2"头：20、21、22、23、24、25、26、27、28、29',
        '"3"头：30、31、32、33、34、35、36、37、38、39',
        '"4"头：40、41、42、43、44、45、46、47、48、49',
        '例如：开奖结果特别号码为21则2头为中奖，其他头数都不中奖。',
      ]
    }, {
      header: '特码尾数',
      body: [
        '特码尾数：是指特码属尾数的号码。',
        '"0"尾：10、20、30、40',
        '"1"尾：01、11、21、31、41',
        '"2"尾：02、12、22、32、42',
        '"3"尾：03、13、23、33、43',
        '"4"尾：04、14、24、34、44',
        '"5"尾：05、15、25、35、45',
        '"6"尾：06、16、26、36、46',
        '"7"尾：07、17、27、37、47',
        '"8"尾：08、18、28、38、48',
        '"9"尾：09、19、29、39、49',
        '例如：开奖结果特别号码为21则1尾数为中奖，其他尾数都不中奖。',
      ]
    }, {
      header: '五行',
      body: [
        '挑选一个五行选项为一个组合，若开奖号码的特码在此组合内，即视为中奖；若开奖号码的特码亦不在此组合内，即视为不中奖；',
        '金：06、07、20、21、28、29、36、37',
        '木：02、03、10、11、18、19、32、33、40、41、48、49',
        '水：08、09、16、17、24、25、38、39、46、47',
        '火：04、05、12、13、26、27、43、35、42、43',
        '土：01、14、15、22、23、30、31、44、45',
      ]
    }, {
      header: '平特一肖',
      body: [
        '指开奖的7个号码中含有所属生肖的一个或多个号码，但派彩指派一次，即不论同生肖号码出现一个或多个号码都指派一次。',
        '羊：01、13、25、37、49',
        '猴：12、24、36、48',
        '鸡：11、23、35、47',
        '狗：10、22、34、46',
        '猪：09、21、33、45',
        '鼠：08、20、32、44',
        '牛：07、19、31、43',
        '虎：06、18、30、42',
        '兔：05、17、29、41',
        '龙：04、16、28、40',
        '蛇：03、15、27、39',
        '马：02、14、26、38',
      ]
    }, {
      header: '平特尾数',
      body: [
        '指开奖的7个号码中含有所属生肖的一个或多个号码，但派彩指派一次，即不论同生肖号码出现一个或多个号码都指派一次。',
        '"0"尾：10、20、30、40',
        '"1"尾：01、11、21、31、41',
        '"2"尾：02、12、22、32、42',
        '"3"尾：03、13、23、33、43',
        '"4"尾：04、14、24、34、44',
        '"5"尾：05、15、25、35、45',
        '"6"尾：06、16、26、36、46',
        '"7"尾：07、17、27、37、47',
        '"8"尾：08、18、28、38、48',
        '"9"尾：09、19、29、39、49',
        '例如：开奖结果正码号码为11、31、42、44、35、32特别号码为21则1尾2尾4尾5尾都为中奖，其他尾数都不中奖。',
      ]
    }, {
      header: '连码',
      body: [
        '三中二：所投注的每三个号码为一组合，若其中2个是开奖号码中的正码，即为三中二，视为中奖；若3个都是开奖号码中的正码，即为三中二之中三，其余情形视为不中奖，如06、07、08 为一组合，开奖号码中有06、07两个正码，没有08，即为三中二，按三中二赔付；如开奖 号码中有06、07、08三个正码，即为三中二之中三，按中三赔付；如出现1个或没有，视为不中奖 。',
        '四全中：选择投注号码每四个为一组（四个或四个以上），兑奖号为正码，如四个号码都在开奖号码的正码里面，视为中奖，其他情形都视为不中奖 。',
        '三全中：所投注的每三个号码为一组合，若三个号码都是开奖号码之正码，视为中奖，其余情形视为 不中奖。如06、07、08三个都是开奖号码之正码，视为中奖，如两个正码加上一个特别号 码视为不中奖 。',
        '二全中：所投注的每二个号码为一组合，二个号码都是开奖号码之正码，视为中奖，其余情形视为不 中奖（含一个正码加一个特别号码之情形）。',
        '二中特：所投注的每二个号码为一组合，二个号码都是开奖号码之正码，叫二中特之中二；若其中一 个是正码，一个是特别号码，叫二中特之中特；其余情形视为不中奖 。',
        '特串：所投注的每二个号码为一组合，其中一个是正码，一个是特别号码，视为中奖，其余情形视为不中奖（含二个号码都是正码之情形） 。',
      ]
    }, {
      header: '连肖连尾',
      body: [
        '生肖（尾数）所对应的号码和特码生肖（尾数）项目的一样；一个生肖（尾数）对应多个号码，不论同生肖（尾数）的号码出现一个或多个，派彩只派一次。每个生肖（尾数）都有自己的赔率，下注组合的总赔率，取该组合生肖（尾数）的最低（最高）赔率为总赔率。',
        '二连尾:选择二个尾数为一投注组合进行下注。该注的二个尾数必须在当期开出的7个开奖号码相对应的尾数中，视为中奖。',
        '三连尾:选择三个尾数为一投注组合进行下注。该注的三个尾数必须在当期开出的7个开奖号码相对应的尾数中，视为中奖。',
        '四连尾:选择四个尾数为一投注组合进行下注。该注的四个尾数必须在当期开出的7个开奖号码相对应的尾数中，视为中奖。',
        '五连尾:选择五个尾数为一投注组合进行下注。该注的五个尾数必须在当期开出的7个开奖号码相对应的尾数中，视为中奖。',
        '二连肖:选择二个生肖为一投注组合进行下注。该注的二个生肖必须在当期开出的7个开奖号码相对应的生肖中，视为中奖。',
        '三连肖:选择三个生肖为一投注组合进行下注。该注的三个生肖必须在当期开出的7个开奖号码相对应的生肖中，视为中奖。',
        '四连肖:选择四个生肖为一投注组合进行下注。该注的四个生肖必须在当期开出的7个开奖号码相对应的生肖中，视为中奖。',
        '五连肖:选择五个生肖为一投注组合进行下注。该注的五个生肖必须在当期开出的7个开奖号码相对应的生肖中，视为中奖。',
      ]
    }, {
      header: '合肖',
      body: [
        '挑选2-11个生肖『排列如同生肖』为一个组合，并选择开奖号码的特码是否在此组合内『49号除外』，即视为中奖；若当期特码开出49号，则所有组合皆视为和局。',
      ]
    }, {
      header: '自选不中',
      body: [
        '挑选最少5个号码为一投注组合进行下注。当期开出的7个开奖号码都没有在该下注组合中，即视为中奖。每个号码都有自己的赔率，下注组合的总赔率，取该组合号码的最低赔率为总赔率。如下注组合为1-2-3-4-5，开奖号码为6，7，8，9，10，11，12，即为中奖，如果开奖号码为5，6，7，8，9，10，11，那麽为不中奖。',
      ]
    }, {
      header: '总肖',
      body: [
        '当期号码(所有正码与最後开出的特码)开出的不同生肖总数，与所投注之预计开出之生肖总数合(不用指定特定生肖)，则视为中奖，其余情形视为不中奖。例如：如果当期号码为19、24、12、34、40、39 特别号：49，总计六个生肖，若选总肖【6】则为中奖(请注意：49号亦算输赢，不为和）。',
      ]
    }, {
      header: '总肖单双',
      body: [
        '当期号码（正码和特码）开出的不同生肖总数若为单数则为单，若为双数则为双。',
      ]
    }, {
      header: '七色波',
      body: [
        '以开出的7个色波，那种颜色最多为中奖。 开出的6个正码各以1个色波计，特别号以1.5个色波计。而以下3种结果视为和局。',
        '1： 6个正码开出3蓝3绿，而特别码是1.5红',
        '2： 6个正码开出3蓝3红，而特别码是1.5绿',
        '3： 6个正码开出3绿3红，而特别码是1.5蓝',
        '如果出现和局，所有投注红，绿，蓝七色波的金额将全数退回，会员也可投注和局',
      ]
    }, {
      header: '特码：特天肖，特地肖',
      body: [
        '开出的特码属于十二生肖中的牛、兔、龙、马、猴、猪号码为特天肖。',
        '开出的特码属于十二生肖中的鼠、虎、蛇、羊、鸡、狗号码为特地肖。',
        '开出49则视为和局。',
      ]
    }, {
      header: '特码：特前肖，特后肖',
      body: [
        '开出的特码属于十二生肖中的鼠、牛、虎、兔、龙、蛇号码为特前肖。',
        '开出的特码属于十二生肖中的马、羊、猴、鸡、狗、猪号码为特后肖。',
        '开出49则视为和局。',
      ]
    }, {
      header: '特码：特家肖，特野肖',
      body: [
        '开出的特码属于十二生肖中的牛、马、羊、鸡、狗、猪号码为家禽。',
        '开出的特码属于十二生肖中的鼠、虎、龙、蛇、兔、猴号码为野兽。',
        '开出49则视为和局。',
      ]
    }]);
  },
};
export default HK6;