import React, { useEffect, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';

import QRCodeSVG from 'qrcode.react';

import QRcode from '../../../assets/image/subMenu/qr_android.png';

import SubMenuCard from '../SubMenuCard';

import './styles.scss';

import SubMenuConstant from '../../../constants/SubMenuConstant';
import { appActions } from '../../../redux/actions';

const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
  initData: state.homeReducers.initData,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert))
});

const SubMenu = ({ GameIndex, toggleSubMenu, userData, updateModalAlert, initData }) => {
  const lotteryItems = SubMenuConstant.lotteryList();
  const sportsItems = SubMenuConstant.sportsList();
  const gamesItems = SubMenuConstant.gamesList();
  const casinoItems = SubMenuConstant.casinoList();
  const [hoveredCard, setHoveredCard] = useState(null);
  const [apkUrlTrimmed, setApkUrlTrimmed] = useState('');

  useEffect(() => {
    if (initData && initData.company.apkDown) {
      setApkUrlTrimmed(initData.company.apkDown.trim());
    }
  }, [initData]);

  const history = useHistory();

  const handleClick = () => {
    if (userData) {
      history.push('/user/games');
    } else {
      updateModalAlert({
        visible: true,
        type: 'loginAndRegister'
      });
    }
  };

  const SubMenuCardWrapper = ({ item, index }) => (
    <div
      key={`${index.toString()}`}
      className={`col-2 position-relative d-flex justify-content-center ${hoveredCard === index ? 'hovered' : ''}`}
      onMouseEnter={() => setHoveredCard(index)}
      onMouseLeave={() => setHoveredCard(null)}
      onClick={handleClick}
    >
      {hoveredCard === index && (
        <div className="enterGame">
          进入游戏
        </div>
      )}
      <SubMenuCard
        img={item.img}
        title={item.title}
        character={item.character}
        bottom={item.bottom}
        left={item.left}
      />
    </div>
  );

  const QrCodeSection = () => (
    <div className="col-2">
      <div className="d-flex flex-column align-items-end">
        <h4 className="font-weight-bold">APP下载</h4>
        <p>扫一扫下载</p>
        {initData && (initData.company.apkDown !== '' || initData.company.apkDown !== null) && (
          <QRCodeSVG value={apkUrlTrimmed} size={70} />
        )}
        {initData && (initData.company.apkDown === '' || initData.company.apkDown === null) && (
          <img
            src={QRcode}
            alt="..."
            width={70}
            height={70}
          />
        )}
      </div>
    </div>
  );

  return (
    <AnimatePresence>
      { toggleSubMenu ? (
        <motion.div
          initial={{
            opacity: 0, y: '-10%'
          }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.5,
            },
          }}
          exit={{
            opacity: 0,
            y: '-10%',
            transition: {
              duration: 0.5,
            },
          }}
          className="subMenuGradient"
        >
          <div className="subMenu">
            <div className="container-fluid">
              {GameIndex === 1 && (
              <div className="row py-5 justify-content-center align-items-center">
                <div className="col-2">
                  <div className="d-flex flex-column">
                    <h2 className="font-weight-bold">彩票投注</h2>
                    <p>百种彩票玩法任您赢！</p>
                    <p>为玩家打造高品质的娱乐环境安心乐享游戏空间，只为公平、公正的开奖结果。</p>
                  </div>
                </div>
                {lotteryItems.map((item, index) => (
                  <SubMenuCardWrapper key={item.title} item={item} index={index} />
                ))}
                <div className="col-2" />
                <QrCodeSection />
              </div>
              )}
              {GameIndex === 2 && (
              <div className="row py-5 justify-content-center align-items-center">
                <div className="col-2">
                  <div className="d-flex flex-column">
                    <h2 className="font-weight-bold">体育赛事</h2>
                    <p>返水最高可达</p>
                    <h1 className="text-primary font-weight-bold">1.00%</h1>
                  </div>
                </div>
                {sportsItems.map((item, index) => (
                  <SubMenuCardWrapper key={item.title} item={item} index={index} />
                ))}
                <QrCodeSection />
              </div>
              )}
              {GameIndex === 3 && (
              <div className="row py-5 justify-content-center align-items-center">
                <div className="col-2">
                  <div className="d-flex flex-column">
                    <h2 className="font-weight-bold">棋牌游戏</h2>
                    <p>返水最高可达</p>
                    <h1 className="text-primary font-weight-bold">1.00%</h1>
                  </div>
                </div>
                {gamesItems.map((item, index) => (
                  <SubMenuCardWrapper key={item.title} item={item} index={index} />
                ))}
                <div className="col-2" />
                <QrCodeSection />
              </div>
              )}
              {GameIndex === 4 && (
              <div className="row py-5 justify-content-center align-items-center">
                <div className="col-2">
                  <div className="d-flex flex-column">
                    <h2 className="font-weight-bold">真人视讯</h2>
                    <p>返水最高可达</p>
                    <h1 className="text-primary font-weight-bold">1.00%</h1>
                  </div>
                </div>
                {casinoItems.map((item, index) => (
                  <SubMenuCardWrapper key={item.title} item={item} index={index} />
                ))}
                <QrCodeSection />
              </div>
              )}
            </div>
          </div>
        </motion.div>
      ) : '' }
    </AnimatePresence>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubMenu);