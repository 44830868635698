import React, { useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import { useHistory } from 'react-router-dom';

import './styles.scss';

import { FaHandshake, FaUser } from 'react-icons/fa';

import { FaMoneyBills } from 'react-icons/fa6';

import { AiFillSafetyCertificate, AiOutlineTransaction } from 'react-icons/ai';

import { RiLogoutBoxRFill, RiVipCrownFill } from 'react-icons/ri';

import { MdPeople } from 'react-icons/md';

import { BiSolidMessageDetail } from 'react-icons/bi';

import { connect } from 'react-redux';

import { appActions, memberActions } from '../../../redux/actions';

const userProfileItems = [
  {
    name: '个人资料', icon: <FaUser color="#0069d9" size={20} />
  },
  {
    name: '投注记录', icon: <FaMoneyBills color="#0069d9" size={20} />
  },
  {
    name: '交易记录', icon: <AiOutlineTransaction color="#0069d9" size={20} />
  },
  {
    name: '安全中心', icon: <AiFillSafetyCertificate color="#0069d9" size={20} />
  },
  {
    name: 'VIP 特权', icon: <RiVipCrownFill color="#0069d9" size={21} />
  },
  {
    name: '加入我们', icon: <MdPeople color="#0069d9" size={20} />
  },
  {
    name: '消息中心', icon: <BiSolidMessageDetail color="#0069d9" size={20} />
  },
  {
    name: '合作伙伴', icon: <FaHandshake color="#0069d9" size={20} />
  },
  {
    name: '退出登录', icon: <RiLogoutBoxRFill color="#0069d9" size={20} />
  }
];

const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  logout: loginId => dispatch(memberActions.logout(loginId)),
});

const UserProfile = ({ toggleUserProfile, userData, logout, updateModalAlert }) => {
  const [profileIndex, setProfileIndex] = useState(Infinity);

  const history = useHistory();

  const onclickToPages = type => {
    switch (type) {
      case 'profile':
        history.push('/user/betlimit');
        break;
      case 'topup':
        history.push('/user/bank');
        break;
      case 'inbox':
        history.push('/user/announcement');
        break;
      case 'agreement':
        history.push('/agreement');
        break;
      case 'betRecord':
        history.push('/user/third-party-records');
        break;
      case 'transactionRecord':
        history.push('/user/transaction-records');
        break;
      case 'safetyCenter':
        history.push('/user/safety-center');
        break;
      default: history.push('/agreement');
    }
  };

  return (
    <AnimatePresence>
      { toggleUserProfile ? (
        <motion.div
          initial={{
            opacity: 0, y: '-10%'
          }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.5,
            },
          }}
          exit={{
            opacity: 0,
            y: '-10%',
            transition: {
              duration: 0.5,
            },
          }}
          className="userProfileWrapper"
        >
          <div className="container-fluid">
            <div className="d-flex flex-column py-3 justify-content-center align-items-center">
              <div className="d-flex flex-row backgroundBlue p-3">
                <motion.div
                  whileHover={{
                    scale: 1.1
                  }}
                  whileTap={{
                    scale: 0.9
                  }}
                >
                  <button type="button" className="btn btn-sm btn-primary" onClick={() => onclickToPages('topup')}>充值</button>
                </motion.div>
                <motion.div
                  whileHover={{
                    scale: 1.1
                  }}
                  whileTap={{
                    scale: 0.9
                  }}
                >
                  <button type="button" className="btn btn-sm btn-primary ml-2" onClick={() => onclickToPages('topup')}>提现</button>
                </motion.div>
              </div>
              {userProfileItems.map((item, index) => (
                <div
                  key={`${index.toString()}`}
                  className={`d-flex justify-content-start align-items-center backgroundWhite 
                  ${profileIndex === index && 'backgroundDarkBlue font-weight-bold'} `}
                  onMouseEnter={() => setProfileIndex(index)}
                  onClick={() => {
                    if (index === 0) {
                      onclickToPages('profile');
                    } else if (index === 1) {
                      onclickToPages('betRecord');
                    } else if (index === 2) {
                      onclickToPages('transactionRecord');
                    } else if (index === 3) {
                      onclickToPages('safetyCenter');
                    } else if (index === 6) {
                      onclickToPages('inbox');
                    } else if (index === 8) {
                      updateModalAlert({
                        type: 'confirmation',
                        visible: true,
                        data: {
                          message: '确定要退出登录吗?',
                          onConfirmationClick: () => {
                            updateModalAlert({
                              visible: true,
                              type: 'loading',
                            });
                            logout(userData.loginId);
                          },
                          showCancelButton: true
                        }
                      });
                    } else {
                      onclickToPages('agreement');
                    }
                  }}
                >
                  {item.icon}
                  <div className="ml-3">{item.name}</div>
                </div>
              ))}
            </div>
          </div>
        </motion.div>
      ) : '' }
    </AnimatePresence>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);