import { MultipleItemsSelectionPanel, MultipleTypeBetsPanel } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';

const LM = [
  {
    componentType: QuickBet,
  },
  {
    componentType: MultipleItemsSelectionPanel,
    data: {
      title: '连码',
      maximumSelection: 14,
      numberOfColumn: 10,
      list: [
        {
          displayName: '01',
          className: 'circle circleRed',
        }, {
          displayName: '02',
          className: 'circle circleRed',
        }, {
          displayName: '03',
          className: 'circle circleBlue',
        }, {
          displayName: '04',
          className: 'circle',
        }, {
          displayName: '05',
          className: 'circle circleGreen',
        }, {
          displayName: '06',
          className: 'circle circleGreen',
        }, {
          displayName: '07',
          className: 'circle circleRed',
        }, {
          displayName: '08',
          className: 'circle circleRed',
        }, {
          displayName: '09',
          className: 'circle',
        }, {
          displayName: '10',
          className: 'circle',
        }, {
          displayName: '11',
          className: 'circle circleGreen',
        }, {
          displayName: '12',
          className: 'circle circleRed',
        }, {
          displayName: '13',
          className: 'circle circleRed',
        }, {
          displayName: '14',
          className: 'circle',
        }, {
          displayName: '15',
          className: 'circle',
        }, {
          displayName: '16',
          className: 'circle circleGreen',
        }, {
          displayName: '17',
          className: 'circle circleGreen',
        }, {
          displayName: '18',
          className: 'circle circleRed',
        }, {
          displayName: '19',
          className: 'circle circleRed',
        }, {
          displayName: '20',
          className: 'circle',
        }, {
          displayName: '21',
          className: 'circle circleGreen',
        }, {
          displayName: '22',
          className: 'circle circleGreen',
        }, {
          displayName: '23',
          className: 'circle circleRed',
        }, {
          displayName: '24',
          className: 'circle circleRed',
        }, {
          displayName: '25',
          className: 'circle',
        }, {
          displayName: '26',
          className: 'circle',
        }, {
          displayName: '27',
          className: 'circle circleGreen',
        }, {
          displayName: '28',
          className: 'circle circleGreen',
        }, {
          displayName: '29',
          className: 'circle circleRed',
        }, {
          displayName: '30',
          className: 'circle circleRed',
        }, {
          displayName: '31',
          className: 'circle circleBlue',
        },
      ],
    }
  },
  {
    componentType: MultipleTypeBetsPanel,
    data: {
      list: [
        {
          code: 'L2QZ',
          displayName: '二全中',
          minimumSelection: 2,
          maximumSelection: 8,
          fromValue: 1,
          toValue: 31,
        },
        {
          displayName: '三中特',
          minimumSelection: 3,
          maximumSelection: 8,
          code: 'L3ZT',
          fromValue: 1,
          toValue: 31,
          children: [
            {
              code: 'L3ZT'
            },
            {
              code: 'L3ZTZ3'
            },
          ],
        },
        {
          displayName: '四拖三',
          minimumSelection: 4,
          maximumSelection: 8,
          code: 'L4ZT',
          fromValue: 1,
          toValue: 31,
          children: [
            {
              code: 'L4ZT'
            },
            {
              code: 'L4ZTZ4'
            },
          ],
        },
        {
          code: 'L1ZB',
          displayName: '特串',
          minimumSelection: 2,
          maximumSelection: 8,
          fromValue: 1,
          toValue: 31,
        }
      ]
    }
  },
  {
    componentType: MultipleTypeBetsPanel,
    data: {
      list: [
        {
          wrapperStyle: '',
          code: 'L3QZ',
          displayName: '三全中',
          minimumSelection: 3,
          maximumSelection: 11,
          fromValue: 1,
          toValue: 31,
        },
        {
          wrapperStyle: '',
          displayName: '二字半',
          minimumSelection: 3,
          maximumSelection: 8,
          code: 'L2ZB',
          fromValue: 1,
          toValue: 31,
        },
        {
          wrapperStyle: '',
          displayName: '四全中',
          minimumSelection: 4,
          maximumSelection: 9,
          code: 'L4QZ',
          fromValue: 1,
          toValue: 31,
        },
        {
          wrapperStyle: '',
          code: 'L3ZB',
          displayName: '三字半',
          minimumSelection: 4,
          maximumSelection: 8,
          fromValue: 1,
          toValue: 31,
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default LM;