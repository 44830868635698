import { MultipleItemsSelectionPanel, MultipleTypeBetsPanel } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';

const TUOTOU = [
  {
    componentType: QuickBet,
  },
  {
    componentType: MultipleItemsSelectionPanel,
    data: {
      title: '拖头',
      danNum: 1,
      maximumSelection: 15,
      numberOfColumn: 10,
      list: [
        {
          displayName: '01',
          className: 'circle circleRed',
        }, {
          displayName: '02',
          className: 'circle circleRed',
        }, {
          displayName: '03',
          className: 'circle circleBlue',
        }, {
          displayName: '04',
          className: 'circle',
        }, {
          displayName: '05',
          className: 'circle circleGreen',
        }, {
          displayName: '06',
          className: 'circle circleGreen',
        }, {
          displayName: '07',
          className: 'circle circleRed',
        }, {
          displayName: '08',
          className: 'circle circleRed',
        }, {
          displayName: '09',
          className: 'circle',
        }, {
          displayName: '10',
          className: 'circle',
        }, {
          displayName: '11',
          className: 'circle circleGreen',
        }, {
          displayName: '12',
          className: 'circle circleRed',
        }, {
          displayName: '13',
          className: 'circle circleRed',
        }, {
          displayName: '14',
          className: 'circle',
        }, {
          displayName: '15',
          className: 'circle',
        }, {
          displayName: '16',
          className: 'circle circleGreen',
        }, {
          displayName: '17',
          className: 'circle circleGreen',
        }, {
          displayName: '18',
          className: 'circle circleRed',
        }, {
          displayName: '19',
          className: 'circle circleRed',
        }, {
          displayName: '20',
          className: 'circle',
        }, {
          displayName: '21',
          className: 'circle circleGreen',
        }, {
          displayName: '22',
          className: 'circle circleGreen',
        }, {
          displayName: '23',
          className: 'circle circleRed',
        }, {
          displayName: '24',
          className: 'circle circleRed',
        }, {
          displayName: '25',
          className: 'circle',
        }, {
          displayName: '26',
          className: 'circle',
        }, {
          displayName: '27',
          className: 'circle circleGreen',
        }, {
          displayName: '28',
          className: 'circle circleGreen',
        }, {
          displayName: '29',
          className: 'circle circleRed',
        }, {
          displayName: '30',
          className: 'circle circleRed',
        }, {
          displayName: '31',
          className: 'circle circleBlue',
        }, {
          displayName: '32',
          className: 'circle circleGreen',
        }, {
          displayName: '33',
          className: 'circle circleGreen',
        }, {
          displayName: '34',
          className: 'circle circleRed',
        }, {
          displayName: '35',
          className: 'circle circleRed',
        }, {
          displayName: '36',
          className: 'circle',
        },
      ],
    }
  },
  {
    componentType: MultipleTypeBetsPanel,
    data: {
      list: [
        {
          code: 'L2QZ',
          danNum: 1,
          displayName: '二全中',
          minimumSelection: 2,
          maximumSelection: 8,
          fromValue: 1,
          toValue: 36,
        },
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default TUOTOU;