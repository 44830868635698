import React, { useState } from 'react';

import { Button, Col, Row } from 'reactstrap';
import { IoChevronDownSharp, IoClose } from 'react-icons/io5';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions } from '../../../redux/actions';

import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const MobileTransactionFilterModal = ({ platform, updateModalAlert, data }) => {
  const { t } = useTranslation();
  const categoryList = [{
    id: 1,
    name: t('ALL_TRANSACTION'),
    value: 0
  }, {
    id: 100,
    name: t('TOP_UP'),
    value: 100
  }, {
    id: 200,
    name: t('TRANSFER'),
    value: 200
  }, {
    id: 300,
    name: t('DIVIDEND'),
    value: 300
  }, {
    id: 401,
    name: t('CASH_REBATE'),
    value: 401
  }, {
    id: 600,
    name: t('PROMOTIONS'),
    value: 600
  }, {
    id: 700,
    name: t('MANUAL_TOP_UP'),
    value: 700
  }, {
    id: 899,
    name: t('TRANSFER'),
    value: 899
  }, {
    id: 910,
    name: t('UNLOCK_AMOUNT'),
    value: 910
  }, {
    id: 911,
    name: t('LOCK_AMOUNT'),
    value: 911
  }, {
    id: 998,
    name: t('FINANCIAL_TOP_UP'),
    value: 998
  }, {
    id: 999,
    name: t('FINANCIAL_DEDUCT'),
    value: 999
  }];
  const [_selectedStatus, setSelectedStatus] = useState(data.selectedStatus || data.currentStatusSelected);
  const [_selectedCategory, setSelectedCategory] = useState(data.radioButtonSelected || data.currentCategorySelected || 0);
  const statusList = [{
    label: t('SUCCESS'),
    value: 300
  }, {
    label: t('FAIL'),
    value: 400
  }, {
    label: t('CANCEL'),
    value: 500
  }, {
    label: t('PENDING'),
    value: 100
  }, {
    label: t('PENDING_APPROVAL'),
    value: 200
  }];

  return (
    <div className="transactionFilterModalMobile">
      <Row className={`modalHeader ${platform === 'Mobile' && 'm-0'}`}>
        <Col
          className={`${platform === 'Web' && 'd-flex justify-content-center align-items-center'}`}
          xs={platform === 'Web' && {
            size: 4, offset: 4
          }}
        >
          {t('MORE_OPTIONS')}
        </Col>
        <Col xs={platform === 'Web' && '4'} className="d-flex justify-content-end align-items-center">
          <IoClose
            className="h4 mb-0 float-right closeIcon"
            onClick={() => {
              updateModalAlert({
                visible: false
              });
            }}
          />
        </Col>
      </Row>
      <Row className={`modalContent mx-0 pt-3 ${platform === 'Mobile' && 'd-flex justify-content-center'}`}>
        <Col className="moreOptionsWrapper">
          <Row>
            <Col
              className="categoryOpenPickerWrapper py-2"
              onClick={() => updateModalAlert({
                visible: true,
                type: 'radioButtonList',
                data: categoryList && categoryList,
                value: categoryList.find(item => item.value === _selectedCategory),
                onChange: selectedCategory => {
                  updateModalAlert({
                    visible: true,
                    type: 'mobileTransactionFilter',
                    data: {
                      radioButtonSelected: selectedCategory.value,
                      selectedStatus: _selectedStatus,
                      onConfirmationClick: data.onConfirmationClick
                    }
                  });
                  setSelectedCategory(selectedCategory.value);
                },
              })}
            >
              {_selectedCategory ? categoryList.find(item => item.value === _selectedCategory).name : categoryList[0].name}
              <IoChevronDownSharp className="ml-2 h5 mb-0 moreOptions" />
            </Col>
          </Row>
          <Row className="mt-3">
            {statusList.map(item => (
              <Col
                className={`px-0 statusItem ${_selectedStatus === item.value && 'active'}`}
                onClick={() => {
                  if (_selectedStatus !== item.value) return setSelectedStatus(item.value);
                  return setSelectedStatus(undefined);
                }}
              >
                {item.label}
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row className={`modalFooter mx-0 d-flex align-items-center ${platform === 'Mobile' && 'justify-content-center'}`}>
        <Button
          className="confirmButton my-3"
          onClick={() => {
            data && data.onConfirmationClick(_selectedStatus && _selectedStatus, _selectedCategory && _selectedCategory);
            updateModalAlert({
              visible: false
            });
          }}
        >
          {t('CHECK')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileTransactionFilterModal);