import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';


const HM2 = [
  {
    componentType: CollapseType,
    header: '第二球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'B2QH1',
          displayName: '1',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B2QH2',
          displayName: '2',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B2QH3',
          displayName: '3',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B2QH4',
          displayName: '4',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B2QH5',
          displayName: '5',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B2QH6',
          displayName: '6',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B2QH7',
          displayName: '7',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B2QH8',
          displayName: '8',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B2QH9',
          displayName: '9',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B2QH10',
          displayName: '10',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B2QH11',
          displayName: '11',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B2QH12',
          displayName: '12',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B2QH13',
          displayName: '13',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B2QH14',
          displayName: '14',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B2QH15',
          displayName: '15',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B2QH16',
          displayName: '16',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B2QH17',
          displayName: '17',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B2QH18',
          displayName: '18',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B2QH19',
          displayName: '19',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B2QH20',
          displayName: '20',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B2QH21',
          displayName: '21',
          drawImgClassName: 'circle circleGreen',
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '色波，福禄寿喜，两面',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'B2LM1',
          displayName: '大',
        }, {
          code: 'B2LM2',
          displayName: '小',
        }, {
          code: 'B2LM3',
          displayName: '单',
        }, {
          code: 'B2LM4',
          displayName: '双',
        }, {
          code: 'B2LM5',
          displayName: '尾大',
        }, {
          code: 'B2LM6',
          displayName: '尾小',
        }, {
          code: 'B2LM7',
          displayName: '合单',
        }, {
          code: 'B2LM8',
          displayName: '合双',
        }, {
          code: 'B2QH22',
          displayName: '红',
        }, {
          code: 'B2QH23',
          displayName: '绿',
        }, {
          code: 'B2QH24',
          displayName: '蓝',
        }, {
          code: 'B2QH25',
          displayName: '福',
        }, {
          code: 'B2QH26',
          displayName: '禄',
        }, {
          code: 'B2QH27',
          displayName: '寿',
        }, {
          code: 'B2QH28',
          displayName: '喜',
        },
      ]
    }
  }
];

export default HM2;