import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const TWS = [
  {
    componentType: CollapseType,
    header: '特码尾数',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper PK10ItemWrapperGY',
      list: [
        {
          code: 'TMWS1', // keyCode
          displayName: '0尾',
        }, {
          code: 'TMWS2',
          displayName: '1尾',
        }, {
          code: 'TMWS3',
          displayName: '2尾',
        }, {
          code: 'TMWS4',
          displayName: '3尾',
        }, {
          code: 'TMWS5',
          displayName: '4尾',
        }, {
          code: 'TMWS6',
          displayName: '5尾',
        }, {
          code: 'TMWS7',
          displayName: '6尾',
        }, {
          code: 'TMWS8',
          displayName: '7尾',
        }, {
          code: 'TMWS9',
          displayName: '8尾',
        }, {
          code: 'TMWS10',
          displayName: '9尾',
        },
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '特码头数',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper PK10ItemWrapperGY',
      list: [
        {
          code: 'TMTS11',
          displayName: '0头',
        }, {
          code: 'TMTS12',
          displayName: '1头',
        }, {
          code: 'TMTS13',
          displayName: '2头',
        }, {
          code: 'TMTS14',
          displayName: '3头',
        },
        {
          code: 'TMTS15',
          displayName: '4头',
        },
      ]
    }
  }
];

export default TWS;