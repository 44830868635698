import React, { useEffect, useRef, useState } from 'react';

import { Button, Col, Row } from 'reactstrap';
import { FaRegEdit } from 'react-icons/fa';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, pingActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  platform: state.appReducers.platform,
  checkLatencyData: state.pingReducers.checkLatencyData,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  getCheckLatency: () => dispatch(pingActions.getCheckLatency()),
  latencyTest: url => dispatch(pingActions.latencyTest(url)),
});

const MobileOnlineStatusModal = ({ updateModalAlert, checkLatencyData, getCheckLatency, selectedData, latencyTest }) => {
  const { t } = useTranslation();
  const { domainList } = checkLatencyData || {};
  const [_selectedLine, setSelectedLine] = useState();
  const [_onlineStatus, setOnlineStatus] = useState(navigator.onLine ? '成功' : '失败');
  const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
  const [_type, setType] = useState(connection.effectiveType ? connection.effectiveType.toUpperCase() : '');
  const [_ip, setIP] = useState(checkLatencyData?.ip || '-');
  const [_time, setTime] = useState(checkLatencyData?.time || '-');
  const domainName = window.location.hostname;
  const savedCallback = useRef();

  useEffect(() => {
    getCheckLatency();
    if (!navigator.onLine) {
      setIP('-');
      setTime('-');
    }
    // timer
    const tick = () => {
      savedCallback.current();
    };
    const id = setInterval(tick, 10000);
    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    savedCallback.current = () => {
      setOnlineStatus(navigator.onLine ? '成功' : '失败');
      setType(connection.effectiveType ? connection.effectiveType.toUpperCase() : '');
      // skip the calling if network disconnected
      if (!navigator.onLine) {
        setIP('-');
        setTime('-');
      } else {
        setIP(checkLatencyData?.ip || '-');
        setTime(checkLatencyData?.time || '-');
      }
    };
  });

  useEffect(() => {
    if (domainList) {
      domainList?.forEach(element => {
        latencyTest(element.domain);
      });
    }
  }, [domainList]);

  useEffect(() => {
    if (selectedData) {
      setSelectedLine(selectedData);
    }
  }, [selectedData]);

  return (
    <div className="mobileOnlineStatusModal">
      <Row className="modalHeader m-0 pl-3">
        {t('CONNECTION_DETAIL')}
      </Row>
      <Row className="modalContent d-flex justify-content-center mx-0">
        <Row className="mt-1">
          <Col className="text-center">网络类型</Col>
          <Col>
            {_type}
          </Col>
        </Row>
        <Row>
          <Col className="text-center">网络连接</Col>
          <Col>{_onlineStatus}</Col>
        </Row>
        <Row>
          <Col className="text-center">IP地址</Col>
          <Col>
            {_ip || '-'}
          </Col>
        </Row>
        <Row>
          <Col className="text-center">往返时间</Col>
          <Col>
            {_time || '-'}
            ms
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            服务器
          </Col>
          <Col>
            {_selectedLine?.line || (domainList?.find(domains => domains.domain.includes(domainName))?.line) || '线路'}
            <span className="iconOpen m-0 p-0">
              <FaRegEdit
                className="ml-2 h5 mb-0"
                label={(_selectedLine) || ''}
                onClick={() => {
                  if (domainList && domainList.length > 0) {
                    return updateModalAlert({
                      visible: true,
                      type: 'mobileOnlineRadioList',
                      data: domainList.sort((a, b) => a.time - b.time) || [],
                      value: _selectedLine,
                      onChange: ret => {
                        setSelectedLine(ret);
                      },
                    });
                  }
                }}
              />
            </span>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">{t('VERSION')}</Col>
          <Col>{process.env.REACT_APP_VERSION}</Col>
        </Row>
      </Row>
      <Row className="modalFooter align-items-center justify-content-center mx-0 py-3 px-2 flex-nowrap">
        <Button
          className="button py-2"
          onClick={() => {
            updateModalAlert({
              visible: false
            });
          }}
        >
          {t('MODAL_ACKNOWLEDGE')}
        </Button>
        <Button
          className="button py-2 ml-2"
          onClick={() => {
            setOnlineStatus(navigator.onLine ? '成功' : '失败');
            setType(connection.effectiveType ? connection.effectiveType.toUpperCase() : '');
            // skip the calling if network disconnected
            if (!navigator.onLine) {
              setIP('-');
              setTime('-');
            } else {
              getCheckLatency();
              setIP(checkLatencyData?.ip || '-');
              setTime(checkLatencyData?.time || '-');
            }
          }}
        >
          {t('REFRESH')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileOnlineStatusModal);