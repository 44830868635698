const SSC = {
  lotterySpecific: gameData => {
    const { interval, lotteryBeginTimeBase, lotteryEndTimeBase, lotteryName, code, lotteryTermCycle } = gameData || {};
    const endTimeString = lotteryEndTimeBase ? `开到凌晨${lotteryEndTimeBase}` : '';
    const intervalString = interval % 60 === 0 ? `${interval / 60}分` : `${interval}秒`;
    switch (code) {
      case 'JSSSC':
        return `“${lotteryName}”是引进欧洲博彩公司开奖设备，由菲律宾博彩执照公司审核监控，联合开发的彩票游戏，保证公平公正；您可以放心投注、开奖时间，北京时间（GMT+8）每天白天从早上${lotteryBeginTimeBase}${endTimeString}，每${intervalString}开一次奖,每天开奖${lotteryTermCycle}期。`;
      case 'XJSSC':
        return `该游戏的投注时间、开奖时间和开奖号码与新疆时时彩完全同步，北京时间（GMT+8）每天白天从上午${lotteryBeginTimeBase}${endTimeString}点,每${intervalString}钟开一次奖,每天开奖${lotteryTermCycle}期(白天41期,夜间7期)。`;
      default:
        return `该游戏的投注时间、开奖时间和开奖号码与澳洲Lucky 5 Lotto完全同步。您可以放心投注，开奖时间：北京时间（GMT+8）每天白天从早上${lotteryBeginTimeBase}${endTimeString}，每${intervalString}开一次奖,每天开奖${lotteryTermCycle}期。`;
    }
  },
  templateSpecific: () => (
    [
      {
        header: '第一球~第五球',
        body: [
          '第一球特~第五球特：第一球特、第二球特、第三球特、第四球特、第五球特：指下注的每一球特与开出之号码其开奖顺序及开奖号码相同，视为中奖，如第一球开出号码8，下注第一球为8者视为中奖，其余情形视为不中奖。',
          '单双大小：根据相应单项投注第一球特 ~ 第五球特开出的球号，判断胜负。',
          '大小：根据相应单项投注的第一球特 ~ 第五球特开出的球号大於或等於5为特码大，小於或等於4为特码小。',
          '单双：根据相应单项投注的第一球特 ~ 第五球特开出的球号为双数叫特双，如2、6；特码为单数叫特单，如1、3。',
        ]
      }, {
        header: '总和单双大小：',
        body: [
          '大小：根据相应单项投注的第一球特 ~ 第五球特开出的球号大於或等於23为特码大，小於或等於22为特码小',
          '单双：根据相应单项投注的第一球特 ~ 第五球特开出的球号数字总和值是双数为总和双，数字总和值是单数为总和单。'
        ]
      }, {
        header: '前三特殊玩法： 豹子 > 顺子 > 对子 > 半顺 > 杂六',
        body: [
          '豹子：中奖号码的个位十位百位数字都相同。----如中奖号码为000、111、999等，中奖号码的个位十位百位数字相同，则投注豹子者视为中奖，其它视为不中奖。',
          '顺子：中奖号码的个位十位百位数字都相连，不分顺序。（数字9、0、1相连）----如中奖号码为123、901、321、546等，中奖号码个位十位百位数字相连，则投注顺子者视为中奖，其它视为不中奖。',
          '对子：中奖号码的个位十位百位任意两位数字相同。（不包括豹子）----如中奖号码为001，112、696，中奖号码有两位数字相同，则投注对子者视为中奖，其它视为不中奖。如果开奖号码为豹子,则对子视为不中奖。如中奖号码为001，112、696，中奖号码有两位数字相同，则投注对子者视为中奖，其它视为不中奖。',
          '半顺：中奖号码的个位十位百位任意两位数字相连，不分顺序。（不包括顺子、对子。）----如中奖号码为125、540、390、706，中奖号码有两位数字相连，则投注半顺者视为中奖，其它视为不中奖。如果开奖号码为顺子、对子,则半顺视为不中奖。--如中奖号码为123、901、556、233，视为不中奖。',
          '杂六：不包括豹子、对子、顺子、半顺的所有中奖号码。----如中奖号码为157，中奖号码位数之间无关联性，则投注杂六者视为中奖，其它视为不中奖。'
        ]
      }, {
        header: '中三特殊玩法： 豹子 > 顺子 > 对子 > 半顺 > 杂六',
        body: [
          '豹子：中奖号码的十位百位千位数字都相同。----如中奖号码为000、111、999等，中奖号码的十位百位千位数字相同，则投注豹子者视为中奖，其它视为不中奖。',
          '顺子：中奖号码的十位百位千位数字都相连，不分顺序。（数字9、0、1相连）----如中奖号码为123、901、321、546等，中奖号码十位百位千位数字相连，则投注顺子者视为中奖，其它视为不中奖。',
          '对子：中奖号码的十位百位千位任意两位数字相同。（不包括豹子）----如中奖号码为001，112、696，中奖号码有两位数字相同，则投注对子者视为中奖，其它视为不中奖。如果开奖号码为豹子,则对子视为不中奖。如中奖号码为001，112、696，中奖号码有两位数字相同，则投注对子者视为中奖，其它视为不中奖。',
          '半顺：中奖号码的十位百位千位任意两位数字相连，不分顺序。（不包括顺子、对子。）----如中奖号码为125、540、390、706，中奖号码有两位数字相连，则投注半顺者视为中奖，其它视为不中奖。如果开奖号码为顺子、对子,则半顺视为不中奖。--如中奖号码为123、901、556、233，视为不中奖。',
          '杂六：不包括豹子、对子、顺子、半顺的所有中奖号码。----如中奖号码为157，中奖号码位数之间无关联性，则投注杂六者视为中奖，其它视为不中奖。'
        ]
      }, {
        header: '后三特殊玩法： 豹子 > 顺子 > 对子 > 半顺 > 杂六',
        body: [
          '豹子：中奖号码的百位千位万位数字都相同。----如中奖号码为000、111、999等，中奖号码的百位千位万位数字相同，则投注豹子者视为中奖，其它视为不中奖。',
          '顺子：中奖号码的百位千位万位数字都相连，不分顺序。（数字9、0、1相连）----如中奖号码为123、901、321、546等，中奖号码百位千位万位数字相连，则投注顺子者视为中奖，其它视为不中奖。',
          '对子：中奖号码的百位千位万位任意两位数字相同。（不包括豹子）----如中奖号码为001，112、696，中奖号码有两位数字相同，则投注对子者视为中奖，其它视为不中奖。如果开奖号码为豹子,则对子视为不中奖。如中奖号码为001，112、696，中奖号码有两位数字相同，则投注对子者视为中奖，其它视为不中奖。',
          '半顺：中奖号码的百位千位万位任意两位数字相连，不分顺序。（不包括顺子、对子。）----如中奖号码为125、540、390、706，中奖号码有两位数字相连，则投注半顺者视为中奖，其它视为不中奖。如果开奖号码为顺子、对子,则半顺视为不中奖。--如中奖号码为123、901、556、233，视为不中奖。',
          '杂六：不包括豹子、对子、顺子、半顺的所有中奖号码。----如中奖号码为157，中奖号码位数之间无关联性，则投注杂六者视为中奖，其它视为不中奖。'
        ]
      }, {
        header: '龙虎和特殊玩法：龙>虎>和（0为最小，9为最大）',
        body: [
          '龙：开出之号码第一球（万位）的中奖号码大于第五球（个位）的中奖号码，如出和局为打和。如 第一球开出4 第五球开出2；第一球开出9 第五球开出8；第一球开出5 第五球开出1...中奖为龙。',
          '虎：开出之号码第一球（万位）的中奖号码小于第五球（个位）的中奖号码，如出和局为打和。如 第一球开出7 第五球开出9；第一球开出3 第五球开出5；第一球开出5 第五球开出8...中奖为虎。',
          '和：开出之号码第一球（万位）的中奖号码等于第五球（个位）的中奖号码，例如开出结果：2***2则投注和局者视为中奖，投注龙虎返还投注金额。'
        ]
      }, {
        header: '一字组合',
        body: [
          '前三：在0~9中选择一个号码下注。如下注号码在开出之号码的第一球（万位）、第二球（千位）及第三球（佰位）中任何一位出现则视为中奖，其余情形视为不中奖。',
          '中三：在0~9中选择一个号码下注。如下注号码在开出之号码的第二球（千位）、第三球（佰位）及第四球（拾位）中任何一位出现则视为中奖，其余情形视为不中奖。',
          '后三：在0~9中选择一个号码下注。如下注号码在开出之号码的第三球（佰位）、第四球（拾位）及第五球（个位）中任何一位出现则视为中奖，其余情形视为不中奖。',
          '全五： 在0~9中选择一个号码下注。如下注号码在开出之号码的任何一位中出现则视为中奖，其余情形视为不中奖。'
        ]
      }, {
        header: '二字组合',
        body: [
          '前三：在0~9中选择二个号码下注（不分顺序）。如下注号码在开出之号码的第一球（万位）、第二球（千位）及第三球（佰位）中出现（不分顺序）则视为中奖，其余情形视为不中奖。',
          '中三： 在0~9中选择二个号码下注（不分顺序）。如下注号码在开出之号码的第二球（千位）、第三球（佰位）及第四球（拾位）中出现（不分顺序）则视为中奖，其余情形视为不中奖。',
          '后三： 在0~9中选择二个号码下注（不分顺序）。如下注号码在开出之号码的第三球（佰位）、第四球（拾位）及第五球（个位）中出现（不分顺序）则视为中奖，其余情形视为不中奖。',
        ]
      }, {
        header: '二字定位',
        body: [
          '万千：在0~9中按顺序选择二个号码下注。如下注号码与开出之号码的第一球（万位）及第二球（千位）相同且顺序一致则视为中奖，其余情形视为不中奖。',
          '万佰：在0~9中按顺序选择二个号码下注。如下注号码与开出之号码的第一球（万位）及第三球（佰位）相同且顺序一致则视为中奖，其余情形视为不中奖。',
          '万拾：在0~9中按顺序选择二个号码下注。如下注号码与开出之号码的第一球（万位）及第四球（拾位）相同且顺序一致则视为中奖，其余情形视为不中奖。',
          '万个：在0~9中按顺序选择二个号码下注。如下注号码与开出之号码的第一球（万位）及第五球（个位）相同且顺序一致则视为中奖，其余情形视为不中奖。',
          '千佰：在0~9中按顺序选择二个号码下注。如下注号码与开出之号码的第二球（千位）及第三球（佰位）相同且顺序一致则视为中奖，其余情形视为不中奖。',
          '千拾：在0~9中按顺序选择二个号码下注。如下注号码与开出之号码的第二球（千位）及第四球（拾位）相同且顺序一致则视为中奖，其余情形视为不中奖。',
          '千个：在0~9中按顺序选择二个号码下注。如下注号码与开出之号码的第二球（千位）及第五球（个位）相同且顺序一致则视为中奖，其余情形视为不中奖。',
          '佰拾：在0~9中按顺序选择二个号码下注。如下注号码与开出之号码的第三球（佰位）及第四球（拾位）相同且顺序一致则视为中奖，其余情形视为不中奖。',
          '佰个：在0~9中按顺序选择二个号码下注。如下注号码与开出之号码的第三球（佰位）及第五球（个位）相同且顺序一致则视为中奖，其余情形视为不中奖。',
          '拾个：在0~9中按顺序选择二个号码下注。如下注号码与开出之号码的第四球（拾位）及第五球（个位）相同且顺序一致则视为中奖，其余情形视为不中奖。',
        ]
      }, {
        header: '二字和数',
        body: [
          '万千：如开出之号码的第一球（万位）及第二球（千位）相加的和数与下注选择的范围相同，则视为中奖，其余情形视为不中奖。',
          '万佰：如开出之号码的第一球（万位）及第三球（佰位）相加的和数与下注选择的范围相同，则视为中奖，其余情形视为不中奖。',
          '万拾：如开出之号码的第一球（万位）及第四球（拾位）相加的和数与下注选择的范围相同，则视为中奖，其余情形视为不中奖。',
          '万个：如开出之号码的第一球（万位）及第五球（个位）相加的和数与下注选择的范围相同，则视为中奖，其余情形视为不中奖。',
          '千佰：如开出之号码的第二球（千位）及第三球（佰位）相加的和数与下注选择的范围相同，则视为中奖，其余情形视为不中奖。',
          '千拾：如开出之号码的第二球（千位）及第四球（拾位）相加的和数与下注选择的范围相同，则视为中奖，其余情形视为不中奖。',
          '千个：如开出之号码的第二球（千位）及第五球（个位）相加的和数与下注选择的范围相同，则视为中奖，其余情形视为不中奖。',
          '佰拾：如开出之号码的第三球（佰位）及第四球（拾位）相加的和数与下注选择的范围相同，则视为中奖，其余情形视为不中奖。',
          '佰个：如开出之号码的第三球（佰位）及第五球（个位）相加的和数与下注选择的范围相同，则视为中奖，其余情形视为不中奖。',
          '拾个：如开出之号码的第四球（拾位）及第五球（个位）相加的和数与下注选择的范围相同，则视为中奖，其余情形视为不中奖。',
        ]
      }, {
        header: '二字和数尾数',
        body: [
          '万千：在0~9中选择一个号码下注。如下注号码与开出之号码的第一球（万位）及第二球（千位）相加的和数之尾数相同，则视为中奖，其余情形视为不中奖。',
          '万佰：在0~9中选择一个号码下注。如下注号码与开出之号码的第一球（万位）及第三球（佰位）相加的和数之尾数相同，则视为中奖，其余情形视为不中奖。',
          '万拾：在0~9中选择一个号码下注。如下注号码与开出之号码的第一球（万位）及第四球（拾位）相加的和数之尾数相同，则视为中奖，其余情形视为不中奖。',
          '万个：在0~9中选择一个号码下注。如下注号码与开出之号码的第一球（万位）及第五球（个位）相加的和数之尾数相同，则视为中奖，其余情形视为不中奖。',
          '千佰：在0~9中选择一个号码下注。如下注号码与开出之号码的第二球（千位）及第三球（佰位）相加的和数之尾数相同，则视为中奖，其余情形视为不中奖。',
          '千拾：在0~9中选择一个号码下注。如下注号码与开出之号码的第二球（千位）及第四球（拾位）相加的和数之尾数相同，则视为中奖，其余情形视为不中奖。',
          '千个：在0~9中选择一个号码下注。如下注号码与开出之号码的第二球（千位）及第五球（个位）相加的和数之尾数相同，则视为中奖，其余情形视为不中奖。',
          '佰拾：在0~9中选择一个号码下注。如下注号码与开出之号码的第三球（佰位）及第四球（拾位）相加的和数之尾数相同，则视为中奖，其余情形视为不中奖。',
          '佰个：在0~9中选择一个号码下注。如下注号码与开出之号码的第三球（佰位）及第五球（个位）相加的和数之尾数相同，则视为中奖，其余情形视为不中奖。',
          '拾个：在0~9中选择一个号码下注。如下注号码与开出之号码的第四球（拾位）及第五球（个位）相加的和数之尾数相同，则视为中奖，其余情形视为不中奖。',
        ]
      }, {
        header: '三字组合',
        body: [
          '前三： 在0~9中选择三个号码下注（不分顺序）。如下注号码在开出之号码的第一球（万位）、第二球（千位）及第三球（佰位）中出现（不分顺序）则视为中奖，其余情形视为不中奖。',
          '中三： 在0~9中选择三个号码下注（不分顺序）。如下注号码在开出之号码的第二球（千位）、第三球（佰位）及第四球（拾位）中出现（不分顺序）则视为中奖，其余情形视为不中奖。',
          '后三： 在0~9中选择三个号码下注（不分顺序）。如下注号码在开出之号码的第三球（佰位）、第四球（拾位）及第五球（个位）中出现（不分顺序）则视为中奖，其余情形视为不中奖。',
        ]
      }, {
        header: '三字定位',
        body: [
          '前三：在0~9中按顺序选择三个号码下注。如下注号码与开出之号码的第一球（万位）、第二球（千位）及第三球（佰位）相同且顺序一至则视为中奖，其余情形视为不中奖。',
          '中三：在0~9中按顺序选择三个号码下注。如下注号码与开出之号码的第二球（千位）、第三球（佰位）及第四球（拾位）相同且顺序一至则视为中奖，其余情形视为不中奖。',
          '后三：在0~9中按顺序选择三个号码下注。如下注号码与开出之号码的第三球（佰位）、第四球（拾位）及第五球（个位）相同且顺序一至则视为中奖，其余情形视为不中奖。',
        ]
      }, {
        header: '三字和数',
        body: [
          '前三：如开出之号码的第一球（万位）、第二球（千位）及第三球（佰位）相加的和数与下注选择的范围相同，则视为中奖，其余情形视为不中奖。',
          '中三：如开出之号码的第二球（千位）、第三球（佰位）及第四球（拾位）相加的和数与下注选择的范围相同，则视为中奖，其余情形视为不中奖。',
          '后三：如开出之号码的第三球（佰位）、第四球（拾位）及第五球（个位）相加的和数与下注选择的范围相同，则视为中奖，其余情形视为不中奖。',
        ]
      }, {
        header: '三字和数尾数',
        body: [
          '前三：在0~9中选择一个号码下注。如下注号码与开出之号码的第一球（万位）、第二球（千位）及第三球（佰位）相加的和数之尾数相同，则视为中奖，其余情形视为不中奖。',
          '中三：在0~9中选择一个号码下注。如下注号码与开出之号码的第二球（千位）、第三球（佰位）及第四球（拾位）相加的和数之尾数相同，则视为中奖，其余情形视为不中奖。',
          '后三：在0~9中选择一个号码下注。如下注号码与开出之号码的第三球（佰位）、第四球（拾位）及第五球（个位）相加的和数之尾数相同，则视为中奖，其余情形视为不中奖。',
        ]
      }, {
        header: '组选三',
        body: [
          '在前三球、中三球、后三球中可以挑选5~10个号码，当开奖结果中有且只有两个号码重复，则视为中奖。挑选不同个数号码有其相对应的赔率。如果是选择 1、2、3、4、5中的任何两个号码，且其中有一个号码重复则中奖。 举例：112、344，若是开出豹子则不算中奖。 【附注】："豹子"为三字同号，例如：111、222。'
        ]
      }, {
        header: '组选六',
        body: [
          '在前三球、中三球、后三球中可以挑选择4~8个号码，当开奖结果都出现在所下注的号码中且没有任何号码重复，则视为中奖。挑选不同的号码有其相对应的赔率，中奖赔率以所选号码中的最小赔率计算彩派。 举例：如果是选择(1、2、3、4)，则开奖结果为123、124、134、234都中奖，其他都是不中奖。例如：112、133、145、444等都是不中奖。'
        ]
      }, {
        header: '复式组合',
        body: [
          '在前三球、中三球、后三球中分别选择号码组合。例如在后三选择3x2x4，即佰位数选3个号码，拾位数选2个号码，个位数选4个号码或选择3x3x3，如每个位数所选择的号码都对应出现在开奖结果中，则为中奖。 此游戏选号有以下限制： 每一位数最少要选择1个号码，最多选10个号码。 三个位数共选取号码数量，最少需选择9个号，最多选择21个号码。'
        ]
      }, {
        header: '跨度',
        body: [
          '以开奖三个号码（前三球、中三球、后三球）的最大差距(跨度)，作为中奖的依据。会员可以选择0~9的任一跨度。 举例：开奖结果为3，4，8。中奖的跨度为5。(最大号码 8减最小号码 3 = 5)。若开奖结果三号码都相同，称作豹子，投注注豹子者中奖，其馀为不中奖。'
        ]
      }, {
        header: '龙虎组合',
        body: [
          '万千：开奖结果万位大于千位为龙、千位大于万位为虎、二者相同为和；投注和局者视为中奖，投注龙虎返还投注金额。万千总和的个位数1,3,5,7,9时为“单”，0,2,4,6,8时为“双”；5~9为“大”，0~4时为“小”。 投注方案：龙、大，开奖号码：54321，即中龙虎和万千，和值大。',
          '万佰：开奖结果万位大于佰位为龙、佰位大于万位为虎、二者相同为和；投注和局者视为中奖，投注龙虎返还投注金额。万佰总和的个位数1,3,5,7,9时为“单”，0,2,4,6,8时为“双”；5~9为“大”，0~4时为“小”。 投注方案：龙、大，开奖号码：54321，即中龙虎和万千，和值大。',
          '万拾：开奖结果万位大于拾位为龙、拾位大于万位为虎、二者相同为和；投注和局者视为中奖，投注龙虎返还投注金额。万拾总和的个位数1,3,5,7,9时为“单”，0,2,4,6,8时为“双”；5~9为“大”，0~4时为“小”。 投注方案：龙、大，开奖号码：54321，即中龙虎和万千，和值大。',
          '万个：开奖结果万位大于个位为龙、个位大于万位为虎、二者相同为和；投注和局者视为中奖，投注龙虎返还投注金额。万个总和的个位数1,3,5,7,9时为“单”，0,2,4,6,8时为“双”；5~9为“大”，0~4时为“小”。 投注方案：龙、大，开奖号码：54321，即中龙虎和万千，和值大。',
          '千佰：开奖结果千位大于佰位为龙、佰位大于千位为虎、二者相同为和；投注和局者视为中奖，投注龙虎返还投注金额。千佰总和的个位数1,3,5,7,9时为“单”，0,2,4,6,8时为“双”；5~9为“大”，0~4时为“小”。 投注方案：龙、大，开奖号码：54321，即中龙虎和万千，和值大。',
          '千拾：开奖结果千位大于拾位为龙、拾位大于千位为虎、二者相同为和；投注和局者视为中奖，投注龙虎返还投注金额。千拾总和的个位数1,3,5,7,9时为“单”，0,2,4,6,8时为“双”；5~9为“大”，0~4时为“小”。 投注方案：龙、大，开奖号码：54321，即中龙虎和万千，和值大。',
          '千个：开奖结果千位大于个位为龙、个位大于千位为虎、二者相同为和；投注和局者视为中奖，投注龙虎返还投注金额。千个总和的个位数1,3,5,7,9时为“单”，0,2,4,6,8时为“双”；5~9为“大”，0~4时为“小”。 投注方案：龙、大，开奖号码：54321，即中龙虎和万千，和值大。',
          '佰拾：开奖结果佰位大于拾位为龙、拾位大于佰位为虎、二者相同为和；投注和局者视为中奖，投注龙虎返还投注金额。佰拾总和的个位数1,3,5,7,9时为“单”，0,2,4,6,8时为“双”；5~9为“大”，0~4时为“小”。 投注方案：龙、大，开奖号码：54321，即中龙虎和万千，和值大。',
          '佰个：开奖结果佰位大于个位为龙、个位大于佰位为虎、二者相同为和；投注和局者视为中奖，投注龙虎返还投注金额。佰个总和的个位数1,3,5,7,9时为“单”，0,2,4,6,8时为“双”；5~9为“大”，0~4时为“小”。 投注方案：龙、大，开奖号码：54321，即中龙虎和万千，和值大。',
          '拾个：开奖结果拾位大于个位为龙、个位大于拾位为虎、二者相同为和；投注和局者视为中奖，投注龙虎返还投注金额。拾个总和的个位数1,3,5,7,9时为“单”，0,2,4,6,8时为“双”；5~9为“大”，0~4时为“小”。 投注方案：龙、大，开奖号码：54321，即中龙虎和万千，和值大。',
        ]
      }, {
        header: '牛牛：',
        body: [
          '以開出之五個號碼為基礎,組合任三個號碼成0或10的倍數取剩余兩個號碼之和為點數(大於10時減10作為對獎基準,例如:00017為8點,02818為9點,99219.88400皆為10點俗稱牛牛;01234,12589因任三個數都無法組合成0或10的倍數此局視為無點，當五個號碼相同時，例：11111…99999視為「無點」，00000則視為「牛牛」。'
        ]
      }, {
        header: '牛牛雙面：',
        body: [
          '牛大(6、7、8、9、10) ，牛小 (1、2、3、4、5 ) ：若開出的點數在大或小區則中獎。無點(無牛)則視為不中獎。',
          '牛單(1、3、5、7、9 ) ，牛雙 (2、4、6、8、10) ：若開出的點數在單或雙區則中獎。無點(無牛)則視為不中獎。',
        ]
      }, {
        header: '梭哈：',
        body: [
          '五梅：開出的五個號碼全部相同,例如:00000,11111,22222。',
          '四帶壹：開出的五個號碼中四個相同，例如:10000,11110,22221,33331,44442,55552。',
          '葫蘆：開出的五個號碼中三個相同(三條)及兩個號碼相同(壹對)，例如:11222,22333,33444,11000。',
          '三條：開出的五個號碼中三個相同及剩下的兩個號碼不相同，例如:12333,01222,34555,66678。',
          '順子：開出的五個號碼從小到大排列為 01234,12345,23456,34567,45678, 56789,06789,01789,01289,01239。',
          '兩對：開出的五個號碼中有兩組相同，例如:00112,11223,33445,66779。',
          '單對：開出的五個號碼中有且只有壹組相同,例如:00123,00789,55678。',
          '五不靠：開出的五個號碼從小到大排列為13579,02468。',
          '散號：開出的五個號碼沒有任何相同或相連關系的，例如:12356,01345,34678,12579。',
        ]
      }
    ]),
};

export default SSC;