import React, { useEffect, useState } from 'react';

import { Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';

import './styles.scss';

const mapStatetoProps = state => ({
  template: state.gameReducers.template
});

const TabContainer = ({ content, navMenu, noIndicator, template, resetSelection }) => {
  const [activeTab, setActiveTab] = useState(navMenu[0].value);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    if (resetSelection) {
      setActiveTab(navMenu[1].value);
    }
  }, [template]);

  return (
    <Col md="12" className="tabContainer p-0 my-2">
      <Nav tabs>
        {
          navMenu && navMenu.map(item => (
            <NavItem className={`${item.className}`}>
              <NavLink
                className={`${noIndicator && activeTab === item.value ? 'active' : 'inactive'}`}
                onClick={() => { item.value !== '#' && toggle(item.value); }}
              >
                {item.label}
              </NavLink>
              {!noIndicator && (<div className={`${activeTab === item.value ? 'active' : 'inactive'} indicator`} />)}
            </NavItem>
          ))
        }
      </Nav>
      <TabContent activeTab={activeTab}>
        {
          content.map(item => (
            <TabPane tabId={item.key}>
              {
                item.children.map(tabChild => {
                  const TempComponent = tabChild.componentType;
                  return <TempComponent {...tabChild.data} />;
                })
              }
            </TabPane>
          ))

        }
      </TabContent>
    </Col>
  );
};

export default connect(mapStatetoProps)(TabContainer);