import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const LiangMian = [
  {
    componentType: CollapseType,
    header: '总和、龙虎',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'ZHLM41',
          displayName: '总和大',
        }, {
          code: 'ZHDS43',
          displayName: '总和单',
        }, {
          code: 'ZHWDX45',
          displayName: '总尾大',
        }, {
          code: 'ZHLM47',
          displayName: '龙',
        }, {
          code: 'ZHLM42',
          displayName: '总和小',
        }, {
          code: 'ZHDS44',
          displayName: '总和双',
        }, {
          code: 'ZHWDX46',
          displayName: '总尾小',
        }, {
          code: 'ZHLM48',
          displayName: '虎',
        },
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第一球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B1LM1',
          displayName: '大'
        },
        {
          code: 'B1LM2',
          displayName: '小'
        },
        {
          code: 'B1LM3',
          displayName: '单'
        },
        {
          code: 'B1LM4',
          displayName: '双'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第二球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B2LM9',
          displayName: '大'
        },
        {
          code: 'B2LM10',
          displayName: '小'
        },
        {
          code: 'B2LM11',
          displayName: '单'
        },
        {
          code: 'B2LM12',
          displayName: '双'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第三球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B3LM17',
          displayName: '大'
        },
        {
          code: 'B3LM18',
          displayName: '小'
        },
        {
          code: 'B3LM19',
          displayName: '单'
        },
        {
          code: 'B3LM20',
          displayName: '双'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第四球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B4LM25',
          displayName: '大'
        },
        {
          code: 'B4LM26',
          displayName: '小'
        },
        {
          code: 'B4LM27',
          displayName: '单'
        },
        {
          code: 'B4LM28',
          displayName: '双'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第五球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B5LM33',
          displayName: '大'
        },
        {
          code: 'B5LM34',
          displayName: '小'
        },
        {
          code: 'B5LM35',
          displayName: '单'
        },
        {
          code: 'B5LM36',
          displayName: '双'
        }
      ]
    }
  }
];

export default LiangMian;