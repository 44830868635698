import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const TEMA = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '赔率', '金额'],
      mainColumnLength: 4,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      emptyItemStyle: ['column-3items-empty-label', 'column-3items-empty-odds', 'column-3items-empty-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'TM1',
          displayName: '01',
          className: 'circle circleRed',
        }, {
          code: 'TM11',
          displayName: '11',
          className: 'circle circleGreen',
        }, {
          code: 'TM21',
          displayName: '21',
          className: 'circle circleGreen',
        }, {
          code: 'TM31',
          displayName: '31',
          className: 'circle circleBlue',
        }, {
          code: 'TM2',
          displayName: '02',
          className: 'circle circleRed',
        }, {
          code: 'TM12',
          displayName: '12',
          className: 'circle circleRed',
        }, {
          code: 'TM22',
          displayName: '22',
          className: 'circle circleGreen',
        }, {
          code: 'TM3',
          displayName: '03',
          className: 'circle circleBlue',
          newLine: true,
        }, {
          code: 'TM13',
          displayName: '13',
          className: 'circle circleRed',
        }, {
          code: 'TM23',
          displayName: '23',
          className: 'circle circleRed',
        }, {
          code: 'TM4',
          displayName: '04',
          className: 'circle',
          newLine: true,
        }, {
          code: 'TM14',
          displayName: '14',
          className: 'circle',
        }, {
          code: 'TM24',
          displayName: '24',
          className: 'circle circleRed',
        }, {
          code: 'TM5',
          displayName: '05',
          className: 'circle circleGreen',
          newLine: true,
        }, {
          code: 'TM15',
          displayName: '15',
          className: 'circle',
        }, {
          code: 'TM25',
          displayName: '25',
          className: 'circle',
        }, {
          code: 'TM6',
          displayName: '06',
          className: 'circle circleGreen',
          newLine: true,
        }, {
          code: 'TM16',
          displayName: '16',
          className: 'circle circleGreen',
        }, {
          code: 'TM26',
          displayName: '26',
          className: 'circle',
        }, {
          code: 'TM7',
          displayName: '07',
          className: 'circle circleRed',
          newLine: true,
        }, {
          code: 'TM17',
          displayName: '17',
          className: 'circle circleGreen',
        }, {
          code: 'TM27',
          displayName: '27',
          className: 'circle circleGreen',
        }, {
          code: 'TM8',
          displayName: '08',
          className: 'circle circleRed',
          newLine: true,
        }, {
          code: 'TM18',
          displayName: '18',
          className: 'circle circleRed',
        }, {
          code: 'TM28',
          displayName: '28',
          className: 'circle circleGreen',
        }, {
          code: 'TM9',
          displayName: '09',
          className: 'circle',
          newLine: true,
        }, {
          code: 'TM19',
          displayName: '19',
          className: 'circle circleRed',
        }, {
          code: 'TM29',
          displayName: '29',
          className: 'circle circleRed',
        }, {
          code: 'TM10',
          displayName: '10',
          className: 'circle',
          newLine: true,
        }, {
          code: 'TM20',
          displayName: '20',
          className: 'circle',
        }, {
          code: 'TM30',
          displayName: '30',
          className: 'circle circleRed',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default TEMA;