import React, { Suspense, lazy, useEffect } from 'react';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { connect } from 'react-redux';

import zhCN from 'antd/es/locale/zh_CN';

// Web
import Home from './pages/Web/Home/index';

import { appActions, cashActions, companyActions, gameActions, homeActions, noticeActions } from './redux/actions';
import { store } from './redux';
import './App.scss';

import { GameVariable } from './constants/index';

const AuthLayout = lazy(() => import('./containers/Web/Auth/AuthLayout'));
const MobileAuthLayout = lazy(() => import('./containers/Mobile/Auth/AuthLayout'));
const Agreement = lazy(() => import('./pages/Web/Agreement'));
const MobilePublicLayout = lazy(() => import('./containers/Mobile/Public/PublicLayout'));
const NoMatch = lazy(() => import('./pages/Web/NoMatch'));

const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
  themeColor: state.appReducers.themeColor,
  lotteryType: state.gameReducers.lotteryType,
  template: state.gameReducers.template,
  selectedSubType: state.gameReducers.selectedSubType,
  companyInfo: state.companyReducers.companyInfo,
});

const mapDispatchToProps = dispatch => ({
  updateThemeColor: data => dispatch(appActions.updateThemeColor(data)),

  getUserGames: successCallback => dispatch(homeActions.getUserGames(successCallback)),
  updateLotteryAndTemplate: value => dispatch(gameActions.updateLotteryAndTemplate(value)),
  updateSelectedSubGameList: selectedSubgameList => dispatch(gameActions.updateSelectedSubGameList(selectedSubgameList)),
  getCompanyInfo: () => dispatch(companyActions.getInfo()),
  getSecurityInfo: () => dispatch(cashActions.getSecurityInfo()),
  getHomeIndex: () => dispatch(homeActions.getHomeIndex()),
  getLatestAnnouncement: () => dispatch(noticeActions.latestAnnouncement()),
});

function App({
  userData,
  updateThemeColor,
  themeColor,
  lotteryType,
  companyInfo,
  getUserGames,
  updateLotteryAndTemplate,
  getCompanyInfo,
  getSecurityInfo,
  updateSelectedSubGameList,
  userGames,
  selectedSubType,
  getHomeIndex,
  getLatestAnnouncement
}) {
  const detectPlatform = () => {
    const sUserAgent = navigator.userAgent.toLowerCase();
    const bIsIpad = sUserAgent.match(/ipad/i) !== null;
    const bIsIphoneOs = sUserAgent.match(/iphone os/i) !== null;
    const bIsMidp = sUserAgent.match(/midp/i) !== null;
    const bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) !== null;
    const bIsUc = sUserAgent.match(/ucweb/i) !== null;
    const bIsAndroid = sUserAgent.match(/android/i) !== null;
    const bIsCE = sUserAgent.match(/windows ce/i) !== null;
    const bIsWM = sUserAgent.match(/windows mobile/i) !== null;
    if ((bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM)) {
      store.dispatch(appActions.updatePlatForm('Mobile'));
      return 'Mobile';
    }
    store.dispatch(appActions.updatePlatForm('Web'));
    return 'Web';
  };
  const platform = detectPlatform();


  useEffect(() => {
    const temp = localStorage.getItem('themeColor');
    if (!themeColor || !temp) {
      if (temp) {
        updateThemeColor(temp);
      } else {
        updateThemeColor('blue');
        localStorage.setItem('themeColor', 'blue');
      }
    }
    if (companyInfo && lotteryType) {
      const { company: { lotteryStyles } } = companyInfo || {};
      const { cssStyle } = lotteryStyles?.find(x => x.lotteryType === lotteryType?.item) || {};
      if (cssStyle) {
        updateThemeColor(cssStyle);
      } else {
        updateThemeColor(temp || 'blue');
      }
    }
  }, [companyInfo, lotteryType]);

  useEffect(() => {
    if (userData) {
      getCompanyInfo();
      if (userData?.isVisitor !== 1) {
        getSecurityInfo();
      }
      if (platform === 'Web') {
        if (!userGames && !selectedSubType) {
          getUserGames(userGames => {
            const { lotteryList } = userGames || {};
            const firstLottery = lotteryList[0];
            const newSubGameList = GameVariable[`CJ_${firstLottery.templateCode}`].children.filter(
              _item => (
                (!firstLottery.gameStatus.find(_gameStatus => _gameStatus.pageCode.toUpperCase() === _item.tabId)) || (firstLottery?.isSupportZmt && _item.tabId === 'ZMTB')
              )
            );
            updateLotteryAndTemplate({
              template: lotteryList[0].templateCode,
              lotteryType: {
                item: lotteryList[0].code,
                redirect: false,
              },
              selectedSubType: GameVariable[`CJ_${lotteryList[0].templateCode}`].children[0].tabId
            });
            updateSelectedSubGameList(newSubGameList);
          });
        }
      }
    }
    getHomeIndex();
    getLatestAnnouncement();
  }, [userData]);

  return (
    <div className={`theme-${themeColor || 'blue'} App`}>
      <ConfigProvider locale={zhCN}>
        <BrowserRouter>
          <Suspense>
            <Switch>
              <Route exact path="/reset-password" name="reset Password" render={props => (platform === 'Web' ? <Home {...props} /> : <MobilePublicLayout {...props} />)} />
              <Route exact path="/forget-password" name="Forget Password" render={props => (platform === 'Web' ? <Home {...props} /> : <MobilePublicLayout {...props} />)} />
              <Route exact path="/register" name="Register" render={props => (platform === 'Web' ? <Home {...props} /> : <MobilePublicLayout {...props} />)} />
              <Route exact path="/login" name="Login" render={props => (platform === 'Web' ? <Home {...props} /> : <MobilePublicLayout {...props} />)} />
              {
              userData && !userData?.checkPassword && (
                <Route
                  path="/user"
                  name="User"
                  render={props => (
                    (platform === 'Web'
                      ? <AuthLayout {...props} />
                      : <MobileAuthLayout {...props} />)
                  )}
                />
              )
            }
              {userData && !userData?.checkPassword
            && <Route exact path="/agreement" name="Agreement" render={props => <Agreement {...props} />} />}

              <Route exact path="/customer-service" name="CustomerService" render={props => <MobileAuthLayout {...props} />} />

              <Route
                exact
                path="/"
                name="Home"
                render={props => (platform === 'Web' ? <Home {...props} />
                  : <MobileAuthLayout {...props} />
                )}
              />
              <Route
                path="*"
                name="Not Found"
                render={props => (!userData ? (
                  <Redirect to={{
                    pathname: '/',
                  }}
                  />
                ) : <NoMatch {...props} />)}
              />

            </Switch>
          </Suspense>
        </BrowserRouter>
      </ConfigProvider>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);