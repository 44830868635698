import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const QSB = [
  {
    componentType: CollapseType,
    header: '七色波',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper PK10ItemWrapperGY',
      list: [
        {
          code: '7SB28',
          displayName: '红波',
          className: 'fillRed',
        }, {
          code: '7SB29',
          displayName: '蓝波',
          className: 'fillBlue',
        }, {
          code: '7SB30',
          displayName: '绿波',
          className: 'fillGreen',
        }, {
          code: '7SB31',
          displayName: '和局',
          className: 'fillGreen',
        }
      ]
    }
  }
];

export default QSB;