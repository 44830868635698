const KJ = {
  lotterySpecific: gameData => {
    const { interval, lotteryBeginTimeBase, lotteryEndTimeBase, lotteryName, code, lotteryTermCycle } = gameData || {};
    const endTimeString = lotteryEndTimeBase ? `开到晚上${lotteryEndTimeBase}` : '';
    const intervalString = interval % 60 === 0 ? `${interval / 60}分` : `${interval}秒`;
    switch (code) {
      case 'BJK3' || 'GXK3':
        return `该游戏的投注时间、开奖时间和开奖号码与“${lotteryName}”完全同步，北京时间（GMT+8）每天白天从上午${lotteryBeginTimeBase}${endTimeString}，每${intervalString}钟开一次奖,每天开奖${lotteryTermCycle}期。`;

      case 'JSUK3' || 'FJK3':
        return `该游戏保证公平公正；您可以放心投注、开奖时间，北京时间（GMT+8）每天从${lotteryBeginTimeBase}开始，每${intervalString}钟开一次奖,每天开奖${lotteryTermCycle}期。`;

      default:
        return `以下所有投注皆含本金\n该游戏保证公平公正；您可以放心投注、开奖时间，北京时间（GMT+8）每天从${lotteryBeginTimeBase}开始，每${intervalString}开一次奖,每天开奖${lotteryTermCycle}期。`;
    }
  },
  templateSpecific: () => ([{
    header: '骰宝',
    body: [
      '小：三粒骰子之点数总和由4点至10点；',
      '大：三粒骰子之点数总和由11点至17点；注：若三粒骰子平面点数相同，通吃「大」、「小」各注。',
      '三军/鱼虾蟹：任何一粒骰子出现选定之平面点数；',
      '围骰：三粒骰子平面与选定点数相同；',
      '全骰：在一点至六点内，三粒骰子平面点数相同；',
      '点数：由4点至17点，三粒骰子平面点数之总和；',
      '长牌：任两粒骰子之平面点数；',
      '短牌：选定两粒骰子之平面点数；',
    ]
  }])
};

export default KJ;