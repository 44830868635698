import BZ from './BZ';
import L3Z2 from './L3Z2';
import L7T4 from './L7T4';
import L7Z4 from './L7Z4';
import LIUXIAO from './LIUXIAO';
import LM from './LM';
import LMPL from './LMPL';
import TEMA from './TEMA';
import TUOTOU from './TUOTOU';
import Z1T from './Z1T';
import Z2T from './Z2T';
import Z3T from './Z3T';
import Z4T from './Z4T';
import Z5T from './Z5T';
import Z6T from './Z6T';
import Z7T from './Z7T';
import ZHENGMA from './ZHENGMA';

const F36X7 = {
  children: [
    {
      tabId: 'LM',
      label: '连码',
      children: LM.LM,
      settingCode: '3Z2,4QZ,3QZ,2QZ，4ZT,1ZB,2ZB,3ZB,3ZT'
    },
    {
      tabId: 'TUOTOU',
      label: '拖头',
      children: TUOTOU.TUOTOU,
      midCode: 'L2QZ'
    },
    {
      tabId: 'LMPL',
      label: '两面盘类',
      children: LMPL.LMPL,
      settingCode: 'TMLM,ZMLM'
    },
    {
      tabId: 'LIUXIAO',
      label: '六肖',
      children: LIUXIAO.LIUXIAO,
    },
    {
      tabId: 'ZHENGMA',
      label: '正码',
      children: ZHENGMA.ZHENGMA,
      settingCode: 'ZM,ZFLM'
    },
    {
      tabId: 'TEMA',
      label: '特码',
      children: TEMA.TEMA,
      settingCode: 'TM'
    },
    {
      tabId: 'Z1T',
      label: '正1特',
      children: Z1T.Z1T,
      midCode: 'Z1T'
    },
    {
      tabId: 'Z2T',
      label: '正2特',
      children: Z2T.Z2T,
      midCode: 'Z2T'
    },
    {
      tabId: 'Z3T',
      label: '正3特',
      children: Z3T.Z3T,
      midCode: 'Z3T'
    },
    {
      tabId: 'Z4T',
      label: '正4特',
      children: Z4T.Z4T,
      midCode: 'Z4T'
    },
    {
      tabId: 'Z5T',
      label: '正5特',
      children: Z5T.Z5T,
      midCode: 'Z5T'
    },
    {
      tabId: 'Z6T',
      label: '正6特',
      children: Z6T.Z6T,
      midCode: 'Z6T'
    },
    {
      tabId: 'Z7T',
      label: '正7特',
      children: Z7T.Z7T,
      midCode: 'Z7T'
    },
    {
      tabId: 'BZ',
      label: '不中',
      children: BZ.BZ,
    },
    {
      tabId: 'L3Z2',
      label: '三中二',
      children: L3Z2.L3Z2,
    },
    {
      tabId: 'L7Z4',
      label: '七中四',
      children: L7Z4.L7Z4,
    },
    {
      tabId: 'L7T4',
      label: '七拖四',
      children: L7T4.L7T4,
    },
  ],
  results: {
    header: [{
      value: '开奖结果'
    }],
    // TabList: [{
    //   code: 'NUMBER',
    //   renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
    //     value: item, className: ''
    //   }))
    // },
    // {
    //   code: 'BIG_SMALL',
    //   renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
    //     value: item <= 5 ? '小' : '大', className: ''
    //   }))
    // },
    // {
    //   code: 'ODD_EVEN',
    //   renderFunc: rowItem => rowItem.openResult.split(',').map(item => ({
    //     value: item % 2 ? '单' : '双', className: ''
    //   }))
    // }],
    defaultFunc: () => {}
  }
};

export default F36X7;