import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const ZXZX = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['生肖', '号码', '赔率', '金额'],
      mainColumnLength: 2,
      subColumnLength: 4,
      subColumnStyle: ['column-4items-label', 'column-4items-balls', 'column-4items-odds', 'column-4items-input'],
      betItemStyle: ['column-4items-label', 'column-4items-array-balls column-4items-balls', 'column-4items-odds', 'column-4items-input'],
      betItemList: ['DisplayText', 'HK6_Zodiac', 'Odds', 'Input'],
      betItemColumn: 4,
      zodiacTotalBall: 49,
      list: [
        {
          code: 'ZENGX13', // keyCode
          displayName: '鼠',
        }, {
          code: 'ZENGX19',
          displayName: '马',
        }, {
          code: 'ZENGX14',
          displayName: '牛',
        }, {
          code: 'ZENGX20',
          displayName: '羊',
        }, {
          code: 'ZENGX15',
          displayName: '虎',
        }, {
          code: 'ZENGX21',
          displayName: '猴',
        }, {
          code: 'ZENGX16',
          displayName: '兔',
        }, {
          code: 'ZENGX22',
          displayName: '鸡',
        }, {
          code: 'ZENGX17',
          displayName: '龙',
        }, {
          code: 'ZENGX23',
          displayName: '狗',
        }, {
          code: 'ZENGX18',
          displayName: '蛇',
        }, {
          code: 'ZENGX24',
          displayName: '猪',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['种类', '赔率', '金额'],
      mainColumnLength: 4,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'ZX25', // keyCode
          displayName: '2肖',
        }, {
          code: 'ZX27',
          displayName: '4肖',
        }, {
          code: 'ZX29',
          displayName: '6肖',
        }, {
          code: 'ZXDS31',
          displayName: '总肖单',
        }, {
          code: 'ZX26',
          displayName: '3肖',
        }, {
          code: 'ZX28',
          displayName: '5肖',
        }, {
          code: 'ZX30',
          displayName: '7肖',
        }, {
          code: 'ZXDS32',
          displayName: '总肖双',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default ZXZX;