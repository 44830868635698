import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import PengAndDan from '../../../../components/Mobile/ComponentType/PengAndDan';
import TeMaAndLianMa from '../../../../components/Mobile/ComponentType/TeMaAndLianMa';

import OptionItem from '../../../../components/Mobile/OptionItem';
import { renderHeXiaoBalls } from '../../../utils';

const HX = [
  {
    componentType: TeMaAndLianMa,
    data: {
      list: [
        {
          code: 'TMHX3',
          displayName: '2肖中',
          minimumSelection: 2,
          maximumSelection: 11,
          danList: ['1'],
        }, {
          code: 'TMHX4',
          displayName: '2肖不中',
          minimumSelection: 2,
          maximumSelection: 11,
          danList: ['1'],
        }, {
          code: 'TMHX5',
          displayName: '3肖中',
          minimumSelection: 3,
          maximumSelection: 11,
          danList: ['1', '2'],
        }, {
          code: 'TMHX6',
          displayName: '3肖不中',
          minimumSelection: 3,
          maximumSelection: 11,
          danList: ['1', '2'],
        }, {
          code: 'TMHX7',
          displayName: '4肖中',
          minimumSelection: 4,
          maximumSelection: 11,
          danList: ['1', '2', '3'],
        }, {
          code: 'TMHX8',
          displayName: '4肖不中',
          minimumSelection: 4,
          maximumSelection: 11,
          danList: ['1', '2', '3'],
        }, {
          code: 'TMHX9',
          displayName: '5肖中',
          minimumSelection: 5,
          maximumSelection: 10,
          danList: ['1', '2', '3', '4'],
        }, {
          code: 'TMHX10',
          displayName: '5肖不中',
          minimumSelection: 5,
          maximumSelection: 10,
          danList: ['1', '2', '3', '4'],
        }, {
          code: 'TMHX11',
          displayName: '6肖中',
          minimumSelection: 6,
          maximumSelection: 10,
          danList: ['1', '2', '3', '4', '5'],
        }, {
          code: 'TMHX12',
          displayName: '6肖不中',
          minimumSelection: 6,
          maximumSelection: 10,
          danList: ['1', '2', '3', '4', '5'],
        },
        {
          code: 'TMHX13',
          displayName: '7肖中',
          minimumSelection: 7,
          maximumSelection: 11,
          danList: ['1', '2', '3', '4', '5', '6'],
        }, {
          code: 'TMHX14',
          displayName: '7肖不中',
          minimumSelection: 7,
          maximumSelection: 11,
          danList: ['1', '2', '3', '4', '5', '6'],
        }, {
          code: 'TMHX15',
          displayName: '8肖中',
          minimumSelection: 8,
          maximumSelection: 11,
          danList: ['1', '2', '3', '4', '5', '6', '7'],
        }, {
          code: 'TMHX16',
          displayName: '8肖不中',
          minimumSelection: 8,
          maximumSelection: 11,
          danList: ['1', '2', '3', '4', '5', '6', '7'],
        }, {
          code: 'TMHX17',
          displayName: '9肖中',
          minimumSelection: 9,
          maximumSelection: 11,
          danList: ['1', '2', '3', '4', '5', '6', '7', '8'],
        }, {
          code: 'TMHX18',
          displayName: '9肖不中',
          minimumSelection: 9,
          maximumSelection: 11,
          danList: ['1', '2', '3', '4', '5', '6', '7', '8'],
        }, {
          code: 'TMHX19',
          displayName: '10肖中',
          minimumSelection: 10,
          maximumSelection: 11,
          danList: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
        }, {
          code: 'TMHX20',
          displayName: '10肖不中',
          minimumSelection: 10,
          maximumSelection: 11,
          danList: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
        }, {
          code: 'TMHX21',
          displayName: '11肖中',
          minimumSelection: 11,
          maximumSelection: 11,
          danList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        }, {
          code: 'TMHX22',
          displayName: '11肖不中',
          minimumSelection: 11,
          maximumSelection: 11,
          danList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        }
      ]
    }
  },
  {
    componentType: PengAndDan,
    data: {}
  },
  {
    componentType: CollapseType,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper HK6TeXiao',
      list: [
        {
          displayName: '鼠',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 48),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '马',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 48),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '牛',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 48),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '羊',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 48),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '虎',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 48),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '猴',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 48),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '兔',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 48),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '鸡',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 48),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '龙',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 48),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '狗',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 48),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '蛇',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 48),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '猪',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 48),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }
      ]
    }
  }
];

export default HX;