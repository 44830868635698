import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const ZSP = [
  {
    componentType: CollapseType,
    header: '和值',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'HZ1',
          displayName: '0',
          drawImgClassName: 'circle circleYellow'
        },
        {
          code: 'HZ2',
          displayName: '1',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'HZ3',
          displayName: '2',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'HZ4',
          displayName: '3',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'HZ5',
          displayName: '4',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'HZ6',
          displayName: '5',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'HZ7',
          displayName: '6',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'HZ8',
          displayName: '7',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'HZ9',
          displayName: '8',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'HZ10',
          displayName: '9',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'HZ11',
          displayName: '10',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'HZ12',
          displayName: '11',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'HZ13',
          displayName: '12',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'HZ14',
          displayName: '13',
          drawImgClassName: 'circle circleYellow'
        },
        {
          code: 'HZ15',
          displayName: '14',
          drawImgClassName: 'circle circleYellow'
        },
        {
          code: 'HZ16',
          displayName: '15',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'HZ17',
          displayName: '16',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'HZ18',
          displayName: '17',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'HZ19',
          displayName: '18',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'HZ20',
          displayName: '19',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'HZ21',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'HZ22',
          displayName: '21',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'HZ23',
          displayName: '22',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'HZ24',
          displayName: '23',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'HZ25',
          displayName: '24',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'HZ26',
          displayName: '25',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'HZ27',
          displayName: '26',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'HZ28',
          displayName: '27',
          drawImgClassName: 'circle circleYellow'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '两面',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'DX1',
          displayName: '大',
        }, {
          code: 'DS3',
          displayName: '单',
        }, {
          code: 'JDX5',
          displayName: '极大',
        }, {
          code: 'ZH7',
          displayName: '大单',
        }, {
          code: 'ZH8',
          displayName: '大双',
        }, {
          code: 'DX2',
          displayName: '小',
        }, {
          code: 'DS4',
          displayName: '双',
        }, {
          code: 'JDX6',
          displayName: '极小',
        }, {
          code: 'ZH10',
          displayName: '小单',
        }, {
          code: 'ZH9',
          displayName: '小双',
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '色波、豹子',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [{
        code: 'SB1',
        displayName: '红波',
      }, {
        code: 'SB3',
        displayName: '绿波',
      }, {
        code: 'SB2',
        displayName: '蓝波',
      }, {
        code: 'BZ4',
        displayName: '豹子',
      }]
    }
  }
];

export default ZSP;