import React, { useEffect, useState } from 'react';

import { IoCheckmarkOutline } from 'react-icons/io5';
import { connect } from 'react-redux';

import { quickBetDictionary, renderHeXiaoBalls } from '../../../constants/utils';

import { appActions, cartActions, gameActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  gameInfo: state.placeBetReducers.gameInfo,
  presetAmount: state.gameReducers.presetAmount,
  cart: state.cartReducers.cart,
  sectionSelection: state.gameReducers.sectionSelection,
  companyInfo: state.companyReducers.companyInfo,
  selectedSubType: state.gameReducers.selectedSubType,
  betTypeSelection: state.gameReducers.betTypeSelection,
  selectedDan: state.gameReducers.selectedDan,
  currentSelected: state.gameReducers.currentSelected,
  templateCode: state.gameReducers.template,
});

const mapDispatchToProps = dispatch => ({
  addItemToCart: (item, reset) => dispatch(cartActions.addItemToCart(item, reset)),
  removeItemInCart: keyCode => dispatch(cartActions.removeItemInCart(keyCode)),
  updateSelectedDan: dan => dispatch(gameActions.updateSelectedDan(dan)),
  updateCurrentSelected: val => dispatch(gameActions.updateCurrentSelected(val)),
  resetItemInCart: () => dispatch(cartActions.resetItemInCart()),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  updateCart: cart => dispatch(cartActions.updateCart(cart)),
});

const OptionItem = ({ templateCode, clickDisable, image, selectedDan, updateSelectedDan, betTypeSelection, sectionSelection, companyInfo, generateNumberWrapperStyle, generateNumber, selectedSubType, resetItemInCart, kuaiJieSelectedGroupIndex, currentSelected, updateCurrentSelected, code, cart, displayName, itemWrapperStyle, drawImgClassName, gameInfo, showOdds = true, addItemToCart, presetAmount, removeItemInCart }) => {
  const [_gameInfo, setGameInfo] = useState();
  const [_active, setActive] = useState();
  const [_oddStatus, setOddStatus] = useState('--');

  useEffect(() => {
    if (JSON.stringify(gameInfo) !== JSON.stringify(_gameInfo)) {
      setGameInfo(gameInfo);
    }
  }, [gameInfo]);

  useEffect(() => {
    if (_gameInfo && selectedDan.length === 0 && !cart) {
      let _emptyFlag = true;
      if (selectedSubType === 'KuaiJie' || ((selectedSubType === 'ZhiXuan' || selectedSubType === 'EZDW' || selectedSubType === 'SZDW' || selectedSubType === 'FSZH') && sectionSelection && sectionSelection?.numberOfSelectionBlock)) {
        currentSelected.forEach(x => {
          if (x?.size !== 0) {
            _emptyFlag = false;
          }
        });
        if (_emptyFlag) {
          setActive();
        }
      } else if (currentSelected.length === 0) {
        setActive();
      }
    }
  }, [cart, currentSelected, selectedDan, _gameInfo]);

  useEffect(() => {
    if (!_gameInfo) {
      setOddStatus('--');
      setActive();
    } else if (_gameInfo && _gameInfo.oddsList) {
      let odds;
      if (selectedSubType === 'KuaiJie' || selectedSubType === 'ZX3' || selectedSubType === 'ZX6') {
        odds = 1;
        if (selectedSubType === 'KuaiJie') {
          if (templateCode === 'HK6') {
            if (currentSelected[kuaiJieSelectedGroupIndex]?.has(kuaiJieSelectedGroupIndex === 0 ? displayName : Number(displayName))) {
              setActive('LP');
            } else {
              setActive();
            }
          } else if (currentSelected[kuaiJieSelectedGroupIndex]?.has(JSON.stringify({
            code, displayName
          }))) {
            setActive('LP');
          } else {
            setActive();
          }
        }
      } else if (sectionSelection && sectionSelection.code !== 'TMA' && sectionSelection.code !== 'TMB' && selectedSubType !== 'ZhiXuan') {
        let _item;
        if (selectedSubType === 'ZXBZ' || selectedSubType === 'LX' || selectedSubType === 'LM' || selectedSubType === 'LW' || selectedSubType === 'BZ' || selectedSubType === 'EZHS' || selectedSubType === 'SZHS') {
          if (selectedSubType === 'LX') {
            const _code = sectionSelection.code + (((sectionSelection.code.slice(1, 2) - 2) * 12) + Number(code));
            _item = _gameInfo.oddsList[_code];
          } else if (selectedSubType === 'LW') {
            const _code = sectionSelection.code + (((sectionSelection.code.slice(1, 2) - 2) * 10) + Number(code) + (sectionSelection.code.includes('BZ') ? 30 : 0));
            _item = _gameInfo.oddsList[_code];
          } else {
            _item = _gameInfo.oddsList[`${sectionSelection.code}${code}`];
          }
        } else {
          _item = _gameInfo.oddsList[sectionSelection.code];
        }
        odds = _item ? _item?.enabled === 1 ? _item.odds : '停押' : '--';
      } else if (selectedSubType === 'ZhiXuan' && kuaiJieSelectedGroupIndex > -1 && sectionSelection) {
        const _item = _gameInfo.oddsList[sectionSelection.code];
        odds = _item ? _item?.enabled === 1 ? _item.odds : '停押' : '--';
        if (sectionSelection?.numberOfSelectionBlock === 2) {
          if (kuaiJieSelectedGroupIndex === 0 && currentSelected[1]?.has(displayName)) {
            odds = '--';
          }
          if (kuaiJieSelectedGroupIndex === 1 && currentSelected[0]?.has(displayName)) {
            odds = '--';
          }
        }
        if (sectionSelection?.numberOfSelectionBlock === 3) {
          if (kuaiJieSelectedGroupIndex === 0 && (currentSelected[1]?.has(displayName) || currentSelected[2]?.has(displayName))) {
            odds = '--';
          }
          if (kuaiJieSelectedGroupIndex === 1 && (currentSelected[0]?.has(displayName) || currentSelected[2]?.has(displayName))) {
            odds = '--';
          }
          if (kuaiJieSelectedGroupIndex === 2 && (currentSelected[0]?.has(displayName) || currentSelected[1]?.has(displayName))) {
            odds = '--';
          }
        }
      } else {
        odds = _gameInfo.oddsList[code] ? _gameInfo.oddsList[code]?.enabled === 1 ? _gameInfo.oddsList[code]?.odds : '停押' : '--';
      }
      setOddStatus(odds);
    }
    return () => setOddStatus('--');
  }, [_gameInfo, currentSelected, sectionSelection]);

  const handlePermutation = (arr, permutationLength, tempDan, amount) => {
    const tempResult = {};
    const result = [];
    function combine(input, len, start) {
      if (len === 0) {
        const _result = result.map(x => x.displayName).sort().join(',');
        const _dan = tempDan.map(x => x.displayName).sort().join(',');
        let oddsResult;
        if (selectedSubType === 'LianMa' || selectedSubType === 'HX' || selectedSubType === 'LIUXIAO' || selectedSubType === 'TeMaBaoSan') {
          oddsResult = _gameInfo && _gameInfo.oddsList[sectionSelection.code];
        } else if ((sectionSelection.code.includes('BZ') && selectedSubType !== 'LX') || (selectedSubType === 'LX' && !sectionSelection.code.includes('BZ'))) {
          // select smaller odds
          (tempDan.length > 0 ? tempDan : result).forEach(item => (oddsResult ? item.odds <= oddsResult.odds ? oddsResult = item : oddsResult : oddsResult = item));
        } else {
          // select bigger odds
          (tempDan.length > 0 ? tempDan : result).forEach(item => (oddsResult ? item.odds >= oddsResult.odds ? oddsResult = item : oddsResult : oddsResult = item));
        }
        const tempDisplayName = tempDan.length > 0 ? [_dan, _result].join(',') : _result;

        return tempResult[tempDisplayName] = {
          categoryName: oddsResult.midType,
          displayName: tempDisplayName,
          odds: oddsResult.odds,
          midType: oddsResult.midType,
          keyCode: oddsResult.keyCode,
          amount: amount || parseInt(presetAmount, 10) || 0,
        };
      }

      for (let i = start; i <= input.length - len; i++) {
        if (tempDan.length > 0) result[permutationLength - len] = input[i];
        else result[sectionSelection.minimumSelection - len] = input[i];
        combine(input, len - 1, i + 1);
      }

      return null;
    }

    combine(arr, tempDan.length > 0 ? permutationLength : sectionSelection.minimumSelection, 0);

    return tempResult;
  };

  const onItemClick = () => {
    if (!_gameInfo.oddsList) return;
    if (selectedSubType === 'KuaiJie') {
      const temp = [...currentSelected];
      const tempResult = {};
      if (templateCode === 'HK6') {
        if (temp[kuaiJieSelectedGroupIndex].has(displayName)) {
          temp[kuaiJieSelectedGroupIndex].delete(displayName);
          setActive();
        } else {
          temp[kuaiJieSelectedGroupIndex].add(displayName);
          setActive('LP');
        }
        let tempArr = [];
        [...temp[kuaiJieSelectedGroupIndex]].forEach(x => {
          if (quickBetDictionary[x]) {
            tempArr = tempArr.concat(quickBetDictionary[x]);
          } else {
            const Lstemp = renderHeXiaoBalls(companyInfo.company.zodiac, x, 49).map(x => x.value);
            tempArr = tempArr.concat(Lstemp);
          }
        });
        updateCurrentSelected([temp[kuaiJieSelectedGroupIndex], new Set(tempArr)]);
        if (temp[kuaiJieSelectedGroupIndex].size > 0) {
          tempArr.forEach(x => {
            const currentOddItem = _gameInfo && _gameInfo.oddsList && (_gameInfo.oddsList[`TMA${x}`]);
            const { midType, odds, enabled, keyCode } = currentOddItem || {};
            if (enabled === 0) return;
            const tempDisplayName = `${x}`;
            tempResult[tempDisplayName] = {
              categoryName: midType,
              displayName: tempDisplayName,
              odds,
              midType,
              keyCode,
              amount: parseInt(presetAmount, 10) || 0,
            };
          });
          return addItemToCart(tempResult, true);
        }
        resetItemInCart();
      } else if (templateCode === 'PK10') {
        if (temp[kuaiJieSelectedGroupIndex].has(JSON.stringify({
          code, displayName
        }))) {
          temp[kuaiJieSelectedGroupIndex].delete(JSON.stringify({
            code, displayName
          }));
          setActive();
        } else {
          temp[kuaiJieSelectedGroupIndex].add(JSON.stringify({
            code, displayName
          }));
          setActive('LP');
        }
        updateCurrentSelected(temp);
        if (temp[0].size > 0 && temp[1].size > 0) {
          [...temp[0]].forEach(x => [...temp[1]].forEach(y => {
            const xObj = JSON.parse(x);
            const yObj = JSON.parse(y);
            const keyCode = `${xObj.code}${yObj.displayName > 0 ? xObj.code[0] === 'B' ? 'CH' : 'HM' : 'LM'}${yObj.code}`;
            const currentOddItem = _gameInfo && _gameInfo.oddsList && (_gameInfo.oddsList[keyCode]);
            const { midType, odds, enabled } = currentOddItem || {};
            if (enabled === 0) return;
            tempResult[keyCode] = {
              displayName: yObj?.displayName,
              amount: presetAmount || 0,
              midType,
              keyCode,
              odds,
            };
          }));
          return addItemToCart(tempResult, true);
        }
        resetItemInCart();
      } else {
        if (temp[kuaiJieSelectedGroupIndex].has(code)) {
          temp[kuaiJieSelectedGroupIndex].delete(code);
          setActive();
        } else {
          temp[kuaiJieSelectedGroupIndex].add(code);
          setActive('LP');
        }
        updateCurrentSelected(temp);
        if (temp[0].size > 0 && temp[1].size > 0) {
          [...temp[0]].forEach(x => [...temp[1]].forEach(y => {
            const keyCode = `${x}${y}`;
            const currentOddItem = _gameInfo && _gameInfo.oddsList && (_gameInfo.oddsList[keyCode]);
            const { midType, odds, enabled } = currentOddItem || {};
            if (enabled === 0) return;
            tempResult[keyCode] = {
              displayName: temp[kuaiJieSelectedGroupIndex].has(code) ? displayName : '',
              amount: presetAmount || 0,
              midType,
              keyCode,
              odds,
            };
          }));
          return addItemToCart(tempResult, true);
        }
        resetItemInCart();
      }
    } else if (selectedSubType === 'ZhiXuan' || selectedSubType === 'EZDW' || selectedSubType === 'SZDW' || selectedSubType === 'FSZH') {
      const tempResult = {};
      const currentOddItem = _gameInfo && _gameInfo.oddsList && (_gameInfo.oddsList[sectionSelection.code]);
      const { midType, odds, enabled, keyCode } = currentOddItem || {};
      if (enabled === 0) return;
      const tempSelected = [].concat(currentSelected || []) || [];
      const temp = tempSelected[kuaiJieSelectedGroupIndex];
      if (temp.has(displayName)) {
        temp.delete(displayName);
        setActive();
      } else {
        temp.add(displayName);
        setActive('LP');
      }
      tempSelected[kuaiJieSelectedGroupIndex] = temp;
      updateCurrentSelected(tempSelected);
      if (sectionSelection?.numberOfSelectionBlock === 2) {
        if (tempSelected[0].size > 0 && tempSelected[1].size > 0) {
          tempSelected[0].forEach(x => {
            tempSelected[1].forEach(y => {
              const tempDisplayName = `${x},${y}`;
              tempResult[tempDisplayName] = {
                categoryName: midType,
                displayName: tempDisplayName,
                odds,
                midType,
                keyCode,
                amount: parseInt(presetAmount, 10) || 0,
              };
            });
          });
          return addItemToCart(tempResult, true);
        }
        return resetItemInCart();
      }
      if (sectionSelection?.numberOfSelectionBlock === 3) {
        const len0 = tempSelected[0].size;
        const len1 = tempSelected[1].size;
        const len2 = tempSelected[2].size;
        const _sum = len0 + len1 + len2;

        if (len0 > 0 && len1 > 0 && len2 > 0 && (selectedSubType === 'FSZH' ? (_sum >= sectionSelection.minimumSelection) : true)) {
          tempSelected[0].forEach(x => {
            tempSelected[1].forEach(y => {
              tempSelected[2].forEach(z => {
                const tempDisplayName = `${x},${y},${z}`;
                tempResult[tempDisplayName] = {
                  categoryName: midType,
                  displayName: tempDisplayName,
                  odds,
                  midType,
                  keyCode,
                  amount: parseInt(presetAmount, 10) || 0,
                };
              });
            });
          });
          return addItemToCart(tempResult, true);
        }
        return resetItemInCart();
      }
    } else if (selectedSubType === 'ZX3' || selectedSubType === 'ZX6') {
      let tempCurrentSelected = [...currentSelected];
      if (tempCurrentSelected.includes(displayName)) {
        tempCurrentSelected = tempCurrentSelected.filter(x => x !== displayName);
        setActive();
      } else {
        tempCurrentSelected.push(displayName);
        setActive('LP');
      }
      updateCurrentSelected(tempCurrentSelected);
      if (tempCurrentSelected.length >= sectionSelection.minimumSelection) {
        const keyCode = `${sectionSelection.code}${tempCurrentSelected.length}`;
        const currentOddItem = _gameInfo.oddsList[keyCode];
        const { midType, odds, enabled } = currentOddItem || {};
        if (enabled === 0) return;
        return addItemToCart({
          [keyCode]: {
            displayName: [].concat(tempCurrentSelected).join(),
            amount: presetAmount || 0,
            midType,
            keyCode,
            odds,
          }
        }, true);
      }
      return resetItemInCart();
    } else if (sectionSelection && sectionSelection.code !== 'TMA' && sectionSelection.code !== 'TMB' && selectedSubType !== 'ZhiXuan' && selectedSubType !== 'EZHS' && selectedSubType !== 'SZHS') {
      let currentOddItem;
      if (selectedSubType === 'HX' || selectedSubType === 'LIUXIAO' || selectedSubType === 'TeMaBaoSan' || selectedSubType === 'LianMa') {
        currentOddItem = _gameInfo && _gameInfo.oddsList && (_gameInfo.oddsList[sectionSelection.code]);
      }
      if (selectedSubType === 'ZXBZ' || selectedSubType === 'LX' || selectedSubType === 'LM' || selectedSubType === 'LW' || selectedSubType === 'BZ') {
        if (selectedSubType === 'LX') {
          const _code = sectionSelection.code + (((sectionSelection.code.slice(1, 2) - 2) * 12) + Number(code));
          currentOddItem = _gameInfo.oddsList[_code];
        } else if (selectedSubType === 'LW') {
          const _code = sectionSelection.code + (((sectionSelection.code.slice(1, 2) - 2) * 10) + Number(code) + (sectionSelection.code.includes('BZ') ? 30 : 0));
          currentOddItem = _gameInfo.oddsList[_code];
        } else {
          currentOddItem = _gameInfo.oddsList[`${sectionSelection.code}${code}`];
        }
      }

      const { midType, odds, enabled, keyCode } = currentOddItem || {};
      const tempDan = [].concat(selectedDan || []) || [];
      const tempSelected = [].concat(currentSelected || []) || [];
      const isDan = betTypeSelection && betTypeSelection.value === 'DT';
      const itemBet = {
        odds,
        enabled,
        midType,
        displayName,
        keyCode,
      };
      if (isDan) {
        if (selectedSubType === 'HX' || selectedSubType === 'LIUXIAO' || selectedSubType === 'TeMaBaoSan' || selectedSubType === 'LianMa') {
          itemBet.keyCode = sectionSelection.code;
        }
        if (selectedSubType === 'ZXBZ' || selectedSubType === 'LX' || selectedSubType === 'LM' || selectedSubType === 'LW' || selectedSubType === 'BZ') {
          if (selectedSubType === 'LX') {
            itemBet.keyCode = sectionSelection.code + (((sectionSelection.code.slice(1, 2) - 2) * 12) + Number(code));
          } else if (selectedSubType === 'LW') {
            itemBet.keyCode = sectionSelection.code + (((sectionSelection.code.slice(1, 2) - 2) * 10) + Number(code) + (sectionSelection.code.includes('BZ') ? 30 : 0));
          } else {
            itemBet.keyCode = sectionSelection.code + Number(code);
          }
        }

        if ((_active !== 'LP' && tempDan.length < Number(betTypeSelection.selectedDan)) || ((_active === 'DT') && (tempDan.length === Number(betTypeSelection.selectedDan)))) {
          const index = [].concat(tempDan).findIndex(x => (x.displayName + sectionSelection.code) === (displayName + sectionSelection.code));
          if (index > -1) {
            tempDan.splice(index, 1);
            setActive();
          } else {
            tempDan.push(itemBet);
            setActive('DT');
          }
        } else {
          const index = [].concat(tempSelected).findIndex(x => (x.displayName + sectionSelection.code) === (displayName + sectionSelection.code));
          if (index > -1) {
            tempSelected.splice(index, 1);
            setActive();
          } else {
            tempSelected.push(itemBet);
            setActive('LP');
          }
        }

        if (tempDan && tempDan.length >= Number(betTypeSelection.selectedDan) && (tempSelected.length + tempDan.length >= sectionSelection.minimumSelection)) {
          const permutationLength = sectionSelection.minimumSelection - tempDan.length;
          const result = handlePermutation(tempSelected, permutationLength, tempDan, presetAmount);
          addItemToCart(result, true);
        } else {
          resetItemInCart();
        }
        updateSelectedDan(tempDan);
        return updateCurrentSelected(tempSelected);
      }

      if (selectedSubType === 'HX' || selectedSubType === 'LIUXIAO' || selectedSubType === 'TeMaBaoSan' || selectedSubType === 'LianMa') {
        itemBet.keyCode = sectionSelection.code;
      }
      if (selectedSubType === 'ZXBZ' || selectedSubType === 'LX' || selectedSubType === 'LM' || selectedSubType === 'LW' || selectedSubType === 'BZ') {
        if (selectedSubType === 'LX') {
          itemBet.keyCode = sectionSelection.code + (((sectionSelection.code.slice(1, 2) - 2) * 12) + Number(code));
        } else if (selectedSubType === 'LW') {
          itemBet.keyCode = sectionSelection.code + (((sectionSelection.code.slice(1, 2) - 2) * 10) + Number(code) + (sectionSelection.code.includes('BZ') ? 30 : 0));
        } else {
          itemBet.keyCode = `${sectionSelection.code}${code}`;
        }
      }
      if ((tempSelected.length < sectionSelection.maximumSelection)) {
        const index = [].concat(tempSelected).findIndex(x => (x.displayName + sectionSelection.code) === (displayName + sectionSelection.code));
        if (index > -1) {
          tempSelected.splice(index, 1);
          setActive();
        } else {
          tempSelected.push(itemBet);
          setActive('LP');
        }
      }

      if ((tempSelected.length >= sectionSelection.minimumSelection)) {
        const result = handlePermutation(tempSelected, sectionSelection.minimumSelection, [], presetAmount);
        addItemToCart(result, true);
      } else {
        resetItemInCart();
      }
      return updateCurrentSelected(tempSelected);
    } else if (selectedSubType === 'EZHS' || selectedSubType === 'SZHS') {
      const keyCode = `${sectionSelection.code}${code}`;
      const currentOddItem = _gameInfo.oddsList[keyCode];
      const { midType, odds, enabled } = currentOddItem || {};
      if (enabled === 0) return;
      if (cart && cart[keyCode]) {
        removeItemInCart(keyCode);
        setActive();
      } else {
        setActive('LP');
        return addItemToCart({
          [keyCode]: {
            displayName,
            amount: presetAmount || 0,
            midType,
            keyCode,
            odds,
          }
        });
      }
    } else {
      const keyCode = code;
      const currentOddItem = _gameInfo && _gameInfo.oddsList && (_gameInfo.oddsList[keyCode]);
      const { midType, odds, enabled } = currentOddItem || {};
      if (enabled === 0) return;
      if (cart && cart[keyCode]) {
        removeItemInCart(keyCode);
        setActive();
      } else {
        setActive('LP');
        return addItemToCart({
          [keyCode]: {
            displayName,
            amount: presetAmount || 0,
            midType,
            keyCode,
            odds,
          }
        });
      }
    }
  };

  return (
    <div className={`optionWrapper ${itemWrapperStyle} ${_active ? 'selected' : ''}`} onClick={() => { if (_oddStatus > 0 && !clickDisable) return onItemClick(); }}>
      {!image && (
      <div>
        <span className={drawImgClassName || ''}>{displayName}</span>
      </div>
      )}
      {image
      && (
      <div className="d-flex">
        {
          [].concat(image).map(item => <img src={item} alt="" />)
        }
      </div>
      )}
      {showOdds && (<div className="oddsStyle">{_oddStatus}</div>)}
      {generateNumber && (
      <div className={generateNumberWrapperStyle}>
        {generateNumber({
          displayName, preZodiac: companyInfo.company.zodiac, currentZodiac: displayName, zodiacTotalBall: 49
        }).map(item => <span className={item.className}>{item.value}</span>)}
      </div>
      )}
      {
        _active === 'DT' && (
        <span className="selectedIconDan">
          胆
        </span>
        )
      }
      {
        _active && (
        <span className="selectedIcon">
          <IoCheckmarkOutline color="white" />
        </span>
        )
      }
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionItem);