import { StatusCodes } from 'http-status-codes';

import { SET_ACTIVE_OPTION, SET_MODAL_ALERT, SET_PRESET_DEPOSIT_AMOUNT, SET_SECURITY_INFO, SET_TEMP_GIFT_INFO, SET_TEMP_PERSONAL_INFOMATION, SET_TRANSACTION_DETAILS, SET_TRANSACTION_RECORDS } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';
import withdrawActions from '../withdrawActions';

const setSecurityInfo = securityInfoData => ({
  type: SET_SECURITY_INFO,
  value: securityInfoData,
});

const setTransactionRecords = companyInfo => ({
  type: SET_TRANSACTION_RECORDS,
  value: companyInfo,
});

const setModalAlert = modal => ({
  type: SET_MODAL_ALERT,
  value: modal
});

const setTempGiftInfo = tempGiftInfo => ({
  type: SET_TEMP_GIFT_INFO,
  value: tempGiftInfo
});

const setTempPersonalInformation = tempPersonalInformation => ({
  type: SET_TEMP_PERSONAL_INFOMATION,
  value: tempPersonalInformation
});

const setPresetDepositAmount = amounts => ({
  type: SET_PRESET_DEPOSIT_AMOUNT,
  value: amounts
});

const setTransactionDetails = transactionDetails => ({
  type: SET_TRANSACTION_DETAILS,
  value: transactionDetails
});

const getSecurityInfo = () => dispatch => {
  apiService.get('/cash/GetSecurityCentre').then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return dispatch(setSecurityInfo(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const getTransactionRecords = (actionType, actionStatus, beginTime, endTime, orderId = '', page = 1, rows = 15) => dispatch => {
  apiService.get('/cash/allTransfers/', `actionType=${actionType}&actionStatus=${actionStatus}&beginTime=${beginTime}&endTime=${endTime}&orderId=${orderId}&page=${page}&rows=${rows}`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(setTransactionRecords(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const saveMemberInfo = (familyName, dateOfBirth, gender, callback = () => {}) => dispatch => {
  apiService.post('/cash/saveMemberInfo', {
    familyName,
    dateOfBirth,
    gender,
  }).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(getSecurityInfo());
      dispatch(updateTempPersonalInformation());
      return dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
          onConfirmationClick: () => callback()
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const savePhoneNumber = phone => dispatch => { // Bind Phone  Company.SafePhoneEnable = 1
  apiService.post('/cash/SavePhoneNumber', phone).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(getSecurityInfo());
      return dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const sendBindPhoneCode = phoneNum => dispatch => { // Bind Phone  Company.SafePhoneEnable = 0
  apiService.post('/cash/sendBindPhoneCode',
    phoneNum).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'error',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const checkBindPhoneCode = (verifCode, successCallBack = () => {}) => dispatch => { // Bind Phone  Company.SafePhoneEnable = 0
  apiService.post('/cash/checkBindPhoneCode',
    JSON.stringify(verifCode)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(getSecurityInfo());
      return dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
          onConfirmationClick: () => successCallBack()
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const sendPhoneVerificationCode = successCallBack => dispatch => { // unBind Phone  Company.SafePhoneEnable = 0
  apiService.get('/cash/sendPhoneVerificationCode').then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      if (successCallBack) successCallBack();
      dispatch(getSecurityInfo());
      return dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
    return dispatch(setModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg,
        showCancelButton: false
      }
    }));
  });
};

const checkPhoneRemoveCode = (safeCode, successCallBack = () => {}) => dispatch => { // unBind Phone  Company.SafePhoneEnable = 0
  apiService.post('/cash/CheckPhoneRemoveCode',
    safeCode).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(getSecurityInfo());
      return dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
          onConfirmationClick: () => successCallBack()
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
    return dispatch(setModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg,
        showCancelButton: false
      }
    }));
  });
};

const setWithdrawPW = (action, oldSafePass, newSafePass, confirmNewPass, verifyCode, successCallBack = () => {}) => dispatch => {
  apiService.post('/cash/setWithdrawPW', {
    action,
    oldSafePass,
    newSafePass,
    confirmNewPass,
    verifyCode,
  }).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(getSecurityInfo());
      return dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
          onConfirmationClick: () => successCallBack()
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const saveMemGiftAddress = (address, address2, receiverName, phone, successCallBack) => dispatch => {
  apiService.post('/cash/SaveMemGiftAddress', {
    address,
    address2,
    receiverName,
    phone,
  }).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      if (successCallBack) successCallBack();
      dispatch(updateTempGiftInfo(undefined, undefined, undefined, undefined));
      dispatch(getSecurityInfo());
      return dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const getBankCardDetail = bankId => dispatch => {
  apiService.get(`/cash/BankCard/${bankId}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(setModalAlert({
        visible: true,
        type: 'mobileBankCardDetail',
        data: data.result
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const deleteBankCard = bankId => dispatch => {
  apiService.post('/cash/deleteBankCard/', JSON.stringify(bankId)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(getSecurityInfo());
      dispatch(withdrawActions.getInfo());
      dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const usePromoCode = proCode => dispatch => {
  apiService.post('/cash/UsePromoCode', JSON.stringify(proCode)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(setModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
      }
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'error',
        data: {
          message: data.msg
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};


const saveBankCard = (action, cardNumber, bankCode, bankName, bankAddress, bankType, withdrawPW, successCallBack = () => {}) => dispatch => {
  apiService.post('/cash/saveBankCard', {
    action,
    cardNumber,
    bankCode,
    bankName,
    bankAddress,
    bankType,
    withdrawPW
  }).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(getSecurityInfo());
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
          onConfirmationClick: () => successCallBack()
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const sendForgotPWCode = (loginId, phoneNumber, verifyCode, successCallBack) => dispatch => {
  apiService.post('/cash/sendForgotPWCode', {
    loginId,
    phoneNumber,
    verifyCode,
  }).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (successCallBack) successCallBack();
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const checkForgotPWCode = (loginId, phoneNumber, verifyCode, successCallBack) => dispatch => {
  apiService.post('/cash/checkForgotPWCode', {
    loginId,
    phoneNumber,
    verifyCode
  }).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (successCallBack) successCallBack();
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const setNewForgotPW = (newPassword, cfmPassword, successCallBack) => dispatch => {
  apiService.post('/cash/setNewForgotPW', {
    newPassword,
    cfmPassword
  }).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (successCallBack) successCallBack();
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const getTransactionDetails = (transferID = '') => dispatch => {
  apiService.get(`/cash/TransferDetail/${transferID}`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(setTransactionDetails(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const updateTempGiftInfo = (giftReceiver, giftAddress1, giftAddress2, giftPhone) => dispatch => {
  dispatch(setTempGiftInfo({
    giftReceiver,
    giftAddress1,
    giftAddress2,
    giftPhone,
  }));
};

const updateTempPersonalInformation = (familyName, dateOfBirth, gender) => dispatch => {
  dispatch(setTempPersonalInformation({
    familyName,
    dateOfBirth,
    gender,
  }));
};

const updatePresetDepositAmount = amounts => dispatch => (
  dispatch(setPresetDepositAmount(amounts))
);

const clearTransactionDetail = () => dispatch => {
  dispatch(setTransactionDetails(undefined));
  dispatch(setTransactionRecords(undefined));
};

const updateActiveOption = value => ({
  type: SET_ACTIVE_OPTION,
  value
});

const fetchIsExistLogin = (account, setSuccessMessage, setErrorMessage, setSubmitting) => {
  apiService.get(`/cash/IsExistsLoginId/${account}`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      setSuccessMessage(data.msg);
      setErrorMessage('');
      setSubmitting(true);
    }
  }).catch(error => {
    if (error.response) {
      setErrorMessage(error.response.data.msg);
    } else {
      setErrorMessage(error.message);
    }
    setSubmitting(false);
    setSuccessMessage('');
  });
};

export default {
  getSecurityInfo,
  saveMemberInfo,
  savePhoneNumber,
  sendBindPhoneCode,
  checkBindPhoneCode,
  sendPhoneVerificationCode,
  checkPhoneRemoveCode,
  setWithdrawPW,
  saveMemGiftAddress,
  deleteBankCard,
  getTransactionRecords,
  getBankCardDetail,
  usePromoCode,
  saveBankCard,
  sendForgotPWCode,
  checkForgotPWCode,
  setNewForgotPW,
  updateTempGiftInfo,
  updateTempPersonalInformation,
  updatePresetDepositAmount,
  getTransactionDetails,
  clearTransactionDetail,
  updateActiveOption,
  fetchIsExistLogin
};