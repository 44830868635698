import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BetsItems from '../BetsItems';
import './styles.scss';

const mapStateToProps = state => ({
  gameInfo: state.placeBetReducers.gameInfo,
  presetAmount: state.gameReducers.presetAmount,
  cart: state.cartReducers.cart,
});

function MultipleTypeBetsPanel({ list, cart }) {
  const { t } = useTranslation();
  const [_rowTotalAmount, setRowTotalAmount] = useState(0);
  useEffect(() => {
    let amount = 0;
    if (cart && Object.keys(cart).length) {
      Object.values(cart).forEach(item => {
        amount += list.find(x => x.displayName === item.midType) ? item.amount : 0;
      });
    }
    setRowTotalAmount(amount);
  }, [cart]);

  return (
    <div className="multipleTypeBetsPanelWrapper w-100 d-flex my-2">
      {
      list.map((item, index) => <BetsItems key={`sub_items_${index.toString()}`} {...item} />)
      }
      <div>
        <div className="h-50">{t('TOTAL_AMOUNT')}</div>
        <div className="h-50">{_rowTotalAmount}</div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(MultipleTypeBetsPanel);