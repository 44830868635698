import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';
import TeMaAndLianMa from '../../../../components/Mobile/ComponentType/TeMaAndLianMa';

const EZHS = [
  {
    componentType: TeMaAndLianMa,
    data: {
      list: [
        {
          displayName: '万千',
          numberOfSelectionBlock: 2,
          code: 'HS2WQ',
          minimumSelection: 2,
          maximumSelection: 20,
          children: [{
            code: 'HS2WQ'
          }]
        },
        {
          displayName: '万佰',
          numberOfSelectionBlock: 2,
          code: 'HS2WB',
          minimumSelection: 3,
          maximumSelection: 20,
          children: [{
            code: 'HS2WB'
          }]
        },
        {
          displayName: '万拾',
          numberOfSelectionBlock: 2,
          code: 'HS2WS',
          minimumSelection: 2,
          maximumSelection: 20,
          children: [{
            code: 'HS2WS'
          }]
        },
        {
          displayName: '万个',
          numberOfSelectionBlock: 2,
          code: 'HS2WG',
          minimumSelection: 3,
          maximumSelection: 20,
          children: [{
            code: 'HS2WG'
          }]
        },
        {
          displayName: '千佰',
          numberOfSelectionBlock: 2,
          code: 'HS2QB',
          minimumSelection: 2,
          maximumSelection: 20,
          children: [{
            code: 'HS2QB'
          }]
        },
        {
          displayName: '千拾',
          numberOfSelectionBlock: 2,
          code: 'HS2QS',
          minimumSelection: 3,
          maximumSelection: 20,
          children: [{
            code: 'HS2QS'
          }]
        }, {
          displayName: '千个',
          numberOfSelectionBlock: 2,
          code: 'HS2QG',
          minimumSelection: 2,
          maximumSelection: 20,
          children: [{
            code: 'HS2QG'
          }]
        },
        {
          displayName: '佰拾',
          numberOfSelectionBlock: 2,
          code: 'HS2BS',
          minimumSelection: 3,
          maximumSelection: 20,
          children: [{
            code: 'HS2BS'
          }]
        }, {
          displayName: '佰个',
          numberOfSelectionBlock: 2,
          code: 'HS2BG',
          minimumSelection: 2,
          maximumSelection: 20,
          children: [{
            code: 'HS2BG'
          }]
        },
        {
          displayName: '拾个',
          numberOfSelectionBlock: 2,
          code: 'HS2SG',
          minimumSelection: 3,
          maximumSelection: 20,
          children: [{
            code: 'HS2SG'
          }]
        },
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '万千和数',
    headerStyle: '',
    selectionBlockIndex: 0,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      kuaiJieSelectedGroupIndex: 0,
      list: [
        {
          code: '_4',
          displayName: '0~4',
        }, {
          code: '_5',
          displayName: '5',
        }, {
          code: '_6',
          displayName: '6',
        }, {
          code: '_7',
          displayName: '7',
        }, {
          code: '_8',
          displayName: '8',
        }, {
          code: '_9',
          displayName: '9',
        }, {
          code: '_10',
          displayName: '10',
        }, {
          code: '_11',
          displayName: '11',
        }, {
          code: '_12',
          displayName: '12',
        }, {
          code: '_13',
          displayName: '13',
        }, {
          code: '_14',
          displayName: '14~18',
        }
      ]
    }
  }, {
    componentType: CollapseType,
    header: '万千和数尾数',
    headerStyle: '',
    selectionBlockIndex: 1,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      kuaiJieSelectedGroupIndex: 1,
      list: [
        {
          code: 'W_0',
          displayName: '0',
        }, {
          code: 'W_1',
          displayName: '1',
        }, {
          code: 'W_2',
          displayName: '2',
        }, {
          code: 'W_3',
          displayName: '3',
        }, {
          code: 'W_4',
          displayName: '4',
        }, {
          code: 'W_5',
          displayName: '5',
        }, {
          code: 'W_6',
          displayName: '6',
        }, {
          code: 'W_7',
          displayName: '7',
        }, {
          code: 'W_8',
          displayName: '8',
        }, {
          code: 'W_9',
          displayName: '9',
        }
      ]
    }
  }
];

export default EZHS;