
import OptionItem from '../../../../components/Mobile/OptionItem';

import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';

import PengAndDan from '../../../../components/Mobile/ComponentType/PengAndDan';

import TeMaAndLianMa from '../../../../components/Mobile/ComponentType/TeMaAndLianMa';

const BZ = [
  {
    componentType: TeMaAndLianMa,
    data: {
      list: [
        {
          code: 'BZ3BZ',
          displayName: '3不中',
          minimumSelection: 3,
          maximumSelection: 9,
          className: 'cellWrapper',
          danList: ['1', '2'],
        },
        {
          code: 'BZ4BZ',
          displayName: '4不中',
          minimumSelection: 4,
          maximumSelection: 9,
          className: 'cellWrapper',
          danList: ['1', '2', '3'],
        }, {
          code: 'BZ5BZ',
          displayName: '5不中',
          minimumSelection: 5,
          maximumSelection: 9,
          className: 'cellWrapper',
          danList: ['1', '2', '3', '4'],
        }, {
          code: 'BZ6BZ',
          displayName: '6不中',
          minimumSelection: 6,
          maximumSelection: 10,
          className: 'cellWrapper',
          danList: ['1', '2', '3', '4', '5'],
        }, {
          code: 'BZ7BZ',
          displayName: '7不中',
          minimumSelection: 7,
          maximumSelection: 11,
          className: 'cellWrapper',
          danList: ['1', '2', '3', '4', '5', '6'],
        }, {
          code: 'BZ8BZ',
          displayName: '8不中',
          minimumSelection: 8,
          maximumSelection: 11,
          className: 'cellWrapper',
          danList: ['1', '2', '3', '4', '5', '6', '7'],
        }, {
          code: 'BZ9BZ',
          displayName: '9不中',
          minimumSelection: 9,
          maximumSelection: 12,
          className: 'cellWrapper',
          danList: ['1', '2', '3', '4', '5', '6', '7', '8'],
        }, {
          code: 'BZ10BZ',
          displayName: '10不中',
          minimumSelection: 10,
          maximumSelection: 13,
          className: 'cellWrapper',
          danList: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
        }, {
          code: 'BZ11BZ',
          displayName: '11不中',
          minimumSelection: 11,
          maximumSelection: 14,
          className: 'cellWrapper',
          danList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        }, {
          code: 'BZ12BZ',
          displayName: '12不中',
          minimumSelection: 12,
          maximumSelection: 15,
          className: 'cellWrapper',
          danList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
        }, {
          code: 'BZ13BZ',
          displayName: '13不中',
          minimumSelection: 13,
          maximumSelection: 16,
          className: 'cellWrapper',
          danList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        }
      ]
    }
  },
  {
    componentType: PengAndDan,
    data: {}
  },
  {
    componentType: CollapseType,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper HK6TeXiao',
      list: [
        {
          displayName: '01',
          drawImgClassName: 'circle circleRed',
          code: 1
        },
        {
          displayName: '02',
          drawImgClassName: 'circle circleRed',
          code: 2
        },
        {
          displayName: '03',
          drawImgClassName: 'circle circleBlue',
          code: 3
        },
        {
          displayName: '04',
          drawImgClassName: 'circle circleBlue',
          code: 4
        },
        {
          displayName: '05',
          drawImgClassName: 'circle circleGreen',
          code: 5
        },
        {
          displayName: '06',
          drawImgClassName: 'circle circleGreen',
          code: 6
        },
        {
          displayName: '07',
          drawImgClassName: 'circle circleRed',
          code: 7
        },
        {
          displayName: '08',
          drawImgClassName: 'circle circleRed',
          code: 8
        },
        {
          displayName: '09',
          drawImgClassName: 'circle circleBlue',
          code: 9
        },
        {
          displayName: '10',
          drawImgClassName: 'circle circleBlue',
          code: 10
        },
        {
          displayName: '11',
          drawImgClassName: 'circle circleGreen',
          code: 11
        },
        {
          displayName: '12',
          drawImgClassName: 'circle circleRed',
          code: 12
        },
        {
          displayName: '13',
          drawImgClassName: 'circle circleRed',
          code: 13
        },
        {
          displayName: '14',
          drawImgClassName: 'circle circleBlue',
          code: 14
        },
        {
          displayName: '15',
          drawImgClassName: 'circle circleBlue',
          code: 15
        },
        {
          displayName: '16',
          drawImgClassName: 'circle circleGreen',
          code: 16
        },
        {
          displayName: '17',
          drawImgClassName: 'circle circleGreen',
          code: 17
        },
        {
          displayName: '18',
          drawImgClassName: 'circle circleRed',
          code: 18
        },
        {
          displayName: '19',
          drawImgClassName: 'circle circleRed',
          code: 19
        },
        {
          displayName: '20',
          drawImgClassName: 'circle circleBlue',
          code: 20
        },
        {
          displayName: '21',
          drawImgClassName: 'circle circleGreen',
          code: 21
        },
        {
          displayName: '22',
          drawImgClassName: 'circle circleGreen',
          code: 22
        },
        {
          displayName: '23',
          drawImgClassName: 'circle circleRed',
          code: 23
        },
        {
          displayName: '24',
          drawImgClassName: 'circle circleRed',
          code: 24
        },
        {
          displayName: '25',
          drawImgClassName: 'circle circleBlue',
          code: 25
        },
        {
          displayName: '26',
          drawImgClassName: 'circle circleBlue',
          code: 26
        },
        {
          displayName: '27',
          drawImgClassName: 'circle circleGreen',
          code: 27
        },
        {
          displayName: '28',
          drawImgClassName: 'circle circleGreen',
          code: 28
        },
        {
          displayName: '29',
          drawImgClassName: 'circle circleRed',
          code: 29
        },
        {
          displayName: '30',
          drawImgClassName: 'circle circleRed',
          code: 30
        },
        {
          displayName: '31',
          drawImgClassName: 'circle circleBlue',
          code: 31
        }
      ]
    }
  }
];

export default BZ;