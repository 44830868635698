import { SET_ACTIVE_OPTION, SET_PRESET_DEPOSIT_AMOUNT, SET_SECURITY_INFO, SET_TEMP_GIFT_INFO, SET_TEMP_PERSONAL_INFOMATION, SET_TRANSACTION_DETAILS, SET_TRANSACTION_RECORDS, SET_TREND_PREFERENCE } from '../../actions/actionTypes';

const initialState = {
  securityData: undefined,
  transactionRecords: undefined,
  tempGiftInfo: undefined,
  tempPersonalInformation: {},
  presetDepositAmounts: [{
    name: '¥100',
    value: 100
  }, {
    name: '¥200',
    value: 200
  }, {
    name: '¥500',
    value: 500
  }, {
    name: '¥1000',
    value: 1000
  }, {
    name: '¥2000',
    value: 2000
  }, {
    name: '¥5000',
    value: 5000
  }],
  transactionDetails: undefined,
  activeOption: 'settings'
};

const memberReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_SECURITY_INFO:
      return {
        ...state, securityData: action.value
      };
    case SET_ACTIVE_OPTION:
      return {
        ...state, activeOption: action.value
      };
    case SET_TRANSACTION_RECORDS:
      return {
        ...state, transactionRecords: action.value
      };
    case SET_TREND_PREFERENCE:
      const newSecurityData = state.securityData;
      newSecurityData.trendPreference = action.value.trendPreference;
      return {
        ...state, securityData: newSecurityData
      };
    case SET_TEMP_GIFT_INFO:
      return {
        ...state, tempGiftInfo: action.value
      };
    case SET_TEMP_PERSONAL_INFOMATION:
      return {
        ...state, tempPersonalInformation: action.value
      };
    case SET_PRESET_DEPOSIT_AMOUNT:
      return {
        ...state, presetDepositAmounts: action.value
      };
    case SET_TRANSACTION_DETAILS:
      return {
        ...state, transactionDetails: action.value
      };
    default:
      return state;
  }
};

export default memberReducers;