import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const ZH = [
  {
    componentType: CollapseType,
    header: '总和、总和过关',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'BJK8ItemWrapper',
      list: [
        {
          code: 'LM1', // keyCode
          displayName: '总和大',
        }, {
          code: 'LM2',
          displayName: '总和小',
        }, {
          code: 'LM3',
          displayName: '总和单',
        }, {
          code: 'LM4',
          displayName: '总和双',
        }, {
          code: 'ZHH5',
          displayName: '总和810',
        }, {
          code: 'ZHGG6',
          displayName: '总和大单',
        }, {
          code: 'ZHGG7',
          displayName: '总和大双',
        }, {
          code: 'ZHGG8',
          displayName: '总和小单',
        }, {
          code: 'ZHGG9',
          displayName: '总和小双',
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '前后和',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'BJK8ItemWrapper',
      list: [
        {
          code: 'QHH10', // keyCode
          displayName: '前(多)',
        }, {
          code: 'QHH11',
          displayName: '后(多)',
        }, {
          code: 'QHH12',
          displayName: '前后(和)',
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '单双和',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'BJK8ItemWrapper',
      list: [
        {
          code: 'DSH13', // keyCode
          displayName: '单(多)',
        }, {
          code: 'DSH14',
          displayName: '双(多)',
        }, {
          code: 'DSH15',
          displayName: '单双(和)',
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '五行',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'BJK8ItemWrapper',
      list: [
        {
          code: 'WX16', // keyCode
          displayName: '金',
        }, {
          code: 'WX17',
          displayName: '木',
        }, {
          code: 'WX18',
          displayName: '水',
        }, {
          code: 'WX19',
          displayName: '火',
        }, {
          code: 'WX20',
          displayName: '土',
        }
      ]
    }
  }
];

export default ZH;