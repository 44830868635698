import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const ZONGXIAO = [
  {
    componentType: CollapseType,
    header: '总肖',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper PK10ItemWrapperGY',
      list: [
        {
          code: 'ZX25', // keyCode
          displayName: '2肖',
        }, {
          code: 'ZX27',
          displayName: '4肖',
        }, {
          code: 'ZX29',
          displayName: '6肖',
        }, {
          code: 'ZXDS31',
          displayName: '总肖单',
        }, {
          code: 'ZX26',
          displayName: '3肖',
        }, {
          code: 'ZX28',
          displayName: '5肖',
        }, {
          code: 'ZX30',
          displayName: '7肖',
        }, {
          code: 'ZXDS32',
          displayName: '总肖双',
        }
      ]
    }
  }
];

export default ZONGXIAO;