const PCDD = {
  lotterySpecific: gameData => {
    const { lotteryBeginTimeBase, lotteryEndTimeBase, lotteryName, code } = gameData || {};
    const endTimeString = lotteryEndTimeBase ? `至${lotteryEndTimeBase}` : '';
    switch (code) {
      default:
        return `以下所有投注皆含本金\n ${lotteryName}开奖结果来源于国家福利彩票北京快乐8官网开奖号码，从早上${lotteryBeginTimeBase}${endTimeString}，每5分钟一期不停开奖。${lotteryName}每期开奖共开出20个数字，幸运28将这20个开奖号码按照由小到大的顺序依次排列；取其1-6位开奖号码相加，和值的末位数作为幸运28开奖第一个数值； 取其7-12位开奖号码相加，和值的末位数作为幸运28开奖第二个数值，取其13-18位开奖号码相加，和值的末位数作为幸运 28开奖第三个数值；三个数值相加即为幸运28最终的开奖结果。`;
    }
  },
  templateSpecific: () => ([{
    header: '1.大小玩法',
    body: [
      '数字14-27为大 数字0-13为小 出13押小回本金 出14押大回本本金。',
    ]
  }, {
    header: '2.单双玩法',
    body: [
      '数字1，3，5，~27为单 数字0，2，4~26为双 出13压单回本出14押双回本。',
    ]
  }, {
    header: '3.极值玩法',
    body: [
      '[极小0-5] 10倍 [极大22-27] 10倍 举例：买极小100元 开奖结果为0-5其中一个数字就中1000元（包括本金）极大反之。'
    ]
  }, {
    header: '4.组合玩法',
    body: [
      '数字14，16，~26为大双 数字0，2，4，~12为小双。',
      '数字15，17，~27为大单 数字1，3，5，~13为小单。',
      '开13，14组合回本，举例 买100元小单开13就回本金 买100元大双开14就回本金。',
    ]
  }, {
    header: '5.定位玩法(单点数字玩法)',
    body: [
      '从数字0-27中选取一个数字 中奖以对应赔率含本金奖励。',
    ]
  }, {
    header: '6.波色',
    body: [
      '绿波号码：1 , 4 , 7 , 10 , 16 , 19 , 22 , 25',
      '蓝波号码：2 , 5 , 8 , 11 , 17 , 20 , 23 , 26',
      '红波号码：3 , 6 , 9 , 12 , 15 , 18 , 21 , 24\n\n赔率 1 : 3',
      '开黄波下注色波任何一个都视为不中奖！'
    ]
  }, {
    header: '7.豹子',
    body: [
      '当期3个开奖号码一致则中奖,赔率 1 : 50 , 如当期开奖结果为 3+3+3=9 则中奖豹子。',
    ]
  }, {
    header: '8.特码包三',
    body: [
      '选取三个特码,三个特码中有任一号码和开奖结果总和一致即中奖,赔率 1 : 3.5。',
    ]
  }])
};

export default PCDD;