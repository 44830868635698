import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const LIANGMIAN = [
  {
    componentType: CollapseType,
    header: '冠、亚军和',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'GYJH18', // keyCode
          displayName: '冠亚大',
        }, {
          code: 'GYJH19',
          displayName: '冠亚小',
        }, {
          code: 'GYJH20',
          displayName: '冠亚单',
        }, {
          code: 'GYJH21',
          displayName: '冠亚双',
        }
      ],
    }
  },
  {
    componentType: CollapseType,
    header: '冠军',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'GJLM1', // keyCode
        displayName: '大',
      }, {
        code: 'GJLM2',
        displayName: '小',
      }, {
        code: 'GJLM3',
        displayName: '单',
      }, {
        code: 'GJLM4',
        displayName: '双',
      }, {
        code: 'GJLM5',
        displayName: '龙',
      }, {
        code: 'GJLM6',
        displayName: '虎',
      }],
    }
  },
  {
    componentType: CollapseType,
    header: '亚军',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'YJLM1', // keyCode
        displayName: '大',
      }, {
        code: 'YJLM2',
        displayName: '小',
      }, {
        code: 'YJLM3',
        displayName: '单',
      }, {
        code: 'YJLM4',
        displayName: '双',
      }, {
        code: 'YJLM5',
        displayName: '龙',
      }, {
        code: 'YJLM6',
        displayName: '虎',
      }],
    }
  },
  {
    componentType: CollapseType,
    header: '第三名',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'B3LM1', // keyCode
        displayName: '大',
      }, {
        code: 'B3LM2',
        displayName: '小',
      }, {
        code: 'B3LM3',
        displayName: '单',
      }, {
        code: 'B3LM4',
        displayName: '双',
      }, {
        code: 'B3LM5',
        displayName: '龙',
      }, {
        code: 'B3LM6',
        displayName: '虎',
      }],
    }
  },
  {
    componentType: CollapseType,
    header: '第四名',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'B4LM1', // keyCode
        displayName: '大',
      }, {
        code: 'B4LM2',
        displayName: '小',
      }, {
        code: 'B4LM3',
        displayName: '单',
      }, {
        code: 'B4LM4',
        displayName: '双',
      }, {
        code: 'B4LM5',
        displayName: '龙',
      }, {
        code: 'B4LM6',
        displayName: '虎',
      }],
    }
  },
  {
    componentType: CollapseType,
    header: '第五名',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'B5LM1', // keyCode
        displayName: '大',
      }, {
        code: 'B5LM2',
        displayName: '小',
      }, {
        code: 'B5LM3',
        displayName: '单',
      }, {
        code: 'B5LM4',
        displayName: '双',
      }, {
        code: 'B5LM5',
        displayName: '龙',
      }, {
        code: 'B5LM6',
        displayName: '虎',
      }],
    }
  },
  {
    componentType: CollapseType,
    header: '第六名',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'B6LM1', // keyCode
        displayName: '大',
      }, {
        code: 'B6LM2',
        displayName: '小',
      }, {
        code: 'B6LM3',
        displayName: '单',
      }, {
        code: 'B6LM4',
        displayName: '双',
      }],
    }
  },
  {
    componentType: CollapseType,
    header: '第七名',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'B7LM1', // keyCode
        displayName: '大',
      }, {
        code: 'B7LM2',
        displayName: '小',
      }, {
        code: 'B7LM3',
        displayName: '单',
      }, {
        code: 'B7LM4',
        displayName: '双',
      }],
    }
  },
  {
    componentType: CollapseType,
    header: '第八名',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'B8LM1', // keyCode
        displayName: '大',
      }, {
        code: 'B8LM2',
        displayName: '小',
      }, {
        code: 'B8LM3',
        displayName: '单',
      }, {
        code: 'B8LM4',
        displayName: '双',
      }],
    }
  },
  {
    componentType: CollapseType,
    header: '第九名',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'B9LM1', // keyCode
        displayName: '大',
      }, {
        code: 'B9LM2',
        displayName: '小',
      }, {
        code: 'B9LM3',
        displayName: '单',
      }, {
        code: 'B9LM4',
        displayName: '双',
      }],
    }
  },
  {
    componentType: CollapseType,
    header: '第十名',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'B10LM1', // keyCode
        displayName: '大',
      }, {
        code: 'B10LM2',
        displayName: '小',
      }, {
        code: 'B10LM3',
        displayName: '单',
      }, {
        code: 'B10LM4',
        displayName: '双',
      }],
    }
  },
];

export default LIANGMIAN;