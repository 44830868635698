import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';
import { renderHeXiaoBalls } from '../../../utils';

const TX = [
  {
    componentType: CollapseType,
    header: '特肖',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper HK6TeXiao',
      list: [
        {
          code: 'TMSX1', // keyCode
          displayName: '鼠',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'TMSX7',
          displayName: '马',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'TMSX2',
          displayName: '牛',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'TMSX8',
          displayName: '羊',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'TMSX3',
          displayName: '虎',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'TMSX9',
          displayName: '猴',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'TMSX4',
          displayName: '兔',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'TMSX10',
          displayName: '鸡',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'TMSX5',
          displayName: '龙',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'TMSX11',
          displayName: '狗',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'TMSX6',
          displayName: '蛇',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }, {
          code: 'TMSX12',
          displayName: '猪',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, obj.zodiacTotalBall),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper'
        }
      ]
    }
  }
];

export default TX;