import { SET_TOP_UP_OPTIONS } from '../../actions/actionTypes';

const initialState = {
  topUpOptions: undefined,
};

const topupReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOP_UP_OPTIONS:
      return {
        ...state, topUpOptions: action.value
      };

    default:
      return state;
  }
};

export default topupReducers;