import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const ZHENGMA = [
  {
    componentType: CollapseType,
    header: '特码',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'ZM1',
          displayName: '01',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZM2',
          displayName: '02',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZM3',
          displayName: '03',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZM4',
          displayName: '04',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZM5',
          displayName: '05',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZM6',
          displayName: '06',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZM7',
          displayName: '07',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZM8',
          displayName: '08',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZM9',
          displayName: '09',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZM10',
          displayName: '10',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZM11',
          displayName: '11',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZM12',
          displayName: '12',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZM13',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZM14',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZM15',
          displayName: '15',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZM16',
          displayName: '16',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZM17',
          displayName: '17',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZM18',
          displayName: '18',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZM19',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZM20',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZM21',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZM22',
          displayName: '22',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZM23',
          displayName: '23',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZM24',
          displayName: '24',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZM25',
          displayName: '25',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZM26',
          displayName: '26',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZM27',
          displayName: '27',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZM28',
          displayName: '28',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZM29',
          displayName: '29',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZM30',
          displayName: '30',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZM31',
          displayName: '31',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'ZM32',
          displayName: '32',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZM33',
          displayName: '33',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'ZM34',
          displayName: '34',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZM35',
          displayName: '35',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'ZM36',
          displayName: '36',
          drawImgClassName: 'circle circleBlue'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '总分两面',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper PK10ItemWrapperGY',
      list: [
        {
          code: 'ZFDX_D',
          displayName: '总分大',
        }, {
          code: 'ZFDX_X',
          displayName: '总分小',
        }, {
          code: 'ZFDS_D',
          displayName: '总分单',
        }, {
          code: 'ZFDS_S',
          displayName: '总分双',
        }
      ]
    }
  }
];

export default ZHENGMA;