import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const SB = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['生肖', '号码', '赔率', '金额'],
      mainColumnLength: 1,
      subColumnLength: 4,
      subColumnStyle: ['column-4items-label', 'column-4items-balls', 'column-4items-odds', 'column-4items-input'],
      betItemStyle: ['column-4items-label paddingLabel', 'column-4items-balls column-4items-array-balls', 'column-4items-odds', 'column-4items-input'],
      betItemList: ['DisplayText', 'HK6_SeBo_Balls', 'Odds', 'Input'],
      betItemColumn: 4,
      list: [
        {
          code: 'TMSB60', // keyCode
          displayName: '红波',
          className: 'hk6-sb-red-bg'
        }, {
          code: 'TMSB61',
          displayName: '蓝波',
          className: 'hk6-sb-blue-bg'
        }, {
          code: 'TMSB62',
          displayName: '绿波',
          className: 'hk6-sb-green-bg'
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['半波', '赔率', '金额'],
      mainColumnLength: 3,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'TBBDS2', // keyCode
          displayName: '红单',
          className: 'hk6-sb-red-text'
        }, {
          code: 'TBBDS11', // keyCode
          displayName: '蓝单',
          className: 'hk6-sb-blue-text'
        }, {
          code: 'TBBDS20', // keyCode
          displayName: '绿单',
          className: 'hk6-sb-green-text'
        }, {
          code: 'TBBDS3',
          displayName: '红双',
          className: 'hk6-sb-red-text'
        }, {
          code: 'TBBDS12',
          displayName: '蓝双',
          className: 'hk6-sb-blue-text'
        }, {
          code: 'TBBDS21',
          displayName: '绿双',
          className: 'hk6-sb-green-text'
        }, {
          code: 'TBBDX4',
          displayName: '红大',
          className: 'hk6-sb-red-text'
        }, {
          code: 'TBBDX13',
          displayName: '蓝大',
          className: 'hk6-sb-blue-text'
        }, {
          code: 'TBBDX22',
          displayName: '绿大',
          className: 'hk6-sb-green-text'
        }, {
          code: 'TBBDX5',
          displayName: '红小',
          className: 'hk6-sb-red-text'
        }, {
          code: 'TBBDX14',
          displayName: '蓝小',
          className: 'hk6-sb-blue-text'
        }, {
          code: 'TBBDX23',
          displayName: '绿小',
          className: 'hk6-sb-green-text'
        },
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['半波', '赔率', '金额'],
      mainColumnLength: 3,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'TMBBB6', // keyCode
          displayName: '红大单',
          className: 'hk6-sb-red-text'
        }, {
          code: 'TMBBB15', // keyCode
          displayName: '蓝大单',
          className: 'hk6-sb-blue-text'
        }, {
          code: 'TMBBB24', // keyCode
          displayName: '绿大单',
          className: 'hk6-sb-green-text'
        }, {
          code: 'TMBBB7',
          displayName: '红大双',
          className: 'hk6-sb-red-text'
        }, {
          code: 'TMBBB16',
          displayName: '蓝大双',
          className: 'hk6-sb-blue-text'
        }, {
          code: 'TMBBB25',
          displayName: '绿大双',
          className: 'hk6-sb-green-text'
        }, {
          code: 'TMBBB8',
          displayName: '红小单',
          className: 'hk6-sb-red-text'
        }, {
          code: 'TMBBB17',
          displayName: '蓝小单',
          className: 'hk6-sb-blue-text'
        }, {
          code: 'TMBBB26',
          displayName: '绿小单',
          className: 'hk6-sb-green-text'
        }, {
          code: 'TMBBB9',
          displayName: '红小双',
          className: 'hk6-sb-red-text'
        }, {
          code: 'TMBBB18',
          displayName: '蓝小双',
          className: 'hk6-sb-blue-text'
        }, {
          code: 'TMBBB27',
          displayName: '绿小双',
          className: 'hk6-sb-green-text'
        },
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default SB;