import React, { useEffect, useState } from 'react';

import { Row } from 'reactstrap';
import { Select } from 'element-react';
import { connect } from 'react-redux';

import Label from '../../Label';
import './styles.scss';

const mapStateToProps = state => ({
  lengReResult: state.resultHistoryReducers.lengReResult,
});

const ProbabilityResultPanel = ({ lengReResult, dropdownList, result, sideBetNavList }) => {
  const [_result, setResult] = useState();
  const [_dropdownList, setDropdownList] = useState(dropdownList);
  const [_selectedDropdown, setSelectedDropdown] = useState(dropdownList && dropdownList.length > 0 && dropdownList[0]);
  const [_selectedDisplayItem, setSelectedDisplayItem] = useState(new Array(25).fill(''));
  const [_selectedSideBetDropdown, setSelectedSideBetDropdown] = useState(sideBetNavList && sideBetNavList.length > 0 && sideBetNavList[0]);
  const [_selectedSideBetDisplayItem, setSelecteSideBetDisplayItem] = useState(new Array(25).fill(''));

  useEffect(() => {
    if (JSON.stringify(lengReResult) !== JSON.stringify(_result)) {
      const generatedItem = generateResult(_selectedDropdown.value, _selectedDropdown.resultPosition);
      setSelectedDisplayItem(generatedItem);
      const sideBetItem = generateResult(_selectedSideBetDropdown.value, _selectedDropdown.resultPosition);
      setSelecteSideBetDisplayItem(sideBetItem);
      setResult(lengReResult);
    }
  }, [lengReResult]);

  useEffect(() => {
    if (JSON.stringify(dropdownList) !== JSON.stringify(_dropdownList)) {
      setSelectedDropdown(dropdownList[0]);
      setDropdownList(dropdownList);
    }
  }, [dropdownList]);

  const generateResult = (value, resultPosition) => {
    const generatedItem = (result[value] && result[value].render(lengReResult, resultPosition)) || [];
    const emptyItem = new Array(25 - generatedItem.length).fill('');
    if (generatedItem.length < 25) generatedItem.push(...emptyItem);
    return generatedItem;
  };

  return (
    <Row className="probabilityResultWrapper pb-2 mt-2 m-1">
      <div className="selectionWrapper">
        <Select
          value={_selectedDropdown}
          onChange={item => {
            const generatedItem = generateResult(item.value, item.resultPosition);
            setSelectedDisplayItem(generatedItem);
            const sideBetItem = generateResult(_selectedSideBetDropdown.value, item.resultPosition);
            setSelecteSideBetDisplayItem(sideBetItem);
            setSelectedDropdown(item);
          }}
        >
          {
            _dropdownList.length > 0 && _dropdownList.map(item => (
              <Select.Option key={item.value} label={item.label} value={item} />
            ))
          }
        </Select>
      </div>
      <div className="resultWrapper">
        <div className="navWrapper">
          <Label
            className="dropdownItem active"
            value={_selectedDropdown && _selectedDropdown.label}
          />
        </div>
        <table>
          <tr>
            {
              _selectedDisplayItem.map(item => (
                <td>
                  {
                    Array.isArray(item) ? (
                      <>
                        {
                          item.map(x => (
                            <div className="value">
                              {x}
                            </div>
                          ))
                        }
                      </>
                    ) : (
                      <div className="value">
                        {item}
                      </div>
                    )
                  }
                </td>
              ))
            }
          </tr>
        </table>
      </div>
      <div className="sideBetResultWrapper mt-2">
        <div className="navWrapper">
          {
            sideBetNavList.map(item => (
              <Label
                onClick={() => {
                  const sideBetItem = generateResult(item.value, _selectedDropdown.resultPosition);
                  setSelecteSideBetDisplayItem(sideBetItem);
                  setSelectedSideBetDropdown(item);
                }}
                className={`sideBetItem ${_selectedSideBetDropdown.value === item.value ? 'active' : ''}`}
                value={item.label}
              />
            ))
          }
        </div>
        <table>
          <tr>
            {
              _selectedSideBetDisplayItem.map(item => (
                <td>
                  {
                    Array.isArray(item) ? (
                      <>
                        {
                          item.map(x => (
                            <div className="value">
                              {x}
                            </div>
                          ))
                        }
                      </>
                    ) : (
                      <div className="value">
                        {item}
                      </div>
                    )
                  }
                </td>
              ))
            }
          </tr>
        </table>
      </div>
    </Row>
  );
};

export default connect(mapStateToProps)(ProbabilityResultPanel);