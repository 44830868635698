import React, { useState } from 'react';

import { Col, ModalBody, ModalHeader, Row } from 'reactstrap';
import { IoRadioButtonOff, IoRadioButtonOn } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const RadioButtonListModal = ({ data, value, onChange }) => {
  const { t } = useTranslation();
  const [_selectedValue, setSelectedValue] = useState(value || {});
  const selectItem = newItem => {
    setSelectedValue(newItem);
    onChange(newItem);
  };
  return (
    <div className="radioButtonListModalWrapper">
      <ModalHeader className="px-0 text-white">
        {t('PLEASE_SELECT')}
      </ModalHeader>
      <ModalBody className="modalBodyWrapper">
        {data && data.map((item, index) => (
          <Row
            key={`item_list_${index.toString()}`}
            onClick={() => selectItem(item)}
          >
            <Col className="d-flex justify-content-start align-items-center col-9">
              {item.gameName || item.name || item.bankName || item.gameName || (item.bankCutNumber
                && (
                <span className="ml-3">
                  {item.bankCutNumber}
                </span>
                )
              ) }
            </Col>
            <Col className="d-flex justify-content-center align-items-center col-3">
              <div className="radioButtonItem d-flex justify-content-center align-items-center">
                {
                  ((_selectedValue.id !== undefined) && _selectedValue.id === item.id) || ((_selectedValue.bankID !== undefined) && _selectedValue.bankID === item.bankID) || (_selectedValue === item.id) || ((_selectedValue.bankCode !== undefined) && _selectedValue.bankCode === item.bankCode) || ((_selectedValue.gameCode !== undefined) && _selectedValue.gameCode === item.gameCode) ? <IoRadioButtonOn className="h4" /> : <IoRadioButtonOff className="h4" />
                }
              </div>
            </Col>
          </Row>
        ))}
      </ModalBody>
    </div>
  );
};

export default RadioButtonListModal;