import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const HM15 = [
  {
    componentType: CollapseType,
    header: '第一球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'B1QH1',
          displayName: '1',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B1QH2',
          displayName: '2',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B1QH3',
          displayName: '3',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B1QH4',
          displayName: '4',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B1QH5',
          displayName: '5',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B1QH6',
          displayName: '6',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B1QH7',
          displayName: '7',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B1QH8',
          displayName: '8',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B1QH9',
          displayName: '9',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B1QH10',
          displayName: '10',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B1QH11',
          displayName: '11',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B1QH12',
          displayName: '12',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B1QH13',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B1QH14',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B1QH15',
          displayName: '15',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B1QH16',
          displayName: '16',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B1QH17',
          displayName: '17',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B1QH18',
          displayName: '18',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B1QH19',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B1QH20',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B1QH21',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第二球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'B2QH1',
          displayName: '1',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B2QH2',
          displayName: '2',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B2QH3',
          displayName: '3',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B2QH4',
          displayName: '4',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B2QH5',
          displayName: '5',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B2QH6',
          displayName: '6',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B2QH7',
          displayName: '7',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B2QH8',
          displayName: '8',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B2QH9',
          displayName: '9',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B2QH10',
          displayName: '10',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B2QH11',
          displayName: '11',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B2QH12',
          displayName: '12',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B2QH13',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B2QH14',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B2QH15',
          displayName: '15',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B2QH16',
          displayName: '16',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B2QH17',
          displayName: '17',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B2QH18',
          displayName: '18',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B2QH19',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B2QH20',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B2QH21',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第三球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'B3QH1',
          displayName: '1',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B3QH2',
          displayName: '2',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B3QH3',
          displayName: '3',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B3QH4',
          displayName: '4',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B3QH5',
          displayName: '5',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B3QH6',
          displayName: '6',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B3QH7',
          displayName: '7',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B3QH8',
          displayName: '8',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B3QH9',
          displayName: '9',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B3QH10',
          displayName: '10',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B3QH11',
          displayName: '11',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B3QH12',
          displayName: '12',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B3QH13',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B3QH14',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B3QH15',
          displayName: '15',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B3QH16',
          displayName: '16',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B3QH17',
          displayName: '17',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B3QH18',
          displayName: '18',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B3QH19',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B3QH20',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B3QH21',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第四球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'B4QH1',
          displayName: '1',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B4QH2',
          displayName: '2',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B4QH3',
          displayName: '3',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B4QH4',
          displayName: '4',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B4QH5',
          displayName: '5',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B4QH6',
          displayName: '6',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B4QH7',
          displayName: '7',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B4QH8',
          displayName: '8',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B4QH9',
          displayName: '9',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B4QH10',
          displayName: '10',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B4QH11',
          displayName: '11',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B4QH12',
          displayName: '12',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B4QH13',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B4QH14',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B4QH15',
          displayName: '15',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B4QH16',
          displayName: '16',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B4QH17',
          displayName: '17',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B4QH18',
          displayName: '18',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B4QH19',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B4QH20',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B4QH21',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '第五球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'B5QH1',
          displayName: '1',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B5QH2',
          displayName: '2',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B5QH3',
          displayName: '3',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B5QH4',
          displayName: '4',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B5QH5',
          displayName: '5',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B5QH6',
          displayName: '6',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B5QH7',
          displayName: '7',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B5QH8',
          displayName: '8',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B5QH9',
          displayName: '9',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B5QH10',
          displayName: '10',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B5QH11',
          displayName: '11',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B5QH12',
          displayName: '12',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B5QH13',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B5QH14',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B5QH15',
          displayName: '15',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B5QH16',
          displayName: '16',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B5QH17',
          displayName: '17',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B5QH18',
          displayName: '18',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'B5QH19',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'B5QH20',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'B5QH21',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        }
      ]
    }
  },
];

export default HM15;