import React from 'react';

import { Button, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const MobileReminderModal = ({ updateModalAlert, data }) => {
  const { t } = useTranslation();
  return (
    <div className="mobileReminderModal">
      <Row className="modalHeader m-0">
        <Col className={data?.headerClassName}>
          {data.headerTitle}
        </Col>
      </Row>
      <Row className="modalContent d-flex justify-content-center mx-0">
        <Row className="mx-0 px-3 py-2 d-flex justify-content-center align-items-center">
          <span className="">{data.message}</span>
        </Row>
      </Row>
      <Row className="modalFooter align-items-center justify-content-center mx-0 py-3 px-2 flex-nowrap">
        {data && data.showCancelButton !== false && (
        <Button
          className="cancelButton py-2 w-50"
          onClick={() => {
            updateModalAlert({
              visible: false
            });
            if (data && data.onCancelClick) data.onCancelClick();
          }}
        >
          {t('CANCEL')}
        </Button>
        )}
        <Button
          className="confirmButton py-2 w-50 ml-2"
          onClick={() => {
            if (data && data.onConfirmationClick) data.onConfirmationClick();
          }}
        >
          {t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(MobileReminderModal);