import { OddsTypeTable, ProbabilitySelectionPanel } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const Q3ZX = [
  {
    componentType: ProbabilitySelectionPanel,
    data: {
      midCodeList: [{
        label: '前三复式',
        value: 'ZX2',
        minimumSelection: 3,
        maximumSelection: 33,
        iteration: 3,
      }]
    }
  },
  {
    componentType: QuickBet,
  },
  {
    componentType: OddsTypeTable,
    data: {
      renderLabel: () => '前三复式'
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '第一球',
      cycleHeaderTitle: true,
      mainColumnLength: 4,
      subColumnLength: 2,
      subColumnStyle: ['w-50', 'w-50'],
      betItemStyle: ['w-50', 'w-50'],
      betItemList: ['DisplayText', 'CheckBox'],
      betItemColumn: 2,
      list: [
        {
          code: 'A1',
          displayName: '1',
          className: 'circle',
        },
        {
          code: 'A4',
          displayName: '4',
          className: 'circle',
        },
        {
          code: 'A7',
          displayName: '7',
          className: 'circle',
        },
        {
          code: 'A10',
          displayName: '10',
          className: 'circle',
        },
        {
          code: 'A2',
          displayName: '2',
          className: 'circle',
        },
        {
          code: 'A5',
          displayName: '5',
          className: 'circle',
        },
        {
          code: 'A8',
          displayName: '8',
          className: 'circle',
        },
        {
          code: 'A11',
          displayName: '11',
          className: 'circle',
        },
        {
          code: 'A3',
          displayName: '3',
          className: 'circle',
        },
        {
          code: 'A6',
          displayName: '6',
          className: 'circle',
        },
        {
          code: 'A9',
          displayName: '9',
          className: 'circle',
        },
      ]
    }
  }, {
    componentType: Template2,
    data: {
      tableTitle: '第二球',
      cycleHeaderTitle: true,
      mainColumnLength: 4,
      subColumnLength: 2,
      subColumnStyle: ['w-50', 'w-50'],
      betItemStyle: ['w-50', 'w-50'],
      betItemList: ['DisplayText', 'CheckBox'],
      betItemColumn: 2,
      list: [
        {
          code: 'B1',
          displayName: '1',
          className: 'circle',
        },
        {
          code: 'B4',
          displayName: '4',
          className: 'circle',
        },
        {
          code: 'B7',
          displayName: '7',
          className: 'circle',
        },
        {
          code: 'B10',
          displayName: '10',
          className: 'circle',
        },
        {
          code: 'B2',
          displayName: '2',
          className: 'circle',
        },
        {
          code: 'B5',
          displayName: '5',
          className: 'circle',
        },
        {
          code: 'B8',
          displayName: '8',
          className: 'circle',
        },
        {
          code: 'B11',
          displayName: '11',
          className: 'circle',
        },
        {
          code: 'B3',
          displayName: '3',
          className: 'circle',
        },
        {
          code: 'B6',
          displayName: '6',
          className: 'circle',
        },
        {
          code: 'B9',
          displayName: '9',
          className: 'circle',
        },
      ]
    }
  }, {
    componentType: Template2,
    data: {
      tableTitle: '第三球',
      cycleHeaderTitle: true,
      mainColumnLength: 4,
      subColumnLength: 2,
      subColumnStyle: ['w-50', 'w-50'],
      betItemStyle: ['w-50', 'w-50'],
      betItemList: ['DisplayText', 'CheckBox'],
      betItemColumn: 2,
      list: [
        {
          code: 'C1',
          displayName: '1',
          className: 'circle',
        },
        {
          code: 'C4',
          displayName: '4',
          className: 'circle',
        },
        {
          code: 'C7',
          displayName: '7',
          className: 'circle',
        },
        {
          code: 'C10',
          displayName: '10',
          className: 'circle',
        },
        {
          code: 'C2',
          displayName: '2',
          className: 'circle',
        },
        {
          code: 'C5',
          displayName: '5',
          className: 'circle',
        },
        {
          code: 'C8',
          displayName: '8',
          className: 'circle',
        },
        {
          code: 'C11',
          displayName: '11',
          className: 'circle',
        },
        {
          code: 'C3',
          displayName: '3',
          className: 'circle',
        },
        {
          code: 'C6',
          displayName: '6',
          className: 'circle',
        },
        {
          code: 'C9',
          displayName: '9',
          className: 'circle',
        },
      ]
    }
  }, {
    componentType: QuickBet,
  }
];

export default Q3ZX;