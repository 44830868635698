import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const KuaiJie = [
  {
    componentType: CollapseType,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      kuaiJieSelectedGroupIndex: 0,
      list: [
        {
          code: 'GJ', // keyCode
          displayName: '冠军',
          showOdds: false,
        }, {
          code: 'YJ',
          displayName: '亚军',
          showOdds: false,
        }, {
          code: 'B3',
          displayName: '第三名',
          showOdds: false,
        }, {
          code: 'B4',
          displayName: '第四名',
          showOdds: false,
        }, {
          code: 'B5',
          displayName: '第五名',
          showOdds: false,
        }, {
          code: 'B6',
          displayName: '第六名',
          showOdds: false,
        }, {
          code: 'B7',
          displayName: '第七名',
          showOdds: false,
        }, {
          code: 'B8',
          displayName: '第八名',
          showOdds: false,
        }, {
          code: 'B9',
          displayName: '第九名',
          showOdds: false,
        }, {
          code: 'B10',
          displayName: '第十名',
          showOdds: false,
        }]
    }
  },
  {
    componentType: CollapseType,
    header: '',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      kuaiJieSelectedGroupIndex: 1,
      list: [{
        code: '7',
        displayName: '1',
        drawImgClassName: 'PK10_ball square_1',
        showOdds: false,
      }, {
        code: '8',
        displayName: '2',
        drawImgClassName: 'PK10_ball square_2',
        showOdds: false,
      }, {
        code: '9',
        displayName: '3',
        drawImgClassName: 'PK10_ball square_3',
        showOdds: false,
      }, {
        code: '10',
        displayName: '4',
        drawImgClassName: 'PK10_ball square_4',
        showOdds: false,
      }, {
        code: '11',
        displayName: '5',
        drawImgClassName: 'PK10_ball square_5',
        showOdds: false,
      }, {
        code: '12',
        displayName: '6',
        drawImgClassName: 'PK10_ball square_6',
        showOdds: false,
      }, {
        code: '13',
        displayName: '7',
        drawImgClassName: 'PK10_ball square_7',
        showOdds: false,
      }, {
        code: '14',
        displayName: '8',
        drawImgClassName: 'PK10_ball square_8',
        showOdds: false,
      }, {
        code: '15',
        displayName: '9',
        drawImgClassName: 'PK10_ball square_9',
        showOdds: false,
      }, {
        code: '16',
        displayName: '10',
        drawImgClassName: 'PK10_ball square_10',
        showOdds: false,
      }, {
        code: '1',
        displayName: '大',
        showOdds: false,
      }, {
        code: '2',
        displayName: '小',
        showOdds: false,
      }, {
        code: '3',
        displayName: '单',
        showOdds: false,
      }, {
        code: '4',
        displayName: '双',
        showOdds: false,
      }]
    }
  },
];

export default KuaiJie;