import { StatusCodes } from 'http-status-codes';

import { SET_BET_DETAIL_ITEM, SET_BET_REPORT_LIST, SET_SETTLED_BET_LIST, SET_UNSETTLED_BET_LIST } from '../actionTypes';
import { apiService } from '../../../library';

const setBetReportList = reportList => ({
  type: SET_BET_REPORT_LIST,
  value: reportList,
});

const setSettledBetList = betList => ({
  type: SET_SETTLED_BET_LIST,
  value: betList,
});

const setUnsettledBetList = betList => ({
  type: SET_UNSETTLED_BET_LIST,
  value: betList,
});

const setBetDetailItem = item => ({
  type: SET_BET_DETAIL_ITEM,
  value: item,
});

const history = (startDate, endDate, lotteryType) => dispatch => {
  apiService.get('/bettingList/history', `startDate=${startDate}&endDate=${endDate}&lotteryType=${lotteryType}`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(setBetReportList(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const unSettle = (index = 0, row = 10) => dispatch => {
  apiService.get('/bettingList/unsettle', `index=${index}&row=${row}`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(setUnsettledBetList(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const settle = (index = 0, row = 10) => dispatch => {
  apiService.get('/bettingList/settled', `index=${index}&row=${row}`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(setSettledBetList(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const betDetail = (date, index = 0, row = 10, lotteryType = '') => dispatch => {
  apiService.get(`/bettingList/betDetail/${date}`,
    new URLSearchParams({
      index,
      row,
      lotteryType
    })).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(setBetDetailItem(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

export default {
  betDetail,
  history,
  unSettle,
  settle
};