import CJ_11X5JSC from '../../assets/Mobile/image/lottery/11X5JSC.png';

import CJ_AUXY5SSC from '../../assets/Mobile/image/lottery/AUXY5SSC.png';
import CJ_AUXYPK10 from '../../assets/Mobile/image/lottery/AUXYPK10.png';
import CJ_AUXYSCPK10 from '../../assets/Mobile/image/lottery/AUXYSCPK10.png';
import CJ_AUXYSSC from '../../assets/Mobile/image/lottery/AUXYSSC.png';

import CJ_BJFTPK10 from '../../assets/Mobile/image/lottery/BJFTPK10.png';
import CJ_BJK3 from '../../assets/Mobile/image/lottery/BJK3.png';
import CJ_BJK8 from '../../assets/Mobile/image/lottery/BJK8.png';

import CJ_CQFTSSC from '../../assets/Mobile/image/lottery/CQFTSSC.png';
import CJ_CQSSC from '../../assets/Mobile/image/lottery/CQSSC.png';
import CJ_CQXYNC from '../../assets/Mobile/image/lottery/CQXYNC.png';
import CJ_CXYFTPK10 from '../../assets/Mobile/image/lottery/CXYFTPK10.png';

import CJ_DSNJSSCPK10 from '../../assets/Mobile/image/lottery/DSNJSSCPK10.png';
import CJ_DSNJSSSC from '../../assets/Mobile/image/lottery/DSNJSSSC.png';
import CJ_DXYFTPK10 from '../../assets/Mobile/image/lottery/DXYFTPK10.png';

import CJ_FJ31X7 from '../../assets/Mobile/image/lottery/FJ31X7.png';
import CJ_FJ36X7 from '../../assets/Mobile/image/lottery/FJ36X7.png';
import CJ_FJK3 from '../../assets/Mobile/image/lottery/FJK3.png';

import CJ_GD11X5 from '../../assets/Mobile/image/lottery/GD11X5.png';
import CJ_GDKL10 from '../../assets/Mobile/image/lottery/GDKL10.png';
import CJ_GX11X5 from '../../assets/Mobile/image/lottery/GX11X5.png';
import CJ_GXK10 from '../../assets/Mobile/image/lottery/GXK10.png';
import CJ_GXK3 from '../../assets/Mobile/image/lottery/GXK3.png';

import CJ_HK6 from '../../assets/Mobile/image/lottery/HK6.png';
import CJ_HLSXSSC from '../../assets/Mobile/image/lottery/HLSXSSC.png';
import CJ_HN5MSSC from '../../assets/Mobile/image/lottery/HN5MSSC.png';

import CJ_JAUXY8KL10 from '../../assets/Mobile/image/lottery/JAUXY8KL10.png';
import CJ_JLK3 from '../../assets/Mobile/image/lottery/JLK3.png';
import CJ_JS168HK6 from '../../assets/Mobile/image/lottery/JS168HK6.png';
import CJ_JS5K3 from '../../assets/Mobile/image/lottery/JS5K3.png';
import CJ_JSHK6 from '../../assets/Mobile/image/lottery/JSHK6.png';
import CJ_JSK3 from '../../assets/Mobile/image/lottery/JSK3.png';
import CJ_JSKL10 from '../../assets/Mobile/image/lottery/JSKL10.png';
import CJ_JSNSSC from '../../assets/Mobile/image/lottery/JSNSSC.png';
import CJ_JSSCNPK10 from '../../assets/Mobile/image/lottery/JSSCNPK10.png';
import CJ_JSSCPK10 from '../../assets/Mobile/image/lottery/JSSCPK10.png';
import CJ_JSSSC from '../../assets/Mobile/image/lottery/JSSSC.png';
import CJ_JSUK3 from '../../assets/Mobile/image/lottery/JSUK3.png';
import CJ_JSXYFTPK10C from '../../assets/Mobile/image/lottery/JSXYFTPK10.png';

import CJ_K3JSC from '../../assets/Mobile/image/lottery/K3JSC.png';
import CJ_LUCKYSB from '../../assets/Mobile/image/lottery/LUCKYSB.png';
import CJ_MCHK6 from '../../assets/Mobile/image/lottery/MCHK6.png';
import CJ_PCDD from '../../assets/Mobile/image/lottery/PCDD.png';
import CJ_PK10 from '../../assets/Mobile/image/lottery/PK10.png';

import CJ_SGFTPK10 from '../../assets/Mobile/image/lottery/SGFTPK10.png';
import CJ_SHK3 from '../../assets/Mobile/image/lottery/SHK3.png';
import CJ_TJSSC from '../../assets/Mobile/image/lottery/TJSSC.png';
import CJ_TWHK6 from '../../assets/Mobile/image/lottery/TWHK6.png';
import CJ_USASCPK10 from '../../assets/Mobile/image/lottery/USASCPK10.png';
import CJ_WNSK3 from '../../assets/Mobile/image/lottery/WNSK3.png';

import CJ_XJSSC from '../../assets/Mobile/image/lottery/XJSSC.png';
import CJ_XYFTFTPK10 from '../../assets/Mobile/image/lottery/XYFTFTPK10.png';
import CJ_XYFTPK10 from '../../assets/Mobile/image/lottery/XYFTPK10.png';
import CJ_XYSCFTPK10 from '../../assets/Mobile/image/lottery/XYSCFTPK10.png';
import CJ_XYSSCFTSSC from '../../assets/Mobile/image/lottery/XYSSCFTSSC.png';
import CJ_XYSTPK10 from '../../assets/Mobile/image/lottery/XYSTPK10.png';

import CJ_BG from '../../assets/Mobile/image/thirdPartyGame/BG.png';
import CJ_DS from '../../assets/Mobile/image/thirdPartyGame/DS.png';
import CJ_IM_AG from '../../assets/Mobile/image/thirdPartyGame/IM_AG.png';
import CJ_IM_SP from '../../assets/Mobile/image/thirdPartyGame/IM_SP.png';
import CJ_KY_CC from '../../assets/Mobile/image/thirdPartyGame/KY_CC.png';
import CJ_LC_CC from '../../assets/Mobile/image/thirdPartyGame/LC_CC.png';
import CJ_XJ_SP from '../../assets/Mobile/image/thirdPartyGame/XJ_SP.png';

export default {
  CJ_11X5JSC,
  CJ_AUXY5SSC,
  CJ_AUXYPK10,
  CJ_AUXYSCPK10,
  CJ_AUXYSSC,
  CJ_BJFTPK10,
  CJ_BJK3,
  CJ_BJK8,
  CJ_CQFTSSC,
  CJ_CQSSC,
  CJ_CQXYNC,
  CJ_CXYFTPK10,
  CJ_DSNJSSCPK10,
  CJ_DSNJSSSC,
  CJ_DXYFTPK10,
  CJ_FJ31X7,
  CJ_FJ36X7,
  CJ_FJK3,
  CJ_GD11X5,
  CJ_GDKL10,
  CJ_GX11X5,
  CJ_GXK3,
  CJ_GXK10,
  CJ_HK6,
  CJ_HLSXSSC,
  CJ_HN5MSSC,
  CJ_JAUXY8KL10,
  CJ_JLK3,
  CJ_JS5K3,
  CJ_JS168HK6,
  CJ_JSHK6,
  CJ_JSK3,
  CJ_JSKL10,
  CJ_JSNSSC,
  CJ_JSSCNPK10,
  CJ_JSSCPK10,
  CJ_JSSSC,
  CJ_JSUK3,
  CJ_JSXYFTPK10C,
  CJ_K3JSC,
  CJ_LUCKYSB,
  CJ_MCHK6,
  CJ_PCDD,
  CJ_PK10,
  CJ_SGFTPK10,
  CJ_SHK3,
  CJ_TJSSC,
  CJ_TWHK6,
  CJ_USASCPK10,
  CJ_WNSK3,
  CJ_XJSSC,
  CJ_XYFTFTPK10,
  CJ_XYFTPK10,
  CJ_XYSCFTPK10,
  CJ_XYSSCFTSSC,
  CJ_XYSTPK10,
  CJ_BG,
  CJ_DS,
  CJ_IM_AG,
  CJ_IM_SP,
  CJ_KY_CC,
  CJ_LC_CC,
  CJ_XJ_SP
};