import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';

import PengAndDan from '../../../../components/Mobile/ComponentType/PengAndDan';

import TeMaAndLianMa from '../../../../components/Mobile/ComponentType/TeMaAndLianMa';

import OptionItem from '../../../../components/Mobile/OptionItem';

const LM = [
  {
    componentType: TeMaAndLianMa,
    data: {
      list: [
        {
          code: 'L2QZ',
          displayName: '二全中',
          minimumSelection: 2,
          maximumSelection: 8,
        },
        {
          displayName: '三中特',
          minimumSelection: 3,
          maximumSelection: 8,
          code: 'L3ZT',
          children: [
            {
              code: 'L3ZT'
            },
            {
              code: 'L3ZTZ3'
            },
          ],
        },
        {
          displayName: '四拖三',
          minimumSelection: 4,
          maximumSelection: 8,
          code: 'L4ZT',
          children: [
            {
              code: 'L4ZT'
            },
            {
              code: 'L4ZTZ4'
            },
          ],
        },
        {
          code: 'L1ZB',
          displayName: '特串',
          minimumSelection: 2,
          maximumSelection: 8,
        },
        {
          wrapperStyle: '',
          code: 'L3QZ',
          displayName: '三全中',
          minimumSelection: 3,
          maximumSelection: 11,
        },
        {
          wrapperStyle: '',
          displayName: '二字半',
          minimumSelection: 3,
          maximumSelection: 8,
          code: 'L2ZB',
        },
        {
          wrapperStyle: '',
          displayName: '四全中',
          minimumSelection: 4,
          maximumSelection: 9,
          code: 'L4QZ',
        },
        {
          wrapperStyle: '',
          code: 'L3ZB',
          displayName: '三字半',
          minimumSelection: 4,
          maximumSelection: 8,
        }
      ]
    }
  },
  {
    componentType: PengAndDan,
    data: {}
  },
  {
    componentType: CollapseType,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: '1',
          displayName: '01',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '02',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '03',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '04',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '05',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '06',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '07',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '08',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '09',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '10',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '11',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '12',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '15',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '16',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '17',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '18',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '22',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '23',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '24',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '25',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '26',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: '1',
          displayName: '27',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '28',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: '1',
          displayName: '29',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '30',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: '1',
          displayName: '31',
          drawImgClassName: 'circle circleBlue'
        }
      ]
    }
  }
];

export default LM;

// {
//   componentType: MultipleTypeBetsPanel,
//   data: {
//     list: [
//       {
//         code: 'L2QZ',
//         displayName: '二全中',
//         minimumSelection: 2,
//         maximumSelection: 8,
//         fromValue: 1,
//         toValue: 36,
//       },
//       {
//         displayName: '三中特',
//         minimumSelection: 3,
//         maximumSelection: 8,
//         code: 'L3ZT',
//         fromValue: 1,
//         toValue: 36,
//         children: [
//           {
//             code: 'L3ZT'
//           },
//           {
//             code: 'L3ZTZ3'
//           },
//         ],
//       },
//       {
//         displayName: '四拖三',
//         minimumSelection: 4,
//         maximumSelection: 8,
//         code: 'L4ZT',
//         fromValue: 1,
//         toValue: 36,
//         children: [
//           {
//             code: 'L4ZT'
//           },
//           {
//             code: 'L4ZTZ4'
//           },
//         ],
//       },
//       {
//         code: 'L1ZB',
//         displayName: '特串',
//         minimumSelection: 2,
//         maximumSelection: 8,
//         fromValue: 1,
//         toValue: 36,
//       }
//     ]
//   }
// }
// {
//   componentType: MultipleTypeBetsPanel,
//   data: {
//     list: [
//       {
//         wrapperStyle: '',
//         code: 'L3QZ',
//         displayName: '三全中',
//         minimumSelection: 3,
//         maximumSelection: 11,
//         fromValue: 1,
//         toValue: 36,
//       },
//       {
//         wrapperStyle: '',
//         displayName: '二字半',
//         minimumSelection: 3,
//         maximumSelection: 8,
//         code: 'L2ZB',
//         fromValue: 1,
//         toValue: 36,
//       },
//       {
//         wrapperStyle: '',
//         displayName: '四全中',
//         minimumSelection: 4,
//         maximumSelection: 9,
//         code: 'L4QZ',
//         fromValue: 1,
//         toValue: 36,
//       },
//       {
//         wrapperStyle: '',
//         code: 'L3ZB',
//         displayName: '三字半',
//         minimumSelection: 4,
//         maximumSelection: 8,
//         fromValue: 1,
//         toValue: 36,
//       }
//     ]
//   }
// }