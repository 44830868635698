import React from 'react';

import { Col, Input, Row } from 'reactstrap';
import { connect } from 'react-redux';

import Label from '../../Label';
import { cartActions } from '../../../redux/actions';
import { validateNumber } from '../../../constants/utils';
import './styles.scss';

const mapStateToProps = state => ({
  gameInfo: state.placeBetReducers.gameInfo,
  presetAmount: state.gameReducers.presetAmount,
  cart: state.cartReducers.cart,
});

const mapDispatchToProps = dispatch => ({
  addItemToCart: (item, reset) => dispatch(cartActions.addItemToCart(item, reset)),
  removeItemInCart: keyCode => dispatch(cartActions.removeItemInCart(keyCode)),
});

const FanTanTemplate = ({ gameInfo, presetAmount, addItemToCart, cart, removeItemInCart }) => {
  const renderOdds = keyCode => {
    const currentOddItem = gameInfo && gameInfo.oddsList && gameInfo.oddsList[keyCode];
    if (currentOddItem && currentOddItem.enabled) {
      return currentOddItem.odds;
    }
    return '--';
  };

  const onItemClick = (keyCode, displayName, amount) => {
    const currentOddItem = gameInfo && gameInfo.oddsList && gameInfo.oddsList[keyCode];
    const { midType, odds, enabled } = currentOddItem || {};

    if (!gameInfo || !gameInfo?.oddsList || enabled === 0) return;

    if (cart && cart[keyCode] && !amount) {
      return removeItemInCart(keyCode);
    }

    return addItemToCart({
      [keyCode]: {
        amount: amount || presetAmount || 0,
        displayName,
        midType,
        keyCode,
        odds,
      }
    });
  };

  return (
    <Col md="12" className="fantanTemplate my-2 p-0">
      <table>
        <tbody>
          <tr>
            <td>三门</td>
            <td className={`corner ${cart && cart.JI3_4 ? 'selected' : ''}`} onClick={() => onItemClick('JI3_4', '3-4角')}>
              <Row>
                <b>角</b>
              </Row>
              <Row className="label">
                <Label value="3 4" />
              </Row>
              <Row className="odds">
                {renderOdds('JI3_4')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.JI3_4?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('JI3_4') === '--') return;
                    onItemClick('JI3_4', '3-4角', validateNumber(text));
                  }}
                />
              </Row>
            </td>
            <td className={cart && cart.NI34 ? 'selected' : ''} onClick={() => onItemClick('NI34', '3念4')}>
              <Col className="d-flex flex-column">
                <Row className="label">
                  <Label value="3 念 4" />
                </Row>
                <Row className="odds">
                  {renderOdds('NI34')}
                </Row>
                <Row>
                  <Input
                    onClick={e => e.stopPropagation()}
                    value={(cart && (cart?.NI34?.amount)) || ''}
                    onChange={({ target: { value: text } }) => {
                      if (renderOdds('NI34') === '--') return;
                      onItemClick('NI34', '3念4', validateNumber(text));
                    }}
                  />
                </Row>
              </Col>
            </td>
            <td className={cart && cart.NI31 ? 'selected' : ''} onClick={() => onItemClick('NI31', '3念1')}>
              <Row className="label">
                <Label value="3 念 1" />
              </Row>
              <Row className="odds">
                {renderOdds('NI31')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.NI31?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('NI31') === '--') return;
                    onItemClick('NI31', '3念1', validateNumber(text));
                  }}
                />
              </Row>
            </td>
            <td className={cart && cart.NI32 ? 'selected' : ''} onClick={() => onItemClick('NI32', '3念2')}>
              <Row className="label">
                <Label value="3 念 2" />
              </Row>
              <Row className="odds">
                {renderOdds('NI32')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.NI32?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('NI32') === '--') return;
                    onItemClick('NI32', '3念2', validateNumber(text));
                  }}
                />
              </Row>
            </td>
            <td className={`corner ${cart && cart.JI2_3 ? 'selected' : ''}`} onClick={() => onItemClick('JI2_3', '2-3角')}>
              <Row>
                <b>角</b>
              </Row>
              <Row className="label">
                <Label value="2 3" />
              </Row>
              <Row className="odds">
                {renderOdds('JI2_3')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.JI2_3?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('JI2_3') === '--') return;
                    onItemClick('JI2_3', '2-3角', validateNumber(text));
                  }}
                />
              </Row>
            </td>
            <td>番</td>
          </tr>
          <tr>
            <td className={cart && cart.SM123 ? 'selected' : ''} onClick={() => onItemClick('SM123', '123中')}>
              <Row className="label">
                <Label value="1 2 3" />
              </Row>
              <Row className="subLabel">
                <Label value="中" />
              </Row>
              <Row className="odds">
                {renderOdds('SM123')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.SM123?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('SM123') === '--') return;
                    onItemClick('SM123', '123中', validateNumber(text));
                  }}
                />
              </Row>
            </td>
            <td className={cart && cart.NI43 ? 'selected' : ''} onClick={() => onItemClick('NI43', '4念3')}>
              <Row className="label">
                <Label value="4 念 3" />
              </Row>
              <Row className="odds">
                {renderOdds('NI43')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.NI43?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('NI43') === '--') return;
                    onItemClick('NI43', '4念3', validateNumber(text));
                  }}
                />
              </Row>
            </td>
            <td colSpan="3" rowSpan="3" className="trapezoidWrapper">
              <div className={`trapezoid top ${cart && cart.ZE3 ? 'selected' : ''}`} onClick={() => onItemClick('ZE3', '3 正')}>
                <Col className="d-flex justify-content-center align-items-center flex-column">
                  <Label className="font-weight-bold" value="3 正" />
                  <Row className="odds">
                    {renderOdds('ZE3')}
                  </Row>
                  <Input
                    onClick={e => e.stopPropagation()}
                    value={(cart && (cart?.ZE3?.amount)) || ''}
                    onChange={({ target: { value: text } }) => {
                      if (renderOdds('ZE3') === '--') return;
                      onItemClick('ZE3', '3 正', validateNumber(text));
                    }}
                  />
                </Col>
              </div>
              <div className={`trapezoid left ${cart && cart.ZE4 ? 'selected' : ''}`} onClick={() => onItemClick('ZE4', '4 正')}>
                <Col className="d-flex justify-content-center align-items-center flex-column">
                  <Label className="font-weight-bold" value="4 正" />
                  <Row className="odds">
                    {renderOdds('ZE4')}
                  </Row>
                  <Input
                    onClick={e => e.stopPropagation()}
                    value={(cart && (cart?.ZE4?.amount)) || ''}
                    onChange={({ target: { value: text } }) => {
                      if (renderOdds('ZE4') === '--') return;
                      onItemClick('ZE4', '4 正', validateNumber(text));
                    }}
                  />
                </Col>
              </div>
              <div className={`trapezoid right ${cart && cart.ZE2 ? 'selected' : ''}`} onClick={() => onItemClick('ZE2', '2 正')}>
                <Col className="d-flex justify-content-center align-items-center flex-column">
                  <Label className="font-weight-bold" value="2 正" />
                  <Row className="odds">
                    {renderOdds('ZE2')}
                  </Row>
                  <Input
                    onClick={e => e.stopPropagation()}
                    value={(cart && (cart?.ZE2?.amount)) || ''}
                    onChange={({ target: { value: text } }) => {
                      if (renderOdds('ZE2') === '--') return;
                      onItemClick('ZE2', '2 正', validateNumber(text));
                    }}
                  />
                </Col>
              </div>
              <div className={`trapezoid bottom ${cart && cart.ZE1 ? 'selected' : ''}`} onClick={() => onItemClick('ZE1', '1 正')}>
                <Col className="d-flex justify-content-center align-items-center flex-column">
                  <Label className="font-weight-bold" value="1 正" />
                  <Row className="odds">
                    {renderOdds('ZE1')}
                  </Row>
                  <Input
                    onClick={e => e.stopPropagation()}
                    value={(cart && (cart?.ZE1?.amount)) || ''}
                    onChange={({ target: { value: text } }) => {
                      if (renderOdds('ZE1') === '--') return;
                      onItemClick('ZE1', '1 正', validateNumber(text));
                    }}
                  />
                </Col>
              </div>
              <div className="d-flex flex-row justify-content-center">
                <div className="middle left">
                  <div className={`w-100 h-50 d-flex flex-column justify-content-center align-items-center ${cart && cart.DS_D ? 'selected' : ''}`} onClick={() => onItemClick('DS_D', '单')}>
                    <Row className="label">
                      <Label value="单" />
                    </Row>
                    <Row className="odds">
                      {renderOdds('DS_D')}
                    </Row>
                    <Row>
                      <Input
                        onClick={e => e.stopPropagation()}
                        value={(cart && (cart?.DS_D?.amount)) || ''}
                        onChange={({ target: { value: text } }) => {
                          if (renderOdds('DS_D') === '--') return;
                          onItemClick('DS_D', '单', validateNumber(text));
                        }}
                      />
                    </Row>
                  </div>
                  <div className={`w-100 h-50 border-top d-flex flex-column justify-content-center align-items-center ${cart && cart.DX_D ? 'selected' : ''}`} onClick={() => onItemClick('DX_D', '大')}>
                    <Row className="label">
                      <Label value="大" />
                    </Row>
                    <Row className="odds">
                      {renderOdds('DX_D')}
                    </Row>
                    <Row>
                      <Input
                        onClick={e => e.stopPropagation()}
                        value={(cart && (cart?.DX_D?.amount)) || ''}
                        onChange={({ target: { value: text } }) => {
                          if (renderOdds('DX_D') === '--') return;
                          onItemClick('DX_D', '大', validateNumber(text));
                        }}
                      />
                    </Row>
                  </div>
                </div>
                <div className="middle right">
                  <div className={`w-100 h-50 d-flex flex-column justify-content-center align-items-center ${cart && cart.DS_S ? 'selected' : ''}`} onClick={() => onItemClick('DS_S', '双')}>
                    <Row className="label">
                      <Label value="双" />
                    </Row>
                    <Row className="odds">
                      {renderOdds('DS_S')}
                    </Row>
                    <Row>
                      <Input
                        onClick={e => e.stopPropagation()}
                        value={(cart && (cart?.DS_S?.amount)) || ''}
                        onChange={({ target: { value: text } }) => {
                          if (renderOdds('DS_S') === '--') return;
                          onItemClick('DS_S', '双', validateNumber(text));
                        }}
                      />
                    </Row>
                  </div>
                  <div className={`w-100 h-50 border-top d-flex flex-column justify-content-center align-items-center ${cart && cart.DX_X ? 'selected' : ''}`} onClick={() => onItemClick('DX_X', '小')}>
                    <Row className="label">
                      <Label value="小" />
                    </Row>
                    <Row className="odds">
                      {renderOdds('DX_X')}
                    </Row>
                    <Row>
                      <Input
                        onClick={e => e.stopPropagation()}
                        value={(cart && (cart?.DX_X?.amount)) || ''}
                        onChange={({ target: { value: text } }) => {
                          if (renderOdds('DX_X') === '--') return;
                          onItemClick('DX_X', '小', validateNumber(text));
                        }}
                      />
                    </Row>
                  </div>
                </div>
              </div>
            </td>
            <td className={cart && cart.NI23 ? 'selected' : ''} onClick={() => onItemClick('NI23', '2念3')}>
              <Row className="label">
                <Label value="2 念 3" />
              </Row>
              <Row className="odds">
                {renderOdds('NI23')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.NI23?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('NI23') === '--') return;
                    onItemClick('NI23', '2念3', validateNumber(text));
                  }}
                />
              </Row>
            </td>
            <td className={cart && cart.FA1 ? 'selected' : ''} onClick={() => onItemClick('FA1', '1番')}>
              <Row className="label">
                <Label value="1" />
              </Row>
              <Row className="subLabel">
                <Label value="番" />
              </Row>
              <Row className="odds">
                {renderOdds('FA1')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.FA1?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('FA1') === '--') return;
                    onItemClick('FA1', '1番', validateNumber(text));
                  }}
                />
              </Row>
            </td>
          </tr>
          <tr>
            <td className={cart && cart.SM124 ? 'selected' : ''} onClick={() => onItemClick('SM124', '124中')}>
              <Row className="label">
                <Label value="1 2 4" />
              </Row>
              <Row className="subLabel">
                <Label value="中" />
              </Row>
              <Row className="odds">
                {renderOdds('SM124')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.SM124?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('SM124') === '--') return;
                    onItemClick('SM124', '124中', validateNumber(text));
                  }}
                />
              </Row>
            </td>
            <td className={cart && cart.NI42 ? 'selected' : ''} onClick={() => onItemClick('NI42', '4念2')}>
              <Row className="label">
                <Label value="4 念 2" />
              </Row>
              <Row className="odds">
                {renderOdds('NI42')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.NI42?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('NI42') === '--') return;
                    onItemClick('NI42', '4念2', validateNumber(text));
                  }}
                />
              </Row>
            </td>
            <td className={cart && cart.NI24 ? 'selected' : ''} onClick={() => onItemClick('NI24', '2念4')}>
              <Row className="label">
                <Label value="2 念 4" />
              </Row>
              <Row className="odds">
                {renderOdds('NI24')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.NI24?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('NI24') === '--') return;
                    onItemClick('NI24', '2念4', validateNumber(text));
                  }}
                />
              </Row>
            </td>
            <td className={cart && cart.FA2 ? 'selected' : ''} onClick={() => onItemClick('FA2', '2番')}>
              <Row className="label">
                <Label value="2" />
              </Row>
              <Row className="subLabel">
                <Label value="番" />
              </Row>
              <Row className="odds">
                {renderOdds('FA2')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.FA2?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('FA2') === '--') return;
                    onItemClick('FA2', '2番', validateNumber(text));
                  }}
                />
              </Row>
            </td>
          </tr>
          <tr>
            <td className={cart && cart.SM134 ? 'selected' : ''} onClick={() => onItemClick('SM134', '134中')}>
              <Row className="label">
                <Label value="1 3 4" />
              </Row>
              <Row className="subLabel">
                <Label value="中" />
              </Row>
              <Row className="odds">
                {renderOdds('SM134')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.SM134?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('SM134') === '--') return;
                    onItemClick('SM134', '134中', validateNumber(text));
                  }}
                />
              </Row>
            </td>
            <td className={cart && cart.NI41 ? 'selected' : ''} onClick={() => onItemClick('NI41', '4念1')}>
              <Row className="label">
                <Label value="4 念 1" />
              </Row>
              <Row className="odds">
                {renderOdds('NI41')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.NI41?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('NI41') === '--') return;
                    onItemClick('NI41', '4念1', validateNumber(text));
                  }}
                />
              </Row>
            </td>
            <td className={cart && cart.NI21 ? 'selected' : ''} onClick={() => onItemClick('NI21', '2念1')}>
              <Row className="label">
                <Label value="2 念 1" />
              </Row>
              <Row className="odds">
                {renderOdds('NI21')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.NI21?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('NI21') === '--') return;
                    onItemClick('NI21', '2念1', validateNumber(text));
                  }}
                />
              </Row>
            </td>
            <td className={cart && cart.FA3 ? 'selected' : ''} onClick={() => onItemClick('FA3', '3番')}>
              <Row className="label">
                <Label value="3" />
              </Row>
              <Row className="subLabel">
                <Label value="番" />
              </Row>
              <Row className="odds">
                {renderOdds('FA3')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.FA3?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('FA3') === '--') return;
                    onItemClick('FA3', '3番', validateNumber(text));
                  }}
                />
              </Row>
            </td>
          </tr>
          <tr>
            <td className={cart && cart.SM234 ? 'selected' : ''} onClick={() => onItemClick('SM234', '234中')}>
              <Row className="label">
                <Label value="2 3 4" />
              </Row>
              <Row className="subLabel">
                <Label value="中" />
              </Row>
              <Row className="odds">
                {renderOdds('SM234')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.SM234?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('SM234') === '--') return;
                    onItemClick('SM234', '234中', validateNumber(text));
                  }}
                />
              </Row>
            </td>
            <td className={`corner ${cart && cart.JI1_4 ? 'selected' : ''}`} onClick={() => onItemClick('JI1_4', '4-1角')}>
              <Row>
                <b>角</b>
              </Row>
              <Row className="label">
                <Label value="4 1" />
              </Row>
              <Row className="odds">
                {renderOdds('JI1_4')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.JI1_4?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('JI1_4') === '--') return;
                    onItemClick('JI1_4', '4-1角', validateNumber(text));
                  }}
                />
              </Row>
            </td>
            <td className={cart && cart.NI14 ? 'selected' : ''} onClick={() => onItemClick('NI14', '1念4')}>
              <Row className="label">
                <Label value="1 念 4" />
              </Row>
              <Row className="odds">
                {renderOdds('NI14')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.NI14?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('NI14') === '--') return;
                    onItemClick('NI14', '1念4', validateNumber(text));
                  }}
                />
              </Row>
            </td>
            <td className={cart && cart.NI13 ? 'selected' : ''} onClick={() => onItemClick('NI13', '1念3')}>
              <Row className="label">
                <Label value="1 念 3" />
              </Row>
              <Row className="odds">
                {renderOdds('NI13')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.NI13?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('NI13') === '--') return;
                    onItemClick('NI13', '1念3', validateNumber(text));
                  }}
                />
              </Row>
            </td>
            <td className={cart && cart.NI12 ? 'selected' : ''} onClick={() => onItemClick('NI12', '1念2')}>
              <Row className="label">
                <Label value="1 念 2" />
              </Row>
              <Row className="odds">
                {renderOdds('NI12')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.NI12?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('NI12') === '--') return;
                    onItemClick('NI12', '1念2', validateNumber(text));
                  }}
                />
              </Row>
            </td>
            <td className={`corner ${cart && cart.JI1_2 ? 'selected' : ''}`} onClick={() => onItemClick('JI1_2', '1-2角')}>
              <Row>
                <b>角</b>
              </Row>
              <Row className="label">
                <Label value="1 2" />
              </Row>
              <Row className="odds">
                {renderOdds('JI1_2')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.JI1_2?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('JI1_2') === '--') return;
                    onItemClick('JI1_2', '1-2角', validateNumber(text));
                  }}
                />
              </Row>
            </td>
            <td className={cart && cart.FA4 ? 'selected' : ''} onClick={() => onItemClick('FA4', '4番')}>
              <Row className="label">
                <Label value="4" />
              </Row>
              <Row className="subLabel">
                <Label value="番" />
              </Row>
              <Row className="odds">
                {renderOdds('FA4')}
              </Row>
              <Row>
                <Input
                  onClick={e => e.stopPropagation()}
                  value={(cart && (cart?.FA4?.amount)) || ''}
                  onChange={({ target: { value: text } }) => {
                    if (renderOdds('FA4') === '--') return;
                    onItemClick('FA4', '4番', validateNumber(text));
                  }}
                />
              </Row>
            </td>
          </tr>
        </tbody>
      </table>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FanTanTemplate);