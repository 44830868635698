import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const DH610 = [
  {
    componentType: CollapseType,
    header: '第六名',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'B6CH7', // keyCode
        displayName: '1',
        drawImgClassName: 'PK10_ball square_1',
      }, {
        code: 'B6CH8',
        displayName: '2',
        drawImgClassName: 'PK10_ball square_2',
      }, {
        code: 'B6CH9',
        displayName: '3',
        drawImgClassName: 'PK10_ball square_3',
      }, {
        code: 'B6CH10',
        displayName: '4',
        drawImgClassName: 'PK10_ball square_4',
      }, {
        code: 'B6CH11',
        displayName: '5',
        drawImgClassName: 'PK10_ball square_5',
      }, {
        code: 'B6CH12',
        displayName: '6',
        drawImgClassName: 'PK10_ball square_6',
      }, {
        code: 'B6CH13',
        displayName: '7',
        drawImgClassName: 'PK10_ball square_7',
      }, {
        code: 'B6CH14',
        displayName: '8',
        drawImgClassName: 'PK10_ball square_8',
      }, {
        code: 'B6CH15',
        displayName: '9',
        drawImgClassName: 'PK10_ball square_9',
      }, {
        code: 'B6CH16',
        displayName: '10',
        drawImgClassName: 'PK10_ball square_10',
      }],
    }
  },
  {
    componentType: CollapseType,
    header: '第七名',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'B7CH7', // keyCode
        displayName: '1',
        drawImgClassName: 'PK10_ball square_1',
      }, {
        code: 'B7CH8',
        displayName: '2',
        drawImgClassName: 'PK10_ball square_2',
      }, {
        code: 'B7CH9',
        displayName: '3',
        drawImgClassName: 'PK10_ball square_3',
      }, {
        code: 'B7CH10',
        displayName: '4',
        drawImgClassName: 'PK10_ball square_4',
      }, {
        code: 'B7CH11',
        displayName: '5',
        drawImgClassName: 'PK10_ball square_5',
      }, {
        code: 'B7CH12',
        displayName: '6',
        drawImgClassName: 'PK10_ball square_6',
      }, {
        code: 'B7CH13',
        displayName: '7',
        drawImgClassName: 'PK10_ball square_7',
      }, {
        code: 'B7CH14',
        displayName: '8',
        drawImgClassName: 'PK10_ball square_8',
      }, {
        code: 'B7CH15',
        displayName: '9',
        drawImgClassName: 'PK10_ball square_9',
      }, {
        code: 'B7CH16',
        displayName: '10',
        drawImgClassName: 'PK10_ball square_10',
      }],
    }
  },
  {
    componentType: CollapseType,
    header: '第八名',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'B8CH7', // keyCode
        displayName: '1',
        drawImgClassName: 'PK10_ball square_1',
      }, {
        code: 'B8CH8',
        displayName: '2',
        drawImgClassName: 'PK10_ball square_2',
      }, {
        code: 'B8CH9',
        displayName: '3',
        drawImgClassName: 'PK10_ball square_3',
      }, {
        code: 'B8CH10',
        displayName: '4',
        drawImgClassName: 'PK10_ball square_4',
      }, {
        code: 'B8CH11',
        displayName: '5',
        drawImgClassName: 'PK10_ball square_5',
      }, {
        code: 'B8CH12',
        displayName: '6',
        drawImgClassName: 'PK10_ball square_6',
      }, {
        code: 'B8CH13',
        displayName: '7',
        drawImgClassName: 'PK10_ball square_7',
      }, {
        code: 'B8CH14',
        displayName: '8',
        drawImgClassName: 'PK10_ball square_8',
      }, {
        code: 'B8CH15',
        displayName: '9',
        drawImgClassName: 'PK10_ball square_9',
      }, {
        code: 'B8CH16',
        displayName: '10',
        drawImgClassName: 'PK10_ball square_10',
      }],
    }
  },
  {
    componentType: CollapseType,
    header: '第九名',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'B9CH7', // keyCode
        displayName: '1',
        drawImgClassName: 'PK10_ball square_1',
      }, {
        code: 'B9CH8',
        displayName: '2',
        drawImgClassName: 'PK10_ball square_2',
      }, {
        code: 'B9CH9',
        displayName: '3',
        drawImgClassName: 'PK10_ball square_3',
      }, {
        code: 'B9CH10',
        displayName: '4',
        drawImgClassName: 'PK10_ball square_4',
      }, {
        code: 'B9CH11',
        displayName: '5',
        drawImgClassName: 'PK10_ball square_5',
      }, {
        code: 'B9CH12',
        displayName: '6',
        drawImgClassName: 'PK10_ball square_6',
      }, {
        code: 'B9CH13',
        displayName: '7',
        drawImgClassName: 'PK10_ball square_7',
      }, {
        code: 'B9CH14',
        displayName: '8',
        drawImgClassName: 'PK10_ball square_8',
      }, {
        code: 'B9CH15',
        displayName: '9',
        drawImgClassName: 'PK10_ball square_9',
      }, {
        code: 'B9CH16',
        displayName: '10',
        drawImgClassName: 'PK10_ball square_10',
      }],
    }
  },
  {
    componentType: CollapseType,
    header: '第十名',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'B10CH7', // keyCode
        displayName: '1',
        drawImgClassName: 'PK10_ball square_1',
      }, {
        code: 'B10CH8',
        displayName: '2',
        drawImgClassName: 'PK10_ball square_2',
      }, {
        code: 'B10CH9',
        displayName: '3',
        drawImgClassName: 'PK10_ball square_3',
      }, {
        code: 'B10CH10',
        displayName: '4',
        drawImgClassName: 'PK10_ball square_4',
      }, {
        code: 'B10CH11',
        displayName: '5',
        drawImgClassName: 'PK10_ball square_5',
      }, {
        code: 'B10CH12',
        displayName: '6',
        drawImgClassName: 'PK10_ball square_6',
      }, {
        code: 'B10CH13',
        displayName: '7',
        drawImgClassName: 'PK10_ball square_7',
      }, {
        code: 'B10CH14',
        displayName: '8',
        drawImgClassName: 'PK10_ball square_8',
      }, {
        code: 'B10CH15',
        displayName: '9',
        drawImgClassName: 'PK10_ball square_9',
      }, {
        code: 'B10CH16',
        displayName: '10',
        drawImgClassName: 'PK10_ball square_10',
      }],
    }
  },
];

export default DH610;