import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const Z6T = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '赔率', '金额'],
      mainColumnLength: 4,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      emptyItemStyle: ['column-3items-empty-label', 'column-3items-empty-odds', 'column-3items-empty-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'Z6T1',
          displayName: '01',
          className: 'circle circleRed',
        }, {
          code: 'Z6T11',
          displayName: '11',
          className: 'circle circleGreen',
        }, {
          code: 'Z6T21',
          displayName: '21',
          className: 'circle circleGreen',
        }, {
          code: 'Z6T31',
          displayName: '31',
          className: 'circle circleBlue',
        }, {
          code: 'Z6T2',
          displayName: '02',
          className: 'circle circleRed',
        }, {
          code: 'Z6T12',
          displayName: '12',
          className: 'circle circleRed',
        }, {
          code: 'Z6T22',
          displayName: '22',
          className: 'circle circleGreen',
        }, {
          code: 'Z6T32',
          displayName: '32',
          className: 'circle circleGreen',
        }, {
          code: 'Z6T3',
          displayName: '03',
          className: 'circle circleBlue',
        }, {
          code: 'Z6T13',
          displayName: '13',
          className: 'circle circleRed',
        }, {
          code: 'Z6T23',
          displayName: '23',
          className: 'circle circleRed',
        }, {
          code: 'Z6T33',
          displayName: '33',
          className: 'circle circleGreen',
        }, {
          code: 'Z6T4',
          displayName: '04',
          className: 'circle',
        }, {
          code: 'Z6T14',
          displayName: '14',
          className: 'circle',
        }, {
          code: 'Z6T24',
          displayName: '24',
          className: 'circle circleRed',
        }, {
          code: 'Z6T34',
          displayName: '34',
          className: 'circle circleRed',
        }, {
          code: 'Z6T5',
          displayName: '05',
          className: 'circle circleGreen',
        }, {
          code: 'Z6T15',
          displayName: '15',
          className: 'circle',
        }, {
          code: 'Z6T25',
          displayName: '25',
          className: 'circle',
        }, {
          code: 'Z6T35',
          displayName: '35',
          className: 'circle circleRed',
        }, {
          code: 'Z6T6',
          displayName: '06',
          className: 'circle circleGreen',
        }, {
          code: 'Z6T16',
          displayName: '16',
          className: 'circle circleGreen',
        }, {
          code: 'Z6T26',
          displayName: '26',
          className: 'circle',
        }, {
          code: 'Z6T36',
          displayName: '36',
          className: 'circle',
        }, {
          code: 'Z6T7',
          displayName: '07',
          className: 'circle circleRed',
        }, {
          code: 'Z6T17',
          displayName: '17',
          className: 'circle circleGreen',
        }, {
          code: 'Z6T27',
          displayName: '27',
          className: 'circle circleGreen',
        }, {
          code: 'Z6T8',
          displayName: '08',
          className: 'circle circleRed',
          newLine: true,
        }, {
          code: 'Z6T18',
          displayName: '18',
          className: 'circle circleRed',
        }, {
          code: 'Z6T28',
          displayName: '28',
          className: 'circle circleGreen',
        }, {
          code: 'Z6T9',
          displayName: '09',
          className: 'circle',
          newLine: true,
        }, {
          code: 'Z6T19',
          displayName: '19',
          className: 'circle circleRed',
        }, {
          code: 'Z6T29',
          displayName: '29',
          className: 'circle circleRed',
        }, {
          code: 'Z6T10',
          displayName: '10',
          className: 'circle',
          newLine: true,
        }, {
          code: 'Z6T20',
          displayName: '20',
          className: 'circle',
        }, {
          code: 'Z6T30',
          displayName: '30',
          className: 'circle circleRed',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default Z6T;