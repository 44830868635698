import { StatusCodes } from 'http-status-codes';

import { SET_ACTIVE_THIRD_PARTY_GAME_URL, SET_MODAL_ALERT, SET_THIRD_PARTY_BET_RECORDS, SET_THIRD_PARTY_GAMES_OPTIONS, SET_THIRD_PARTY_GAME_AMOUNT } from '../actionTypes';
import { apiService } from '../../../library';
import withdrawActions from '../withdrawActions';

const setThirdpartyBetRecords = topUpOptions => ({
  type: SET_THIRD_PARTY_BET_RECORDS,
  value: topUpOptions,
});

const setThirdPartyGamesOptions = gameOptions => ({
  type: SET_THIRD_PARTY_GAMES_OPTIONS,
  value: gameOptions
});

const setActiveThirdPartyGameURL = gameUrl => ({
  type: SET_ACTIVE_THIRD_PARTY_GAME_URL,
  value: gameUrl
});

const setThirdPartyGameAmountByCode = (gameCode, amount) => ({
  type: SET_THIRD_PARTY_GAME_AMOUNT,
  value: {
    gameCode,
    amount
  }
});

const setModalAlert = modal => ({
  type: SET_MODAL_ALERT,
  value: modal
});

const betHistory = (beginTime = '', endTime = '', orderId = '', gameCode = '', page = 1, rows = 10, settleStatus = -1) => dispatch => {
  apiService.get('/thirdPartyGames/betHistory',
    new URLSearchParams({
      beginTime,
      endTime,
      orderId,
      gameCode,
      page,
      rows,
      settleStatus
    })).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(setThirdpartyBetRecords(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const transfer = () => dispatch => {
  apiService.get('/thirdPartyGames/transfer').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(setThirdPartyGamesOptions(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const autoGamesTransfer = (username, isMobile) => dispatch => {
  apiService.get(`/thirdPartyGames/autoGamesTransfer/${username}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(transfer());
      if (isMobile) {
        dispatch(updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
      }
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const loadAmount = (gameCode, fromSite) => dispatch => {
  apiService.get(`/thirdPartyGames/loadAmount/${gameCode}`,
    new URLSearchParams({
      fromSite
    })).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(setThirdPartyGameAmountByCode(gameCode, data.result));
    }
  }).catch(error => {
    dispatch(setThirdPartyGameAmountByCode(gameCode, error?.response?.data?.msg));
    Promise.reject(error);
  });
};

const LoadMainAmount = gameCode => dispatch => {
  apiService.get('/thirdPartyGames/LoadMainAmount').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(setThirdPartyGameAmountByCode(gameCode, data.result?.mainAmount));
    }
  }).catch(error => {
    dispatch(setThirdPartyGameAmountByCode(gameCode, error?.response?.data?.msg));
    Promise.reject(error);
  });
};

const getGameUrl = (gameCode, fromSite, params1 = '', params2 = '', callback = () => {}) => () => {
  apiService.get(`/thirdPartyGames/GameUrl/${gameCode}`,
    new URLSearchParams({
      fromSite,
      params1,
      params2
    })).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback({
        url: data.result,
        status: 'success'
      });
    }
  }).catch(error => {
    callback({
      msg: error?.response?.data?.msg,
      status: 'error'
    });
    Promise.reject(error);
  });
};

const transferAmount = (fromCode, toCode, acAmount, fromSite, errorCallback, isMobile = false) => dispatch => {
  apiService.post('/thirdPartyGames/transferAmount', {
    fromCode,
    toCode,
    acAmount,
    fromSite
  }).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(transfer());
      if (isMobile) {
        dispatch(updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
      }
    }
  }).catch(error => {
    errorCallback();
    Promise.reject(error);
  });
};

const returnFunds = (username, isMobile = false) => dispatch => {
  apiService.post(`/thirdPartyGames/returnFunds/${username}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(transfer());
      dispatch(withdrawActions.getInfo());
      if (isMobile) {
        dispatch(updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
      }
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const updateGameUrl = () => dispatch => {
  dispatch(setActiveThirdPartyGameURL());
};

const updateModalAlert = modal => dispatch => (
  dispatch(setModalAlert(modal))
);

export default {
  autoGamesTransfer,
  betHistory,
  LoadMainAmount,
  loadAmount,
  returnFunds,
  transfer,
  transferAmount,
  getGameUrl,
  updateGameUrl,
};