import React, { useState } from 'react';

import { Carousel, Tabs } from 'element-react';
import { connect } from 'react-redux';

import { appActions, memberActions } from '../../../../redux/actions';
import AppMockup from '../../../../assets/image/home/app_mockup.png';
import Banner1 from '../../../../assets/image/home/banner_1.jpg';
import Banner2 from '../../../../assets/image/home/banner_2.jpg';
import Banner3 from '../../../../assets/image/home/banner_3.jpg';

import Chrome from '../../../../assets/image/home/browser_chrome.png';
import Firefox from '../../../../assets/image/home/browser_firefox.png';
import IE from '../../../../assets/image/home/browser_ie.png';


import CompanyLogo1 from '../../../../assets/image/home/companylogo_1.png';
import CompanyLogo2 from '../../../../assets/image/home/companylogo_2.png';
import CompanyLogo3 from '../../../../assets/image/home/companylogo_3.png';

import Games1 from '../../../../assets/image/home/games_1.png';
import Games2 from '../../../../assets/image/home/games_2.png';
import Games3 from '../../../../assets/image/home/games_3.png';
import Games4 from '../../../../assets/image/home/games_4.png';
import Games5 from '../../../../assets/image/home/games_5.png';
import Games6 from '../../../../assets/image/home/games_6.png';
import Games7 from '../../../../assets/image/home/games_7.png';
import Games8 from '../../../../assets/image/home/games_8.png';
import Games9 from '../../../../assets/image/home/games_9.png';

import IconApp from '../../../../assets/image/home/icon_app.png';
// import Logo from '../../../../assets/logo/homeLogo.png';
import NavActive from '../../../../assets/image/home/nav_active.png';
import QrAndriod from '../../../../assets/image/home/qr_android.png';

import Payment1 from '../../../../assets/image/home/payment_1.png';
import Payment2 from '../../../../assets/image/home/payment_2.png';
import Payment3 from '../../../../assets/image/home/payment_3.png';
import Payment4 from '../../../../assets/image/home/payment_4.png';

import TopGanmes1 from '../../../../assets/image/home/topgames_1.jpg';
import TopGanmes2 from '../../../../assets/image/home/topgames_2.jpg';
import TopGanmes3 from '../../../../assets/image/home/topgames_3.jpg';
import TopGanmes4 from '../../../../assets/image/home/topgames_4.jpg';


import QrIos from '../../../../assets/image/home/qr_ios.png';

import 'element-theme-default';
import './styles.scss';

const mapDispatchToProps = dispatch => ({
  login: (username, password) => dispatch(memberActions.login(username, password)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const DJH = props => {
  const [gameIndex, setGameIndex] = useState(0);

  const { updateModalAlert } = props || {};

  const [selectedTopGame, setSelectedTopGame] = useState('js');


  const topGames = [{
    name: 'js',
    label: '极速彩',
    leftImage: TopGanmes1,
    lotteryDesc: '本应用是一款彩票开奖资讯类应用，结合当前最全彩票种类，包含彩票开奖信息，统计数据等多种功能为一体本应用是一款彩票开奖资讯类应用，结合当前最全彩票种类，包含彩票开奖信息，统计数据等多种功能为一体。',
    lotteryList: [{
      img: Games1, name: '极速飞车'
    }, {
      img: Games2, name: '极速分分彩'
    }]
  }, {
    name: 'xy',
    label: '幸运彩',
    leftImage: TopGanmes2,
    lotteryDesc: '本应用是一款彩票开奖资讯类应用，结合当前最全彩票种类，包含彩票开奖信息，统计数据等多种功能为一体。',
    lotteryList: [{
      img: Games3, name: '幸运赛车'
    }, {
      img: Games4, name: '幸运时时彩'
    }]
  }, {
    name: 'lh',
    label: '六合彩',
    leftImage: TopGanmes3,
    lotteryDesc: '本应用是一款彩票开奖资讯类应用，结合当前最全彩票种类，包含彩票开奖信息，统计数据等多种功能为一体。',
    lotteryList: [{
      img: Games5, name: '香港六合彩'
    }, {
      img: Games6, name: '澳门六合彩'
    }, {
      img: Games7, name: '台湾大乐透'
    }]
  }, {
    name: 'ft',
    label: '番摊',
    leftImage: TopGanmes4,
    lotteryDesc: '本应用是一款彩票开奖资讯类应用，结合当前最全彩票种类，包含彩票开奖信息，统计数据等多种功能为一体。',
    lotteryList: [{
      img: Games8, name: '赛车番摊'
    }, {
      img: Games9, name: '时时彩番摊'
    }]
  }];

  return (
    <div className="homePageWebWrapper">
      <div className="homeHeader">
        {/* <img className="logoWrapper" src={Logo} alt="logo" srcSet="" /> */}
        <div className="logoText">DJH</div>
        <div className="d-flex gameWrapper">
          {['首页', '体育', '棋牌', '真人'].map((item, index) => (
            <div onClick={() => setGameIndex(index)} className={`gameItem ${gameIndex === index && 'selected'}`}>
              <div>{item}</div>
              <img src={NavActive} alt="nav" srcSet="" />
            </div>
          ))}
        </div>
        <div className="d-flex serviceWrapper">
          {['优惠', '加盟', '客服', '常见问题', 'APP'].map((item, index) => (
            <div onClick={() => setGameIndex(index + 4)} className={`serviceItem ${gameIndex === (index + 4) && 'selected'}`}>
              <div>{item}</div>
              <img src={NavActive} alt="nav" srcSet="" />
            </div>
          ))}
        </div>
        <div
          className="d-flex homeloginAndRegister"
          onClick={() => {
            updateModalAlert({
              visible: true,
              type: 'loginAndRegister',
            });
          }}
        >
          <div>登入</div>
          /
          <div>注册</div>
        </div>
      </div>
      <div className="bannerWrapper">
        <Carousel trigger="click" className="carouselStyle">
          {
            [Banner1, Banner2, Banner3].map((item, index) => (
              <Carousel.Item key={`${index.toString()}`}>
                <div><img className="bannerImage" src={item} alt="banner" /></div>
              </Carousel.Item>
            ))
          }
        </Carousel>
      </div>
      <div className="homeContentWrapper">
        <div className="noticeWrapper">
          <div>尊敬的客户：由于重庆幸运农场暂无开奖数据或开奖延误，我司将暂时关闭该彩种投注，待官网开奖稳定后再重新开放,为此对您带来的不便敬请谅解</div>
          <div className="btnMore">更多</div>
        </div>
        <div className="contentHeader">
          <div className="lineAndDot">
            <div className="line" />
            <div className="circleDot" />
          </div>
          <div className="mx-5">
            <div className="topTitle">APP下载</div>
            <div className="subTitle">APP DOWNLOAD</div>
          </div>
          <div className="lineAndDot">
            <div className="circleDot" />
            <div className="line" />
          </div>
        </div>

        <div className="appWrapper d-flex">
          <div className="w-50">
            <div className="d-flex align-items-center">
              <img src={IconApp} alt="iconApp" srcSet="" />
              <div className="appTitle">全站APP</div>
            </div>
            <div className="appDesc">全球首家一体化娱乐原生APP，尽显流畅，完美操作。海量体育， 电竞顶尖赛车，真人娱乐，彩票投注及电子游戏等，最新最全娱乐项目尽在掌中，体验扫码下载，即刻拥有</div>
            <div className="d-flex justify-content-center">
              <img src={QrAndriod} className="qrImg" alt="android" srcSet="" />
              <img src={QrIos} className="qrImg" alt="ios" srcSet="" />
            </div>
          </div>
          <div className="w-50">
            <img className="w-100" src={AppMockup} alt="app" srcSet="" />
          </div>
        </div>

        <div className="contentHeader">
          <div className="lineAndDot">
            <div className="line" />
            <div className="circleDot" />
          </div>
          <div className="mx-5">
            <div className="topTitle">热门彩种</div>
            <div className="subTitle">TOP GAMES</div>
          </div>
          <div className="lineAndDot">
            <div className="circleDot" />
            <div className="line" />
          </div>
        </div>

        <div className="topGameWrapper">
          <Tabs type="border-card" activeName={selectedTopGame} onTabClick={tab => setSelectedTopGame(tab.props.name)}>
            {
              topGames.map(item => (
                <Tabs.Pane label={item.label} name={item.name}>
                  <div className="d-flex w-100">
                    <div className="leftPart"><img src={item.leftImage} alt="leftImg" srcSet="" /></div>
                    <div className="rightPart">
                      <div className="categoryName">{item.label}</div>
                      <div className="categoryDesc">{item.lotteryDesc}</div>
                      <div className="d-flex lottertList">
                        {item.lotteryList.map(item => (
                          <div>
                            <img src={item.img} alt="img" srcSet="" />
                            <div className="lotteryName">{item.name}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Tabs.Pane>
              ))
            }
          </Tabs>
        </div>

        <div className="contentHeader">
          <div className="lineAndDot">
            <div className="line" />
            <div className="circleDot" />
          </div>
          <div className="mx-5">
            <div className="topTitle">常见问题</div>
            <div className="subTitle">FAQ</div>
          </div>
          <div className="lineAndDot">
            <div className="circleDot" />
            <div className="line" />
          </div>
        </div>
        <div className="quesyionWrapper">
          {[{
            question: '如何加入星辉科技呢？', answer: '您可以直接点击注册，填写资料后，即可登记成为星辉科技会员。'
          }, {
            question: '我在哪里可以找到活动规则？', answer: '在登陆后，您可以在游戏右上看到‘活动规则’选项，清楚的告诉您游戏的玩法、规则及派彩方式。'
          }, {
            question: '如何提款？', answer: '提款时请先确保金额不是处于冻结状态，最低提款金额为100元。任何一笔存款或返水最少需要一倍任意游戏才能进行提款，优惠红利则需按优惠规则限定的流水倍数打完流水，否则提款将无法通过。提款失败者请到交易记录页面查询原因。'
          }].map((item, index) => (
            <div className="questionSection" key={`question_${index.toString()}`}>
              <div className="d-flex">
                <div className="qAndA">问：</div>
                <div>{item.question}</div>
              </div>
              <div className="d-flex">
                <div className="qAndA">答：</div>
                <div>{item.answer}</div>
              </div>
            </div>
          ))}
          <div className="reminderWrapper">星辉科技提醒您：理性购彩，热心公益。未满18周岁的未成年人禁止购彩及兑奖!</div>
        </div>
      </div>
      <div className="homeFooterWrapper">
        <div className="d-flex companyWrapper">{[CompanyLogo1, CompanyLogo2, CompanyLogo3].map(item => <img src={item} alt="companyLogo" srcSet="" />)}</div>
        <div className="d-flex paymentWrapper">{[Payment1, Payment2, Payment3, Payment4].map(item => <img src={item} alt="paymentLogo" srcSet="" />)}</div>
        <div className="d-flex infoAndBrowserWrapper">
          <div className="infoWrapper">
            {['关于我们', '联系我们', '合作伙伴', '常见问题'].map(item => <div>{item}</div>)}
          </div>
          <div className="d-flex browserWrapper">{[Chrome, Firefox, IE].map(item => <img src={item} alt="browserLogo" srcSet="" />)}</div>
        </div>
        <div className="copyRightWrapper">
          Copyright@2022 星辉科技
        </div>

      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(DJH);