import React, { useEffect, useState } from 'react';

import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import { BsFillCircleFill } from 'react-icons/bs';
import { Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { cartActions, gameActions } from '../../../../redux/actions';

import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  betTypeSelection: state.gameReducers.betTypeSelection,
  sectionSelection: state.gameReducers.sectionSelection,
});

const mapDispatchToProps = dispatch => ({
  updateBetTypeSelection: item => dispatch(gameActions.updateBetTypeSelection(item)),
  updateSelectedDan: dan => dispatch(gameActions.updateSelectedDan(dan)),
  updateCurrentSelected: val => dispatch(gameActions.updateCurrentSelected(val)),
  resetItemInCart: () => dispatch(cartActions.resetItemInCart()),
});

const BetTypeContainer = ({ updateBetTypeSelection, betTypeSelection, sectionSelection, updateSelectedDan, updateCurrentSelected, resetItemInCart }) => {
  const { t } = useTranslation();
  const [_betType, setBetType] = useState('LP');
  const [_selectedDan, setSelectedDan] = useState('1');
  const [opened, toggleOpened] = useState(false);

  useEffect(() => {
    if (sectionSelection) {
      toggleOpened(false);
      updateBetTypeSelection();
      if (sectionSelection && sectionSelection.danList && sectionSelection.danList.length > 0) {
        setSelectedDan(sectionSelection.danList[0]);
      }
    }
  }, [sectionSelection]);

  useEffect(() => {
    if (!betTypeSelection) {
      updateBetTypeSelection({
        value: t('LP')
      });
    } else {
      setBetType(betTypeSelection.value);
    }
  }, [betTypeSelection]);

  return (
    <Row className="betTypeContainerWrapper">
      <div
        className="d-flex justify-content-center"
        onClick={() => {
          updateCurrentSelected([]);
          resetItemInCart();
          updateSelectedDan([]);
          updateBetTypeSelection({
            value: t('LP')
          });
        }}
      >
        <span className="optionWrapper active">
          {
            _betType === t('LP') && (
              <BsFillCircleFill className="active" />
            )
          }
        </span>
        <span>{t('LP')}</span>
      </div>
      <div
        className="d-flex justify-content-center ml-2"
        onClick={() => {
          updateCurrentSelected([]);
          resetItemInCart();
          updateSelectedDan([]);
          updateBetTypeSelection({
            value: t('DT'),
            selectedDan: sectionSelection && sectionSelection.danList[0]
          });
        }}
      >
        <span className="optionWrapper active">
          {
            _betType === t('DT') && (
              <BsFillCircleFill className="active" />
            )
          }
        </span>
        <span>{t('DT')}</span>
      </div>
      {
        _betType === t('DT') && (
          <div className="d-flex justify-content-center ml-3">
            <span>{t('DTSZ')}</span>
            <div className="drawer-container d-flex align-items-center">
              <span className="dtText ml-2">
                {_selectedDan}
                {t('DAN')}
              </span>
              <span className="border rounded  px-1 mx-2 squareBtn d-flex align-items-center" onClick={() => toggleOpened(!opened)}>
                {
                  opened ? <IoChevronBackOutline /> : <IoChevronForwardOutline />
                }
              </span>
              <div className={`${opened ? 'containerDrawerOpend' : 'containerDrawerClosed'}`}>
                {
                  sectionSelection && sectionSelection.danList.map((item, index) => (
                    <span
                      key={`dan_${index.toString()}`}
                      className={`border rounded squareBtn px-1 mx-1 ${item === _selectedDan && 'optionSelected'}`}
                      onClick={() => {
                        updateBetTypeSelection({
                          value: _betType, selectedDan: item
                        });
                        setSelectedDan(`${item}`);
                        toggleOpened(!opened);
                      }}
                    >
                      {`${item}`}
                    </span>
                  ))
                }
              </div>
            </div>
          </div>
        )
      }
    </Row>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BetTypeContainer);