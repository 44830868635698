import BALANCE_MANAGEMENT from '../../assets/image/profileDrawer/ico_u3.png';
import BET_REPORT from '../../assets/image/profileDrawer/ico_u6.png';
import CHANGE_THEME from '../../assets/image/profileDrawer/ico_u18.png';
import CO_OP from '../../assets/image/profileDrawer/ico_u8.png';
import FAQ from '../../assets/image/profileDrawer/ico_u10.png';
import GAME_LOBBY from '../../assets/image/profileDrawer/ico_u1.png';
import INBOX from '../../assets/image/profileDrawer/ico_u12.png';
import INFO from '../../assets/image/profileDrawer/ico_u13.png';
import MOBILE_LOGOUT from '../../assets/image/profileDrawer/ico_u14.png';
import PROFILE_DRAWER_REGISTER from '../../assets/image/profileDrawer/ico_u2.png';
import PROMOTION from '../../assets/image/profileDrawer/ico_u9.png';
import RESULT from '../../assets/image/profileDrawer/ico_u17.png';
import RULES from '../../assets/image/profileDrawer/ico_u11.png';
import SETTLED from '../../assets/image/profileDrawer/ico_u5.png';
import THIRD_PARTY_RECORDS from '../../assets/image/profileDrawer/ico_u7.png';
import TRANSFER_BALANCE from '../../assets/image/profileDrawer/ico_u15.png';
import UNSETTLED from '../../assets/image/profileDrawer/ico_u4.png';
import VOUCHER_REDEMPTION from '../../assets/image/profileDrawer/ico_u16.png';

import PROFILE_PICTURE from '../../assets/image/profileDrawer/ico_ren.png';

export default {
  GAME_LOBBY,
  PROFILE_DRAWER_REGISTER,
  BALANCE_MANAGEMENT,
  UNSETTLED,
  SETTLED,
  BET_REPORT,
  THIRD_PARTY_RECORDS,
  CO_OP,
  PROMOTION,
  FAQ,
  RULES,
  INBOX,
  INFO,
  MOBILE_LOGOUT,
  TRANSFER_BALANCE,
  VOUCHER_REDEMPTION,
  RESULT,
  CHANGE_THEME,
  PROFILE_PICTURE
};