import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import { gameActions } from '../../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  sectionSelection: state.gameReducers.sectionSelection,
  selectedSubType: state.gameReducers.selectedSubType,
});

const mapDispatchToProps = dispatch => ({
  updateSectionSelection: selection => dispatch(gameActions.updateSectionSelection(selection)),
  updateBetTypeSelection: item => dispatch(gameActions.updateBetTypeSelection(item)),
});

const TeMaAndLianMa = ({ list, updateBetTypeSelection, updateSectionSelection, sectionSelection, selectedSubType }) => {
  useEffect(() => {
    updateSectionSelection({
      ...list[0]
    });
    return () => { updateSectionSelection(); updateBetTypeSelection(); };
  }, [selectedSubType]);

  const onClickTeMaAndLianMa = item => {
    updateSectionSelection({
      ...item
    });
  };

  return (
    <div className="TemaAndLianMaWrapper">
      <div className="scrollRow">
        <div className="d-flex">
          {
            list.map(item => <span onClick={() => onClickTeMaAndLianMa(item)} className={`TeMaAndLianMaBtn ${sectionSelection?.code === item.code && 'selected'}`}>{item.displayName}</span>)
          }
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TeMaAndLianMa);