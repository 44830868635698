import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';


const HM4 = [
  {
    componentType: CollapseType,
    header: '第四球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'B4QH1',
          displayName: '1',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B4QH2',
          displayName: '2',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B4QH3',
          displayName: '3',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B4QH4',
          displayName: '4',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B4QH5',
          displayName: '5',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B4QH6',
          displayName: '6',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B4QH7',
          displayName: '7',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B4QH8',
          displayName: '8',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B4QH9',
          displayName: '9',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B4QH10',
          displayName: '10',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B4QH11',
          displayName: '11',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B4QH12',
          displayName: '12',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B4QH13',
          displayName: '13',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B4QH14',
          displayName: '14',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B4QH15',
          displayName: '15',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B4QH16',
          displayName: '16',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B4QH17',
          displayName: '17',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B4QH18',
          displayName: '18',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B4QH19',
          displayName: '19',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B4QH20',
          displayName: '20',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B4QH21',
          displayName: '21',
          drawImgClassName: 'circle circleGreen',
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '色波，福禄寿喜，两面',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'B4LM1',
          displayName: '大',
        }, {
          code: 'B4LM2',
          displayName: '小',
        }, {
          code: 'B4LM3',
          displayName: '单',
        }, {
          code: 'B4LM4',
          displayName: '双',
        }, {
          code: 'B4LM5',
          displayName: '尾大',
        }, {
          code: 'B4LM6',
          displayName: '尾小',
        }, {
          code: 'B4LM7',
          displayName: '合单',
        }, {
          code: 'B4LM8',
          displayName: '合双',
        }, {
          code: 'B4QH22',
          displayName: '红',
        }, {
          code: 'B4QH23',
          displayName: '绿',
        }, {
          code: 'B4QH24',
          displayName: '蓝',
        }, {
          code: 'B4QH25',
          displayName: '福',
        }, {
          code: 'B4QH26',
          displayName: '禄',
        }, {
          code: 'B4QH27',
          displayName: '寿',
        }, {
          code: 'B4QH28',
          displayName: '喜',
        },
      ]
    }
  },
];

export default HM4;