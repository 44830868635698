import { BetTypeContainer, CheckBox, MultipleTypeSelectionPanel, Odds } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const L7T4 = [
  {
    componentType: QuickBet,
  },
  {
    componentType: MultipleTypeSelectionPanel,
    data: {
      list: [
        {
          rowTitle: '类别',
          rowTitleClassName: 'text-center',
          rowType: CheckBox,
          wrapperStyle: 'checkBoxRow F36X7',
          onCheckBoxChange: (updateSectionSelection, subItem) => {
            updateSectionSelection(subItem);
          },
          rowData: [{
            code: 'L7TZ41',
            displayName: '七特中',
            minimumSelection: 7,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5', '6'],
          }]
        },
        {
          rowTitle: '赔率',
          rowTitleClassName: 'text-center',
          rowType: Odds,
          wrapperStyle: 'oddsRow F36X7_3Z2',
          rowData: [{
            code: 'L7TZ41',
            displayName: [
              {
                code: 'L7TZ41', displayName: '中四', className: 'd-flex justify-content-center align-items-center border-right h-100 flex-grow-1'
              }, {
                code: 'L7TZ5ZT1', displayName: '五中特', className: 'd-flex justify-content-center align-items-center  border-right h-100 flex-grow-1'
              }, {
                code: 'L7TZ51', displayName: '中五', className: 'd-flex justify-content-center align-items-center border-right h-100 flex-grow-1'
              }, {
                code: 'L7TZ6ZT1', displayName: '六中特', className: 'd-flex justify-content-center align-items-center  border-right h-100 flex-grow-1'
              }, {
                code: 'L7TZ61', displayName: '中六', className: 'd-flex justify-content-center align-items-center border-right h-100 flex-grow-1'
              }, {
                code: 'L7TZ7ZT1', displayName: '七中特', className: 'd-flex justify-content-center align-items-center  border-right h-100 flex-grow-1'
              }, {
                code: 'L7TZ71', displayName: '中七', className: 'd-flex justify-content-center align-items-center h-100 flex-grow-1'
              }
            ],
            className: 'chooseBetOdds p-0',
          }]
        },
        {
          rowTitleClassName: 'p-2 text-center',
          rowType: BetTypeContainer,
          wrapperStyle: 'p-2',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['生肖', '勾选', '号码'],
      mainColumnLength: 4,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-checkbox'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-checkbox'],
      emptyItemStyle: ['column-3items-empty-label', 'column-3items-empty-odds', 'column-3items-empty-checkbox'],
      betItemList: ['DisplayText', 'Odds', 'CheckBox'],
      betItemColumn: 3,
      list: [
        {
          code: 'L7TZ41',
          displayName: '01',
          className: 'circle circleRed',
        }, {
          code: 'L7TZ411',
          displayName: '11',
          className: 'circle circleGreen',
        }, {
          code: 'L7TZ421',
          displayName: '21',
          className: 'circle circleGreen',
        }, {
          code: 'L7TZ431',
          displayName: '31',
          className: 'circle circleBlue',
        }, {
          code: 'L7TZ42',
          displayName: '02',
          className: 'circle circleRed',
        }, {
          code: 'L7TZ412',
          displayName: '12',
          className: 'circle circleRed',
        }, {
          code: 'L7TZ422',
          displayName: '22',
          className: 'circle circleGreen',
        }, {
          code: 'L7TZ432',
          displayName: '32',
          className: 'circle circleGreen',
        }, {
          code: 'L7TZ43',
          displayName: '03',
          className: 'circle circleBlue',
        }, {
          code: 'L7TZ413',
          displayName: '13',
          className: 'circle circleRed',
        }, {
          code: 'L7TZ423',
          displayName: '23',
          className: 'circle circleRed',
        }, {
          code: 'L7TZ433',
          displayName: '33',
          className: 'circle circleGreen',
        }, {
          code: 'L7TZ44',
          displayName: '04',
          className: 'circle',
        }, {
          code: 'L7TZ414',
          displayName: '14',
          className: 'circle',
        }, {
          code: 'L7TZ424',
          displayName: '24',
          className: 'circle circleRed',
        }, {
          code: 'L7TZ434',
          displayName: '34',
          className: 'circle circleRed',
        }, {
          code: 'L7TZ45',
          displayName: '05',
          className: 'circle circleGreen',
        }, {
          code: 'L7TZ415',
          displayName: '15',
          className: 'circle',
        }, {
          code: 'L7TZ425',
          displayName: '25',
          className: 'circle',
        }, {
          code: 'L7TZ435',
          displayName: '35',
          className: 'circle circleRed',
        }, {
          code: 'L7TZ46',
          displayName: '06',
          className: 'circle circleGreen',
        }, {
          code: 'L7TZ416',
          displayName: '16',
          className: 'circle circleGreen',
        }, {
          code: 'L7TZ426',
          displayName: '26',
          className: 'circle',
        }, {
          code: 'L7TZ436',
          displayName: '36',
          className: 'circle',
        }, {
          code: 'L7TZ47',
          displayName: '07',
          className: 'circle circleRed',
        }, {
          code: 'L7TZ417',
          displayName: '17',
          className: 'circle circleGreen',
        }, {
          code: 'L7TZ427',
          displayName: '27',
          className: 'circle circleGreen',
        }, {
          code: 'L7TZ48',
          displayName: '08',
          className: 'circle circleRed',
          newLine: true,
        }, {
          code: 'L7TZ418',
          displayName: '18',
          className: 'circle circleRed',
        }, {
          code: 'L7TZ428',
          displayName: '28',
          className: 'circle circleGreen',
        }, {
          code: 'L7TZ49',
          displayName: '09',
          className: 'circle',
          newLine: true,
        }, {
          code: 'L7TZ419',
          displayName: '19',
          className: 'circle circleRed',
        }, {
          code: 'L7TZ429',
          displayName: '29',
          className: 'circle circleRed',
        }, {
          code: 'L7TZ410',
          displayName: '10',
          className: 'circle',
          newLine: true,
        }, {
          code: 'L7TZ420',
          displayName: '20',
          className: 'circle',
        }, {
          code: 'L7TZ430',
          displayName: '30',
          className: 'circle circleRed',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default L7T4;