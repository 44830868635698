import QuickBet from '../../../../components/Web/Content/QuickBet';
import TabContainer from '../../../../components/Web/TabContainer';
import Template2 from '../../../../components/GameTemplates/Template2';

const ZMT = [
  {
    componentType: QuickBet,
  },
  {
    componentType: TabContainer,
    data: {
      navMenu: [{
        label: '正一特',
        value: 'ZMT1'
      }, {
        label: '正二特',
        value: 'ZMT2'
      }, {
        label: '正三特',
        value: 'ZMT3'
      }, {
        label: '正四特',
        value: 'ZMT4'
      }, {
        label: '正五特',
        value: 'ZMT5'
      }, {
        label: '正六特',
        value: 'ZMT6'
      }],
      content: [{
        key: 'ZMT1',
        children: [{
          componentType: Template2,
          data: {
            cycleHeaderTitle: true,
            tableHeadertitle: ['号码', '赔率', '金额'],
            mainColumnLength: 5,
            subColumnLength: 3,
            subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            emptyItemStyle: ['column-3items-empty-label', 'column-3items-empty-odds', 'column-3items-empty-input'],
            betItemList: ['DisplayText', 'Odds', 'Input'],
            betItemColumn: 3,
            list: [
              {
                code: 'ZMT11',
                displayName: '01',
                className: 'circle circleRed',
              }, {
                code: 'ZMT111',
                displayName: '11',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT121',
                displayName: '21',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT131',
                displayName: '31',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT141',
                displayName: '41',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT12',
                displayName: '02',
                className: 'circle circleRed',
              }, {
                code: 'ZMT112',
                displayName: '12',
                className: 'circle circleRed',
              }, {
                code: 'ZMT122',
                displayName: '22',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT132',
                displayName: '32',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT142',
                displayName: '42',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT13',
                displayName: '03',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT113',
                displayName: '13',
                className: 'circle circleRed',
              }, {
                code: 'ZMT123',
                displayName: '23',
                className: 'circle circleRed',
              }, {
                code: 'ZMT133',
                displayName: '33',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT143',
                displayName: '43',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT14',
                displayName: '04',
                className: 'circle',
              }, {
                code: 'ZMT114',
                displayName: '14',
                className: 'circle',
              }, {
                code: 'ZMT124',
                displayName: '24',
                className: 'circle circleRed',
              }, {
                code: 'ZMT134',
                displayName: '34',
                className: 'circle circleRed',
              }, {
                code: 'ZMT144',
                displayName: '44',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT15',
                displayName: '05',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT115',
                displayName: '15',
                className: 'circle',
              }, {
                code: 'ZMT125',
                displayName: '25',
                className: 'circle',
              }, {
                code: 'ZMT135',
                displayName: '35',
                className: 'circle circleRed',
              }, {
                code: 'ZMT145',
                displayName: '45',
                className: 'circle circleRed',
              }, {
                code: 'ZMT16',
                displayName: '06',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT116',
                displayName: '16',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT126',
                displayName: '26',
                className: 'circle',
              }, {
                code: 'ZMT136',
                displayName: '36',
                className: 'circle',
              }, {
                code: 'ZMT146',
                displayName: '46',
                className: 'circle circleRed',
              }, {
                code: 'ZMT17',
                displayName: '07',
                className: 'circle circleRed',
              }, {
                code: 'ZMT117',
                displayName: '17',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT127',
                displayName: '27',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT137',
                displayName: '37',
                className: 'circle',
              }, {
                code: 'ZMT147',
                displayName: '47',
                className: 'circle',
              }, {
                code: 'ZMT18',
                displayName: '08',
                className: 'circle circleRed',
              }, {
                code: 'ZMT118',
                displayName: '18',
                className: 'circle circleRed',
              }, {
                code: 'ZMT128',
                displayName: '28',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT138',
                displayName: '38',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT148',
                displayName: '48',
                className: 'circle',
              }, {
                code: 'ZMT19',
                displayName: '09',
                className: 'circle',
              }, {
                code: 'ZMT119',
                displayName: '19',
                className: 'circle circleRed',
              }, {
                code: 'ZMT129',
                displayName: '29',
                className: 'circle circleRed',
              }, {
                code: 'ZMT139',
                displayName: '39',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT149',
                displayName: '49',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT110',
                displayName: '10',
                className: 'circle',
              }, {
                code: 'ZMT120',
                displayName: '20',
                className: 'circle',
              }, {
                code: 'ZMT130',
                displayName: '30',
                className: 'circle circleRed',
              }, {
                code: 'ZMT140',
                displayName: '40',
                className: 'circle circleRed',
              }
            ]
          }
        }, {
          componentType: Template2,
          data: {
            cycleHeaderTitle: true,
            tableHeadertitle: ['号码', '赔率', '金额'],
            mainColumnLength: 5,
            subColumnLength: 3,
            subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemList: ['DisplayText', 'Odds', 'Input'],
            betItemColumn: 3,
            list: [
              {
                code: 'ZM1LM1',
                displayName: '正1-单',
              }, {
                code: 'ZM1LM3',
                displayName: '正1-大',
              }, {
                code: 'ZM1LM5',
                displayName: '正1-合单',
              }, {
                code: 'ZM1LM7',
                displayName: '正1-合大',
              }, {
                code: 'ZM1LM9',
                displayName: '正1-尾大',
              }, {
                code: 'ZM1LM2',
                displayName: '正1-双',
              }, {
                code: 'ZM1LM4',
                displayName: '正1-小',
              }, {
                code: 'ZM1LM6',
                displayName: '正1-合双',
              }, {
                code: 'ZM1LM8',
                displayName: '正1-合小',
              }, {
                code: 'ZM1LM10',
                displayName: '正1-尾小',
              }, {
                code: 'ZM1SB11',
                displayName: '正1-红波',
                className: 'fillRed',
              }, {
                code: 'ZM1SB12',
                displayName: '正1-蓝波',
                className: 'fillBlue',
              }, {
                code: 'ZM1SB13',
                displayName: '正1-绿波',
                className: 'fillGreen',
              }
            ]
          }
        }]
      }, {
        key: 'ZMT2',
        children: [{
          componentType: Template2,
          data: {
            cycleHeaderTitle: true,
            tableHeadertitle: ['号码', '赔率', '金额'],
            mainColumnLength: 5,
            subColumnLength: 3,
            subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemList: ['DisplayText', 'Odds', 'Input'],
            betItemColumn: 3,
            list: [
              {
                code: 'ZMT21',
                displayName: '01',
                className: 'circle circleRed',
              }, {
                code: 'ZMT211',
                displayName: '11',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT221',
                displayName: '21',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT231',
                displayName: '31',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT241',
                displayName: '41',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT22',
                displayName: '02',
                className: 'circle circleRed',
              }, {
                code: 'ZMT212',
                displayName: '12',
                className: 'circle circleRed',
              }, {
                code: 'ZMT222',
                displayName: '22',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT232',
                displayName: '32',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT242',
                displayName: '42',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT23',
                displayName: '03',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT213',
                displayName: '13',
                className: 'circle circleRed',
              }, {
                code: 'ZMT223',
                displayName: '23',
                className: 'circle circleRed',
              }, {
                code: 'ZMT233',
                displayName: '33',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT243',
                displayName: '43',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT24',
                displayName: '04',
                className: 'circle',
              }, {
                code: 'ZMT214',
                displayName: '14',
                className: 'circle',
              }, {
                code: 'ZMT224',
                displayName: '24',
                className: 'circle circleRed',
              }, {
                code: 'ZMT234',
                displayName: '34',
                className: 'circle circleRed',
              }, {
                code: 'ZMT244',
                displayName: '44',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT25',
                displayName: '05',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT215',
                displayName: '15',
                className: 'circle',
              }, {
                code: 'ZMT225',
                displayName: '25',
                className: 'circle',
              }, {
                code: 'ZMT235',
                displayName: '35',
                className: 'circle circleRed',
              }, {
                code: 'ZMT245',
                displayName: '45',
                className: 'circle circleRed',
              }, {
                code: 'ZMT26',
                displayName: '06',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT216',
                displayName: '16',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT226',
                displayName: '26',
                className: 'circle',
              }, {
                code: 'ZMT236',
                displayName: '36',
                className: 'circle',
              }, {
                code: 'ZMT246',
                displayName: '46',
                className: 'circle circleRed',
              }, {
                code: 'ZMT27',
                displayName: '07',
                className: 'circle circleRed',
              }, {
                code: 'ZMT217',
                displayName: '17',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT227',
                displayName: '27',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT237',
                displayName: '37',
                className: 'circle',
              }, {
                code: 'ZMT247',
                displayName: '47',
                className: 'circle',
              }, {
                code: 'ZMT28',
                displayName: '08',
                className: 'circle circleRed',
              }, {
                code: 'ZMT218',
                displayName: '18',
                className: 'circle circleRed',
              }, {
                code: 'ZMT228',
                displayName: '28',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT238',
                displayName: '38',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT248',
                displayName: '48',
                className: 'circle',
              }, {
                code: 'ZMT29',
                displayName: '09',
                className: 'circle',
              }, {
                code: 'ZMT219',
                displayName: '19',
                className: 'circle circleRed',
              }, {
                code: 'ZMT229',
                displayName: '29',
                className: 'circle circleRed',
              }, {
                code: 'ZMT239',
                displayName: '39',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT249',
                displayName: '49',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT210',
                displayName: '10',
                className: 'circle',
              }, {
                code: 'ZMT220',
                displayName: '20',
                className: 'circle',
              }, {
                code: 'ZMT230',
                displayName: '30',
                className: 'circle circleRed',
              }, {
                code: 'ZMT240',
                displayName: '40',
                className: 'circle circleRed',
              }
            ]
          }
        }, {
          componentType: Template2,
          data: {
            cycleHeaderTitle: true,
            tableHeadertitle: ['号码', '赔率', '金额'],
            mainColumnLength: 5,
            subColumnLength: 3,
            subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemList: ['DisplayText', 'Odds', 'Input'],
            betItemColumn: 3,
            list: [
              {
                code: 'ZM2LM1',
                displayName: '正2-单',
              }, {
                code: 'ZM2LM3',
                displayName: '正2-大',
              }, {
                code: 'ZM2LM5',
                displayName: '正2-合单',
              }, {
                code: 'ZM2LM7',
                displayName: '正2-合大',
              }, {
                code: 'ZM2LM9',
                displayName: '正2-尾大',
              }, {
                code: 'ZM2LM2',
                displayName: '正2-双',
              }, {
                code: 'ZM2LM4',
                displayName: '正2-小',
              }, {
                code: 'ZM2LM6',
                displayName: '正2-合双',
              }, {
                code: 'ZM2LM8',
                displayName: '正2-合小',
              }, {
                code: 'ZM2LM10',
                displayName: '正2-尾小',
              }, {
                code: 'ZM2SB11',
                displayName: '正2-红波',
                className: 'fillRed',
              }, {
                code: 'ZM2SB12',
                displayName: '正2-蓝波',
                className: 'fillBlue',
              }, {
                code: 'ZM2SB13',
                displayName: '正2-绿波',
                className: 'fillGreen',
              }
            ]
          }
        }]
      }, {
        key: 'ZMT3',
        children: [{
          componentType: Template2,
          data: {
            cycleHeaderTitle: true,
            tableHeadertitle: ['号码', '赔率', '金额'],
            mainColumnLength: 5,
            subColumnLength: 3,
            subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemList: ['DisplayText', 'Odds', 'Input'],
            betItemColumn: 3,
            list: [
              {
                code: 'ZMT31',
                displayName: '01',
                className: 'circle circleRed',
              }, {
                code: 'ZMT311',
                displayName: '11',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT321',
                displayName: '21',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT331',
                displayName: '31',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT341',
                displayName: '41',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT32',
                displayName: '02',
                className: 'circle circleRed',
              }, {
                code: 'ZMT312',
                displayName: '12',
                className: 'circle circleRed',
              }, {
                code: 'ZMT322',
                displayName: '22',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT332',
                displayName: '32',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT342',
                displayName: '42',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT33',
                displayName: '03',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT313',
                displayName: '13',
                className: 'circle circleRed',
              }, {
                code: 'ZMT323',
                displayName: '23',
                className: 'circle circleRed',
              }, {
                code: 'ZMT333',
                displayName: '33',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT343',
                displayName: '43',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT34',
                displayName: '04',
                className: 'circle',
              }, {
                code: 'ZMT314',
                displayName: '14',
                className: 'circle',
              }, {
                code: 'ZMT324',
                displayName: '24',
                className: 'circle circleRed',
              }, {
                code: 'ZMT334',
                displayName: '34',
                className: 'circle circleRed',
              }, {
                code: 'ZMT344',
                displayName: '44',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT35',
                displayName: '05',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT315',
                displayName: '15',
                className: 'circle',
              }, {
                code: 'ZMT325',
                displayName: '25',
                className: 'circle',
              }, {
                code: 'ZMT335',
                displayName: '35',
                className: 'circle circleRed',
              }, {
                code: 'ZMT345',
                displayName: '45',
                className: 'circle circleRed',
              }, {
                code: 'ZMT36',
                displayName: '06',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT316',
                displayName: '16',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT326',
                displayName: '26',
                className: 'circle',
              }, {
                code: 'ZMT336',
                displayName: '36',
                className: 'circle',
              }, {
                code: 'ZMT346',
                displayName: '46',
                className: 'circle circleRed',
              }, {
                code: 'ZMT37',
                displayName: '07',
                className: 'circle circleRed',
              }, {
                code: 'ZMT317',
                displayName: '17',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT327',
                displayName: '27',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT337',
                displayName: '37',
                className: 'circle',
              }, {
                code: 'ZMT347',
                displayName: '47',
                className: 'circle',
              }, {
                code: 'ZMT38',
                displayName: '08',
                className: 'circle circleRed',
              }, {
                code: 'ZMT318',
                displayName: '18',
                className: 'circle circleRed',
              }, {
                code: 'ZMT328',
                displayName: '28',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT338',
                displayName: '38',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT348',
                displayName: '48',
                className: 'circle',
              }, {
                code: 'ZMT39',
                displayName: '09',
                className: 'circle',
              }, {
                code: 'ZMT319',
                displayName: '19',
                className: 'circle circleRed',
              }, {
                code: 'ZMT329',
                displayName: '29',
                className: 'circle circleRed',
              }, {
                code: 'ZMT339',
                displayName: '39',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT349',
                displayName: '49',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT310',
                displayName: '10',
                className: 'circle',
              }, {
                code: 'ZMT320',
                displayName: '20',
                className: 'circle',
              }, {
                code: 'ZMT330',
                displayName: '30',
                className: 'circle circleRed',
              }, {
                code: 'ZMT340',
                displayName: '40',
                className: 'circle circleRed',
              }
            ]
          }
        }, {
          componentType: Template2,
          data: {
            cycleHeaderTitle: true,
            tableHeadertitle: ['号码', '赔率', '金额'],
            mainColumnLength: 5,
            subColumnLength: 3,
            subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemList: ['DisplayText', 'Odds', 'Input'],
            betItemColumn: 3,
            list: [
              {
                code: 'ZM3LM1',
                displayName: '正3-单',
              }, {
                code: 'ZM3LM3',
                displayName: '正3-大',
              }, {
                code: 'ZM3LM5',
                displayName: '正3-合单',
              }, {
                code: 'ZM3LM7',
                displayName: '正3-合大',
              }, {
                code: 'ZM3LM9',
                displayName: '正3-尾大',
              }, {
                code: 'ZM3LM2',
                displayName: '正3-双',
              }, {
                code: 'ZM3LM4',
                displayName: '正3-小',
              }, {
                code: 'ZM3LM6',
                displayName: '正3-合双',
              }, {
                code: 'ZM3LM8',
                displayName: '正3-合小',
              }, {
                code: 'ZM3LM10',
                displayName: '正3-尾小',
              }, {
                code: 'ZM3SB11',
                displayName: '正3-红波',
                className: 'fillRed',
              }, {
                code: 'ZM3SB12',
                displayName: '正3-蓝波',
                className: 'fillBlue',
              }, {
                code: 'ZM3SB13',
                displayName: '正3-绿波',
                className: 'fillGreen',
              }
            ]
          }
        }]
      }, {
        key: 'ZMT4',
        children: [{
          componentType: Template2,
          data: {
            cycleHeaderTitle: true,
            tableHeadertitle: ['号码', '赔率', '金额'],
            mainColumnLength: 5,
            subColumnLength: 3,
            subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemList: ['DisplayText', 'Odds', 'Input'],
            betItemColumn: 3,
            list: [
              {
                code: 'ZMT41',
                displayName: '01',
                className: 'circle circleRed',
              }, {
                code: 'ZMT411',
                displayName: '11',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT421',
                displayName: '21',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT431',
                displayName: '31',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT441',
                displayName: '41',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT42',
                displayName: '02',
                className: 'circle circleRed',
              }, {
                code: 'ZMT412',
                displayName: '12',
                className: 'circle circleRed',
              }, {
                code: 'ZMT422',
                displayName: '22',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT432',
                displayName: '32',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT442',
                displayName: '42',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT43',
                displayName: '03',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT413',
                displayName: '13',
                className: 'circle circleRed',
              }, {
                code: 'ZMT423',
                displayName: '23',
                className: 'circle circleRed',
              }, {
                code: 'ZMT433',
                displayName: '33',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT443',
                displayName: '43',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT44',
                displayName: '04',
                className: 'circle',
              }, {
                code: 'ZMT414',
                displayName: '14',
                className: 'circle',
              }, {
                code: 'ZMT424',
                displayName: '24',
                className: 'circle circleRed',
              }, {
                code: 'ZMT434',
                displayName: '34',
                className: 'circle circleRed',
              }, {
                code: 'ZMT444',
                displayName: '44',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT45',
                displayName: '05',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT415',
                displayName: '15',
                className: 'circle',
              }, {
                code: 'ZMT425',
                displayName: '25',
                className: 'circle',
              }, {
                code: 'ZMT435',
                displayName: '35',
                className: 'circle circleRed',
              }, {
                code: 'ZMT445',
                displayName: '45',
                className: 'circle circleRed',
              }, {
                code: 'ZMT46',
                displayName: '06',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT416',
                displayName: '16',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT426',
                displayName: '26',
                className: 'circle',
              }, {
                code: 'ZMT436',
                displayName: '36',
                className: 'circle',
              }, {
                code: 'ZMT446',
                displayName: '46',
                className: 'circle circleRed',
              }, {
                code: 'ZMT47',
                displayName: '07',
                className: 'circle circleRed',
              }, {
                code: 'ZMT417',
                displayName: '17',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT427',
                displayName: '27',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT437',
                displayName: '37',
                className: 'circle',
              }, {
                code: 'ZMT447',
                displayName: '47',
                className: 'circle',
              }, {
                code: 'ZMT48',
                displayName: '08',
                className: 'circle circleRed',
              }, {
                code: 'ZMT418',
                displayName: '18',
                className: 'circle circleRed',
              }, {
                code: 'ZMT428',
                displayName: '28',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT438',
                displayName: '38',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT448',
                displayName: '48',
                className: 'circle',
              }, {
                code: 'ZMT49',
                displayName: '09',
                className: 'circle',
              }, {
                code: 'ZMT419',
                displayName: '19',
                className: 'circle circleRed',
              }, {
                code: 'ZMT429',
                displayName: '29',
                className: 'circle circleRed',
              }, {
                code: 'ZMT439',
                displayName: '39',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT449',
                displayName: '49',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT410',
                displayName: '10',
                className: 'circle',
              }, {
                code: 'ZMT420',
                displayName: '20',
                className: 'circle',
              }, {
                code: 'ZMT430',
                displayName: '30',
                className: 'circle circleRed',
              }, {
                code: 'ZMT440',
                displayName: '40',
                className: 'circle circleRed',
              }
            ]
          }
        }, {
          componentType: Template2,
          data: {
            cycleHeaderTitle: true,
            tableHeadertitle: ['号码', '赔率', '金额'],
            mainColumnLength: 5,
            subColumnLength: 3,
            subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemList: ['DisplayText', 'Odds', 'Input'],
            betItemColumn: 3,
            list: [
              {
                code: 'ZM4LM1',
                displayName: '正4-单',
              }, {
                code: 'ZM4LM3',
                displayName: '正4-大',
              }, {
                code: 'ZM4LM5',
                displayName: '正4-合单',
              }, {
                code: 'ZM4LM7',
                displayName: '正4-合大',
              }, {
                code: 'ZM4LM9',
                displayName: '正4-尾大',
              }, {
                code: 'ZM4LM2',
                displayName: '正4-双',
              }, {
                code: 'ZM4LM4',
                displayName: '正4-小',
              }, {
                code: 'ZM4LM6',
                displayName: '正4-合双',
              }, {
                code: 'ZM4LM8',
                displayName: '正4-合小',
              }, {
                code: 'ZM4LM10',
                displayName: '正4-尾小',
              }, {
                code: 'ZM4SB11',
                displayName: '正4-红波',
                className: 'fillRed',
              }, {
                code: 'ZM4SB12',
                displayName: '正4-蓝波',
                className: 'fillBlue',
              }, {
                code: 'ZM4SB13',
                displayName: '正4-绿波',
                className: 'fillGreen',
              }
            ]
          }
        }]
      }, {
        key: 'ZMT5',
        children: [{
          componentType: Template2,
          data: {
            cycleHeaderTitle: true,
            tableHeadertitle: ['号码', '赔率', '金额'],
            mainColumnLength: 5,
            subColumnLength: 3,
            subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemList: ['DisplayText', 'Odds', 'Input'],
            betItemColumn: 3,
            list: [
              {
                code: 'ZMT51',
                displayName: '01',
                className: 'circle circleRed',
              }, {
                code: 'ZMT511',
                displayName: '11',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT521',
                displayName: '21',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT531',
                displayName: '31',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT541',
                displayName: '41',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT52',
                displayName: '02',
                className: 'circle circleRed',
              }, {
                code: 'ZMT512',
                displayName: '12',
                className: 'circle circleRed',
              }, {
                code: 'ZMT522',
                displayName: '22',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT532',
                displayName: '32',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT542',
                displayName: '42',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT53',
                displayName: '03',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT513',
                displayName: '13',
                className: 'circle circleRed',
              }, {
                code: 'ZMT523',
                displayName: '23',
                className: 'circle circleRed',
              }, {
                code: 'ZMT533',
                displayName: '33',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT543',
                displayName: '43',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT54',
                displayName: '04',
                className: 'circle',
              }, {
                code: 'ZMT514',
                displayName: '14',
                className: 'circle',
              }, {
                code: 'ZMT524',
                displayName: '24',
                className: 'circle circleRed',
              }, {
                code: 'ZMT534',
                displayName: '34',
                className: 'circle circleRed',
              }, {
                code: 'ZMT544',
                displayName: '44',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT55',
                displayName: '05',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT515',
                displayName: '15',
                className: 'circle',
              }, {
                code: 'ZMT525',
                displayName: '25',
                className: 'circle',
              }, {
                code: 'ZMT535',
                displayName: '35',
                className: 'circle circleRed',
              }, {
                code: 'ZMT545',
                displayName: '45',
                className: 'circle circleRed',
              }, {
                code: 'ZMT56',
                displayName: '06',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT516',
                displayName: '16',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT526',
                displayName: '26',
                className: 'circle',
              }, {
                code: 'ZMT536',
                displayName: '36',
                className: 'circle',
              }, {
                code: 'ZMT546',
                displayName: '46',
                className: 'circle circleRed',
              }, {
                code: 'ZMT57',
                displayName: '07',
                className: 'circle circleRed',
              }, {
                code: 'ZMT517',
                displayName: '17',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT527',
                displayName: '27',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT537',
                displayName: '37',
                className: 'circle',
              }, {
                code: 'ZMT547',
                displayName: '47',
                className: 'circle',
              }, {
                code: 'ZMT58',
                displayName: '08',
                className: 'circle circleRed',
              }, {
                code: 'ZMT518',
                displayName: '18',
                className: 'circle circleRed',
              }, {
                code: 'ZMT528',
                displayName: '28',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT538',
                displayName: '38',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT548',
                displayName: '48',
                className: 'circle',
              }, {
                code: 'ZMT59',
                displayName: '09',
                className: 'circle',
              }, {
                code: 'ZMT519',
                displayName: '19',
                className: 'circle circleRed',
              }, {
                code: 'ZMT529',
                displayName: '29',
                className: 'circle circleRed',
              }, {
                code: 'ZMT539',
                displayName: '39',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT549',
                displayName: '49',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT510',
                displayName: '10',
                className: 'circle',
              }, {
                code: 'ZMT520',
                displayName: '20',
                className: 'circle',
              }, {
                code: 'ZMT530',
                displayName: '30',
                className: 'circle circleRed',
              }, {
                code: 'ZMT540',
                displayName: '40',
                className: 'circle circleRed',
              }
            ]
          }
        }, {
          componentType: Template2,
          data: {
            cycleHeaderTitle: true,
            tableHeadertitle: ['号码', '赔率', '金额'],
            mainColumnLength: 5,
            subColumnLength: 3,
            subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemList: ['DisplayText', 'Odds', 'Input'],
            betItemColumn: 3,
            list: [
              {
                code: 'ZM5LM1',
                displayName: '正5-单',
              }, {
                code: 'ZM5LM3',
                displayName: '正5-大',
              }, {
                code: 'ZM5LM5',
                displayName: '正5-合单',
              }, {
                code: 'ZM5LM7',
                displayName: '正5-合大',
              }, {
                code: 'ZM5LM9',
                displayName: '正5-尾大',
              }, {
                code: 'ZM5LM2',
                displayName: '正5-双',
              }, {
                code: 'ZM5LM4',
                displayName: '正5-小',
              }, {
                code: 'ZM5LM6',
                displayName: '正5-合双',
              }, {
                code: 'ZM5LM8',
                displayName: '正5-合小',
              }, {
                code: 'ZM5LM10',
                displayName: '正5-尾小',
              }, {
                code: 'ZM5SB11',
                displayName: '正5-红波',
                className: 'fillRed',
              }, {
                code: 'ZM5SB12',
                displayName: '正5-蓝波',
                className: 'fillBlue',
              }, {
                code: 'ZM5SB13',
                displayName: '正5-绿波',
                className: 'fillGreen',
              }
            ]
          }
        }]
      }, {
        key: 'ZMT6',
        children: [{
          componentType: Template2,
          data: {
            cycleHeaderTitle: true,
            tableHeadertitle: ['号码', '赔率', '金额'],
            mainColumnLength: 5,
            subColumnLength: 3,
            subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemList: ['DisplayText', 'Odds', 'Input'],
            betItemColumn: 3,
            list: [
              {
                code: 'ZMT61',
                displayName: '01',
                className: 'circle circleRed',
              }, {
                code: 'ZMT611',
                displayName: '11',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT621',
                displayName: '21',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT631',
                displayName: '31',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT641',
                displayName: '41',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT62',
                displayName: '02',
                className: 'circle circleRed',
              }, {
                code: 'ZMT612',
                displayName: '12',
                className: 'circle circleRed',
              }, {
                code: 'ZMT622',
                displayName: '22',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT632',
                displayName: '32',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT642',
                displayName: '42',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT63',
                displayName: '03',
                className: 'circle circleBlue',
              }, {
                code: 'ZMT613',
                displayName: '13',
                className: 'circle circleRed',
              }, {
                code: 'ZMT623',
                displayName: '23',
                className: 'circle circleRed',
              }, {
                code: 'ZMT633',
                displayName: '33',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT643',
                displayName: '43',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT64',
                displayName: '04',
                className: 'circle',
              }, {
                code: 'ZMT614',
                displayName: '14',
                className: 'circle',
              }, {
                code: 'ZMT624',
                displayName: '24',
                className: 'circle circleRed',
              }, {
                code: 'ZMT634',
                displayName: '34',
                className: 'circle circleRed',
              }, {
                code: 'ZMT644',
                displayName: '44',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT65',
                displayName: '05',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT615',
                displayName: '15',
                className: 'circle',
              }, {
                code: 'ZMT625',
                displayName: '25',
                className: 'circle',
              }, {
                code: 'ZMT635',
                displayName: '35',
                className: 'circle circleRed',
              }, {
                code: 'ZMT645',
                displayName: '45',
                className: 'circle circleRed',
              }, {
                code: 'ZMT66',
                displayName: '06',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT616',
                displayName: '16',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT626',
                displayName: '26',
                className: 'circle',
              }, {
                code: 'ZMT636',
                displayName: '36',
                className: 'circle',
              }, {
                code: 'ZMT646',
                displayName: '46',
                className: 'circle circleRed',
              }, {
                code: 'ZMT67',
                displayName: '07',
                className: 'circle circleRed',
              }, {
                code: 'ZMT617',
                displayName: '17',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT627',
                displayName: '27',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT637',
                displayName: '37',
                className: 'circle',
              }, {
                code: 'ZMT647',
                displayName: '47',
                className: 'circle',
              }, {
                code: 'ZMT68',
                displayName: '08',
                className: 'circle circleRed',
              }, {
                code: 'ZMT618',
                displayName: '18',
                className: 'circle circleRed',
              }, {
                code: 'ZMT628',
                displayName: '28',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT638',
                displayName: '38',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT648',
                displayName: '48',
                className: 'circle',
              }, {
                code: 'ZMT69',
                displayName: '09',
                className: 'circle',
              }, {
                code: 'ZMT619',
                displayName: '19',
                className: 'circle circleRed',
              }, {
                code: 'ZMT629',
                displayName: '29',
                className: 'circle circleRed',
              }, {
                code: 'ZMT639',
                displayName: '39',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT649',
                displayName: '49',
                className: 'circle circleGreen',
              }, {
                code: 'ZMT610',
                displayName: '10',
                className: 'circle',
              }, {
                code: 'ZMT620',
                displayName: '20',
                className: 'circle',
              }, {
                code: 'ZMT630',
                displayName: '30',
                className: 'circle circleRed',
              }, {
                code: 'ZMT640',
                displayName: '40',
                className: 'circle circleRed',
              }
            ]
          }
        }, {
          componentType: Template2,
          data: {
            cycleHeaderTitle: true,
            tableHeadertitle: ['号码', '赔率', '金额'],
            mainColumnLength: 5,
            subColumnLength: 3,
            subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
            betItemList: ['DisplayText', 'Odds', 'Input'],
            betItemColumn: 3,
            list: [
              {
                code: 'ZM6LM1',
                displayName: '正6-单',
              }, {
                code: 'ZM6LM3',
                displayName: '正6-大',
              }, {
                code: 'ZM6LM5',
                displayName: '正6-合单',
              }, {
                code: 'ZM6LM7',
                displayName: '正6-合大',
              }, {
                code: 'ZM6LM9',
                displayName: '正6-尾大',
              }, {
                code: 'ZM6LM2',
                displayName: '正6-双',
              }, {
                code: 'ZM6LM4',
                displayName: '正6-小',
              }, {
                code: 'ZM6LM6',
                displayName: '正6-合双',
              }, {
                code: 'ZM6LM8',
                displayName: '正6-合小',
              }, {
                code: 'ZM6LM10',
                displayName: '正6-尾小',
              }, {
                code: 'ZM6SB11',
                displayName: '正6-红波',
                className: 'fillRed',
              }, {
                code: 'ZM6SB12',
                displayName: '正6-蓝波',
                className: 'fillBlue',
              }, {
                code: 'ZM6SB13',
                displayName: '正6-绿波',
                className: 'fillGreen',
              }
            ]
          }
        }]
      }]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default ZMT;