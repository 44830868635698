import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const ZTM = [
  {
    componentType: CollapseType,
    header: '正一特',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'Z1T1',
          displayName: '01',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z1T2',
          displayName: '02',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z1T3',
          displayName: '03',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z1T4',
          displayName: '04',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z1T5',
          displayName: '05',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z1T6',
          displayName: '06',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z1T7',
          displayName: '07',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z1T8',
          displayName: '08',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z1T9',
          displayName: '09',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z1T10',
          displayName: '10',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z1T11',
          displayName: '11',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z1T12',
          displayName: '12',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z1T13',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z1T14',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z1T15',
          displayName: '15',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z1T16',
          displayName: '16',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z1T17',
          displayName: '17',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z1T18',
          displayName: '18',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z1T19',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z1T20',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z1T21',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z1T22',
          displayName: '22',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z1T23',
          displayName: '23',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z1T24',
          displayName: '24',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z1T25',
          displayName: '25',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z1T26',
          displayName: '26',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z1T27',
          displayName: '27',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z1T28',
          displayName: '28',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z1T29',
          displayName: '29',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z1T30',
          displayName: '30',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z1T31',
          displayName: '31',
          drawImgClassName: 'circle circleBlue'
        },
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '正二特',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'Z2T1',
          displayName: '01',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z2T2',
          displayName: '02',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z2T3',
          displayName: '03',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z2T4',
          displayName: '04',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z2T5',
          displayName: '05',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z2T6',
          displayName: '06',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z2T7',
          displayName: '07',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z2T8',
          displayName: '08',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z2T9',
          displayName: '09',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z2T10',
          displayName: '10',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z2T11',
          displayName: '11',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z2T12',
          displayName: '12',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z2T13',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z2T14',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z2T15',
          displayName: '15',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z2T16',
          displayName: '16',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z2T17',
          displayName: '17',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z2T18',
          displayName: '18',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z2T19',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z2T20',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z2T21',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z2T22',
          displayName: '22',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z2T23',
          displayName: '23',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z2T24',
          displayName: '24',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z2T25',
          displayName: '25',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z2T26',
          displayName: '26',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z2T27',
          displayName: '27',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z2T28',
          displayName: '28',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z2T29',
          displayName: '29',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z2T30',
          displayName: '30',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z2T31',
          displayName: '31',
          drawImgClassName: 'circle circleBlue'
        },
      ]
    }
  }, {
    componentType: CollapseType,
    header: '正三特',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'Z3T1',
          displayName: '01',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z3T2',
          displayName: '02',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z3T3',
          displayName: '03',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z3T4',
          displayName: '04',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z3T5',
          displayName: '05',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z3T6',
          displayName: '06',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z3T7',
          displayName: '07',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z3T8',
          displayName: '08',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z3T9',
          displayName: '09',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z3T10',
          displayName: '10',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z3T11',
          displayName: '11',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z3T12',
          displayName: '12',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z3T13',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z3T14',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z3T15',
          displayName: '15',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z3T16',
          displayName: '16',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z3T17',
          displayName: '17',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z3T18',
          displayName: '18',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z3T19',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z3T20',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z3T21',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z3T22',
          displayName: '22',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z3T23',
          displayName: '23',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z3T24',
          displayName: '24',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z3T25',
          displayName: '25',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z3T26',
          displayName: '26',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z3T27',
          displayName: '27',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z3T28',
          displayName: '28',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z3T29',
          displayName: '29',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z3T30',
          displayName: '30',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z3T31',
          displayName: '31',
          drawImgClassName: 'circle circleBlue'
        },
      ]
    }
  }, {
    componentType: CollapseType,
    header: '正四特',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'Z4T1',
          displayName: '01',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z4T2',
          displayName: '02',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z4T3',
          displayName: '03',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z4T4',
          displayName: '04',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z4T5',
          displayName: '05',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z4T6',
          displayName: '06',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z4T7',
          displayName: '07',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z4T8',
          displayName: '08',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z4T9',
          displayName: '09',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z4T10',
          displayName: '10',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z4T11',
          displayName: '11',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z4T12',
          displayName: '12',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z4T13',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z4T14',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z4T15',
          displayName: '15',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z4T16',
          displayName: '16',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z4T17',
          displayName: '17',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z4T18',
          displayName: '18',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z4T19',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z4T20',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z4T21',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z4T22',
          displayName: '22',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z4T23',
          displayName: '23',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z4T24',
          displayName: '24',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z4T25',
          displayName: '25',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z4T26',
          displayName: '26',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z4T27',
          displayName: '27',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z4T28',
          displayName: '28',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z4T29',
          displayName: '29',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z4T30',
          displayName: '30',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z4T31',
          displayName: '31',
          drawImgClassName: 'circle circleBlue'
        },
      ]
    }
  }, {
    componentType: CollapseType,
    header: '正五特',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'Z5T1',
          displayName: '01',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z5T2',
          displayName: '02',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z5T3',
          displayName: '03',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z5T4',
          displayName: '04',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z5T5',
          displayName: '05',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z5T6',
          displayName: '06',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z5T7',
          displayName: '07',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z5T8',
          displayName: '08',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z5T9',
          displayName: '09',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z5T10',
          displayName: '10',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z5T11',
          displayName: '11',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z5T12',
          displayName: '12',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z5T13',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z5T14',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z5T15',
          displayName: '15',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z5T16',
          displayName: '16',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z5T17',
          displayName: '17',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z5T18',
          displayName: '18',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z5T19',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z5T20',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z5T21',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z5T22',
          displayName: '22',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z5T23',
          displayName: '23',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z5T24',
          displayName: '24',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z5T25',
          displayName: '25',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z5T26',
          displayName: '26',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z5T27',
          displayName: '27',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z5T28',
          displayName: '28',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z5T29',
          displayName: '29',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z5T30',
          displayName: '30',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z5T31',
          displayName: '31',
          drawImgClassName: 'circle circleBlue'
        },
      ]
    }
  }, {
    componentType: CollapseType,
    header: '正六特',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'Z6T1',
          displayName: '01',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z6T2',
          displayName: '02',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z6T3',
          displayName: '03',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z6T4',
          displayName: '04',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z6T5',
          displayName: '05',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z6T6',
          displayName: '06',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z6T7',
          displayName: '07',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z6T8',
          displayName: '08',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z6T9',
          displayName: '09',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z6T10',
          displayName: '10',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z6T11',
          displayName: '11',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z6T12',
          displayName: '12',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z6T13',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z6T14',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z6T15',
          displayName: '15',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z6T16',
          displayName: '16',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z6T17',
          displayName: '17',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z6T18',
          displayName: '18',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z6T19',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z6T20',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z6T21',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z6T22',
          displayName: '22',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z6T23',
          displayName: '23',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z6T24',
          displayName: '24',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z6T25',
          displayName: '25',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z6T26',
          displayName: '26',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z6T27',
          displayName: '27',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z6T28',
          displayName: '28',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z6T29',
          displayName: '29',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z6T30',
          displayName: '30',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z6T31',
          displayName: '31',
          drawImgClassName: 'circle circleBlue'
        },
      ]
    }
  }, {
    componentType: CollapseType,
    header: '正七特',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'Z7T1',
          displayName: '01',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z7T2',
          displayName: '02',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z7T3',
          displayName: '03',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z7T4',
          displayName: '04',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z7T5',
          displayName: '05',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z7T6',
          displayName: '06',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z7T7',
          displayName: '07',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z7T8',
          displayName: '08',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z7T9',
          displayName: '09',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z7T10',
          displayName: '10',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z7T11',
          displayName: '11',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z7T12',
          displayName: '12',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z7T13',
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z7T14',
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z7T15',
          displayName: '15',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z7T16',
          displayName: '16',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z7T17',
          displayName: '17',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z7T18',
          displayName: '18',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z7T19',
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z7T20',
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z7T21',
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z7T22',
          displayName: '22',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z7T23',
          displayName: '23',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z7T24',
          displayName: '24',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z7T25',
          displayName: '25',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z7T26',
          displayName: '26',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'Z7T27',
          displayName: '27',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z7T28',
          displayName: '28',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'Z7T29',
          displayName: '29',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z7T30',
          displayName: '30',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'Z7T31',
          displayName: '31',
          drawImgClassName: 'circle circleBlue'
        },
      ]
    }
  }
];

export default ZTM;