import React from 'react';
import './styles.scss';

const ServiceCardTwo = ({ src, title, content }) => (
  <div className="card serviceCardTwo">
    <div className="cardTitle d-flex align-items-center py-2 px-3">
      <div className="font-weight-bold">{title}</div>
    </div>
    <div className="cardBody">
      <div className="position-relative pt-3 px-3">
        <p className="card-text font-weight-normal text-muted">{content}</p>
        <div className="position-absolute cardImage">
          <img className="w-60" src={src} alt="app" />
        </div>
      </div>
    </div>
  </div>
);

export default ServiceCardTwo;