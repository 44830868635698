import ZH from './ZH';
import ZM from './ZM';
import { renderResultDateCell } from '../../utils';

const getBallClass = val => {
  if (val > 40) {
    return 'ball ballBlueDark';
  }
  return 'ball ballBlueLight';
};

const renderQiu = data => {
  if (!data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  for (let i = 0; i < (result.length) / 2; i++) {
    tempResult.push([{
      value: result[i],
      className: getBallClass(result[i])
    }, {
      value: result[i + Math.floor((result.length) / 2)],
      className: getBallClass(result[i + Math.floor((result.length) / 2)])
    }]);
  }
  return tempResult;
};

const renderZH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  let championSum = 0;
  result.forEach(item => (championSum += parseInt(item, 10)));

  tempResult.push({
    value: championSum
  });

  tempResult.push({
    value: championSum === 810 ? '和' : championSum > 810 ? '大' : '小',
    className: championSum === 810 ? 'greenText' : championSum > 810 ? 'redText' : '小',
  });

  tempResult.push({
    value: championSum % 2 ? '单' : '双',
    className: championSum % 2 ? 'redText' : '',
  });

  tempResult.push({
    value: (championSum < 695) ? '金' : (championSum >= 696 && championSum <= 763) ? '木' : (championSum >= 764 && championSum <= 855) ? '水' : (championSum >= 856 && championSum <= 923) ? '火' : '土'
  });
  return tempResult;
};

const renderBS = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  let frontCount = 0;
  let backCount = 0;
  let oddCount = 0;
  let evenCount = 0;
  result.forEach(item => {
    if (parseInt(item, 10) <= 40) {
      frontCount += 1;
    } else {
      backCount += 1;
    }
    if ((parseInt(item, 10) % 2) === 0) {
      evenCount += 1;
    } else {
      oddCount += 1;
    }
  });

  tempResult.push({
    value: (frontCount > backCount) ? '前(多)' : (backCount > frontCount) ? '后(多)' : '前后(和)',
    className: (frontCount > backCount) ? 'redText' : (backCount > frontCount) ? '' : 'greenText',
  });

  tempResult.push({
    value: (evenCount > oddCount) ? '双(多)' : (oddCount > evenCount) ? '单(多)' : '单双(和)',
    className: (evenCount > oddCount) ? 'redText' : (oddCount > evenCount) ? '' : 'greenText',
  });

  return tempResult;
};

const BJK8 = {
  children: [
    {
      tabId: 'ZhengHe',
      label: '整合',
      children: ZH.ZH,
      settingCode: 'DSH,QHH,ZH,WX,ZHGG,LM'
    },
    {
      tabId: 'ZhengMa',
      label: '正码',
      children: ZM.ZM,
      settingCode: 'ZM'
    }
  ],
  resultTab: {
    headerCell: [{
      attr: '期数',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '日期',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '彩球号码',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '总和',
      wrapperStyle: 'pk10Header'
    }, {
      attr: '比数量',
      wrapperStyle: 'pk10Header'
    }],
    bodyCell: [
      {
        attr: 'installments',
        wrapperStyle: 'd-flex justify-content-center align-items-center'
      },
      {
        attr: renderResultDateCell,
        wrapperStyle: 'dateCol'
      },
      {
        attr: renderQiu,
        wrapperStyle: 'd-flex bjk8BallCol'
      },
      {
        attr: renderZH,
        wrapperStyle: 'd-flex justify-content-center align-items-center pk10GYH'
      },
      {
        attr: renderBS,
        wrapperStyle: 'd-flex justify-content-center align-items-center pk10LH'
      }
    ],
  },
};

export default BJK8;