
import TeMaAndLianMa from '../../../../components/Mobile/ComponentType/TeMaAndLianMa';

import PengAndDan from '../../../../components/Mobile/ComponentType/PengAndDan';
import { renderHeXiaoBalls } from '../../../utils';

import OptionItem from '../../../../components/Mobile/OptionItem';

import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';

const LIUXIAO = [
  {
    componentType: TeMaAndLianMa,
    data: {
      list: [
        {
          code: 'LX',
          displayName: '六肖',
          minimumSelection: 6,
          maximumSelection: 10,
          className: 'cellWrapper',
          danList: ['1', '2', '3', '4', '5'],
        }
      ]
    }
  },
  {
    componentType: PengAndDan,
    data: {}
  },
  {
    componentType: CollapseType,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper HK6TeXiao',
      list: [
        {
          displayName: '鼠',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 36),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '马',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 36),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '牛',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 36),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '羊',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 36),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '虎',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 36),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '猴',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 36),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '兔',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 36),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '鸡',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 36),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '龙',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 36),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '狗',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 36),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '蛇',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 36),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }, {

          displayName: '猪',
          generateNumber: obj => renderHeXiaoBalls(obj.preZodiac, obj.currentZodiac, 36),
          generateNumberWrapperStyle: 'HK6TeXiaoWrapper',
          showOdds: false
        }
      ]
    }
  }
];

export default LIUXIAO;