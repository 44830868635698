import axios from 'axios';

import { SET_ACTIVE_OPTION, SET_MODAL_ALERT, USER_LOGOUT } from '../../redux/actions/actionTypes';
import { store } from '../../redux';

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : `${window.location.protocol}//${window.location.hostname}/api`,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }
});

instance.interceptors.request.use(
  config => {
    const storeState = store.getState();
    const { platform, token } = storeState.appReducers || {};

    if (platform === 'Mobile') {
      config.baseURL = `${config.baseURL}/Mobile/`;
    }
    if (token) {
      config.headers = {
        ...config.headers, Authorization: `Bearer ${token}`
      };
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(response => response,
  error => {
    if (axios.isCancel(error)) {
      return;
    }
    const originalRequest = error.config;

    if (originalRequest) {
      if (error.response) {
        const {
          data,
          status,
        } = error.response || {};

        const { msg, state } = data || {};

        if (status === 400 && msg && !originalRequest.url.includes('/thirdPartyGames/loadAmount') && !originalRequest.url.includes('/IsExistsLoginId') && !originalRequest.url.includes('/SendRegisterPhoneSMS') && !originalRequest.url.includes('/register')) {
          store.dispatch({
            type: SET_MODAL_ALERT,
            value: {
              visible: true,
              type: 'error',
              data: {
                message: msg,
                onConfirmClick: state === (6014 || 6020 || 6019) ? () => {
                  store.dispatch({
                    type: SET_ACTIVE_OPTION,
                    value: 'settings'
                  });
                } : undefined
              }
            }
          });
          return Promise.reject(error);
        }

        if (status === 401 && msg) {
          store.dispatch({
            type: SET_MODAL_ALERT,
            value: {
              visible: true,
              type: 'confirmation',
              data: {
                message: msg,
                confirmText: '知道了',
                showCancelButton: false,
                onConfirmationClick: () => {
                  store.dispatch({
                    type: USER_LOGOUT
                  });
                }
              }
            }
          });
          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(error);
  });

export default instance;