import { BetTypeContainer, CheckBox, MultipleTypeSelectionPanel, Odds } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const L3Z2 = [
  {
    componentType: QuickBet,
  },
  {
    componentType: MultipleTypeSelectionPanel,
    data: {
      list: [
        {
          rowTitle: '类别',
          rowTitleClassName: 'text-center',
          rowType: CheckBox,
          wrapperStyle: 'checkBoxRow F36X7',
          onCheckBoxChange: (updateSectionSelection, subItem) => {
            updateSectionSelection(subItem);
          },
          rowData: [{
            code: 'L3Z2Z21',
            displayName: '三中二',
            minimumSelection: 3,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1', '2'],
          }]
        },
        {
          rowTitle: '赔率',
          rowTitleClassName: 'text-center',
          rowType: Odds,
          wrapperStyle: 'oddsRow F36X7_3Z2',
          rowData: [{
            code: 'L3Z2Z21',
            displayName: [{
              code: 'L3Z2Z21', displayName: '中二', className: 'd-flex justify-content-center align-items-center border-right h-100 flex-grow-1'
            }, {
              code: 'L3Z2Z31', displayName: '中三', className: 'd-flex justify-content-center align-items-center h-100 flex-grow-1'
            }],
            className: 'chooseBetOdds p-0',
          }]
        },
        {
          rowTitleClassName: 'p-2 text-center',
          rowType: BetTypeContainer,
          wrapperStyle: 'p-2',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['生肖', '勾选', '号码'],
      mainColumnLength: 4,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-checkbox'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-checkbox'],
      emptyItemStyle: ['column-3items-empty-label', 'column-3items-empty-odds', 'column-3items-empty-checkbox'],
      betItemList: ['DisplayText', 'Odds', 'CheckBox'],
      betItemColumn: 3,
      list: [
        {
          code: 'L3Z2Z21',
          displayName: '01',
          className: 'circle circleRed',
        }, {
          code: 'L3Z2Z211',
          displayName: '11',
          className: 'circle circleGreen',
        }, {
          code: 'L3Z2Z221',
          displayName: '21',
          className: 'circle circleGreen',
        }, {
          code: 'L3Z2Z231',
          displayName: '31',
          className: 'circle circleBlue',
        }, {
          code: 'L3Z2Z22',
          displayName: '02',
          className: 'circle circleRed',
        }, {
          code: 'L3Z2Z212',
          displayName: '12',
          className: 'circle circleRed',
        }, {
          code: 'L3Z2Z222',
          displayName: '22',
          className: 'circle circleGreen',
        }, {
          code: 'L3Z2Z23',
          displayName: '03',
          className: 'circle circleBlue',
          newLine: true,
        }, {
          code: 'L3Z2Z213',
          displayName: '13',
          className: 'circle circleRed',
        }, {
          code: 'L3Z2Z223',
          displayName: '23',
          className: 'circle circleRed',
        }, {
          code: 'L3Z2Z24',
          displayName: '04',
          className: 'circle',
          newLine: true,
        }, {
          code: 'L3Z2Z214',
          displayName: '14',
          className: 'circle',
        }, {
          code: 'L3Z2Z224',
          displayName: '24',
          className: 'circle circleRed',
        }, {
          code: 'L3Z2Z25',
          displayName: '05',
          className: 'circle circleGreen',
          newLine: true,
        }, {
          code: 'L3Z2Z215',
          displayName: '15',
          className: 'circle',
        }, {
          code: 'L3Z2Z225',
          displayName: '25',
          className: 'circle',
        }, {
          code: 'L3Z2Z26',
          displayName: '06',
          className: 'circle circleGreen',
          newLine: true,
        }, {
          code: 'L3Z2Z216',
          displayName: '16',
          className: 'circle circleGreen',
        }, {
          code: 'L3Z2Z226',
          displayName: '26',
          className: 'circle',
        }, {
          code: 'L3Z2Z27',
          displayName: '07',
          className: 'circle circleRed',
          newLine: true,
        }, {
          code: 'L3Z2Z217',
          displayName: '17',
          className: 'circle circleGreen',
        }, {
          code: 'L3Z2Z227',
          displayName: '27',
          className: 'circle circleGreen',
        }, {
          code: 'L3Z2Z28',
          displayName: '08',
          className: 'circle circleRed',
          newLine: true,
        }, {
          code: 'L3Z2Z218',
          displayName: '18',
          className: 'circle circleRed',
        }, {
          code: 'L3Z2Z228',
          displayName: '28',
          className: 'circle circleGreen',
        }, {
          code: 'L3Z2Z29',
          displayName: '09',
          className: 'circle',
          newLine: true,
        }, {
          code: 'L3Z2Z219',
          displayName: '19',
          className: 'circle circleRed',
        }, {
          code: 'L3Z2Z229',
          displayName: '29',
          className: 'circle circleRed',
        }, {
          code: 'L3Z2Z210',
          displayName: '10',
          className: 'circle',
          newLine: true,
        }, {
          code: 'L3Z2Z220',
          displayName: '20',
          className: 'circle',
        }, {
          code: 'L3Z2Z230',
          displayName: '30',
          className: 'circle circleRed',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default L3Z2;