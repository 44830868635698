import { RESET_PRESET_AMOUNT_LIST, SET_BET_TYPE_SELECTION, SET_CHANGLONG_DATA, SET_CURRENT_SELECTED, SET_LOTTERY_AND_TEMPLATE, SET_PRESET_AMOUNT, SET_PRESET_AMOUNT_ENABLED, SET_PRESET_AMOUNT_LIST, SET_SECTION_SELECTION, SET_SELECTED_DAN, SET_SELECTED_SUBGAME_LIST, SET_SUB_TYPE, UPDATE_PRESET_AMOUNT } from '../actionTypes';

const setCurrentSelected = val => ({
  type: SET_CURRENT_SELECTED,
  value: val
});

const setSelectedDan = dan => ({
  type: SET_SELECTED_DAN,
  value: dan
});

const setSubType = val => ({
  type: SET_SUB_TYPE,
  value: val
});

const setLotteryAndTemplate = val => ({
  type: SET_LOTTERY_AND_TEMPLATE,
  value: val
});

const setPresetAmount = val => ({
  type: SET_PRESET_AMOUNT,
  value: val
});

const setPresetAmountList = val => ({
  type: SET_PRESET_AMOUNT_LIST,
  value: val
});

const updateCartPresetAmount = val => ({
  type: UPDATE_PRESET_AMOUNT,
  value: val
});

const resetPresetAmountListAction = () => ({
  type: RESET_PRESET_AMOUNT_LIST
});

const setBetTypeSelection = val => ({
  type: SET_BET_TYPE_SELECTION,
  value: val
});

const setSectionSelection = val => ({
  type: SET_SECTION_SELECTION,
  value: val
});

const setSelectedSubGameList = val => ({
  type: SET_SELECTED_SUBGAME_LIST,
  value: val
});

const setChangLong = changLongData => ({
  type: SET_CHANGLONG_DATA,
  value: changLongData
});

const setPresetEnabled = val => ({
  type: SET_PRESET_AMOUNT_ENABLED,
  value: val
});

const updateSectionSelection = value => dispatch => (
  dispatch(setSectionSelection(value))
);

const updateSubType = value => dispatch => (
  dispatch(setSubType(value))
);

const updateLotteryAndTemplate = value => dispatch => {
  dispatch(setLotteryAndTemplate(value));
  dispatch(resetChangLongData());
};

const updatePresetAmount = value => dispatch => {
  dispatch(setPresetAmount(value));
  dispatch(updateCartPresetAmount(value));
};

const updatePresetAmountList = value => dispatch => (
  dispatch(setPresetAmountList(value))
);

const resetPresetAmountList = () => dispatch => (
  dispatch(resetPresetAmountListAction())
);

const updateSelectedSubGameList = value => dispatch => (
  dispatch(setSelectedSubGameList(value))
);

const updateBetTypeSelection = value => dispatch => (
  dispatch(setBetTypeSelection(value))
);

const updateSelectedDan = value => dispatch => (
  dispatch(setSelectedDan(value))
);

const updateCurrentSelected = value => dispatch => (
  dispatch(setCurrentSelected(value))
);

const resetChangLongData = () => dispatch => (
  dispatch(setChangLong(new Array(10).fill()))
);

const updatePresetEnabled = value => dispatch => (
  dispatch(setPresetEnabled(value))
);

export default {
  updateLotteryAndTemplate,
  updateSubType,
  updatePresetAmount,
  updatePresetAmountList,
  updateSectionSelection,
  updateSelectedSubGameList,
  updateBetTypeSelection,
  resetPresetAmountList,
  updateSelectedDan,
  updateCurrentSelected,
  updatePresetEnabled,
};