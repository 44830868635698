import React, { useEffect, useState } from 'react';

import { Button, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, cartActions, gameActions } from '../../../../redux/actions';
import { validateNumber } from '../../../../constants/utils';
import './styles.scss';

const mapStateToProps = state => ({
  presetAmount: state.gameReducers.presetAmount,
  presetAmountList: state.gameReducers.presetAmountList,
  cart: state.cartReducers.cart,
  presetEnabled: state.gameReducers.presetEnabled,
});

const mapDispatchToProps = dispatch => ({
  updatePresetEnabled: value => dispatch(gameActions.updatePresetEnabled(value)),
  updatePresetAmount: amount => dispatch(gameActions.updatePresetAmount(amount)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  resetItemInCart: () => dispatch(cartActions.resetItemInCart()),
});

const QuickBet = ({ presetAmount, presetAmountList, updatePresetAmount, updateModalAlert, updatePresetEnabled, resetItemInCart, cart, presetEnabled }) => {
  const [_presetEnabled, setPresetEnabled] = useState(presetEnabled);
  const [_presetAmount, setPresetAmount] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (_presetAmount !== presetAmount) {
      setPresetAmount(presetAmount || '');
    }

    if (_presetEnabled !== presetEnabled) {
      if (presetEnabled) {
        setPresetAmount(presetAmount || '');
      }
      setPresetEnabled(presetEnabled);
    }
  }, [presetAmount, presetEnabled]);

  const confirmBet = cart => {
    if (!cart || Object.keys(cart).length === 0) return;
    updateModalAlert({
      type: 'confirmBet',
      visible: true
    });
  };

  function keyDownHandler(event) {
    const element = document.getElementsByClassName('confirmBetWrapperWeb');
    if (element.length === 0) {
      if (event.key === 'Enter') {
        event.preventDefault();
        confirmBet(cart);
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler, false);
    return () => {
      document.removeEventListener('keydown', keyDownHandler, false);
    };
  }, [cart]);

  return (
    <div className="w-100 d-flex justify-content-center quickBet pt-2 pb-2">
      <Button
        type="button"
        className="quickSelectionAmountBtn p-0"
        onClick={() => updateModalAlert({
          type: 'presetAmount',
          visible: true
        })}
      >
        {t('QUICK_SELECTION_AMOUNT')}
      </Button>
      <div className="presetAmount d-flex align-items-center mx-4">
        <label
          htmlFor="preset"
          className="d-flex align-items-center mb-0 mr-2"
          onClick={() => {
            updatePresetEnabled(!_presetEnabled);
            if (_presetEnabled) {
              updatePresetAmount('');
            }
          }}
        >
          <Input type="checkbox" id="preset" className="mr-1" defaultChecked={_presetEnabled} />
          {t('PRESET')}
        </label>
        <Input
          type="text"
          className="quickBetAmountInput"
          value={_presetAmount}
          onChange={({ target: { value: text } }) => {
            const validatedText = validateNumber(text);
            updatePresetAmount(validatedText || '');
          }}
          placeholder={t('AMOUNT')}
        />
      </div>
      <div className="d-flex quickBetAmountBtnGroup">
        {
          presetAmountList.map((item, index) => <Input key={`preset_amount_${index.toString()}`} type="button" onClick={() => updatePresetAmount((!presetAmount || Number.isNaN(presetAmount) ? 0 : presetAmount) + item)} value={item} />)
        }
      </div>
      <Button
        type="button"
        className="confirmBtn p-0"
        onClick={() => confirmBet(cart)}
      >
        {t('CONFIRM')}
      </Button>
      <Button
        type="button"
        className="resetBtn p-0"
        onClick={() => {
          resetItemInCart();
          updatePresetAmount('');
        }}
      >
        {t('RESET')}
      </Button>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickBet);