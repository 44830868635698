import { ProbabilitySelectionPanel } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const GJGroup = ['GJHM7', 'GJHM8', 'GJHM9', 'GJHM10', 'GJHM11', 'GJHM12', 'GJHM13', 'GJHM14', 'GJHM15', 'GJHM16'];
const YJGroup = ['YJHM7', 'YJHM8', 'YJHM9', 'YJHM10', 'YJHM11', 'YJHM12', 'YJHM13', 'YJHM14', 'YJHM15', 'YJHM16'];
const B3Group = ['B3CH7', 'B3CH8', 'B3CH9', 'B3CH10', 'B3CH11', 'B3CH12', 'B3CH13', 'B3CH14', 'B3CH15', 'B3CH16'];
const B4Group = ['B4CH7', 'B4CH8', 'B4CH9', 'B4CH10', 'B4CH11', 'B4CH12', 'B4CH13', 'B4CH14', 'B4CH15', 'B4CH16'];
const B5Group = ['B5CH7', 'B5CH8', 'B5CH9', 'B5CH10', 'B5CH11', 'B5CH12', 'B5CH13', 'B5CH14', 'B5CH15', 'B5CH16'];
const B6Group = ['B6CH7', 'B6CH8', 'B6CH9', 'B6CH10', 'B6CH11', 'B6CH12', 'B6CH13', 'B6CH14', 'B6CH15', 'B6CH16'];
const B7Group = ['B7CH7', 'B7CH8', 'B7CH9', 'B7CH10', 'B7CH11', 'B7CH12', 'B7CH13', 'B7CH14', 'B7CH15', 'B7CH16'];
const B8Group = ['B8CH7', 'B8CH8', 'B8CH9', 'B8CH10', 'B8CH11', 'B8CH12', 'B8CH13', 'B8CH14', 'B8CH15', 'B8CH16'];
const B9Group = ['B9CH7', 'B9CH8', 'B9CH9', 'B9CH10', 'B9CH11', 'B9CH12', 'B9CH13', 'B9CH14', 'B9CH15', 'B9CH16'];
const B10Group = ['B10CH7', 'B10CH8', 'B10CH9', 'B10CH10', 'B10CH11', 'B10CH12', 'B10CH13', 'B10CH14', 'B10CH15', 'B10CH16'];

const transformLengReValue = (lengRe, yiLouCount, group, currentKeyCode) => {
  const lengReArr = group.map(item => lengRe[item]);
  const yiLouArr = lengReArr.map(x => yiLouCount - x);
  return {
    value: lengRe[currentKeyCode],
    className: Math.max(...lengReArr) === lengRe[currentKeyCode] ? 'hot' : (Math.min(...lengReArr) === lengRe[currentKeyCode] ? 'cold' : ''),
    classNameYiLou: Math.max(...yiLouArr) === (yiLouCount - lengRe[currentKeyCode]) ? 'hot' : (Math.min(...yiLouArr) === (yiLouCount - lengRe[currentKeyCode]) ? 'cold' : ''),
  };
};

const DH = [
  {
    componentType: ProbabilitySelectionPanel,
  },
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: false,
      tableHeadertitle: ['冠军', '亚军', '第三名', '第四名', '第五名'],
      mainColumnLength: 5,
      subColumnLength: 1,
      subColumnStyle: ['column-1item-style'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      showProbability: true,
      list: [
        {
          code: 'GJHM7',
          displayName: '1',
          className: 'square square_1',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, GJGroup, 'GJHM7');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'YJHM7',
          displayName: '1',
          className: 'square square_1',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, YJGroup, 'YJHM7');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B3CH7',
          displayName: '1',
          className: 'square square_1',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B3Group, 'B3CH7');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B4CH7',
          displayName: '1',
          className: 'square square_1',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B4Group, 'B4CH7');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B5CH7',
          displayName: '1',
          className: 'square square_1',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B5Group, 'B5CH7');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        },
        {
          code: 'GJHM8',
          displayName: '2',
          className: 'square square_2',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, GJGroup, 'GJHM8');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'YJHM8',
          displayName: '2',
          className: 'square square_2',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, YJGroup, 'YJHM8');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B3CH8',
          displayName: '2',
          className: 'square square_2',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B3Group, 'B3CH8');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B4CH8',
          displayName: '2',
          className: 'square square_2',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B4Group, 'B4CH8');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B5CH8',
          displayName: '2',
          className: 'square square_2',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B5Group, 'B5CH8');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        },
        {
          code: 'GJHM9',
          displayName: '3',
          className: 'square square_3',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, GJGroup, 'GJHM9');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'YJHM9',
          displayName: '3',
          className: 'square square_3',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, YJGroup, 'YJHM9');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B3CH9',
          displayName: '3',
          className: 'square square_3',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B3Group, 'B3CH9');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B4CH9',
          displayName: '3',
          className: 'square square_3',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B4Group, 'B4CH9');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B5CH9',
          displayName: '3',
          className: 'square square_3',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B5Group, 'B5CH9');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        },
        {
          code: 'GJHM10',
          displayName: '4',
          className: 'square square_4',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, GJGroup, 'GJHM10');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'YJHM10',
          displayName: '4',
          className: 'square square_4',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, YJGroup, 'YJHM10');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B3CH10',
          displayName: '4',
          className: 'square square_4',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B3Group, 'B3CH10');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B4CH10',
          displayName: '4',
          className: 'square square_4',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B4Group, 'B4CH10');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B5CH10',
          displayName: '4',
          className: 'square square_4',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B5Group, 'B5CH10');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        },
        {
          code: 'GJHM11',
          displayName: '5',
          className: 'square square_5',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, GJGroup, 'GJHM11');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'YJHM11',
          displayName: '5',
          className: 'square square_5',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, YJGroup, 'YJHM11');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B3CH11',
          displayName: '5',
          className: 'square square_5',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B3Group, 'B3CH11');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B4CH11',
          displayName: '5',
          className: 'square square_5',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B4Group, 'B4CH11');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B5CH11',
          displayName: '5',
          className: 'square square_5',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B5Group, 'B5CH11');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        },
        {
          code: 'GJHM12',
          displayName: '6',
          className: 'square square_6',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, GJGroup, 'GJHM12');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'YJHM12',
          displayName: '6',
          className: 'square square_6',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, YJGroup, 'YJHM12');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B3CH12',
          displayName: '6',
          className: 'square square_6',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B3Group, 'B3CH12');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B4CH12',
          displayName: '6',
          className: 'square square_6',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B4Group, 'B4CH12');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B5CH12',
          displayName: '6',
          className: 'square square_6',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B5Group, 'B5CH12');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        },
        {
          code: 'GJHM13',
          displayName: '7',
          className: 'square square_7',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, GJGroup, 'GJHM13');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'YJHM13',
          displayName: '7',
          className: 'square square_7',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, YJGroup, 'YJHM13');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B3CH13',
          displayName: '7',
          className: 'square square_7',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B3Group, 'B3CH13');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B4CH13',
          displayName: '7',
          className: 'square square_7',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B4Group, 'B4CH13');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B5CH13',
          displayName: '7',
          className: 'square square_7',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B5Group, 'B5CH13');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        },
        {
          code: 'GJHM14',
          displayName: '8',
          className: 'square square_8',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, GJGroup, 'GJHM14');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'YJHM14',
          displayName: '8',
          className: 'square square_8',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, YJGroup, 'YJHM14');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B3CH14',
          displayName: '8',
          className: 'square square_8',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B3Group, 'B3CH14');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B4CH14',
          displayName: '8',
          className: 'square square_8',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B4Group, 'B4CH14');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B5CH14',
          displayName: '8',
          className: 'square square_8',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B5Group, 'B5CH14');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        },
        {
          code: 'GJHM15',
          displayName: '9',
          className: 'square square_9',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, GJGroup, 'GJHM15');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'YJHM15',
          displayName: '9',
          className: 'square square_9',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, YJGroup, 'YJHM15');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B3CH15',
          displayName: '9',
          className: 'square square_9',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B3Group, 'B3CH15');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B4CH15',
          displayName: '9',
          className: 'square square_9',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B4Group, 'B4CH15');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B5CH15',
          displayName: '9',
          className: 'square square_9',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B5Group, 'B5CH15');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        },
        {
          code: 'GJHM16',
          displayName: '10',
          className: 'square square_10',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, GJGroup, 'GJHM16');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'YJHM16',
          displayName: '10',
          className: 'square square_10',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, YJGroup, 'YJHM16');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B3CH16',
          displayName: '10',
          className: 'square square_10',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B3Group, 'B3CH16');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B4CH16',
          displayName: '10',
          className: 'square square_10',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B4Group, 'B4CH16');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B5CH16',
          displayName: '10',
          className: 'square square_10',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B5Group, 'B5CH16');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        },
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: false,
      tableHeadertitle: ['第六名', '第七名', '第八名', '第九名', '第十名'],
      mainColumnLength: 5,
      subColumnLength: 1,
      subColumnStyle: ['column-1item-sty]l'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      showProbability: true,
      list: [
        {
          code: 'B6CH7',
          displayName: '1',
          className: 'square square_1',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B6Group, 'B6CH7');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B7CH7',
          displayName: '1',
          className: 'square square_1',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B7Group, 'B7CH7');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B8CH7',
          displayName: '1',
          className: 'square square_1',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B8Group, 'B8CH7');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B9CH7',
          displayName: '1',
          className: 'square square_1',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B9Group, 'B9CH7');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B10CH7',
          displayName: '1',
          className: 'square square_1',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B10Group, 'B10CH7');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        },
        {
          code: 'B6CH8',
          displayName: '2',
          className: 'square square_2',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B6Group, 'B6CH8');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B7CH8',
          displayName: '2',
          className: 'square square_2',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B7Group, 'B7CH8');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B8CH8',
          displayName: '2',
          className: 'square square_2',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B8Group, 'B8CH8');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B9CH8',
          displayName: '2',
          className: 'square square_2',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B9Group, 'B9CH8');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B10CH8',
          displayName: '2',
          className: 'square square_2',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B10Group, 'B10CH8');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        },
        {
          code: 'B6CH9',
          displayName: '3',
          className: 'square square_3',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B6Group, 'B6CH9');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B7CH9',
          displayName: '3',
          className: 'square square_3',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B7Group, 'B7CH9');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B8CH9',
          displayName: '3',
          className: 'square square_3',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B8Group, 'B8CH9');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B9CH9',
          displayName: '3',
          className: 'square square_3',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B9Group, 'B9CH9');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B10CH9',
          displayName: '3',
          className: 'square square_3',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B10Group, 'B10CH9');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        },
        {
          code: 'B6CH10',
          displayName: '4',
          className: 'square square_4',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B6Group, 'B6CH10');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B7CH10',
          displayName: '4',
          className: 'square square_4',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B7Group, 'B7CH10');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B8CH10',
          displayName: '4',
          className: 'square square_4',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B8Group, 'B8CH10');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B9CH10',
          displayName: '4',
          className: 'square square_4',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B9Group, 'B9CH10');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B10CH10',
          displayName: '4',
          className: 'square square_4',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B10Group, 'B10CH10');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        },
        {
          code: 'B6CH11',
          displayName: '5',
          className: 'square square_5',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B6Group, 'B6CH11');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B7CH11',
          displayName: '5',
          className: 'square square_5',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B7Group, 'B7CH11');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B8CH11',
          displayName: '5',
          className: 'square square_5',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B8Group, 'B8CH11');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B9CH11',
          displayName: '5',
          className: 'square square_5',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B9Group, 'B9CH11');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B10CH11',
          displayName: '5',
          className: 'square square_5',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B10Group, 'B10CH11');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        },
        {
          code: 'B6CH12',
          displayName: '6',
          className: 'square square_6',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B6Group, 'B6CH12');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B7CH12',
          displayName: '6',
          className: 'square square_6',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B7Group, 'B7CH12');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B8CH12',
          displayName: '6',
          className: 'square square_6',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B8Group, 'B8CH12');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B9CH12',
          displayName: '6',
          className: 'square square_6',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B9Group, 'B9CH12');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B10CH12',
          displayName: '6',
          className: 'square square_6',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B10Group, 'B10CH12');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        },
        {
          code: 'B6CH13',
          displayName: '7',
          className: 'square square_7',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B6Group, 'B6CH13');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B7CH13',
          displayName: '7',
          className: 'square square_7',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B7Group, 'B7CH13');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B8CH13',
          displayName: '7',
          className: 'square square_7',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B8Group, 'B8CH13');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B9CH13',
          displayName: '7',
          className: 'square square_7',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B9Group, 'B9CH13');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B10CH13',
          displayName: '7',
          className: 'square square_7',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B10Group, 'B10CH13');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        },
        {
          code: 'B6CH14',
          displayName: '8',
          className: 'square square_8',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B6Group, 'B6CH14');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B7CH14',
          displayName: '8',
          className: 'square square_8',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B7Group, 'B7CH14');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B8CH14',
          displayName: '8',
          className: 'square square_8',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B8Group, 'B8CH14');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B9CH14',
          displayName: '8',
          className: 'square square_8',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B9Group, 'B9CH14');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B10CH14',
          displayName: '8',
          className: 'square square_8',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B10Group, 'B10CH14');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        },
        {
          code: 'B6CH15',
          displayName: '9',
          className: 'square square_9',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B6Group, 'B6CH15');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B7CH15',
          displayName: '9',
          className: 'square square_9',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B7Group, 'B7CH15');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B8CH15',
          displayName: '9',
          className: 'square square_9',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B8Group, 'B8CH15');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B9CH15',
          displayName: '9',
          className: 'square square_9',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B9Group, 'B9CH15');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B10CH15',
          displayName: '9',
          className: 'square square_9',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B10Group, 'B10CH15');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        },
        {
          code: 'B6CH16',
          displayName: '10',
          className: 'square square_10',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B6Group, 'B6CH16');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B7CH16',
          displayName: '10',
          className: 'square square_10',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B7Group, 'B7CH16');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B8CH16',
          displayName: '10',
          className: 'square square_10',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B8Group, 'B8CH16');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B9CH16',
          displayName: '10',
          className: 'square square_10',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B9Group, 'B9CH16');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        }, {
          code: 'B10CH16',
          displayName: '10',
          className: 'square square_10',
          generateLengReValue: (lengRe, yiLouCount) => {
            const { value, className, classNameYiLou } = transformLengReValue(lengRe, yiLouCount, B10Group, 'B10CH16');
            return {
              value,
              className,
              classNameYiLou
            };
          },
        },
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default DH;