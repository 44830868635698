import React, { useState } from 'react';

import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import './styles.scss';

const CollapseType = ({ headerStyle, header, itemType, itemWrapperStyle, kuaiJieSelectedGroupIndex, clickDisable, list }) => {
  const [_collapseOpened, setCollapseStatus] = useState(true);
  return (
    <div className="collapseTypeWrapper">
      {header !== undefined && (
      <div className={`collapseTypeHeader ${headerStyle}`} onClick={() => setCollapseStatus(!_collapseOpened)}>
          {header}
          {_collapseOpened
            ? <FaChevronUp className="ml-2" />
            : <FaChevronDown className="ml-2" />}
      </div>
      )}
      <div className={`collapseTypeContent ${_collapseOpened ? 'collapseTypeContentOpend' : 'collapseTypeContentClosed'}`}>
        {
        list.map(item => {
          const BetItem = itemType;
          return <BetItem {...item} itemWrapperStyle={itemWrapperStyle} kuaiJieSelectedGroupIndex={kuaiJieSelectedGroupIndex} clickDisable={clickDisable} />;
        })
      }
      </div>
    </div>
  );
};

export default CollapseType;