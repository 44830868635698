import React, { useEffect, useState } from 'react';

import { Button } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Exclamation } from '../../../assets/icon/TopUpIcons/icon_exclamation.svg';
import Label from '../../Label';
import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
  lotteryType: state.gameReducers.lotteryType,
  userGames: state.homeReducers.userGames,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const GameNoticeModal = ({ platform, lotteryType, updateModalAlert, data, userGames }) => {
  const { openString } = data || {};
  const { t } = useTranslation();
  const [lotteryName, setLotteryName] = useState('');

  function handleKeyDown(event) {
    if (event.key === 'Enter' || event.key === 'Escape') {
      updateModalAlert({
        visible: false
      });
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  useEffect(() => {
    if (userGames && userGames?.lotteryList) {
      const temp = userGames?.lotteryList.find(x => x.code === lotteryType.item)?.lotteryName ?? '';
      setLotteryName(temp);
    }
  }, [lotteryType]);
  return (
    <div className={`gameNoticeWrapper${platform}`}>
      <div className="modalHeader d-flex mx-0">
        <div className="d-flex  align-items-center col-6">
          {t('REMINDER')}
        </div>
        <div className="d-flex justify-content-end align-items-center col-6">
          <IoClose
            className="h4 mb-0 float-right closeIcon"
            onClick={() => {
              updateModalAlert({
                visible: false
              });
            }}
          />
        </div>
      </div>
      <div className="modalContent mx-0 d-flex">
        <div className={`d-flex justify-content-center ${platform === 'Web' ? 'mb-4 mt-5' : 'mt-2 mb-1'}`}>
          <Exclamation className="exclamationIcon" />
        </div>
        <div className={`d-flex justify-content-center ${platform === 'Web' ? 'mb-4' : 'mb-2'}`}>
          <Label className="lotteryClosedTitle" value={`${lotteryName}${t('LOTTERY_HAD_CLOSED')}`} />
        </div>
        {
          openString !== '' && (
            <>
              <div className={`d-flex justify-content-center ${platform === 'Web' ? 'mb-4' : 'mb-2'}`}>
                <Label value={t('NEXT_DRAW_TIME_IS')} />
              </div>
              <div className={`d-flex justify-content-center ${platform === 'Web' ? 'mb-4' : 'mb-2'}`}>
                <Label className="nextDrawTime" value={openString} />
              </div>
            </>
          )
        }
      </div>
      <div className="modalFooter d-flex align-items-center mx-0">
        <Button
          className="confirmButton my-3"
          onClick={() => {
            updateModalAlert({
              visible: false
            });
          }}
        >
          {t('CONFIRM')}
        </Button>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GameNoticeModal);