import { CheckBox, MultipleTypeSelectionPanel, Odds } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';
import gameItemIconMap from '../../../GameItemIcon';

const LIANMA = [
  {
    componentType: QuickBet,
  },
  {
    componentType: MultipleTypeSelectionPanel,
    data: {
      list: [
        {
          rowTitle: '类别',
          rowTitleClassName: 'text-center',
          rowType: CheckBox,
          wrapperStyle: 'checkBoxRow',
          onCheckBoxChange: (updateSectionSelection, subItem) => {
            updateSectionSelection(subItem);
          },
          rowData: [{
            code: 'LMA1',
            displayName: '任选二',
            minimumSelection: 2,
            maximumSelection: 7,
            className: 'cellWrapper',
          }, {
            code: 'LMA2',
            displayName: '任选三',
            minimumSelection: 3,
            maximumSelection: 7,
            className: 'cellWrapper',
          }, {
            code: 'LMA3',
            displayName: '任选四',
            minimumSelection: 4,
            maximumSelection: 7,
            className: 'cellWrapper',
          }, {
            code: 'LMA4',
            displayName: '任选五',
            minimumSelection: 5,
            maximumSelection: 7,
            className: 'cellWrapper',
          }, {
            code: 'LMA5',
            displayName: '选二连组',
            minimumSelection: 2,
            maximumSelection: 7,
            className: 'cellWrapper',
          }, {
            code: 'LMA6',
            displayName: '选三前组',
            minimumSelection: 3,
            maximumSelection: 7,
            className: 'cellWrapper',
          }]
        },
        {
          rowTitle: '赔率',
          rowTitleClassName: 'text-center',
          rowType: Odds,
          wrapperStyle: 'oddsRow',
          className: 'chooseBetOdds',
          rowData: [{
            code: 'LMA1',
            className: 'chooseBetOdds',
          }, {
            code: 'LMA2',
            className: 'chooseBetOdds',
          }, {
            code: 'LMA3',
            className: 'chooseBetOdds',
          }, {
            code: 'LMA4',
            className: 'chooseBetOdds',
          }, {
            code: 'LMA5',
            className: 'chooseBetOdds',
          }, {
            code: 'LMA6',
            className: 'chooseBetOdds',
          }]
        },
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '勾选'],
      mainColumnLength: 4,
      subColumnLength: 2,
      subColumnStyle: ['w-50', 'w-50'],
      betItemStyle: ['w-50', 'w-50'],
      betItemList: ['DisplayOrText', 'CheckBox'],
      betItemColumn: 2,
      list: [
        {
          code: 'B1M1',
          displayName: '1',
          className: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'B1M6',
          displayName: '6',
          className: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'B1M11',
          displayName: '11',
          className: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'B1M16',
          displayName: '16',
          className: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'B1M2',
          displayName: '2',
          className: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'B1M7',
          displayName: '7',
          className: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'B1M12',
          displayName: '12',
          className: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'B1M17',
          displayName: '17',
          className: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'B1M3',
          displayName: '3',
          className: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'B1M8',
          displayName: '8',
          className: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'B1M13',
          displayName: '13',
          className: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'B1M18',
          displayName: '18',
          className: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'B1M4',
          displayName: '4',
          className: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'B1M9',
          displayName: '9',
          className: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'B1M14',
          displayName: '14',
          className: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'B1M19',
          displayName: '19',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'B1M5',
          displayName: '5',
          className: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'B1M10',
          displayName: '10',
          className: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'B1M15',
          displayName: '15',
          className: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'B1M20',
          displayName: '20',
          className: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default LIANMA;