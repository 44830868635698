import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Row } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const TextInputModal = ({ platform, updateModalAlert, data }) => {
  const { t } = useTranslation();
  const [_text, setText] = useState('');

  const confirmModal = () => {
    updateModalAlert({
      visible: false
    });
    if (data && data.onConfirmationClick) data.onConfirmationClick(_text);
  };

  const cancelModal = () => {
    updateModalAlert({
      visible: false
    });
    if (data && data.onCancelClick) data.onCancelClick();
  };

  function handleKeyDown(event) {
    if (event.key === 'Escape') {
      cancelModal();
    } else if (event.key === 'Enter') {
      confirmModal();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="textInputModalWrapperMobile">
      <Row className={`modalHeader ${platform === 'Mobile' && 'm-0'}`}>
        <Col
          className={`${'d-flex  align-items-center'}`}
        >
          {t('REMINDER')}
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <IoClose
            className="h4 mb-0 float-right closeIcon"
            onClick={() => {
              updateModalAlert({
                visible: false
              });
            }}
          />
        </Col>
      </Row>
      <Row className={`modalContent ${platform === 'Mobile' && 'd-flex justify-content-center mx-0'}`}>
        <Row className="d-flex justify-content-center">
          <Input
            value={_text}
            className="my-3"
            onChange={({ target: { value: text } }) => {
              setText(text.trim() || '');
            }}
          />
        </Row>
      </Row>
      <Row className={`modalFooter d-flex align-items-center mx-0 ${platform === 'Mobile' && 'justify-content-center'}`}>
        <Button
          className="confirmButton my-3 mr-3"
          onClick={() => {
            confirmModal();
          }}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
        {
          data && data.showCancelButton !== false && (
            <Button
              className="cancelButton my-3"
              onClick={() => {
                cancelModal();
              }}
            >
              {(data && data.cancelText) || t('CANCEL')}
            </Button>
          )
        }
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TextInputModal);