import TMBS from './TMBS';
import ZSP from './ZSP';

const PCDD = {
  children: [
    {
      tabId: 'ZhuShiPan',
      label: '主势盘',
      children: ZSP.ZSP,
      settingCode: 'DW,DX,DS,JDX,LM,TMSB,HMDZ'
    },
    {
      tabId: 'TeMaBaoSan',
      label: '特码包三',
      children: TMBS.TMBS,
      settingCode: 'TMBS'
    },
  ]
};

export default PCDD;