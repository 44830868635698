import React, { useEffect, useState } from 'react';

import { Button, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const mapStateToProps = state => ({
  lotteryType: state.gameReducers.lotteryType,
});

const MobileBetsListModal = ({ data, lotteryType, updateModalAlert }) => {
  const { t } = useTranslation();
  const [_total, setTotal] = useState({
    total: 0, amount: 0, result: 0
  });

  useEffect(() => {
    const tatal = data.list.length;
    let amount = 0;
    let result = 0;
    data.list.forEach(item => {
      amount += item.amount;
      result += item.result;
    });
    setTotal({
      tatal, amount, result: Number(result).toFixed(2)
    });
  }, []);

  return (
    <div className="mobileBetsListModal">
      <Row className="modalHeader m-0">
        <Col className={data?.headerClassName}>
          {`${t(lotteryType.item)}-下注成功`}
        </Col>
      </Row>
      <Row className="modalContent d-flex justify-content-center mx-0">
        <div className="d-flex w-100 py-2 border-bottom px-1">
          <div className="col-6 px-0 d-flex justify-content-start align-items-center">玩法</div>
          <div className="col-3 px-0 d-flex justify-content-center align-items-center">金额</div>
          <div className="col-3 px-0 d-flex justify-content-center align-items-center">可赢金额</div>
        </div>
        <div className="w-100 betListScrollContent px-1">
          {
              data.list.map(item => (
                <div className="d-flex w-100 py-1">
                  <div className="col-6 px-0 d-flex flex-column justify-content-center">
                    <div className="text-break">
                      {`${item.midType} ${item.displayName}`}
                    </div>
                    <div className="text-danger">
                      {`@${item.odds}`}
                    </div>
                  </div>
                  <div className="col-3 px-0 d-flex justify-content-center align-items-center">{item.amount}</div>
                  <div className="col-3 px-0 d-flex justify-content-center align-items-center">{item.result}</div>
                </div>
              ))
            }
        </div>
        <div className="d-flex w-100 border-top border-bottom py-2 px-1">
          <div className="col-6 px-0 d-flex justify-content-start align-items-center">
            统计
            {_total.total}
            注
          </div>
          <div className="col-3 px-0 d-flex justify-content-center align-items-center">{_total.amount}</div>
          <div className="col-3 px-0 d-flex justify-content-center align-items-center">{_total.result}</div>
        </div>
      </Row>
      <Row className="modalFooter align-items-center justify-content-center mx-0 py-3 px-2 flex-nowrap">
        <Button
          className="confirmButton py-2 w-100"
          onClick={() => updateModalAlert({
            visible: false
          })}

        >
          {t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileBetsListModal);