import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const EZZH = [
  {
    componentType: CollapseType,
    header: '二字前三',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'ZH2Q3_00',
          displayName: '00',
        }, {
          code: 'ZH2Q3_12',
          displayName: '12',
        }, {
          code: 'ZH2Q3_25',
          displayName: '25',
        }, {
          code: 'ZH2Q3_39',
          displayName: '39',
        }, {
          code: 'ZH2Q3_59',
          displayName: '59',
        }, {
          code: 'ZH2Q3_01',
          displayName: '01',
        }, {
          code: 'ZH2Q3_13',
          displayName: '13',
        }, {
          code: 'ZH2Q3_26',
          displayName: '26',
        }, {
          code: 'ZH2Q3_44',
          displayName: '44',
        }, {
          code: 'ZH2Q3_66',
          displayName: '66',
        }, {
          code: 'ZH2Q3_02',
          displayName: '02',
        }, {
          code: 'ZH2Q3_14',
          displayName: '14',
        }, {
          code: 'ZH2Q3_27',
          displayName: '27',
        }, {
          code: 'ZH2Q3_45',
          displayName: '45',
        }, {
          code: 'ZH2Q3_67',
          displayName: '67',
        }, {
          code: 'ZH2Q3_03',
          displayName: '03',
        }, {
          code: 'ZH2Q3_15',
          displayName: '15',
        }, {
          code: 'ZH2Q3_28',
          displayName: '28',
        }, {
          code: 'ZH2Q3_46',
          displayName: '46',
        }, {
          code: 'ZH2Q3_68',
          displayName: '68',
        }, {
          code: 'ZH2Q3_04',
          displayName: '04',
        }, {
          code: 'ZH2Q3_16',
          displayName: '16',
        }, {
          code: 'ZH2Q3_29',
          displayName: '29',
        }, {
          code: 'ZH2Q3_47',
          displayName: '47',
        }, {
          code: 'ZH2Q3_69',
          displayName: '69',
        }, {
          code: 'ZH2Q3_05',
          displayName: '05',
        }, {
          code: 'ZH2Q3_17',
          displayName: '17',
        }, {
          code: 'ZH2Q3_33',
          displayName: '33',
        }, {
          code: 'ZH2Q3_48',
          displayName: '48',
        }, {
          code: 'ZH2Q3_77',
          displayName: '77',
        }, {
          code: 'ZH2Q3_06',
          displayName: '06',
        }, {
          code: 'ZH2Q3_18',
          displayName: '18',
        }, {
          code: 'ZH2Q3_34',
          displayName: '34',
        }, {
          code: 'ZH2Q3_49',
          displayName: '49',
        }, {
          code: 'ZH2Q3_78',
          displayName: '78',
        }, {
          code: 'ZH2Q3_07',
          displayName: '07',
        }, {
          code: 'ZH2Q3_19',
          displayName: '19',
        }, {
          code: 'ZH2Q3_35',
          displayName: '35',
        }, {
          code: 'ZH2Q3_55',
          displayName: '55',
        }, {
          code: 'ZH2Q3_79',
          displayName: '79',
        }, {
          code: 'ZH2Q3_08',
          displayName: '08',
        }, {
          code: 'ZH2Q3_22',
          displayName: '22',
        }, {
          code: 'ZH2Q3_36',
          displayName: '36',
        }, {
          code: 'ZH2Q3_56',
          displayName: '56',
        }, {
          code: 'ZH2Q3_88',
          displayName: '88',
        }, {
          code: 'ZH2Q3_09',
          displayName: '09',
        }, {
          code: 'ZH2Q3_23',
          displayName: '23',
        }, {
          code: 'ZH2Q3_37',
          displayName: '37',
        }, {
          code: 'ZH2Q3_57',
          displayName: '57',
        }, {
          code: 'ZH2Q3_89',
          displayName: '89',
        }, {
          code: 'ZH2Q3_11',
          displayName: '11',
        }, {
          code: 'ZH2Q3_24',
          displayName: '24',
        }, {
          code: 'ZH2Q3_38',
          displayName: '38',
        }, {
          code: 'ZH2Q3_58',
          displayName: '58',
        }, {
          code: 'ZH2Q3_99',
          displayName: '99',
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '二字中三',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'ZH2Z3_00',
          displayName: '00',
        }, {
          code: 'ZH2Z3_12',
          displayName: '12',
        }, {
          code: 'ZH2Z3_25',
          displayName: '25',
        }, {
          code: 'ZH2Z3_39',
          displayName: '39',
        }, {
          code: 'ZH2Z3_59',
          displayName: '59',
        }, {
          code: 'ZH2Z3_01',
          displayName: '01',
        }, {
          code: 'ZH2Z3_13',
          displayName: '13',
        }, {
          code: 'ZH2Z3_26',
          displayName: '26',
        }, {
          code: 'ZH2Z3_44',
          displayName: '44',
        }, {
          code: 'ZH2Z3_66',
          displayName: '66',
        }, {
          code: 'ZH2Z3_02',
          displayName: '02',
        }, {
          code: 'ZH2Z3_14',
          displayName: '14',
        }, {
          code: 'ZH2Z3_27',
          displayName: '27',
        }, {
          code: 'ZH2Z3_45',
          displayName: '45',
        }, {
          code: 'ZH2Z3_67',
          displayName: '67',
        }, {
          code: 'ZH2Z3_03',
          displayName: '03',
        }, {
          code: 'ZH2Z3_15',
          displayName: '15',
        }, {
          code: 'ZH2Z3_28',
          displayName: '28',
        }, {
          code: 'ZH2Z3_46',
          displayName: '46',
        }, {
          code: 'ZH2Z3_68',
          displayName: '68',
        }, {
          code: 'ZH2Z3_04',
          displayName: '04',
        }, {
          code: 'ZH2Z3_16',
          displayName: '16',
        }, {
          code: 'ZH2Z3_29',
          displayName: '29',
        }, {
          code: 'ZH2Z3_47',
          displayName: '47',
        }, {
          code: 'ZH2Z3_69',
          displayName: '69',
        }, {
          code: 'ZH2Z3_05',
          displayName: '05',
        }, {
          code: 'ZH2Z3_17',
          displayName: '17',
        }, {
          code: 'ZH2Z3_33',
          displayName: '33',
        }, {
          code: 'ZH2Z3_48',
          displayName: '48',
        }, {
          code: 'ZH2Z3_77',
          displayName: '77',
        }, {
          code: 'ZH2Z3_06',
          displayName: '06',
        }, {
          code: 'ZH2Z3_18',
          displayName: '18',
        }, {
          code: 'ZH2Z3_34',
          displayName: '34',
        }, {
          code: 'ZH2Z3_49',
          displayName: '49',
        }, {
          code: 'ZH2Z3_78',
          displayName: '78',
        }, {
          code: 'ZH2Z3_07',
          displayName: '07',
        }, {
          code: 'ZH2Z3_19',
          displayName: '19',
        }, {
          code: 'ZH2Z3_35',
          displayName: '35',
        }, {
          code: 'ZH2Z3_55',
          displayName: '55',
        }, {
          code: 'ZH2Z3_79',
          displayName: '79',
        }, {
          code: 'ZH2Z3_08',
          displayName: '08',
        }, {
          code: 'ZH2Z3_22',
          displayName: '22',
        }, {
          code: 'ZH2Z3_36',
          displayName: '36',
        }, {
          code: 'ZH2Z3_56',
          displayName: '56',
        }, {
          code: 'ZH2Z3_88',
          displayName: '88',
        }, {
          code: 'ZH2Z3_09',
          displayName: '09',
        }, {
          code: 'ZH2Z3_23',
          displayName: '23',
        }, {
          code: 'ZH2Z3_37',
          displayName: '37',
        }, {
          code: 'ZH2Z3_57',
          displayName: '57',
        }, {
          code: 'ZH2Z3_89',
          displayName: '89',
        }, {
          code: 'ZH2Z3_11',
          displayName: '11',
        }, {
          code: 'ZH2Z3_24',
          displayName: '24',
        }, {
          code: 'ZH2Z3_38',
          displayName: '38',
        }, {
          code: 'ZH2Z3_58',
          displayName: '58',
        }, {
          code: 'ZH2Z3_99',
          displayName: '99',
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '二字后三',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'ZH2H3_00',
          displayName: '00',
        }, {
          code: 'ZH2H3_12',
          displayName: '12',
        }, {
          code: 'ZH2H3_25',
          displayName: '25',
        }, {
          code: 'ZH2H3_39',
          displayName: '39',
        }, {
          code: 'ZH2H3_59',
          displayName: '59',
        }, {
          code: 'ZH2H3_01',
          displayName: '01',
        }, {
          code: 'ZH2H3_13',
          displayName: '13',
        }, {
          code: 'ZH2H3_26',
          displayName: '26',
        }, {
          code: 'ZH2H3_44',
          displayName: '44',
        }, {
          code: 'ZH2H3_66',
          displayName: '66',
        }, {
          code: 'ZH2H3_02',
          displayName: '02',
        }, {
          code: 'ZH2H3_14',
          displayName: '14',
        }, {
          code: 'ZH2H3_27',
          displayName: '27',
        }, {
          code: 'ZH2H3_45',
          displayName: '45',
        }, {
          code: 'ZH2H3_67',
          displayName: '67',
        }, {
          code: 'ZH2H3_03',
          displayName: '03',
        }, {
          code: 'ZH2H3_15',
          displayName: '15',
        }, {
          code: 'ZH2H3_28',
          displayName: '28',
        }, {
          code: 'ZH2H3_46',
          displayName: '46',
        }, {
          code: 'ZH2H3_68',
          displayName: '68',
        }, {
          code: 'ZH2H3_04',
          displayName: '04',
        }, {
          code: 'ZH2H3_16',
          displayName: '16',
        }, {
          code: 'ZH2H3_29',
          displayName: '29',
        }, {
          code: 'ZH2H3_47',
          displayName: '47',
        }, {
          code: 'ZH2H3_69',
          displayName: '69',
        }, {
          code: 'ZH2H3_05',
          displayName: '05',
        }, {
          code: 'ZH2H3_17',
          displayName: '17',
        }, {
          code: 'ZH2H3_33',
          displayName: '33',
        }, {
          code: 'ZH2H3_48',
          displayName: '48',
        }, {
          code: 'ZH2H3_77',
          displayName: '77',
        }, {
          code: 'ZH2H3_06',
          displayName: '06',
        }, {
          code: 'ZH2H3_18',
          displayName: '18',
        }, {
          code: 'ZH2H3_34',
          displayName: '34',
        }, {
          code: 'ZH2H3_49',
          displayName: '49',
        }, {
          code: 'ZH2H3_78',
          displayName: '78',
        }, {
          code: 'ZH2H3_07',
          displayName: '07',
        }, {
          code: 'ZH2H3_19',
          displayName: '19',
        }, {
          code: 'ZH2H3_35',
          displayName: '35',
        }, {
          code: 'ZH2H3_55',
          displayName: '55',
        }, {
          code: 'ZH2H3_79',
          displayName: '79',
        }, {
          code: 'ZH2H3_08',
          displayName: '08',
        }, {
          code: 'ZH2H3_22',
          displayName: '22',
        }, {
          code: 'ZH2H3_36',
          displayName: '36',
        }, {
          code: 'ZH2H3_56',
          displayName: '56',
        }, {
          code: 'ZH2H3_88',
          displayName: '88',
        }, {
          code: 'ZH2H3_09',
          displayName: '09',
        }, {
          code: 'ZH2H3_23',
          displayName: '23',
        }, {
          code: 'ZH2H3_37',
          displayName: '37',
        }, {
          code: 'ZH2H3_57',
          displayName: '57',
        }, {
          code: 'ZH2H3_89',
          displayName: '89',
        }, {
          code: 'ZH2H3_11',
          displayName: '11',
        }, {
          code: 'ZH2H3_24',
          displayName: '24',
        }, {
          code: 'ZH2H3_38',
          displayName: '38',
        }, {
          code: 'ZH2H3_58',
          displayName: '58',
        }, {
          code: 'ZH2H3_99',
          displayName: '99',
        }
      ]
    }
  },
];

export default EZZH;