import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';
import gameItemIconMap from '../../../GameItemIcon';

const SJDX = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '三军，大小',
      mainColumnLength: 4,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-style-1th', 'column-3items-style-2th', 'column-3items-style-3th'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayOrText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'SJ11',
          image: gameItemIconMap.CJ_DICE_1,
          displayName: '1',
        }, {
          code: 'SJ13',
          image: gameItemIconMap.CJ_DICE_3,
          displayName: '3',
        }, {
          code: 'SJ15',
          image: gameItemIconMap.CJ_DICE_5,
          displayName: '5',
        }, {
          code: 'DX48',
          displayName: '大',
        }, {
          code: 'SJ12',
          image: gameItemIconMap.CJ_DICE_2,
          displayName: '2',
          newLine: true
        }, {
          code: 'SJ14',
          image: gameItemIconMap.CJ_DICE_4,
          displayName: '4',
        }, {
          code: 'SJ16',
          image: gameItemIconMap.CJ_DICE_6,
          displayName: '6',
        }, {
          code: 'DX49',
          displayName: '小',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '围骰、全骰',
      mainColumnLength: 3,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-style-1th', 'column-3items-style-2th', 'column-3items-style-3th'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      emptyItemStyle: ['column-3items-empty-label', 'column-3items-empty-odds', 'column-3items-empty-input'],
      betItemList: ['DisplayOrText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'WS7',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_1, gameItemIconMap.CJ_DICE_1, gameItemIconMap.CJ_DICE_1],
          displayName: '111',
        }, {
          code: 'WS9',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_3, gameItemIconMap.CJ_DICE_3, gameItemIconMap.CJ_DICE_3],
          displayName: '333',
        }, {
          code: 'WS11',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_5, gameItemIconMap.CJ_DICE_5, gameItemIconMap.CJ_DICE_5],
          displayName: '555',
        }, {
          code: 'WS8',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_2, gameItemIconMap.CJ_DICE_2, gameItemIconMap.CJ_DICE_2],
          displayName: '222',
          newLine: true
        }, {
          code: 'WS10',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_4, gameItemIconMap.CJ_DICE_4, gameItemIconMap.CJ_DICE_4],
          displayName: '444',
        }, {
          code: 'WS12',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_6, gameItemIconMap.CJ_DICE_6, gameItemIconMap.CJ_DICE_6],
          displayName: '666',
        }, {
          code: 'QS50',
          displayName: '全骰',
        },
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '点数',
      mainColumnLength: 4,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-style-1th', 'column-3items-style-2th', 'column-3items-style-3th'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      emptyItemStyle: ['column-3items-empty-label', 'column-3items-empty-odds', 'column-3items-empty-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'DS34',
          displayName: '4点',
        }, {
          code: 'DS38',
          displayName: '8点',
        }, {
          code: 'DS42',
          displayName: '12点',
        }, {
          code: 'DS46',
          displayName: '16点',
        }, {
          code: 'DS35',
          displayName: '5点',
          newLine: true
        }, {
          code: 'DS39',
          displayName: '9点',
        }, {
          code: 'DS43',
          displayName: '13点',
        }, {
          code: 'DS47',
          displayName: '17点',
        }, {
          code: 'DS36',
          displayName: '6点',
        }, {
          code: 'DS40',
          displayName: '10点',
        }, {
          code: 'DS44',
          displayName: '14点',
        }, {
          code: 'DS37',
          displayName: '7点',
          newLine: true
        }, {
          code: 'DS41',
          displayName: '11点',
        }, {
          code: 'DS45',
          displayName: '15点',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '长牌',
      mainColumnLength: 3,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-style-1th', 'column-3items-style-2th', 'column-3items-style-3th'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayOrText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'CP13',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_1, gameItemIconMap.CJ_DICE_2],
          displayName: '12',
        }, {
          code: 'CP18',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_2, gameItemIconMap.CJ_DICE_3],
          displayName: '23',
        }, {
          code: 'CP23',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_3, gameItemIconMap.CJ_DICE_5],
          displayName: '35',
        }, {
          code: 'CP14',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_1, gameItemIconMap.CJ_DICE_3],
          displayName: '13',
          newLine: true
        }, {
          code: 'CP19',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_2, gameItemIconMap.CJ_DICE_4],
          displayName: '24',
        }, {
          code: 'CP24',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_3, gameItemIconMap.CJ_DICE_6],
          displayName: '36',
        }, {
          code: 'CP15',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_1, gameItemIconMap.CJ_DICE_4],
          displayName: '14',
          newLine: true
        }, {
          code: 'CP20',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_2, gameItemIconMap.CJ_DICE_5],
          displayName: '25',
        }, {
          code: 'CP25',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_4, gameItemIconMap.CJ_DICE_5],
          displayName: '45',
        }, {
          code: 'CP16',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_1, gameItemIconMap.CJ_DICE_5],
          displayName: '15',
          newLine: true
        }, {
          code: 'CP21',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_2, gameItemIconMap.CJ_DICE_6],
          displayName: '26',
        }, {
          code: 'CP26',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_4, gameItemIconMap.CJ_DICE_6],
          displayName: '46',
        }, {
          code: 'CP16',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_1, gameItemIconMap.CJ_DICE_6],
          displayName: '16',
          newLine: true
        }, {
          code: 'CP22',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_3, gameItemIconMap.CJ_DICE_4],
          displayName: '34',
        }, {
          code: 'CP27',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_5, gameItemIconMap.CJ_DICE_6],
          displayName: '56',
        },
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '短牌',
      mainColumnLength: 3,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-style-1th', 'column-3items-style-2th', 'column-3items-style-3th'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayOrText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'DP28',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_1, gameItemIconMap.CJ_DICE_1],
          displayName: '11',
        }, {
          code: 'DP30',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_3, gameItemIconMap.CJ_DICE_3],
          displayName: '33',
        }, {
          code: 'DP32',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_5, gameItemIconMap.CJ_DICE_5],
          displayName: '55',
        }, {
          code: 'DP29',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_2, gameItemIconMap.CJ_DICE_2],
          displayName: '22',
          newLine: true
        }, {
          code: 'DP31',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_4, gameItemIconMap.CJ_DICE_4],
          displayName: '44',
        }, {
          code: 'DP33',
          isHorizontal: true,
          image: [gameItemIconMap.CJ_DICE_6, gameItemIconMap.CJ_DICE_6],
          displayName: '66',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default SJDX;