import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const NNSH = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '牛牛',
      mainColumnLength: 4,
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      emptyItemStyle: ['column-3items-empty-label', 'column-3items-empty-odds', 'column-3items-empty-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'NN_0', // keyCode
          displayName: '无牛',
        }, {
          code: 'NN_1',
          displayName: '牛1',
        }, {
          code: 'NN_2',
          displayName: '牛2',
        }, {
          code: 'NN_3',
          displayName: '牛3',
        }, {
          code: 'NN_4',
          displayName: '牛4',
        }, {
          code: 'NN_5',
          displayName: '牛5',
        }, {
          code: 'NN_6',
          displayName: '牛6',
        }, {
          code: 'NN_7',
          displayName: '牛7',
        }, {
          code: 'NN_8',
          displayName: '牛8',
        }, {
          code: 'NN_9',
          displayName: '牛9',
        }, {
          code: 'NN_10',
          displayName: '牛牛',
        }, {
          code: 'NNLMDS_D',
          displayName: '牛单',
        }, {
          code: 'NNLMDS_S',
          displayName: '牛双',
        }, {
          code: 'NNLMDX_D',
          displayName: '牛大',
        }, {
          code: 'NNLMDX_X',
          displayName: '牛小',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '梭哈',
      mainColumnLength: 4,
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      emptyItemStyle: ['column-3items-empty-label', 'column-3items-empty-odds', 'column-3items-empty-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'SH_WM', // keyCode
          displayName: '五梅',
        }, {
          code: 'SH_SDY',
          displayName: '四带一',
        }, {
          code: 'SH_HL',
          displayName: '葫芦',
        }, {
          code: 'SH_SZ',
          displayName: '顺子',
        }, {
          code: 'SH_ST',
          displayName: '三条',
        }, {
          code: 'SH_LD',
          displayName: '两对',
        }, {
          code: 'SH_DD',
          displayName: '单对',
        }, {
          code: 'SH_WBK',
          displayName: '五不靠',
        }, {
          code: 'SH_SH',
          displayName: '散号',
        }
      ]
    }
  }, {
    componentType: QuickBet,
  }
];

export default NNSH;