import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';
import PengAndDan from '../../../../components/Mobile/ComponentType/PengAndDan';
import TeMaAndLianMa from '../../../../components/Mobile/ComponentType/TeMaAndLianMa';

const SZDW = [
  {
    componentType: TeMaAndLianMa,
    data: {
      list: [
        {
          displayName: '前三定位',
          numberOfSelectionBlock: 3,
          code: 'DW3Q3',
          minimumSelection: 2,
          maximumSelection: 20,
          children: [{
            code: 'DW3Q3'
          }]
        },
        {
          displayName: '中三定位',
          numberOfSelectionBlock: 3,
          code: 'DW3Z3',
          minimumSelection: 3,
          maximumSelection: 20,
          children: [{
            code: 'DW3Z3'
          }]
        },
        {
          displayName: '后三定位',
          numberOfSelectionBlock: 3,
          code: 'DW3H3',
          minimumSelection: 2,
          maximumSelection: 20,
          children: [{
            code: 'DW3H3'
          }]
        }
      ]
    }
  },
  {
    componentType: PengAndDan,
    data: {}
  },
  {
    componentType: CollapseType,
    header: '万位',
    headerStyle: '',
    selectionBlockIndex: 0,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      kuaiJieSelectedGroupIndex: 0,
      list: [
        {
          drawImgClassName: 'circle',
          displayName: '0'
        },
        {
          drawImgClassName: 'circle',
          displayName: '1'
        },
        {
          drawImgClassName: 'circle',
          displayName: '2'
        },
        {
          drawImgClassName: 'circle',
          displayName: '3'
        },
        {
          drawImgClassName: 'circle',
          displayName: '4'
        },
        {
          drawImgClassName: 'circle',
          displayName: '5'
        },
        {
          drawImgClassName: 'circle',
          displayName: '6'
        },
        {
          drawImgClassName: 'circle',
          displayName: '7'
        },
        {
          drawImgClassName: 'circle',
          displayName: '8'
        },
        {
          drawImgClassName: 'circle',
          displayName: '9'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '千位',
    headerStyle: '',
    selectionBlockIndex: 1,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      kuaiJieSelectedGroupIndex: 1,
      list: [
        {
          drawImgClassName: 'circle',
          displayName: '0'
        },
        {
          drawImgClassName: 'circle',
          displayName: '1'
        },
        {
          drawImgClassName: 'circle',
          displayName: '2'
        },
        {
          drawImgClassName: 'circle',
          displayName: '3'
        },
        {
          drawImgClassName: 'circle',
          displayName: '4'
        },
        {
          drawImgClassName: 'circle',
          displayName: '5'
        },
        {
          drawImgClassName: 'circle',
          displayName: '6'
        },
        {
          drawImgClassName: 'circle',
          displayName: '7'
        },
        {
          drawImgClassName: 'circle',
          displayName: '8'
        },
        {
          drawImgClassName: 'circle',
          displayName: '9'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '百位',
    headerStyle: '',
    selectionBlockIndex: 2,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      kuaiJieSelectedGroupIndex: 2,
      list: [
        {
          drawImgClassName: 'circle',
          displayName: '0'
        },
        {
          drawImgClassName: 'circle',
          displayName: '1'
        },
        {
          drawImgClassName: 'circle',
          displayName: '2'
        },
        {
          drawImgClassName: 'circle',
          displayName: '3'
        },
        {
          drawImgClassName: 'circle',
          displayName: '4'
        },
        {
          drawImgClassName: 'circle',
          displayName: '5'
        },
        {
          drawImgClassName: 'circle',
          displayName: '6'
        },
        {
          drawImgClassName: 'circle',
          displayName: '7'
        },
        {
          drawImgClassName: 'circle',
          displayName: '8'
        },
        {
          drawImgClassName: 'circle',
          displayName: '9'
        }
      ]
    }
  }
];

export default SZDW;