import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const KD = [
  {
    componentType: CollapseType,
    header: '前三',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'KDQ3_0', // keyCode
          displayName: '0',
          drawImgClassName: 'circle'
        }, {
          code: 'KDQ3_1',
          displayName: '1',
          drawImgClassName: 'circle'
        }, {
          code: 'KDQ3_2',
          displayName: '2',
          drawImgClassName: 'circle'
        }, {
          code: 'KDQ3_3',
          displayName: '3',
          drawImgClassName: 'circle'
        }, {
          code: 'KDQ3_4',
          displayName: '4',
          drawImgClassName: 'circle'
        }, {
          code: 'KDQ3_5',
          displayName: '5',
          drawImgClassName: 'circle'
        }, {
          code: 'KDQ3_6',
          displayName: '6',
          drawImgClassName: 'circle'
        }, {
          code: 'KDQ3_7',
          displayName: '7',
          drawImgClassName: 'circle'
        }, {
          code: 'KDQ3_8',
          displayName: '8',
          drawImgClassName: 'circle'
        }, {
          code: 'KDQ3_9',
          displayName: '9',
          drawImgClassName: 'circle'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '中三',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'KDZ3_0', // keyCode
          displayName: '0',
          drawImgClassName: 'circle'
        }, {
          code: 'KDZ3_1',
          displayName: '1',
          drawImgClassName: 'circle'
        }, {
          code: 'KDZ3_2',
          displayName: '2',
          drawImgClassName: 'circle'
        }, {
          code: 'KDZ3_3',
          displayName: '3',
          drawImgClassName: 'circle'
        }, {
          code: 'KDZ3_4',
          displayName: '4',
          drawImgClassName: 'circle'
        }, {
          code: 'KDZ3_5',
          displayName: '5',
          drawImgClassName: 'circle'
        }, {
          code: 'KDZ3_6',
          displayName: '6',
          drawImgClassName: 'circle'
        }, {
          code: 'KDZ3_7',
          displayName: '7',
          drawImgClassName: 'circle'
        }, {
          code: 'KDZ3_8',
          displayName: '8',
          drawImgClassName: 'circle'
        }, {
          code: 'KDZ3_9',
          displayName: '9',
          drawImgClassName: 'circle'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '后三',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      list: [
        {
          code: 'KDH3_0', // keyCode
          displayName: '0',
          drawImgClassName: 'circle'
        }, {
          code: 'KDH3_1',
          displayName: '1',
          drawImgClassName: 'circle'
        }, {
          code: 'KDH3_2',
          displayName: '2',
          drawImgClassName: 'circle'
        }, {
          code: 'KDH3_3',
          displayName: '3',
          drawImgClassName: 'circle'
        }, {
          code: 'KDH3_4',
          displayName: '4',
          drawImgClassName: 'circle'
        }, {
          code: 'KDH3_5',
          displayName: '5',
          drawImgClassName: 'circle'
        }, {
          code: 'KDH3_6',
          displayName: '6',
          drawImgClassName: 'circle'
        }, {
          code: 'KDH3_7',
          displayName: '7',
          drawImgClassName: 'circle'
        }, {
          code: 'KDH3_8',
          displayName: '8',
          drawImgClassName: 'circle'
        }, {
          code: 'KDH3_9',
          displayName: '9',
          drawImgClassName: 'circle'
        }
      ]
    }
  },
];

export default KD;