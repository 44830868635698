import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import TeMaAndLianMa from '../../../../components/Mobile/ComponentType/TeMaAndLianMa';

import OptionItem from '../../../../components/Mobile/OptionItem';

const KuaiJie = [
  {
    componentType: TeMaAndLianMa,
    data: {
      list: [{
        code: 'TMA',
        displayName: '特码A',
        settingCode: 'TMA',
        isTmb: 0
      }, {
        code: 'TMB',
        displayName: '特码B',
        settingCode: 'TMA',
        isTmb: 1
      }]
    }
  },
  {
    componentType: CollapseType,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper threeColumnStyle',
      kuaiJieSelectedGroupIndex: 0,
      list: [
        {
          showOdds: false,
          displayName: '红波',
        },
        {
          showOdds: false,
          displayName: '蓝波',
        },
        {
          showOdds: false,
          displayName: '绿波',
        },
        {
          showOdds: false,
          displayName: '红大',
        },
        {
          showOdds: false,
          displayName: '蓝大',
        },
        {
          showOdds: false,
          displayName: '绿大',
        },
        {
          showOdds: false,
          displayName: '大号',
        },
        {
          showOdds: false,
          displayName: '红小',
        },
        {
          showOdds: false,
          displayName: '蓝小',
        },
        {
          showOdds: false,
          displayName: '绿小',
        },
        {
          showOdds: false,
          displayName: '小号',
        },
        {
          showOdds: false,
          displayName: '红单',
        },
        {
          showOdds: false,
          displayName: '蓝单',
        },
        {
          showOdds: false,
          displayName: '绿单',
        },
        {
          showOdds: false,
          displayName: '单号',
        },
        {
          showOdds: false,
          displayName: '红双',
        },
        {
          showOdds: false,
          displayName: '蓝双',
        },
        {
          showOdds: false,
          displayName: '绿双',
        },
        {
          showOdds: false,
          displayName: '双号',
        },
        {
          showOdds: false,
          displayName: '大单',
        },
        {
          showOdds: false,
          displayName: '大双',
        },
        {
          showOdds: false,
          displayName: '小单',
        },
        {
          showOdds: false,
          displayName: '小双',
        },
        {
          showOdds: false,
          displayName: '合单',
        },
        {
          showOdds: false,
          displayName: '合双',
        },
        {
          showOdds: false,
          displayName: '鼠',
        },
        {
          showOdds: false,
          displayName: '牛',
        },
        {
          showOdds: false,
          displayName: '虎',
        },
        {
          showOdds: false,
          displayName: '兔',
        },
        {
          showOdds: false,
          displayName: '龙',
        },
        {
          showOdds: false,
          displayName: '蛇',
        },
        {
          showOdds: false,
          displayName: '马',
        },
        {
          showOdds: false,
          displayName: '羊',
        },
        {
          showOdds: false,
          displayName: '猴',
        },
        {
          showOdds: false,
          displayName: '鸡',
        },
        {
          showOdds: false,
          displayName: '狗',
        },
        {
          showOdds: false,
          displayName: '猪',
        },
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper threeColumnStyle',
      kuaiJieSelectedGroupIndex: 1,
      clickDisable: true,
      list: [
        {
          code: 'TMA1',
          showOdds: false,
          displayName: '01',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA2',
          showOdds: false,
          displayName: '02',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA3',
          showOdds: false,
          displayName: '03',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA4',
          showOdds: false,
          displayName: '04',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA5',
          showOdds: false,
          displayName: '05',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA6',
          showOdds: false,
          displayName: '06',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA7',
          showOdds: false,
          displayName: '07',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA8',
          showOdds: false,
          displayName: '08',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA9',
          showOdds: false,
          displayName: '09',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA10',
          showOdds: false,
          displayName: '10',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA11',
          showOdds: false,
          displayName: '11',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA12',
          showOdds: false,
          displayName: '12',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA13',
          showOdds: false,
          displayName: '13',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA14',
          showOdds: false,
          displayName: '14',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA15',
          showOdds: false,
          displayName: '15',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA16',
          showOdds: false,
          displayName: '16',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA17',
          showOdds: false,
          displayName: '17',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA18',
          showOdds: false,
          displayName: '18',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA19',
          showOdds: false,
          displayName: '19',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA20',
          showOdds: false,
          displayName: '20',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA21',
          showOdds: false,
          displayName: '21',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA22',
          showOdds: false,
          displayName: '22',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA23',
          showOdds: false,
          displayName: '23',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA24',
          showOdds: false,
          displayName: '24',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA25',
          showOdds: false,
          displayName: '25',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA26',
          showOdds: false,
          displayName: '26',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA27',
          showOdds: false,
          displayName: '27',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA28',
          showOdds: false,
          displayName: '28',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA29',
          showOdds: false,
          displayName: '29',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA30',
          showOdds: false,
          displayName: '30',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA31',
          showOdds: false,
          displayName: '31',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA32',
          showOdds: false,
          displayName: '32',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA33',
          showOdds: false,
          displayName: '33',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA34',
          showOdds: false,
          displayName: '34',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA35',
          showOdds: false,
          displayName: '35',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA36',
          showOdds: false,
          displayName: '36',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA37',
          showOdds: false,
          displayName: '37',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA38',
          showOdds: false,
          displayName: '38',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA39',
          showOdds: false,
          displayName: '39',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA40',
          showOdds: false,
          displayName: '40',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA41',
          showOdds: false,
          displayName: '41',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA42',
          showOdds: false,
          displayName: '42',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA43',
          showOdds: false,
          displayName: '43',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA44',
          showOdds: false,
          displayName: '44',
          drawImgClassName: 'circle circleGreen'
        },
        {
          code: 'TMA45',
          showOdds: false,
          displayName: '45',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA46',
          showOdds: false,
          displayName: '46',
          drawImgClassName: 'circle circleRed'
        },
        {
          code: 'TMA47',
          showOdds: false,
          displayName: '47',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA48',
          showOdds: false,
          displayName: '48',
          drawImgClassName: 'circle circleBlue'
        },
        {
          code: 'TMA49',
          showOdds: false,
          displayName: '49',
          drawImgClassName: 'circle circleGreen'
        }
      ]
    }
  },
];

export default KuaiJie;