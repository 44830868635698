import { ProbabilitySelectionPanel } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const ZMA = [
  {
    componentType: ProbabilitySelectionPanel,
  },
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '赔率', '金额'],
      mainColumnLength: 5,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      emptyItemStyle: ['column-3items-empty-label', 'column-3items-empty-odds', 'column-3items-empty-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'ZMA1',
          displayName: '01',
          className: 'circle circleRed',
        }, {
          code: 'ZMA11',
          displayName: '11',
          className: 'circle circleGreen',
        }, {
          code: 'ZMA21',
          displayName: '21',
          className: 'circle circleGreen',
        }, {
          code: 'ZMA31',
          displayName: '31',
          className: 'circle circleBlue',
        }, {
          code: 'ZMA41',
          displayName: '41',
          className: 'circle circleBlue',
        }, {
          code: 'ZMA2',
          displayName: '02',
          className: 'circle circleRed',
        }, {
          code: 'ZMA12',
          displayName: '12',
          className: 'circle circleRed',
        }, {
          code: 'ZMA22',
          displayName: '22',
          className: 'circle circleGreen',
        }, {
          code: 'ZMA32',
          displayName: '32',
          className: 'circle circleGreen',
        }, {
          code: 'ZMA42',
          displayName: '42',
          className: 'circle circleBlue',
        }, {
          code: 'ZMA3',
          displayName: '03',
          className: 'circle circleBlue',
        }, {
          code: 'ZMA13',
          displayName: '13',
          className: 'circle circleRed',
        }, {
          code: 'ZMA23',
          displayName: '23',
          className: 'circle circleRed',
        }, {
          code: 'ZMA33',
          displayName: '33',
          className: 'circle circleGreen',
        }, {
          code: 'ZMA43',
          displayName: '43',
          className: 'circle circleGreen',
        }, {
          code: 'ZMA4',
          displayName: '04',
          className: 'circle',
        }, {
          code: 'ZMA14',
          displayName: '14',
          className: 'circle',
        }, {
          code: 'ZMA24',
          displayName: '24',
          className: 'circle circleRed',
        }, {
          code: 'ZMA34',
          displayName: '34',
          className: 'circle circleRed',
        }, {
          code: 'ZMA44',
          displayName: '44',
          className: 'circle circleGreen',
        }, {
          code: 'ZMA5',
          displayName: '05',
          className: 'circle circleGreen',
        }, {
          code: 'ZMA15',
          displayName: '15',
          className: 'circle',
        }, {
          code: 'ZMA25',
          displayName: '25',
          className: 'circle',
        }, {
          code: 'ZMA35',
          displayName: '35',
          className: 'circle circleRed',
        }, {
          code: 'ZMA45',
          displayName: '45',
          className: 'circle circleRed',
        }, {
          code: 'ZMA6',
          displayName: '06',
          className: 'circle circleGreen',
        }, {
          code: 'ZMA16',
          displayName: '16',
          className: 'circle circleGreen',
        }, {
          code: 'ZMA26',
          displayName: '26',
          className: 'circle',
        }, {
          code: 'ZMA36',
          displayName: '36',
          className: 'circle',
        }, {
          code: 'ZMA46',
          displayName: '46',
          className: 'circle circleRed',
        }, {
          code: 'ZMA7',
          displayName: '07',
          className: 'circle circleRed',
        }, {
          code: 'ZMA17',
          displayName: '17',
          className: 'circle circleGreen',
        }, {
          code: 'ZMA27',
          displayName: '27',
          className: 'circle circleGreen',
        }, {
          code: 'ZMA37',
          displayName: '37',
          className: 'circle',
        }, {
          code: 'ZMA47',
          displayName: '47',
          className: 'circle',
        }, {
          code: 'ZMA8',
          displayName: '08',
          className: 'circle circleRed',
        }, {
          code: 'ZMA18',
          displayName: '18',
          className: 'circle circleRed',
        }, {
          code: 'ZMA28',
          displayName: '28',
          className: 'circle circleGreen',
        }, {
          code: 'ZMA38',
          displayName: '38',
          className: 'circle circleGreen',
        }, {
          code: 'ZMA48',
          displayName: '48',
          className: 'circle',
        }, {
          code: 'ZMA9',
          displayName: '09',
          className: 'circle',
        }, {
          code: 'ZMA19',
          displayName: '19',
          className: 'circle circleRed',
        }, {
          code: 'ZMA29',
          displayName: '29',
          className: 'circle circleRed',
        }, {
          code: 'ZMA39',
          displayName: '39',
          className: 'circle circleGreen',
        }, {
          code: 'ZMA49',
          displayName: '49',
          className: 'circle circleGreen',
        }, {
          code: 'ZMA10',
          displayName: '10',
          className: 'circle',
        }, {
          code: 'ZMA20',
          displayName: '20',
          className: 'circle',
        }, {
          code: 'ZMA30',
          displayName: '30',
          className: 'circle circleRed',
        }, {
          code: 'ZMA40',
          displayName: '40',
          className: 'circle circleRed',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['号码', '赔率', '金额'],
      mainColumnLength: 4,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'LM69',
          displayName: '总单',
        }, {
          code: 'LM70',
          displayName: '总双',
        }, {
          code: 'LM71',
          displayName: '总大',
        }, {
          code: 'LM72',
          displayName: '总小',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default ZMA;