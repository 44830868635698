import { BetTypeContainer, CheckBox, MultipleTypeSelectionPanel, Odds } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const L7Z4 = [
  {
    componentType: QuickBet,
  },
  {
    componentType: MultipleTypeSelectionPanel,
    data: {
      list: [
        {
          rowTitle: '类别',
          rowTitleClassName: 'text-center',
          rowType: CheckBox,
          wrapperStyle: 'checkBoxRow F36X7',
          onCheckBoxChange: (updateSectionSelection, subItem) => {
            updateSectionSelection(subItem);
          },
          rowData: [{
            code: 'L7PZ41',
            displayName: '七中四',
            minimumSelection: 7,
            maximumSelection: 11,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5', '6'],
          }]
        },
        {
          rowTitle: '赔率',
          rowTitleClassName: 'text-center',
          rowType: Odds,
          wrapperStyle: 'oddsRow F36X7',
          rowData: [{
            code: 'L7PZ41',
            className: 'chooseBetOdds',
          }]
        },
        {
          rowTitleClassName: 'p-2 text-center',
          rowType: BetTypeContainer,
          wrapperStyle: 'p-2',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['生肖', '勾选', '号码'],
      mainColumnLength: 4,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-checkbox'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-checkbox'],
      emptyItemStyle: ['column-3items-empty-label', 'column-3items-empty-odds', 'column-3items-empty-checkbox'],
      betItemList: ['DisplayText', 'Odds', 'CheckBox'],
      betItemColumn: 3,
      list: [
        {
          code: 'L7PZ41',
          displayName: '01',
          className: 'circle circleRed',
        }, {
          code: 'L7PZ411',
          displayName: '11',
          className: 'circle circleGreen',
        }, {
          code: 'L7PZ421',
          displayName: '21',
          className: 'circle circleGreen',
        }, {
          code: 'L7PZ431',
          displayName: '31',
          className: 'circle circleBlue',
        }, {
          code: 'L7PZ42',
          displayName: '02',
          className: 'circle circleRed',
        }, {
          code: 'L7PZ412',
          displayName: '12',
          className: 'circle circleRed',
        }, {
          code: 'L7PZ422',
          displayName: '22',
          className: 'circle circleGreen',
        }, {
          code: 'L7PZ43',
          displayName: '03',
          className: 'circle circleBlue',
          newLine: true,
        }, {
          code: 'L7PZ413',
          displayName: '13',
          className: 'circle circleRed',
        }, {
          code: 'L7PZ423',
          displayName: '23',
          className: 'circle circleRed',
        }, {
          code: 'L7PZ44',
          displayName: '04',
          className: 'circle',
          newLine: true,
        }, {
          code: 'L7PZ414',
          displayName: '14',
          className: 'circle',
        }, {
          code: 'L7PZ424',
          displayName: '24',
          className: 'circle circleRed',
        }, {
          code: 'L7PZ45',
          displayName: '05',
          className: 'circle circleGreen',
          newLine: true,
        }, {
          code: 'L7PZ415',
          displayName: '15',
          className: 'circle',
        }, {
          code: 'L7PZ425',
          displayName: '25',
          className: 'circle',
        }, {
          code: 'L7PZ46',
          displayName: '06',
          className: 'circle circleGreen',
          newLine: true,
        }, {
          code: 'L7PZ416',
          displayName: '16',
          className: 'circle circleGreen',
        }, {
          code: 'L7PZ426',
          displayName: '26',
          className: 'circle',
        }, {
          code: 'L7PZ47',
          displayName: '07',
          className: 'circle circleRed',
          newLine: true,
        }, {
          code: 'L7PZ417',
          displayName: '17',
          className: 'circle circleGreen',
        }, {
          code: 'L7PZ427',
          displayName: '27',
          className: 'circle circleGreen',
        }, {
          code: 'L7PZ48',
          displayName: '08',
          className: 'circle circleRed',
          newLine: true,
        }, {
          code: 'L7PZ418',
          displayName: '18',
          className: 'circle circleRed',
        }, {
          code: 'L7PZ428',
          displayName: '28',
          className: 'circle circleGreen',
        }, {
          code: 'L7PZ49',
          displayName: '09',
          className: 'circle',
          newLine: true,
        }, {
          code: 'L7PZ419',
          displayName: '19',
          className: 'circle circleRed',
        }, {
          code: 'L7PZ429',
          displayName: '29',
          className: 'circle circleRed',
        }, {
          code: 'L7PZ410',
          displayName: '10',
          className: 'circle',
          newLine: true,
        }, {
          code: 'L7PZ420',
          displayName: '20',
          className: 'circle',
        }, {
          code: 'L7PZ430',
          displayName: '30',
          className: 'circle circleRed',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default L7Z4;