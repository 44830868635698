import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const DH15 = [
  {
    componentType: CollapseType,
    header: '冠军',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'GJHM7', // keyCode
        displayName: '1',
        drawImgClassName: 'PK10_ball square_1',
      }, {
        code: 'GJHM8',
        displayName: '2',
        drawImgClassName: 'PK10_ball square_2',
      }, {
        code: 'GJHM9',
        displayName: '3',
        drawImgClassName: 'PK10_ball square_3',
      }, {
        code: 'GJHM10',
        displayName: '4',
        drawImgClassName: 'PK10_ball square_4',
      }, {
        code: 'GJHM11',
        displayName: '5',
        drawImgClassName: 'PK10_ball square_5',
      }, {
        code: 'GJHM12',
        displayName: '6',
        drawImgClassName: 'PK10_ball square_6',
      }, {
        code: 'GJHM13',
        displayName: '7',
        drawImgClassName: 'PK10_ball square_7',
      }, {
        code: 'GJHM14',
        displayName: '8',
        drawImgClassName: 'PK10_ball square_8',
      }, {
        code: 'GJHM15',
        displayName: '9',
        drawImgClassName: 'PK10_ball square_9',
      }, {
        code: 'GJHM16',
        displayName: '10',
        drawImgClassName: 'PK10_ball square_10',
      }],
    }
  },
  {
    componentType: CollapseType,
    header: '亚军',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'YJHM7', // keyCode
        displayName: '1',
        drawImgClassName: 'PK10_ball square_1',
      }, {
        code: 'YJHM8',
        displayName: '2',
        drawImgClassName: 'PK10_ball square_2',
      }, {
        code: 'YJHM9',
        displayName: '3',
        drawImgClassName: 'PK10_ball square_3',
      }, {
        code: 'YJHM10',
        displayName: '4',
        drawImgClassName: 'PK10_ball square_4',
      }, {
        code: 'YJHM11',
        displayName: '5',
        drawImgClassName: 'PK10_ball square_5',
      }, {
        code: 'YJHM12',
        displayName: '6',
        drawImgClassName: 'PK10_ball square_6',
      }, {
        code: 'YJHM13',
        displayName: '7',
        drawImgClassName: 'PK10_ball square_7',
      }, {
        code: 'YJHM14',
        displayName: '8',
        drawImgClassName: 'PK10_ball square_8',
      }, {
        code: 'YJHM15',
        displayName: '9',
        drawImgClassName: 'PK10_ball square_9',
      }, {
        code: 'YJHM16',
        displayName: '10',
        drawImgClassName: 'PK10_ball square_10',
      }],
    }
  },
  {
    componentType: CollapseType,
    header: '第三名',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'B3CH7', // keyCode
        displayName: '1',
        drawImgClassName: 'PK10_ball square_1',
      }, {
        code: 'B3CH8',
        displayName: '2',
        drawImgClassName: 'PK10_ball square_2',
      }, {
        code: 'B3CH9',
        displayName: '3',
        drawImgClassName: 'PK10_ball square_3',
      }, {
        code: 'B3CH10',
        displayName: '4',
        drawImgClassName: 'PK10_ball square_4',
      }, {
        code: 'B3CH11',
        displayName: '5',
        drawImgClassName: 'PK10_ball square_5',
      }, {
        code: 'B3CH12',
        displayName: '6',
        drawImgClassName: 'PK10_ball square_6',
      }, {
        code: 'B3CH13',
        displayName: '7',
        drawImgClassName: 'PK10_ball square_7',
      }, {
        code: 'B3CH14',
        displayName: '8',
        drawImgClassName: 'PK10_ball square_8',
      }, {
        code: 'B3CH15',
        displayName: '9',
        drawImgClassName: 'PK10_ball square_9',
      }, {
        code: 'B3CH16',
        displayName: '10',
        drawImgClassName: 'PK10_ball square_10',
      }],
    }
  },
  {
    componentType: CollapseType,
    header: '第四名',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'B4CH7', // keyCode
        displayName: '1',
        drawImgClassName: 'PK10_ball square_1',
      }, {
        code: 'B4CH8',
        displayName: '2',
        drawImgClassName: 'PK10_ball square_2',
      }, {
        code: 'B4CH9',
        displayName: '3',
        drawImgClassName: 'PK10_ball square_3',
      }, {
        code: 'B4CH10',
        displayName: '4',
        drawImgClassName: 'PK10_ball square_4',
      }, {
        code: 'B4CH11',
        displayName: '5',
        drawImgClassName: 'PK10_ball square_5',
      }, {
        code: 'B4CH12',
        displayName: '6',
        drawImgClassName: 'PK10_ball square_6',
      }, {
        code: 'B4CH13',
        displayName: '7',
        drawImgClassName: 'PK10_ball square_7',
      }, {
        code: 'B4CH14',
        displayName: '8',
        drawImgClassName: 'PK10_ball square_8',
      }, {
        code: 'B4CH15',
        displayName: '9',
        drawImgClassName: 'PK10_ball square_9',
      }, {
        code: 'B4CH16',
        displayName: '10',
        drawImgClassName: 'PK10_ball square_10',
      }],
    }
  },
  {
    componentType: CollapseType,
    header: '第五名',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [{
        code: 'B5CH7', // keyCode
        displayName: '1',
        drawImgClassName: 'PK10_ball square_1',
      }, {
        code: 'B5CH8',
        displayName: '2',
        drawImgClassName: 'PK10_ball square_2',
      }, {
        code: 'B5CH9',
        displayName: '3',
        drawImgClassName: 'PK10_ball square_3',
      }, {
        code: 'B5CH10',
        displayName: '4',
        drawImgClassName: 'PK10_ball square_4',
      }, {
        code: 'B5CH11',
        displayName: '5',
        drawImgClassName: 'PK10_ball square_5',
      }, {
        code: 'B5CH12',
        displayName: '6',
        drawImgClassName: 'PK10_ball square_6',
      }, {
        code: 'B5CH13',
        displayName: '7',
        drawImgClassName: 'PK10_ball square_7',
      }, {
        code: 'B5CH14',
        displayName: '8',
        drawImgClassName: 'PK10_ball square_8',
      }, {
        code: 'B5CH15',
        displayName: '9',
        drawImgClassName: 'PK10_ball square_9',
      }, {
        code: 'B5CH16',
        displayName: '10',
        drawImgClassName: 'PK10_ball square_10',
      }],
    }
  },
];

export default DH15;