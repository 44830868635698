import { StatusCodes } from 'http-status-codes';

import { SET_BALANCE_LOG, SET_BET_LIMIT, SET_CAPTCHA_DATA, SET_MODAL_ALERT, SET_USER_DATA, SET_USER_INFO, SET_USER_NOTIFICATIONS, USER_LOGOUT } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';
import { store } from '../..';

const setUserData = appData => ({
  type: SET_USER_DATA,
  value: appData,
});

const setUserInfo = userInfo => ({
  type: SET_USER_INFO,
  value: userInfo,
});

const setCaptchaData = captchaData => ({
  type: SET_CAPTCHA_DATA,
  value: captchaData,
});

const setUserLogout = () => ({
  type: USER_LOGOUT,
});

const setBetLimit = betLimitData => ({
  type: SET_BET_LIMIT,
  value: betLimitData,
});

const setUserNotifications = userNotifications => ({
  type: SET_USER_NOTIFICATIONS,
  value: userNotifications,
});

const setBalanceLog = balanceLogData => ({
  type: SET_BALANCE_LOG,
  value: balanceLogData,
});

const setModalAlert = modal => ({
  type: SET_MODAL_ALERT,
  value: modal
});

const updateBetLimit = betLimitData => dispatch => {
  dispatch(setBetLimit(betLimitData));
};

const updateBalanceLog = balanceLogData => dispatch => {
  dispatch(setBalanceLog(balanceLogData));
};

const getInfo = successCallBack => dispatch => {
  const storeState = store.getState();
  const { oddsType } = storeState.appReducers || {};
  apiService.get('/member/getInfo').then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      if (successCallBack) {
        successCallBack();
      }
      if (data.result?.panKou.length > 0 && (!oddsType || data.result?.panKou.indexOf(oddsType) === -1)) {
        dispatch(appActions.setCurrentPankou(data.result?.panKou.split('')[0]));
      }
      if (!data.result?.panKou) {
        dispatch(appActions.setCurrentPankou(''));
      }
      return dispatch(setUserInfo(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const login = (username = 'testtest238', password = 'aaaa1111', callback = () => {}) => dispatch => {
  apiService.post('/member/login', {
    username,
    password,
  }).then(res => {
    dispatch(setModalAlert({
      visible: false,
    }));
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(getInfo());
        callback();
        return dispatch(setUserData(data.result));
      }
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'error',
        message: data.msg
      }));
    }
  }).catch(error => {
    dispatch(setModalAlert({
      visible: false,
    }));
    if (error?.response?.data) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'error',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};


// for external client login
const GetLoginUserInfo = (callback = () => {}) => dispatch => {
  apiService.get('/member/GetLoginUserInfo').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        callback(true);
        dispatch(getInfo());
        return dispatch(setUserData(data.result));
      }
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'error',
        message: data.msg
      }));
    }
  }).catch(error => {
    callback(false);
    if (error?.response?.status !== 401) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'error',
        message: error?.response?.data?.msg
      }));
    }
    Promise.reject(error);
  });
};

const visitorLogin = (callback = () => {}) => dispatch => {
  apiService.get('/member/visitorLogin').then(res => {
    const { data, status } = res;

    if (status === StatusCodes.OK) {
      dispatch(getInfo());
      dispatch(setUserData(data.result));
      return callback();
    }
  }).catch(error => {
    Promise.reject(error);
  }).finally(() => {
    dispatch(appActions.updateModalAlert({
      visible: false,
    }));
  });
};

const getRegisterPhoneSMS = (phoneNum, setSMSError) => dispatch => {
  apiService.get(`/member/SendRegisterPhoneSMS/${phoneNum}/`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'info',
        message: data.msg
      }));
    }
    setSMSError(null);
  }).catch(error => {
    if (error.response) {
      setSMSError(error.response.data.msg);
    } else {
      setSMSError(error.message);
    }
  });
};


const getRegisterPhoneSMSWeb = (phone, setSMSSuccess, setSMSError, setIsLoading, setIsOpenToast) => {
  apiService.get(`/member/SendRegisterPhoneSMS/${phone}/`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      setSMSSuccess(data.msg);
    }
    setSMSError(null);
    setIsLoading(false);
    setIsOpenToast(true);
  }).catch(error => {
    if (error.response) {
      setSMSError(error.response.data.msg);
    } else {
      setSMSError(error.message);
    }
    setSMSSuccess(null);
    setIsLoading(false);
  });
};

const getCaptcha = () => dispatch => {
  apiService.get('/member/Captcha/').then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(setCaptchaData(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const register = (loginName, password, affCode, captchaCode, safeCode, safePhone) => dispatch => {
  apiService.post('/member/register', {
    loginName,
    password,
    affCode,
    captchaCode,
    safeCode,
    safePhone
  }).then(res => {
    dispatch(setModalAlert({
      visible: false,
    }));
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(setModalAlert({
          visible: true,
          type: 'mobileReminder',
          data: {
            headerTitle: '注册成功!',
            message: '将登入与导回到首页',
            onConfirmationClick: () => dispatch(login(loginName, password)),
            showCancelButton: false,
          }
        }));
      }
    }
  }).catch(error => {
    dispatch(setModalAlert({
      visible: false,
    }));
    if (error?.response?.data) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'error',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const registerWeb = (loginName, password, affCode, captchaCode, safeCode, safePhone, setRegisterSuccess, setRegisterError, setIsRegistering, setRegisterErrorToast, setRegisterSuccessToast) => {
  apiService.post('/member/register', {
    loginName,
    password,
    affCode,
    captchaCode,
    safeCode,
    safePhone
  }).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        setRegisterSuccess(data.msg);
      }
    }
    setRegisterError(null);
    setIsRegistering(false);
    setRegisterSuccessToast(true);
  }).catch(error => {
    if (error.response) {
      setRegisterError(error.response.data.msg);
    } else {
      setRegisterError(error.message);
    }
    setRegisterSuccess(null);
    setIsRegistering(false);
    setRegisterErrorToast(true);
  });
};

const getBetLimit = lotteryType => dispatch => {
  apiService.get(`/member/BetLimit/${lotteryType}`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return dispatch(updateBetLimit(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const getBalanceLog = (page = 1, row = 15) => dispatch => {
  apiService.get('/member/BalanceLog', `page=${page}&row=${row}`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return dispatch(updateBalanceLog(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const userNotifications = (page = 1, row = 15) => dispatch => {
  apiService.get('/member/userMessages', `page=${page}&row=${row}`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return dispatch(setUserNotifications(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const messageDetail = messageId => dispatch => {
  apiService.get(`/member/messageDetail/${messageId}`).then(res => {
    const { status } = res || {};

    if (status === StatusCodes.OK) {
      return dispatch(userNotifications());
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const submitFeedBack = (type, title, content) => dispatch => {
  apiService.post('/member/feedback/', {
    feedbackType: type,
    title,
    content
  }).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const changePassword = (oldPassword, newPassword, cfmPassword, successCallback) => dispatch => {
  apiService.post('/member/ChangePassword/', {
    oldPassword,
    newPassword,
    cfmPassword
  }).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      if (successCallback) successCallback();
      return dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const logout = (loginId, callback = () => {}) => dispatch => {
  if (loginId) {
    apiService.post('/member/Logout', {
      loginId,
      refreshToken: '',
    }).then(res => {
      const { status } = res || {};
      if (status === StatusCodes.OK) {
        callback();
        return dispatch(setUserLogout());
      }
    }).catch(error => {
      Promise.reject(error);
    });
  } else {
    return dispatch(setUserLogout());
  }
};


// for external logout

const ClientLogout = (callback = () => {}) => dispatch => {
  apiService.post('/member/ClientLogout').then(res => {
    const { status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      return dispatch(setUserLogout());
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

export default {
  getInfo,
  login,
  visitorLogin,
  getRegisterPhoneSMS,
  getCaptcha,
  register,
  logout,
  getBetLimit,
  getBalanceLog,
  userNotifications,
  messageDetail,
  submitFeedBack,
  changePassword,
  getRegisterPhoneSMSWeb,
  registerWeb,
  GetLoginUserInfo,
  ClientLogout
};