import React, { useState } from 'react';

import { connect } from 'react-redux';

import Promo1 from '../../../../../assets/image/home/promo_1.png';
import Promo2 from '../../../../../assets/image/home/promo_2.png';
import Promo3 from '../../../../../assets/image/home/promo_3.png';
import Promo4 from '../../../../../assets/image/home/promo_4.png';
import PromoBanner from '../../../../../assets/image/home/promo_banner.png';

import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
});

const PromoContent = () => {
  const [openStatus, setOpenStatus] = useState([]);

  return (
    <>
      <div className="promoBannerWrapper my-4">
        <div><img className="bannerImage" src={PromoBanner} alt="banner" /></div>
      </div>
      <div className="promoContentWrapper">
        <div className="promoItemWrapper mt-5">
          {[{
            headerTitle: '充值送彩金',
            headerDesc: '即日起只要您加入星辉科技成为会员并且首次充值金额100元以上，即可获得星辉科技派送的18元彩金',
            headerImg: Promo1,
            content: [{
              headerTitle: '活动内容:',
              contentList: [
                '感恩回馈老客户的同时，星辉科技诚信更多的新会员加入我们；',
                '即日起只要您加入星辉科技成为会员并且首次充值金额100元以上，即可获得星辉科技派送的18元彩金。'
              ]
            }, {
              headerTitle: '申请方式:',
              contentList: [
                '完成存款金额的1倍流水并申请提现，清空余额后，即可联系在线客服申请优惠。',
                '优惠彩金最低出款100元，最高出款无上限。',
                '1.每位玩家、每户、每一住址、每一电子邮箱地址、每一电话号码、相同支付方式（相同借记卡/信用卡/银行账户）及IP地址只能享有一次优惠；若会员有重复申请账号行为，公司保留取消或收回会员优惠彩金的权利。',
                '2.星辉科技的所有优惠特为玩家设定，如发现任何团体或个人，以不诚实方式套取红利或任何威胁、滥用公司优惠等行为，公司保留冻结、取消该团体或个人账户及账户结余的权利。',
                '3. 星辉科技保留对活动最终解释权，以及在无通知的情况下修改、终止活动的权利，参与该优惠活动，即可表示您同意星辉科技《一般优惠规则与条款》'
              ]
            }]
          }, {
            headerTitle: '全勤100元，周周放送！',
            headerDesc: '您可以直接点击注册，填写资料后，即可登记成为星辉科技会员。',
            headerImg: Promo2,
            content: [{
              headerTitle: '活动内容：', contentList: []
            }, {
              headerTitle: '星辉科技为了答谢和鼓励一直以来专注、勤奋、支持我们的会员，我们开设了如下奖励！',
              contentList: [
                '全勤奖周周送： 新老会员只要在活动期间（连续7日/一周），连续7天入款达到7000元以上，且周期有效投注达3万元以上，期期可领奖金100元；',
                '若每次活动周期7天内充值总额未达到7000元，每周期有效投注量未达到3万以上，则不符合领奖资格。',
                '活动以北京时间计算周期：具备得奖资格的会员，必须在满足条件后联系在线客服申请，提供会员账户。',
                '任何无风险投注（如对押投注）均不算入有效投注额。'
              ]
            }, {
              headerTitle: '申请规则',
              contentList: [
                '1.所有优惠以人民币（RMB）为结算金额，以北京时间（GMT+8）为计时区间。',
                '2.每位玩家、每户、每一住址、每一电子邮箱地址、每一电话号码、相同支付方式（相同借记卡/信用卡/银行账户）及IP地址只能享有一次优惠；若会员有重复申请账号行为，公司保留取消或收回会员优惠彩金的权利。',
                '3.星辉科技的所有优惠特为玩家设定，如发现任何团体或个人，以不诚实方式套取红利或任何威胁、滥用公司优惠等行为，公司保留冻结、取消该团体或个人账户及账户结余的权利。',
                '4.若会员对活动有争议时，为确保双方利益，杜绝身份盗用行为，星辉科技有权要求会员向我们提供充足有效的文件，用以确认是否享有该优惠的资质。',
                '5.当参与优惠会员未能完全遵守、或违反、或滥用任何有关公司优惠或推广的条款，又或我们有任何证据有任何团队或个人投下一连串的关联赌注，籍以造成无论赛果怎样都可以确保可以从该存款红利或其他推广活动提供的优惠获利，星辉科技保留权利向此团队或者个人停止、取消优惠或索回已支付的全部优惠红利。此外，公司亦保留权利向这些客户扣取相当于优惠红利价值的行政费用，以补偿我们的行政成本。',
                '6.星辉科技保留对话活动的最终解释权，以及在无通知的情况下修改、终止活动的权利，并且适用于所有优惠活动。']
            }]
          }, {
            headerTitle: '神秘现金，随机派送！',
            headerDesc: '您可以直接点击注册，填写资料后，即可登记成为星辉科技会员。',
            headerImg: Promo3,
            content: [{
              headerTitle: '活动对象', contentList: ['星辉科技全体会员。']
            }, {
              headerTitle: '活动时间', contentList: ['即日起（结束时间以官网通知为准）。']
            }, {
              headerTitle: '活动内容',
              contentList: [
                '神秘现金无需发送申请，直接划入会员账号或绑定银行账号',
                '星辉科技将不定期派发鸿运现金给与新老会员们。本活动没有优惠门槛，没有参加限制，没有奖金上限，只要是星辉科技注册会员，就有机会获得意外惊喜现金。',
                '祝您红红火火，好运连连！'
              ]
            }, {
              headerTitle: '一般优惠规则与条款',
              contentList: [
                '1.所有优惠以人民币（RMB）为结算金额，以北京时间（GMT+8）为计时区间。',
                '2.每位玩家、每户、每一住址、每一电子邮箱地址、每一电话号码、相同支付方式（相同借记卡/信用卡/银行账户）及IP地址只能享有一次优惠；若会员有重复申请账号行为，公司保留取消或收回会员优惠彩金的权利。',
                '3.星辉科技的所有优惠特为玩家设定，如发现任何团体或个人，以不诚实方式套取红利或任何威胁、滥用公司优惠等行为，公司保留冻结、取消该团体或个人账户及账户结余的权利。',
                '4.若会员对活动有争议时，为确保双方利益，杜绝身份盗用行为，星辉科技有权要求会员向我们提供充足有效的文件，用以确认是否享有该优惠的资质。当参与优惠会员未能完全遵守、或违反、或滥用任何有关公司优惠或推广的条款，又或我们有任何证据有任何团队或个人投下一连串的关联赌注，籍以造成无论赛果怎样都可以确保可以从该存款红利或其他推广活动提供的优惠获利，星辉科技保留权利向此团队或者个人停止、取消优惠或索回已支付的全部优惠红利。',
                '5.此外，公司亦保留权利向这些客户扣取相当于优惠红利价值的行政费用，以补偿我们的行政成本。星辉科技保留对话活动的最终解释权，以及在无通知的情况下修改、终止活动的权利，并且适用于所有优惠活动。'
              ]
            }]
          }, {
            headerTitle: '邀请好友，丰厚彩金等你来拿！',
            headerDesc: '您可以直接点击注册，填写资料后，即可登记成为星辉科技会员。',
            headerImg: Promo4,
            content: [{
              headerTitle: '活动申请：',
              contentList: [
                '本活动与代理无关，只限星辉科技全体会员，谢谢大家！',
                '独乐乐不如众乐乐，您还在单打独斗吗？立即呼唤请朋友好友体验组队娱乐的乐趣，每推荐一位好友即可领取星辉科技为您贴心准备的推荐彩金'
              ]
            }, {
              headerTitle: '礼包大回馈：',
              contentList: [
                '介绍人数1人以上，游戏天数3天，存款金额100元，彩金回馈58元',
                '即日起您每成功推荐一位会员并存款次数达到3次以上、单次存款金额100元，即获得58元彩金红利回馈，介绍的朋友越多，您获得的奖金也就越多'
              ]
            }, {
              headerTitle: '申请步骤',
              contentList: [
                '1.请推荐之前联系在线客服提交您的会员账号以及被推荐的会员账号（该会员必须是新注册未充值）；',
                '2.被推荐人需达到要求存款总额/次数；（被推荐人必须是没存款的新会员）；',
                '3推荐人即可联系在线客服申请58元彩金红利，彩金1倍流水，即可提款。'
              ]
            }, {
              headerTitle: '活动规则',
              contentList: [
                '1.本次优惠活动即日起生效，优惠活动适用于星辉科技全体会员；',
                '2.所有会员只能拥有一个账号申请该优惠，同一个IP，同意存/提款卡视为统一客户。如果发现同一个人拥有两个或以上的账号，重复的账号将被冻结，每IP地址只能被推荐一次；',
                '3.推荐人是不能注册成为自己的被推荐人，如有违反规则，将被没收彩金；',
                '4.此活动可以与其他优惠同时进行享有；',
                '5.星辉科技保留所有权利在任何时候可以更改、停止、取消优惠活动并不会做出任何书面解释；'
              ]
            }]
          }].map((item, index) => (
            <div className="questionSection" key={`question_${index.toString()}`}>
              <div className="d-flex">
                <div className="col-5 d-flex flex-column justify-content-center align-items-center">
                  <h5 className="text-center font-weight-bold">限时活动</h5>
                  <h3 className="promoItemHeader fontColor">{item?.headerTitle}</h3>
                  <div
                    className="viewDetailBtn px-2"
                    onClick={() => {
                      if (!openStatus[index]) {
                        const temp = [...openStatus];
                        temp[index] = true;
                        setOpenStatus([...temp]);
                      } else {
                        const temp = [...openStatus];
                        temp[index] = false;
                        setOpenStatus([...temp]);
                      }
                    }}
                  >
                    查看详情
                  </div>
                </div>
                <div className="col-7">
                  <h5 className="fontColor">{item?.headerDesc}</h5>
                </div>
              </div>
              <div className="d-flex">
                <img src={item.headerImg} alt="" srcSet="" />
              </div>
              <div className={`w-100 accordionCard ${openStatus[index] && 'openAccordionCard'}`}>
                {
                  item.content.map((data, dataIndex) => (
                    <div key={`data${dataIndex.toString()}`} className="subItemSection">
                      <div className="subItemSectionHeader"><h4 className="fontColor font-weight-bold">{data?.headerTitle}</h4></div>
                      {
                        data?.contentList.map((subData, subDataIndex) => <div key={`subData${subDataIndex.toString()}`} className="subItemSectionContent">{subData}</div>)
                      }
                    </div>
                  ))
                }
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, null)(PromoContent);