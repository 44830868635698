
import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';
import PengAndDan from '../../../../components/Mobile/ComponentType/PengAndDan';
import TeMaAndLianMa from '../../../../components/Mobile/ComponentType/TeMaAndLianMa';

const ZhiXuan = [
  {
    componentType: TeMaAndLianMa,
    data: {
      list: [{
        displayName: '前二复式',
        numberOfSelectionBlock: 2,
        code: 'ZX1',
        minimumSelection: 2,
        maximumSelection: 20,
        children: [{
          code: 'ZX1'
        }]
      },
      {
        displayName: '前三复式',
        numberOfSelectionBlock: 3,
        code: 'ZX2',
        minimumSelection: 3,
        maximumSelection: 20,
        children: [{
          code: 'ZX2'
        }]
      }]
    }
  },
  {
    componentType: PengAndDan,
    data: {}
  },
  {
    componentType: CollapseType,
    header: '第一球',
    headerStyle: '',
    selectionBlockIndex: 0,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      kuaiJieSelectedGroupIndex: 0,
      list: [
        {
          code: '1',
          displayName: '1',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '5',
          displayName: '5',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '9',
          displayName: '9',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '2',
          displayName: '2',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '6',
          displayName: '6',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '10',
          displayName: '10',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '3',
          displayName: '3',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '7',
          displayName: '7',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '11',
          displayName: '11',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '4',
          displayName: '4',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '8',
          displayName: '8',
          drawImgClassName: 'circle',
          showOdds: false
        },
      ]
    }
  }, {
    componentType: CollapseType,
    header: '第二球',
    headerStyle: '',
    selectionBlockIndex: 1,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      kuaiJieSelectedGroupIndex: 1,
      list: [
        {
          code: '1',
          displayName: '1',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '5',
          displayName: '5',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '9',
          displayName: '9',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '2',
          displayName: '2',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '6',
          displayName: '6',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '10',
          displayName: '10',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '3',
          displayName: '3',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '7',
          displayName: '7',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '11',
          displayName: '11',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '4',
          displayName: '4',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '8',
          displayName: '8',
          drawImgClassName: 'circle',
          showOdds: false
        },
      ]
    }
  }, {
    componentType: CollapseType,
    header: '第三球',
    headerStyle: '',
    selectionBlockIndex: 2,
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'CJ_11X5ItemWrapper',
      kuaiJieSelectedGroupIndex: 2,
      list: [
        {
          code: '1',
          displayName: '1',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '5',
          displayName: '5',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '9',
          displayName: '9',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '2',
          displayName: '2',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '6',
          displayName: '6',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '10',
          displayName: '10',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '3',
          displayName: '3',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '7',
          displayName: '7',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '11',
          displayName: '11',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '4',
          displayName: '4',
          drawImgClassName: 'circle',
          showOdds: false
        },
        {
          code: '8',
          displayName: '8',
          drawImgClassName: 'circle',
          showOdds: false
        },
      ]
    }
  }
];

export default ZhiXuan;