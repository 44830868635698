import React, { useEffect, useState } from 'react';

import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, cartActions, gameActions } from '../../../redux/actions';
import { validateNumber } from '../../../constants/utils';
import './styles.scss';

const mapStateToProps = state => ({
  gameInfo: state.placeBetReducers.gameInfo,
  presetAmount: state.gameReducers.presetAmount,
  cart: state.cartReducers.cart,
  sectionSelection: state.gameReducers.sectionSelection,
  companyInfo: state.companyReducers.companyInfo,
  selectedSubType: state.gameReducers.selectedSubType,
  betTypeSelection: state.gameReducers.betTypeSelection,
  selectedDan: state.gameReducers.selectedDan,
  currentSelected: state.gameReducers.currentSelected,
  lotteryType: state.gameReducers.lotteryType,
  template: state.gameReducers.template,
  lengReResult: state.resultHistoryReducers.lengReResult,
  yiLouCount: state.appReducers.yiLouCount,
  showLengRe: state.appReducers.showLengRe,
  showYiLou: state.appReducers.showYiLou,
});

const mapDispatchToProps = dispatch => ({
  addItemToCart: (item, reset) => dispatch(cartActions.addItemToCart(item, reset)),
  removeItemInCart: keyCode => dispatch(cartActions.removeItemInCart(keyCode)),
  updateSelectedDan: dan => dispatch(gameActions.updateSelectedDan(dan)),
  updateCurrentSelected: val => dispatch(gameActions.updateCurrentSelected(val)),
  resetItemInCart: () => dispatch(cartActions.resetItemInCart()),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  updateCart: cart => dispatch(cartActions.updateCart(cart)),
});

const BetsItems = ({ code, fromValue, toValue, danNum, minimumSelection, presetAmount, addItemToCart, displayName, children, updateCart, updateModalAlert, gameInfo, cart, selectedDan, currentSelected }) => {
  const { t } = useTranslation();
  const [_currentSelection, setCurrentSelection] = useState([]);
  const [_selectedDan, setSelectedDan] = useState([]);
  const [_gameInfo, setGameInfo] = useState();
  const [_displayOdds, setDisplayOdds] = useState();
  const [_numOfBets, setNumOfBets] = useState(0);
  const [_amount, setAmount] = useState();
  const [_result, setResult] = useState();
  const [_selected, setSelected] = useState(false);
  useEffect(() => {
    if (JSON.stringify(gameInfo) !== JSON.stringify(_gameInfo)) {
      setGameInfo(gameInfo);
    }
  }, [gameInfo]);

  useEffect(() => {
    setAmount(presetAmount);
  }, [presetAmount]);

  useEffect(() => {
    let temp;
    if (_gameInfo?.oddsList) {
      temp = children ? children.map(item => generateOdds(_gameInfo?.oddsList, item.code)).join('/') : generateOdds(_gameInfo?.oddsList, code);
    } else {
      temp = children ? children.map(() => '--').join('/') : '--';
    }
    setDisplayOdds(temp);
  }, [_gameInfo]);

  const generateOdds = (oddsList, code) => {
    let odds;
    for (let i = fromValue; i <= toValue; i++) {
      odds ? odds >= oddsList[`${code}${i}`].odds ? (odds = oddsList[`${code}${i}`].odds) : odds : (odds = oddsList[`${code}${i}`].odds);
    }
    return odds || '--';
  };

  useEffect(() => {
    if (JSON.stringify(selectedDan) !== JSON.stringify(_selectedDan)) {
      setSelectedDan(selectedDan || []);
    }
  }, [selectedDan]);

  useEffect(() => {
    if (JSON.stringify(currentSelected) !== JSON.stringify(_currentSelection)) {
      setCurrentSelection(currentSelected || []);
    }
  }, [currentSelected]);

  useEffect(() => {
    const tempSelection = [];
    let tempItem;
    const tempDanSelection = [];
    let tempDanItem;
    if (_gameInfo?.oddsList && _currentSelection.length > 0) {
      const oddsCode = `${code}${Number(_currentSelection[_currentSelection.length - 1].displayName)}`;
      tempItem = _gameInfo?.oddsList[oddsCode];
      _currentSelection.forEach(item => {
        tempSelection.push({
          categoryName: tempItem.midType,
          displayName: item.displayName,
          odds: generateOdds(_gameInfo?.oddsList, code),
          midType: tempItem.midType,
          keyCode: `${code}${Number(item.displayName)}`,
          amount: _amount || parseInt(presetAmount, 10) || 0
        });
      });
    }
    if (_gameInfo?.oddsList && _selectedDan.length > 0) {
      const oddsCode = `${code}${Number(_selectedDan[_selectedDan.length - 1].displayName)}`;
      tempDanItem = _gameInfo?.oddsList[oddsCode];
      _selectedDan.forEach(item => {
        tempDanSelection.push({
          categoryName: tempDanItem.midType,
          displayName: item.displayName,
          odds: generateOdds(_gameInfo?.oddsList, code),
          midType: tempDanItem.midType,
          keyCode: `${code}${Number(item.displayName)}`,
          amount: _amount || parseInt(presetAmount, 10) || 0
        });
      });
    }
    if (danNum) {
      const result = handlePermutation(tempSelection, minimumSelection - danNum, tempDanSelection, _amount || presetAmount || 0);
      (JSON.stringify(result) !== JSON.stringify(_result)) && (setResult(result));
      setNumOfBets(Object.keys(result).length);
    } else {
      const result = handlePermutation(tempSelection, minimumSelection, [], _amount || presetAmount || 0);
      (JSON.stringify(result) !== JSON.stringify(_result)) && (setResult(result));
      setNumOfBets(Object.keys(result).length);
    }
  }, [_currentSelection, _selectedDan, _gameInfo?.oddsList, _amount]);

  useEffect(() => {
    if (!cart || Object.keys(cart).length === 0) {
      setSelected(false);
    }
  }, [cart]);


  const onItemClick = () => {
    if (_numOfBets === 0) return;
    if (cart && Object.values(cart).find(item => item.midType === displayName)) {
      const tempCart = {};
      Object.entries(cart).forEach(([key, value]) => {
        if (value.midType !== displayName) {
          tempCart[key] = value;
        }
      });
      updateCart(tempCart);
      setSelected(false);
    } else {
      addItemToCart(_result, false);
      setSelected(true);
    }
  };


  const handlePermutation = (arr, permutationLength, tempDan, amount) => {
    const tempResult = {};
    const result = [];
    function combine(input, len, start) {
      if (len === 0) {
        const _result = result.map(x => x.displayName).sort().join(',');
        const _dan = tempDan.map(x => x.displayName).sort().join(',');
        let oddsResult;
        (tempDan.length > 0 ? tempDan : result).forEach(item => (oddsResult ? item.odds <= oddsResult.odds ? oddsResult = item : oddsResult : oddsResult = item));

        const tempDisplayName = tempDan.length > 0 ? [_dan, _result].join(',') : _result;

        return tempResult[tempDisplayName + oddsResult.midType] = {
          categoryName: oddsResult.midType,
          displayName: tempDisplayName,
          odds: oddsResult.odds,
          midType: oddsResult.midType,
          keyCode: oddsResult.keyCode,
          amount: amount || parseInt(presetAmount, 10) || 0,
        };
      }

      for (let i = start; i <= input.length - len; i++) {
        if (tempDan.length > 0) result[permutationLength - len] = input[i];
        else result[minimumSelection - len] = input[i];
        combine(input, len - 1, i + 1);
      }

      return null;
    }

    combine(arr, tempDan.length > 0 ? permutationLength : minimumSelection, 0);

    return tempResult;
  };

  useEffect(() => {
    if (JSON.stringify(gameInfo) !== JSON.stringify(_gameInfo)) {
      if (gameInfo && _gameInfo) {
        const { oddsList: newOddsList } = gameInfo || {};
        const { oddsList } = _gameInfo || {};
        if (newOddsList && oddsList) {
          const diff = difference(oddsList, newOddsList);
          if (Object.keys(diff).length > 0) {
            const changes = checkChangesInCart(diff);
            if (changes > 0) {
              updateModalAlert({
                visible: true,
                type: 'confirmation',
                data: {
                  message: t('X_AMOUNT_ODDS_CHANGE_ARE_YOU_SURE_TO_UPDATE').replace('X', changes),
                  confirmationText: t('UPDATE_IMMEDIATELY'),
                  onConfirmationClick: () => {
                    const tempCart = JSON.parse(JSON.stringify(cart));
                    Object.entries(tempCart).forEach(([key, item]) => {
                      if (diff[key]) {
                        tempCart[key] = {
                          ...item,
                          ...diff[key]
                        };
                      }
                    });
                    updateCart(tempCart);
                  },
                  cancelText: t('CANCEL_IMMEDIATELY'),
                  onCancelClick: () => {
                    const tempCart = JSON.parse(JSON.stringify(cart));
                    Object.entries(tempCart).forEach(([key]) => {
                      if (diff[key]) {
                        delete tempCart[key];
                      }
                    });
                    updateCart(tempCart);
                    updateModalAlert({
                      visible: false,
                    });
                  }
                }
              });
            }
          }
        }
      }
      setGameInfo(gameInfo);
    }
  }, [gameInfo]);

  const checkChangesInCart = diff => {
    if (!cart) return;
    let count = 0;
    Object.keys(cart).forEach(key => {
      if (diff[key] !== undefined) count += 1;
    });
    return count;
  };

  const difference = (obj1, obj2) => {
    let keyFound = {};
    Object.keys(obj1).forEach(key => {
      if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
        keyFound = {
          ...keyFound,
          [key]: obj2[key]
        };
      }
    });
    return keyFound;
  };
  return (
    <div className={`BetsItems d-flex flex-wrap ${_selected && 'selected'}`} onClick={() => { onItemClick(); }}>
      <div className="oddsLabel">
        {`${displayName}(${_displayOdds})`}
      </div>
      <div className="betsLabel">{t('TOTAL_BET_COUNT')}</div>
      <div className="inputAmount">
        <Input
          type="text"
          value={_amount}
          className="chooseBetInput"
          onClick={e => e.stopPropagation()}
          onChange={({ target: { value: text } }) => {
            setAmount(validateNumber(text));
          }}
        />
      </div>
      <div className="betsTotal">{_numOfBets}</div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BetsItems);