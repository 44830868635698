import React, { useRef, useState } from 'react';

import { connect } from 'react-redux';

import { Tooltip } from 'reactstrap';

import { motion } from 'framer-motion';

import { BiSolidToTop } from 'react-icons/bi';
import { MdContactSupport } from 'react-icons/md';

import CollaborateContent from '../Content/CollaborateContent';
import Footer from '../Footer';
import Header from '../Header';
import HomeContent from '../Content/HomeContent';
import PromoContent from '../Content/PromoContent';

import ContactList from '../../../../components/Web/ContactList';


import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
});

const Default = () => {
  const [GameIndex, setGameIndex] = useState(0);
  const [toggleSubMenu, setToggleSubMenu] = useState(false);
  const [toggleContact, setToggleContact] = useState(false);
  const [toggleUserProfile, setToggleUserProfile] = useState(false);
  const scrollableDivRef = useRef(null);

  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const [tooltipOpen2, setTooltipOpen2] = useState(false);

  const toggle1 = () => setTooltipOpen1(!tooltipOpen1);
  const toggle2 = () => setTooltipOpen2(!tooltipOpen2);

  const handleScroll = () => {
    setToggleSubMenu(false);
    setToggleUserProfile(false);
  };

  const scrollToTop = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  const ComponentList = [<HomeContent />, <HomeContent />, <HomeContent />, <HomeContent />, <HomeContent />, <PromoContent />, <CollaborateContent />, <HomeContent />, <HomeContent />, <HomeContent />];

  return (
    <div className="homePageWebWrapper position-relative" onScroll={handleScroll} ref={scrollableDivRef}>
      <Header GameIndex={GameIndex} setGameIndex={setGameIndex} toggleSubMenu={toggleSubMenu} setToggleSubMenu={setToggleSubMenu} toggleUserProfile={toggleUserProfile} setToggleUserProfile={setToggleUserProfile} />
      <div onMouseEnter={() => {
        setToggleSubMenu(false);
        setToggleUserProfile(false);
        setGameIndex(0);
      }}
      >
        {
          ComponentList[GameIndex]
        }
      </div>
      <Footer />
      <div onClick={() => setToggleContact(!toggleContact)} id="contactUs" className="floatingContacts position-fixed p-2 font-weight-bold">
        <motion.div
          whileHover={{
            scale: 1.2
          }}
          whileTap={{
            scale: 0.8
          }}
        >
          <MdContactSupport size={25} />
        </motion.div>
      </div>
      {toggleContact && (
        <div className="d-flex flex-column position-fixed contactList">
          <ContactList />
        </div>
      )}
      <div onClick={scrollToTop} id="backToTop" className="floatingTop position-fixed p-2 font-weight-bold">
        <motion.div
          whileHover={{
            scale: 1.2
          }}
          whileTap={{
            scale: 0.8
          }}
        >
          <BiSolidToTop size={25} />
        </motion.div>
      </div>
      <Tooltip placement="left" isOpen={tooltipOpen1} target="backToTop" toggle={toggle1}>
        返回顶部
      </Tooltip>
      <Tooltip placement="left" isOpen={tooltipOpen2} target="contactUs" toggle={toggle2}>
        联络我们
      </Tooltip>
    </div>
  );
};

export default connect(mapStateToProps, null)(Default);