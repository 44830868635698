import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const HM3 = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      tableTitle: '第三球',
      cycleHeaderTitle: true,
      mainColumnLength: 4,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-style-1th', 'column-3items-style-2th', 'column-3items-style-3th'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'B3QH1',
          displayName: '1',
          className: 'circle circleRed',
        }, {
          code: 'B3QH2',
          displayName: '2',
          className: 'circle circleBlue',
        }, {
          code: 'B3QH3',
          displayName: '3',
          className: 'circle circleGreen',
        }, {
          code: 'B3QH4',
          displayName: '4',
          className: 'circle circleRed',
        }, {
          code: 'B3QH5',
          displayName: '5',
          className: 'circle circleBlue',
        }, {
          code: 'B3QH6',
          displayName: '6',
          className: 'circle circleGreen',
        }, {
          code: 'B3QH7',
          displayName: '7',
          className: 'circle circleRed',
        }, {
          code: 'B3QH8',
          displayName: '8',
          className: 'circle circleBlue',
        }, {
          code: 'B3QH9',
          displayName: '9',
          className: 'circle circleGreen',
        }, {
          code: 'B3QH10',
          displayName: '10',
          className: 'circle circleRed',
        }, {
          code: 'B3QH11',
          displayName: '11',
          className: 'circle circleBlue',
        }, {
          code: 'B3QH12',
          displayName: '12',
          className: 'circle circleGreen',
        }, {
          code: 'B3QH13',
          displayName: '13',
          className: 'circle circleRed',
        }, {
          code: 'B3QH14',
          displayName: '14',
          className: 'circle circleBlue',
        }, {
          code: 'B3QH15',
          displayName: '15',
          className: 'circle circleGreen',
        }, {
          code: 'B3QH16',
          displayName: '16',
          className: 'circle circleRed',
        }, {
          code: 'B3QH17',
          displayName: '17',
          className: 'circle circleBlue',
        }, {
          code: 'B3QH18',
          displayName: '18',
          className: 'circle circleGreen',
        }, {
          code: 'B3QH19',
          displayName: '19',
          className: 'circle circleRed',
        }, {
          code: 'B3QH20',
          displayName: '20',
          className: 'circle circleBlue',
        }, {
          code: 'B3QH21',
          displayName: '21',
          className: 'circle circleGreen',
        }, {
          code: 'B3SB22',
          displayName: '红',
          className: 'fillRed',
        }, {
          code: 'B3SB23',
          displayName: '绿',
          className: 'fillGreen',
        }, {
          code: 'B3SB24',
          displayName: '蓝',
          className: 'fillBlue',
        }, {
          code: 'B3FLSX25',
          displayName: '福',
          className: 'label',
        }, {
          code: 'B3FLSX26',
          displayName: '禄',
          className: 'label',
        }, {
          code: 'B3FLSX27',
          displayName: '寿',
          className: 'label',
        }, {
          code: 'B3FLSX28',
          displayName: '喜',
          className: 'label',
        }, {
          code: 'B3LM17',
          displayName: '大',
          className: 'label',
        }, {
          code: 'B3LM18',
          displayName: '小',
          className: 'label',
        }, {
          code: 'B3LM19',
          displayName: '单',
          className: 'label',
        }, {
          code: 'B3LM20',
          displayName: '双',
          className: 'label',
        }, {
          code: 'B3LM21',
          displayName: '尾大',
          className: 'label',
        }, {
          code: 'B3LM22',
          displayName: '尾小',
          className: 'label',
        }, {
          code: 'B3LM23',
          displayName: '合单',
          className: 'label',
        }, {
          code: 'B3LM24',
          displayName: '合双',
          className: 'label',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default HM3;