import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const ZM16 = [
  {
    componentType: CollapseType,
    header: '正码一',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'ZM1LM1',
          displayName: '单'
        },
        {
          code: 'ZM1LM2',
          displayName: '双'
        },
        {
          code: 'ZM1LM3',
          displayName: '大'
        },
        {
          code: 'ZM1LM4',
          displayName: '小'
        },
        {
          code: 'ZM1LM5',
          displayName: '合单'
        },
        {
          code: 'ZM1LM6',
          displayName: '合双'
        },
        {
          code: 'ZM1LM7',
          displayName: '合大'
        },
        {
          code: 'ZM1LM8',
          displayName: '合小'
        },
        {
          code: 'ZM1LM9',
          displayName: '尾大'
        },
        {
          code: 'ZM1LM10',
          displayName: '尾小'
        },
        {
          code: 'ZM1SB11',
          displayName: '红波',
          className: 'hk6-sb-red-bg'
        },
        {
          code: 'ZM1SB12',
          displayName: '蓝波',
          className: 'hk6-sb-blue-bg'
        },
        {
          code: 'ZM1SB13',
          displayName: '绿波',
          className: 'hk6-sb-green-bg'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '正码二',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'ZM2LM1',
          displayName: '单'
        },
        {
          code: 'ZM2LM2',
          displayName: '双'
        },
        {
          code: 'ZM2LM3',
          displayName: '大'
        },
        {
          code: 'ZM2LM4',
          displayName: '小'
        },
        {
          code: 'ZM2LM5',
          displayName: '合单'
        },
        {
          code: 'ZM2LM6',
          displayName: '合双'
        },
        {
          code: 'ZM2LM7',
          displayName: '合大'
        },
        {
          code: 'ZM2LM8',
          displayName: '合小'
        },
        {
          code: 'ZM2LM9',
          displayName: '尾大'
        },
        {
          code: 'ZM2LM10',
          displayName: '尾小'
        },
        {
          code: 'ZM2SB11',
          displayName: '红波',
          className: 'hk6-sb-red-bg'
        },
        {
          code: 'ZM2SB12',
          displayName: '蓝波',
          className: 'hk6-sb-blue-bg'
        },
        {
          code: 'ZM2SB13',
          displayName: '绿波',
          className: 'hk6-sb-green-bg'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '正码三',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'ZM3LM1',
          displayName: '单'
        },
        {
          code: 'ZM3LM2',
          displayName: '双'
        },
        {
          code: 'ZM3LM3',
          displayName: '大'
        },
        {
          code: 'ZM3LM4',
          displayName: '小'
        },
        {
          code: 'ZM3LM5',
          displayName: '合单'
        },
        {
          code: 'ZM3LM6',
          displayName: '合双'
        },
        {
          code: 'ZM3LM7',
          displayName: '合大'
        },
        {
          code: 'ZM3LM8',
          displayName: '合小'
        },
        {
          code: 'ZM3LM9',
          displayName: '尾大'
        },
        {
          code: 'ZM3LM10',
          displayName: '尾小'
        },
        {
          code: 'ZM3SB11',
          displayName: '红波',
          className: 'hk6-sb-red-bg'
        },
        {
          code: 'ZM3SB12',
          displayName: '蓝波',
          className: 'hk6-sb-blue-bg'
        },
        {
          code: 'ZM3SB13',
          displayName: '绿波',
          className: 'hk6-sb-green-bg'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '正码四',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'ZM4LM1',
          displayName: '单'
        },
        {
          code: 'ZM4LM2',
          displayName: '双'
        },
        {
          code: 'ZM4LM3',
          displayName: '大'
        },
        {
          code: 'ZM4LM4',
          displayName: '小'
        },
        {
          code: 'ZM4LM5',
          displayName: '合单'
        },
        {
          code: 'ZM4LM6',
          displayName: '合双'
        },
        {
          code: 'ZM4LM7',
          displayName: '合大'
        },
        {
          code: 'ZM4LM8',
          displayName: '合小'
        },
        {
          code: 'ZM4LM9',
          displayName: '尾大'
        },
        {
          code: 'ZM4LM10',
          displayName: '尾小'
        },
        {
          code: 'ZM4SB11',
          displayName: '红波',
          className: 'hk6-sb-red-bg'
        },
        {
          code: 'ZM4SB12',
          displayName: '蓝波',
          className: 'hk6-sb-blue-bg'
        },
        {
          code: 'ZM4SB13',
          displayName: '绿波',
          className: 'hk6-sb-green-bg'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '正码五',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'ZM5LM1',
          displayName: '单'
        },
        {
          code: 'ZM5LM2',
          displayName: '双'
        },
        {
          code: 'ZM5LM3',
          displayName: '大'
        },
        {
          code: 'ZM5LM4',
          displayName: '小'
        },
        {
          code: 'ZM5LM5',
          displayName: '合单'
        },
        {
          code: 'ZM5LM6',
          displayName: '合双'
        },
        {
          code: 'ZM5LM7',
          displayName: '合大'
        },
        {
          code: 'ZM5LM8',
          displayName: '合小'
        },
        {
          code: 'ZM5LM9',
          displayName: '尾大'
        },
        {
          code: 'ZM5LM10',
          displayName: '尾小'
        },
        {
          code: 'ZM5SB11',
          displayName: '红波',
          className: 'hk6-sb-red-bg'
        },
        {
          code: 'ZM5SB12',
          displayName: '蓝波',
          className: 'hk6-sb-blue-bg'
        },
        {
          code: 'ZM5SB13',
          displayName: '绿波',
          className: 'hk6-sb-green-bg'
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '正码六',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'ZM6LM1',
          displayName: '单'
        },
        {
          code: 'ZM6LM2',
          displayName: '双'
        },
        {
          code: 'ZM6LM3',
          displayName: '大'
        },
        {
          code: 'ZM6LM4',
          displayName: '小'
        },
        {
          code: 'ZM6LM5',
          displayName: '合单'
        },
        {
          code: 'ZM6LM6',
          displayName: '合双'
        },
        {
          code: 'ZM6LM7',
          displayName: '合大'
        },
        {
          code: 'ZM6LM8',
          displayName: '合小'
        },
        {
          code: 'ZM6LM9',
          displayName: '尾大'
        },
        {
          code: 'ZM6LM10',
          displayName: '尾小'
        },
        {
          code: 'ZM6SB11',
          displayName: '红波',
          className: 'hk6-sb-red-bg'
        },
        {
          code: 'ZM6SB12',
          displayName: '蓝波',
          className: 'hk6-sb-blue-bg'
        },
        {
          code: 'ZM6SB13',
          displayName: '绿波',
          className: 'hk6-sb-green-bg'
        }
      ]
    }
  }
];

export default ZM16;