import { BetTypeContainer, MultipleTypeSelectionPanel, Tab } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const LX = [
  {
    componentType: QuickBet,
  },
  {
    componentType: MultipleTypeSelectionPanel,
    data: {
      list: [
        {
          rowType: Tab,
          wrapperStyle: 'tabRow',
          onCheckBoxChange: (updateSectionSelection, subItem) => {
            updateSectionSelection(subItem);
          },
          rowData: [{
            code: 'L2LX',
            displayName: '二肖',
            minimumSelection: 2,
            maximumSelection: 6,
            className: 'cellWrapper',
            danList: ['1'],
            midCode: 'L2LX'
          }, {
            code: 'L3LX',
            displayName: '三肖',
            minimumSelection: 3,
            maximumSelection: 6,
            className: 'cellWrapper',
            danList: ['1', '2'],
            midCode: 'L3LX'
          }, {
            code: 'L4LX',
            displayName: '四肖',
            minimumSelection: 4,
            maximumSelection: 7,
            className: 'cellWrapper',
            danList: ['1', '2', '3'],
            midCode: 'L4LX'
          }, {
            code: 'L5LX',
            displayName: '五肖',
            minimumSelection: 5,
            maximumSelection: 8,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4'],
            midCode: 'L5LX'
          }, {
            code: 'L2LXBZ',
            displayName: '二肖不中',
            minimumSelection: 2,
            maximumSelection: 6,
            className: 'cellWrapper',
            danList: ['1'],
            midCode: 'L2LXBZ'
          }, {
            code: 'L3LXBZ',
            displayName: '三肖不中',
            minimumSelection: 3,
            maximumSelection: 6,
            className: 'cellWrapper',
            danList: ['1', '2'],
            midCode: 'L3LXBZ'
          }, {
            code: 'L4LXBZ',
            displayName: '四肖不中',
            minimumSelection: 4,
            maximumSelection: 7,
            className: 'cellWrapper',
            danList: ['1', '2', '3'],
            midCode: 'L4LXBZ'
          }, {
            code: 'L5LXBZ',
            displayName: '五肖不中',
            minimumSelection: 5,
            maximumSelection: 8,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4'],
            midCode: 'L5LXBZ'
          }]
        },
        {
          rowTitleClassName: 'p-2 text-center',
          rowType: BetTypeContainer,
          wrapperStyle: 'p-2',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['生肖', '赔率', '选择', '号码'],
      mainColumnLength: 2,
      subColumnLength: 4,
      subColumnStyle: ['column-4items-label', 'column-4items-odds', 'column-4items-checkbox', 'column-4items-balls'],
      betItemStyle: ['column-4items-label', 'column-4items-odds', 'column-4items-checkbox', 'column-4items-array-balls column-4items-balls'],
      betItemList: ['DisplayText', 'Odds', 'CheckBox', 'HK6_Zodiac'],
      betItemColumn: 4,
      list: [{
        code: '1',
        displayName: '鼠',
      }, {
        code: '2',
        displayName: '牛',
      }, {
        code: '3',
        displayName: '虎',
      }, {
        code: '4',
        displayName: '兔',
      }, {
        code: '5',
        displayName: '龙',
      }, {
        code: '6',
        displayName: '蛇',
      }, {
        code: '7',
        displayName: '马',
      }, {
        code: '8',
        displayName: '羊',
      }, {
        code: '9',
        displayName: '猴',
      }, {
        code: '10',
        displayName: '鸡',
      }, {
        code: '11',
        displayName: '狗',
      }, {
        code: '12',
        displayName: '猪',
      }]
    }
  },
  {
    componentType: QuickBet,
  }
];

export default LX;