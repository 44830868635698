import { SET_INIT_DATA, SET_USER_GAMES } from '../../actions/actionTypes';

const initialState = {
  userGames: undefined,
  initData: undefined,
};

const homeReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_GAMES:
      return {
        ...state, userGames: action.value
      };
    case SET_INIT_DATA:
      return {
        ...state, initData: action.value
      };

    default:
      return state;
  }
};

export default homeReducers;