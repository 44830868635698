import { SET_LOTTERY_RULES_DATA } from '../../actions/actionTypes';

const initialState = {
  lotteryRulesData: undefined,
};

const gameRulesReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOTTERY_RULES_DATA:
      return {
        ...state, lotteryRulesData: action.value
      };

    default:
      return state;
  }
};

export default gameRulesReducers;