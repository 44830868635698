import { BetTypeContainer, CheckBox, MultipleTypeSelectionPanel, Odds } from '../../../../components/Web';
import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const LIUXIAO = [
  {
    componentType: QuickBet,
  },
  {
    componentType: MultipleTypeSelectionPanel,
    data: {
      list: [
        {
          rowTitle: '类别',
          rowTitleClassName: 'text-center',
          rowType: CheckBox,
          wrapperStyle: 'checkBoxRow F36X7',
          onCheckBoxChange: (updateSectionSelection, subItem) => {
            updateSectionSelection(subItem);
          },
          rowData: [{
            code: 'LX',
            displayName: '六肖',
            minimumSelection: 6,
            maximumSelection: 10,
            className: 'cellWrapper',
            danList: ['1', '2', '3', '4', '5'],
          }]
        },
        {
          rowTitle: '赔率',
          rowTitleClassName: 'text-center',
          rowType: Odds,
          wrapperStyle: 'oddsRow F36X7',
          className: 'chooseBetOdds',
          rowData: [{
            code: 'LX',
            className: 'chooseBetOdds',
          }]
        },
        {
          rowTitleClassName: 'p-2 text-center',
          rowType: BetTypeContainer,
          wrapperStyle: 'p-2',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['生肖', '勾选', '号码'],
      mainColumnLength: 2,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label hk6-hx-label', 'column-3items-odds hk6-hx-checkbox', 'column-3items-input hk6-hx-ball'],
      betItemStyle: ['column-3items-label hk6-hx-label', 'column-3items-odds hk6-hx-checkbox', 'column-3items-array-balls hk6-hx-ball column-3items-input'],
      betItemList: ['DisplayText', 'CheckBox', 'HK6_Zodiac'],
      betItemColumn: 3,
      zodiacTotalBall: 36,
      list: [
        {
          displayName: '鼠',
        }, {
          displayName: '马',
        }, {
          displayName: '牛',
        }, {
          displayName: '羊',
        }, {
          displayName: '虎',
        }, {
          displayName: '猴',
        }, {
          displayName: '兔',
        }, {
          displayName: '鸡',
        }, {
          displayName: '龙',
        }, {
          displayName: '狗',
        }, {
          displayName: '蛇',
        }, {
          displayName: '猪',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default LIUXIAO;