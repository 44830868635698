import React from 'react';
import './styles.scss';

const ServiceCardOne = ({ src, title, subTitle, content }) => (
  <div className="card serviceCardWrapper px-4">
    <div className="row no-gutters justify-content-center align-items-center">
      <div className="col-md-4">
        <div className="serviceImage">
          <img className="w-100" src={src} alt="app" />
        </div>
      </div>
      <div className="col-md-8">
        <div className="card-body">
          <div className="position-relative">
            <h1 className="card-title font-weight-bold">{title}</h1>
            <p className="font-weigh-light position-absolute serviceSubtitle">{subTitle}</p>
          </div>
          <p className="card-text font-weight-normal">{content}</p>
        </div>
      </div>
    </div>
  </div>
);

export default ServiceCardOne;