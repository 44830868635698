import React, { useEffect } from 'react';

import { Col } from 'reactstrap';
import { connect } from 'react-redux';

import { gameActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  sectionSelection: state.gameReducers.sectionSelection,
  selectedSubType: state.gameReducers.selectedSubType,
});

const mapDispatchToProps = dispatch => ({
  updateSectionSelection: selection => dispatch(gameActions.updateSectionSelection(selection)),
});

const MultipleTypeSelectionPanel = ({ list, gameInfo, updateSectionSelection, sectionSelection, selectedSubType }) => {
  const generateAvailableData = () => {
    const result = [];
    list.forEach(item => {
      if (item.rowData) {
        item.rowData.forEach(rowItem => {
          if (!result.find(x => JSON.stringify(x) === JSON.stringify(rowItem)) && rowItem.code) {
            result.push(rowItem);
          }
        });
      }
    });
    return result;
  };

  useEffect(() => {
    const availableData = generateAvailableData();
    if (availableData && availableData.length > 0) {
      if (selectedSubType !== 'HX') {
        updateSectionSelection(availableData[0]);
      }
    }
  }, [list, selectedSubType]);

  useEffect(() => {
    const availableData = generateAvailableData();
    if (selectedSubType === 'HX' && gameInfo && gameInfo?.oddsList) {
      if (!sectionSelection || gameInfo?.oddsList?.[sectionSelection.code]?.enabled === 0) {
        updateSectionSelection(availableData.find(x => (gameInfo?.oddsList && gameInfo?.oddsList[x.code] && gameInfo?.oddsList[x.code].enabled === 1)));
      }
    }
  }, [gameInfo?.oddsList, sectionSelection]);

  return (
    <Col md="12" className="multiTypePanelWrapper my-2">
      <table className="multiTypePanel w-100  text-center">
        <tbody>
          {
            list.map((item, index) => {
              const Component = item.rowType;
              return (
                <tr key={`rows_${index.toString()}`}>
                  <td>
                    <div className={item.wrapperStyle}>
                      {item.rowTitle && <div><span className={item.rowTitleClassName}>{item.rowTitle}</span></div>}
                      {
                        item.rowData ? item.rowData.map((subItem, subIndex) => {
                          if (Array.isArray(subItem.displayName)) {
                            return (
                              <div key={`sub_cell_${subIndex.toString()}`} className={subItem.className}>
                                {
                                  subItem.displayName.map(sub => (
                                    <Component {...sub} onChange={() => item.onCheckBoxChange(updateSectionSelection, subItem)} gameInfo={gameInfo} active={sectionSelection && subItem.code === sectionSelection.code} />
                                  ))
                                }
                              </div>
                            );
                          }
                          return (
                            <div key={`sub_cell_${subIndex.toString()}`} className={`${subItem.className} ${!(gameInfo && gameInfo.oddsList && gameInfo.oddsList[subItem.code]) ? 'disabledCell' : ''}`}>
                              <Component {...subItem} onChange={() => item.onCheckBoxChange(updateSectionSelection, subItem)} gameInfo={gameInfo} active={sectionSelection && subItem.code === sectionSelection.code} />
                            </div>
                          );
                        }) : <Component />
                      }
                    </div>
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MultipleTypeSelectionPanel);