import React, { useEffect, useState } from 'react';

import { Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { cartActions, gameActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  sectionSelection: state.gameReducers.sectionSelection,
  gameInfo: state.placeBetReducers.gameInfo,
  currentSelected: state.gameReducers.currentSelected,
});

const mapDispatchToProps = dispatch => ({
  updateSectionSelection: selection => dispatch(gameActions.updateSectionSelection(selection)),
  resetItemInCart: () => dispatch(cartActions.resetItemInCart()),
});

const OddsTypeTable = ({ sectionSelection, gameInfo, renderLabel, currentSelected }) => {
  const [_sectionSelection, setSectionSelection] = useState(sectionSelection);
  const { t } = useTranslation();

  useEffect(() => {
    if (sectionSelection) {
      setSectionSelection(sectionSelection);
    }
  }, [sectionSelection]);

  return (
    <Row className="oddsTypeTable mt-2 m-1">
      <table>
        <tbody>
          <tr>
            <td className="label">{t('ODDS_TYPE')}</td>
            <td className="value">{renderLabel(sectionSelection) || '--'}</td>
          </tr>
          <tr>
            <td className="label">{t('ODDS')}</td>
            <td className="value odds">{(gameInfo && _sectionSelection && gameInfo.oddsList && gameInfo.oddsList[_sectionSelection.value] && gameInfo.oddsList[_sectionSelection.value].odds) || (gameInfo && _sectionSelection && gameInfo.oddsList && currentSelected && gameInfo.oddsList[`${_sectionSelection.value}_${currentSelected.length}`] && gameInfo.oddsList[`${_sectionSelection.value}_${currentSelected.length}`].odds) || '--'}</td>
          </tr>
        </tbody>
      </table>
    </Row>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OddsTypeTable);