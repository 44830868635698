import QuickBet from '../../../../components/Web/Content/QuickBet';
import Template2 from '../../../../components/GameTemplates/Template2';

const TX = [
  {
    componentType: QuickBet,
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['生肖', '号码', '赔率', '金额'],
      mainColumnLength: 2,
      subColumnLength: 4,
      subColumnStyle: ['column-4items-label', 'column-4items-balls', 'column-4items-odds', 'column-4items-input'],
      betItemStyle: ['column-4items-label', 'column-4items-array-balls column-4items-balls', 'column-4items-odds', 'column-4items-input'],
      betItemList: ['DisplayText', 'HK6_Zodiac', 'Odds', 'Input'],
      betItemColumn: 4,
      zodiacTotalBall: 49,
      list: [
        {
          code: 'TMSX1', // keyCode
          displayName: '鼠',
        }, {
          code: 'TMSX7',
          displayName: '马',
        }, {
          code: 'TMSX2',
          displayName: '牛',
        }, {
          code: 'TMSX8',
          displayName: '羊',
        }, {
          code: 'TMSX3',
          displayName: '虎',
        }, {
          code: 'TMSX9',
          displayName: '猴',
        }, {
          code: 'TMSX4',
          displayName: '兔',
        }, {
          code: 'TMSX10',
          displayName: '鸡',
        }, {
          code: 'TMSX5',
          displayName: '龙',
        }, {
          code: 'TMSX11',
          displayName: '狗',
        }, {
          code: 'TMSX6',
          displayName: '蛇',
        }, {
          code: 'TMSX12',
          displayName: '猪',
        }
      ]
    }
  },
  {
    componentType: Template2,
    data: {
      cycleHeaderTitle: true,
      tableHeadertitle: ['种类', '赔率', '金额'],
      mainColumnLength: 3,
      subColumnLength: 3,
      subColumnStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemStyle: ['column-3items-label', 'column-3items-odds', 'column-3items-input'],
      betItemList: ['DisplayText', 'Odds', 'Input'],
      betItemColumn: 3,
      list: [
        {
          code: 'TMWS1', // keyCode
          displayName: '0尾',
        }, {
          code: 'TMWS6',
          displayName: '5尾',
        }, {
          code: 'TMTS11',
          displayName: '0头',
        }, {
          code: 'TMWS2',
          displayName: '1尾',
        }, {
          code: 'TMWS7',
          displayName: '6尾',
        }, {
          code: 'TMTS12',
          displayName: '1头',
        }, {
          code: 'TMWS3',
          displayName: '2尾',
        }, {
          code: 'TMWS8',
          displayName: '7尾',
        }, {
          code: 'TMTS13',
          displayName: '2头',
        }, {
          code: 'TMWS4',
          displayName: '3尾',
        }, {
          code: 'TMWS9',
          displayName: '8尾',
        }, {
          code: 'TMTS14',
          displayName: '3头',
        }, {
          code: 'TMWS5',
          displayName: '4尾',
        }, {
          code: 'TMWS10',
          displayName: '9尾',
        }, {
          code: 'TMTS15',
          displayName: '4头',
        }
      ]
    }
  },
  {
    componentType: QuickBet,
  },
];

export default TX;