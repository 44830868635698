import { SET_BET_DETAIL_ITEM, SET_BET_REPORT_LIST, SET_SETTLED_BET_LIST, SET_UNSETTLED_BET_LIST } from '../../actions/actionTypes';

const initialState = {
  betReportList: undefined,
  unsettleBetList: undefined,
  settleBetList: undefined,
  betDetailItem: undefined,
};

const bettingListReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_BET_DETAIL_ITEM:
      return {
        ...state, betDetailItem: action.value
      };

    case SET_BET_REPORT_LIST:
      return {
        ...state, betReportList: action.value
      };

    case SET_SETTLED_BET_LIST:
      return {
        ...state, settleBetList: action.value
      };

    case SET_UNSETTLED_BET_LIST:
      return {
        ...state, unsettleBetList: action.value
      };

    default:
      return state;
  }
};

export default bettingListReducers;