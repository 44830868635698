import React, { useEffect } from 'react';

import { Button } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Exclamation } from '../../../assets/icon/TopUpIcons/icon_exclamation.svg';
import Label from '../../Label';
import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const ConfirmationModal = ({ platform, updateModalAlert, data }) => {
  const { t } = useTranslation();

  const confirmModal = () => {
    updateModalAlert({
      visible: false
    });
    if (data && data.onConfirmationClick) data.onConfirmationClick();
  };

  function handleKeyDown(event) {
    if (event.key === 'Escape') {
      updateModalAlert({
        visible: false
      });
      if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
      if (data && data.onCancelClick) data.onCancelClick();
    } else if (event.key === 'Enter') {
      confirmModal();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className={`confirmModalWrapper${platform}`}>
      <div className="modalHeader d-flex">
        <div
          className="d-flex align-items-center col"
        >
          {t('REMINDER')}
        </div>
        <div className="d-flex justify-content-end align-items-center col">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </div>
      </div>
      <div className="modalContent">
        <div className="d-flex justify-content-center my-2">
          <Exclamation className="exclamationIcon" />
        </div>
        <div className="d-flex justify-content-center my-2">
          <Label className="confirmText" value={data && data.message} />
        </div>
      </div>
      <div className="modalFooter d-flex align-items-center">
        <Button
          className={`confirmButton my-3 ${platform === 'Web' ? 'mr-3' : 'ml-2 mr-3'}`}
          onClick={() => {
            confirmModal();
          }}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
        {
          data && data.showCancelButton !== false && (
            <Button
              className={`cancelButton my-3 ${platform === 'Web' ? 'mr-3' : 'mr-2'}`}
              onClick={() => {
                updateModalAlert({
                  visible: false
                });
                if (data && data.onCancelClick) data.onCancelClick();
              }}
            >
              {(data && data.cancelText) || t('CANCEL')}
            </Button>
          )
        }
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);