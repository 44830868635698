import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';

const HM3 = [
  {
    componentType: CollapseType,
    header: '第三球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'B3QH1',
          displayName: '1',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B3QH2',
          displayName: '2',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B3QH3',
          displayName: '3',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B3QH4',
          displayName: '4',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B3QH5',
          displayName: '5',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B3QH6',
          displayName: '6',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B3QH7',
          displayName: '7',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B3QH8',
          displayName: '8',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B3QH9',
          displayName: '9',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B3QH10',
          displayName: '10',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B3QH11',
          displayName: '11',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B3QH12',
          displayName: '12',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B3QH13',
          displayName: '13',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B3QH14',
          displayName: '14',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B3QH15',
          displayName: '15',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B3QH16',
          displayName: '16',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B3QH17',
          displayName: '17',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B3QH18',
          displayName: '18',
          drawImgClassName: 'circle circleGreen',
        }, {
          code: 'B3QH19',
          displayName: '19',
          drawImgClassName: 'circle circleRed',
        }, {
          code: 'B3QH20',
          displayName: '20',
          drawImgClassName: 'circle circleBlue',
        }, {
          code: 'B3QH21',
          displayName: '21',
          drawImgClassName: 'circle circleGreen',
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '色波，福禄寿喜，两面',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'Hk6ItemWrapper',
      list: [
        {
          code: 'B3LM1',
          displayName: '大',
        }, {
          code: 'B3LM2',
          displayName: '小',
        }, {
          code: 'B3LM3',
          displayName: '单',
        }, {
          code: 'B3LM4',
          displayName: '双',
        }, {
          code: 'B3LM5',
          displayName: '尾大',
        }, {
          code: 'B3LM6',
          displayName: '尾小',
        }, {
          code: 'B3LM7',
          displayName: '合单',
        }, {
          code: 'B3LM8',
          displayName: '合双',
        }, {
          code: 'B3QH22',
          displayName: '红',
        }, {
          code: 'B3QH23',
          displayName: '绿',
        }, {
          code: 'B3QH24',
          displayName: '蓝',
        }, {
          code: 'B3QH25',
          displayName: '福',
        }, {
          code: 'B3QH26',
          displayName: '禄',
        }, {
          code: 'B3QH27',
          displayName: '寿',
        }, {
          code: 'B3QH28',
          displayName: '喜',
        },
      ]
    }
  }
];

export default HM3;