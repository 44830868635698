import DH15 from './DH15';
import DH610 from './DH610';
import GYZH from './GYZH';
import KuaiJie from './KuaiJie';
import LIANGMIAN from './LIANGMIAN';


const balls = {
  1: {
    value: 1,
    className: 'square square_1'
  },
  2: {
    value: 2,
    className: 'square square_2'
  },
  3: {
    value: 3,
    className: 'square square_3'
  },
  4: {
    value: 4,
    className: 'square square_4'
  },
  5: {
    value: 5,
    className: 'square square_5'
  },
  6: {
    value: 6,
    className: 'square square_6'
  },
  7: {
    value: 7,
    className: 'square square_7'
  },
  8: {
    value: 8,
    className: 'square square_8'
  },
  9: {
    value: 9,
    className: 'square square_9'
  },
  10: {
    value: 10,
    className: 'square square_10'
  }
};

const renderQiu = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  return result.map(item => (
    {
      value: item, className: balls[item] ? balls[item].className : ''
    }
  ));
};

const renderGYH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  const championSum = parseInt(result[0], 10) + parseInt(result[1], 10);

  tempResult.push({
    value: championSum,
    className: 'redText resultItem'
  });

  tempResult.push({
    value: championSum > 11 ? '大' : '小',
    className: championSum > 11 ? 'resultItem resultItemBlue' : 'resultItem resultItemOrange',
  });

  tempResult.push({
    value: championSum % 2 ? '单' : '双',
    className: championSum % 2 ? 'resultItem resultItemOrange' : 'resultItem resultItemBlue',
  });
  return tempResult;
};

const renderDX = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  let tempResult = [];

  tempResult = result.map(item => ({
    value: item > 5 ? '大' : '小',
    className: item > 5 ? 'resultItem resultItemBlue' : 'resultItem resultItemOrange',
  }));

  return tempResult;
};

const renderDS = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  let tempResult = [];

  tempResult = result.map(item => ({
    value: item % 2 ? '单' : '双',
    className: item % 2 ? 'resultItem resultItemBlue' : 'resultItem resultItemOrange',
  }));

  return tempResult;
};

const renderLH = data => {
  if (data && !data.openResult) return;
  const result = data.openResult.split(',');
  const tempResult = [];
  for (let i = 0; i < result.length / 2; i++) {
    if ((parseInt(result[i], 10)) > parseInt(result[result.length - 1 - i], 10)) {
      tempResult.push({
        value: '龙',
        className: 'resultItem resultItemBlue',
      });
    } else {
      tempResult.push({
        value: '虎',
        className: 'resultItem resultItemOrange',
      });
    }
  }
  return tempResult;
};

const PK10 = {
  children: [
    {
      tabId: 'KuaiJie',
      label: '快捷',
      children: KuaiJie.KuaiJie,
      settingCode: 'CH,LM'
    },
    {
      tabId: 'LiangMian',
      label: '两面',
      children: LIANGMIAN.LIANGMIAN,
      defaultTab: true,
      settingCode: 'LM,GYJHDX,GYJHDS'
    },
    {
      tabId: 'DH15',
      label: '1-5号车',
      children: DH15.DH15,
      midCode: 'GJHM,YJHM,B3CH,B4CH,B5CH'
    },
    {
      tabId: 'DH610',
      label: '6-10号车',
      children: DH610.DH610,
      midCode: 'B6CH,B7CH,B8CH,B9CH,B10CH'
    },
    {
      tabId: 'GYZH',
      label: '冠亚组合',
      children: GYZH.GYZH,
      settingCode: 'GYJH,GYJHDX,GYJHDS'
    }
  ],
  results: {
    TabList: [{
      code: 'NUMBER',
      renderFunc: data => renderQiu(data)
    },
    {
      code: 'GYH',
      renderFunc: data => renderGYH(data)
    },
    {
      code: 'BIG_SMALL',
      renderFunc: data => renderDX(data)
    },
    {
      code: 'ODD_EVEN',
      renderFunc: data => renderDS(data)
    },
    {
      code: 'LH',
      renderFunc: data => renderLH(data)
    }]
  }
};

export default PK10;