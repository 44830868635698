import CollapseType from '../../../../components/Mobile/ComponentType/CollapseType';
import OptionItem from '../../../../components/Mobile/OptionItem';
import gameItemIconMap from '../../../GameItemIcon';


const HM8 = [
  {
    componentType: CollapseType,
    header: '第八球',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B8HM1',
          displayName: '1',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WATERMELON,
        }, {
          code: 'B8HM6',
          displayName: '6',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_GRAPES,
        }, {
          code: 'B8HM11',
          displayName: '11',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEAR,
        }, {
          code: 'B8HM16',
          displayName: '16',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_RADISH,
        }, {
          code: 'B8HM2',
          displayName: '2',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_COCONUT,
        }, {
          code: 'B8HM7',
          displayName: '7',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_LYCHEE,
        }, {
          code: 'B8HM12',
          displayName: '12',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_APPLE,
        }, {
          code: 'B8HM17',
          displayName: '17',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PUMPKIN,
        }, {
          code: 'B8HM3',
          displayName: '3',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_DURIAN,
        }, {
          code: 'B8HM8',
          displayName: '8',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_CHERRY,
        }, {
          code: 'B8HM13',
          displayName: '13',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PEACH,
        }, {
          code: 'B8HM18',
          displayName: '18',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_EGGPLANT,
        }, {
          code: 'B8HM4',
          displayName: '4',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_POMELO,
        }, {
          code: 'B8HM9',
          displayName: '9',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_STRAWBERRY,
        }, {
          code: 'B8HM14',
          displayName: '14',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_ORANGE,
        }, {
          code: 'B8HM19',
          displayName: '19',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_DOG,
        }, {
          code: 'B8HM5',
          displayName: '5',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_PINEAPPLE,
        }, {
          code: 'B8HM10',
          displayName: '10',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_TOMATO,
        }, {
          code: 'B8HM15',
          displayName: '15',
          drawImgClassName: 'circle',
          image: gameItemIconMap.CJ_WINTERMELON,
        }, {
          code: 'B8HM20',
          displayName: '20',
          drawImgClassName: 'circle circleRed',
          image: gameItemIconMap.CJ_COW,
        }
      ]
    }
  },
  {
    componentType: CollapseType,
    header: '两面',
    headerStyle: '',
    data: {
      itemType: OptionItem,
      itemWrapperStyle: 'PK10ItemWrapper',
      list: [
        {
          code: 'B8LM21',
          displayName: '大',
        }, {
          code: 'B8LM23',
          displayName: '单',
        }, {
          code: 'B8LM25',
          displayName: '尾大',
        }, {
          code: 'B8LM27',
          displayName: '合单',
        }, {
          code: 'B8LM22',
          displayName: '小',
        }, {
          code: 'B8LM24',
          displayName: '双',
        }, {
          code: 'B8LM26',
          displayName: '尾小',
        }, {
          code: 'B8LM28',
          displayName: '合双',
        }, {
          code: 'B8FW31',
          displayName: '东',
        }, {
          code: 'B8FW32',
          displayName: '南',
        }, {
          code: 'B8FW33',
          displayName: '西',
        }, {
          code: 'B8FW34',
          displayName: '北',
        }, {
          code: 'B8ZFB35',
          displayName: '中',
        }, {
          code: 'B8ZFB36',
          displayName: '发',
        }, {
          code: 'B8ZFB37',
          displayName: '白',
        }
      ]
    }
  },
];

export default HM8;